import React from "react";
import TaskMain from "../components/taskManage/TaskMain";
import UserAuthRoute from "./common/UserAuthRoute";
import TaskMyAddList from "../components/taskManage/TaskMyAddList";
import TaskNotifySetting from "../components/taskManage/TaskNotifySetting";
import MobilePopupAttach from "../components/common/MobilePopupAttach";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import TaskNotifyNotLogin from "../components/taskManage/TaskNotifyNotLogin";

function TaskManageRoute({ match }) {
  return (
    <CustomSwitch>
      <CacheRoute
        exact
        path={`${match.path}/list`}
        component={TaskMain}
        when="always"
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my`}
        requireAuth={true}
        component={TaskMyAddList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/setting/info`}
        component={TaskNotifyNotLogin}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/setting`}
        requireAuth={true}
        component={TaskNotifySetting}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/pop`}
        requireAuth={true}
        component={MobilePopupAttach}
      />
    </CustomSwitch>
  );
}

export default TaskManageRoute;
