import React from "react";
import SaleFeeds from "./SaleFeeds";

const MONTHS = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

const AnnualSalesFeeTable = ({ yearFeeds, universityList }) => {
  return (
    <div className="annual-fee-grid">
      <div className="total-col header-cell">합계</div>
      {MONTHS.map((month) => (
        <div className="header-cell" key={month}>
          {month}
        </div>
      ))}

      {yearFeeds.map((data, feedIndex) => (
        <SaleFeeds key={feedIndex} feedRows={data} univName={universityList[feedIndex]} />
      ))}
    </div>
  );
};

export default AnnualSalesFeeTable;
