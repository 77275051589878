const UNIVERSITY_ROW_DATA = [
  "total_amount",
  "annual_total_amount",
  "office_fee",
  "actual_fee",
  "ipn_fee",
  "surtax",
  "package_fee",
  "deposit_amount",
  "non_deposit_amount",
];

export default function transformUniversitiesIncome(responce) {
  const {
    data: { annualPayDepositAllList = [] },
  } = responce;

  if (!annualPayDepositAllList.length) return;

  const universitiesTables = Array.from({ length: annualPayDepositAllList.length }, () => [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);

  //* looping through each university
  annualPayDepositAllList.forEach(({ data: { annualPayDepositList } }, universityNumber) => {
    //* looping through university monthly data
    annualPayDepositList.forEach((universityMonthlyData) => {
      UNIVERSITY_ROW_DATA.forEach((rowField, rowIndex) => {
        universitiesTables[universityNumber][rowIndex][universityMonthlyData.pmonth - 1] =
          universityMonthlyData[rowField] ?? 0;
      });
    });
  });

  return universitiesTables;
}
