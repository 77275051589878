import "./css/AnnualFeeGiveUpConfirm.scss";

function AnnualFeeGiveUpConfirm({ setShowGiveUpPop, annualFeeData }) {
  return (
    <div id="AnnualFeeGiveUpConfirm">
      <div className="title">
        <strong>포기 알림</strong>
        <i
          className="icon_exit_gray"
          onClick={() => setShowGiveUpPop(false)}
        ></i>
      </div>
      <div className="cont">
        <p>
          특허 권리를 포기합니다.
          <br />
          발명자에게 안내 메일을 송부하시겠습니까?
        </p>
        <div className="btns">
          <button
            className="yes"
            onClick={() => {
              window.open(
                `/patent/detail/${annualFeeData.patent_idx}`,
                "_blank"
              );
              setShowGiveUpPop(false);
            }}
          >
            네
          </button>
          <button className="no" onClick={() => setShowGiveUpPop(false)}>
            아니오
          </button>
        </div>
      </div>
    </div>
  );
}

export default AnnualFeeGiveUpConfirm;
