import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Admin from "../../../API/admin";
import "../css/AdminCheckPoint.scss";
import { Link } from "react-router-dom";
import Styles from "../../patent/detail/checkpoint/tabsNavigator/tabsNavigator.module.scss";
function AdminCheckPoint() {
  const [frequentQuestionsState, setFrequentQuestionsState] = useState({
    state: "LOADING",
    questions: [],
  });
  const [activeTab, setactiveTab] = useState(0);
  const [activeAccordionItems, setActiveAccordionItems] = useState([]);
  const category = 15;
  const path = "checkpoint/posting";
 
  const changeTabHandler = (name, index) => {
    setactiveTab({ name, index });
    setActiveAccordionItems([]);
  };
  const toggleAccordionItem = (index) => {
    setActiveAccordionItems((pervItems) =>
      pervItems.includes(index)
        ? pervItems.filter((itemIndex) => itemIndex !== index)
        : [...pervItems, index]
    );
  };

  useEffect(() => {
    const payload = {
      flag: 2,
    };
    Admin.adminCheckPointList(payload)
      .then((result) => {
        const { checkpointList } = result.data.data;
        setFrequentQuestionsState({
          state: "SUCCESS",
          questions: checkpointList,
        });
        setactiveTab({
          name: Object.keys(checkpointList)[0],
          index: 0,
        });
      })
      .catch((err) => {
        setFrequentQuestionsState({
          state: "ERROR",
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, []);

 

  return (
    <div id="AdminCheckPoint" style={{ minHeight: "100vh" }}>
      <h2 className="page_title">Check Point</h2>
      <div className="btns">
        <Link to={`${path}?category=${category}`} className="btn_post">
          글 작성하기
        </Link>
      </div>
      <div className={Styles["tabs-navigator"]}>
        {frequentQuestionsState.state ===
        "LOADING" ? null : frequentQuestionsState.state === "SUCCESS" ? (
          <>
            <ul>
              {Object.keys(frequentQuestionsState.questions)?.map(
                (tabName, tabIndex) => (
                  <li
                    onClick={() => changeTabHandler(tabName, tabIndex)}
                    style={{
                      backgroundColor:
                        activeTab.name === tabName ? "#fff7f4" : "",
                    }}
                    key={tabName}
                  >
                    {tabName}
                  </li>
                )
              )}
            </ul>
            <ul
              style={{
                borderTopLeftRadius: activeTab.index === 0 ? 0 : "24px",
                borderTopRightRadius: activeTab.index === 2 ? 0 : "24px",
              }}
            >
              {frequentQuestionsState.questions[activeTab.name]?.map(
                ({ idx, answer, question }) => (
                  <li key={idx}>
                    <div onClick={() => toggleAccordionItem(idx)}>
                      <p>{question}</p>
                      <i
                        style={{
                          rotate: activeAccordionItems.includes(idx)
                            ? "90deg"
                            : "",
                        }}
                        className="icon_arrow_right_s"
                      />
                    </div>

                    <div
                      style={{
                        height: activeAccordionItems.includes(idx) ? "auto" : "0",
                        overflow: "hidden",
                      }}
                    >
                      <Link
                        to={`${path}?idx=${idx}&type=view&category=${category}`}
                        className="answer-link"
                       
                      >
                        <p>{answer}</p>
                      </Link>
                    </div>
                  </li>
                )
              )}
            </ul>
          </>
        ) : (
          <div className={Styles["error-div"]}>
            {frequentQuestionsState.error}
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminCheckPoint;
