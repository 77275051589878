import React, { useState } from "react";
import AdminApi from "../../../../../API/admin";
import SelectBox from "../../../../common/selectBox/SelectBox";
import Styles from "./CheckointForm.module.scss";

const CheckpointForm = ({ checkpoint, categories, onSubmit, onClose, title, actionType }) => {
  const [formData, setformData] = useState({
    category: checkpoint.category || { ...categories[0] },
    label: checkpoint.label || { ...categories[0].children?.[0] },
    question: checkpoint.question || "",
  });

  const updateFormData = (name, value) =>
    name === "category"
      ? setformData((oldValues) => ({ ...oldValues, [name]: value, label: value.children?.[0] }))
      : setformData((oldValues) => ({ ...oldValues, [name]: value }));

  const filteredLabels = categories.find(({ name }) => name === formData.category.name)?.children ?? [];

  const submitHandler = (event) => {
    event.preventDefault();
    const payload = {
      flag: 1,
      subFlag: formData.category.subFlag,
      detailFlag: formData.label.detailFlag,
      question: formData.question,
    };
    const responsePromise = !checkpoint.idx
      ? AdminApi.addCheckPointList(payload)
      : AdminApi.editCheckPointList({ idx: checkpoint.idx, ...payload });
    onSubmit(responsePromise);
  };

  return (
    <form onSubmit={submitHandler} className={Styles["checkpoint-form"]}>
      <button className={Styles["close-btn"]} onClick={onClose} type="button">
        <i className="icon_exit" />
      </button>
      <h2>{title}</h2>
      <div>
        <label htmlFor="category">카테고리*</label>
        <SelectBox
          className={Styles["select-div"]}
          withArrowIndicator={true}
          name="category"
          selectedOption={{ name: formData.category.name }}
          options={categories ?? []}
          onChange={(name, option) => updateFormData(name, option)}
        />
      </div>
      <div>
        <label htmlFor="category">항목*</label>
        <SelectBox
          className={Styles["select-div"]}
          withArrowIndicator={true}
          name="label"
          selectedOption={{ name: formData.label.name }}
          options={filteredLabels}
          onChange={(name, option) => updateFormData(name, option)}
        />
      </div>
      <div>
        <label htmlFor="question">질문*</label>
        <textarea
          value={formData.question}
          onChange={(event) => updateFormData("question", event.target.value)}
          id="question"
          rows={6}
          required
        ></textarea>
      </div>

      <div>
        <button type="submit">{actionType === 'Add' ? '추가하기' : '수정하기'}</button>
      </div>
    </form>
  );
};

export default CheckpointForm;
