import React from "react";
import "./css/IntroRedirectList.scss";
import {Link} from "react-router-dom";
import links from "../../../const/links";

function IntroRedirectList() {
	return (
		<div id="IntroRedirectList">
			<div className="new_redirect_list pc">
				<Link to={links.marketInfo}>
					<img src={require('../../../assets/images/intro/icon_new.svg').default} className="icon_new" alt="바로가기 이미지" />
					<div className="wrap">
						<img src={require('../../../assets/images/common/icon-market-info.svg').default} alt="바로가기 이미지" style={{width: 55, height: 48}}/>
						<p>시장 정보 보기</p>
					</div>
					<button className="icon_next_black"/>
				</Link>
				<Link to={links.researchTechCategory}>
					<img src={require('../../../assets/images/intro/icon_new.svg').default} className="icon_new" alt="바로가기 이미지" />
					<div className="wrap">
						<img src={require('../../../assets/images/common/icon-category-tech.svg').default} alt="바로가기 이미지"/>
						<p>선행기술 조사</p>
					</div>
					<button className="icon_next_black"/>
				</Link>
				<Link to={links.matchingBiz}>
					<div className="wrap">
						<img src={require('../../../assets/images/common/icon-matchingbiz.svg').default} alt="바로가기 이미지" style={{width: 54, height: 48}}/>
						<p>매칭비즈</p>
					</div>
					<button className="icon_next_black"/>
				</Link>
			</div>
			<div className="redirect_list pc">
				<Link to={links.docTrade}>
					<div className="wrap_img">
						<img src={require('../../../assets/images/common/icon-sample-market.svg').default} alt="바로가기 이미지" />
					</div>
					<p>제안서마켓</p>
				</Link>
				<Link to={links.consortium}>
					<div className="wrap_img">
						<img src={require('../../../assets/images/common/icon-con.svg').default} alt="바로가기 이미지" />
					</div>
					<p>컨소시엄</p>
				</Link>
				<Link to={links.exposition}>
					<div className="wrap_img">
						<img src={require('../../../assets/images/common/icon-edu.svg').default} alt="바로가기 이미지" />
					</div>
					<p>교육·행사 알림</p>
				</Link>
				<Link to={links.businessDescription}>
					<div className="wrap_img">
						<img src={require('../../../assets/images/common/icon-bussiness.svg').default} alt="바로가기 이미지" />
					</div>
					<p>사업설명회</p>
				</Link>
				<Link to={links.govSupport}>
					<div className="wrap_img">
						<img src={require('../../../assets/images/common/icon-gov.svg').default} alt="바로가기 이미지" />
					</div>
					<p>지원·정책알림</p>
				</Link>
			</div>
		</div>
	)
}

export default IntroRedirectList;