import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import "./css/MarketInfoHeader.scss";

const MarketInfoHeader = ({ category }) => {
  const [marketInfoCount, setMarketInfoCount] = useState(0);
  const [fieldCount, setFieldCount] = useState(0);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getMarketCategory().then((res) => {
      let data = res.data.category_list;
      getCount(data);
    });
  };

  const getCount = (data) => {
    data.map((el) => {
      setMarketInfoCount((prevState) => prevState + el.child.length);
      el.child.map((item) => {
        setFieldCount((prevState) => prevState + item.data_cnt);
      });
    });
  };

  return (
    <div id="MarketInfoHeader">
      <div className="tutorial-box pc">
        <div className="wrap_text">
          <h2>시장 정보 보기</h2>
          <p>
            <span>제안서, 사업계획서 작성에 필요한</span>
            <span>{marketInfoCount}</span>개의 분야,
            <br />
            <span>{fieldCount.toLocaleString("en-AU")}</span>건의 시장정보를
            활용해 보세요.
          </p>
        </div>
      </div>
      <div className="category_wrapper">
        <div className="category_item">
          <p>
            HOME <i className="icon_next_black" /> 시장 정보 보기
            <i className="icon_next_black" /> 분야 선택하기
          </p>
        </div>
        <div className="links">
          <Link to="my" className="btn_my">
            <span>나의 시장 정보 페이지</span>
            <i className="icon_notification_blue_full" />
          </Link>
          <Link to="request" className="btn_write">
            <span>시장정보 작성 요청</span>
            <i className="icon_pencil_outline" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MarketInfoHeader;
