import React, { useState } from "react";
import "./css/CustomerService.scss";
import _ from "lodash";
import CommunityContainer from "../container/CommunityContainer";
import CheckBox from "../common/CheckBox";
import IntroAPI from "../../API/intro";
import phone from "../../assets/images/intro/icon-phone.png";
import envelope from "../../assets/images/intro/icon-envelope.png";

function CustomerService({ history }) {
  const [agree, setAgree] = useState(false);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const [questions, setQuestions] = useState({
    name: "",
    company_name: "",
    phone: "",
    email: "",
    content: "",
  });

  let str = isViewTypeIpnow ? "IPNOW" : "BIZNAVI";
  const onChange = (key, e) => {
    let copy = _.cloneDeep(questions);
    copy[key] = e.target.value;
    setQuestions(copy);
  };

  const onClickSend = () => {
    if (!agree) {
      alert("개인정보 수집에 동의해주세요");
      return;
    }

    let keys = Object.keys(questions);

    for (let i in keys) {
      if (questions[keys[i]].length == 0) {
        alert("모든 정보를 입력해주세요");
        return;
      }
    }

    if (window.confirm("접수하시겠습니까?")) {
      IntroAPI.sendSupport(questions).then(() => {
        alert("문의사항이 접수되었습니다\n빠른 시일내로 답변드리겠습니다");
        history.go(0);
      });
    }
  };

  return (
    <div id="CustomerService">
      <div className="pc">
        <div className="intro">
          <p>
            고객의 <span>행복</span>을 지원하겠습니다.
          </p>
          <p>
            보다 편리한 {str}의 사용을 돕기 위하여 다양한 고객지원 서비스를
            운영하고 있습니다.
          </p>
          <div className="contact_info">
            <div className="contact_text">
              <span>문의전화</span>
              <img src={phone} alt="phone" style={{ width: 26, height: 21 }} />
            </div>
            <div className="contact_text_info">
              <p>
                Tel.{" "}
                <span
                  style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                >
                  070-4249-1023
                </span>
              </p>
              <p>월~금 09:00~18:00 주말 및 공휴일 휴무</p>
            </div>
          </div>
          <div className="contact_info">
            <div className="contact_text">
              <span>이메일</span>
              <img
                src={envelope}
                alt="envelope"
                style={{ width: 30, height: 21 }}
              />
            </div>
            <div className="contact_text_info">
              <p>
                <span
                  style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                >
                  ipnow@ipnow.co.kr
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="contact_us">
          <h2 className="contact_us_text">지금 바로 도움이 필요하신가요?</h2>
          <div className="contact_request">
            <div className="contact_request_left">
              <div className="column">
                <h2>담당자 성함</h2>
                <input
                  type="text"
                  placeholder="담당자분 이름을 입력해주세요"
                  onChange={(e) => onChange("name", e)}
                />
              </div>
              <div className="column">
                <h2>회사명</h2>
                <input
                  type="text"
                  placeholder="회사명을 입력해주세요"
                  onChange={(e) => onChange("company_name", e)}
                />
              </div>
              <div className="column">
                <h2>연락처</h2>
                <input
                  type="text"
                  placeholder="전화번호를 입력해주세요"
                  onChange={(e) => onChange("phone", e)}
                />
              </div>
              <div className="column">
                <h2>E-mail</h2>
                <input
                  type="text"
                  placeholder="회사 이메일을 입력해주세요"
                  onChange={(e) => onChange("email", e)}
                />
              </div>
              <div className="radio_btn">
                <input
                  type="radio"
                  checked={agree}
                  onClick={() => {
                    setAgree(!agree);
                  }}
                  id="agree"
                  name="choice"
                />
                <label htmlFor="agree">
                  <span
                    style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                  >
                    개인정보 수집 및 이용목적
                  </span>
                  에 동의합니다
                </label>
              </div>
            </div>
            <div className="contact_request_right">
              <div className="column">
                <h2>문의 내용</h2>
                <textarea
                  placeholder="최대 5000자 입력 가능합니다"
                  onChange={(e) => onChange("content", e)}
                />
              </div>
              <button
                className="btn_send"
                onClick={onClickSend}
                style={{
                  backgroundColor: isViewTypeIpnow ? "#ffa600" : "#4593f5",
                }}
              >
                상담 요청
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mo">
        <div className="mo_banner" />
        <div className="intro">
          <h2>
            고객의 <span>행복</span>을 지원하겠습니다.
          </h2>
          <p>
            보다 편리한 BIZNAVI의 사용을 돕기 위하여
            <br />
            다양한 고객지원 서비스를 운영하고 있습니다.
          </p>
        </div>
        <div className="form_wrapper">
          <div className="form">
            <h2>지금 바로 도움이 필요하신가요?</h2>
            <div className="field">
              <p>담당자 성함</p>
              <input
                type="text"
                placeholder="담당자분 이름을 입력해주세요"
                onChange={(e) => onChange("name", e)}
              />
            </div>
            <div className="field">
              <p>회사명</p>
              <input
                type="text"
                placeholder="회사명을 입력해주세요"
                onChange={(e) => onChange("company_name", e)}
              />
            </div>
            <div className="field">
              <p>연락처</p>
              <input
                type="text"
                placeholder="전화번호를 입력해주세요"
                onChange={(e) => onChange("phone", e)}
              />
            </div>
            <div className="field">
              <p>E-mail</p>
              <input
                type="text"
                placeholder="회사 이메일 입력해주세요"
                onChange={(e) => onChange("email", e)}
              />
            </div>
            <div className="field textAreaField">
              <p>문의 내용</p>
              <textarea onChange={(e) => onChange("content", e)} />
            </div>
            <div className="checkbox">
              <input
                type="radio"
                id="checkbox"
                name="drone"
                value={agree}
                onClick={() => setAgree(!agree)}
                checked={agree}
              />
              <label htmlFor="checkbox">
                <span
                  style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                >
                  개인 정보 수집 및 이용목적
                </span>
                에 동의합니다.
              </label>
            </div>
            {/* <CheckBox
              text="(필수) 개인정보 수집 및 이용목적에 동의합니다."
              checked={agree}
              onChangeChecked={() => {
                setAgree(!agree);
              }}
            />*/}

            <button
              className="btn_send"
              onClick={onClickSend}
              style={{
                backgroundColor: isViewTypeIpnow ? "#ffa600" : "#4593f5",
              }}
            >
              지금 바로 무료 상담 신청하기
            </button>
            <div className="contact_phone_mo">
              <div className="contact_text">
                <span>문의전화</span>
                <img
                  src={phone}
                  alt="phone"
                  style={{ width: 26, height: 21 }}
                />
              </div>
              <div className="contact_text_info">
                <div className="contact_text_info_left">
                  <p>Tel.</p>
                </div>
                <div className="contact_text_info_right">
                  <span
                    style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                  >
                    070-4249-1023
                  </span>
                  <p>월~금 09:00~18:00 주말 및 공휴일 휴무</p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact_info">
            <div className="contact_text">
              <span>이메일</span>
              <img
                src={envelope}
                alt="envelope"
                style={{ width: 30, height: 21 }}
              />
            </div>
            <div className="contact_text_info">
              <p>
                <span
                  style={{ color: isViewTypeIpnow ? "#ffa600" : "#4593f5" }}
                >
                  ipnow@ipnow.co.kr
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerService;
