import HeatMap from "./HeatMap";

const KeyValue = ({ datas = {}, getPatentBySmart }) => {
  const rawData = datas;
  const xLabels = Object.keys(rawData);

  const mappingLevel = (level) => {
    let levelStr = "";
    if (level === 10) {
      levelStr = "AAA";
    } else if (level === 9) {
      levelStr = "AA";
    } else if (level === 8) {
      levelStr = "A";
    } else if (level === 7) {
      levelStr = "BBB";
    } else if (level === 6) {
      levelStr = "BB";
    } else if (level === 5) {
      levelStr = "B";
    } else if (level === 4) {
      levelStr = "CCC";
    } else if (level === 3) {
      levelStr = "CC";
    } else if (level === 2) {
      levelStr = "C";
    } else if (level === 1) {
      levelStr = "N";
    }

    return levelStr;
  };

  if (xLabels.length === 0) {
    return <div id="KeyValue">No data available</div>;
  }

  const rawYLabels = [
    ...new Set(rawData[xLabels[0]].map((item) => item.sm_grade)),
  ].sort((a, b) => b - a);

  const yLabels = rawYLabels.map(mappingLevel);

  const data = rawYLabels.map((grade) => {
    return xLabels.map((year) => {
      const record = rawData[year].find((item) => item.sm_grade === grade);
      return record ? record.cnt : 0;
    });
  });

  return (
    <div id="KeyValue">
      <HeatMap
        data={data}
        xLabels={xLabels}
        yLabels={yLabels}
        clickEvent={getPatentBySmart}
      />
    </div>
  );
};

export default KeyValue;
