import React, {useState} from "react";
import "./css/CyberCompanyManualAdd.scss";
import CyberAPI from "../../API/cyber";

function CyberCompanyManualAdd({history}) {
    const [account, setAccount] = useState({
        name: "",
        representative: ""
    });

    const addCompany = () => {
        CyberAPI.addCompany(account).then(() => {
            alert("등록되었습니다");
            history.push("/cyber/add");
        });
    };

    return (
        <div id="CyberSignup">
            <h2 className="page_title">기업 수동 등록하기</h2>
            <div className="form">
                <div className="field">
                    <div className="head">
                        <h2>기업명*</h2>
                        <p>*필수입력항목</p>
                    </div>
                    <input type="text"
                           value={account.name}
                           onChange={e => setAccount({...account, name: e.target.value})}
                    />
                </div>
                <div className="field">
                    <div className="head">
                        <h2>대표자*</h2>
                    </div>
                    <input type="text"
                           value={account.representative}
                           onChange={e => setAccount({...account, representative: e.target.value})}
                    />
                </div>
            </div>
            <button className="btn" onClick={addCompany}>등록하기</button>
        </div>
    )
}

export default CyberCompanyManualAdd;