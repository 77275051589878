import React, {useState, useEffect, useContext} from "react";
import "./css/HomeInvestor.scss";
import InvestorAPI from "../../API/investor/index";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";
import SearchInterested from "./SearchInterested";
import CommonContext from "../../store/Common";

function HomeInvestor({history}) {
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [mTbody, setMTbody] = useState([]);
    const [data, setData] = useState({});
    const {isMobile} = useContext(CommonContext);
    const [moreList, setMoreList] = useState([]);
    const count = isMobile ? 10 : 100;

    useEffect(() => {
        let payload = {
            page: 1,
            count: count,
        };
        getList(payload);
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawMobile();
        }
    }, [moreList]);

    const getList = (payload, type) => {
        InvestorAPI.getRequestInvestmentList(payload).then(res => {
            let get_data = res.data;

            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
                setData(get_data);
            }
            setData(get_data);

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const drawTable = () => {
        if (data.items.length) {
            setTbody(
                data.items.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.req_amount}</td>
                            <td>{item.representative}</td>
                            <td>{item.license_number}</td>
                            <td>{item.company_phone}</td>
                            <td>{item.core_tech_desc}</td>
                            <td>{item.business_model_desc}</td>
                            <td>
                                <button className="icon_more_red" onClick={() => redirect(item.idx)}/>
                            </td>
                        </tr>
                    );
                })
            );
        } else {
            setTbody(window.$Global.notTd(9, '투자 요청 기업이 없습니다'));
        }
        setMoreList(new Array(data.items.length));
    };

    const drawMobile = () => {
        setMTbody(
            data.items.map((item, index) => {
                return (
                    <div className="item" key={index}>
                        <div className="head">
                            <p>{item.req_amount}</p>
                            <div className="head_more">
                                <h2>{item.core_tech_desc}</h2>
                            </div>
                            {
                                moreList[index]
                                    ?   <button className="icon_more_arrow_down_black" onClick={() => onClickToggle(index)}/>
                                    :   <button className="icon_more_arrow_up" onClick={() => onClickToggle(index)}/>
                            }
                        </div>
                        <div className={`content ${moreList[index] ? 'active' : ''}`}>
                            <div className="data">
                                <p className="title">회사명</p>
                                <p className="value">{item.company_name}</p>
                            </div>
                            <div className="data">
                                <p className="title">대표자</p>
                                <p className="value">{item.representative}</p>
                            </div>
                            <div className="data">
                                <p className="title">사업자 등록번호</p>
                                <p className="value">{item.license_number}</p>
                            </div>
                            <div className="data">
                                <p className="title">회사 전화번호</p>
                                <p className="value">{item.company_phone}</p>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    };

    const redirect = (idx) => {
        history.push(`/patentIntro/detail/${idx}`);
    };

    const nextList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getList(payload, "next");
    };

    const onClickToggle = (index) => {
        let copy = _.cloneDeep(moreList);
        copy[index] = !copy[index];
        setMoreList(copy);
    };

    return (
        <div id="HomeInvestor">
            <div className="wrap_head">
                {
                    isMobile
                    ? <p className="desc">상세내용은 PC를 통해 확인해주시기 바랍니다.</p>
                    :
                        <>
                            <h2 className="area_title">투자를 원하는 기업들입니다.</h2>
                            <div className="area_box">
                                <img src={require('../../assets/images/home/bg_investor.png').default} alt="이미지"/>
                            </div>
                        </>
                }
            </div>
            {
                isMobile &&
                <>
                    <div className="list">
                        {mTbody}
                    </div>
                    {
                        hasMore && <button className="btn" onClick={nextList}>더보기</button>
                    }
                </>
            }
            {
                !isMobile &&
                <>
                    <h2 className="table_title">투자 요청 기업 리스트</h2>
                    <div className="table-box-wrap">
                        <div className="wrap_list" id="scrollableDiv">
                            <InfiniteScroll
                                dataLength={tbody.length}
                                next={nextList}
                                hasMore={hasMore}
                                scrollableTarget="scrollableDiv">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>순번</th>
                                            <th>회사명</th>
                                            <th>투자 요청 금액</th>
                                            <th>대표자</th>
                                            <th>사업자 등록번호</th>
                                            <th>회사 전화번호</th>
                                            <th>핵심 특허기술</th>
                                            <th>비지니스모델소개</th>
                                            <th width="8%">상세보기</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tbody}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        </div>
                    </div>

                    <h2 className="area_title">기업기술역량 · 등록특허 등급</h2>
                    <SearchInterested/>
                </>
            }
        </div>
    );
}

export default HomeInvestor;
