import React, { useState } from "react";
import DatePickerCustom from "../../../../datepicker/DatePickerCustom";
import SALES_API from "../../../../../API/annualPay/sales";
import SharedStyles from "../../../css/sales/shared.module.scss";

const DepositeForm = ({ initialValues, onSubmit, onClose, university }) => {
  const [formData, setFormData] = useState(initialValues ?? {});
  const [loading, setloading] = useState(false);

  const handleDateInputsChange = (field, pickedDate) => {
    setFormData((prev) => ({
      ...prev,
      [field]: new Date(pickedDate).getTime(),
    }));
  };
  const handleTextInputsChange = (field, value) => setFormData((prevState) => ({ ...prevState, [field]: value }));

  const submitHandler = async (event) => {
    event.preventDefault();
    setloading(true);

    let result;
    const payload = {
      company_idx: university.company_idx,
      depositor: formData.depositor,
      depositAmount: formData.depositAmount,
      memo: formData.memo,
      occurrenceDate: window.$Global.convertDate(formData.occurrenceDate).substring(0, 7),
      collectionDate: window.$Global.convertDate(formData.collectionDate),
    }
    try {
      result = !initialValues.idx ? await SALES_API.uploadNewDeposit(payload) : await SALES_API.updateDeposit({ ...payload, idx: initialValues.idx });
      onSubmit({ status: "SUCCESS", type: "NEW", message: result.data.message });
    } catch (error) {
      if (error.response) {
        onSubmit({ status: "ERROR", type: "NEW", message: error.response.data.message });
      } else {
        onSubmit({ status: "ERROR", type: "NEW", message: error.message });
      }
    } finally {
      setloading(false);
    }
  };

  // Computed Values
  const requiredFields = ["occurrenceDate", "depositor", "collectionDate", "depositAmount"];
  const formDataIsValid = requiredFields.every((key) => formData[key]);

  return (
    <form onSubmit={submitHandler} className={SharedStyles["sales-form"]}>
      {loading && <span className={`spinner ${SharedStyles["spinner"]}`} />}

      <button disabled={loading} className={SharedStyles["close-btn"]} onClick={onClose} type="button">
        <i className="icon_exit" />
      </button>
      <h1>수금액 추가하기</h1>
      <div>
        <label htmlFor="university">발생기관</label>
        <input type="text" disabled value={university.name} />
      </div>
      <div>
        <label htmlFor="occurrenceDate">발생날짜*</label>
        <DatePickerCustom
          data={formData.occurrenceDate}
          onChangeDatePicker={(pickedDate) => handleDateInputsChange("occurrenceDate", pickedDate)}
        />
      </div>
      <div>
        <label htmlFor="depositor">입금자*</label>
        <input
          type="text"
          id="depositor"
          required
          value={formData.depositor}
          onChange={(event) => handleTextInputsChange("depositor", event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="collectionDate">수금날짜*</label>
        <DatePickerCustom
          data={formData.collectionDate}
          onChangeDatePicker={(pickedDate) => handleDateInputsChange("collectionDate", pickedDate)}
        />
      </div>
      <div>
        <label htmlFor="depositAmount">금액*</label>
        <input
          type="number"
          required
          id="depositAmount"
          value={formData.depositAmount}
          onChange={(event) => handleTextInputsChange("depositAmount", event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="memo">메모</label>
        <textarea
          rows={5}
          id="memo"
          value={formData.memo}
          onChange={(event) => handleTextInputsChange("memo", event.target.value)}
        ></textarea>
      </div>

      <button disabled={!formDataIsValid || loading} style={{ opacity: formDataIsValid ? 1 : 0.6 }} type="submit">
        저장
      </button>
    </form>
  );
};

export default DepositeForm;
