import React from "react";

const SaleRow = ({ data, className = "" }) => {
  const calculateRowTotal = (row) => row.reduce((total, monthNum) => total + monthNum);
  return ["total", ...data].map((monthNum, index) => (
    <div key={index} className={`num-cell ${className}`}>
      {index === 0 ? window.$Global.numberWithCommas(calculateRowTotal(data)) : window.$Global.numberWithCommas(monthNum)}
    </div>
  ));
};

const SaleFeeds = ({ feedRows, univName }) => {
  return (
    <>
      <div className="report-info">
        <h4>{univName}</h4>
        <p className="indent-1">-매출</p>
        <p className="indent-2">-연차료 납부대행</p>
        <div>
          <ul>
            <li>-관납료</li>
            <li>실비</li>
            <li>IPN 수수료</li>
            <li>-수수료 VAT</li>
          </ul>
        </div>
        <h3 className="indent-2">Package</h3>
        <p className="indent-1 text-blue">수금</p>
        <p className="indent-1 text-red">미수금</p>
      </div>
      {feedRows.map((feedRow, index) => (
        <SaleRow
          key={index}
          data={feedRow}
          className={index === 7 ? "text-blue" : index === 8 ? "text-red last-row" : index === 0 ? "first-row" : ""}
        />
      ))}
    </>
  );
};

export default SaleFeeds;
