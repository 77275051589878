import React, {useEffect, useState} from 'react';
import CheckBox from "../common/CheckBox";
import './css/PopupPatentSelect.scss';
import PatentAPI from "../../API/patent";

function PopupPatentSelect({onClickClose, onClickSuccess}) {
    const [applyPatentList, setApplyPatentList] = useState(null); // 선출원특허 리스트
    const [checkedList, setCheckedList] = useState([]);
    
    useEffect(() => {
        getFirstApplyPatent(1);
    }, []);

    const getFirstApplyPatent = (option) => {
        let payload = {
            page: 1,
            count: 0,
            option: option
        };
        PatentAPI.getFirstApplyPatent(payload).then(res => setApplyPatentList(res.data.items));
    };

    const toggleCheckbox = (idx) => {
        let changeList = checkedList.filter(item => {
                item = false;
                return item;
        });
        changeList[idx] = !changeList[idx];
        setCheckedList(changeList);
    };

    const onClickSelectPatent = () => {
        let selected_idx = checkedList.findIndex(item=> item);

        if (!applyPatentList[selected_idx]) {
            alert("특허를 선택해주세요");
            return;
        }
        onClickSuccess(applyPatentList[selected_idx]);
    };

    if (!applyPatentList) return null;

    let tbody = applyPatentList.map((item, idx) => {

        return (
            <tr key={idx} className={checkedList[idx] ? 'active' : ''}>
                <td>
                    <CheckBox checked={checkedList[idx]} onChangeChecked={() => toggleCheckbox(idx)}/>
                </td>
                <td>{item.manage_number}</td>
                <td>{item.apply_number}</td>
                <td>{window.$Global.convertNationCodeToKoreanStr(item.nation)}</td>
                <td>{window.$Global.convertDate(item.apply_at)}</td>
                <td>{item.invention_name}</td>
            </tr>
        );
    });

    return (
        <div id="PopupPatentSelect">
            <div className="header">
                <h2>선출원 번호 선택</h2>
                <div className="btns">
                    <button className="btn_success" onClick={onClickSelectPatent}>완료</button>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
            </div>
            <div className="row">
                <h2>기간 설정</h2>
                <select onChange={(e) => getFirstApplyPatent(e.target.value)}>
                    <option value={1}>출원 후 1년 이내 특허</option>
                    <option value={0}>모든 특허</option>
                </select>
            </div>
            <div className="row">
                <h2>선출원 선택</h2>
                <div className="wrap_table">
                    <table>
                        <thead>
                            <tr>
                                <th>선택</th>
                                <th>관리번호</th>
                                <th>출원번호</th>
                                <th>국가</th>
                                <th>출원일</th>
                                <th>발명의 명칭</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PopupPatentSelect;
