import { useEffect, useState, useCallback } from "react";
import PatentAPI from "../../../API/patent";
import { debounce } from "lodash";
import "../css/IpcStatistic.scss";

const PatentList = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [rowData, setRowData] = useState([]);

  const getPatentForCandidate = async (newPage, initialLoad = false) => {
    try {
      const res = await PatentAPI.getPatentForCandidate({
        page: newPage,
        count: count,
      });
      if (res.data?.candidatePatent?.length > 0) {
        if (initialLoad) {
          setRowData(res.data.candidatePatent);
        } else {
          setRowData((prevRowData) => [
            ...prevRowData,
            ...res.data.candidatePatent,
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching patent data:", error);
    }
  };

  const handleScroll = useCallback(
    debounce((event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setPage((prevPage) => prevPage + 1);
      }
    }, 200),
    []
  );

  useEffect(() => {
    getPatentForCandidate(1, true); // 첫 로딩 시 초기 데이터 가져오기
  }, []);

  useEffect(() => {
    if (page > 1) {
      getPatentForCandidate(page);
    }
  }, [page]);

  return (
    <div
      id="PatentList"
      onScroll={handleScroll}
      style={{ height: 400, overflowY: "scroll" }}
    >
      <table className="patent-table">
        <thead>
          <tr>
            <th className="grid-header" style={{ width: "140px" }}>
              출원번호
            </th>
            <th className="grid-header" style={{ width: "100px" }}>
              출원일
            </th>
            <th className="grid-header" style={{ width: "800px" }}>
              발명의 명칭
            </th>
            <th className="grid-header">Main IPC</th>
            <th className="grid-header" style={{ width: "300px" }}>
              기술명
            </th>
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, index) => (
            <tr key={index}>
              <td className="grid-cell">{row.apply_number}</td>
              <td className="grid-cell">{row.apply_at}</td>
              <td className="grid-cell">{row.invention_name}</td>
              <td className="grid-cell">{row.ipc}</td>
              <td className="grid-cell">{row.keywords}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatentList;
