import AdminAPI from "../../API/admin";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const MailingIsPaid = ({ location }) => {
  //isPaid
  const history = useHistory();
  const q = location.search.split("=")[1];
  const changedQ = q.replace(/&/g, "&").replace(/\+/g, "+");
  const payload = { aesp: changedQ }; // API 호출 시 전송할 데이터

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await AdminAPI.isPaid(payload); // Call AdminAPI method

    if (response?.data?.check === false) {
      alert("회신기한이 지난 메일입니다.");
      history.push("/");
      return;
    }

    if (response?.data?.responseAlready) {
      alert("이미 응답한 메일입니다.");
      history.push("/");
      return;
    }

    if (response?.data?.validFormat) {
      history.push({
        pathname: "/sendMail/birthday",
        state: { response: response.data },
      });
    } else {
      alert("Error occurred!");
    }
  };

  return <div></div>;
};

export default MailingIsPaid;
