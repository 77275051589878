import React from "react";
import "./css/DocTradeSampleRegisterFullRow.scss";

function DocTradeSampleRegisterFullRow({data, category, onChangeInput, onClickDelete}) {
    return (
        <>
            {
                data.map((item, idx) => {
                    return (
                        <tr key={idx} className="informaiton_row">
                            <td className="informaiton_num-data">{idx + 1}</td>
                            <td className="informaiton_title-data">
                                <label htmlFor="informationTitle" className="hidden">제목</label>
                                <input type="text"
                                       name="informationTitle"
                                       id="informationTitle"
                                       className="informaiton_title-data_input"
                                       placeholder="구체적으로 기재하시기 바랍니다. &#40;한글, 영문 포함 최대 200글자&#41;"
                                       value={item.title}
                                       onChange={e => onChangeInput(idx, "title", e.target.value)}
                                />
                            </td>
                            <td className="informaiton_payment-data">
                                <label htmlFor="priceNum" className="hidden">판매가격</label>
                                <input type="text"
                                       name="priceNum"
                                       id="priceNum"
                                       className="informaiton_payment-data_input"
                                       value={window.$Global.commaify(item.price) || 0}
                                       onChange={e => onChangeInput(idx, "price", window.$Global.numberRegx(e.target.value))}
                                />
                                <span className="informaiton_payment-data_text">원</span>
                            </td>
                            <td className="informaiton_dataClass-data">
                                <select name="dataClassSel" id="dataClassSel" className="dataClassSel" onChange={e => onChangeInput(idx, "category_idx1", e.target.value)}>
                                    {
                                        category.map(item => <option key={item.idx} className="dataClassOption" value={item.idx}>{item.name}</option>)
                                    }
                                </select>
                            </td>
                            <td className="informaiton_introduce-data">
                                <label htmlFor="introduceTextarea" className="hidden">소개글 텍스트 입력란</label>
                                <input type="text"
                                       name="introduceTextarea"
                                       id="introduceTextarea"
                                       className="input"
                                       placeholder="자료에 대한 소개글을 입력해주세요. &#40;1,000자 내외&#41;"
                                       value={item.content}
                                       onChange={e => onChangeInput(idx, "content", e.target.value)}
                                />
                            </td>
                            <td className="informaiton_del">
                                <button className="informaiton_del_btn" onClick={() => onClickDelete(idx)}>
                                    <span className="icon_badge_del"></span>
                                </button>
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export default DocTradeSampleRegisterFullRow;