import React from "react";
import CheckBox from "../common/CheckBox";
import "./css/MarketInfoCartItem.scss";

function MarketInfoCartItem({index, item, hideCheckBox, checked, showPopup, onClickImageDelete, onClickCheck}) {
	const notUserUploadImage = !item.title; // 유저가 업로드한 이미지가 아닌 경우 참

	return (
		<li className="MarketInfoCartItem">
			{
				notUserUploadImage && <button className="icon_badge_del ir_txt" onClick={() => onClickImageDelete(item.idx)}>삭제하기</button>
			}
			<img src={window.$Global.getCDN(item.image_key)} alt="이미지" onContextMenu={window.$Global.blockRightClick}/>
			<h2>{item.title}</h2>
			<p>{window.$Global.convertDate(item.made_at, ' ')}</p>
			<div className="info">
				{
					!notUserUploadImage
					&&
					<p onClick={() => showPopup(item.idx, "graph")}>
						<i className="icon_alert_circle"/>
						<span>자세히</span>
					</p>
				}
				{
					!hideCheckBox && <CheckBox text="선택하기" checked={checked} onChangeChecked={e => onClickCheck(e, item, index)}/>
				}
			</div>
		</li>
	)
}

export default MarketInfoCartItem;