import React, {useContext} from "react";
import "./terms.scss";
import Terms from "../../const/Terms";

function TermsPayment({history}) {
    const redirect = () => {
        history.go(-1);
    };

    return (
        <div id="Terms">
            <h2>
                IPNOW 결제 취소 및 환불 규정
            </h2>
            <p className="custom_scroll" dangerouslySetInnerHTML={ {__html: Terms.paymentTxt} }/>
            <button className="btn_confirm" onClick={redirect}>확인</button>
        </div>
    )
}

export default TermsPayment;
