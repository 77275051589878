// toast
import { toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import config from "../const/config";
import companyType from "../types/companyType";
import { Redirect, useHistory } from "react-router-dom";
import qs from "query-string";
import {
  nationStrType,
  nationType,
  tradeMarkCountryKr,
  tradeMarkNationKr,
  tradeMarkNationType,
} from "../types/nationType";
import axios from "axios";
import links from "../const/links";
import GwAPI from "../API/groupware";
import tradeMark from "../store/TradeMark";
import * as Clipboard from "clipboard-polyfill";
import { ClipboardItem } from "clipboard-polyfill";
import CommonAPI from "../API/common";

let { kakao } = window;

export const kr_index = [
  "가",
  "나",
  "다",
  "라",
  "마",
  "바",
  "사",
  "아",
  "자",
  "차",
  "카",
  "타",
  "파",
  "하",
];

export default {
  getGroupwareAdminMenu(setAdminMenu, bean) {
    let param = {
      su_menu_cd: bean.su_menu_cd,
      menu_cd: bean.menu_cd,
    };
    let resultVal = [];
    GwAPI.getAdminMenuList(param).then((res) => {
      let resultData = res.data.dataMenuMap;
      let result = resultData.menuList;
      let resultItems = [];
      if (result.length > 0) {
        // 1단계: lookup 객체 생성
        let lookup = {};
        let defaultItem = {
          name: bean.menuTitle,
          isOpen: bean.isOpen,
          children: [],
          suMenuCd: param.su_menu_cd,
        };
        resultItems = [defaultItem];
        result.forEach((item) => {
          lookup[item.menu_cd] = { ...item }; // item 복사
          lookup[item.menu_cd].name = item.menu_name;
          lookup[item.menu_cd].url = item.link_path;
          lookup[item.menu_cd].isOpen = bean.isOpen;
          lookup[item.menu_cd].isContentWrap = bean.isContentWrap;
          lookup[item.menu_cd].suMenuCd = param.su_menu_cd;
          lookup[item.menu_cd].menuTitle = bean.menuTitle;
        });

        // 2단계: 부모-자식 관계 만들기
        let rootItems = [];
        result.forEach((item) => {
          if (item.su_menu_cd in lookup) {
            // 부모 노드가 있으면, 자식 배열에 추가합니다.
            if (lookup[item.su_menu_cd].children === undefined) {
              lookup[item.su_menu_cd].children = [];
            }
            lookup[item.su_menu_cd].children.push(lookup[item.menu_cd]);
          } else {
            // 부모 노드가 없으면, 루트 노드입니다.
            rootItems.push(lookup[item.menu_cd]);
            resultItems[0].children.push(lookup[item.menu_cd]);
          }
        });

        resultVal = resultItems;
        setAdminMenu(resultItems);
      }
    });
  },
  getGroupwareMenu(setGwMenu, bean) {
    let param = {
      su_menu_cd: bean.su_menu_cd,
    };
    let resultVal = [];
    GwAPI.getMenuList(param).then((res) => {
      let resultData = res.data.dataMenuMap;
      let result = resultData.menuList;
      let resultItems = [];
      if (result.length > 0) {
        // 1단계: lookup 객체 생성
        let lookup = {};
        let defaultItem = {
          name: bean.menuTitle,
          isOpen: bean.isOpen,
          children: [],
          suMenuCd: param.su_menu_cd,
        };
        resultItems = [defaultItem];
        result.forEach((item) => {
          lookup[item.menu_cd] = { ...item }; // item 복사
          lookup[item.menu_cd].name = item.menu_name;
          lookup[item.menu_cd].url = item.link_path;
          lookup[item.menu_cd].isOpen = bean.isOpen;
          lookup[item.menu_cd].isContentWrap = bean.isContentWrap;
          lookup[item.menu_cd].suMenuCd = param.su_menu_cd;
          lookup[item.menu_cd].menuTitle = bean.menuTitle;
        });

        // 2단계: 부모-자식 관계 만들기
        let rootItems = [];
        result.forEach((item) => {
          if (item.su_menu_cd in lookup) {
            // 부모 노드가 있으면, 자식 배열에 추가합니다.
            if (lookup[item.su_menu_cd].children === undefined) {
              lookup[item.su_menu_cd].children = [];
            }
            lookup[item.su_menu_cd].children.push(lookup[item.menu_cd]);
          } else {
            // 부모 노드가 없으면, 루트 노드입니다.
            rootItems.push(lookup[item.menu_cd]);
            resultItems[0].children.push(lookup[item.menu_cd]);
          }
        });

        resultVal = resultItems;
        setGwMenu(resultItems);
      }
    });
  },
  getGroupwareSrcPath(command, docType = "", section = "attend") {
    window.parent.location.href =
      "/groupware/works?section=" +
      section +
      "&command=" +
      command +
      "&doc_type=" +
      docType;
    // if(path === 'attend'){
    //   window.parent.location.href = path + "?doc_type=" + docType;
    // }else if(path === 'vacation'){
    //   path = 'works';
    //   window.parent.location.href = path + "?mode=vacation&doc_type=" + docType;
    // }else{
    //   window.parent.location.href = path;
    // }
  },
  docView(item, lstType) {
    let strParam = "";
    let listMode = "";
    let path = "/groupware/works?section=docView&command=viewDoc";
    strParam += "&idx=" + item.idx;
    strParam += "&doc_knd=" + item.table_name;
    strParam += "&doc_status=" + item.status;
    strParam += "&doc_status_name=" + item.status_name;
    if (lstType == "myList") {
      listMode = "myList";
    }
    strParam += "&list_mode=" + listMode;
    if (item.table_name == "dt_document_worktime") {
      strParam += "&lstPart=doc";
    } else {
      strParam += "&lstPart=" + item.lstPart;
    }
    //alert(strParam);
    window.parent.location.href = path + strParam;
  },
  commandView(item, lstType) {
    let strParam = "";
    let listMode = "";
    let path = "/groupware/works?section=command&command=showCommandOrder";
    strParam += "&idx=" + item.idx;
    strParam += "&method=" + lstType;
    //alert(strParam);
    window.parent.location.href = path + strParam;
  },
  schedulerView(item, lstType) {
    let strParam = "";
    let listMode = "";
    let path = "/groupware/schedule";
    strParam += "?id=" + item.scheduleId;
    window.parent.location.href = path + strParam;
  },
  boardView(item, lstType) {
    let strParam = "";
    let listMode = "";
    let path = "/groupware/works?section=board&command=view";
    strParam += "&boardno=101&pid=" + item.pid;
    window.parent.location.href = path + strParam;
  },

  getCDN(url) {
    return url
      ? url.indexOf("http") > -1
        ? url
        : `https://d1k4pifav5a1we.cloudfront.net/${url}`
      : require("../assets/images/common/img_news.png").default;
  },

  redirectHome(auth) {
    if (auth > -1) {
      window.location.href = "/";
    } else {
      window.location.href = "/intro/main";
    }
  },

  convertDate(date, defaultValue) {
    if (!date) return defaultValue || "";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  },
  convertDateUseNA(date, defaultValue) {
    if (!date) return defaultValue || "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  },

  convertDateNotChar(date, defaultValue) {
    if (!date) return defaultValue || "";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      date.getDate().toString().padStart(2, "0")
    );
  },

  convertDateKor(date, defaultValue) {
    if (!date) return defaultValue || "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "년 " +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "월 " +
      date.getDate().toString().padStart(2, "0") +
      "일"
    );
  },

  convertDateDot(date, defaultValue) {
    if (!date) return defaultValue || "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "." +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "." +
      date.getDate().toString().padStart(2, "0")
    );
  },

  convertMonthDot(date, defaultValue) {
    if (!date) return defaultValue || "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "." +
      (date.getMonth() + 1).toString().padStart(2, "0")
    );
  },
  convertDateTime(date) {
    if (!date) return "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      " " +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
    );
  },
  convertDateTimeSlash(date) {
    if (!date) return "N/A";
    date = new Date(Number(date));
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      date.getDate().toString().padStart(2, "0") +
      " " +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0")
    );
  },
  convertDateTimeSecond(date) {
    if (!date) return "N/A";
    date = new Date(Number(date));
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      " " +
      date.getHours().toString().padStart(2, "0") +
      ":" +
      date.getMinutes().toString().padStart(2, "0") +
      ":" +
      date.getSeconds().toString().padStart(2, "0")
    );
  },

  covertLocalDateTime(date) {
    if (!date) return "N/A";
    date = new Date(date);
    return `${date.getFullYear()}-${`${date.getMonth() + 1}`.padStart(
      2,
      "0"
    )}-${`${date.getDate()}`.padStart(2, "0")}T${`${date.getHours()}`.padStart(
      2,
      "0"
    )}:${`${date.getMinutes()}`.padStart(2, "0")}`;
  },

  /*존속기간만료일을 계산하는 함수
  존속기간 만료일은 등록일의 + 10년, 갱신등록시 추가로 + 10년입니다.*/
  convertRegistAtPlusDate(date, length) {
    if (!date) return "N/A";

    date = new Date(date);

    if (length === 0) {
      date.setFullYear(date.getFullYear() + 10);
    } else {
      /*date.setFullYear(date.getFullYear() + 10 * (length + 1));  갱신등록시 추가로 + 10년*/
      date.setFullYear(date.getFullYear() + 10);
    }

    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  },
  isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  },

  convertTypeToText(type) {
    let str = "";
    switch (type) {
      case 0:
        str = "원출원";
        break;
      case 1:
        str = "분할";
        break;
      case 2:
        str = "변경";
        break;
      case 3:
        str = "국내우선권";
        break;
      case 4:
        str = "CA";
        break;
      case 5:
        str = "DA";
        break;
      case 6:
        str = "CIP";
        break;
      case 7:
        str = "재출원";
        break;
    }
    return str;
  },

  convertDesignStateToText(state) {
    let str = "";
    switch (state) {
      case 0:
        str = "디자인 창작물 접수";
        break;
      case 1:
        str = "초안 작성 요청";
        break;
      case 2:
        str = "초안 검토 대기";
        break;
      case 3:
        str = "초안 수정본 전달";
        break;
      case 4:
        str = "초안 최종본 검토 대기";
        break;
      case 5:
        str = "출원 지시";
        break;
      case 6:
        str = "출원 완료";
        break;
      case 7:
        str = "심사 청구 요청 상태";
        break;
      case 8:
        str = "등록 결정 상태 (NOA)";
        break;
      case 9:
        str = "등록 완료 상태";
        break;
      case 10:
        str = "심사 청구 거절 이유 (OA)";
        break;
      case 11:
        str = "oa 대응안 검토 대기";
        break;
      case 12:
        str = "oa 대응안 수정본 업로드";
        break;
      case 13:
        str = "oa 대응한 최종본 접수";
        break;
      case 14:
        str = "oa 초안 작성 지시";
        break;
      case 15:
        str = "oa 초안 접수";
        break;
      case 16:
        str = "oa 초안 수정본 전달";
        break;
      case 17:
        str = "oa 초안 최종본 접수";
        break;
      case 18:
        str = "oa 제출 지시";
        break;
      case 19:
        str = "번역문 초안 작성 지시";
        break;
      case 20:
        str = "번역문 초안 검토 대기";
        break;
      case 21:
        str = "번역문 초안 수정본 전달";
        break;
      case 22:
        str = "번역문 최종본 검토 대기";
        break;
      case 99:
        str = "종료";
        break;
    }
    return str;
  },

  convertStateToText(state) {
    let str = "";
    switch (state) {
      case 0:
        str = "발명 신고서 접수";
        break;
      case 1:
        str = "선행기술 조사 신청 상태";
        break;
      case 2:
        str = "선행기술 조사 완료";
        break;
      case 3:
        str = "초안 작성 요청";
        break;
      case 4:
        str = "초안 검토 대기";
        break;
      case 5:
        str = "초안 수정본 전달";
        break;
      case 6:
        str = "초안 최종본 검토 대기";
        break;
      case 7:
        str = "출원 지시";
        break;
      case 8:
        str = "출원 완료";
        break;
      case 9:
        str = "심사중";
        break;
      case 10:
        str = "등록 결정 상태 (NOA)";
        break;
      case 11:
        str = "분할 출원 상태";
        break;
      case 12:
        str = "등록 완료 상태";
        break;
      case 13:
        str = "심사 청구 거절 이유(OA)";
        break;
      case 14:
        str = "oa 대응안 검토 대기";
        break;
      case 15:
        str = "oa 대응안 수정본 업로드";
        break;
      case 16:
        str = "oa 대응한 최종본 접수";
        break;
      case 17:
        str = "oa 초안 작성 지시";
        break;
      case 18:
        str = "oa 초안 접수";
        break;
      case 19:
        str = "oa 초안 수정본 전달";
        break;
      case 20:
        str = "oa 초안 최종본 접수";
        break;
      case 21:
        str = "oa 제출 지시";
        break;
      case 22:
        str = "번역문 초안 작성 지시";
        break;
      case 23:
        str = "번역문 초안 검토 대기";
        break;
      case 24:
        str = "번역문 초안 수정본 전달";
        break;
      case 25:
        str = "번역문 최종본 검토 대기";
        break;
      case 26:
        str = "우선심사청구 요청";
        break;
      case 99:
        str = "종료";
        break;
    }
    return str;
  },

  convertInventionStateToStr(state) {
    let str;
    switch (state) {
      case 0:
        str = "발명신고서 접수";
        break;
      case 1:
        str = "사건등록";
        break;
      case 2:
        str = "종료";
        break;
      case 3:
        str = "폐기";
        break;
      default:
        break;
    }
    return str;
  },
  // rank
  // 기업
  // 0 : 경영진
  // 1 : 관리자
  // 2 : 발명자
  // 9 : 슈퍼 계정
  // 특허사무소
  // 10 : 대표
  // 11 : 관리자
  // 12 : 담당자
  // 19 : 슈퍼 계정
  // 투자자
  // 29 : 투자자
  checkAuth(type, auth) {
    switch (type) {
      case "모두":
        return true;
      case "특허회사":
        return auth < 10;
      case "경영진":
        return auth == 0;
      case "특허회사_관리자":
        return auth == 0 || auth == 1 || auth == 9;
      case "특허회사_발명자만":
        return auth == 2;
      case "대리인":
        return 20 > auth && auth >= 10;
      case "대리인_관리자":
        return auth == 10 || auth == 11 || auth == 19;
      case "대리인_담당자":
        return auth == 10 || auth == 11 || auth == 12;
      case "대리인_담당자만":
        return auth == 12;
      case "투자자":
        return auth == 29;
      case "슈퍼계정":
        return auth == 9 || auth == 19;
    }
  },

  convertAccountType(type) {
    switch (Number(type)) {
      case companyType.comapny:
        return this.isIpnow() ? "법인기업 · 대학 · 연구소" : "기업";
        break;
      case companyType.agent:
        return "특허사무소";
      case companyType.invester:
        return "투자자";
      case companyType.person:
        return "개인";
    }
  },

  download(url, payload, type, setIsLoading,setDownloadComplete) {
    let req = new XMLHttpRequest();
    req.open("POST", config.url + `/api/v1/${url}`, true);
    req.setRequestHeader("X-token", window.$Global.getToken());
    if (type === 1) {
      req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    } else {
      req.setRequestHeader("Content-Type", "text/json;charset=utf8");
    }
    req.responseType = "blob";

    req.onload = function () {
      if (this.status === 200) {

        if(payload && payload.excel===1){
          alert("다운로드 완료");
          setIsLoading(false);
          setDownloadComplete(false); 
        }
        let blob = this.response;
        let filename = "";
        let disposition = req.getResponseHeader("Content-Disposition");

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let URL = window.URL || window.webkitURL;
          let downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              // a.target = "_blank";
              a.download = decodeURIComponent(filename);
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }
          
          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      } else {
        let blob = this.response,
          fr = new FileReader();

        fr.onload = function () {
          alert(JSON.parse(this.result).message);
        };

        fr.readAsText(blob);
      }
    };

    if (payload !== undefined) {
      let queryStr = "";
      for (const [key, value] of Object.entries(payload)) {
        queryStr += key + "=" + value + "&";
      }
      req.send(queryStr.slice(0, -1));
    } else {
      req.send(JSON.stringify(payload));
    }
  },

  async downloadViaFetch(url, payload, type) {
    const apiUrl = `${config.url}/api/v1/${url}`;
    const token = window.$Global.getToken();

    let headers = new Headers({
      'X-token': token,
      'Content-Type': type === 1 ? 'application/x-www-form-urlencoded' : 'application/json'
    });

    let body;
    if (payload) {
      body = type === 1
        ? new URLSearchParams(payload).toString()
        : JSON.stringify(payload);
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: body
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(JSON.parse(errorText).message);
      }

      const blob = await response.blob();
      const disposition = response.headers.get('Content-Disposition');
      let filename = 'download';

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      if (window.navigator.msSaveBlob) {
        // IE workaround
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = decodeURIComponent(filename);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Download failed:', error);
      alert(error.message);
    }
  },

  extractTextFromHTML(html) {
    // Create a temporary element to parse the HTML
    const temp = document.createElement('div');
    temp.innerHTML = html;

    // Function to recursively extract text from nodes
    function extractTextFromNode(node) {
      let text = '';

      if (node.nodeType === Node.TEXT_NODE) {
        // If it's a text node, just return its content
        return node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // If it's an element node, handle special cases and recurse
        const tagName = node.tagName.toLowerCase();

        // Add newlines for block-level elements
        if (['div', 'p', 'br', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li'].includes(tagName)) {
          text += '\n';
        }

        // Recurse through child nodes
        for (let child of node.childNodes) {
          text += extractTextFromNode(child);
        }

        // Add another newline after certain block elements
        if (['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol'].includes(tagName)) {
          text += '\n';
        }
      }

      return text;
    }

    // Extract text from the temporary element
    let extractedText = extractTextFromNode(temp);

    // Clean up extra whitespace
    extractedText = extractedText.replace(/\n{3,}/g, '\n\n').trim();

    return extractedText;
  },


  download_regist(url, payload, type) {
    let req = new XMLHttpRequest();
    req.open("POST", "https://www.ipnow.co.kr" + `/api/v1/${url}`, true);
    req.setRequestHeader(
      "X-token",
      "bfac6afde610fd90fa306c69a87fc1f8:f03392bad3950169e1ad3037a91b3b0186cf4241c1a9a09054afccbcbe4707ab14f9a81a4f41ad5a5c9569edd971d7ec9e7c7c7b7f29282893bb793853209e515dfbea8ba3c9ad5bc72ddf2b26648a798b8de3fbac99d7a507e09130b9dcc34633d87d1c6f4ce706bb11a8720e2803611d160c27f2456d5e682941d14c6d1c1d493abf32470decea1692f26430db3f043a23093137eee700f5149abfc5f580681fc2396d5462a2e1e17e5727e3bc3f6c584d153ff261e79dc3dec8ad39c5eea8ea4ff1c0d1f9d468ef154cb45c4cac771a9faa44d0745463c613a1c486a99793c74e674d01efd1b21d040ed8ccd13abee119ea95fb60a323aff839f0c35e0b5e7874115e958de3276b979b90c12ff30e4827a5b6149978c9bf54ca6fe25da03c"
    );
    if (type === 1) {
      req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    } else {
      req.setRequestHeader("Content-Type", "text/json;charset=utf8");
    }
    req.responseType = "blob";

    req.onload = function () {
      if (this.status === 200) {
        let blob = this.response;
        let filename = "";
        let disposition = req.getResponseHeader("Content-Disposition");

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let URL = window.URL || window.webkitURL;
          let downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              // a.target = "_blank";
              a.download = decodeURIComponent(filename);
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      } else {
        let blob = this.response,
          fr = new FileReader();

        fr.onload = function () {
          alert(JSON.parse(this.result).message);
        };

        fr.readAsText(blob);
      }
    };

    if (payload !== undefined) {
      let queryStr = "";
      for (const [key, value] of Object.entries(payload)) {
        queryStr += key + "=" + value + "&";
      }
      req.send(queryStr.slice(0, -1));
    } else {
      req.send(JSON.stringify(payload));
    }
  },

  download_get(url, payload, type) {
    let req = new XMLHttpRequest();
    let queryStr = Object.entries(payload.params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    req.open("GET", config.url + `/api/v1/${url}?${queryStr}`, true);
    req.setRequestHeader("X-token", window.$Global.getToken());
    if (type === 1) {
      req.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    } else {
      req.setRequestHeader("Content-Type", "text/json;charset=utf8");
    }
    req.responseType = "blob";

    req.onload = function () {
      if (this.status === 200) {
        let blob = this.response;
        let filename = "";
        let disposition = req.getResponseHeader("Content-Disposition");

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let URL = window.URL || window.webkitURL;
          let downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              // a.target = "_blank";
              a.download = decodeURIComponent(filename);
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      } else {
        let blob = this.response,
          fr = new FileReader();

        fr.onload = function () {
          alert(JSON.parse(this.result).message);
        };

        fr.readAsText(blob);
      }
    };

    if (payload !== undefined) {
      let queryStr = "";
      for (const [key, value] of Object.entries(payload)) {
        queryStr += key + "=" + value + "&";
      }
      req.send(queryStr.slice(0, -1));
    } else {
      req.send(JSON.stringify(payload));
    }
  },

  newDownload(url, payload) {
    let req = new XMLHttpRequest();
    req.open("POST", config.url + `/api/v1/${url}`, true);
    req.setRequestHeader("X-token", window.$Global.getToken());
    req.setRequestHeader("Content-Type", "application/json;charset=utf8");
    req.responseType = "blob";

    req.onload = function () {
      if (this.status === 200) {
        let blob = this.response;
        let filename = "";
        let disposition = req.getResponseHeader("Content-Disposition");

        if (disposition && disposition.indexOf("attachment") !== -1) {
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          let URL = window.URL || window.webkitURL;
          let downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            let a = document.createElement("a");
            if (typeof a.download === "undefined") {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = decodeURIComponent(filename);
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl);
          }, 100); // cleanup
        }
      } else {
        let blob = this.response,
          fr = new FileReader();

        fr.onload = function () {
          alert(JSON.parse(this.result).message);
        };

        fr.readAsText(blob);
      }
    };

    req.send(JSON.stringify(payload));
  },
  async fileDownloadSync(url, payload) {
    const conf = {
      method: "POST",
      url: config.url + `/api/v1/${url}`,
      headers: {
        ContentType: "application/json",
        "x-token": sessionStorage.getItem("token"),
      },
      responseType: "blob",
      data: payload,
    };

    let response;
    try {
      response = await axios(conf);
    } catch (e) {
      const status = e.response.status;
      if (status === 400) {
        alert("올바르지 않은 파라미터 업로드");
      } else if (status === 700) {
        alert("특허 조회에 실패했습니다.");
      }
      return;
    }
    const name = decodeURI(
      response.headers["content-disposition"]
        .split("filename=")[1]
        .replace(/"/g, "")
    );
    const uri = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = uri;
    link.setAttribute("download", name);
    link.style.cssText = "display:none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  },

  dataDownload(res) {
    let req = res.headers;
    let blob = res.data;
    let filename = "";

    let disposition = req["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1])
        filename = matches[1].replace(/['"]/g, "");
    }

    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      let URL = window.URL || window.webkitURL;
      let downloadUrl = URL.createObjectURL(new Blob([blob]));

      if (filename) {
        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = decodeURIComponent(filename);
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location.href = downloadUrl;
      }

      setTimeout(function () {
        URL.revokeObjectURL(downloadUrl);
      }, 100); // cleanup
    }
  },

  convertNationCodeToStr(no) {
    return nationType.find((item, idx) => {
      let result = "";
      if (no === idx) {
        result = item;
      }
      return result;
    });
  },

  convertTradeMarkNationCodeToStr(no) {
    return Object.keys(tradeMarkNationType).find((item, idx) => {
      let result = "";
      if (no === idx) {
        result = item;
      }
      return result === "EP" ? "EU" : result;
    });
  },

  convertChildNationCodeToStr(no) {
    switch (no) {
      case 0:
        return "AL";
      case 1:
        return "AT";
      case 2:
        return "BE";
      case 3:
        return "BG";
      case 4:
        return "CH";
      case 5:
        return "CY";
      case 6:
        return "CZ";
      case 7:
        return "DE";
      case 8:
        return "DK";
      case 9:
        return "EE";
      case 10:
        return "ES";
      case 11:
        return "FI";
      case 12:
        return "FR";
      case 13:
        return "GB";
      case 14:
        return "GR";
      case 15:
        return "HR";
      case 16:
        return "HU";
      case 17:
        return "IE";
      case 18:
        return "IS";
      case 19:
        return "IT";
      case 20:
        return "LI";
      case 21:
        return "LT";
      case 22:
        return "LU";
      case 23:
        return "LV";
      case 24:
        return "MC";
      case 25:
        return "MK";
      case 26:
        return "MT";
      case 27:
        return "NL";
      case 28:
        return "NO";
      case 29:
        return "PL";
      case 30:
        return "PT";
      case 31:
        return "RO";
      case 32:
        return "RS";
      case 33:
        return "SE";
      case 34:
        return "SI";
      case 35:
        return "SK";
      case 36:
        return "SM";
      case 37:
        return "TR";
      case 38:
        return "SG";
    }
  },

  convertNationCodeToKoreanStr(no) {
    return nationStrType.find((item, idx) => {
      let result = "";
      if (no === idx) result = item;
      return result;
    });
  },

  convertEPNationCodeToKoreanStr(no) {
    switch (no) {
      case 7:
        return "독일";
      case 13:
        return "영국";
    }
  },

  convertTradeMarkStateCodeToStr(no) {
    switch (no) {
      case 0:
        return "준비중";
      case 1:
        return "선행상표조사";
      case 2:
        return "선행상표조사 완료";
      case 3:
        return "출원신청";
      case 4:
        return "출원완료";
      case 5:
        return "등록신청";
      case 6:
        return "등록결정";
      case 7:
        return "등록완료";
      case 8:
        return "oa";
      case 9:
        return "oa의견 전달 받은 상태";
      case 10:
        return "oa제출 요청상태";
      case 11:
        return "이의신청 상태";
      case 12:
        return "이의신청 의견서 접수 상태";
      case 13:
        return "갱신 신청 상태";
      case 14:
        return "갱신 요청 상태";
      case 80:
        return "공고";
      case 98:
        return "거절(미확정)";
      case 99:
        return "종료";
    }
  },

  parseJsonArray(arr) {
    if (!arr) return;
    return arr.filter((item) => {
      item.type = "account";
      item.password = "****";
      delete item.company_idx;
      delete item.company_name;
      delete item.created_at;
      delete item.rank;
      return item;
    });
  },

  setWeekendColor(date) {
    return date.getDay() === 0 || date.getDay() === 6 ? "color_red" : "";
  },

  cloneObject(obj) {
    let clone = {};
    for (var key in obj) {
      if (typeof obj[key] == "object" && obj[key] != null) {
        clone[key] = this.cloneObject(obj[key]);
      } else {
        clone[key] = obj[key];
      }
    }

    return clone;
  },

  getInfoMessage(text) {
    let str = text.replace(/ /g, "").replace(/\n/g, "");
    // 우선권 1, 우선권 2 에 대한 예외 처리 코드 추가
    if (str.substr(0, 3) == "우선권") {
      str = str.substr(0, 3);
    }
    let data = {
      관리번호:
        "귀사 내부에서 관리하는 관리번호.\n개별 특허별로 관리번호가 자동으로 생성되며, 필요시 수정도 가능.\n자동생성의 예를 보면 P(특허를 나타냄)-2020(출원년도)-0001(4자리수로 나타내며, 해당 해의 몇번째 특허인지 나타냄)-KR(국가)순으로 자동 생성됨.\n내부관리번호를 귀사에서 별도로 관리하고자 할 경우에는 수동으로 개별 번호를 입력하면 됨",
      유형: "출원의 유형을 표시함.\n맨처음 출원하는 신규출원(원출원)인지? 분할? 변경? 국내우선권출원 중 어떤 것인지? 선택하는 항목임",
      담당자:
        "기업 내에서의 담당자를 설정합니다.\n담당자는 관리자 중 1인이 될 수 있으며, 이를 위하여 관리자페이지(사용자관리 페이지)에서 관리자로 먼저 등록이 되어야 합니다.",
      대리인담당자:
        "기업 내에서의 담당자를 설정합니다.\n담당자는 관리자 중 1인이 될 수 있으며, 이를 위하여 관리자페이지(사용자관리 페이지)에서 관리자로 먼저 등록이 되어야 합니다.",
      발명자1: "발명신고서에 입력된 발명자가 표시됩니다.",
      대리인:
        "대리인을 설정합니다.\n대리인을 설정하기 위하여서는 먼저 사용자 관리 페이지에서 대리인을 등록하여야 합니다.\n대리인이 설정된 후에는 본 사건에 정보에 대하여 대리인과 공유하게 되며, 대리인은 비밀유지의무를 갖습니다.",
      현지대리인:
        "국제특허에 대하여 현지대리인이 있는 경우, 현지대리인을 설정합니다.",
      발명신고서:
        "내부 발명자가 본인의 발명 내용을 직무발명신고서에 작성해서 발명자가 사용하는 ID로 가입 후 직발명신고서 상세페이지에서 upload하면 그 해달 날이 발명신고서 접수일이 됨.\n직발서 접수현황에서 관리자가 출원에 대한 판단이 결정되면 본 페이지에 접수일과 직발서 내용을 첨부됨. 본 페이지에서는 발명자가 upload했던 직무발명 신고서를 업로드/다운로드 할 수 있음.\n본 내용은 특허사무소가 사용하는 특허사무소용 페이지에도 공유됨.",
      발명신고서접수:
        "내부 발명자가 본인의 발명 내용을 직무발명신고서에 작성해서 발명자가 사용하는 ID로 가입 후 직발명신고서 상세페이지에서 upload하면 그 해달 날이 발명신고서 접수일이 됨.\n직발서 접수현황에서 관리자가 출원에 대한 판단이 결정되면 본 페이지에 접수일과 직발서 내용을 첨부됨. 본 페이지에서는 발명자가 upload했던 직무발명 신고서를 업로드/다운로드 할 수 있음.\n본 내용은 특허사무소가 사용하는 특허사무소용 페이지에도 공유됨.",
      선행기술조사:
        "선행기술조사 여부를 표시하며 선행기술조사 파일 업로드/다운로드를 지원합니다",
      프로젝트명: "출원과 관련된 프로젝트 정보를 입력합니다.",
      초안작성지시:
        "대리인에게 명세서 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      초안작성지시일:
        "대리인에게 명세서 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      초안작성지시접수일:
        "대리인에게 명세서 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      초안접수:
        "대리인으로부터 명세서 초안이 접수된 날짜를 표시하며,\n명세서 초안 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 초안이 업로드된 날을 접수일자로 표시합니다.\n내부 기한이 설정된 경우에는 기한 날짜를 우선적으로 표시하며, 초안이 실제로 접수된 이후로는 초안이 접수된 날짜를 표시합니다.",
      초안전달:
        "대리인으로부터 명세서 초안이 접수된 날짜를 표시하며,\n명세서 초안 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 초안이 업로드된 날을 접수일자로 표시합니다.\n내부 기한이 설정된 경우에는 기한 날짜를 우선적으로 표시하며, 초안이 실제로 접수된 이후로는 초안이 접수된 날짜를 표시합니다.",
      초안발명자검토:
        "명세서 초안에 대한 발명자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n발명자 검토 파일 업로드/다운로드를 지원합니다.",
      초안발명자검토일:
        "명세서 초안에 대한 발명자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n발명자 검토 파일 업로드/다운로드를 지원합니다.",
      초안담당자검토:
        "명세서 초안에 대한 담당자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n담당자 검토 파일 업로드/다운로드를 지원합니다.",
      초안담당자검토일:
        "명세서 초안에 대한 담당자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n담당자 검토 파일 업로드/다운로드를 지원합니다.",
      초안수정본전달:
        "대리인으로부터 받은 명세서 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 초안 최종본을 업로드하게 됩니다.\n대리인으로부터 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 초안 수정본이 업로드된 날을 접수일자로 표시합니다.\n내부 기한이 설정된 경우에는 기한 날짜를 우선적으로 표시하며, 최종본이 실제로 접수된 이후로는 최종본이 접수된 날짜를 표시합니다.",
      초안수정본접수:
        "대리인으로부터 받은 명세서 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 초안 최종본을 업로드하게 됩니다.\n대리인으로부터 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 초안 수정본이 업로드된 날을 접수일자로 표시합니다.\n내부 기한이 설정된 경우에는 기한 날짜를 우선적으로 표시하며, 최종본이 실제로 접수된 이후로는 최종본이 접수된 날짜를 표시합니다.",
      초안최종본접수:
        "대리인으로부터 초안 최종본이 접수된 날짜를 표시하며,\n최종본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 초안 최종본이 업로드된 날을 접수일자로 표시합니다.\n내부 기한이 설정된 경우에는 기한 날짜를 우선적으로 표시하며, 최종본이 실제로 접수된 이후로는 최종본이 접수된 날짜를 표시합니다.",
      출원지시:
        "대리인에게 출원 완료를 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.",
      출원지시일:
        "대리인에게 출원 완료를 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.",
      출원지시접수:
        "대리인에게 출원 완료를 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.",
      심사청구:
        "대리인에게 심사청구를 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.\n한국특허출원은 심사청구를 하여야 심사에 착수하며, 이후 심사에 통과하여야 등록된 특허권을 받을 수 있습니다.\n심사청구기간은 출원일 후 3년입니다.",
      우선심사청구:
        "대리인에게 우선심사청구를 지시합니다.\n우선심사청구를 하게 되면 통상의 경우보다 빠르게 등록여부를 심사 받을 수 있습니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.\n우선심사청구를 통하여 심사 기간을 줄일 수 있습니다.",
      심사청구일: "심사 청구일이 표시됩니다.",
      심사청구기한:
        "심사청구 기한이 표시됩니다.\n심사청구 기한 내에 심사청구를 하지 않으면,\n출원은 자동으로 취하간주되어 더 이상 특허를 받을 수 없게 됩니다.",
      출원번호:
        "출원번호를 입력합니다.\n입력된 출원번호는 페이지 상단에도 표시됩니다.",
      출원일: "실제 출원일을 표시합니다.\n출원이 완료된 후 표시됩니다.",
      국내우선권주장기한:
        "국내우선권 주장 출원이 가능한 법적 기한을 표시합니다.",
      우선일:
        "패밀리 중 최초로 출원된 출원의 출원일을 표시합니다.\n특허 심사과정에서의 등록여부를 우선일을 기준으로 판단합니다.",
      해외우선권주장기한:
        "해외우선권 주장 출원이 가능한 법적 기한을 표시합니다.",
      PCT출원기한:
        "PCT 국제 출원이 가능한 법적 기한을 표시합니다.\nPCT 국제 출원을 통하여 출원을 진행할 국가의 선택 기한을 18개월정도 실질적으로 연장할 수 있습니다.",
      발명의명칭:
        "특허의 명칭을 입력합니다.\n출원 전에는 발명신고서 상의 명칭이 표시되며,\n출원이 완료된 후에는 실제 출원된 발명의 명칭을 입력합니다.",
      영문명칭: "특허 출원이 완료된 후 영문 명칭을 입력합니다.",
      OA발행일:
        "OA는 Office Action 을 의미하며,\n특허 심사과정에서 나오는 거절이유입니다.\n주된 OA로는 심사관의 의견제출통지서, 거절결정서 등이 있습니다.",
      OA답변서제출일: "OA 답변서를 제출한 일자가 표시됩니다.",
      대응안발명자검토:
        "대리인의 OA 대응방안을 검토하여 업로드합니다.\n발명자와 사내 특허담당자의 검토 내용을 각각 업로드합니다.\n이곳에 업로드된 내용은 대리인이 볼 수 없습니다.",
      대응안담당자검토:
        "대리인의 OA 대응방안을 검토하여 업로드합니다.\n발명자와 사내 특허담당자의 검토 내용을 각각 업로드합니다.\n이곳에 업로드된 내용은 대리인이 볼 수 없습니다.",
      대응안수정본전달:
        "발명자와 사내 특허담당자의 검토 내용을 취합하여 업로드합니다.\n대리인은 이곳에 업로드된 내용을 확인하고, OA 대응방안을 추가 검토하게 됩니다.",
      대응안최종본접수:
        "전달된 대응안 수정본을 대리인이 검토하여 수정된 대응안 최종본을 업로드 합니다.",
      OA초안작성지시:
        "대리인의 대응안 최종본을 사내 담당자가 검토하여 추가 검토 요청 사항이 없을 경우, 대리인에게 특허청 제출용 OA 초안을 작성할 것을 지시합니다.",
      OA초안작성지시일:
        "대리인의 대응안 최종본을 사내 담당자가 검토하여 추가 검토 요청 사항이 없을 경우, 대리인에게 특허청 제출용 OA 초안을 작성할 것을 지시합니다.",
      OA초안접수: "대리인이 작성한 특허청 제출용 OA의 초안입니다.",
      OA수정본전달:
        "대리인이 작성한 OA 초안에 대한 수정 요청사항을 전달합니다.",
      OA최종본접수: "수정 요청사항을 반영한 특허청 제출용 OA의 최종본입니다.",
      OA제출지시:
        "대리인에게 OA 제출을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.",
      OA제출지시일:
        "대리인에게 OA 제출을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.",
      OA연장횟수: "OA 제출 기한을 연장한 경우, 그 연장한 횟수를 표시합니다.",
      OA기한:
        "OA 제출 기한입니다.\n제출 기한내에 반드시 제출이 완료되어야 하며, 제출이 되지 않을 경우 출원은 거절 확정됩니다.",
      등록결정일: "특허 심사 결과, 등록이 결정된 일자가 표시됩니다.",
      분할출원:
        "출원에 대하여 분할출원이 진행된 경우, 분할출원 일자 및 횟수가 표시됩니다.",
      등록일: "특허 등록일이 표시됩니다.",
      등록번호: "특허의 등록 번호가 표시됩니다.",
      존속기간만료일:
        "특허의 존속기간 만료일을 표시합니다.\n존속기간 만료일은 일반적으로 출원일 후 20년이 되는 날까지이며,\n이 탭에 표시된 존속기간 만료일 전이라도, 연차료를 납부하지 않을 경우 특허는 소멸합니다.",
      등록기한:
        "특허 등록 기한이 표시됩니다.\n등록 기한 내에 등록료가 납부 완료 되어야 합니다.",
      관납료납부기한: "관납료납부기한을 할 수 있는 기한이 표시됩니다.",
      분할출원기한: "분할출원을 할 수 있는 기한이 표시됩니다.",
      납부년차:
        "특허의 연차료 납부년차가 표시됩니다.\n연차료는 납부년차가 올라감에 따라 주기적으로 상승합니다.",
      청구항수:
        "특허의 등록된 청구항 수를 표시합니다.\n한국 출원의 경우, 청구항 수가 많아질수록 연차료도 올라갑니다.",
      연차료기한:
        "연차료 납부 기한을 표시합니다.\n기한 내에 연차료를 납부하지 않으면 특허는 포기됩니다.",
      연차관리회사: "특허의 연차료 관리회사를 입력합니다.",
      종료일: "특허 종료일을 표시합니다.",
      종료사유:
        "특허 종료 사유를 입력합니다.\n무효 : 무효심판에 의해 무효된 경우\n취하 : 출원이 등록 전에 취하된 경우\n포기 : 특허 등록 후 포기되는 경우 (ex. 등록료 불납)\n거절 : 출원이 심사과정에서 거절 확정된 경우\n만료 : 특허가 존속기간이 만료되어 소멸한 경우\n미출원 : 발명신고서가 접수되었으나, 출원하지 않기로 결정된 경우",
      우선권: "우선권주장 원출원 정보를 표시합니다.",
      번역문초안작성지시:
        "대리인에게 번역문 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      번역문초안작성지시일:
        "대리인에게 번역문 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      번역문초안접수:
        "번역문 초안이 접수된 날짜를 표시하며,\n번역문 초안 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안이 업로드된 날을 접수일자로 표시합니다",
      번역문초안발명자검토:
        "번역문 초안에 대한 발명자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n발명자 검토 파일 업로드/다운로드를 지원합니다.",
      번역문초안발명자검토일:
        "번역문 초안에 대한 발명자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n발명자 검토 파일 업로드/다운로드를 지원합니다.",
      번역문초안담당자검토:
        "번역문 초안에 대한 담당자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n담당자 검토 파일 업로드/다운로드를 지원합니다.",
      번역문초안담당자검토일:
        "번역문 초안에 대한 담당자의 검토 기한을 표시합니다.\n(본 기한은 법적 기한은 아니며, 회사 내부적 프로세스에 의한 업무 처리 기한을 의미합니다.)\n담당자 검토 파일 업로드/다운로드를 지원합니다.",
      번역문초안수정본전달:
        "대리인으로부터 받은 번역문 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 번역문 초안 최종본을 업로드하게 됩니다.\n\n대리인으로부터 번역문 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 수정본이 업로드된 날을 접수일자로 표시합니다.\n",
      번역문초안수정본전달일:
        "대리인으로부터 받은 번역문 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 번역문 초안 최종본을 업로드하게 됩니다.\n\n대리인으로부터 번역문 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 수정본이 업로드된 날을 접수일자로 표시합니다.\n",
      번역문초안최종본접수:
        "대리인으로부터 번역문 초안 최종본이 접수된 날짜를 표시하며,\n번역문 초안 최종본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 최종본이 업로드된 날을 접수일자로 표시합니다.",
      번역문초안최종본접수일:
        "대리인으로부터 번역문 초안 최종본이 접수된 날짜를 표시하며,\n번역문 초안 최종본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 최종본이 업로드된 날을 접수일자로 표시합니다.",
      번역문기한: "번역문 기한을 표시합니다.",
      국제조사보고서:
        "PCT 국제조사보고서의 업로드/다운로드를 지원합니다.\nPCT 국제조사보고서는 각 개별국 심사단계에서 참고자료가 되지만, 법적 구속력은 없습니다.",
      국내단계진입기한:
        "PCT 국제출원의 개별국 국내단계 진입기한을 표시합니다.\n개별국 국내단계 진입 후에는 각 국가에서의 출원 심사 절차를 밟게 됩니다.",
      PACE신청일:
        "PACE 신청일을 표시합니다.\nPACE제도는 유럽의 심사절차 속행 신청제도로서 한국의 우선심사제도의 유사합니다.\nPACE를 신청하게 되면 통상의 경우보다 빠르게 등록여부를 심사 받을 수 있습니다.",
      지정국가:
        "유럽특허청의 등록결정이 나면,\n유럽 내에서 특허권을 등록할 국가를 선택합니다.\n국가를 지정하여 각 개별국에 등록료를 납부하여야 납부된 국가에서 특허권이 인정됩니다.",
      지정국번역문초안작성지시:
        "지정국 번역문 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      지정국번역문초안작성지시일:
        "지정국 번역문 초안 작성을 지시합니다.\n버튼을 누르면 지시가 전달되며, 지시 날짜가 표시됩니다.\n지시 날짜는 임의로 변경이 불가합니다.",
      번역문초안수정본접수:
        "대리인으로부터 받은 번역문 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 번역문 초안 최종본을 업로드하게 됩니다.\n\n대리인으로부터 번역문 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 수정본이 업로드된 날을 접수일자로 표시합니다.",
      번역문초안수정본접수일:
        "대리인으로부터 받은 번역문 초안을 검토한 의견을 업로드 시킵니다.\n업로드된 의견을 대리인이 확인하고 수정하여 번역문 초안 최종본을 업로드하게 됩니다.\n\n대리인으로부터 번역문 초안 수정본이 접수된 날짜를 표시하며,\n수정본 파일의 업로드/다운로드를 지원합니다.\n대리인으로부터 번역문 초안 수정본이 업로드된 날을 접수일자로 표시합니다.",
      지정국번역문기한: "지정국 번역문 기한을 표시합니다.",
    };
    return data[str];
  },

  convertProductStateCodeToState(code) {
    switch (Number(code)) {
      case 0:
        return "미적용";
      case 1:
        return "제품 개발중";
      case 2:
        return "제품 적용중";
      default:
        return "알 수 없음";
    }
  },

  numberWithCommas(x) {
    // 유효성 체크 추가
    if (x == null || isNaN(x)) {
      return "0";
    }

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  calcGraphMaxNumber(arr) {
    let max = Math.floor(Math.max.apply(null, arr) * 1.3);

    let max_str = max + "";
    let cnt = max_str.length - 1;
    let divide_num = 1;

    for (let i = 0; i < cnt; i++) {
      divide_num += "0";
    }
    divide_num = Number(divide_num);
    max = Math.ceil(max / divide_num) * divide_num;

    return max;
  },

  convertInvestTypeToStr(code) {
    switch (code) {
      case 0:
        return "개인형 엑셀러레이터";
      case 1:
        return "법인형 엑셀러레이터";
      case 2:
        return "벤쳐캐피탈";
      case 3:
        return "사모펀드";
      case 4:
        return "은행";
      case 5:
        return "개인투자조합";
      case 6:
        return "기술지주회사";
      case 7:
        return "기업";
    }
  },

  notTd(colSpan, str) {
    return (
      <tr className="not_styled">
        <td colSpan={colSpan}>{str}</td>
      </tr>
    );
  },

  convertAuthCodeToStr(rank) {
    let authName = "";
    switch (rank) {
      case 0:
        authName = "경영진";
        break;
      case 1:
        authName = "관리자";
        break;
      case 2:
        authName = "발명자";
        break;
      case 9:
        authName = "회사계정";
        break;
      case 10:
        authName = "대표";
        break;
      case 11:
        authName = "관리자";
        break;
      case 12:
        authName = "담당자";
        break;
      case 19:
        authName = "회사계정";
        break;
      case 29:
        authName = "투자자";
        break;
    }
    return authName;
  },

  convertBillTypeToStr(n) {
    let authName = "";
    switch (n) {
      case 0:
        authName = "출원 전";
        break;
      case 1:
        authName = "원출원";
        break;
      case 2:
        authName = "분할출원";
        break;
      case 3:
        authName = "변경출원";
        break;
      case 4:
        authName = "가출원";
        break;
      case 5:
        authName = "심사청구";
        break;
      case 6:
        authName = "우선 심사청구";
        break;
      case 8:
        authName = "자진보정";
        break;
      case 9:
        authName = "기간연장";
        break;
      case 10:
        authName = "OA대응";
        break;
      case 11:
        authName = "등록";
        break;
      case 12:
        authName = "심판 청구";
        break;
      case 13:
        authName = "심판 중간 (의견서 제출)";
        break;
      case 14:
        authName = "심판 중간 (답변서 제출)";
        break;
      case 15:
        authName = "심판 성사";
        break;
      case 16:
        authName = "연차료 관리";
        break;
      case 17:
        authName = "명의 변경 및 실시권 설정 등록";
        break;
      case 18:
        authName = "정보변경";
        break;
      case 19:
        authName = "기타 분석 업무";
        break;
      case 20:
        authName = "그외 업무(기타)";
        break;
      case 21:
        authName = "선행기술조사";
        break;
      case 22:
        authName = "일반 출원";
        break;
      case 23:
        authName = "심사청구";
        break;
      case 24:
        authName = "우선심사신청";
        break;
      case 25:
        authName = "계속출원";
        break;
      case 26:
        authName = "분할출원";
        break;
      case 27:
        authName = "재출원";
      case 28:
        authName = "EP 개별국 출원";
        break;
      case 29:
        authName = "oa";
        break;
      case 30:
        authName = "출원 유지";
        break;
      case 31:
        authName = "IDS";
        break;
      case 32:
        authName = "자진보정";
        break;
      case 33:
        authName = "등록";
        break;
      case 34:
        authName = "기타비용\n(정보변경, 통지서 송부 등)";
        break;
      case 35:
        authName = "기타비용\n(클레임차트 작성, 분석비용)";
        break;
      case 36:
        authName = "선행기술 조사";
        break;
      case 37:
        authName = "국제단계";
        break;
      case 38:
        authName = "국내단계";
        break;
    }
    return authName;
  },

  commaify(value) {
    let result = ("" + value).replace(/^(-?\d+)(\d{3})/, "$1,$2");
    return value == result ? result : this.commaify(result);
  },

  newCommaify(value) {
    value = value.toString().replace(/\D/g, "");
    let result = ("" + value).replace(/^(-?\d+)(\d{3})/, "$1,$2");
    return value == result ? result : this.commaify(result);
  },

  removeHTML(str) {
    let text = str.replace(/<br\/>/gi, "\n");
    text = text.replace(
      /<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi,
      ""
    );
    return text;
  },

  deduplicationToJSON(arr, c) {
    return arr.filter((item1, idx1) => {
      return (
        arr.findIndex((item2) => {
          return item1[c ? c : "idx"] === item2[c ? c : "idx"];
        }) === idx1
      );
    });
  },

  decode(str) {
    const entities = {
      amp: "&",
      apos: "'",
      lt: "<",
      gt: ">",
      quot: '"',
      nbsp: "\xa0",
    };
    const entityPattern = /&([a-z]+);/gi;

    return str.replace(entityPattern, (match, entity) => {
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }
      return match;
    });
  },

  logout(mode) {
    this.deleteSession();
    let { Kakao } = window;
    Kakao.Auth.logout();
    window.location.href = "/";
  },
  copy(el) {
    el = el.current;
    el.select();
    document.execCommand("copy");
    el.setSelectionRange(0, 0); //선택영역 초기화
    window.$Global.toast("클립보드에 복사되었습니다");
  },

  deleteSession() {
    sessionStorage.clear();
  },

  dataURItoBlob(dataURI) {
    let byteString = atob(dataURI.split(",")[1]);
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  },

  getBrowserSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },

  getDday(expiration_at) {
    let today = new Date().getTime();
    let gap = expiration_at - today;
    let dday = Math.ceil(gap / (1000 * 60 * 60 * 24));
    return dday < 0 ? "종료" : dday;
  },

  getFullDday(expiration_at) {
    let today = new Date().getTime();
    let gap = expiration_at - today;
    let dday = Math.ceil(gap / (1000 * 60 * 60 * 24));
    return dday < 0 ? "종료" : `(D-${dday})`;
  },
  calcYear(start_year, cnt) {
    const year = Number(start_year.toString().substr(2));
    let convert_arr = [year + "년"];
    let year_arr = [start_year];
    for (let i = 1; i <= cnt; i++) {
      convert_arr.push(year + i + "년");
      year_arr.push(start_year + i);
    }
    return { convert_arr: convert_arr, year_arr: year_arr };
  },

  numberFormat(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },

  numberToKorean(number) {
    let inputNumber = number < 0 ? false : number;
    let unitWords = ["", "만 ", "억 ", "조 ", "경 "];
    let splitUnit = 10000;
    let splitCount = unitWords.length;
    let resultArray = [];
    let resultString = "";

    for (let i = 0; i < splitCount; i++) {
      let unitResult =
        (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
      unitResult = Math.floor(unitResult);
      if (unitResult > 0) {
        resultArray[i] = unitResult;
      }
    }

    for (let i = 0; i < resultArray.length; i++) {
      if (!resultArray[i]) continue;
      resultString =
        String(this.numberFormat(resultArray[i])) + unitWords[i] + resultString;
    }

    return resultString;
  },

  numberRegx(str) {
    let regexp = /[^0-9]/g;

    if (str[0] == 0) {
      str = str.substring(1);
    }
    return str.replace(regexp, "");
  },

  isNumberInput(str) {
    return str.replace(/[^0-9]/g, "");
  },

  matchJsonToStr(json, key, condition) {
    if (this.isEmptyObject(json)) return;
    let result = [];
    let obj = json[key];
    let keys = Object.keys(obj);
    let values = Object.values(obj);

    for (
      let i = 0;
      i <= Math.ceil(Math.sqrt(Math.max.apply(null, values)));
      i++
    ) {
      let square = condition & Math.pow(2, i);
      if (square !== 0) {
        for (let j in keys) {
          if (obj[keys[j]] == square) {
            result.push(keys[j]);
          }
        }
      }
    }

    return result.join(", ");
  },

  matchJsonToValue(json, condition) {
    if (this.isEmptyObject(json)) return;

    let result = [];
    let keys = Object.keys(json);

    for (let i = 0; i < keys.length; i++) {
      let square = condition & Math.pow(2, i);

      if (square !== 0) {
        for (let j in keys) {
          if (json[keys[j]] == square) {
            result.push(square);
          }
        }
      }
    }
    return result;
  },

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  convertLine(str) {
    var re = /\r\n/g;
    return str.replaceAll(re, "<br/>");
  },

  extendCheckImage(str) {
    if (!str.match(config.imgTypeCheck)) {
      alert("jpg, jpeg, png, gif, 파일만 업로드 가능합니다.");
      return false;
    }
    return true;
  },

  extendCheckPDF(str) {
    if (!str.match(config.pdfTypeCheck)) {
      alert("PDF 파일만 업로드 가능합니다.");
      return false;
    }
    return true;
  },

  imagePopup(url) {
    var imgObj = new Image();
    imgObj.src = url;
    let imageWin = window.open(
      "",
      "profile_popup",
      "width=" + imgObj.width + "px, height=" + imgObj.height + "px"
    );
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write(
      "<a href=javascript:window.close()><img src='" +
      imgObj.src +
      "' border=0></a>"
    );
    imageWin.document.write("</body><html>");
    imageWin.document.title = imgObj.src;
  },

  addressSearch(address) {
    // 주소-좌표 변환 객체를 생성합니다
    let geocoder = new kakao.maps.services.Geocoder();

    // 주소로 좌표를 검색합니다
    return new Promise((resolve, reject) => {
      if (!address) {
        return resolve({});
      } else {
        geocoder.addressSearch(address, (result, status) => {
          // 정상적으로 검색이 완료됐으면
          if (status === kakao.maps.services.Status.OK) {
            resolve({
              lon: result[0].x,
              lat: result[0].y,
            });
          } else {
            alert("주소를 다시 확인해주세요");
            reject("NO DATA");
          }
        });
      }
    });
  },

  blockRightClick(e) {
    e.preventDefault();
    alert("오른쪽 버튼은 사용할 수 없습니다.");
    return false;
  },

  toggleViewType() {
    const token = window.$Global.getToken();
    let ipnowUrl = "https://ipnow.co.kr";
    let findBizUrl = "https://biznavi.co.kr";

    if (token !== null) {
      ipnowUrl += "?token=" + token;
      findBizUrl += "?token=" + token;
    }

    let url =
      window.location.hostname.indexOf("findbiz") > -1 ? ipnowUrl : findBizUrl;
    window.open(url, "_blank");
  },

  getPlatformName() {
    const isViewTypeIpnow = window.$Global.isIpnow();
    return isViewTypeIpnow ? "아이피나우" : "비즈내비";
  },

  isIpnow(location) {
    const { pageType } = qs.parse(location?.search);

    if (pageType === "findbiz") return false;

    return (
      window.location.hostname.indexOf("ipnow") > -1 || pageType === "ipnow"
    );
  },

  getToken() {
    return sessionStorage.getItem(config.keyToken);
  },

  hasLoginToken() {
    return this.getToken() != null;
  },

  saveHistory() {
    const auth = this.hasLoginToken();
    if (!auth) {
      sessionStorage.setItem("historyURL", window.location.href);
    }
  },

  renderFunc(props, Component) {
    const auth = this.hasLoginToken();
    window.$Global.saveHistory();
    return auth ? <Component {...props} /> : <Redirect to="/login" />;
  },

  toast(msg) {
    toast.success(msg, {
      position: "top-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide,
    });
  },

  saveToast() {
    this.toast("저장되었습니다");
  },
  registerToast() {
    this.toast("등록되었습니다");
  },
  updateToast() {
    this.toast("수정을 눌러 편집 해주세요");
  },
  confirmToast() {
    this.toast("이벤트를 클릭하시면 읽음처리 됩니다");
  },

  handleCopyImage(imageUrl) {
    console.log("copy");
    const img = new Image();
    img.src = imageUrl;

    img.addEventListener("load", () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        Clipboard.write([new ClipboardItem({ "image/png": blob })]);
      }, "image/png");
    });
  },

  convertStateName(state, termination_type) {
    let str = "";
    switch (state) {
      case 0:
        str = "준비";
        break;
      case 4:
        str = "출원";
        break;
      case 80:
        str = "공고";
        break;
      case 98:
        str = "거절(미확정)";
        break;
      case 7:
        str = "등록";
        break;
      case 99:
        if (termination_type === 3) {
          str = "거절(확정)";
        } else if (termination_type === 4) {
          str = "소멸";
        }
        break;
    }
    return str;
  },

  convertCountryName(name) {
    let obj = {};
    obj = tradeMarkCountryKr.find((item) => Object.keys(item) == name);

    return Object.values(obj);
  },

  convertNationName(value) {
    return tradeMarkNationKr[value];
  },
  convertPayStateTypeToStr(n) {
    let authName = "";
    switch (n) {
      case 0:
        authName = "대기";
        break;
      case 1:
        authName = "납부요청";
        break;
      case 2:
        authName = "납부완료";
        break;
      case 3:
        authName = "납부완료";
        break;
      case 4:
        authName = "입금완료";
        break;
      case 5:
        authName = "기업체 납부";
        break;
      case 6:
        authName = "납부제외";
        break;
      case 99:
        authName = "포기";
        break;
    }
    return authName;
  },

  validateBirthDate(date) {
    const pattern =
      /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

    if (pattern.test(Number(date)) === false) {
      return false;
    } else {
      return true;
    }
  },

  validateEmailAddress(address) {
    const pattern = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;

    if (pattern.test(address) === false) {
      return false;
    } else {
      return true;
    }
  },

  getOrdinalNumber(number) {
    const specialCases = ["th", "st", "nd", "rd"],
      v = number % 100;
    return (
      number +
      (specialCases[(v - 20) % 10] || specialCases[v] || specialCases[0])
    );
  },
};
