import React, {useContext, useEffect, useState} from "react";
import "./css/ExpositionSub.scss";
import {Link, useParams} from "react-router-dom";
import CommonAPI from "../../API/common";
import CommonContext from "../../store/Common";

let { kakao } = window;
function ExpositionSub() {
	const {isMobile} = useContext(CommonContext);
	const {idx} = useParams();
	const [resData, setResData] = useState({});
	const costStr = !resData.cost ? '무료' : isNaN(resData.cost) ? resData.cost : window.$Global.commaify(resData.cost);

	useEffect(() => {
		CommonAPI.getNoticeInfo(idx).then(res => {
			let data = res.data;
			setResData(data);

			if (data.lat && data.lon) {
				let container = document.getElementById('map');
				let options = {
					center: new kakao.maps.LatLng(data.lat, data.lon),
					level: 3
				};
				let map = new kakao.maps.Map(container, options);
				// 마커를 생성합니다
				let marker = new kakao.maps.Marker({
					// 마커가 표시될 위치입니다
					position: options.center
				});

				// 마커가 지도 위에 표시되도록 설정합니다
				marker.setMap(map);
			}
		});
	}, []);

	let MapTag = [];

	if (Boolean(resData.address)) {
		MapTag =
			<section className="expositionSub_content_map">
				<h3 className="expositionSub_content_map_title">
					장소 안내
				</h3>
				<div className="expositionSub_content_map_section">
					<div id="map"/>
					<p className="expositionSub_content_map_section_addr">{resData.location} {resData.address}</p>
				</div>
			</section>
	}

	return (
		<div id="expositionSub">
			{
				!isMobile
				&&
				<div className="pc">
					<div className="expositionSub">
						<h2 className="expositionSub_title">교육·행사·경진대회</h2>
						<div className="expositionSub_content">
							<section className="expositionSub_content_title-wrap">
								<h3 className="expositionSub_content_title">{resData.title}</h3>
								<p className="expositionSub_content_date">{window.$Global.convertDate(resData.created_at)} 등록</p>
							</section>
							<section className="expositionSub_content_info">
								<div className="expositionSub_content_info_img">
									<img src={window.$Global.getCDN(resData.thumbnail_key)} alt={resData.title} />
								</div>
								<div className="expositionSub_content_info_content">
									<h4 className="expositionSub_content_info_content_title">{resData.title}</h4>
									<dl className="list">
										<dt className="list_title">
											장&nbsp;&nbsp;&nbsp;&nbsp;소
										</dt>
										<dd className="list_info">{resData.location}</dd>
										<dt className="list_title">
											일&nbsp;&nbsp;&nbsp;&nbsp;시
										</dt>
										<dd className="list_info">{resData.time_detail}</dd>
										<dt className="list_title">
											비&nbsp;&nbsp;&nbsp;&nbsp;용
										</dt>
										<dd className="list_info">{costStr}</dd>
										<dt className="list_title">
											주&nbsp;&nbsp;&nbsp;&nbsp;최
										</dt>
										<dd className="list_info">{resData.host}</dd>
									</dl>
									<div className="expositionSub_content_info_content_text-wrap">
										<p className="text">
											{resData.introduce}
										</p>
										{/*<div className="btn-wrap">*/}
										{/*	<button type="button" className="event">행사 참여하기</button>*/}
										{/*	<button type="button" className="copy">주소 복사히기</button>*/}
										{/*</div>*/}
									</div>
								</div>
							</section>
							<section className="expositionSub_content_intro">
								<h5 className="expositionSub_content_intro_title">
									행사&middot;경진대회 소개
								</h5>
								<div className="expositionSub_content_intro_section">
									<div className="content sun-editor-editable" dangerouslySetInnerHTML={ {__html: resData.content} }/>
								</div>
							</section>
							{MapTag}
							<div className="expositionSub_content_btn-wrap">
								<Link to="/exposition/list" className="btn_list">목&nbsp;&nbsp;록</Link>
							</div>
						</div>
					</div>
				</div>
			}

			<div className="mo">
				<div className="expositionSub">
					<div className="expositionSub_img">
						<img src={window.$Global.getCDN(resData.thumbnail_key)} alt={resData.title} />
					</div>
					<div className="expositionSub_content">
						<div className="expositionSub_content_box">
							<section className="expositionSub_content_title-wrap">
								<h3 className="expositionSub_content_title">
									{resData.title}
								</h3>
								<span className="expositionSub_content_day">
									{
										window.$Global.getDday(resData.start_at) == "종료"
										? <span className="today">TODAY</span>
										: <span className="dday">D-{window.$Global.getDday(resData.start_at)}</span>
									}
								</span>
							</section>
							<section className="expositionSub_content_table">
								<dl className="list">
									<dt className="list_title">
										장&nbsp;&nbsp;소
									</dt>
									<dd className="list_info">
										{resData.location}
									</dd>
									<dt className="list_title">
										일&nbsp;&nbsp;시
									</dt>
									<dd className="list_info">
										{resData.time_detail}
									</dd>
									<dt className="list_title">
										비&nbsp;&nbsp;용
									</dt>
									<dd className="list_info">
										{costStr}
									</dd>
									<dt className="list_title">
										주&nbsp;&nbsp;최
									</dt>
									<dd className="list_info">
										{resData.host}
									</dd>
								</dl>
							</section>
							<section className="expositionSub_content_info">
								<div className="text sun-editor-editable" dangerouslySetInnerHTML={ {__html: resData.content} }/>
							</section>
							{MapTag}
							<div className="expositionSub_content_btn-wrap">
								<Link to="/exposition/list" className="btn_list">목&nbsp;&nbsp;록</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ExpositionSub;