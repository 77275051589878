import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";
import ResearchTechCategoryList from "../components/researchTech/ResearchTechCategoryList";
import ResearchTechMy from "../components/researchTech/ResearchTechMy";
import ResearchTechEdit from "../components/researchTech/edit/ResearchTechEdit";
import ResearchTechMain from "../components/researchTech/ResearchTechMain";
import ResearchTechRequestEdit from "../components/researchTech/request/ResearchTechRequestEdit";
import ResearchTechPatentList from "../components/researchTech/ResearchTechPatentList";

function ResearchTechRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/category`} requireAuth={false} component={ResearchTechCategoryList}/>
			<UserAuthRoute exact path={`${match.path}/my`} requireAuth={true} component={ResearchTechMy}/>
			<UserAuthRoute exact path={`${match.path}/edit`} requireAuth={true} component={ResearchTechEdit}/>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={false} component={ResearchTechMain}/>
			<UserAuthRoute exact path={`${match.path}/patent`} requireAuth={false} component={ResearchTechPatentList}/>
			<UserAuthRoute exact path={`${match.path}/requestEdit`} requireAuth={false} component={ResearchTechRequestEdit}/>
		</CustomSwitch>
	)
}

export default ResearchTechRoute;