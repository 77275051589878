import React from "react";
import Styles from "./checkpoint.module.scss";
import questionMark from "../../../../assets/images/trademark/questionMark.png";
import xMark from "../../../../assets/images/trademark/x-mark.png";
import exclamationMark from "../../../../assets/images/trademark/exclamation.png";
import rightMark from "../../../../assets/images/trademark/right.png";
import TabsNavigator from "./tabsNavigator/TabsNavigator";

const Checkpoint = () => {
  return (
    <div className={Styles["checkpoint-page"]}>
      <div>
        <div>
          <img src={rightMark} alt="questionMark" />
          <img src={xMark} alt="questionMark" />
          <img src={exclamationMark} alt="questionMark" />
          <img src={questionMark} alt="questionMark" />
        </div>

        <div>
          <p>IP를 방치하고, 매년 관리 비용만 낭비하고 있지는 않나요? IP 관리를 잘하면 IP로 돈을 벌 수 있습니다.</p>
          <p>IP NOW의 IP관리 시스템은 내가 보유한 IP를 출원부터 등록시까지 관리해서</p>
          <p>IP의 가치를 높이고(Value-up), 수익화까지 연계할 수 있는 IP혁신 플랫폼입니다.</p>
        </div>
      </div>
      {/* tabs */}
      <TabsNavigator />
    </div>
  );
};

export default Checkpoint;
