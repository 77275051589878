import SaveButton from "../SaveButton";
import "./css/RnDText.scss";

const RnDFirst = () => {
  return (
    <div>
      <p>R&D과제 계획서 작성하기</p>
      <p>
        [별지 제1호서식] 연구개발계획서(신청용¸
        협약용)(국가연구개발혁신법 시행규칙) (1)
      </p>
    </div>
  );
};

const RnDThird = () => {
  return (
    <div>
      <p>R&D과제 계획서 작성하기 본문 1</p>
      <p>
        &lt;본문 1&gt;의 서식은 각 항목에서 요구하는 정보를 포함하여
        연구개발과제의 특성에 따라 항목을 추가하거나 항목의 순서와 구성을
        변경하는 등<br /> 서식을 수정하여 사용하거나 별도의 첨부자료 활용이
        가능합니다.
      </p>
    </div>
  );
};

const RnDFourth = () => {
  return (
    <div>
      <p>R&D과제 계획서 작성하기 본문 2</p>
      <p>
        &lt;본문 2&gt;의 서식은 2021.1.4.부터 2021.12.31.까지 각 항목에서
        요구하는 정보를 포함하여 연구개발과제의 특성에 따라 항목을 추가하거나
        <br />
        항목의 순서와 구성을 변경하는 등 서식을 수정하여 사용하거나 별도의
        첨부자료 활용이 가능합니다.
      </p>
    </div>
  );
};

const RnDText = ({ index }) => {
  return (
    <div id="RnDText">
      {(index === 1 || index === 2) && <RnDFirst />}
      {index === 3 && <RnDThird />}
      {(index === 4 || index === 5) && <RnDFourth />}
      <SaveButton />
    </div>
  );
};

export default RnDText;
