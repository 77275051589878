import React, { useState } from "react";
import Styles from "./selectBox.module.scss";
import useClickOutside from "../../../hooks/useClickOutside";

const SelectBox = ({
  selectedOption,
  options,
  onChange,
  isDisabled = false,
  name = "",
  className = "",
  withArrowIndicator = false,
}) => {
  const [dropdownIsVisible, setDropdownIsVisible] = useState(false);

  const selectDivRef = useClickOutside(() => setDropdownIsVisible(false));

  const selectHandler = (name, option) => {
    onChange(name, option);
    setDropdownIsVisible(false);
  };

  return (
    <div ref={selectDivRef} className={`${Styles["select-div"]} ${className}`}>
      <button disabled={isDisabled} onClick={() => setDropdownIsVisible(!dropdownIsVisible)} type="button">
        {selectedOption.name}
      </button>
      {withArrowIndicator && <i style={{ rotate: dropdownIsVisible ? "90deg" : "" }} className="icon_arrow_right_s" />}
      {dropdownIsVisible && (
        <ul>
          {options.map((option) => (
            <li onClick={() => selectHandler(name, option)} key={option.name}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectBox;
