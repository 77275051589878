import React from "react";

function IntroRow({title, smallTitle, fileTitle, introDesc, condition, autoFocus, onChange, upHide, downHide, onClickDownPopShow, onClickUpPopShow}) {

    return (
        <>
            <h2 className="title">{title}</h2>
            <div className="rows">
                <div className="row no_data">
                    <h2 className="title">{smallTitle}</h2>
                </div>
                <div className="row no_data"/>
                <div className="row no_data">
                    <h2 className="title">{fileTitle}</h2>
                    <div className="btns">
                        {
                            downHide
                            ?   null
                            :   <button className="icon_download" onClick={onClickDownPopShow}/>
                        }
                        {
                            upHide
                                ?   null
                                :   <button className="icon_upload" onClick={onClickUpPopShow}/>
                        }
                    </div>
                </div>
            </div>
            {
                condition
                ?   <textarea value={introDesc}
                              onChange={onChange}
                              autoFocus={autoFocus}
                              placeholder={title + "을(를) 상세히 입력해주세요"}
                    />
                :
                    <div className="desc">
                        {introDesc}
                    </div>
            }
        </>
    );
}
export default IntroRow;
