import React, { useRef, useState } from "react";
import BannerIpnow from "../../common/BannerIpnow";
import CountUp from "react-countup";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import InfoCompanyAPI from "../../../API/infoCompany";
import CalcAnnualFeePopup from "./CalcAnnualFeePopup";
import links from "../../../const/links";
import IpnowIntroTechPopUp from "../../popup/IpnowIntroTechPopUp";

function IntroIpNow({ history }) {
  const input_ref = React.useRef();
  const [keyword, setKeyword] = useState("");
  const [keyword_list, setKeywordList] = useState("");
  const [keyword_list_show, setKeywordListShow] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [arr, setArr] = useState([]);
  const [patent, setPatent] = useState({});
  const [companyName, setCompanyname] = useState("");
  const [apply_at, setApplyAt] = useState(0);
  const [mouse, setMouse] = useState(false);
  const [toggleSlider, setToggleSlider] = useState(false);
  const [toggleIndex, setToggleIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const sliderRef = useRef();

  const getSearchCompany = () => {
    if (!keyword.length) {
      setKeywordList([]);
      return;
    }
    InfoCompanyAPI.getInfoCompany(keyword).then((res) => {
      if (res.data.length) {
        setKeywordList(
          res.data.map((item, index) => {
            return (
              <li
                key={index}
                className="active"
                tabIndex={0}
                onClick={() =>
                  onClickCalcAnnualFee(item.name, item.company_code)
                }
                onBlur={onBlur}
              >
                <div>
                  <h2>{item.name}</h2>
                  <p>
                    {item.name_en &&
                      item.name_en.replace(/(\s*)/g, "") &&
                      `영문법인명 ${item.name_en}`}
                    {item.corporate_number &&
                      item.corporate_number.replace(/(\s*)/g, "") &&
                      ` / 법인등록번호 ${item.corporate_number}`}
                    {item.license_number &&
                      item.license_number.replace(/(\s*)/g, "") &&
                      ` / 사업자 등록번호 ${item.license_number}`}
                    {item.representative && ` / 대표자 ${item.representative}`}
                    {item.establishment_at &&
                      ` / 설립일 ${window.$Global.convertDate(
                        item.establishment_at
                      )}`}
                  </p>
                </div>
              </li>
            );
          })
        );
      } else {
        setKeywordList([
          <li>
            <div>
              <p>입력하신 회사가 등록되지 않았거나 등록한 특허가 없습니다.</p>
            </div>
          </li>,
        ]);
      }
      setKeywordListShow(true);
      input_ref.current.focus();
    });
  };

  const onClickCalcAnnualFee = (name, company_code) => {
    if (!company_code) {
      alert("특허가 존재하지 않아 조회할수 없습니다");
      return;
    }
    InfoCompanyAPI.getInfoCompanyAnnual(company_code).then((res) => {
      let data = res.data;
      let annual_pay = data.annual_pay;
      setPatent(data.patent);
      setArr(annual_pay.map((item) => item.cost));
      setCompanyname(name);
      setApplyAt(annual_pay[annual_pay.length - 1].year);
      setPopupShow(true);
      setKeywordListShow(false);
    });
  };

  const onClickClose = () => {
    setPopupShow(false);
  };

  const onChangeInput = (value) => {
    setKeyword(value);
    setKeywordList([]);
    setKeywordListShow(false);
  };

  const onBlur = () => {
    if (!mouse) {
      setKeywordListShow(false);
    }
  };

  const onClickGoSignUp = () => {
    history.push("/signup/type");
  };

  const settings_mo = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };

  const menu_settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    touchMove: false,
    afterChange: (index) => {
      setToggleIndex(index);
    },
  };

  const slickGoTo = (value) => {
    sliderRef.current.slickPause();
    sliderRef.current.slickGoTo(value);
    setToggleSlider(true);
    setToggleIndex(value);
  };

  const togglePlay = () => {
    if (toggleSlider) {
      sliderRef.current.slickPlay();
    } else {
      sliderRef.current.slickPause();
    }
    setToggleSlider(!toggleSlider);
  };

  return (
    <>
      <div className="pc">
        {showPopup && <IpnowIntroTechPopUp setShowPopup={setShowPopup} />}
        <div className="banner-wrap">
          <BannerIpnow history={history} />
        </div>
        <div className="page ctl_page">
          <div className="menus">
            <ul>
              <li className="menu" onClick={() => slickGoTo(0)}>
                <div className={`inner ${toggleIndex == 0 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_bulb" />
                  </div>
                  <p>IP자동관리&기한자동관리</p>
                </div>
              </li>
              <li className="menu" onClick={() => slickGoTo(1)}>
                <div className={`inner ${toggleIndex == 1 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_pay_manage" />
                  </div>
                  <p>비용/연차료 자동 관리</p>
                </div>
              </li>
              <li className="menu" onClick={() => slickGoTo(2)}>
                <div className={`inner ${toggleIndex == 2 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_invest" />
                  </div>
                  <p>IP 활용자금 유치</p>
                </div>
              </li>
              <li className="menu" onClick={() => slickGoTo(3)}>
                <div className={`inner ${toggleIndex == 3 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_trade" />
                  </div>
                  <p>특허 · 상표자동거래 시스템</p>
                </div>
              </li>
              <li className="menu" onClick={() => slickGoTo(4)}>
                <div className={`inner ${toggleIndex == 4 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_task" />
                  </div>
                  <p>정부 · 공공 · 지자체 사업찾기</p>
                </div>
              </li>
              <li className="menu" onClick={() => slickGoTo(5)}>
                <div className={`inner ${toggleIndex == 5 ? "active" : ""}`}>
                  <div className="wrap_icon">
                    <i className="icon_grade_auto" />
                  </div>
                  <p>기술 · 특허 등급 자동제공</p>
                </div>
              </li>
            </ul>
            <button
              className={toggleSlider ? `icon_play` : `icon_pause`}
              onClick={togglePlay}
            />
            <Slider ref={sliderRef} {...menu_settings}>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_bulb" />
                    <div className="inner_desc">
                      <h2>자동화된 특허, 상표, 디자인권 관리 기능</h2>
                      <p>
                        엑셀은 이제 그만 버리십시오. 직관적인 UX/UI를 통해 모든
                        정보를 알기 쉽게 제공한
                        <br />
                        IPNOW관리시스템으로 언제든지 확인 & 보완하고 원하는
                        권리를 만들어 나갈 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div className="desc_tab">
                    <i className="icon_two_person" />
                    <div className="inner_desc">
                      <h2>비전문가도 쉽게 관리/활용</h2>
                      <p>
                        IP전문 인력이 없어도 누구나 쉽게 사용할 수 있도록 세상의
                        모든 IP보유 기업을 위해
                        <br />
                        만들었습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_annual" />
                    <div className="inner_desc">
                      <h2>예상연차료 정보 제공 및 지급추천 기능</h2>
                      <p>
                        귀사의 모든 IP의 연차 도료를 만료일까지 계산해 그래프로
                        보여 드립니다.
                        <br />
                        또한 불필요한 연차료를 내지 않도록 시그널을
                        제공해드립니다.
                      </p>
                    </div>
                  </div>
                  <div className="desc_tab">
                    <i className="icon_pay_manage" />
                    <div className="inner_desc">
                      <h2>특허사무소와의 자동연결으로 간편한 비용처리</h2>
                      <p>
                        특허사무소와 비용 청구를 메일로 주고 받으시죠?
                        <br />
                        아이피나우에서 온라인으로 자동으로 청구하고 비용처리
                        하세요.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_ip_loans" />
                    <div className="inner_desc">
                      <h2>IP담보대출을 위한 자동정보 제공 (가치평가 포함)</h2>
                      <p>
                        이젠 IP로 은행에서 대출을 받을 수 있습니다.
                        <br />
                        IP담보대출을 받을 때 필요한 IP/제품/매출등에 대한 DATA를
                        자동으로 제공해 드립니다.
                      </p>
                    </div>
                  </div>
                  <div className="desc_tab">
                    <i className="icon_invest" />
                    <div className="inner_desc">
                      <h2>투자유치를 위한 투자자 자동연결 시스템</h2>
                      <p>
                        좋은 기술/특허를 이용해 투자를 유치할 수 있습니다.
                        <br />
                        IPNOW는 직접 투자자와 연결해 회사를 소개할 수 있는
                        자동화 시스템을 제공합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_open_market" />
                    <div className="inner_desc">
                      <h2>우수 특허 매각 / 매입을 위한 오픈마켓</h2>
                      <p>
                        기업이 보유한 우수한 특허에 매각을 통해 수익을 창출
                        하고,
                        <br />꼭 필요한 특허(기술)을 IPNOW 시스템을 통해서
                        매입해보세요.
                      </p>
                    </div>
                  </div>
                  <div className="desc_tab">
                    <i className="icon_trade" />
                    <div className="inner_desc">
                      <h2>간편한 원터치 상표거래 기능</h2>
                      <p>다양한 상표거래와 함께 추천 상표도 받아보세요.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_task" />
                    <div className="inner_desc">
                      <h2>정부사업 자동정리 기능(Target 정보 자동제공)</h2>
                      <p>
                        기업이 원하는 정부사업를 가장 쉽게 파악 할 수 있도록
                        정리된 사업 정보제공으로
                        <br />
                        정부 · 공공 · 지자체 사업 한번에 모아 보고 관리 하기
                      </p>
                    </div>
                  </div>
                  <div className="desc_tab">
                    <i className="icon_company_matching" />
                    <div className="inner_desc">
                      <h2>기업 매칭 시스템 제공</h2>
                      <p>
                        사업 신청 시 함께 하고자하는 최적의 기업을 손쉽게 찾아
                        보세요.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="slider_item">
                <div className="menu_desc">
                  <div className="desc_tab">
                    <i className="icon_grade_auto" />
                    <div className="inner_desc">
                      <h2>기업 기술 역량, 등록 특허 등급제공기능</h2>
                      <p>우리기업의 IP기반 기술등급을 확인 해 보세요.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="page page2">
          <div className="auto_calc_annual_fee">
            <img
              src={
                require("../../../assets/images/common/bg-annual-fee.png")
                  .default
              }
              className="bg_image"
              alt="이미지"
            />
            <div className="txt">
              <p className="desc">
                <span className="color_blue">1초</span>
                안에
                <br /> 우리회사
                <span className="color_yellow"> 특허/상표 유지비용</span>을
                계산해 드립니다.
              </p>
            </div>
            <div className="search">
              <input
                type="text"
                placeholder="회사명을 입력하여 검색하면 만료일까지 연차료를 확인 하실 수 있습니다."
                ref={input_ref}
                value={keyword}
                onChange={(e) => onChangeInput(e.target.value)}
                onFocus={() => setKeywordListShow(true)}
                onBlur={onBlur}
                onKeyUp={(e) => {
                  if (e.key === "Enter") getSearchCompany();
                }}
              />
              {keyword_list_show && Boolean(keyword_list.length) && (
                <ul
                  className="keyword_list custom_scroll no_focus_outline"
                  onMouseEnter={() => setMouse(true)}
                  onMouseLeave={() => setMouse(false)}
                >
                  {keyword_list}
                </ul>
              )}
              <button
                className="btn_search icon_m_search"
                onClick={getSearchCompany}
              />
            </div>
          </div>
          <div className="search_company" onClick={() => setShowPopup(true)}>
            <p>
              <span>알고싶은</span>
              <br />
              <span>기업·경쟁기업</span>의<br />
              <span>기술등급</span>
              <br />
              알아보기
            </p>
            <img
              src={
                require("../../../assets/images/common/bg-search-company.png")
                  .default
              }
              alt="이미지"
            />
          </div>
        </div>
        <div className="page page3">
          <div className="txt">
            <h2 className="title">
              IP<span>(특허·상표·디자인권)</span>으로
              <br />
              자금을 확보하는 시대입니다.
            </h2>
            <p className="desc">
              핵심 무형 자산(IP)으로 기업이 절실하게 필요로 하는 자금을 확보할
              수 있습니다.
              <br />
              IP를 통한 담보대출, IP를 활용한 투자유치는 기업 생존과 성장에 필수
              조건입니다.
            </p>
            <button onClick={onClickGoSignUp}>무료로 시작하기</button>
          </div>
        </div>

        <div className="page page4">
          <h2 className="title">IPNOW를 선택하는 특별한 3가지 이유</h2>
          <ul>
            <li>
              <img
                src={
                  require("../../../assets/images/common/intro-img-ip.png")
                    .default
                }
                alt="이미지"
              />
              <p className="title">쉬운 IP관리 시스템</p>
              <p className="desc">
                경영진, 담당자, 발명자 등<br />
                직무에 따라 맞춤별 서비스 제공이 됩니다.
              </p>
            </li>
            <li>
              <img
                src={
                  require("../../../assets/images/common/intro-img-cost.png")
                    .default
                }
                alt="이미지"
              />
              <p className="title">비용 절감</p>
              <p className="desc">
                불필요한 연차료 납부를 방지하여
                <br />
                비용절감을 제공합니다.
              </p>
            </li>
            <li>
              <img
                src={
                  require("../../../assets/images/common/intro-img-auto.png")
                    .default
                }
                alt="이미지"
              />
              <p className="title">자동화시스템</p>
              <p className="desc">
                다양한 자동시스템을 통해 IP담보대출,
                <br />
                투자유치 및 특허를
                <br />
                수익화 할 수 있도록 지원 해 드립니다.
              </p>
            </li>
          </ul>
          <button onClick={onClickGoSignUp}>무료로 시작하기</button>
        </div>

        <div className="page page5">
          <div className="txt">
            <h2 className="title">
              기업과 특허사무소를 이어주는
              <br />
              온라인 공유 시스템
            </h2>
            <p className="desc">
              이메일로만 IP관련 정보를 제공하면 놓치는 것이 많습니다.
              <br />
              IPNOW에서는 특허사무소가 정보를 입력하면
              <br />
              기업이 쉽게 확인할 수 있는 시스템을 제공합니다.
              <br />
              또한 등록한 정보를 이메일로 자동으로 발송시켜
              <br />
              혹시나 놓칠 수 있는 상황을 방지합니다.
            </p>
          </div>
        </div>

        <div className="page page6">
          <div className="inner">
            <div className="image_wrapper">
              <img
                src={
                  require("../../../assets/images/intro/bg-intro-image-first.png")
                    .default
                }
                className="img_main"
                alt="이미지"
              />
              <img
                src={
                  require("../../../assets/images/intro/bg-intro-image-second.png")
                    .default
                }
                className="img_main"
                alt="이미지"
              />
            </div>
            <div className="txt">
              <h2 className="title">IPNOW 차별점</h2>
              <ul>
                <li>
                  <p>
                    <span>저렴한 비용</span>
                    <br />
                    <span>무료 사용 2개월 이상, 월 사용료 1만원 부터</span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>보기쉬운 그래픽</span>
                    <br />
                    <span>
                      비전문가도 한눈에 파악할 수 있는 알기 쉽고 정확한 Data
                      그래픽
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>24시간 정확하고 빠른상담 AI챗봇</span>
                    <br />
                    <span>
                      궁금한게 있다면 언제든지 물어보고 정확한 답을 얻을 수{" "}
                      <br />
                      있는 24시간 AI 챗봇
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span>자동입력/분석 시스템</span>
                    <br />
                    <span>기업에 필요한 다양한 Data 자동입력, 분석 시스템</span>
                  </p>
                </li>
              </ul>
              <button onClick={onClickGoSignUp}>무료로 시작하기</button>
            </div>
          </div>
        </div>
      </div>

      <div className="mo">
        <div className="banner">
          <Slider {...settings_mo}>
            <div className="item">
              <img
                src={
                  require("../../../assets/images/mobile/bg_banner_01.svg")
                    .default
                }
                alt="이미지"
              />
              <button className="btn btn_free_start" onClick={onClickGoSignUp}>
                2개월 무료로 시작하기
              </button>
            </div>
            <div className="item">
              <img
                src={
                  require("../../../assets/images/mobile/bg_banner_03.svg")
                    .default
                }
                alt="이미지"
              />
              <button
                className="btn btn_free_start_green"
                onClick={onClickGoSignUp}
              >
                2개월 무료로 시작하기
              </button>
            </div>
            <div className="item">
              <img
                src={
                  require("../../../assets/images/mobile/bg_banner_02.svg")
                    .default
                }
                alt="이미지"
              />
              <button className="btn btn_grade" onClick={onClickGoSignUp}>
                2개월 무료로 시작하기
              </button>
            </div>
          </Slider>
        </div>
        <div className="auto_calc_annual_fee">
          <div className="search_form">
            {keyword.length == 0 && (
              <p>
                <span>회사 이름을 입력해보세요</span>
              </p>
            )}
            <button
              className="icon_m_search"
              src={
                require("../../../assets/images/mobile/icon_search.svg").default
              }
              onClick={getSearchCompany}
            />
            <input
              type="text"
              ref={input_ref}
              value={keyword}
              onChange={(e) => onChangeInput(e.target.value)}
              onFocus={() => setKeywordListShow(true)}
              onBlur={onBlur}
              onKeyUp={(e) => {
                if (e.key === "Enter") getSearchCompany();
              }}
            />
          </div>
          <div className="use_notify">
            <i className="icon_alert_circle" />
            <p>특허유지비용을 1초만에 확인 가능합니다</p>
          </div>
          {keyword_list_show && Boolean(keyword_list.length) && (
            <ul
              className="keyword_list custom_scroll no_focus_outline"
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              {keyword_list}
            </ul>
          )}
        </div>
        <button className="btn btn_free" onClick={onClickGoSignUp} />
        <img
          className="intro_01"
          src={require("../../../assets/images/mobile/bg_intro_01.svg").default}
          alt="이미지"
        />
        <div className="side_signup first">
          <p>
            지금 회원가입하시면 2개월간
            <br />
            무료로 서비스를 이용하실 수 있습니다
          </p>
          <button className="btn_go_signup" onClick={onClickGoSignUp} />
        </div>
        <div className="more_view_area">
          <h2 className="area_title">IP 수익화/정보분석 서비스</h2>
          <div className="list remove_scrollbar">
            <img
              src={
                require("../../../assets/images/mobile/bg_main_trade.svg")
                  .default
              }
              alt="이미지"
              onClick={() => history.push("/trade/list")}
            />
            <img
              src={
                require("../../../assets/images/mobile/bg_main_grade.svg")
                  .default
              }
              alt="이미지"
              onClick={() => history.push("/grade/company")}
            />
            <img
              src={
                require("../../../assets/images/mobile/bg_main_ip.svg").default
              }
              alt="이미지"
              onClick={() => history.push("/intro/loans")}
            />
            <img
              src={
                require("../../../assets/images/mobile/bg_main_invest.svg")
                  .default
              }
              alt="이미지"
              onClick={() => history.push("/intro/investment")}
              style={{ height: 180 }}
            />
          </div>
        </div>

        <img
          className="intro_02"
          src={require("../../../assets/images/mobile/bg_intro_02.svg").default}
          alt="이미지"
        />

        <div className="wrap_intro">
          <img
            className="intro_03"
            src={
              require("../../../assets/images/mobile/bg_intro_03.svg").default
            }
            alt="이미지"
          />
          <button className="btn_free" onClick={onClickGoSignUp} />
        </div>
        <img
          className="intro_04"
          src={require("../../../assets/images/mobile/bg_intro_04.svg").default}
          alt="이미지"
        />
        <img
          className="intro_05"
          src={require("../../../assets/images/mobile/bg_intro_05.svg").default}
          alt="이미지"
        />

        <button className="btn_go_signup_yello" onClick={onClickGoSignUp}>
          회원가입 하러가기
        </button>

        <img
          className="intro_06"
          src={require("../../../assets/images/mobile/bg_intro_06.svg").default}
          alt="이미지"
        />
        <img
          className="intro_07"
          src={require("../../../assets/images/mobile/bg_intro_07.svg").default}
          alt="이미지"
        />

        <div className="side_signup last" style={{ minHeight: 115 }}>
          <p>2가지 중 어떤 방법으로 IP 관리하시겠습니까?</p>
          <button className="btn_go_signup" onClick={onClickGoSignUp} />
        </div>
      </div>
      {popupShow && (
        <CalcAnnualFeePopup
          companyName={companyName}
          data={arr}
          apply_at={apply_at}
          patent={patent}
          onClickClose={onClickClose}
        />
      )}
    </>
  );
}

export default IntroIpNow;
