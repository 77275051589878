import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./PatentGrade.scss";
import PatentAPI from "../../../API/patent";

function PatentGrade() {
  const { patent_idx } = useParams();
  const [patentInfo, setPatentInfo] = useState({
    manage_number: "",
    nation: "",
  });
  const gradeRef = useRef();

  useEffect(() => {
    PatentAPI.getInfo(patent_idx).then((res) => {
      let data = res.data;
      setPatentInfo(data);

      for (let i = 0; i < gradeRef.current.childNodes.length; i++) {
        let item = gradeRef.current.childNodes[i];
        let dataset = item.dataset.grade;
        let sm_grade = data.sm_grade;

        if (dataset == sm_grade) {
          item.classList.add("grade_" + item.dataset.grade);
        }

        if (dataset == "N") {
          item.classList.add("grade_default");
        }
      }
    });
  }, []);

  return (
    <div id="PatentGrade">
      <div className="header">
        <h2 className="title">특허 등급 상세페이지</h2>
        <div className="patent_info">
          관리번호{" "}
          {patentInfo.manage_number != "null"
            ? patentInfo.manage_number
            : "N/A"}
          <i
            className={`icon_flag_${window.$Global.convertNationCodeToStr(
              patentInfo.nation
            )} flag`}
          />
        </div>
        <div className="patent_info">
          등록번호{" "}
          {patentInfo.register_number != "null"
            ? patentInfo.register_number
            : "N/A"}
        </div>
      </div>

      <div className="inner_txt">
        <h2>SMART5 등급</h2>
        <p>한국발명진흥회 Smart5 특허분석시스템에서 제공하는 특허 등급</p>
      </div>
      <div className="wrap_box">
        <div className="box one-third">
          <div className="inner">
            <div className={`circle grade_${patentInfo.sm_grade || "default"}`}>
              {patentInfo.sm_grade || "N"}
            </div>
            <p className="color_main">특허평가등급</p>
          </div>
          <ul className="circle_grade">
            <li>
              <h2>권리성 등급</h2>
              <p>{patentInfo.sm_r_grade || "N"}</p>
            </li>
            <li>
              <h2>기술성 등급</h2>
              <p>{patentInfo.sm_t_grade || "N"}</p>
            </li>
            <li>
              <h2>활용성 등급</h2>
              <p>{patentInfo.sm_u_grade || "N"}</p>
            </li>
          </ul>
        </div>
        <div className="box two-third">
          <h2>등급체계 비교표</h2>
          <ul className="smart3 grade" ref={gradeRef}>
            <li>Smart5</li>
            <li data-grade="AAA">AAA</li>
            <li data-grade="AA">AA</li>
            <li data-grade="A">A</li>
            <li data-grade="BBB">BBB</li>
            <li data-grade="BB">BB</li>
            <li data-grade="B">B</li>
            <li data-grade="CCC">CCC</li>
            <li data-grade="CC">CC</li>
            <li data-grade="C">C</li>
            <li data-grade="N">N</li>
          </ul>
          <ul className="smart3">
            <li>9등급 기준</li>
            <li>1</li>
            <li>2</li>
            <li>3</li>
            <li>4</li>
            <li>5</li>
            <li>6</li>
            <li>7</li>
            <li>8</li>
            <li>9</li>
            <li>정보없음</li>
          </ul>
        </div>
      </div>

      <div className="inner_txt">
        <h2>NOW 사업성 등급</h2>
        <p>
          SMART5 + 사업성 평가 요소 + 가중치 변경, 전문가와 협업을 통해 IP의
          사업성에 초점을 맞춘 IP등급
        </p>
      </div>
      <div className="box now_grade">
        <div className="inner">
          <div
            className={`circle grade_${
              patentInfo.now_grade
                ?.toString()
                .replace(/-/g, "")
                .replace(/\+/g, "")
                .replace(/0/g, "") || "default"
            }`}
          >
            {patentInfo.now_grade || "N"}
          </div>
        </div>
        <ul className="circle_grade">
          <li>
            <h2 className="color_main">종합 등급</h2>
            <p>{patentInfo.now_grade || "N"}</p>
          </li>
        </ul>
        {/*<img src={require('../../../assets/images/common/bg_patent_grade_graph.png').default} className="bg_image" alt="이미지"/>*/}
      </div>
    </div>
  );
}

export default PatentGrade;
