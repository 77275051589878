import React, { useContext } from "react";
import PatentContext from "../../../store/Patent";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import { Link } from "react-router-dom";
import DatePickerRow from "./common/DatePickerRow";
import FileRow from "./common/FileRow";
import InputAndFileRow from "./common/InputAndFileRow";
import SelectRow from "./common/SelectRow";
import DivideApplyRow from "./common/DivideApplyRow";
import ClaimCountRow from "./common/ClaimCountRow";
import FormSelfCorrecting from "./FormSelfCorrecting";

function FormAgentUs({
  ListOaTag,
  agentCompanyListTag,
  managerSelectTag,
  checkAuth,
  inventorListTag,
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeInput,
  onClickClaim,
  onClickFirstClaim,
  onChangeDatePickerInnerDate,
  onChangeClaimCount,
  onChangeDatePicker,
}) {
  const patentInfo = useContext(PatentContext);

  return (
    <div className="form">
      <div className="field">
        <div className="rows">
          <InputRow
            title="대리인 관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.agent_manage_number}
            onChange={onChangeInput}
            setKey="agent_manage_number"
          />
        </div>
        <div className="rows">
          <NormalRow title="관리번호" content={patentInfo.manage_number} />
          <NormalRow title="유형" content={patentInfo.type} />
          <div className="row">
            <h2 className="title">
              Family 출원
              <Link
                className="icon_plus"
                to={`/patent/family/${patentInfo.idx}`}
              />
            </h2>
            <p>{patentInfo.family_cnt}건</p>
          </div>
        </div>
        <div className="rows">
          <NormalRow title="담당자" content={patentInfo.manager_name} />
          <NormalRow
            title="발명자 1"
            content={patentInfo.inventor.length && patentInfo.inventor[0].name}
          />
          <SelectRow
            title="대리인 담당자"
            content={patentInfo.agent_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                agent_idx: e.target.value,
                agent_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            condition={patentInfo.editing}
            contentIdx={patentInfo.agent_idx}
            selectTag={managerSelectTag}
          />
        </div>
      </div>
      {inventorListTag}
      {patentInfo.mergeTag}
      <div className="field">
        <div className="rows">
          <NormalRow
            title={"번역문\n초안작성 지시 접수일"}
            content={patentInfo.draft_translation_order_at}
            txtType="date"
          />
          <InnerDatePickerAndFileRow
            title={"번역문\n초안 전달"}
            data={patentInfo.translateDraftReceivingDate}
            haveFile={Boolean(patentInfo.translation_draft_file_cnt)}
            dateBtnHide={true}
            uploadBtnShow={true}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft_translation, {
                modified: 0,
              })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_draft_translation, {
                modified: 0,
              })
            }
          />
          <DateAndFileRow
            title={`번역문\n초안 수정본 접수${
              patentInfo.draft_translation_modified_upload_at !== null
                ? "일"
                : ""
            }`}
            date={patentInfo.draft_translation_modified_upload_at}
            haveFile={Boolean(patentInfo.translation_modified_draft_file_cnt)}
            uploadBtnShow={false}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft_translation, {
                modified: 1,
              })
            }
          />
        </div>
        <div className="rows">
          <InnerDatePickerAndFileRow
            title={"번역문 초안\n최종본 전달"}
            data={patentInfo.translateDraftFinalDate}
            haveFile={Boolean(patentInfo.translation_final_draft_file_cnt)}
            dateBtnHide={true}
            uploadBtnShow={true}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft_translation, {
                modified: 2,
              })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_draft_translation, {
                modified: 2,
              })
            }
          />
          <div className="row no_data" />
          <NormalRow
            title="번역문 기한"
            titleClassName="color_blue"
            content={patentInfo.translation_deadline}
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="출원 지시 접수일"
            titleClassName="color_blue"
            content={patentInfo.apply_order_at}
          />
        </div>
      </div>
      <div className="field">
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            onClickDownPopShow={() => onClickDownPopShow(typeName.agent_apply)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.agent_apply)}
          />
          <DatePickerRow
            title="출원일"
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="우선일"
            content={patentInfo.priority_at}
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <FileRow
            title="IDS 제출"
            haveFile={patentInfo.ids_file_cnt}
            onClickDownPopShow={() => onClickDownPopShow(typeName.agent_ids)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.agent_ids)}
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
        </div>
      </div>
      {ListOaTag}
      <FormSelfCorrecting
        onClickDownPopShow={onClickDownPopShow}
        onClickUpPopShow={onClickUpPopShow}
        onChangeDatePicker={onChangeDatePicker}
      />
      <div className="field">
        <div className="rows">
          <DatePickerRow
            title="등록 결정일"
            titleClassName="color_blue"
            condition={patentInfo.editing}
            data={patentInfo.register_decision_at}
            dataKey="register_decision_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <DivideApplyRow
            editingClass={patentInfo.editingClass}
            divideApplication={patentInfo.divideApplication}
            toggleSelected={() =>
              patentInfo.setPatent({
                ...patentInfo,
                divideApplication: {
                  ...patentInfo.divideApplication,
                  value: !patentInfo.divideApplication.value,
                },
              })
            }
          />
          <DatePickerRow
            title="등록일"
            condition={patentInfo.editing}
            data={patentInfo.register_at}
            dataKey="register_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="rows">
          <InputAndFileRow
            title="등록번호"
            content={patentInfo.register_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.register_file_cnt)}
            onChange={(e) => onChangeInput("register_number", e.target.value)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_register)
            }
            onClickUpPopShow={() => onClickUpPopShow(typeName.agent_register)}
          />
          <NormalRow
            title="존속기간 만료일"
            titleClassName="color_blue"
            content={patentInfo.expiration_at}
            contentClassName="color_blue"
            txtType="date"
          />
          <NormalRow
            title="등록기한"
            titleClassName="color_blue"
            content={patentInfo.register_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="CA 기한"
            titleClassName="color_blue"
            content={patentInfo.ca_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>

      <div className="field">
        <div className="rows">
          <NormalRow
            title="납부년차"
            titleClassName="color_blue"
            content={
              patentInfo.payment_year && patentInfo.payment_year + "년차"
            }
            contentClassName="color_blue"
          />
          <ClaimCountRow
            editing={patentInfo.editing}
            claimCount={patentInfo.claimCount}
            onChangeIndependence={(e) =>
              onChangeClaimCount("independence", e.target.value)
            }
            onChangeDependent={(e) =>
              onChangeClaimCount("dependent", e.target.value)
            }
          />
          <NormalRow
            title="연차료 기한"
            titleClassName="color_blue"
            content={patentInfo.annual_payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        {/*<div className="rows">*/}
        {/*<NormalRow title='연차관리 회사' content={patentInfo.annual_payment_manage_company}/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default FormAgentUs;
