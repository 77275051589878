import "./css/ToggleButton.scss";

const ToggleButton = ({ isOpen, toggleOpen }) => {
  return (
    <button onClick={toggleOpen} className="toggle-button">
      {isOpen ? (
        <i className="icon_more_arrow_up"></i>
      ) : (
        <i className="icon_more_arrow_down_black"></i>
      )}
    </button>
  );
};

export default ToggleButton;
