import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CustomSwitch from "./common/CustomSwitch";
import MailingRecept from "../components/mailing/MailingRecept";
import MailingIsGiveup from "../components/mailing/MailingIsGiveup";
import MailingIsPaid from "../components/mailing/MailingIsPaid";
import Birthday from "../components/mailing/Birthday";
import aesMailTestfrom from "../components/mailing/aesMailTest";
import Confirm_IsPaid from "../components/mailing/confirm_IsPaid";
import Confirm_IsGiveup from "../components/mailing/confirm_IsGiveup";
function MailingRoute({ match }) {
  return (
    <CustomSwitch>
      {/* 납부, 미납부 */}
      <UserAuthRoute
        exact
        path={`${match.path}/isPaid`}
        requireAuth={false}
        component={MailingIsPaid}
      />
      {/* 수용, 포기 */}
      <UserAuthRoute
        exact
        requireAuth={false}
        path={`${match.path}/isGiveup`}
        component={MailingIsGiveup}
      />
      {/* 메일 수신 여부 판단 */}
      <UserAuthRoute
        exact
        path={`${match.path}/isRecept`}
        requireAuth={false}
        component={MailingRecept}
      />
      {/* 생일 체크 */}
      <UserAuthRoute
        exact
        path={`${match.path}/Birthday`}
        requireAuth={false}
        component={Birthday}
      />
      {/* 메일 수신 여부 판단 */}
      <UserAuthRoute
        exact
        path={`${match.path}/aesMailTest`}
        requireAuth={false}
        component={aesMailTestfrom}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/confirm_isgiveup`}
        requireAuth={false}
        component={Confirm_IsGiveup}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/confirm_ispaid`}
        requireAuth={false}
        component={Confirm_IsPaid}
      />
    </CustomSwitch>
  );
}

export default MailingRoute;
