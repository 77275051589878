import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import DocTradeSample from "../components/docTrade/DocTradeSample";
import DocTradeMyTransaction from "../components/docTrade/DocTradeMyTransaction";
import DocTradeSampleRegister from "../components/docTrade/DocTradeSampleRegister";
import DocTradeTransaction from "../components/docTrade/DocTradeTransaction";
import DocTradeSamplePurchase from "../components/docTrade/DocTradeSamplePurchase";
import CustomSwitch from "./common/CustomSwitch";

function DocTradeRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={false} component={DocTradeSample}/>
			<UserAuthRoute exact path={`${match.path}/my`} requireAuth={true} component={DocTradeMyTransaction}/>
			<UserAuthRoute exact path={`${match.path}/add`} requireAuth={true} component={DocTradeSampleRegister}/>
			<UserAuthRoute exact path={`${match.path}/view/:idx`} requireAuth={false} component={DocTradeTransaction}/>
			<UserAuthRoute exact path={`${match.path}/payment/:idx`} requireAuth={true} component={DocTradeSamplePurchase}/>
		</CustomSwitch>
	)
}

export default DocTradeRoute;