import React, {useState, useEffect, useContext} from "react";
import "./css/TradeMain.scss";
import PopupFilterPrice from "./PopupFilterPrice";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import TradeList from "./TradeList";
import CommonAPI from "../../API/common";
import TradeItem from "./TradeItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonContext from "../../store/Common";
import config from "./const/index";
import qs from "query-string";

function TradeMain({history, location}) {
    const {isMobile} = useContext(CommonContext);
    const count = 100;
    // 모바일
    const [moMenuShow, setMoMenuShow] = useState(false);
    const [recommend, setRecommend] = useState(0);

    // pc
    const [recommendList, setRecommendList] = useState({items: []});
    const {isSearchKeyword, isPriceRange, min, max} = qs.parse(location.search);
    const isSearch = isSearchKeyword || isPriceRange;

    // 공용
    const [type, setType] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [list, setList] = useState({items: []});
    const [priceRange, setPriceRange] = useState([config.min, config.max]);

    useEffect(() => {
        if (!isSearchKeyword && !isPriceRange) {
            setKeyword('');
            setPriceRange([config.min, config.max]);
            getList();
        } else {
            setKeyword(isSearchKeyword);
            setPriceRange([min, max]);
            getSearchList();
        }
    }, [isSearchKeyword, isPriceRange, min, max])

    useEffect(() => {
        if (isMobile) {
            window.history.pushState(null, document.title, window.location.href);
        }
        window.addEventListener('popstate', closeBack);
        return () => {
            window.removeEventListener('popstate', closeBack);
        }
    }, []);
    
    const closeBack = () => {
        if (isMobile) {
            setMoMenuShow(false);
            window.history.pushState(null, document.title, window.location.href);
        }
    }

    const getList = (payload, next) => {
        CommonAPI.getList(getPayload()).then(res => {
            let data = res.data;

            if (next) {
                data.items = list.items.concat(data.items);
                setCurPage(curPage + 1);
            }

            data.items = data.items.filter(item => item.state != 1);
            setHasMore(data.last > data.page);
            setList(data);
        });

        if (!isSearch && !recommendList.items.length) {
            let payload = {
                page: 1,
                count: count,
                recommanded: 1
            }
            CommonAPI.getList(payload).then(res => {
                let data = res.data;
                if (next) {
                    data.items = recommendList.items.concat(data.items);
                }
                setRecommendList(data);
            });
        }
    };

    const getSearchList = () => {
        CommonAPI.getList(getPayload(true)).then(res => {
            let data = res.data;
            data.items = data.items.filter(item => item.state != 1);
            setHasMore(data.last > data.page);
            setList(data);
        });
    };

    const onClickSearch = () => {
        history.push(`?isSearchKeyword=${keyword}&isPriceRange=true&min=${priceRange[0]}&max=${priceRange[1]}`);
    }

    const getPayload = (page) => {
        let min_price = min || priceRange[0];
        let max_price = max || priceRange[1];
        let add = {};

        if (page) {
            setCurPage(1);
        }

        if (min_price == config.min && max_price == config.max) {
            min_price = null;
            max_price = null;
        }

        if (Boolean(isSearch)) {
            add = {
                keyword: keyword || isSearchKeyword,
                min_price: min_price,
                max_price: max_price
            };
        }

        return {
            page: curPage,
            count: count,
            type: type,
            recommanded: recommend,
            ...add
        };
    };

    const onClickNextList = () => {
        getList(null, true);
    }

    const search = () => {
        history.push({
            pathname: '/trade/result',
            search: `?keyword=${keyword || ''}&type=${type}&min=${priceRange[0] || ''}&max=${priceRange[1] || ''}`
        });
    }

    const FuncList = {
        getPrice: () => {
            return priceRange
        },
        onClose: () => setMoMenuShow(false),
        setPrice: (value) => {
            setPriceRange(value);
            setMoMenuShow(false);
        }
    };

    const ComponentWithDimmed = WithPreventScroll(PopupFilterPrice, (moMenuShow && isMobile), FuncList);

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        touchMove: false
    };

    return (
        <div id="TradeMain">
            <div className="pc">
                <div className="banner">
                    <div className="txt">
                        <p>
                            쉽고 간단하게<br/>
                            내 특허&상표의 최고가에 도전하세요.
                        </p>
                        <p>믿을 수 있는 IP거래</p>
                        <p>지금 경험해 보세요.</p>
                    </div>
                    <img src={require(`../../assets/images/common/bg_trade_main_image.png`).default} alt="메인 이미지"/>
                </div>
                <div className="wrap">
                    <div className={`w_head ${isSearch ? 'border_line' : ''}`}>
                        <div className="search_area">
                            <h2 className="title">거래 검색하기</h2>
                            <div className="inner">
                                <select onChange={e => setType(e.target.value)}>
                                    <option value={1}>상표명</option>
                                    <option value={0}>지정상표군</option>
                                </select>
                                <input type="text"
                                       placeholder="상표 정보를 입력해주세요"
                                       value={keyword}
                                       onChange={e => setKeyword(e.target.value)}
                                       onKeyUp={(e) => {if(e.key === 'Enter') onClickSearch();}}
                                />
                                <button className="btn_search icon_search_white" onClick={() => onClickSearch()}/>
                                <button className="icon_condition" onClick={() => setMoMenuShow(true)}/>
                            </div>
                        </div>
                        <div className="my_trade_area">
                            <h2 className="title">나의 거래</h2>
                            <div className="inner">
                                <button className="btn_my_trade" onClick={() => history.push('/trade/my')}>거래 확인하기</button>
                                <button className="btn_trade_post" onClick={() => history.push('/trade/post')}>
                                    <i className="icon_posting_pencil"/>
                                    <span>거래 올리기</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        !isSearch
                        &&
                        <div className="bg">
                            <div className="wrap_list">
                                <div className="l_head">
                                    <h2>추천 상표</h2>
                                </div>

                                <Slider {...settings}>
                                    {
                                        recommendList.items.map((item, idx) => {
                                            return (
                                                <TradeItem key={idx} item={item}/>
                                            )
                                        })
                                    }
                                </Slider>
                                {
                                    !Boolean(recommendList.items.length)
                                    &&
                                    <p>추천 상표가 없습니다</p>
                                }
                            </div>
                        </div>
                    }
                    <div className="wrap_list">
                        <div className="l_head">
                            <h2>
                                {
                                    isSearch
                                    ?   '검색결과'
                                    :   '판매중인 상표'
                                }
                                {
                                    isSearch
                                    &&
                                    <span>{list.total_count}건</span>
                                }
                            </h2>
                        </div>
                        <div className="search_result">
                            {
                                (isSearch && !(min == config.min && max == config.max))
                                &&
                                <p>
                                    {
                                        window.$Global.numberToKorean(min) + '~' + window.$Global.numberToKorean(max)
                                    }
                                </p>
                            }
                            {
                                (isSearch && !Boolean(list.items.length))
                                &&
                                <p>
                                    {
                                        isSearchKeyword
                                        &&
                                        <>
                                            <b>{isSearchKeyword}</b>에 대한
                                        </>
                                    }
                                    검색결과가 없습니다.
                                </p>
                            }
                        </div>
                        <div className="list">
                            {
                                list.items.map((item, idx) => {
                                    return (
                                        <TradeItem key={idx} item={item}/>
                                    )
                                })
                            }
                        </div>
                        {
                            hasMore
                            &&
                            <button className="btn_more" onClick={onClickNextList}>상표 더보기</button>
                        }
                    </div>
                </div>
            </div>
            <div className="mo">
                <div className="banner">
                    <h2>상표거래 오픈마켓</h2>
                    <p>쉽고 간단하게<br/>내 상표의 최고가에 도전하세요.</p>
                </div>
                <div className="search_area">
                    <div className="s_head">
                        <h2>거래 검색하기</h2>
                        <button onClick={() => history.push('my')}>나의 거래 확인하기</button>
                    </div>
                    <div className="wrap_search_form">
                        <div className="search_form">
                            <select onChange={e => setType(e.target.value)}>
                                <option value={1}>상표명</option>
                                <option value={0}>지정상표군</option>
                            </select>
                            <input type="text"
                                   placeholder="상표 정보를 입력해주세요"
                                   value={keyword}
                                   onChange={e => setKeyword(e.target.value)}
                                   onKeyUp={(e) => {if(e.key === 'Enter') search();}}
                            />
                            <button className="icon_m_search" onClick={() => search()}/>
                        </div>
                        <button className="btn_search_add" onClick={() => history.push('/trade/notify')}>관심 알림 설정</button>
                        <button className="btn_price" onClick={() => setMoMenuShow(true)}>
                            <i className="icon_condition"/>
                            가격 필터
                        </button>
                    </div>
                </div>
                <TradeList title='추천 상표'
                           activeDepth={1}
                           list={recommendList.items}
                           hasMore={false}
                           onClickNextList={onClickNextList}
                />
                <TradeList title='판매 중인 상표'
                           activeDepth={0}
                           list={list.items}
                           hasMore={hasMore}
                           onClickNextList={onClickNextList}
                />
                <div className="floating" onClick={() => history.push('post')}>
                    <i className="icon_posting_pencil"/>
                    <p>거래 올리기</p>
                </div>
            </div>
            {
                moMenuShow && <ComponentWithDimmed/>
            }
        </div>
    )
}
export default TradeMain;