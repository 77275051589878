import React from "react";

function InputPhone({ able, check, signInfo, setSignInfo, onClick, required }) {
  return (
    <div className={`field ${able ? "" : "action_no"}`}>
      <div className="f_head">
        {required && <i className="icon_red_dot" />}
        가입자 전화번호
      </div>
      <div className="f_content">
        <input
          type="number"
          className={`${check ? "disabled" : ""}`}
          maxLength="11"
          style={{ width: 170 }}
          value={signInfo.phone}
          onChange={(e) => setSignInfo({ ...signInfo, phone: e.target.value })}
          placeholder="“-” 구분없이 입력"
        />
        {/*<button className={`btn_check ${check ? 'btn_no' : ''}`} onClick={onClick}>{check ? '인증완료' : '본인인증'}</button>*/}
      </div>
    </div>
  );
}

export default InputPhone;
