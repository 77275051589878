import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import ExpectationAnnual from "../components/annualFee/ExpectationAnnual";
import MExpectationAnnual from "../components/annualFee/mobile/MExpectationAnnual";
import AnnualFeeList from "../components/annualFee/AnnualFeeList";
import AnnualFeeManage from "../components/annualFee/AnnualFeeManage";
import CustomSwitch from "./common/CustomSwitch";
import AnnualFeeCompare from "../components/annualFee/AnnualFeeCompare";
import AnnualFeeGiveUpAndReview from "../components/annualFee/AnnualFeeGiveUpAndReview";

function AnnualRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/graph`}
        requireAuth={true}
        component={ExpectationAnnual}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/mgraph`}
        requireAuth={true}
        component={MExpectationAnnual}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/:year/list`}
        requireAuth={true}
        component={AnnualFeeList}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/manage/:type_idx`}
        requireAuth={true}
        component={AnnualFeeManage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/compare`}
        requireAuth={true}
        component={AnnualFeeCompare}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list/review`}
        requireAuth={true}
        component={AnnualFeeGiveUpAndReview}
      />
    </CustomSwitch>
  );
}

export default AnnualRoute;
