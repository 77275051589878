import React, {useContext, useEffect, useRef, useState} from "react";
import "./css/DocTradeMyTransactionSwitchPoint.scss";
import DocTradeMyTransactionSwitchPointConversionRow from './DocTradeMyTransactionSwitchPointConversionRow';
import CommonAPI from "../../API/common";
import MemberAPI from "../../API/member";
import Validator from "../../util/validator";

function DocTradeMyTransactionSwitchPoint({history}) {
    const [amount, setAmount] = useState(0);
    const refs = {
        account_bank: useRef(),
        account_name: useRef(),
        account_number: useRef(),
    }
    const [info, setInfo] = useState({
        hasAmount: amount,
        amount : 0,
        account_bank: "",
        account_name:  "",
        account_number: ""
    });

    useEffect(() => {
        MemberAPI.getInfo().then(res => {
            sessionStorage.setItem('amount', res.data.amount);
            setAmount(res.data.amount);
        });
    }, []);

    const apply = () => {
        if (Validator.refValidator(Object.keys(refs).map(item => refs[item]))) {
            CommonAPI.withdraw(info).then(() => {
                alert("출금 신청되었습니다");
                history.go(0);
            });
        }
    };

    return (
        <div id="DocTradeMyTransactionSwitchPoint" className="switchPoint">
            <div className="switchPoint_box">
                <h2 className="switchPoint_box_title">출금 전 안내사항</h2>
                <p className="switchPoint_box_text">
                    - 수익금&#40;판매수익&#41;을 회윈님의 은행계좌로 이체합니다.<br />
                    - 최소 출금신청 가능한 금액은 5,000원으로 이체수수료 500원을 포함한 잔액이 5,500원 이상인 경우 신청 가능합니다.<br />
                    <em className="style_light">예시&#41; 수익금 잔액 : 6,000원 → 출금 가능금액 : 5,000원, 이체수수료 500원 ㅣ 출금 후 잔액 500원</em><br />
                    <em className="style_strong">- 백원단위</em>로 신청이 가능합니다.<br />
                    <em className="style_strong">- 신청금액이 25만원을 초과</em>하는 경우 소득세와 주민세&#40;4.4%&#41;가 포함된 금액이 수익금 계정에서 출금처리 됩니다.<br />
                    {/*- 출금신청 시 최초 1회에 한해 실명확인을 하고 있으며, 이후에는 개인정보를 수정할 수 없습니다.<br />*/}
                    {/*<em className="style_light">단, 개명으로 인해 이름을 변경하고자 하실 경우 관리자에게 문의하세요.</em><br />*/}
                    - 반드시 본인명의 계좌로 신청이 가능하며, 계좌번호를 잘못 입력하여 출금된 경우 아이피나우에서는 책임지지 않습니다.<br />
                    - 신청한 다음 영업일 회원님의 은행계좌에 입금해드립니다. &#40;은행 영업일 기준&#41;
                </p>
            </div>
            <div className="switchPoint_conversion">
                <div className="switchPoint_title-wrap">
                    <h2 className="switchPoint_title">포인트 전환</h2>
                    <div className="switchPoint_button-section">
                        <p className="switchPoint_button-section_text">수익금이 5,500원 이상일 때 출금가능합니다.</p>
                        {
                            Boolean(amount >= 5500)
                            &&
                            <button className="switchPoint_button-section_btn" onClick={apply}>출금 신청하기</button>
                        }
                    </div>
                </div>
                <table className="switchPoint_table">
                    <caption className="hidden">총 보유 포인트 보기 및 출금 포인트</caption>
                    <thead className="switchPoint_table_head">
                        <tr>
                            <th scope="col">총 보유 포인트</th>
                            <th scope="col">출금 포인트</th>
                            <th scope="col">은행</th>
                            <th scope="col">계좌번호</th>
                            <th scope="col">예금주명</th>
                        </tr>
                    </thead>
                    <tbody className="switchPoint_table_body">
                        <DocTradeMyTransactionSwitchPointConversionRow info={info} setInfo={setInfo} refs={refs}/>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DocTradeMyTransactionSwitchPoint;