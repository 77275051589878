import DatePicker from "react-datepicker";
import React from "react";
import "./DateRange.scss";

const DateRange = ({ startDate, setStartDate, endDate, setEndDate }) => {
  return (
    <div id="DateRange">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        showIcon
        isClearable
        dateFormat="yyyyMMdd"
        placeholder="ex) yyyymmdd"
      />
      <p>~</p>
      <DatePicker
        selected={endDate}
        onChange={(date) => {
          if (date < startDate) {
            alert("종료일이 시작일보다 빠를 수 없습니다.");
          } else {
            setEndDate(date);
          }
        }}
        showIcon
        isClearable
        dateFormat="yyyyMMdd"
        placeholder="ex) yyyymmdd"
      />
    </div>
  );
};

export default DateRange;
