import React from "react";
import Styles from "./table.module.scss";

const Table = ({ headersFields, records = [], onDelete, onEdit }) => {
  return (
    <table className={Styles["table"]}>
      <thead>
        <tr>
          {headersFields.map(({ title, name }) => (
            <th key={name}>{title}</th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {records.map((record, recordIndex) => (
          <tr key={record.idx}>
            {headersFields.map(({ name }, index) => (
              <td key={`${record.idx}-${index}`}>{typeof record[name] === 'number' ? window.$Global.numberWithCommas(record[name]) : record[name]}</td>
            ))}
            <td>
              <div className={Styles["edit-buttons"]}>
                <button onClick={() => onEdit(record.idx)} type="button">수정</button>
                <button onClick={() => onDelete(record.idx)} type="button">
                  삭제
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
