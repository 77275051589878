import React from "react";
import GovSupportMain from "../components/govSupport/GovSupportMain";
import UserAuthRoute from "./common/UserAuthRoute";
import GovSupportDetail from "../components/govSupport/GovSupportDetail";
import CustomSwitch from "./common/CustomSwitch";
import PatentList from "../components/patent/PatentListView";
import CacheRoute from "react-router-cache-route";

function GovSupportRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`} component={GovSupportMain} when="always"/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={false} component={GovSupportDetail}/>
		</CustomSwitch>
	)
}

export default GovSupportRoute;