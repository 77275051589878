import React from "react";
import "./css/PopupTaskManage.scss";

function PopupTaskManage({onClose}) {
    return (
        <div id="PopupTaskManage">
            <i className="icon_bulb"/>
            <h2>관심 공고 조건이 설정되었습니다.</h2>
            <div className="noti">
                <i className="icon_alert_circle"/>설정한 조건의 공고가 등록될 경우 메일로 전송됩니다.
            </div>
            <button className='btn_confirm' onClick={onClose}>확인</button>
        </div>
    )
};

export default PopupTaskManage;