import React, {useContext} from "react";
import NormalRow from "../../row/NormalRow";
import FileRow from "../../row/FileRow";
import InputRow from "../../row/InputRow";
import BillingContext from "../../../../store/Billing";

function DefaultKRField({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;
    return (
        <div className="field">
            <div className="rows">
                <NormalRow title="국가" content={window.$Global.convertNationCodeToStr(billObj.nation)}/>
                <div className="row no_data"/>
                <FileRow title="첨부 파일" onClickUpPopShow={() => billing.func.onClickUpPopShow("attach_file", billObj)} onClickDownPopShow={() => billing.func.onClickDownPopShow("attach_file")}/>
            </div>
            <div className="rows">
                <NormalRow title="대리인 관리번호" content={billObj.agent_manage_number}/>
                <NormalRow title="출원인 관리번호" content={billObj.manage_number}/>
                <InputRow title="청구번호" titleClassName="color_blue" condition={edit} content={billObj.billing_number} onChange={e => billing.func.onChangeInput(e.target.value, "billing_number", billing)}/>
            </div>
            <div className="rows">
                <NormalRow title="기업 담당자" content={billObj.manager_name}/>
                <NormalRow title="대리인 담당자" titleClassName="color_blue" content={billObj.patent_agent_name}/>
                <NormalRow title="고객명" content={billObj.company_info[0]?.name}/>
            </div>
        </div>
    );
}

export default DefaultKRField;
