import React, {useEffect, useState} from "react";
import "./css/FreeRequestList.scss";
import PatentAPI from "../../API/patent"

function FreeRequestList({history}) {
    const [tbody, setTbody] = useState(window.$Global.notTd(4, '요청 내역이 없습니다'));

    useEffect(() => {
        PatentAPI.getFreeUsList().then(res => {
            let tag = [];
            if (res.data.length) {
                tag = res.data.map((item, idx) => {
                    let state = item.state == 1 ? "심사중" : item.state == 2 ? "승인 완료" : item.state == 3 ? "반려" : "대기";
                    return (
                        <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{item.patent.join()}</td>
                            <td className={item.state == 3 ? "color_red" : item.state == 2 ? "color_blue" : ""}>
                                {state}
                            </td>
                            <td>
                                <button className="icon_more_red" onClick={() => history.push(item.idx)}/>
                            </td>
                        </tr>
                    );
                });
                setTbody(tag);
            }
        });
    }, []);

    return (
        <div id="FreeRequestList">
            <h2 className="page_title">미국 특허 무료 출원 수익분배 받기</h2>
            <div className="area_box">
                <img src={require('../../assets/images/freeApply/bg_intro.png').default} alt="소개 이미지"/>
            </div>
            <div className="header">
                <h2 className="title">미국 무료 출원 요청 목록</h2>
                <button className="btn_add" onClick={() => history.push('new')}>미국 무료 출원 신청하기</button>
            </div>
            <div className="table-box-wrap not_align">
                <div className="wrap_list">
                    <table>
                        <thead>
                            <tr>
                                <th>순번</th>
                                <th>신청 특허 관리번호</th>
                                <th>승인상태</th>
                                <th>상세보기</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default FreeRequestList;
