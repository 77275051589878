import React from "react";
import { Link } from "react-router-dom";

function ContentPhotoItem({ path, item }) {
    const linkPath = item.url ? 'list' : `${path}/detail/${item.idx}`;

    const redirect = () => {
        if (item.url) {
            window.open(item.url, "_blank");
        }
    };

    return (
        <div className="contentPhotoList_list">
            <div className="pc">
                <div className="contentPhotoList_list_img">
                    <Link to={linkPath} onClick={redirect} >
                        <img src={window.$Global.getCDN(item.thumbnail_key)} alt={item.title} />
                    </Link>
                </div>
                <div className="contentPhotoList_list_box">
                        <h3 className="contentPhotoList_list_box_title">
                            {item.title}
                        </h3>
                    <p className="contentPhotoList_list_box_info-wrap">
                        <p className="contentPhotoList_list_box_date">{window.$Global.convertDate(item.deadline_at)}</p>
                        <p className="contentPhotoList_list_box_info">{item.keyword}</p>
                        <p className="contentPhotoList_list_box_day">
                            <span>D-Day</span>
                            <em className="strong">{window.$Global.getDday(item.deadline_at)}</em>
                        </p>
                    </p>
                </div>
                <div className="contentPhotoList_list_box_fav">
                    {/* <button type="button" className="btn_fav off"> */}
                    {/*<button type="button" className="btn_fav on">*/}
                    {/*    즐겨찾기 등록*/}
                    {/*</button>*/}
                </div>
            </div>
            <div className="mo">
                <div className="contentPhotoList_list-wrap">
                    <div className="contentPhotoList_list_img">
                        <Link to={linkPath} onClick={redirect} >
                            <img src={window.$Global.getCDN(item.thumbnail_key)} alt={item.title} />
                        </Link>
                    </div>
                    <div className="contentPhotoList_list_text">
                        <p className="contentPhotoList_list_info">
                            {item.keyword}
                        </p>
                        <h3 className="contentPhotoList_list_title">
                            {item.title}
                        </h3>
                        <div className="contentPhotoList_list_sub">
                            <div className="contentPhotoList_list_date-wrap">
                                <div className="contentPhotoList_list_day">
                                    <span className="day">
                                        D-{window.$Global.getDday(item.deadline_at) || "day"}
                                    </span>
                                    {/* <span className="today">TODAY</span> */}
                                </div>
                                <div className="contentPhotoList_list_date">
                                    {window.$Global.convertDate(item.deadline_at)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentPhotoItem;