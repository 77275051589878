import React, { useContext } from "react";
import "./css/UsagePrice.scss";
import IntroFindBizPrice from "./findbiz/IntroFindBizPrice";
import { useLocation } from "react-router-dom";
import memberContext from "../../store/Member";

function UsagePrice({ history }) {
  const location = useLocation();
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const isLogin = Boolean(useContext(memberContext).idx);
  const onClickGoSignUp = () => {
    history.push("/signup/type");
  };
  const onClickGoPayment = () => {
    history.push("/manage/price?pageType=payment");
  };
  return (
    <div id="UsagePrice">
      {isViewTypeIpnow ? (
        <>
          <div className="pc">
            <h2 className="p_title">서비스 등급 및 이용료</h2>
            <p className="p_desc">
              {!isLogin && <span>회원가입 후&nbsp;</span>}
              <span className="color_orange">최대 2개월 간</span> 스탠다드
              등급을 <span className="color_orange">무료</span>로 이용할 수
              있습니다.
            </p>

            <button
              className="btn btn_start"
              onClick={isLogin ? onClickGoPayment : onClickGoSignUp}
            >
              2개월 무료로 시작하기
              <i className="icon_next" />
            </button>

            <img
              src={require("../../assets/images/intro/price_table.png").default}
              style={{ margin: "25px auto 24px auto", display: "block" }}
              alt="이미지"
            />
            <button
              className="btn btn_start"
              onClick={isLogin ? onClickGoPayment : onClickGoSignUp}
            >
              2개월 무료로 시작하기
              <i className="icon_next" />
            </button>

            {/*<div className="another_bg green">
              <img
                src={
                  require("../../assets/images/intro/bg_findbiz_plus_add.png")
                    .default
                }
                alt="이미지"
              />
            </div>*/}
          </div>
          <div className="mo">
            <p className="m_desc">
              부담없는 최소가격으로 최고급 서비스를
              <br /> 제공해 드립니다.
            </p>
            <h2 className="m_title">기본기능 평생무료</h2>
            <img
              src={
                require("../../assets/images/intro/bg_now_plus_m.png").default
              }
              alt="이미지"
            />
            <button
              className="btn_free_start"
              onClick={onClickGoSignUp}
              style={{ marginBottom: 50 }}
            >
              2개월 무료로 시작하기
            </button>
            <img
              src={
                require("../../assets/images/intro/bg_usagePrice.svg").default
              }
              alt="이미지"
            />
            <button className="btn_free_start" onClick={onClickGoSignUp}>
              2개월 무료로 시작하기
            </button>
          </div>
        </>
      ) : (
        <IntroFindBizPrice />
      )}
    </div>
  );
}

export default UsagePrice;
