import React, { useEffect, useState, useRef, createRef } from "react";
import "./css/AdminTaskPosting.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import AdminAPI from "../../API/admin/index";
import _ from "lodash";
import qs from "query-string";
import config from "../../const/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AdminTaskPosting({ history, location }) {
  const { idx } = qs.parse(location.search);
  const isPostType = qs.parse(location.search).isPostType || "post";
  const isPost = isPostType == "post";
  const isEdit = isPostType == "edit";
  const isView = isPostType == "view";

  const regExp1 =
    /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9])/;
  const regExp2 =
    /\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9])/;
  const regExp3 = /\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01])/;

  const editorRef = useRef(null);
  const [content, setContent] = useState("");
  const [contentImage, setContentImage] = useState("");
  const [contentCoreContentList, setContentCoreContentList] = useState([]);
  const [deadlineCheck, setDeadlineCheck] = useState(false);
  const [receptionCheck, setReceptionCheck] = useState(false);
  const [deadlineValue, setDeadlineValue] = useState(null);
  const [receptionValue, setReceptionValue] = useState(null);
  const [deadlineStringValue, setDeadlineStringValue] = useState("");
  const [receptionStringValue, setReceptionStringValue] = useState("");
  const [readyValue, setReadyValue] = useState(false);
  const [post, setPost] = useState({
    age: 0,
    region: 0,
    field: 0,
    industry: 0,
    c_q: 0,
    support_target: 0,
    state: 0,
    reception_at_type: 1,
    deadline_at_type: 1,
    reception_at: 0,
    deadline_at: 0,
    ready: 0,
  });
  const [field, setField] = useState({});

  useEffect(() => {
    localStorage.setItem(
      config.keyToken,
      "a23e21f873b8b7df899b2d77cde374cf:76093c6bb908a14764d7b3bd34e4c4684e52384c756268d92f154040334e9c32cbd10ffb96a99478663d75aa596ed7ab82c9dca57db7f1880d98cbf2fd412b4c622d138ee7d1834af5efea058658e61b8575c3e525b63d306e11d9affe6fd68cf185fd51f237a9c4f4ca1febe5fe50a2f6fc81433374071d8592c17f6ebde906134b5d5688477359e417133f9db6c391032c9e8bada8324a8943d89ab3796ee68e0822e6bc216fbeffe9c6e70887cb8f96a59b2e0cab3546068b5c6271e2cd188e4a8023c7c4c34b1021b40d18973d7358ec871f1d6d270a8ca4904b7d36f5a2a302ada20c06279c8437deb4481bd6a67c85118aaa6f91aa09e33d659be20dfd908c538d05197f9cd1956440c034f5a29d146a26ed45d91de4dc4ad523ef3f76"
    );
  }, []);

  useEffect(() => {
    AdminAPI.getTaskField().then((res) => {
      setField(res.data);
    });

    if (isEdit || isView) {
      getInfo();
    }
  }, [isPostType]);

  const onChangeCoreContent = (idx, key, value) => {
    let copy = _.cloneDeep(contentCoreContentList);
    copy[idx][key] = value;
    setContentCoreContentList(copy);
  };

  const coreContentAdd = () => {
    let copy = _.cloneDeep(contentCoreContentList);
    if (contentCoreContentList.length == 5) {
      alert("최대 5개까지만 추가 가능합니다");
      return;
    }

    copy.push({
      ref: createRef(),
      title: "",
      content: "",
    });
    setContentCoreContentList(copy);
  };

  const coreContentRemove = () => {
    let copy = _.cloneDeep(contentCoreContentList);
    copy.splice(copy.length - 1, 1);
    setContentCoreContentList(copy);
  };

  const getInfo = () => {
    AdminAPI.getTaskInfo(idx).then((res) => {
      let data = res.data;
      setPost(data);
      setContent(data.content);
      setContentImage(data.content_image);
      console.log(data);
      let content_core = data.content_core;
      for (let i = 0; i < content_core.length; i++) {
        content_core[i].ref = createRef();
      }
      setContentCoreContentList(content_core);
      setReadyValue(data.ready);
      if (data.reception_at !== undefined) {
        if (data.reception_at_type === 0) {
          setReceptionStringValue(data.reception_at);
          setReceptionCheck(true);
        } else if (data.reception_at_type === 1) {
          setReceptionValue(Number(data.reception_at));
          setReceptionCheck(false);
        }
      }
      if (data.deadline_at !== undefined) {
        if (data.deadline_at_type === 0) {
          setDeadlineStringValue(data.deadline_at);
          setDeadlineCheck(true);
        } else if (data.deadline_at_type === 1) {
          setDeadlineValue(Number(data.deadline_at));
          setDeadlineCheck(false);
        }
      }
      let arr = [];
      for (let i = 0; i < data.content_core.length; i++) {
        let item = data.content_core[i];
        arr.push({
          ref: createRef(),
          title: item.title,
          content: item.content,
        });
      }
    });
  };

  const onClickCheck = (name, e, bool) => {
    let copy = _.cloneDeep(post);
    if (e.target.checked) {
      if (bool) {
        copy[name] = Number(e.target.value);
      } else {
        copy[name] += Number(e.target.value);
      }
    } else {
      copy[name] -= Number(e.target.value);
    }
    setPost(copy);
  };

  const onChangePost = (name, value) => {
    let copy = _.cloneDeep(post);
    copy[name] = value;
    setPost(copy);
    console.log(name, value);
  };

  const convertJsonToHTML = (json, name, type) => {
    if (!json) return;
    let keys = Object.keys(json);

    let checkedList = window.$Global.matchJsonToValue(json, post[name]);

    let result = keys.map((item, idx) => {
      return (
        <label key={idx}>
          <input
            type={type ? type : "radio"}
            name={name}
            defaultChecked={
              checkedList.findIndex((item2) => item2 == json[item]) > -1
            }
            value={json[item]}
            onClick={(e) => onClickCheck(name, e, type != "checkbox")}
          />
          {item}
        </label>
      );
    });
    return result;
  };

  const matchJsonToStr = (json, key, condition) => {
    return window.$Global.matchJsonToStr(json, key, condition);
  };

  const onClickPost = async () => {
    let formData = new FormData();
    let copy = _.cloneDeep(post);
    let parse_result = await convertImage(content);
    formData.append("content", parse_result);
    setContent(parse_result);
    formData.append("preview", contentImage[0]);
    let copy_core = _.cloneDeep(contentCoreContentList).filter(
      (item) => item.title
    );
    let copy_core2 = _.cloneDeep(contentCoreContentList);

    for (let i = 0; i < copy_core.length; i++) {
      let item = copy_core[i];
      if (item.ref) {
        item.content = await convertImage(
          item.ref.current.editor.getContents()
        );
        delete item.ref;
      }
    }

    for (let i = 0; i < copy_core2.length; i++) {
      let item = copy_core2[i];
      if (item.ref) {
        item.content = await convertImage(
          item.ref.current.editor.getContents()
        );
      }
    }
    if (copy_core.length) {
      formData.append("description", copy_core[0].content);
    }
    formData.append("content_core", JSON.stringify(copy_core));
    setContentCoreContentList(copy_core2);

    let keys = Object.keys(copy);
    for (let idx in keys) {
      let key = keys[idx];
      console.log(key, copy[key]);
      if (key === "file") {
        Array.from(copy[key]).forEach((item) => {
          if (item.idx) return;
          formData.append("file", item);
        });
      } else if (key === "reception_at") {
        if (receptionCheck) {
          formData.append(key, receptionStringValue);
        } else {
          let date = new Date(
            isNaN(receptionValue) ? receptionValue : Number(receptionValue)
          );
          if (date == "Invalid Date") {
            formData.append(key, receptionValue);
          } else {
            formData.append(
              key,
              new Date(
                isNaN(receptionValue) ? receptionValue : Number(receptionValue)
              )
                .getTime()
                .toString()
            );
          }
        }
      } else if (key === "deadline_at") {
        if (deadlineCheck) {
          formData.append(key, deadlineStringValue);
        } else {
          let date = new Date(
            isNaN(deadlineValue) ? deadlineValue : Number(deadlineValue)
          );
          if (date == "Invalid Date") {
            formData.append(key, deadlineValue);
          } else {
            formData.append(
              key,
              new Date(
                isNaN(deadlineValue) ? deadlineValue : Number(deadlineValue)
              )
                .getTime()
                .toString()
            );
          }
        }
      } else if (key === "ready") {
        const value = readyValue ? 1 : 0;
        formData.append(key, value);
      } else if (key === "announcement_at") {
        formData.append(key, new Date(copy[key]).getTime());
      } else if (
        key !== "content" &&
        key !== "content_image" &&
        key !== "content_core" &&
        key !== "description"
      ) {
        formData.append(key, copy[key]);
      }
    }
    if (isPost) {
      await AdminAPI.addTask(formData).then((res) => {
        alert("등록되었습니다");
        history.push(
          `/admin/posting/task?isPostType=view&idx=${res.data.task_idx}`
        );
      });
    } else {
      AdminAPI.updateTask(idx, formData).then(() => {
        alert("수정되었습니다");
        history.push(`/admin/posting/task?isPostType=view&idx=${idx}`);
      });
    }
  };

  const convertImage = async (str) => {
    let result = str;
    let src = str
      .toString()
      .match(
        new RegExp('src=\\"data:image\\/([a-zA-Z]*);base64,([^\\"]*)\\"', "g")
      );

    if (src != null) {
      for (let i = 0; i < src.length; i++) {
        let data_uri = src[i].replace('src="', "");
        if (data_uri.substr(0, 5) == "https") {
          continue;
        }
        let dataUri = data_uri.replace('"', "");
        let imgUrl = window.$Global.getCDN(
          await saveFile(window.$Global.dataURItoBlob(dataUri))
        );
        result = result.replace(dataUri, imgUrl);
      }
    }

    return result;
  };

  const saveFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return await AdminAPI.uploadImageTrim(formData).then(
      (res) => res.data[0].file_key
    );
  };

  const onClickFileDelete = (file_idx) => {
    AdminAPI.deleteTaskFile(idx, file_idx).then((res) => {
      getInfo();
    });
  };

  const onClickDelete = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      AdminAPI.deleteTask(idx).then(() => {
        sessionStorage.setItem("refreshFindBiz", "true");
        history.push(`/admin/posting/task/list?type=refresh`);
      });
    }
  };

  return (
    <div id="AdminTaskPosting">
      <div className="header">
        <h2>과제관리 게시글 등록</h2>
        <div className="btns">
          {isPost ? (
            <>
              <button className="btn_cancle" onClick={() => history.goBack()}>
                뒤로
              </button>
              <button className="btn_write" onClick={onClickPost}>
                등록
              </button>
            </>
          ) : isView ? (
            <>
              <button
                className="btn_cancle"
                onClick={() => {
                  sessionStorage.setItem("refreshFindBiz", "true");
                  history.push("/admin/posting/task/list");
                }}
              >
                목록
              </button>
              <button
                className="btn_write"
                onClick={() =>
                  history.push(`/admin/posting/task?isPostType=edit&idx=${idx}`)
                }
              >
                수정
              </button>
              <button
                className="btn_copy"
                onClick={() => history.push(`/admin/posting/task?idx=${idx}`)}
              >
                복사 후 신규 등록
              </button>
              <button className="btn_delete" onClick={onClickDelete}>
                삭제
              </button>
            </>
          ) : (
            <>
              <button
                className="btn_cancle"
                onClick={() =>
                  history.push(`/admin/posting/task?isPostType=view&idx=${idx}`)
                }
              >
                취소
              </button>
              <button className="btn_write" onClick={onClickPost}>
                수정완료
              </button>
            </>
          )}
        </div>
      </div>
      <div className="title">
        <h2>공고명</h2>
        {isView ? (
          <p>{post.title}</p>
        ) : (
          <input
            type="text"
            value={post.title}
            onChange={(e) => onChangePost("title", e.target.value)}
          />
        )}
      </div>
      <div className="title">
        <h2>공고기관</h2>
        {isView ? (
          <p>{post.department_name}</p>
        ) : (
          <input
            type="text"
            value={post.department_name}
            onChange={(e) => onChangePost("department_name", e.target.value)}
          />
        )}
      </div>
      <div className="title">
        <h2>참가가능지역</h2>
        <div className="wrap_select">
          {isView ? (
            <p className="semi">
              {matchJsonToStr(field, "region", post.region)}
            </p>
          ) : (
            convertJsonToHTML(field.region, "region", "checkbox")
          )}
        </div>
      </div>
      <div className="title">
        <h2>현황</h2>
        <div className="wrap_select">
          {isView ? (
            <p className="semi">{matchJsonToStr(field, "state", post.state)}</p>
          ) : (
            convertJsonToHTML(field.state, "state")
          )}
        </div>
      </div>
      <div className="title">
        <h2>분야</h2>
        <div className="wrap_select">
          {isView ? (
            <p className="semi">{matchJsonToStr(field, "field", post.field)}</p>
          ) : (
            convertJsonToHTML(field.field, "field", "checkbox")
          )}
        </div>
      </div>
      <div className="wrap_title">
        <div className="title">
          <h2>접수일</h2>
          {isView ? (
            post.reception_at_type === 0 ? (
              <p className="value">{post.reception_at}</p>
            ) : (
              <p className="value">
                {Number(post.reception_at)
                  ? window.$Global.convertDate(post.reception_at)
                  : post.reception_at}
              </p>
            )
          ) : receptionCheck ? (
            <input
              type="text"
              value={receptionStringValue}
              onChange={(e) => {
                setReceptionStringValue(e.target.value);
                onChangePost("reception_at", receptionStringValue);
                onChangePost("reception_at_type", 0);
              }}
              placeholder={"날짜일 경우 YYYY-MM-DD 형식"}
            />
          ) : (
            <DatePicker
              selected={receptionValue}
              onChange={(receptionValue) => {
                setReceptionValue(receptionValue);
                onChangePost("reception_at", receptionValue);
                onChangePost("reception_at_type", 1);
              }}
              dateFormat="yyyy-MM-dd"
              isClearable
              placeholderText="클릭하시면 달력이 열립니다."
            />
          )}
        </div>
        {!isView && (
          <div className="title">
            <input
              type="checkbox"
              id="reception"
              className="reception_check"
              onChange={() => {
                setReceptionCheck(!receptionCheck);
              }}
              checked={receptionCheck}
            />
            <label form="reception">직접입력</label>
          </div>
        )}
      </div>
      <div className="wrap_title">
        <div className="title">
          <h2>마감일시</h2>
          {isView ? (
            post.deadline_at_type === 0 ? (
              <p className="value">{post.deadline_at}</p>
            ) : (
              <p className="value">
                {Number(post.deadline_at)
                  ? window.$Global.convertDateTime(post.deadline_at)
                  : post.deadline_at}
              </p>
            )
          ) : deadlineCheck ? (
            <input
              type="text"
              value={deadlineStringValue}
              onChange={(e) => {
                setDeadlineStringValue(e.target.value);
                onChangePost("deadline_at", deadlineStringValue);
                onChangePost("deadline_at_type", 0);
              }}
              placeholder={"날짜일 경우 YYYY-MM-DD HH:mm 형식"}
            />
          ) : (
            <DatePicker
              selected={deadlineValue}
              onChange={(deadlineValue) => {
                setDeadlineValue(deadlineValue);
                onChangePost("deadline_at", deadlineValue);
                onChangePost("deadline_at_type", 1);
              }}
              timeInputLabel="Time:"
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeInput
              isClearable
              placeholderText="클릭하시면 달력이 열립니다."
            />
          )}
          {!isView && (
            <div className="title">
              <input
                type="checkbox"
                id="deadline"
                className="deadline_check"
                onChange={() => {
                  setDeadlineCheck(!deadlineCheck);
                  onChangePost("deadline_at_type", deadlineCheck ? 1 : 0);
                }}
                checked={deadlineCheck}
              />
              <label form="deadline">직접입력</label>
            </div>
          )}
        </div>
      </div>
      <div className="title">
        <h2>금액</h2>
        {isView ? (
          <p>{post.amount}</p>
        ) : (
          <input
            type="text"
            value={post.amount}
            onChange={(e) => onChangePost("amount", e.target.value)}
          />
        )}
      </div>
      <div className="title">
        <h2>문의처</h2>
        {isView ? (
          <p>{post.inquries}</p>
        ) : (
          <input
            type="text"
            value={post.inquries}
            onChange={(e) => onChangePost("inquries", e.target.value)}
          />
        )}
      </div>
      <div className="title">
        <h2>공고문 링크</h2>
        {isView ? (
          <p>{post.uri}</p>
        ) : (
          <input
            type="text"
            value={post.uri}
            onChange={(e) => onChangePost("uri", e.target.value)}
          />
        )}
      </div>
      <div className="title">
        <h2>첨부파일</h2>
        {isView ? (
          <p>
            {Boolean(post.file?.length)
              ? Array.from(post.file)?.map((item, idx) => {
                  return (
                    <span key={idx}>
                      {(item.file_name || item.name) +
                        "." +
                        (item.file_type || item.type)}
                      <button
                        className="icon_badge_del"
                        onClick={() => onClickFileDelete(item.idx)}
                      />
                    </span>
                  );
                })
              : "없음"}
          </p>
        ) : (
          <input
            type="file"
            multiple
            onChange={(e) => onChangePost("file", e.target.files)}
          />
        )}
      </div>
      {isPost ? null : (
        <div className="title">
          <h2>요약</h2>
          {isView ? (
            <p>{post.ready ? "ON" : "OFF"}</p>
          ) : (
            <input
              type="checkbox"
              onChange={(e) => {
                setReadyValue(!readyValue);
                onChangePost("ready", readyValue ? 1 : 0);
              }}
              checked={readyValue}
            />
          )}
        </div>
      )}
      {!isView && (
        <div className="btns">
          {contentCoreContentList.length > 1 && (
            <button
              className="btn_remove"
              onClick={() => coreContentRemove(idx)}
            >
              삭제
            </button>
          )}
          <button className="btn_add" onClick={coreContentAdd}>
            추가
          </button>
        </div>
      )}
      {contentCoreContentList.map((item, idx) => {
        return (
          <div key={idx} className="input">
            <div style={{ marginTop: 20 }}>
              <h2>핵심내용정리 {idx + 1}</h2>
            </div>
            {isView ? (
              <p>{item.title}</p>
            ) : (
              <input
                type="text"
                value={item.title}
                onChange={(e) =>
                  onChangeCoreContent(idx, "title", e.target.value)
                }
                placeholder="세부 제목"
              />
            )}
            {isView ? (
              <p
                className="sun-editor-editable"
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            ) : (
              <SunEditor
                lang="ko"
                ref={item.ref}
                defaultValue={item.content}
                setOptions={{
                  height: 200,
                  buttonList: [
                    ["undo", "redo", "font", "fontSize", "formatBlock"],
                    [
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "removeFormat",
                    ],
                    [
                      "fontColor",
                      "hiliteColor",
                      "outdent",
                      "indent",
                      "align",
                      "horizontalRule",
                      "list",
                      "table",
                    ],
                    ["link", "image"],
                    // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                  ],
                }}
              />
            )}
          </div>
        );
      })}
      <div className="input" style={{ margin: "30px 0" }}>
        <h2>공고 내용(이미지)</h2>
        {isView ? (
          <p
            className="sun-editor-editable"
            dangerouslySetInnerHTML={{ __html: contentImage }}
          />
        ) : (
          <input
            type="file"
            onChange={(e) => setContentImage(e.target.files)}
          />
        )}
      </div>

      <div className="input">
        <h2>공고 내용(텍스트)</h2>
        {isView ? (
          <p
            className="sun-editor-editable"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : (
          <SunEditor
            lang="ko"
            ref={editorRef}
            defaultValue={content}
            onChange={(e) => setContent(e)}
            setOptions={{
              height: 400,
              buttonList: [
                ["undo", "redo", "font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "removeFormat",
                ],
                [
                  "fontColor",
                  "hiliteColor",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "table",
                ],
                ["link", "image"],
                // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
              ],
            }}
          />
        )}
      </div>
    </div>
  );
}

export default AdminTaskPosting;
