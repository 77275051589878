import React, {useState, useEffect, useRef} from "react";
import "./css/SlickSlider.scss";
import Slider from "react-slick";

function SlickSlider({ list, onClick }) {
	const sliderImg = useRef();

	const settings = {
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 6,
		infinite: false,
		touchMove: false,
	};
	const mobileSettings = {
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: false,
		arrows: false,
		customPaging: function (i) {
			return (
				<div className={i}>{i + 1} / {this.slideCount}</div>
			);
		},
		dots: true,
		dotsClass: "slick-dots slick-thumb",
	}

	return (
		<div id="SlickSlider">
			<div className="wrap_preview pc">
				<Slider {...settings}>
					{
						list?.map((item, idx) => {
							return (
								<img key={idx} onClick={() => onClick(idx)} src={window.$Global.getCDN(item.file_key)} alt="이미지" />
							);
						})
					}
				</Slider>
			</div>
			<div className="wrap_preview mo">
				<Slider {...mobileSettings}>
					{
						list?.map((item, idx) => {
							return (
								<img key={idx} onClick={() => onClick(idx)} src={window.$Global.getCDN(item.file_key)} alt="이미지" ref={sliderImg} />
							);
						})
					}
				</Slider>
				<div className="wrap_preview_pagination">
				</div>
			</div>
		</div>
	);
}

export default SlickSlider;