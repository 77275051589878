import React, {useState} from "react";
import "./css/ResearchTechMy.scss";
import qs from "query-string";
import ResearchTechRequestList from "./request/ResearchTechRequestList";

function ResearchTechMy({location}) {
	const {category} = qs.parse(location.search);
	const [type, setType] = useState(category || 0);
	const [edit, request] = [0, 1];
	const isEdit = type == edit;
	const isRequest = type == request;

	return (
		<div id="ResearchTechMy" className="researchTechMy">
			<div className="researchTechMy_menu">
				<h2>나의 선행 기술</h2>
				<ul>
					<li className={isEdit ? 'active' : ''} onClick={() => setType(edit)}>선행기술 작성 리스트</li>
				</ul>
			</div>
			<ResearchTechRequestList/>
		</div>
	)
}

export default ResearchTechMy;