import React, {
  useContext,
  useEffect,
  useState,
  useParams,
  useRef,
} from "react";
import "./css/TradeMarkListView.scss";
import { Link } from "react-router-dom";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import CheckBox from "../common/CheckBox";
import MemberContext from "../../store/Member";
import TradeMarkAPI from "../../API/trademark";
import InfiniteScroll from "react-infinite-scroll-component";
import _, { filter } from "lodash";
import API from "../../util/api";
//window.localStorage.clear();
function TradeMarkListView() {
  let table_head_tag = [],
    tab_tags = [],
    options_tag = [],
    sort_tags = [],
    tr_ref = [];
  const [checkboxState, setCheckboxState] = useState(false);
  const { auth, manage_company_idx } = useContext(MemberContext);
  const count = 100;
  const [curPage, setCurPage] = useState(1);
  const [data, setData] = useState({ items: [] });
  const [tbody, setTbody] = useState([
    window.$Global.notTd(20, "데이터를 불러오는 중입니다"),
  ]);
  const [tabState, setTabState] = useState(false); // Tab 설정 활성화 여부
  const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
  const [table_head, setTableHead] = useState(["이미지"]); // 테이블 헤더값
  const keyword_ref = React.useRef();
  const [keywordType, setKeywordType] = useState(7);

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  const [filterCheckList, setFilterCheckList] = useState([]);
  const [nationCheckList, setNationCheckList] = useState([]);
  const [nationCode, setNationCode] = useState(null);
  const [stateCode, setStateCode] = useState(null);
  const [nationList, setNationList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [moreTab, setMoreTab] = useState({ state: true, nation: true });
  const [hasMore, setHasMore] = useState(true);
  const [mouse, setMouse] = useState(false);
  const [btns_state_tab, setBtnsStateTab] = useState([]);
  /* 휴지통 */
  const [wastebasket, setWastebasket] = useState(0);
  /* 패밀리 병합*/
  const [selectedItems, setSelectedItems] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  //
  const [btns_state_sort, setBtnsStateSort] = useState([
    { text: "전체", key: 31, checked: 1 },
    { text: "등록", key: 1, checked: 0 },
    { text: "출원", key: 2, checked: 0 },
    { text: "미출원", key: 4, checked: 0 },
    { text: "최종거절", key: 8, checked: 0 },
    { text: "포기(취하, 완료)", key: 16, checked: 0 },
    { text: "family", key: 0, checked: 0 },
    { text: "삭제예정", key: 31, checked: 0 },
  ]);

  const handleCheckboxChange = async (e, item) => {
    setCheckboxState(e.target.checked);
    //여기서 API 호출.
    if (e.target.checked) {
      try {
        await TradeMarkAPI.tradeMarkrecover(item.idx);
        alert("복원되었습니다");
      } catch (error) {
        console.error("API Error:", error);
      }
    }
    setCheckboxState();
    drawTradeMarkList();
  };
  /* 상표 패밀리 병합 */
  const handleCheckboxChange2 = (e, item) => {
    //setCheckboxState(!checkboxState);

    const newSelectedItems = e.target.checked
      ? [...selectedItems, item.idx]
      : selectedItems.filter((idx) => idx !== item.idx);

    setSelectedItems(newSelectedItems);
    setIsChecked((prevChecked) => !prevChecked);
  };

  const handleFamilyMerge = async () => {
    if (selectedItems.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }

    try {
      const payload = {
        trademarkIdxList: selectedItems,
      };

      // 선택된 항목들에 대한 API 호출 처리
      await API.post(`/manager/trademark/familyMerge`, payload);
      alert("선택된 패밀리가 병합되었습니다.");
      setSelectedItems([]); // 선택 항목 초기화
      drawTradeMarkList();
    } catch (error) {
      alert("api err");
    }
  };
  //정렬
  const [sortBy, setSortBy] = useState(null);
  const [sortByApplyNumber, setSortByApplyNumber] = useState(false);
  const [sortAscending, setSortAscending] = useState(true);
  const [Sortapplyat, setSort_apply_at] = useState(true);
  const [sortregister_number, setSort_register_number] = useState(true);
  const [sortregister_at, setSort_register_at] = useState(true);
  const [sortmanage_number, setSort_manage_number] = useState(true);
  const [sortclass, setSort_class] = useState(true);
  const [sortclassname, setSort_class_name] = useState(true);
  //
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [sortingColumn, setSortingColumn] = useState(0);
  const [sortingOrder, setSortingOrder] = useState();
  const initialRender = useRef(true);
  //sort체크박스 상태
  const onSortChange = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSortAscending(order);
    sortedMarkList(column, order);
  };
  const Sort_apply_at = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_apply_at(order);
    sortedMarkList(column, order);
  };
  const Sort_register_number = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_register_number(order);
    sortedMarkList(column, order);
  };
  const Sort_register_at = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_register_at(order);
    sortedMarkList(column, order);
  };
  const Sort_manage_number = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_manage_number(order);
    sortedMarkList(column, order);
  };
  const Sort_class = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_class(order);
    sortedMarkList(column, order);
  };
  const Sort_class_name = (column, order) => {
    setSortingColumn(column);
    setSortingOrder(order);
    setSort_class_name(order);
    sortedMarkList(column, order);
  };
  const toggleSortOptions = (columnValue) => {
    if (sortBy === columnValue) {
      setShowSortOptions(!showSortOptions);
    } else {
      setSortBy(columnValue);
      setShowSortOptions(true);
    }
  };

  useEffect(() => {
    let copy_sort = _.cloneDeep(btns_state_sort);
    if (window.localStorage.getItem("trademark_tab_init")) {
      window.localStorage.setItem("trademark_total_number", "1");
      window.localStorage.setItem("trademark_apply_number", "1");
      //window.localStorage.setItem("trademark_image", "1");
      window.localStorage.setItem("trademark_nation_string", "1");
      window.localStorage.setItem("trademark_apply_at", "1");
      window.localStorage.setItem("trademark_state", "1");
      window.localStorage.setItem("trademark_register_number", "1");
      window.localStorage.setItem("trademark_name", "1");
      window.localStorage.setItem("trademark_agent_company_name", "1");
      window.localStorage.setItem("trademark_manage_number", "0");
      window.localStorage.setItem("trademark_class", "0");
      window.localStorage.setItem("trademark_apply_notice_at", "0");
      window.localStorage.setItem("trademark_renewal_cnt", "0");
      window.localStorage.setItem("trademark_relation_product", "0");
      //window.localStorage.setItem("trademark_recovery_checkbox", "0");
    } else {
      setSelectedBasics(false);
    }
    if (copy_sort[copy_sort.length - 1].wastebasket === 1) {
      setBtnsStateTab([
        {
          text: "건수",
          key: "total_number",
          type: null,
          checked:
            window.localStorage.getItem("trademark_total_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_total_number")),
          defaultChecked: 1,
        },
        {
          text: "이미지",
          key: "image",
          type: null,
          checked:
            window.localStorage.getItem("trademark_image") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_image")),
          defaultChecked: 0,
        },
        {
          text: "국가",
          key: "nation_string",
          type: 1,
          checked:
            window.localStorage.getItem("trademark_nation_string") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_nation_string")),
          defaultChecked: 1,
        },

        {
          text: "출원번호",
          key: "apply_number",
          type: 7,
          checked:
            window.localStorage.getItem("trademark_apply_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_number")),
          defaultChecked: 1,
        },
        {
          text: "출원일",
          key: "apply_at",
          type: 2,
          checked:
            window.localStorage.getItem("trademark_apply_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_at")),
          defaultChecked: 1,
        },
        {
          text: "현재상태",
          key: "state",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_state") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_state")),
          defaultChecked: 1,
        },
        {
          text: "등록번호",
          key: "register_number",
          type: 3,
          checked:
            window.localStorage.getItem("trademark_register_number") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_register_number")
                ),
          defaultChecked: 1,
        },
        {
          text: "등록일",
          key: "register_at",
          type: 4,
          checked:
            window.localStorage.getItem("trademark_register_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_register_at")),
          defaultChecked: 1,
        },
        {
          text: "상표명칭",
          key: "name",
          type: 5,
          checked:
            window.localStorage.getItem("trademark_name") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_name")),
          defaultChecked: 1,
        },
        {
          text: "대리인",
          key: "agent_company_name",
          type: null,
          checked:
            window.localStorage.getItem("trademark_agent_company_name") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_agent_company_name")
                ),
          defaultChecked: 1,
        },
        {
          text: "관리번호",
          key: "manage_number",
          type: 0,
          checked:
            window.localStorage.getItem("trademark_manage_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_manage_number")),
          defaultChecked: 0,
        },
        {
          text: "상표분류",
          key: "class",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_class") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_class")),
          defaultChecked: 0,
        },
        {
          text: "출원공고일",
          key: "apply_notice_at",
          type: null,
          checked:
            window.localStorage.getItem("trademark_apply_notice_at") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_apply_notice_at")
                ),
          defaultChecked: 0,
        },
        {
          text: "갱신차수",
          key: "renewal_cnt",
          type: 9,
          checked:
            window.localStorage.getItem("trademark_renewal_cnt") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_renewal_cnt")),
          defaultChecked: 0,
        },
        {
          text: "관련제품",
          key: "relation_product",
          type: 8,
          checked:
            window.localStorage.getItem("trademark_relation_product") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_relation_product")
                ),
          defaultChecked: 0,
        },
        {
          text: "삭제복원",
          key: "trademark_recovery_checkbox",
          type: null,
          checked:
            window.localStorage.getItem("trademark_recovery_checkbox") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_recovery_checkbox")
                ),
          defaultChecked: 1,
        },
      ]);
    } else if (btns_state_sort.find((obj) => obj.text === "family").checked) {
      setBtnsStateTab([
        {
          text: "패밀리",
          key: "family_checkbox",
          type: null,
          checked:
            window.localStorage.getItem("family_checkbox") == null
              ? 1
              : Number(window.localStorage.getItem("family_checkbox")),
          defaultChecked: 1,
        },
        {
          text: "건수",
          key: "total_number",
          type: null,
          checked:
            window.localStorage.getItem("trademark_total_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_total_number")),
          defaultChecked: 1,
        },
        {
          text: "이미지",
          key: "image",
          type: null,
          checked:
            window.localStorage.getItem("trademark_image") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_image")),
          defaultChecked: 0,
        },
        {
          text: "국가",
          key: "nation_string",
          type: 1,
          checked:
            window.localStorage.getItem("trademark_nation_string") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_nation_string")),
          defaultChecked: 1,
        },

        {
          text: "출원번호",
          key: "apply_number",
          type: 7,
          checked:
            window.localStorage.getItem("trademark_apply_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_number")),
          defaultChecked: 1,
        },
        {
          text: "출원일",
          key: "apply_at",
          type: 2,
          checked:
            window.localStorage.getItem("trademark_apply_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_at")),
          defaultChecked: 1,
        },
        {
          text: "현재상태",
          key: "state",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_state") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_state")),
          defaultChecked: 1,
        },
        {
          text: "등록번호",
          key: "register_number",
          type: 3,
          checked:
            window.localStorage.getItem("trademark_register_number") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_register_number")
                ),
          defaultChecked: 1,
        },
        {
          text: "등록일",
          key: "register_at",
          type: 4,
          checked:
            window.localStorage.getItem("trademark_register_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_register_at")),
          defaultChecked: 1,
        },
        {
          text: "상표명칭",
          key: "name",
          type: 5,
          checked:
            window.localStorage.getItem("trademark_name") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_name")),
          defaultChecked: 1,
        },
        {
          text: "대리인",
          key: "agent_company_name",
          type: null,
          checked:
            window.localStorage.getItem("trademark_agent_company_name") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_agent_company_name")
                ),
          defaultChecked: 1,
        },
        {
          text: "관리번호",
          key: "manage_number",
          type: 0,
          checked:
            window.localStorage.getItem("trademark_manage_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_manage_number")),
          defaultChecked: 0,
        },
        {
          text: "상표분류",
          key: "class",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_class") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_class")),
          defaultChecked: 0,
        },
        {
          text: "출원공고일",
          key: "apply_notice_at",
          type: null,
          checked:
            window.localStorage.getItem("trademark_apply_notice_at") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_apply_notice_at")
                ),
          defaultChecked: 0,
        },
        {
          text: "갱신차수",
          key: "renewal_cnt",
          type: 9,
          checked:
            window.localStorage.getItem("trademark_renewal_cnt") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_renewal_cnt")),
          defaultChecked: 0,
        },
        {
          text: "관련제품",
          key: "relation_product",
          type: 8,
          checked:
            window.localStorage.getItem("trademark_relation_product") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_relation_product")
                ),
          defaultChecked: 0,
        },
      ]);
    } else {
      setBtnsStateTab([
        {
          text: "건수",
          key: "total_number",
          type: null,
          checked:
            window.localStorage.getItem("trademark_total_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_total_number")),
          defaultChecked: 1,
        },
        {
          text: "이미지",
          key: "image",
          type: null,
          checked:
            window.localStorage.getItem("trademark_image") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_image")),
          defaultChecked: 0,
        },
        {
          text: "국가",
          key: "nation_string",
          type: 1,
          checked:
            window.localStorage.getItem("trademark_nation_string") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_nation_string")),
          defaultChecked: 1,
        },

        {
          text: "출원번호",
          key: "apply_number",
          type: 7,
          checked:
            window.localStorage.getItem("trademark_apply_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_number")),
          defaultChecked: 1,
        },
        {
          text: "출원일",
          key: "apply_at",
          type: 2,
          checked:
            window.localStorage.getItem("trademark_apply_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_apply_at")),
          defaultChecked: 1,
        },
        {
          text: "현재상태",
          key: "state",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_state") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_state")),
          defaultChecked: 1,
        },
        {
          text: "등록번호",
          key: "register_number",
          type: 3,
          checked:
            window.localStorage.getItem("trademark_register_number") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_register_number")
                ),
          defaultChecked: 1,
        },
        {
          text: "등록일",
          key: "register_at",
          type: 4,
          checked:
            window.localStorage.getItem("trademark_register_at") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_register_at")),
          defaultChecked: 1,
        },
        {
          text: "상표명칭",
          key: "name",
          type: 5,
          checked:
            window.localStorage.getItem("trademark_name") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_name")),
          defaultChecked: 1,
        },
        {
          text: "대리인",
          key: "agent_company_name",
          type: null,
          checked:
            window.localStorage.getItem("trademark_agent_company_name") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_agent_company_name")
                ),
          defaultChecked: 1,
        },
        {
          text: "관리번호",
          key: "manage_number",
          type: 0,
          checked:
            window.localStorage.getItem("trademark_manage_number") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_manage_number")),
          defaultChecked: 0,
        },
        {
          text: "상표분류",
          key: "class",
          type: 10,
          checked:
            window.localStorage.getItem("trademark_class") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_class")),
          defaultChecked: 0,
        },
        {
          text: "출원공고일",
          key: "apply_notice_at",
          type: null,
          checked:
            window.localStorage.getItem("trademark_apply_notice_at") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_apply_notice_at")
                ),
          defaultChecked: 0,
        },
        {
          text: "갱신차수",
          key: "renewal_cnt",
          type: 9,
          checked:
            window.localStorage.getItem("trademark_renewal_cnt") == null
              ? 1
              : Number(window.localStorage.getItem("trademark_renewal_cnt")),
          defaultChecked: 0,
        },
        {
          text: "관련제품",
          key: "relation_product",
          type: 8,
          checked:
            window.localStorage.getItem("trademark_relation_product") == null
              ? 1
              : Number(
                  window.localStorage.getItem("trademark_relation_product")
                ),
          defaultChecked: 0,
        },
      ]);
    }

    if (isCompany) {
      TradeMarkAPI.getSortFieldCompany().then((res) => {
        setStateList(res.data.state);
        setNationList(res.data.nation);
        setFilterCheckList(res.data.state.map(() => false).concat([true]));
        setNationCheckList(res.data.nation.map(() => false).concat([true]));
      });
    } else if (isAgent) {
      if (manage_company_idx == undefined) return;
      TradeMarkAPI.getSorFieldAgent(manage_company_idx).then((res) => {
        setStateList(res.data.state);
        setNationList(res.data.nation);
      });
      if (btns_state_tab.length) {
        setBtnsStateTab(btns_state_tab.filter((item) => item.text != "대리인"));
      }
    }
  }, [btns_state_sort, sortAscending, data]);

  //기존 useeffect
  useEffect(() => {
    // // 초기 렌더링이 아닌 경우에만 실행되도록 확인합니다.
    // if (!initialRender.current) {
    drawTradeMarkList({
      page: 1,
      count: count,
      options: btns_state_sort
        .filter((item) => item.checked)
        .reduce((x, y) => x + y.key, 0),
      keyword: keyword_ref.current ? keyword_ref.current.value : "",
      type: keywordType,
      sort_state: stateCode,
      sort_nation: nationCode,
      wastebasket: btns_state_sort[btns_state_sort.length - 1].wastebasket,
    });
    // 원하는 작업을 수행합니다.
    // } else {
    //   // 초기 렌더링인 경우, initialRender를 false로 설정하여 다음에 useEffect가 실행되도록 합니다.
    //   initialRender.current = false;
    // }
  }, [auth, btns_state_sort, selectedItems]);

  function determineTextColorBasedOnState(state) {
    switch (state) {
      case "등록완료":
        return "green";
      case "출원완료":
        return "blue";
      case "종료":
        return "red";
      default:
        return "black";
    }
  }
  useEffect(() => {
    if (!data.page) return;
    let selectedBasics_tab = btns_state_tab.filter((item) => item.checked);
    let apply_number = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_number"
    ).length;
    let nation_string = selectedBasics_tab.filter(
      (obj) => obj.key === "nation_string"
    ).length;
    let apply_at = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_at"
    ).length;
    let state = selectedBasics_tab.filter((obj) => obj.key === "state").length;
    let register_number = selectedBasics_tab.filter(
      (obj) => obj.key === "register_number"
    ).length;
    let register_at = selectedBasics_tab.filter(
      (obj) => obj.key === "register_at"
    ).length;
    let name = selectedBasics_tab.filter((obj) => obj.key === "name").length;
    let agent_company_name = selectedBasics_tab.filter(
      (obj) => obj.key === "agent_company_name"
    ).length;
    let manage_number = selectedBasics_tab.filter(
      (obj) => obj.key === "manage_number"
    ).length;
    let trademark_class = selectedBasics_tab.filter(
      (obj) => obj.key === "class"
    ).length;
    let apply_notice_at = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_notice_at"
    ).length;
    let renewal_cnt = selectedBasics_tab.filter(
      (obj) => obj.key === "renewal_cnt"
    ).length;
    let relation_product = selectedBasics_tab.filter(
      (obj) => obj.key === "relation_product"
    ).length;
    let family_checkbox = selectedBasics_tab.filter(
      (obj) => obj.key === "family_checkbox"
    ).length;
    let trademark_recovery_checkbox = selectedBasics_tab.filter(
      (obj) => obj.key === "trademark_recovery_checkbox"
    ).length;
    let trademark_image = selectedBasics_tab.filter(
      (obj) => obj.key === "trademark_image"
    ).length;
    let copy_sort = _.cloneDeep(btns_state_sort);
    if (data.items.length) {
      setTbody(
        data.items.map((item, index) => {
          tr_ref[index] = React.createRef();
          const colorstateList = window.$Global.convertTradeMarkStateCodeToStr(
            item.state
          );
          const textColor = determineTextColorBasedOnState(colorstateList);
          return (
            <React.Fragment key={item.idx}>
              <tr
                data-idx={index}
                className={index === 0 ? "active" : ""}
                onClick={onClickMore}
                //onClick={(e) => onClickMore(e)}
              >
                {btns_state_sort.find((obj) => obj.text === "family")
                  .checked ? (
                  <td className="recovery_checkbox">
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.idx)}
                      onChange={(e) => handleCheckboxChange2(e, item)}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </td>
                ) : null}
                <td key={`${item.idx}_order`}>{data.total_count - index}</td>
                {table_head.includes("이미지") && (
                  <td className="trademark_image">
                    <img
                      src={window.$Global.getCDN(item.image_key)}
                      alt="이미지"
                      style={{ width: "100px", height: "auto" }}
                    />
                  </td>
                )}
                <td>
                  {nation_string
                    ? window.$Global.convertTradeMarkNationCodeToStr(
                        item.nation
                      )
                    : null}
                </td>
                {apply_number ? (
                  <td>{item.apply_number || "출원번호 N/A"}</td>
                ) : null}
                {apply_at ? (
                  <td className="apply_at">
                    {window.$Global.convertDate(item.apply_at)}
                  </td>
                ) : null}
                {state ? (
                  <td className="state" style={{ color: textColor }}>
                    {colorstateList}
                  </td>
                ) : null}
                {register_number ? (
                  <td className="register_number">{item.regist_number}</td>
                ) : null}
                {register_at ? (
                  <td className="register_at">
                    {window.$Global.convertDate(item.regist_at)}
                  </td>
                ) : null}
                {name ? <td className="name">{item.name}</td> : null}
                {agent_company_name ? (
                  <td className="agent_name">{item.agent_company_name}</td>
                ) : null}
                {manage_number ? (
                  <td className="manage_number">
                    {item.manage_number || "관리번호 N/A"}
                  </td>
                ) : null}
                {trademark_class ? (
                  <td className="trademark_class">
                    <p>
                      {window.$Global
                        .deduplicationToJSON(item.class, "class")
                        .map((item) => item.class)
                        .join("류, ")}
                    </p>
                  </td>
                ) : null}
                {apply_notice_at ? (
                  <td className="apply_notice_at">
                    {window.$Global.convertDate(item.apply_notice_at)}
                  </td>
                ) : null}
                {renewal_cnt ? (
                  <td className="renewal_cnt">{item.renewal_cnt}</td>
                ) : null}
                {relation_product ? (
                  <td className="apply_notice_at">{item.relation_product}</td>
                ) : null}
                {trademark_image ? (
                  <td className="trademark_image">{item.trademark_image}</td>
                ) : null}
                {copy_sort[copy_sort.length - 1].wastebasket === 1 && (
                  <td className="recovery_checkbox">
                    <input
                      type="checkbox"
                      checked={checkboxState}
                      onChange={(e) => handleCheckboxChange(e, item)}
                      style={{ width: "20px", height: "20px" }}
                    />
                  </td>
                )}
              </tr>
              <tr
                className={`more_view`}
                style={index === 0 ? { display: "block" } : null}
                ref={tr_ref[index]}
              >
                <td key={`${item.idx}_detail`}>
                  <Link className="detail_info" to={`detail/${item.idx}`}>
                    <h2 className="title">상세정보</h2>
                    <p>
                      자세히보기
                      <i className="icon_more_black" />
                    </p>
                    <i className="icon_detail" />
                  </Link>
                  {isCompany && (
                    <Link className="process_state" to={`memo/${item.idx}`}>
                      <h2 className="title">상표 메모</h2>
                      <p>
                        자세히보기
                        <i className="icon_more_black" />
                      </p>
                      <i className="icon_pencil" />
                    </Link>
                  )}
                  <Link className="family" to={`family/${item.idx}`}>
                    <h2 className="title">Family</h2>
                    <p>
                      자세히보기
                      <i className="icon_more_black" />
                    </p>
                    <i className="icon_home" />
                  </Link>
                  <Link className="relative_file" to={`etc/${item.idx}`}>
                    <h2 className="title">기타 관련 파일</h2>
                    <p>
                      자세히보기
                      <i className="icon_more_black" />
                    </p>
                    <i className="icon_attached_file" />
                  </Link>

                  {isCompany && (
                    <>
                      <Link
                        className={`payment ${
                          item.regist_at ? "" : "no_click"
                        }`}
                        to={`/annual/manage/${item.idx}?nation=${
                          item.nation_string || ""
                        }
                        }&manage_number=${
                          item.manage_number || ""
                        }&register_number=${
                          item.register_number || ""
                        }&deadline_at=${
                          item.apply_at
                            ? new Date(item.apply_at).getFullYear() + 1000
                            : ""
                        }&type=tradeMark`}
                      >
                        <h2 className="title">연차료 관리</h2>
                        <p>
                          자세히보기
                          <i className="icon_more_black" />
                        </p>
                        <i className="icon_patent_payment" />
                      </Link>
                    </>
                  )}
                  <button
                    className="icon_exit"
                    data-idx={index}
                    onClick={onClickHideDetailMore}
                  />
                </td>
              </tr>
            </React.Fragment>
          );
        })
      );
    } else {
      setTbody(window.$Global.notTd(20, "데이터가 없습니다"));
    }
  }, [data, btns_state_tab]);

  const onClickMore = (e) => {
    let idx = e.target.parentNode.dataset.idx || e.target.dataset.idx;
    let target =
      e.target.nodeName.toLowerCase() === "tr" ? e.target : e.target.parentNode;
    let tr = tr_ref[idx];
    if (tr === undefined) {
      tr_ref[idx] = { current: null };
      tr = tr_ref[idx];
    }

    tr_ref.forEach((item) => {
      if (item.current) {
        item.current.style["display"] = "none";
        item.current.previousElementSibling.className = "";
      }
    });

    if (tr.current && tr.current.style.display !== "block") {
      tr.current.style["display"] = "block";
      target.className = "active";
    } else {
    }
  };

  const onClickHideDetailMore = (e) => {
    let idx = e.target.dataset.idx || e.target.parentNode.dataset.idx;
    tr_ref[idx].current.style.display = "none";
    e.target.parentNode.parentNode.previousElementSibling.className = null;
  };

  const onChangeTabChecked = (input, text) => {
    let item = btns_state_tab.find((el) => el.text === text);
    item.checked = Number(input.target.checked);
    setBtnsStateTab([...btns_state_tab]);

    if (item.checked) {
      setTableHead([...table_head, item.text]);
    } else {
      let updatedTableHead = table_head.filter(
        (headItem) => headItem !== item.text
      );
      setTableHead(updatedTableHead);
    }
  };

  const onChangeSortChecked = (input, text) => {
    let copy_sort = _.cloneDeep(btns_state_sort);

    if (input.target.checked) {
      if (text != "전체") {
        copy_sort[copy_sort.length - 1].wastebasket = Number(0);
        copy_sort[0].checked = false;
        copy_sort[copy_sort.length - 1].checked = false;
        if (text == "삭제예정") {
          // state 값

          copy_sort[copy_sort.length - 1].wastebasket = Number(
            input.target.checked
          );
          copy_sort.map((item, index) => {
            if (index > 0) {
              copy_sort[index].checked = false;
            }
          });
        }
      } else {
        copy_sort.map((item, index) => {
          if (index > 0) {
            copy_sort[copy_sort.length - 1].wastebasket = Number(0);
            copy_sort[index].checked = false;
          }
        });
      }
    }

    copy_sort.find((item) => item.text === text).checked = Number(
      input.target.checked
    );

    if (!copy_sort.filter((item) => item.checked).length) {
      alert("최소 한개 이상은 선택해야 합니다.");
      copy_sort.find((item) => item.text === text).checked = true;
      return;
    }
    setBtnsStateSort(copy_sort);
  };
  const onChagneKeywordType = (e) => {
    setKeywordType(e.target.value);
  };

  const onClickToggleselectedBasics = () => {
    // 기본으로 설정 ON 일때 체크 값 기본으로 설정
    if (!selectedBasics) {
      btns_state_tab.forEach((item) => (item.checked = item.defaultChecked));
      setBtnsStateTab(btns_state_tab);
    }
    setSelectedBasics(!selectedBasics);
  };

  const drawTradeMarkList = async (param, type) => {
    let result = await authCheckAPI(
      param || {
        page: curPage,
        count: count,
        options: btns_state_sort
          .filter((item) => item.checked)
          .reduce((x, y) => x + y.key, 0),
        keyword: keyword_ref.current ? keyword_ref.current.value : "",
        type: keywordType,
        sort_state: stateCode,
        wastebasket: btns_state_sort[btns_state_sort.length - 1].wastebasket,
      }
    );
    if (type == "next") {
      let copy = _.cloneDeep(data);
      result.items = copy.items.concat(result.items);
    }
    /*
    const uniqueIdxValues = [...new Set(result.items.map((item) => item.idx))];
    const removedIdxValues = [];

    const uniqueItems = uniqueIdxValues.map((uniqueIdx) => {
    const duplicateItems = result.items.filter((item) => item.idx === uniqueIdx);

    if (duplicateItems.length > 1) {
      removedIdxValues.push(...duplicateItems.slice(1).map((item) => item.idx));
    }

    return duplicateItems[0];
    });

    result.items = uniqueItems;
    console.log("Removed idx values:", removedIdxValues);
    */
    setData(result);
    checkHasMore(result);
  };

  const nextList = () => {
    let param = {
      sorting_column: sortingColumn,
      sorting_order: sortingOrder,
      page: curPage,
      count: count,
      options: btns_state_sort
        .filter((item) => item.checked)
        .reduce((x, y) => x + y.key, 0),
      keyword: keyword_ref.current ? keyword_ref.current.value : "",
      type: keywordType,
      sort_state: stateCode,
    };
    setCurPage(curPage + 1);
    drawTradeMarkList(param, "next");
  };

  const getTradeMarkListCompanyAPI = (params) => {
    return TradeMarkAPI.getTradeMarkListCompany(params).then((res) => res.data);
  };

  const getTradeMarkListAgentAPI = (params) => {
    return TradeMarkAPI.getTradeMarkListAgent(manage_company_idx, params).then(
      (res) => res.data
    );
  };

  const authCheckAPI = async (param) => {
    let result = {};
    if (isCompany) {
      result = await getTradeMarkListCompanyAPI(
        param || { page: curPage, count: count }
      );
    } else {
      result = await getTradeMarkListAgentAPI(
        param || { page: curPage, count: count }
      );
    }
    // 중복 방지
    /*
    const uniqueIdxValues = [...new Set(result.items.map((item) => item.idx))];
    const removedIdxValues = [];

    const uniqueItems = uniqueIdxValues.map((uniqueIdx) => {
    const duplicateItems = result.items.filter((item) => item.idx === uniqueIdx);

    if (duplicateItems.length > 1) {
      removedIdxValues.push(...duplicateItems.slice(1).map((item) => item.idx));
    }

    return duplicateItems[0];
    });

    result.items = uniqueItems;
    console.log("Removed idx values:", removedIdxValues);
    */
    return result;
  };

  const sortedMarkList = async (
    sorting_column,
    sorting_order,
    param2,
    type
  ) => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    scrollableDiv.scrollTop = 0;
    let result = await getTradeMarkListCompanyAPI(
      param2 || {
        sorting_column: sorting_column,
        sorting_order: sorting_order,
        page: 1,
        count: count,
        options: btns_state_sort
          .filter((item) => item.checked)
          .reduce((x, y) => x + y.key, 0),
        keyword: keyword_ref.current ? keyword_ref.current.value : "",
        type: keywordType,
        sort_state: stateCode,
        wastebasket: btns_state_sort[btns_state_sort.length - 1].wastebasket,
      }
    );
    // 중복 방지
    /*
    const uniqueIdxValues = [...new Set(result.items.map((item) => item.idx))];
    const removedIdxValues = [];

    const uniqueItems = uniqueIdxValues.map((uniqueIdx) => {
    const duplicateItems = result.items.filter((item) => item.idx === uniqueIdx);

    if (duplicateItems.length > 1) {
      removedIdxValues.push(...duplicateItems.slice(1).map((item) => item.idx));
    }

    return duplicateItems[0];
    });

    result.items = uniqueItems;
    console.log("Removed idx values:", removedIdxValues);
    */
    if (type == "next") {
      let copy = _.cloneDeep(data);
      result.items = copy.items.concat(result.items);
    }
    setCurPage(2);
    setData(result);
    checkHasMore(result);
    return result;
  };

  const searchTradeMark = async () => {
    let param = {
      page: 1,
      count: count,
      options: btns_state_sort
        .filter((item) => item.checked)
        .reduce((x, y) => x + y.key, 0),
      keyword: keyword_ref.current ? keyword_ref.current.value : "",
      type: keywordType,
    };
    let result = await authCheckAPI(param);
    console.log(result);
    setCurPage(2);
    setData(result);
    checkHasMore(result);
  };

  const onClickFilter = async (state_code, type) => {
    let param = {
      page: 1,
      count: count,
      options: btns_state_sort
        .filter((item) => item.checked)
        .reduce((x, y) => x + y.key, 0),
      keyword: keyword_ref.current ? keyword_ref.current.value : "",
      type: keywordType,
      ...(type === 0
        ? { sort_state: state_code }
        : { sort_nation: state_code }),
      //sort_state: state_code,
    };
    let result = await authCheckAPI(param);
    setCurPage(2);
    setData(result);
    checkHasMore(result);
    if (type === 0) {
      setStateCode(state_code);
    } else if (type === 1) {
      setNationCode(state_code);
    }
  };

  const checkHasMore = (obj) => {
    if (obj.page == obj.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const onClickTabSave = () => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);

    // 기본 탭 설정일때
    if (selectedBasics) {
      window.localStorage.setItem("trademark_tab_init", "1");
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
    } else {
      window.localStorage.removeItem("trademark_tab_init");
      copy_arr_tab.forEach((item) => {
        window.localStorage.setItem("trademark_" + item.key, item.checked);
      });
    }
    setTabState(false);
  };

  const onClickMoreStateDisplay = (type) => {
    let param = {
      [type]: !moreTab[type],
    };
    setMoreTab({ ...moreTab, ...param });
  };

  const onClickFilterCheckBox = (e, text, index) => {
    let copy = _.cloneDeep(filterCheckList);

    copy = copy.map((item, idx) => {
      if (idx != index) {
        return false;
      }
    });

    copy[index] = e.target.checked;

    if (copy.findIndex((item) => item == true) == -1) {
      copy[copy.length - 1] = true;
    }
    setFilterCheckList(copy);

    if (copy[index]) {
      onClickFilter(text, 0);
    } else {
      onClickFilter(null, 0);
    }
    onClickMoreStateDisplay("state");
  };

  const onChangeNation = (e, text, index) => {
    let copy = _.cloneDeep(nationCheckList);

    copy = copy.map((item, idx) => {
      if (idx != index) {
        return false;
      }
    });

    copy[index] = e.target.checked;

    if (copy.findIndex((item) => item == true) == -1) {
      copy[copy.length - 1] = true;
    }
    setNationCheckList(copy);

    if (copy[index]) {
      onClickFilter(text, 1);
    } else {
      onClickFilter(null, 1);
    }
    onClickMoreStateDisplay("nation");
  };

  const onBlur = (type) => {
    if (!mouse) {
      setMoreTab({ ...moreTab, [type]: true });
    }
  };

  btns_state_tab.forEach((item, i) => {
    if (item.text === "국가") {
      const tag = nationList.map((item, index) => {
        return (
          <li key={index} tabIndex={0} onBlur={() => onBlur("nation")}>
            <CheckBox
              key={i}
              text={window.$Global.convertTradeMarkNationCodeToStr(item)}
              checked={nationCheckList[index]}
              onChangeChecked={(e) => onChangeNation(e, item, index)}
            />
          </li>
        );
      });

      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.nation ? "dp_none" : ""}
            onBlur={() => onBlur("nation")}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
            }}
          >
            {item.text}
            <button
              className="icon_more_white"
              onClick={() => onClickMoreStateDisplay("nation")}
            />
            <ul
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <li tabIndex={0} onBlur={() => onBlur("nation")}>
                <CheckBox
                  key={i}
                  text="전체"
                  checked={nationCheckList[nationCheckList.length - 1]}
                  onChangeChecked={(e) =>
                    onChangeNation(e, null, nationCheckList.length)
                  }
                />
              </li>
              {tag}
            </ul>
          </th>
        );
    } else if (item.text === "현재상태") {
      let tag = stateList.map((item, index) => {
        // 현재상태 색 변경
        return (
          <li key={index} tabIndex={0} onBlur={() => onBlur("state")}>
            <CheckBox
              key={i}
              text={
                <span
                  style={{
                    color:
                      index === stateList.length - 1
                        ? "red"
                        : index === stateList.length - 3
                        ? "green"
                        : index === stateList.length - 5
                        ? "blue"
                        : "black",
                  }}
                >
                  {window.$Global.convertTradeMarkStateCodeToStr(item)}
                </span>
              }
              checked={filterCheckList[index]}
              onChangeChecked={(e) => onClickFilterCheckBox(e, item, index)}
            />
          </li>
        );
      });
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.state ? "dp_none" : ""}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 46,
            }}
            onBlur={() => onBlur("state")}
          >
            {item.text}
            <button
              className="icon_more_white"
              onClick={() => onClickMoreStateDisplay("state")}
            />
            <ul
              onMouseEnter={() => setMouse(true)}
              onMouseLeave={() => setMouse(false)}
            >
              <li tabIndex={0} onBlur={() => onBlur("state")}>
                <CheckBox
                  key={i}
                  text="전체"
                  checked={filterCheckList[filterCheckList.length - 1]}
                  onChangeChecked={(e) =>
                    onClickFilterCheckBox(e, null, filterCheckList.length)
                  }
                />
              </li>
              {tag}
            </ul>
          </th>
        );
    } else if (item.text === "이미지") {
      item.checked &&
        table_head_tag.push(
          <th key={i} onBlur={() => onBlur("image")}>
            {item.text}
          </th>
        );
    } else if (item.text === "출원번호") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("apply_number")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}{" "}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("apply_number")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "apply_number" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortAscending}
                    onChange={() => onSortChange(1, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortAscending}
                    onChange={() => onSortChange(1, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "출원일") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("apply_at")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}{" "}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("apply_at")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "apply_at" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={Sortapplyat}
                    onChange={() => Sort_apply_at(2, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!Sortapplyat}
                    onChange={() => Sort_apply_at(2, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "등록번호") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("register_number")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("register_number")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "register_number" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortregister_number}
                    onChange={() => Sort_register_number(3, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortregister_number}
                    onChange={() => Sort_register_number(3, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "등록일") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("register_at")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}{" "}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("register_at")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "register_at" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortregister_at}
                    onChange={() => Sort_register_at(4, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortregister_at}
                    onChange={() => Sort_register_at(4, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "관리번호") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("manage_number")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("manage_number")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "manage_number" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortmanage_number}
                    onChange={() => Sort_manage_number(5, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortmanage_number}
                    onChange={() => Sort_manage_number(5, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "상표분류") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("class")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}{" "}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("class")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "class" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortclass}
                    onChange={() => Sort_class(6, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortclass}
                    onChange={() => Sort_class(6, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else if (item.text === "상표명칭") {
      item.checked &&
        table_head_tag.push(
          <th
            key={i}
            onBlur={() => onBlur("name")}
            style={{ position: "relative", top: "0px" }}
          >
            <p className="apply-number-text">
              {item.text}
              <button
                className="icon_more_white"
                onClick={() => toggleSortOptions("name")}
              >
                <span></span>
              </button>
            </p>
            {sortBy === "name" && showSortOptions && (
              <div className="sort-options">
                <label>
                  <input
                    type="checkbox"
                    checked={sortclassname}
                    onChange={() => Sort_class_name(7, 1)}
                  />
                  오름차순
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={!sortclassname}
                    onChange={() => Sort_class_name(7, 0)}
                  />
                  내림차순
                </label>
              </div>
            )}
          </th>
        );
    } else {
      item.checked && table_head_tag.push(<th key={i}>{item.text}</th>);
    }

    if (
      item.text != "국가" &&
      item.text != "현재상태" &&
      item.text != "건수" &&
      item.text != "출원공고일" &&
      item.text != "대리인" &&
      item.text != "등록일" &&
      item.text != "출원일" &&
      item.text != "이미지"
    ) {
      options_tag.push(
        <option key={i} value={item.type}>
          {item.text}
        </option>
      );
    }

    tab_tags.push(
      <CheckBox
        key={i}
        text={item.text}
        checked={item.checked}
        onChangeChecked={onChangeTabChecked}
        disabled={selectedBasics}
      />
    );
  });

  btns_state_sort.forEach((item, i) => {
    sort_tags.push(
      <CheckBox
        key={i}
        text={item.text}
        checked={item.checked}
        onChangeChecked={onChangeSortChecked}
      />
    );
  });

  return (
    <div id="TradeMarkListView">
      <div className="header">
        <h2 className="title">상표 전체</h2>
        <div className="btns">
          {tabState ? (
            <>
              <ToggleButtonWithSpan
                text="Tab 기본 설정하기 "
                condition={{ on: "ON", off: "OFF" }}
                selected={selectedBasics}
                toggleSelected={onClickToggleselectedBasics}
              />
              <button className="btn_tab_setting_save" onClick={onClickTabSave}>
                Tab 설정 저장
              </button>
            </>
          ) : (
            <>
              <button
                className="btn_tab_setting"
                onClick={handleFamilyMerge}
                style={{
                  display: btns_state_sort.find((obj) => obj.text === "family")
                    .checked
                    ? "block"
                    : "none",
                }}
              >
                패밀리 병합
              </button>

              {isCompany && (
                <Link className="btn_add" to={`/tradeMark/detail/new`}>
                  신규사건 생성
                </Link>
              )}
              <button
                className="btn_tab_setting"
                onClick={() => setTabState(true)}
              >
                Tab 설정
              </button>
            </>
          )}
        </div>
      </div>
      <div className="wrap_tab_btns">{tabState && tab_tags}</div>
      {tabState || (
        <>
          <div className="wrap_search">
            <select onChange={onChagneKeywordType}>{options_tag}</select>
            <input
              type="text"
              className="input_search"
              ref={keyword_ref}
              placeholder="검색어를 입력해주세요"
              onKeyUp={(e) => {
                if (e.key === "Enter") searchTradeMark();
              }}
            />
            <button className="icon_search_black" onClick={searchTradeMark} />
          </div>
          <div className="sorting">{sort_tags}</div>
        </>
      )}
      <div className="table-box-wrap">
        <div id="scrollableDiv" className="wrap_list">
          <InfiniteScroll
            dataLength={data.items.length}
            next={nextList}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table className="list">
              <thead>
                <tr className="no_focus_outline">{table_head_tag}</tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TradeMarkListView);
