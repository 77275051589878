import Menu from "./common/Menu";
import API from "../../util/api";
import React, { useContext, useEffect, useState } from "react";
import memberContext from "../../store/Member";
import "./css/InventorMember.scss";
const InventorMember = () => {
  const memberInfo = useContext(memberContext);
  const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
  const isAgent = window.$Global.checkAuth("대리인", memberInfo.auth);
  const [data, setData] = useState([]);

  useEffect(() => {
    accountGetAPI();
  }, []);
  const accountAddAPI = (item) => {
    let payload = {
      birth_date: item.birth_date, // 예시로 입력한 값
      email: item.email, // 예시로 입력한 값
      inventor_name: item.inventor_name, // 예시로 입력한 값
      // 기타 다른 필드들...
    };
    if (!window.$Global.validateBirthDate(item.birth_date)) {
      alert("유효하지 않은 생년월일 입니다.");
      return;
    }

    if (!window.$Global.validateEmailAddress(item.email)) {
      alert("유효하지 않은 이메일 입니다.");
      return;
    }

    if (isCompany) {
      return API.post("/manager/inventor", payload).then(() => accountGetAPI());
    } else {
      return API.post("/agent/inventor", payload);
    }
  };

  // inventor_member get
  const accountGetAPI = () => {
    API.get("/manager/inventor/getInventor").then((res) => {
      res.data.map((item) => {
        item.isEdit = false;
      });
      setData(res.data);
    });
  };

  // inventor_member update
  const accountUpdateAPI = (item) => {
    let payload = {
      birth_date: item.birth_date,
      email: item.email,
      inventor_name: item.inventor_name,
    };

    if (!window.$Global.validateBirthDate(item.birth_date)) {
      alert("유효하지 않은 생년월일 입니다.");
      return;
    }

    if (!window.$Global.validateEmailAddress(item.email)) {
      alert("유효하지 않은 이메일 입니다.");
      return;
    }

    return API.patch(`/manager/inventor/${item.inventor_idx}`, payload).then(
      () => accountGetAPI()
    );
  };

  // const companyCodeAddAPI = async (code) => {
  //   console.log(code);
  //   if (window.$Global.checkAuth("특허회사", memberInfo.auth)) {
  //     await API.post("/manager/company/connect", { code: code });
  //   } else if (window.$Global.checkAuth("대리인", memberInfo.auth)) {
  //     await API.post("/agent/company/connect", { code: code });
  //   }
  //   init();
  // };

  const addInventor = () => {
    const obj = {
      birth_date: "",
      email: "",
      inventor_name: "",
      isEdit: true,
    };
    setData([...data, obj]);
  };

  const handleChange = (e, item, key) => {
    item[key] = e.target.value;
    setData([...data]);
  };

  const handleDelete = (item, index) => {
    if (item.idx) {
      API.delete(`/manager/inventor/${item.inventor_idx}`).then(() =>
        accountGetAPI()
      );
    } else {
      data.splice(index, 1);
      setData([...data]);
    }
  };
  return (
    <div
      id="InventorMember"
      style={{
        width: "1280px",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        paddingTop: "40px",
      }}
    >
      <Menu number={17} />
      <div className="inventor_wrapper">
        <header>
          <p>발명자 목록</p>
          <button onClick={() => addInventor()}>추가하기</button>
        </header>
        <div className="table_wrapper custom_scroll">
          <table>
            <thead>
              <tr>
                <th>순번</th>
                <th>이름</th>
                <th>생년월일</th>
                <th>이메일</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return item.isEdit ? (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="text"
                        value={item.inventor_name}
                        onChange={(e) => handleChange(e, item, "inventor_name")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.birth_date}
                        onChange={(e) => handleChange(e, item, "birth_date")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.email}
                        onChange={(e) => handleChange(e, item, "email")}
                      />
                    </td>
                    <td>
                      <i
                        className="icon_check_black"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          item.idx
                            ? accountUpdateAPI(item)
                            : accountAddAPI(item)
                        }
                      />
                      <i
                        className="icon_badge_del"
                        onClick={() => handleDelete(item, index)}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.inventor_name}</td>
                    <td>{item.birth_date}</td>
                    <td>{item.email}</td>
                    <td>
                      <i
                        className="icon_edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          item.isEdit = !item.isEdit;
                          setData([...data]);
                        }}
                      />
                      <i
                        className="icon_badge_del"
                        onClick={() => handleDelete(item, index)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InventorMember;
