import React, {useEffect, useState} from "react";
import LawfirmAPI from "../../API/lawfirm";
import "./css/LawfirmMain.scss";
import LawfirmList from "./LawfirmList";
import CommonAttachPopup from "../common/CommonAttachPopup";

function LawfirmMain() {
    const [showPopup, setShowPopup] = useState(false);
    const [resData, setResData] = useState({items: []});
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [choiceIndex, setChoiceIndex] = useState(0);
    const count = 100;

    useEffect(() => {
        let payload = {
            count: count,
            page: 1
        };
        LawfirmAPI.getList(payload).then(res => {
            setResData(res.data)
        });
    }, []);

    const download = (file_idx) => {
        LawfirmAPI.download(resData.items[choiceIndex].idx, file_idx);
    };

    const onClickShowPopup = (idx) => {
        setShowPopup(true);
        setChoiceIndex(idx);
    }

    return (
        <div id="lawfirmMain">
            <div className="pc">
                <div className="lawfirmMain">
                    <div className="lawfirmMain_box-wrap">
                        <div className="lawfirmMain_box">
                            <div className="lawfirmMain_box_title">해외 로펌 소개</div>
                            <p className="lawfirmMain_box_sub">해외의 로펌을 IPNOW에서 알아보세요.</p>
                        </div>
                        {/* <button type="button" className="lawfirmMain_box_btn">
                            튜토리얼 바로가기
                        </button> */}
                    </div>
                    <div className="lawfirmMain_content">
                        <div className="lawfirmMain_content_title">
                            <h2>해외 로펌 소개</h2>
                        </div>
                        <LawfirmList data={resData} onClickChoice={onClickShowPopup}/>
                    </div>
                </div>
            </div>

            <CommonAttachPopup list={resData.items[choiceIndex]?.introduce || []} show={showPopup} onClickDownload={download} onClickClose={() => setShowPopup(false)}/>
        </div>
    )
}

export default LawfirmMain;