import React from "react";

function ButtonApprovalRow({patentInfo, onClick}) {
	return (
		<div className="row">
			<h2 className="title">출원 지시하기</h2>
			{
				patentInfo.editing
				? <button className="btn_order" onClick={onClick}>결재</button>
				: <p>{window.$Global.convertDate(patentInfo.apply_order_at)}</p>
			}
		</div>
	)
}

export default ButtonApprovalRow;