import React, { useState } from "react";
import "./css/DetailPopupDownload.scss";

function DetailPopupDownload({
  show,
  setShow,
  fileViewList,
  setFileList,
  onClickDownload,
  onClickDelete,
  onClickClose,
  deleteHide,
}) {
  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleDroppedFiles(droppedFiles);
  };

  const handleDroppedFiles = (files) => {
    // 파일 업로드 또는 처리 로직을 구현하세요.
    // files 배열에 드래그 앤 드롭된 파일들이 포함됩니다.
    console.log("Dropped files for upload:", files);
    // 파일 업로드 로직을 호출하세요.
    files.forEach((file) => {
      // 업로드 함수를 호출하거나 업로드 처리를 수행하세요.
      // 예: uploadFile(file)
    });
  };

  let files = fileViewList.map((file, index) => {
    return (
      <tr key={`${file.idx}_down`}>
        <td>{index + 1}</td>
        <td>{`${file.file_name}.${file.file_type}`}</td>
        <td>{window.$Global.convertDate(file.created_at)}</td>
        <td>{`${file.name}`}</td>
        <td>
          <button
            className="icon_download"
            onClick={() => onClickDownload(file.idx)}
          />
        </td>
        {deleteHide ? null : (
          <td>
            <button
              className="btn_delete"
              onClick={() => {
                const shouldDelete = window.confirm("정말로 삭제하시겠습니까?");
                if (shouldDelete) {
                  onClickDelete(file.idx);
                }
              }}
            >
              삭제하기
            </button>
          </td>
        )}
      </tr>
    );
  });

  const onClickHide = () => {
    setShow(false);
    setFileList([]);
  };

  return (
    <div
      id="DetailDownloadPopup"
      style={show ? {} : { display: "none" }}
    >
      <h2 className="title">다운로드</h2>
      <button className="icon_exit" onClick={onClickClose || onClickHide} />
      <div className="wrap_table">
        <table>
          <thead>
            <tr>
              <th width="8%">순번</th>
              <th>파일명</th>
              <th width="15%">업로드 날짜</th>
              <th width="10%">업로더</th>
              <th width="10%">다운로드</th>
              {deleteHide ? null : <th width="10%">삭제하기</th>}
            </tr>
          </thead>
          <tbody>{files}</tbody>
        </table>
      </div>
    </div>
  );
}

export default DetailPopupDownload;