import "./css/ManageTradeMarkForm.scss";
import { useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const ManageTradeMarkForm = ({ category, categoryName }) => {
  const [keywordData, setKeywordData] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    getCustomKeyword();
  }, [category]);

  const getCustomKeyword = () => {
    CommonAPI.getCompanyCustomKeyword(category).then((res) => {
      res.data.items.map((item) => {
        item.isEdit = false;
      });
      setKeywordData(res.data.items);
    });
  };

  const addKeyword = () => {
    CommonAPI.addCompanyCustomKeyword(category, { data: keyword }).then(() => {
      setKeyword("");
      alert("추가되었습니다.");
      getCustomKeyword();
    });
  };

  const saveKeyword = (item) => {
    CommonAPI.updateCompanyCustomKeyword(
      category,
      {
        data: item.data,
        order: item.order,
      },
      item.idx
    ).then(() => {
      alert("저장되었습니다.");
      getCustomKeyword();
    });
  };

  const handleEdit = (item) => {
    item.isEdit = !item.isEdit;
    setKeywordData([...keywordData]);
  };

  const handleDelete = (idx) => {
    CommonAPI.deleteCompanyCustomKeyword(category, idx).then(() => {
      alert("삭제되었습니다.");
      getCustomKeyword();
    });
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleDrag = async (result: any) => {
    if (!result.destination) return;
    const items = [...keywordData];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const updatedList = items.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    for (let idx = 0; idx < items.length; idx++) {
      const item = updatedList[idx];
      await CommonAPI.updateCompanyCustomKeyword(
        category,
        {
          data: item.data,
          order: idx + 1,
        },
        item.idx
      ).then((res) => console.log(res));

      await sleep(30);
    }
  };

  return (
    <div id="ManageTradeMarkForm">
      <div className="header">
        <h2>{categoryName}</h2>
        <div>
          <p>데이터 추가</p>
          <input
            type="text"
            placeholder="추가할 키워드를 입력하세요."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button onClick={addKeyword}>추가하기</button>
        </div>
      </div>
      <div className="keyword_table">
        <table>
          <colgroup>
            <col width="10%" />
            <col width="10%" />
            <col width="60%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th></th>
              <th>No.</th>
              <th>데이터</th>
              <th></th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId="keywordList">
              {(provided) => (
                <tbody
                  className="keywordList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {keywordData.map((item, idx) => {
                    return (
                      <Draggable
                        key={idx}
                        draggableId={`test-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            isDragging={snapshot.isDragging}
                          >
                            <td {...provided.dragHandleProps}>
                              <i className="icon_m_menu" />
                            </td>
                            <td>{idx + 1}</td>
                            <td>
                              {item.isEdit ? (
                                <input
                                  type="text"
                                  value={item.data}
                                  onChange={(e) => {
                                    item.data = e.target.value;
                                    setKeywordData([...keywordData]);
                                  }}
                                />
                              ) : (
                                <p>{item.data}</p>
                              )}
                            </td>
                            <td>
                              {item.isEdit ? (
                                <>
                                  <button
                                    className="btn_save"
                                    onClick={() => saveKeyword(item)}
                                  >
                                    저장
                                  </button>
                                  <button
                                    className="btn_cancel"
                                    onClick={() => handleEdit(item)}
                                  >
                                    취소
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn_edit"
                                    onClick={() => handleEdit(item)}
                                  >
                                    수정
                                  </button>
                                  <button
                                    className="btn_cancel"
                                    onClick={() => handleDelete(item.idx)}
                                  >
                                    삭제
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    </div>
  );
};

export default ManageTradeMarkForm;
