import { useEffect, useRef, useState } from "react";
import Box from "./ipStatistic/Box";
import SubmitNumbers from "./ipStatistic/SubmitNumbers";
import PatentAPI from "../../API/patent";
import LegalStatusChart from "./ipStatistic/LegalStatusChart";
import LegalStatus from "./ipStatistic/LegalStatus";
import IpcStatistic from "./ipStatistic/IpcStatistic";
import KeyValue from "./ipStatistic/KeyValue";
import Researcher from "./ipStatistic/Researcher";
import RightCheck from "./ipStatistic/RightCheck";
import PatentList from "./ipStatistic/PatentList";
import DistributionStatus from "./ipStatistic/DistributionStatus";
import "./IpStatistic.scss";
import "./IpStatisticNew.scss";
import DistributionPop from "./DistributionPop";

const IpStatisticNew = () => {
  const isFirstRender = useRef(true);
  const compName =
    sessionStorage.getItem("company_name")?.replace(/\s+/g, "") || "";
  const companyIdx = sessionStorage.getItem("company_idx") || "";
  const companyCode = sessionStorage.getItem("company_code") || "";
  const [statusData, setStatusData] = useState({});
  const [keyValueData, setKeyValueData] = useState({});
  const [isPopOpen, setIsPopOpen] = useState(false);
  const [patentInfo, setPatentInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [patentPage, setPatentPage] = useState(1);
  const [category, setCategory] = useState("keyword");
  const [showPopup, setShowPopup] = useState(false);
  const [inventorName, setInventorName] = useState("");
  const [isOpenPopReset, setIsOpenPopReset] = useState(false);
  const [viewData, setViewData] = useState({
    nation_string: "",
    apply_number: "",
    document_number: "",
  });
  const [staticParams, setStaticParams] = useState({
    strValue: "",
    company_idx: companyIdx,
    flag: category,
    company_code: companyCode,
    year: "",
    subFlag: "",
    page: 1,
    count: 10,
    num: 0,
    level: "",
  });
  const [inventorCount, setInventorCount] = useState(10);
  const [searchType, setSearchType] = useState("");

  const titles = {
    submitNumber: "출원건수 및 증감률",
    legalStatus: "법적상태",
    ipc: "주요IPC",
    keyValue: "Smart5",
    researcher: "주요 연구자",
    rights: "권리이전 건수 및 비율",
  };

  const legalColors = {
    register: "#E57373",
    open: "#FFB74D",
    giveup: "#FFD54F",
    reject: "#64B5F6",
    cancel: "#4DB6AC",
    delete: "#9575CD",
  };

  const getLegalData = () => {
    PatentAPI.getStatus({ flag: 1 }).then((res) => {
      if (res.data?.statusData) {
        setStatusData(res.data.statusData);
      }
    });
  };

  const getKeyValueData = () => {
    PatentAPI.getGrade({ apply_at: 2014 }).then((res) => {
      if (res.data?.result) {
        setKeyValueData(res.data.result);
      }
    });
  };

  const closePatentPop = () => {
    setIsPopOpen(false);
  };

  const getPatentListResearcher = (name, page = 1) => {
    if (!name) return; // Do not proceed if strValue (name) is empty

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "inventor",
      strValue: name,
    };

    setStaticParams(params);
    setSearchType("researcher");
    getPatentList(params);
  };

  const getPatentListBySubmit = (year, page = 1) => {
    if (!year) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "patent",
      strValue: "공개",
      year: year,
    };

    setStaticParams(params);
    setSearchType("submit");
    getPatentList(params);
    setIsPopOpen(true);
  };

  const getPatentBySmart = (num, year, level, page = 1) => {
    if (!num) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "smart",
      strValue: level,
      year: year,
      subFlag: "",
      num: num,
      level: level,
    };

    setStaticParams(params);
    setSearchType("smart");
    getPatentList(params);
    setIsPopOpen(true);
  };

  const getPatentList = (params) => {
    PatentAPI.getPatentStatic(params)
      .then((res) => {
        if (res.status === 200) {
          setPatentInfo(res.data || {});
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("err:", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLegalData();
    getKeyValueData();
  }, []);

  useEffect(() => {
    if (!isFirstRender.current && !isOpenPopReset) {
      if (searchType === "researcher" && inventorName) {
        getPatentListResearcher(inventorName, patentPage);
      } else if (searchType === "submit" && staticParams.year) {
        getPatentListBySubmit(staticParams.year, patentPage);
      } else if (searchType === "smart" && staticParams.year) {
        getPatentBySmart(
          staticParams.num,
          staticParams.year,
          staticParams.strValue,
          patentPage
        );
      }
    }
  }, [patentPage]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const handlePatentPageChange = (page) => {
    setPatentPage(page);
  };

  const handleResearcherClick = (name) => {
    setInventorName(name);
    setPatentPage(1);
    setSearchType("researcher");
    getPatentListResearcher(name, 1);
    setIsPopOpen(true);
  };

  return (
    <div id="IpStatisticNew">
      <div className="box_wrap">
        <Box
          title={titles.submitNumber}
          comp={<SubmitNumbers getPatentListBySubmit={getPatentListBySubmit} />}
        />
        <Box
          comp={<LegalStatusChart data={statusData} color={legalColors} />}
        />
        <Box
          title={titles.legalStatus}
          comp={<LegalStatus data={statusData} color={legalColors} />}
        />
        <Box title={titles.ipc} comp={<IpcStatistic />} />
        <Box
          title={titles.keyValue}
          comp={
            <KeyValue
              datas={keyValueData}
              getPatentBySmart={getPatentBySmart}
            />
          }
        />
        <Box
          title={titles.researcher}
          comp={
            <Researcher
              getInventorList={handleResearcherClick}
              setIsPopOpen={setIsPopOpen}
            />
          }
        />
        <Box title={titles.rights} comp={<RightCheck data={statusData} />} />
      </div>
      <Box
        comp={
          <div id="IpStatistic">
            <DistributionStatus />
          </div>
        }
      />
      <PatentList />
      {isPopOpen && (
        <DistributionPop
          closePatentPop={closePatentPop}
          patents={patentInfo}
          setPatentPage={handlePatentPageChange}
          dataCondition={staticParams}
          setViewData={setViewData}
          setShowPopup={setShowPopup}
        />
      )}
      {showPopup && (
        <div className="modal">
          <iframe
            src={`https://biznavi.co.kr/researchTech/ipnow/patent/${
              viewData.nation_string === "KR"
                ? viewData.apply_number.replace(/-/g, "")
                : viewData.lt_number
            }/detail?nation=${
              viewData.nation_string
            }&companyName=${compName}&tab=0`}
            width="100%"
            height="100%"
            title="특허상세"
          />
          <button onClick={() => setShowPopup(false)}>
            <i className="icon_exit" />
          </button>
        </div>
      )}
    </div>
  );
};

export default IpStatisticNew;
