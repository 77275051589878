import React from 'react';

let bliilng =  React.createContext({
    bill: {
        account_number: "",
        account_owner: "",
        agent_commission: 0,
        agent_company_info: [],
        agent_idx: "",
        agent_manage_number: null,
        agent_name: "",
        apply_number: "",
        bank_name: "",
        basic_fee: 0,
        billing_number: null,
        claim_amount: 0,
        comment: "",
        commission_dc: 0,
        company_info: [],
        created_at: 0,
        dependent_claim_cnt: 0,
        dependent_ec: 0,
        deposit_amount: 0,
        deposit_at: 0,
        drawing_cnt: 0,
        drawing_ec: 0,
        ed_cnt: 0,
        etc_fee: 0,
        files: [],
        for_basic_fee: 0,
        for_drawing_fee: 0,
        for_etc_fee: 0,
        for_gc: 0,
        for_remittance_fee: 0,
        for_sum_commission: 0,
        for_sum_total: 0,
        for_surtax: 0,
        for_translate_fee: 0,
        gc: 0,
        idx: "",
        independent_claim_cnt: 0,
        independent_ec: 0,
        judgement_at: 0,
        judgement_result: "",
        manage_number: "",
        nation: 0,
        oa_cnt: 0,
        patent: {},
        patent_agent_idx: null,
        patent_agent_name: null,
        patent_idx: "",
        real_claim_at: 0,
        referee_number: "",
        referee_type: "",
        scheduled_claim_at: 0,
        screen_claim_cnt: 0,
        state: 0,
        statement_page_cnt: 0,
        statement_page_ec: 0,
        submission_cnt: 0,
        sum_commission: 0,
        sum_total_domestic: 0,
        surtax: 0,
        time_charge_ec: 0,
        total_claim_cnt: 0,
        total_claim_ec: 0,
        type: 0,
        work_hour: 0,
        work_hour_rate: 0,
        // 상표
        apply_basic_commission: "",
        apply_basic_fee: "",
        apply_add_commission: "",
        s_r_commission: "",
        s_r_fee: "",
        p_a_commission: "",
        p_a_fee: "",
        oa_commission: "",
        oa_fee: "",
        reg_commission: "",
        reg_fee: "",
        renewal_basic_commission: "",
        renewal_basic_fee: "",
        renewal_add_commission: "",
        renewal_add_fee: "",
        total_commision: "",
        domestic_fee: "",
        work_description: "",
        change_description: "",
        pay_year: 0,
        billing_at: 0,
        billing_scheduled_at: 0,
    },
    func: {},
    largeClass: [],
    middleClass: [],
});

export default bliilng;
