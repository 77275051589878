import { useEffect, useState, useCallback } from "react";
import PatentAPI from "../../../API/patent";
import { debounce } from "lodash";
import "../css/IpcStatistic.scss";

const Researcher = ({ getInventorList, setIsPopOpen, getData }) => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [rowData, setRowData] = useState([]);

  // const getData = async (newPage, initialLoad = false) => {
  //   try {
  //     const res = await PatentAPI.getInventor({
  //       apply_at: getYearsAgo(10),
  //       page: newPage,
  //       count: count,
  //     });
  //     if (res.data?.inventorData?.length > 0) {
  //       if (initialLoad) {
  //         setRowData(res.data.inventorData);
  //       } else {
  //         setRowData((prevRowData) => [
  //           ...prevRowData,
  //           ...res.data.inventorData,
  //         ]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching inventor data:", error);
  //   }
  // };

  const handleScroll = useCallback(
    debounce((event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setPage((prevPage) => prevPage + 1);
      }
    }, 200),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData(1, true);
      setRowData(data.inventorData || []);
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (page > 1) {
  //     getData(page).then((data) => {
  //       console.log("data2:", data);
  //       setRowData((prevRowData) => [
  //         ...prevRowData,
  //         ...(data.inventorData || []),
  //       ]);
  //     });
  //   }
  // }, [page, getData]);

  const getYearsAgo = (years) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const yearsAgo = currentYear - years;

    return yearsAgo;
  };

  return (
    <div
      id="Researcher"
      onScroll={handleScroll}
      style={{ height: 250, overflowY: "scroll" }}
    >
      <table className="researcher-table">
        <thead>
          <tr>
            <th className="grid-header" style={{ width: "60px" }}>
              순위
            </th>
            <th className="grid-header" style={{ width: "90px" }}>
              연구자
            </th>
            <th className="grid-header" style={{ width: "60px" }}>
              출원건수
            </th>
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, index) => {
            const intensity = Math.min(row.cnt / 200, 1);
            const backgroundColor = `rgba(44, 89, 133, ${intensity})`;
            const color = intensity > 0.5 ? "white" : "black";

            return (
              <tr
                key={index}
                onClick={() => {
                  getInventorList(row.inventor);
                  setIsPopOpen(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <td className="grid-cell">{index + 1}</td>
                <td className="grid-cell">{row.inventor}</td>
                {/* style={{ backgroundColor, color }} */}
                <td className="grid-cell">{row.cnt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Researcher;
