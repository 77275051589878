import React, {useState, useEffect} from "react";
import ContentTableList from "../common/ContentTableList";
import CommonAPI from "../../API/common/index";
import _ from "lodash";

function NotifyListView({history}) {
    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [mTbody, setMTbody] = useState([]);
    const [data, setData] = useState({});
    const [moreList, setMoreList] = useState([]);

    useEffect(() => {
        let payload = {
            page: 1,
            count: count
        };
        getList(payload);
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawMobile();
        }
    }, [moreList]);

    const getList = (payload, type) => {
        CommonAPI.getNoticeList(payload).then(res => {
            let get_data = res.data;

            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
            }
            setData(get_data);

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const nextList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            return (
                <tr key={index} onClick={() => onClickInfo(item.idx)}>
                    <td>{data.total_count - index}</td>
                    <td>
                        <h2>{item.title}</h2>
                    </td>
                    <td>{window.$Global.convertDate(item.created_at)}</td>
                </tr>
            );
        }));
        setMoreList(new Array(data.items.length));
    };

    const drawMobile = () => {
        setMTbody(data.items.map((item, index) => {
            return (
                <div key={index}>
                    <div className="item">
                        <div className="item_header border_none">
                            <div className="header_info">
                                <p className="date">{window.$Global.convertDate(item.created_at)}</p>
                                <p className="title">{item.title}</p>
                            </div>
                            {
                                moreList[index]
                                    ?   <button className="icon_more_arrow_down_black" onClick={() => onClickToggle(index)}/>
                                    :   <button className="icon_more_arrow_up" onClick={() => onClickToggle(index)}/>
                            }
                        </div>
                        <p className={`content sun-editor-editable ${moreList[index] ? 'active' : ''}`} dangerouslySetInnerHTML={ {__html: item.content} }/>
                    </div>
                </div>
            );
        }));
    };

    const onClickToggle = (index) => {
        let copy = _.cloneDeep(moreList);
        copy[index] = !copy[index];
        setMoreList(copy);
    };

    const onClickInfo = (idx) => {
        history.push(`/notify/detail/${idx}`);
    };

    return (
        <ContentTableList history={history} title="공지사항" data={data.items || []} content={tbody} m_content={mTbody} drawList={nextList} hasMore={hasMore}/>
    );
}

export default NotifyListView;
