import React, { useRef, useState, useEffect, useCallback, useLayoutEffect } from "react";
import "../css/HistoryForm.scss";
import SunEditor from "suneditor-react";
import DatePickerCustom from "../../datepicker/DatePickerCustom";
import { useDropzone } from "react-dropzone";
import TradeMarkAPI from "../../../API/trademark";


const FormHistory = ({ onModalClose, onSubmit, record, trademark_idx, type = "HISTORY" }) => {
  const [formData, setFormData] = useState({
    work_at: record?.work_at || "",
    legal_deadline: record?.legal_deadline || "",
    instruction_deadline: record?.instruction_deadline || "",
    instruction_type: record?.is_legal_deadline_done
      ? "legal"
      : record?.is_instruction_deadline_done
        ? "agreedUpon"
        : "",
    job_title: type === "HISTORY" ? record?.title ?? "" : record.parent.title ?? "",
    important: !!record?.is_important,
    comments: record?.content || "",
  });

  // Attachments Logic
  const [attachments, setAttachments] = useState(record?.file || []);
  const [loadingAttachments, setLoadingAttachments] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = Array.from(acceptedFiles).map((file, index) => ({
      idx: crypto.randomUUID(),
      operation: "NEW",
      file_name: file.name.split(".")[0],
      file_type: file.name.split(".")[1],
      created_at: file.lastModified,
      name: "-",
      file: file,
    }));
    setAttachments((oldList) => [...oldList, ...newFiles]);
    setCollapseAttachments(false);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDateInputsChange = (field, pickedDate) => {
    setFormData((prev) => ({
      ...prev,
      [field]: new Date(pickedDate).getTime(),
    }));
  };

  const removeAttachment = (attachmentIdx) => {
    const attachmentIndex = attachments.findIndex(({ idx }) => idx === attachmentIdx);
    if (attachmentIndex === -1) return;

    if (attachments[attachmentIndex].operation === "NEW") {
      const attachmentsCopy = [...attachments];
      attachmentsCopy.splice(attachmentIndex, 1);
      setAttachments(attachmentsCopy);
    } else {
      setAttachments((oldList) =>
        oldList.map((file, index) => (index === attachmentIndex ? { ...file, operation: "DELETE" } : file))
      );
    }
  };

  const fetchHistoryFiles = useCallback(async () => {
    setLoadingAttachments(true);
    try {
      const result = await TradeMarkAPI.getTradeMarkHistoryFiles(trademark_idx, record.idx).then(
        (res) => res.data.fileList
      );
      setAttachments(
        result?.map((file) => ({
          ...file,
          operation: "",
        }))
      );
    } catch (error) {
    } finally {
      setLoadingAttachments(false);
    }
  }, [record.idx, trademark_idx]);

  const acceptIncomingChanges = useRef(true);

  const downloadFileHandler = (file_idx) => {
    window.$Global.downloadViaFetch(
      `/manager/trademark/${trademark_idx}/history/${record.idx}/file/${file_idx}/download`
    );
  };

  useLayoutEffect(() => {
    if (!acceptIncomingChanges.current) return;

    setFormData({
      work_at: record?.work_at ?? "",
      legal_deadline: record?.legal_deadline ?? "",
      instruction_deadline: record?.instruction_deadline ?? "",
      job_title: type === "HISTORY" ? record?.title ?? "" : record.parent.title ?? "",
      important: record?.is_important || false,
      comments: record?.content || "",
      instruction_type: record?.is_legal_deadline_done
        ? "legal"
        : record?.is_instruction_deadline_done
          ? "agreedUpon"
          : "",
    });

    if (editorRef.current) editorRef.current.editor.setContents(record?.content || "");

    if (!record.work_at) setAttachments([]);
    if (record?.file) setAttachments(record.file);
  }, [record, trademark_idx, type]);

  useEffect(() => {
    if (record.idx && !record.file) fetchHistoryFiles();
  }, [fetchHistoryFiles, record.file, record.idx, type]);

  const editorRef = useRef(null);

  const [collapseHistory, setCollapseHistory] = useState(false);
  const [collapseAttachments, setCollapseAttachments] = useState(false);

  // Submit Logic

  const submitHandler = async (event) => {
    event.preventDefault();

    // Files Logic
    if (record.idx) {
      const newFiles = attachments.filter(({ operation }) => operation === "NEW");
      const deletedFiles = attachments.filter(({ operation }) => operation === "DELETE");

      if (newFiles.length || deletedFiles.length) {
        const confirmFileChanges = window.confirm("이 파일의 변경 사항을 저장하시겠습니까?");
        if (confirmFileChanges) {
          acceptIncomingChanges.current = false;

          const filesPromises = [];
          setLoadingAttachments(true);

          deletedFiles?.forEach(({ idx: fileIdx }) => {
            filesPromises.push(TradeMarkAPI.deleteTradeMarkHistoryFiles(trademark_idx, record.idx, fileIdx));
          });

          if (newFiles.length) {
            const filesFormData = new FormData();
            newFiles?.forEach(({ file }) => {
              filesFormData.append("file", file);
            });
            filesPromises.push(TradeMarkAPI.uploadTradeMarkHistoryFiles(trademark_idx, record.idx, filesFormData));
          }

          try {
            await Promise.all(filesPromises);
          } catch (error) {
            window.alert("파일 변경 사항을 저장하는 동안 오류가 발생했습니다. 다시 시도하세요!!!");
          } finally {
            fetchHistoryFiles();
            acceptIncomingChanges.current = true;
          }
        }
      }
    }

    onModalClose();
    onSubmit(
      {
        ...formData,
        attachments,
        comments: editorRef.current.editor.getContents(),
        ...(type === "REPLY" && { parent_idx: record.parent.idx }),
      },
      type
    );
  };

  // Computed Values

  const filterdAttachments = attachments?.filter(({ operation }) => operation !== "DELETE");
  const isSubmitDisabled = type === "HISTORY" ? !formData.work_at || !formData.job_title : !formData.work_at;

  return (
    <div className="form_history_container">
      <input type="text" style={{ opacity: 0, width: 0, height: 0, pointerEvents: "none", position: "fixed" }} />

      <div>
        <header>
          <div onClick={() => setCollapseHistory(!collapseHistory)}>
            <i style={{ rotate: collapseHistory ? "-180deg" : "" }} className="icon_arrow_down_gray"></i>
            <h1>
              <span>{type === "HISTORY" ? "History" : "Reply"}</span> 신규 입력
            </h1>
          </div>
          <i onClick={onModalClose} className="icon_exit_gray"></i>
        </header>
        <form id="history_form" onSubmit={submitHandler} method="modal">
          <div className="collapsable_fields" style={{ height: collapseHistory ? 0 : "auto" }}>
            <div className="dates_div">
              <div className="field_div">
                <label htmlFor="work_at">
                  업무일자<span className="mandatory">*</span>
                </label>
                <DatePickerCustom
                  data={formData.work_at}
                  onChangeDatePicker={(pickedDate) => handleDateInputsChange("work_at", pickedDate)}
                />
              </div>
              {type === "HISTORY" && (
                <>
                  <div className="field_div">
                    <label htmlFor="legal_deadline">법정기한</label>
                    <DatePickerCustom
                      data={formData.legal_deadline}
                      onChangeDatePicker={(pickedDate) => handleDateInputsChange("legal_deadline", pickedDate)}
                    />
                  </div>
                  <div className="field_div">
                    <label htmlFor="instruction_deadline">지시요청 기한</label>
                    <DatePickerCustom
                      data={formData.instruction_deadline}
                      onChangeDatePicker={(pickedDate) => handleDateInputsChange("instruction_deadline", pickedDate)}
                    />
                  </div>
                </>
              )}
            </div>

            {/* Reply Records Specific Fields */}
            {type === "REPLY" && (
              <div className="deadline_type">
                <label>기한처리</label>
                <div>
                  <div>
                    <input
                      type="radio"
                      name="instruction_type"
                      id="legal"
                      value="legal"
                      checked={formData.instruction_type === "legal"}
                      onChange={() => setFormData((prevState) => ({ ...prevState, instruction_type: "legal" }))}
                    />
                    <label htmlFor="legal">법정기한</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="instruction_type"
                      id="agreedUpon"
                      value="agreedUp"
                      checked={formData.instruction_type === "agreedUpon"}
                      onChange={() => setFormData((prevState) => ({ ...prevState, instruction_type: "agreedUpon" }))}
                    />
                    <label htmlFor="agreedUpon">지시요청기한</label>
                  </div>
                </div>
              </div>
            )}

            <div className="job_title">
              <label>업무제목{type === "HISTORY" && <span className="mandatory">*</span>}</label>
              <div className={`${formData.important ? "important" : ""}`}>
                <input
                  type="text"
                  name="job_title"
                  value={formData.job_title}
                  disabled={type === "REPLY"}
                  onChange={(event) => setFormData((prev) => ({ ...prev, job_title: event.target.value }))}
                />
                <div>
                  <label htmlFor="important">중요!</label>
                  <input
                    type="checkbox"
                    name="important"
                    id="important"
                    disabled={type === "REPLY"}
                    checked={formData.important}
                    onChange={(event) => setFormData((prev) => ({ ...prev, important: event.target.checked }))}
                  />
                </div>
              </div>
            </div>

            <div className="attachment_div">
              <div>
                <span onClick={() => setCollapseAttachments(!collapseAttachments)}>
                  첨부파일
                  <i style={{ rotate: collapseAttachments ? "-180deg" : "" }} className="icon_arrow_down_gray"></i>
                </span>

                <div
                  className="drag_zone"
                  style={{ ...(isDragActive && { backgroundColor: "#1e59a1", color: "#fff" }) }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p>내 PC에서 첨부하거나, 파일을 끌어주세요</p>
                </div>
              </div>

              <div className="table_div" style={{ height: collapseAttachments ? 0 : "auto" }}>
                {loadingAttachments ? (
                  <div className="loading">로딩 첨부 파일</div>
                ) : !!attachments.length ? (
                  <table className="attachment_table">
                    <thead>
                      <tr>
                        <th>순번</th>
                        <th>파일명</th>
                        <th>업로드 날짜</th>
                        <th>업로더</th>
                        <th>다운로드</th>
                        <th>삭제하기</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterdAttachments.map(({ idx, file_name, file_type, created_at, name, operation }, index) => (
                        <tr key={idx ?? `${created_at}-${file_name}`}>
                          <td>{index + 1}</td>
                          <td>{`${file_name}.${file_type}`}</td>
                          <td>{!!created_at && new Date(created_at).toISOString().split("T")[0]}</td>
                          <td>{name}</td>
                          <td>
                            {idx && operation !== "NEW" ? (
                              <i onClick={() => downloadFileHandler(idx)} className="icon_download_attached"></i>
                            ) : (
                              "✔"
                            )}
                          </td>
                          <td>
                            <button onClick={() => removeAttachment(idx)} type="button" className="delete_attachment">
                              삭제하기
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>데이터 없음</div>
                )}
              </div>
            </div>
          </div>

          <div className="comments_div">
            <label htmlFor="">코멘트</label>

            <SunEditor
              lang="ko"
              ref={editorRef}
              setContents={formData.comments}
              onChange={(e) => setFormData((data) => ({ ...data, comments: e }))}
              name="comments"
              setOptions={{
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  ["codeView", "bold", "underline", "italic", "strike", "subscript", "superscript", "removeFormat"],
                  ["fontColor", "hiliteColor", "outdent", "indent", "align", "horizontalRule", "table"],
                ],
              }}
            />
          </div>

          <hr />

          <footer className="submit_controls">
            <button
              disabled={isSubmitDisabled || loadingAttachments}
              style={{
                ...(isSubmitDisabled && { backgroundColor: "rgb(150, 199, 24 , 0.5)", cursor: "not-allowed" }),
              }}
              type="submit"
            >
              저장
            </button>
            <button type="reset" onClick={onModalClose}>
              {loadingAttachments ? "로딩중" : "취소"}
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
};

export default FormHistory;
