import React, {useEffect, useState} from "react";
import "./css/CyberSignup.scss";
import CyberAPI from "../../API/cyber";
import MemberAPI from "../../API/member";
import config from "../../const/config";

function CyberSignup({history}) {
    const [account, setAccount] = useState({
        id: "",
        password: "",
        confirm_password: "",
        name: "",
        bank_name: "",
        bank_account_number: ""
    });

    const [checkId, setCheckId] = useState(false);
    const [checkPassword, setCheckPassword] = useState(null);
    const [checkRegPassword, setCheckRegPassword] = useState(null);
    const [checkSignup, setCheckSignup] = useState(false);

    useEffect(() => {
        setCheckId(false);
    }, [account.id]);

    useEffect(() => {
        if (account.password.match(config.checkReg)) {
            setCheckRegPassword(true);
        } else {
            setCheckRegPassword(false);
        }

        if (account.password == account.confirm_password) {
            setCheckPassword(true);
        } else {
            setCheckPassword(false);
        }
    }, [account.password, account.confirm_password]);

    useEffect(() => {
        if (checkId && checkPassword && checkRegPassword && account.name && account.bank_name && account.bank_account_number) {
            setCheckSignup(true);
        } else {
            setCheckSignup(false);
        }
    });

    const signup = () => {
        CyberAPI.signup(account).then(res => {
            alert("회원가입되었습니다");
            history.push("/cyber");
        });
    };

    const onClickCheckID = () => {
        if (!account.id.length) {
            alert("아이디를 입력해주세요");
            return;
        }
        MemberAPI.idCheck(account.id).then(() => {
            alert("사용가능한 아이디입니다");
            setCheckId(true);
        });
    }

    return (
        <div id="CyberSignup">
            <h2 className="page_title">가입하기</h2>
            <div className="form">
                <div className="field">
                    <div className="head">
                        <h2>이름*</h2>
                        <p>*필수입력항목</p>
                    </div>
                    <input type="text"
                           value={account.name}
                           onChange={e => setAccount({...account, name: e.target.value})}
                    />
                </div>
                <div className="field">
                    <div className="head">
                        <h2>아이디*</h2>
                    </div>
                    <div className="body">
                        <input type="text"
                               value={account.id}
                               onChange={e => setAccount({...account, id: e.target.value})}
                        />
                        <button className={`${checkId ? 'checked' : ''}`} onClick={onClickCheckID}>중복확인</button>
                    </div>
                </div>
                <div className="field">
                    <div className="head">
                        <h2>비밀번호*</h2>
                    </div>
                    <input type="password"
                           value={account.password}
                           onChange={e => setAccount({...account, password: e.target.value})}
                    />
                    {
                        !checkRegPassword && <p className="alert_msg">{config.regxMsg}</p>
                    }
                </div>
                <div className="field">
                    <div className="head">
                        <h2>비밀번호 확인*</h2>
                    </div>
                    <input type="password"
                           value={account.confirm_password}
                           onChange={e => setAccount({...account, confirm_password: e.target.value})}
                    />
                    {
                        !checkPassword && <p className="alert_msg false">비밀번호가 일치하지 않습니다</p>
                    }
                </div>
                <div className="field">
                    <div className="head">
                        <h2>은행명*</h2>
                    </div>
                    <input type="text"
                           value={account.bank_name}
                           onChange={e => setAccount({...account, bank_name: e.target.value})}
                    />
                </div>
                <div className="field">
                    <div className="head">
                        <h2>계좌번호*</h2>
                    </div>
                    <input type="text"
                           value={account.bank_account_number}
                           onChange={e => setAccount({...account, bank_account_number: e.target.value})}
                    />
                </div>
            </div>
            <button className={`btn ${checkSignup ? '' : 'btn_no'}`} onClick={signup}>가입</button>
        </div>
    )
}

export default CyberSignup;