import React from "react";
import "./css/MarketInfoPopupDetail.scss";

function MarketInfoPopupDetail({show, type, item, onClose}) {
	let hasContent = Boolean(item.content || item.content_image_list?.length);
	let isGraph = type == "graph";
	let isContent = type == "content";
	if (!show) return null;
	return (
		<div id="MarketInfoPopupDetail" className="custom_scroll">
			<div className="header">
				<h2>{item.title}</h2>
				<button className="icon_exit ir_txt" onClick={onClose}>닫기</button>
			</div>
			{
				isGraph && <img src={window.$Global.getCDN(item.image_key)} className={`graph`} alt={`${item.title} 이미지`}/>
			}
			{
				isContent
				&&
					hasContent
					&&
					<div className="content">
						{
							item.content
								? <p>{item.content}</p>
								:
								// <>
								// 	<SlickSlider list={preview || []} onClick={() => setViewer(false)} />
								// </>
								<img src={window.$Global.getCDN(item.content_image_list[0].content_key)} alt={`${item.title} 이미지`}/>
						}
					</div>
			}
		</div>
	);
};

export default MarketInfoPopupDetail;