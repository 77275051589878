import API from '../../util/api';

export default {
    getInfoCompany(keyword, en) {
        return API.get('/info/company', {params: {keyword, en}});
    },
    getInfoCompanyAnnual(company_code) {
        return API.get('/info/company/annual', {params: {company_code: company_code}});
    },
};
