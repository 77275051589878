import React, {useContext} from "react";
import {Doughnut} from "react-chartjs-2";
import CommonContext from "../../../store/Common";

function DoughnutTotalAmount({daily_amount, colors, textShow, innerText, customSize, width, height}) {
    const {isMobile} = useContext(CommonContext);
    daily_amount = daily_amount["7"];

    if (!colors.length) {
        if (isMobile) {
            colors = ['#E1F5FE', '#81D4FA', '#29B6F6', '#039BE5', '#0288D1', '#1976D2', '#0091EA'];
        } else {
            colors = ['#ffe700', '#2d99ff', '#2cd9c5', '#826af9', '#c3eb3f', '#ffa600', '#fa8fff'];
        }
    }

    let keys = Object.keys(daily_amount);
    let day = keys.map(item => item.substr(6));

    const data = {
        labels: day,
        datasets: [{
            label: day,
            data: keys.map(item => Math.floor(daily_amount[item].amount)),
            backgroundColor: colors,
            hoverBackgroundColor: colors,
            borderWidth: 1,
            hoverBorderWidth: 4
        }]
    };

    const option = {
        legend: {
            display: isMobile,
            position: 'left'
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let indice = tooltipItem.index;
                    let value = window.$Global.numberToKorean(data.datasets[0].data[indice]);
                    return  data.labels[indice] +': 약 '+ value.substr(0, value.indexOf("억") + 1) + '';
                }
            }
        },
        maintainAspectRatio: customSize ? false : true,
        responsive: customSize ? false : true,
        cutoutPercentage: 60,
        plugins: {
            datalabels: {
                display: false
            },
        }
    };

    const plugins = [{
        beforeDraw: function(chart) {
            if (textShow) {
                let clientWidth = window.$Global.getBrowserSize().width;
                let width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                let fontSize = (height / 160).toFixed(2);
                ctx.font = fontSize + "em NanumSquareOTF_acB";
                ctx.textBaseline = "top";
                let text = "약 " + innerText,
                    textY = height / 2 - 5;
                let textX;

                if (clientWidth < 460) {
                    textX = Math.round((width - ctx.measureText(text).width) / 1.6) + 15;
                } else {
                    textX = Math.round((width - ctx.measureText(text).width) / 2) + 40
                }

                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }
    }]

    if (textShow && !innerText.length) return null;

    return (
        <Doughnut data={data}
                  width={width || 280}
                  height={height || 215}
                  options={option}
                  plugins={plugins}
        />
    )
};

export default DoughnutTotalAmount;