import React from "react";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function OaInnerDatePickerAndFileRow({
  title,
  preventTitle,
  index,
  data,
  dataKey,
  setKey,
  haveFile,
  onChangeOaInnerDate,
  onClickDownPopShow,
  onClickUpPopShow,
  dateBtnHide,
  uploadBtnShow,
}) {
  let condition = data.date !== null;

  return (
    <div className="row">
      <h2 className="title">
        {title}
        {condition && !preventTitle ? "일" : ""}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      <div className="btns">
        {condition ? (
          <span>{window.$Global.convertDate(data.date)} </span>
        ) : null
        // <>
        //   {/*<span className="date_txt">기한</span>*/}
        //   <span>
        //     {data.inner_date
        //       ? window.$Global.convertDate(data.inner_date)
        //       : "N/A"}{" "}
        //   </span>
        // </>
        }
        <div style={{ display: "flex", alignItems: "center" }}>
          {dateBtnHide ? (
            ""
          ) : (
            <DatePickerCustom
              data={data.date}
              onChangeDatePicker={(date) =>
                onChangeOaInnerDate(date, index, dataKey)
              }
              readOnly={condition}
            />
          )}
          <button
            className={haveFile ? "icon_download_attached" : "icon_download"}
            onClick={onClickDownPopShow}
          />
          {uploadBtnShow ? (
            <button className="icon_upload" onClick={onClickUpPopShow} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default OaInnerDatePickerAndFileRow;
