import React, {useEffect, useState} from "react";
import "../css/AdminServicePosting.scss";
import ConsultingAPI from "../../../API/consulting";
import qs from "query-string";
import _ from "lodash";

function AdminConsultingPosting({history, location}) {
    const category = 2;
    const {idx, type} = qs.parse(location.search);
    const isView = (type == "view");
    const isEdit = (type == "edit");
    const [postInput, setPostInput] = useState({
        idx: "",
        address: "",
        company_name: null,
        description: "",
        email: "",
        field: "",
        img_key: "",
        introduce: "",
        introduce_file: [],
        introduce_strength: "",
        lat: "",
        lon: "",
        phone: "",
        title: "",
        url: ""
    });

    const path = '/admin/manage';
    const pathList = `${path}/list?category=${category}`;
    const commonParam = `${location.pathname}?idx=${idx}`;

    useEffect(() => {
        if (idx) {
            ConsultingAPI.getInfo(idx).then(res => setPostInput(res.data));
        }
    }, [type]);

    const onClickEditGo = () => {
        history.push(`${commonParam}&type=edit`);
    };

    const onClickEdit = async () => {
        await onClickSuccess(true);
    };

    const onClickPost = async () => {
        await onClickSuccess(false);
    };

    const onClickSuccess = async (edit) => {
        let formData = new FormData();
        let introduceFormData = new FormData();

        let keys = Object.keys(postInput);

        keys.forEach(item => {
            if (item == "lat" || item == "lon") return;
            if (item == "introduce_file") {
                if (isEdit) {
                    postInput[item].forEach(file => introduceFormData.append(item, file));
                } else {
                    postInput[item].forEach(file => formData.append(item, file));
                }
            }  else {
                formData.append(item, postInput[item])
            }
        });

        let result = await window.$Global.addressSearch(postInput.address).then(res => res).catch(err => err);
        if (result === "NO DATA") return false;

        formData.append("lat", result.lat);
        formData.append("lon", result.lon);

        if (edit) {
            await ConsultingAPI.updatePost(idx, formData);
            await ConsultingAPI.updateIntroduceFile(idx, introduceFormData);
            alert("수정되었습니다");
            history.push(`${commonParam}&type=view`);
        } else {
            await ConsultingAPI.addPost(formData).then(() => {
                alert("등록되었습니다");
                history.push(pathList);
            });
        }
    };

    const onClickDelete = (file_idx) => {
        ConsultingAPI.deleteIntroduceFile(idx, file_idx).then(() => {
            let copy = _.cloneDeep(postInput);
            let index = copy.introduce_file.findIndex(item => item.idx === file_idx);
            copy.introduce_file.splice(index, 1);
            setPostInput(copy);
        });
    };

    return (
        <div id="AdminServicePosting">
            <h2 className="page_title">관리 게시글 등록</h2>
            <div className="articles">
                <div className="field">
                    <span>기업명</span>
                    {
                        isView
                        ?   <p>{postInput.company_name}</p>
                        :   <input type="text" value={postInput.company_name} onChange={e => setPostInput({...postInput, company_name: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>분야</span>
                    {
                        isView
                            ?   <p>{postInput.field}</p>
                            :   <input type="text" value={postInput.field} onChange={e => setPostInput({...postInput, field: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>이메일</span>
                    {
                        isView
                            ?   <p>{postInput.email}</p>
                            :   <input type="text" value={postInput.email} onChange={e => setPostInput({...postInput, email: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>연락처</span>
                    {
                        isView
                            ?   <p>{postInput.phone}</p>
                            :   <input type="text" value={postInput.phone} onChange={e => setPostInput({...postInput, phone: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>주소</span>
                    {
                        isView
                            ?   <p>{postInput.address}</p>
                            :   <input type="text" value={postInput.address} onChange={e => setPostInput({...postInput, address: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>소개제목</span>
                    {
                        isView
                            ?   <p>{postInput.title}</p>
                            :   <input type="text" value={postInput.title} onChange={e => setPostInput({...postInput, title: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>설명</span>
                    {
                        isView
                            ?   <p>{postInput.description}</p>
                            :   <input type="text" value={postInput.description} onChange={e => setPostInput({...postInput, description: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>회사소개</span>
                    {
                        isView
                            ?   <p>{postInput.introduce}</p>
                            :   <textarea value={postInput.introduce} onChange={e => setPostInput({...postInput, introduce: window.$Global.convertLine(e.target.value)})}/>
                    }
                </div>
                <div className="field">
                    <span>강점분야 ·특징</span>
                    {
                        isView
                            ?   <p>{postInput.introduce_strength}</p>
                            :   <input type="text" value={postInput.introduce_strength} onChange={e => setPostInput({...postInput, introduce_strength: e.target.value})}/>
                    }
                </div>
                <div className="field">
                    <span>썸네일</span>
                    {
                        isView
                            ?   <p>{postInput.img_key.substr("production_dev/consulting/".length)}</p>
                            :   <input type="file" onChange={e => setPostInput({...postInput, file: e.target.files[0]})}/>
                    }
                </div>
                <div className="field">
                    <span>소개자료</span>
                    {
                        isView
                            ?
                            <div>
                                {
                                    postInput.introduce_file.map((item, idx) => {
                                        return (
                                            <p key={idx}>
                                                {item.file_name + "." + item.file_type}
                                                <button className="icon_badge_del ir_txt" onClick={() => onClickDelete(item.idx)}>삭제</button>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                            :   <input type="file" multiple onChange={e => setPostInput({...postInput, introduce_file: [...e.target.files]})}/>
                    }
                </div>
                <div className="field">
                    <span>홈페이지</span>
                    {
                        isView
                            ?   <p>{postInput.url}</p>
                            :   <input type="text" value={postInput.url} onChange={e => setPostInput({...postInput, url: e.target.value})}/>
                    }
                </div>
            </div>

            <div className="btns">
                {
                    isView
                    ?   <button className="btn_post" onClick={onClickEditGo}>수정</button>
                    :
                        isEdit 
                        ?   <button className="btn_post" onClick={onClickEdit}>수정 완료</button>
                        :   <button className="btn_post" onClick={onClickPost}>등록</button>
                }
                <button className="btn_list" onClick={() => history.push(pathList)}>목록</button>
            </div>
        </div>
    )
};

export default AdminConsultingPosting;