import React from "react";

let tradeMarkDispute = React.createContext({
  dispute_type: 0, //분쟁분류 (-1: 없음, 0: 이의신청, 1: 심판, 2: 소송)
  middle_type: 0, //중분류 (심판 - -1: 없음 0: 취소심판, 1: 무효심판, 2: 거절불복심판, 3: 권리범위, 4: 확인심판)
  statutory_type: 0, //계류법정 (-1: 없음 0: 특허심판원, 1: 특허법원, 2: 대법원)
  case_number: "", //사건번호 (String)
  name: "", //분쟁 명칭 (String)
  claimant_plaintiff: "", //청구인 원고 (String)
  claimant_defendant: "", //청구인 피고 (String)
  respondent_plaintiff: "", //피청구인 원고 (String)
  respondent_defendant: "", //피청구인 피고 (String)
  correction_deadline: 0, //마감일 (Millisecond)
  correction_serve_at: 0, //송달일 (Millisecond)
  correction_charge_at: 0, //청구일 (Millisecond)
  correction_decision_at: 0, //결정일 (Millisecond)
  correction_decision_desc: "", //결정내역 (String)
  referee_transposition_at: 0, //심판전치일 (Millisecond)
  referee_transposition_result: "", //심판 전치 결과 (String)
  objection_deadline: 0, //불복 제기 마감일 (Millisecond)
  objection_charge_at: 0, //불복 제기 청구일 (Millisecond)
  give_up_at: 0, //포기일 (Millisecond)
  give_up_desc: "", //포기 내용 (String)
  final_result: "", //최종결과 (String)
  memo: "", //메모 (String)
  history_list: [
    {
      title: "", // (업무제목)
      work_at: 0, // (업무일자)
      content: "", // (업무진행내용)
      instruction_deadline: 0, // (지시요청기한)
      legal_deadline: 0, // (법정기한)
      etc: "", // (비고)
    },
  ],
  fileList: {
    correction: [],
    correction_file: [],
    judge: [],
    judge_file: [],
    objection: [],
    objection_file: [],
    dispute_giveup: [],
    dispute_giveup_file: [],
    dispute_history: [],
    dispute_history_file: [],
  },
});

export default tradeMarkDispute;
