import React, { useContext, useEffect, useState } from "react";
import "./css/MyProduct.scss";
import Menu from "./common/Menu";
import ProductAPI from "../../API/product";
import _ from "lodash";
import memberContext from "../../store/Member";
import CommonAPI from "../../API/common";
import TrademarkAPI from "../../API/trademark";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
function MySelectboxDB() {
  const { auth } = useContext(memberContext);
  const [edit, setEdit] = useState(false);
  const [response, setResponse] = useState([]);
  const [historyResponse, setRistoryResponse] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [changeList, setChangeList] = useState([]);
  const isAdminCompany = window.$Global.checkAuth("특허회사_관리자", auth);
  const isAdminAgent = window.$Global.checkAuth("대리인_관리자", auth);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const title = isCompany
    ? "셀렉DB 관리"
    : isAgent
    ? "셀렉DB 관리"
    : isInvestor && "셀렉DB 관리";
  const content = isCompany
    ? "검색어"
    : isAgent
    ? "주요 분야명"
    : isInvestor && "관심 분야";
  //handle drag
  const [keywordData, setKeywordData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [originresponse, setOriginResponse] = useState([]);
  const [originresponse2, setOriginResponse2] = useState([]);
  const [drag, setdrag] = useState(false);
  const [trademarkResult, setTrademarkResult] = useState(null);
  const [intittrademarkResult, setinitTrademarkResult] = useState(null);
  const [intittrademarkResult2, setinitTrademarkResult2] = useState(null);
  const [intittrademarkResult3, setinitTrademarkResult3] = useState(null);
  const [intittrademarkResult4, setinitTrademarkResult4] = useState(null);
  const [intittrademarkResult5, setinitTrademarkResult5] = useState(null);
  const [intittrademarkResult6, setinitTrademarkResult6] = useState(null);
  const [intittrademarkResult7, setinitTrademarkResult7] = useState(null);
  const [intittrademarkResult8, setinitTrademarkResult8] = useState(null);

  const [categoryIdx, setCategoryIdx] = useState("1");
  const [changedItems, setChangedItems] = useState([]);
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const copyArr = [...response];
    const [draggedItem] = copyArr.splice(result.source.index, 1);
    copyArr.splice(result.destination.index, 0, draggedItem);
    const nameArray = originresponse.map((item) => item.name);

    const updatedList = copyArr.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    const nameArray2 = updatedList.map((item) => item.idx);
    const idxList = updatedList.map((item) => item.idx);
    for (let i = 0; i < updatedList.length; i++) {
      //console.log(updatedList[i])
      //API 호출을하고 여기에 들어가는 product_idx는 arr[i].idx
      //여기에서 {arr[i].name, order: i+1}
      //ProductAPI.updateProduct(
      //updatedList[i].idx,
      //{
      //order : updatedList[i].order,
      //name : updatedList[i].name,
      //image_key : updatedList[i].image_key,
      //},
      //).then(res => res.data);
      //ProductAPI.updateProduct(updatedList[i].idx,updatedList[i]).then(res => res.data);
    }
    setOriginResponse2(updatedList);
    setResponse(updatedList);
  };

  //
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(async () => {
    setdrag(true);
  }, [originresponse2]);

  useEffect(async () => {
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 1, "알렉")
      .then((result) => {
        setinitTrademarkResult(result);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 2, "알렉")
      .then((result) => {
        setinitTrademarkResult2(result);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("038HV09GTI0506EL", 3, "호빵")
      .then((result) => {
        setinitTrademarkResult3(result);
        console.log(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 4, "알렉")
      .then((result) => {
        setinitTrademarkResult4(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 5, "알렉")
      .then((result) => {
        setinitTrademarkResult5(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 6, "알렉")
      .then((result) => {
        setinitTrademarkResult6(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 7, "알렉")
      .then((result) => {
        setinitTrademarkResult7(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", 8, "알렉")
      .then((result) => {
        setinitTrademarkResult8(result);
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
  }, []);

  useEffect(async () => {
    TrademarkAPI.searchAllTest("TW6P1UDNMO9H6NN3", categoryIdx, "알렉")
      .then((result) => {
        setTrademarkResult(result); // 결과 값을 상태로 설정
      })
      .catch((error) => {
        console.error("Error occurred while fetching trademark data:", error);
        setTrademarkResult([]); // 에러가 발생할 경우 빈 배열로 설정 혹은 다른 기본 값으로 설정
      });
    let result = {};
    if (isCompany) {
      result = await ProductAPI.getProductListCompany().then((res) => res.data);
    } else if (isAgent) {
      result = await ProductAPI.getProductListAsAgent().then((res) => res.data);
    } else if (isInvestor) {
      result = await ProductAPI.getProductListAsAgent().then((res) => res.data);
    }

    setOriginResponse(result);
    setResponse(result);
    setRistoryResponse(result);
  }, [categoryIdx]);

  const handleButtonClick = (buttonName) => {
    let currentCategoryIdx = "";
    switch (buttonName) {
      case "제품명":
        currentCategoryIdx = "1";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "출원인명":
        currentCategoryIdx = "2";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "당사자명":
        currentCategoryIdx = "3";
        setCategoryIdx(currentCategoryIdx);
        break;
      case "상표명":
        currentCategoryIdx = "4";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "상표상태":
        currentCategoryIdx = "5";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "분쟁상태":
        currentCategoryIdx = "6";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "분쟁종류":
        currentCategoryIdx = "7";
        setCategoryIdx(currentCategoryIdx);

        break;
      case "History 업무제목":
        currentCategoryIdx = "8";
        setCategoryIdx(currentCategoryIdx);

        break;
      default:
        break;
    }
  };

  const onChange = (e, p_idx, idx) => {
    const updatedResponse = response.map((item, index) => {
      if (index === idx) {
        return {
          ...item,
          history: e.target.value,
        };
      }
      return item;
    });
    setResponse(updatedResponse);
  };

  /* new onchange */
  const handleInputChange = (e, idx) => {
    const { value } = e.target;
    // 변경된 idx와 해당하는 history 값을 추출하여 객체로 구성된 배열을 생성
    const newChangedItem = { idx, history: value };

    // 기존의 changedItems와 새로운 변경된 항목을 합쳐서 업데이트
    setChangedItems((prevItems) => [
      ...prevItems.filter((item) => item.idx !== idx), // 기존에 있던 항목은 필터링
      newChangedItem, // 새로운 변경된 항목 추가
    ]);
    console.log(changedItems);
    // 변경된 idx와 history 값을 상태로 업데이트
    const updatedData = trademarkResult.data.map((item, index) => {
      if (idx !== index) {
        return item;
      }
      return { ...item, history: value };
    });

    setTrademarkResult((prevState) => ({
      ...prevState,
      data: updatedData,
    }));
  };

  const onClickDelete = (idx) => {
    if (idx !== "new") {
      let arr = deleteList.concat([]);
      arr.push(idx);
      setDeleteList(arr);
    }
    console.log(deleteList);
    if (Array.isArray(trademarkResult)) {
      let copyArr = trademarkResult.slice(); // 배열 복사
      if (!isNaN(idx) && idx >= 0 && idx < copyArr.length) {
        copyArr.splice(idx, 1); // idx에 해당하는 요소 삭제
        setTrademarkResult(copyArr);
      }
    } else if (
      typeof trademarkResult === "object" &&
      trademarkResult !== null
    ) {
      let newObj = { ...trademarkResult }; // 객체 복사
      delete newObj["data"][idx]; // idx에 해당하는 키를 삭제
      newObj["data"] = newObj["data"].filter((item) => item !== undefined);
      console.log(newObj);
      setTrademarkResult(newObj);
      setResponse(newObj);
    }
  };
  /* for (let i = 0; i < changedHistory.length; i++) {
        const idx = changedHistory[i];
        console.log(idx)
        console.log(changedHistory_text[i])
        console.log(intittrademarkResult)
        if (intittrademarkResult.data[idx]) {
          console.log(intittrademarkResult.data[idx].idx)
          // 해당 인덱스가 존재하는 경우 tradeMark.a API 호출
          //await TradeMarkAPI.callAPIA(idx);
          TrademarkAPI.searchdeleteTest2('TW6P1UDNMO9H6NN3', categoryIdx, changedHistory_text[i],intittrademarkResult.data[idx].idx)
          console.log('inif')
        } else {
          // 해당 인덱스가 존재하지 않는 경우 tradeMark.b API 호출
          //await TradeMarkAPI.callAPIB();
          TrademarkAPI.searchTest2('TW6P1UDNMO9H6NN3', categoryIdx, changedHistory_text[i])
          console.log('outif')
        }
      } */

  /* const onClickEditingEnd = async () => {
        let change_arr = changeList.filter(item => item != undefined);
          if (drag) {
            for (let i = 0; i < originresponse2.length; i++) {
            if (isCompany) {
              //await ProductAPI.updateProduct(change_arr[i].idx, nameArray[i]).then(res => res.data);  *수정할
              await ProductAPI.updateProduct(originresponse2[i].idx, originresponse2[i]).then(res => res.data);
              await sleep(30);
            } else if (isAgent) {
                await ProductAPI.updateProductAsAgent(originresponse2[i].idx, originresponse2[i]);
            } else if (isInvestor) {
                await ProductAPI.updateProductAsInvestor(originresponse2[i].idx, originresponse2[i]);
            }
          }
        }
        for (let i = 0; i < change_arr.length; i++) {
            if (change_arr[i].idx == "new") {
                if (isCompany) {
                    await ProductAPI.addProduct(change_arr[i]);
                } else if (isAgent) {
                    await ProductAPI.addProductAsAgent(change_arr[i]);
                } else if (isInvestor) {
                    await ProductAPI.addProductAsInvestor(change_arr[i]);
                }
            } else {
                if (isCompany) {
                    await ProductAPI.updateProduct(change_arr[i].idx, change_arr[i]).then(res => res.data);
                }
            }
        }

        for (let i = 0; i < deleteList.length; i++) {
            if (isCompany) {
                await ProductAPI.deleteProduct(deleteList[i]);
            } else if (isAgent) {
                await ProductAPI.deleteProductAsAgent(deleteList[i]);
            } else if (isInvestor) {
                await ProductAPI.deleteProductAsInvestor(deleteList[i]);
            }
        }

        window.$Global.saveToast();
        setChangeList([]);
        setDeleteList([]);

        setResponse(response.filter(item => item.name.length > 0));
        setEdit(false);
        setdrag(false);
    }; */

  const onClickEditingEnd = async (item) => {
    const hasEmptyData = trademarkResult.data.some(
      (item) => item.history.trim() === ""
    );

    if (hasEmptyData) {
      alert("데이터를 입력해주세요");
      return;
    }

    const hasDuplicates = trademarkResult.data.some((item, index) => {
      const currentHistory = item.history.trim();
      return (
        currentHistory !== "" &&
        trademarkResult.data.some((innerItem, innerIndex) => {
          const innerHistory = innerItem.history.trim();
          console.log(`Comparing ${currentHistory} with ${innerHistory}`);
          return innerIndex !== index && innerHistory === currentHistory;
        })
      );
    });

    if (hasDuplicates) {
      alert("데이터가 중복되었습니다!!");
      return;
    }
    console.log(deleteList);
    let changedHistory = changedItems.map((item) => item.idx);
    let changedHistory_text = changedItems.map((item) => item.history);
    console.log(changedItems);

    let selectedIntittrademarkResult;
    console.log("여기");
    console.log(categoryIdx);

    switch (categoryIdx) {
      case "1":
        selectedIntittrademarkResult = intittrademarkResult;

        break;

      case "2":
        selectedIntittrademarkResult = intittrademarkResult2;

        break;
      case "3":
        selectedIntittrademarkResult = intittrademarkResult3;

        break;

      case "4":
        selectedIntittrademarkResult = intittrademarkResult4;

        break;
      case "5":
        selectedIntittrademarkResult = intittrademarkResult5;

        break;
      case "6":
        selectedIntittrademarkResult = intittrademarkResult6;

        break;
      case "7":
        selectedIntittrademarkResult = intittrademarkResult7;

        break;
      case "8":
        selectedIntittrademarkResult = intittrademarkResult8;
        break;
    }

    for (let i = 0; i < deleteList.length; i++) {
      const idx = deleteList[i];
      console.log("match delete");
      console.log(selectedIntittrademarkResult);
      console.log(idx);
      console.log(selectedIntittrademarkResult.data[idx]);
      TrademarkAPI.searchdeleteTest2(
        "TW6P1UDNMO9H6NN3",
        categoryIdx,
        selectedIntittrademarkResult.data[idx] &&
          selectedIntittrademarkResult.data[idx].history
      );
    }

    for (let i = 0; i < deleteList.length; i++) {
      const idx = deleteList[i];
      console.log("match delete");
      console.log(selectedIntittrademarkResult);
      console.log(idx);
      console.log(selectedIntittrademarkResult.data[idx]);
      TrademarkAPI.searchdeleteTest2(
        "TW6P1UDNMO9H6NN3",
        categoryIdx,
        selectedIntittrademarkResult.data[idx] &&
          selectedIntittrademarkResult.data[idx].history
      );
    }

    for (let i = 0; i < changedHistory.length; i++) {
      const idx = changedHistory[i];
      if (selectedIntittrademarkResult.data[idx]) {
        TrademarkAPI.searchUpdateTest(
          "TW6P1UDNMO9H6NN3",
          categoryIdx,
          changedHistory_text[i],
          selectedIntittrademarkResult.data[idx].idx
        );
      } else {
        // 해당 인덱스가 존재하지 않는 경우 tradeMark.b API 호출
        //await TradeMarkAPI.callAPIB();
        TrademarkAPI.searchTest2(
          "TW6P1UDNMO9H6NN3",
          categoryIdx,
          changedHistory_text[i]
        );
      }
    }
    alert("저장되었습니다");
    window.location.href = "/manage/selectboxDB";
  };
  const onClickEditingCancel = () => {
    setEdit(false);
    setResponse(historyResponse);
  };

  const onClickAdd = (category_idx) => {
    let copy = _.cloneDeep(trademarkResult);
    const updatedCopy = { ...copy }; // copy 객체를 복사하여 새로운 객체 생성
    if (Array.isArray(updatedCopy.data)) {
      updatedCopy.data.push({ history: "" }); // copy 객체의 data 속성이 배열인 경우에만 push() 사용
    } else {
      updatedCopy.data = [{ history: "" }]; // 배열이 아닌 경우 새로운 배열을 할당하여 data 속성에 설정
    }
    console.log("Updated Copy:", updatedCopy);
    setTrademarkResult(updatedCopy);
  };

  let tbody;

  if (trademarkResult && Array.isArray(trademarkResult.data)) {
    tbody = trademarkResult.data.map((item, idx) => (
      <tr key={idx}>
        {/* Drag and Drop 관련 코드가 제거되었습니다 */}
        <td width="10%">{idx + 1}</td>
        <td>
          {edit ? (
            <input
              type="text"
              value={item && item.history !== null ? item.history : ""}
              onChange={(e) => handleInputChange(e, idx)}
            />
          ) : (
            item.history
          )}
        </td>
        {edit && (
          <td width="12%">
            <button className="btn_del" onClick={() => onClickDelete(idx)}>
              삭제
            </button>
          </td>
        )}
      </tr>
    ));
  } else {
    tbody = [
      <tr>
        <td colSpan="3">등록된 데이터가 없습니다</td>
      </tr>,
    ];
  }

  let tbody2;

  if (trademarkResult && Array.isArray(trademarkResult.data)) {
    tbody2 = trademarkResult.data.map((item, idx) => (
      <tr key={idx}>
        <td width="10%">{idx + 1}</td>
        <td>
          {edit ? (
            <input
              type="text"
              value={item && item.history ? item.history : ""}
              onChange={(e) => onChange(e, item ? item.idx : "", idx)}
            />
          ) : (
            item.history
          )}
        </td>
        {edit && (
          <td width="12%">
            <button
              className="btn_del"
              onClick={() => onClickDelete(item.idx, idx)}
            >
              삭제
            </button>
          </td>
        )}
      </tr>
    ));
  } else {
    tbody2 = (
      <tr>
        <td colSpan="3">API 결과가 유효하지 않습니다.</td>
      </tr>
    );
  }

  return (
    <div>
      <div id="MyProduct">
        <Menu number={16} />
        <div className="form">
          <div className="area">
            <div className="header">
              <h2>{title}</h2>
              {edit ? (
                <div className="btns">
                  <button className="btn_save" onClick={onClickEditingEnd}>
                    저장
                  </button>
                  <button className="btn_cancle" onClick={onClickEditingCancel}>
                    취소
                  </button>
                </div>
              ) : (
                (isAdminCompany || isAdminAgent) && (
                  <button onClick={() => setEdit(true)}>수정</button>
                )
              )}
            </div>
            <div className="specific-buttons">
              <button
                className={categoryIdx == 1 && "isClicked"}
                onClick={() => handleButtonClick("제품명")}
              >
                제품 추가/변경
              </button>
              <button
                className={categoryIdx == 2 && "isClicked"}
                onClick={() => handleButtonClick("출원인명")}
              >
                출원인 관리
              </button>
              <button
                className={categoryIdx == 3 && "isClicked"}
                onClick={() => handleButtonClick("당사자명")}
              >
                최종권리자관리
              </button>
              <button
                className={categoryIdx == 4 && "isClicked"}
                onClick={() => handleButtonClick("상표명")}
              >
                상표명칭관리
              </button>
              <button
                className={categoryIdx == 5 && "isClicked"}
                onClick={() => handleButtonClick("상표상태")}
              >
                판결승소상태관리
              </button>
              <button
                className={categoryIdx == 6 && "isClicked"}
                onClick={() => handleButtonClick("분쟁상태")}
              >
                분쟁사건종류관리
              </button>
              <button
                className={categoryIdx == 7 && "isClicked"}
                onClick={() => handleButtonClick("분쟁종류")}
              >
                분쟁중인기업관리
              </button>
              <button
                className={categoryIdx == 8 && "isClicked"}
                onClick={() => handleButtonClick("History 업무제목")}
              >
                히스토리업무제목관리
              </button>
            </div>
            <div className="table-box-wrap">
              <div className="wrap_list" id="scrollableDiv">
                {edit === true ? (
                  // edit 상태가 1일 때의 내용
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>

                        <th></th>
                        <th width="10%">순번</th>
                        <th></th>
                        <th></th>
                        <th>{content}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="productList">
                        {(provided) => (
                          <tbody
                            className="keywordList"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {tbody}
                            {provided.placeholder}
                          </tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </table>
                ) : (
                  // edit 상태가 0일 때의 내용
                  <table>
                    <thead>
                      <tr>
                        <th width="10%">순번</th>
                        <th>{content}</th>
                        {edit && <th width="12%">삭제</th>}
                      </tr>
                    </thead>
                    <tbody>{tbody2}</tbody>
                  </table>
                )}
              </div>
            </div>
            {edit && (
              <button className="btn_add" onClick={onClickAdd}>
                + 추가 하기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySelectboxDB;