import React from "react";
import { Link } from 'react-router-dom';
import "../css/Menu.scss";

function Menu({number}) {

    return (
        <div id="myPageMenu">
            <h2>My 페이지</h2>
            <ul>
                <li className={number == 1 ? "active" : ""}>
                    <Link to="/mypage/info">
                        사용자 정보
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Menu;
