import React from "react";

let tradeMark = React.createContext({
  editing: false,
  registEditing: false,
  memoEditing: false,
  withdrawEditing: false,
  objectionEditing: false,
  giveupEditing: false,
  historyEditing: false,
  productEditing: false,
  historyediting: false,
  eraseEditing: false,
  oaEditing: false,
  infoEditing: false,
  applyEditing:false,
  rejectEditing :false,
  cancelEditing:false,
  name: "", // 상표 명칭
  manage_number: "", // 관리번호
  survey_req: 0, // 선행 상표조사 유무
  manager_idx: "", // 담당자 idx
  manager_name: "", // 담당자
  agent_company_idx: "", // 특허 사무소 idx
  agent_company_name: "", // 특허 사무소
  agent_manage_number: "", // 특허 사무소 관리번호
  agent_idx: "", // 대리인 담당자 idx
  agent_name: "", // 대리인 담당자
  relation_product_idx: "", // 관련 제품idx
  relation_product_name: "", // 관련 제품명
  apply_number: "", // 출원번호
  apply_at: "", // 출원일
  nation: 0, // 나라
  nation_string: "",
  objector: 0, // 이의신청 --> 이의신청인
  objection_state: 0, // 이의신청 --> 현 상태
  objection_req: 0, // 이의신청 유무
  objection_at: "", // 이의신청일
  objection_opinion_upload_at: "", // 이의신청 의견서 접수
  objection_submission_at: "", // 이의신청 의견서 제출일
  product: "", // 관련 제품
  product_class: [],
  product_classification: [], // 상품 분류
  cancel_class_list: [],
  regist_decision_at: 0, // 등록 결정일
  regist_at: 0, // 등록 납부일/등록일
  regist_number: "", // 등록번호
  regist_payment_deadline: 0, // 등록료 납부 기한
  divide_pay: 0, // 분할 납부 여부
  second_payment_scheduled_at: 0, // 2차 납부 예정일
  termination_type: 0,
  renew_list: [],
  state: 0, // 상표 상태
  product_list: [],
  file: null, // 미리보기 이미지
  image_key: "",
  fp_image_key: "",
  priority_screen_req: 0, // 우선 심사 청구
  is_drop: 0, // 취하 유무
  drop_receipt_at: 0, // 취하 접수일
  drop_decision_at: 0, // 취하 결정일
  is_give_up: 0, // 포기 유무
  give_up_receipt_at: 0, // 포기 접수일
  give_up_decision_at: 0, // 포기 결정일
  is_erase: 0, // 말소 유무
  is_cancel_judge: 0, // 취소 심판 유무
  is_invalidity_judge: 0, // 무효 심판 유무
  is_cancel: 0, //취소 유무,
  cancel_decision_at: 0, // 취소 결정일,
  show_drop: 0, // 취하 보기
  show_give_up: 0, // 포기 보기
  show_reject: 0, // 거절 결정 보기,
  show_objection: 0, // 이의신청 보기,
  show_cancel_judge: 0, // 취소 심판 보기,
  show_invalidity_judge: 0, // 무효심판 보기,
  show_memo: 0, // 메모
  show_apply: 0, // 출원
  show_product_list: 0, // 지정상품
  show_regist: 0, // 등록
  show_erase: 0, // 말소
  show_reject_reExamination: 0, // 거절결정 > 재심사 보기
  show_reject_refusalDecision: 0, // 거절결정 > 거절결정불복심판 보기
  show_reject_trialDecision: 0, // 거절결정 > 심결취소소송 보기
  show_reject_caseReversed: 0, // 거절결정 > 파기환송심 보기
  show_reject_trialDecision_supreme: 0, // 거절결정 > 심결취소소송(대법원) 보기
  show_reject_reExamination_oa: 0, // 거절결정 > 재심사 중간사건 보기
  show_reject_refusalDecision_oa: 0, // 거절결정 > 거절결정불복심판 중간사건 보기
  show_reject_trialDecision_oa: 0, // 거절결정 > 심결취소소송 중간사건 보기
  show_reject_caseReversed_oa: 0, // 거절결정 > 파기환송심 중간사건 보기
  reject_receipt_at: 0, //거절결정 접수일(millisecond)
  reject_deadline: 0, // 불복심판신청기한(millisecond)
  depend_deadline: 0, // 존속(millisecond)
  reject_give_up: 0, // 거절 대응포기여부
  is_reject_disobey_judge: 0, // 거절 결정 불복심판 유무
  is_reject_final: 0, //  최종거절여부
  show_coexistence_agreement: 1, //공존동의서 보기(0: 안보기, 1: 보기),
  coexistence_agreement_at: 0, //공존동의서(millisecond),
  history_list: [], // 히스토리 리스트
  designated_country_list: [], // 지정국 리스트
  change_international_registration_name_at: 0, //국제등록명의변경일(millisecond),
  changed_international_registration_name: "", // 변경된 명의자(String),
  is_central_attack: 0, //센트럴어택 유무(0: 무, 1: 유),
  right_holder_idx: 0, // 최종권리자 idx (Number),
  applicant_idx: 0, // 출원인 idx (Number),
  name_idx: 0, //상표명칭 (Number),
  right_holder_name: "", // 최종권리자 String
  applicant_name: "", // 출원인 String
  objector_idx: "",
  objector_name: "",
  oa: [],
  reject_exam_oa: [],
  refusal_decision_oa: [],
  trial_decision_patent_oa: [],
  case_reversed_oa: [],
  // 최종심결, 판결 상태 변수
  refusal_decision_objection_judge_final_trial_state: 0,
  case_reversed_final_trial_state: 0,
  trial_decision_patent_judgement_state: 0,
  trial_decision_supreme_judgement_state: 0,
  overseas_agent_company_name: "", // 해외대리인
  is_international_desinated_step: 0, // 마드리드 국제상표출원 지정국 관청 단계
  international_manage_number: "", // 국제상표출원 사건번호
  international_regist_number: "", // 국제상표출원 등록번호
  international_apply_at: 0, // 국제상표출원 출원번호
  basic_manage_number: "", // 기초상표 사건번호
  is_same_basic_manage_number: 0, // 기초상표와 서지정보 동일
  basic_apply_number: "", // 기초출원번호
  basic_apply_at: 0, // 기초출원일
  basic_regist_number: "", // 기초등록번호
  basic_regist_at: 0, // 기초등록일
  invest_manage_number: "", // 조사 사건번호
  // 상표 신규 등록할 때 사용할 파일 저장 변수
  fileList: {
    survey: [],
    survey_file: [],
    apply: [], // 출원일 파일
    apply_file: [], // 출원 관련 파일들
    oa_content: [],
    oa_content_file: [],
    oa_opinion: [],
    oa_opinion_file: [],
    oa_apply: [],
    oa_apply_file: [],
    oa_declare: [],
    oa_declare_file: [],
    oa_declare_extension: [],
    oa_declare_extension_file: [],
    objection_content: [],
    objection_content_file: [],
    objection_opinion: [],
    objection_opinion_file: [],
    regist: [],
    regist_file: [],
    expiration: [],
    expiration_file: [],
    renewal: [],
    renewal_file: [],
    renew: [],
    renew_file: [],
    renew_split: [],
    renew_split_file: [],
    image: [],
    fp_image: [],
    image_file: [],
    fp_image_file: [],
    drop: [],
    drop1: [],
    drop2: [],
    drop_file: [],
    giveup: [],
    giveup1: [],
    giveup2: [],
    giveup_file: [],
    split: [],
    split_file: [],
    history: [],
    history_file: [],
    coexistence: [],
    coexistence_file: [],
    registration: [],
    registration_file: [],
    international: [],
    international_file: [],
    designated: [],
    designated_file: [],
    reject: [],
    reject_deadline: [],
    reject_file: [],
    reject_exam_file1: [],
    reject_exam_file2: [],
    reject_exam_file3: [],
    refusal_decision_file1: [],
    refusal_decision_file2: [],
    refusal_decision_file3: [],
    trial_decision_patent_file1: [],
    trial_decision_patent_file2: [],
    trial_decision_patent_file3: [],
    trial_decision_supreme_file1: [],
    trial_decision_supreme_file2: [],
    is_case_file1: [],
    case_reversed_file1: [],
  },
  setTradeMark: () => {},
});

export default tradeMark;
