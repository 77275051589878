import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import "../css/ChartTest.scss";
import React from "react";
import TaskDownload from "../../dataVoucher/TaskDownload";
import dataVoucherAPI from "../../../API/dataVoucher/dataVoucher";
const ChartTest = ({ info, company_idx }) => {
  const patentData = Array.from(Object.values(info.patent_statistic));
  const trademarkData = Array.from(Object.values(info.trademark_statistic));
  const annualPay = Array.from(Object.values(info.annual_pay));
  const annualApply = Array.from(Object.values(info.annual_apply_cnt));
  const annualRegister = Array.from(Object.values(info.annual_register_cnt));
  const annualCntYear = Array.from(Object.keys(info.annual_register_cnt));
  let annualPayYear = [];
  let annualPayCost = [];
  let annualApplyCnt = [];
  let annualRegisterCnt = [];

  annualPay.forEach((el) => {
    annualPayYear.push(el.year + "년");
    annualPayCost.push(el.cost);
  });

  annualApply.forEach((el) => {
    annualApplyCnt.push(el.cnt);
  });

  annualRegister.forEach((el, index) => {
    annualRegisterCnt.push(el.cnt);
    annualCntYear[index] = annualCntYear[index].slice(2) + "년";
  });

  let tmp1 = patentData[0];
  patentData[0] = patentData[1];
  patentData[1] = tmp1;

  let tmp2 = trademarkData[0];
  trademarkData[0] = trademarkData[1];
  trademarkData[1] = tmp2;

  const max1 = Math.max(...annualPayCost) * 1.2;
  const max2 = Math.max(...annualApplyCnt) * 1.2;
  const max3 = Math.max(...annualRegisterCnt) * 1.2;

  const fileDownload = () => {
    let payload = { companyCodeList: [company_idx] };
    dataVoucherAPI.downloadTaskFile(payload);
  };

  const patentChartData = {
    labels: ["등록", "출원", "포기"],
    datasets: [
      {
        data: patentData.slice(0, 3),
        backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        borderWidth: 0,
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: false,
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel;
          },
        },
      },
    },
  };

  const trademarkChartData = {
    labels: ["등록", "출원", "포기"],
    datasets: [
      {
        data: trademarkData.slice(0, 3),
        backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
        borderWidth: 0,
      },
    ],
  };

  const annualPayChartData = {
    labels: annualPayYear,
    datasets: [
      {
        data: annualPayCost,
        backgroundColor: ["#F63430", "#FFC135", "#4B85F8", "#00E7E8"],
        hoverBackgroundColor: ["#F63430", "#FFC135", "#4B85F8", "#00E7E8"],
        borderWidth: 1,
      },
    ],
  };

  const annualCountChartData = {
    labels: annualCntYear,
    datasets: [
      {
        label: "출원갯수",
        data: annualApplyCnt,
        backgroundColor: "rgb(255, 172, 18)",
        borderColor: "rgba(255, 172, 18, 0.2)",
        borderWidth: 4,
        lineTension: 0,
        fill: false,
      },
      {
        label: "등록갯수",
        data: annualRegisterCnt,
        backgroundColor: "rgb(0, 149, 174)",
        borderColor: "rgba(0, 149, 174, 0.2)",
        borderWidth: 4,
        lineTension: 0,
        fill: false,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const options2 = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: max1,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };
  const patent_count_options = {
    onClick: function (e, item) {
      let index = item.length
        ? item[0]._index
        : this.scales["x-axis-0"].getValueForPixel(e.x);
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: max2 >= max3 ? max2 : max3,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <>
      <div id="ChartTest">
        <div className="chart_header">
          <span>{info.company_name}의 공개된 IP 정보 현황</span>
          <span>공개된 IP정보 기준으로 작성된 그래프입니다.</span>
        </div>
        <div className="chart_wrapper">
          <div className="tab_info wrap_chart">
            <div className="header">
              <h2>특 허</h2>
              <Link to="/patent/list">
                자세히보기
                <i className="icon_more_arrow_right_white" />
              </Link>
            </div>
            <div className="chart">
              <p className="total_cnt">
                총<span>{patentData[3]}</span>건
              </p>
              <div className="add_label">등록 {patentData[0]}건</div>
              <div className="apply_label">출원 {patentData[1]}건</div>
              <div className="giveup_label">포기 {patentData[2]}건</div>
              <Doughnut
                data={patentChartData}
                width={195}
                height={160}
                options={options}
              />
            </div>
          </div>
          <div className="tab_info wrap_chart">
            <div className="header">
              <h2>상 표</h2>
              <Link to="/patent/list">
                자세히보기
                <i className="icon_more_arrow_right_white" />
              </Link>
            </div>
            <div className="chart">
              <p className="total_cnt">
                총<span>{trademarkData[3]}</span>건
              </p>
              <div className="add_label">등록 {trademarkData[0]}건</div>
              <div className="apply_label">출원 {trademarkData[1]}건</div>
              <div className="giveup_label">포기 {trademarkData[2]}건</div>
              <Doughnut
                data={trademarkChartData}
                width={195}
                height={160}
                options={options}
              />
            </div>
          </div>
          <div className="tab_payment tab_info">
            <div className="header" style={{ marginBottom: 15 }}>
              <h2>예상 연차료</h2>
              <Link to="/annual/graph" className="btn_link">
                자세히보기
              </Link>
            </div>
            {annualPayCost.findIndex((item) => item > 0) > -1 ? (
              <div className="chart">
                <Bar
                  data={annualPayChartData}
                  width={160}
                  height={200}
                  options={options2}
                />
              </div>
            ) : (
              <p className="no_data no_data_black" style={{ paddingTop: 65 }}>
                현재 등록되어진 그래프가 없습니다.
              </p>
            )}
          </div>
          <div className="tab_count tab_info">
            <div className="header" style={{ marginBottom: 15 }}>
              <h2>연도별 데이터</h2>
              <Link to="/annualCount/graph" className="btn_link">
                자세히보기
              </Link>
            </div>
            {annualApplyCnt.findIndex((item) => item > 0) > -1 &&
            annualRegisterCnt.findIndex((item) => item > 0) > -1 ? (
              <div className="chart">
                <Line
                  data={annualCountChartData}
                  width={180}
                  height={200}
                  options={patent_count_options}
                />
              </div>
            ) : (
              <p className="no_data no_data_black" style={{ paddingTop: 65 }}>
                현재 등록되어진 그래프가 없습니다.
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="dataVoucher">
        <button onClick={fileDownload}>공고 문서 다운 받기</button>
      </div>
    </>
  );
};

export default ChartTest;
