import React, { useState, useEffect, useContext } from "react";
import "../patent/detail/css/NationAddPopup.scss";
import { tradeMarkCountry } from "../../types/nationType";
import TradeMarkContext from "../../store/TradeMark";

function TradeMarkNationAddPopup({ show, setShow, nationType }) {
  const nation_ref = React.useRef();
  const [nationList, setNationList] = useState([]);
  const [tbody, setTbody] = useState([]);
  const tradeMark = useContext(TradeMarkContext);

  useEffect(() => {
    let tag = nationList.map((item, index) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>{tradeMarkCountry[item.child_nation]}</td>
          <td>
            {window.$Global.convertEPNationCodeToKoreanStr(item.child_nation)}
          </td>
          <td>
            <button
              className="icon_badge_del"
              onClick={() => onClickNationDelete(index)}
            />
          </td>
        </tr>
      );
    });
    setTbody(tag);
  }, [nationList]);

  const onClickNationAdd = () => {
    let code = nation_ref.current.value;
    let text =
      nation_ref.current.options[nation_ref.current.selectedIndex].text;

    if (code == "default") return;
    let result = nationList.find((item) => {
      return item.code == code;
    });
    if (result) return;
    setNationList(
      nationList.concat({
        child_type: nationType,
        child_nation: code,
        regist_at: 0,
        regist_number: "",
      })
    );
  };

  const onClickNationDelete = (index) => {
    let arr = nationList.slice(0);
    arr.splice(index, 1);
    setNationList(arr);
  };

  const onClickSubmit = () => {
    tradeMark.designated_country_list = [
      ...tradeMark.designated_country_list,
      ...nationList,
    ];
    tradeMark.setTradeMark({ ...tradeMark });
    setNationList([]);
    setShow(false);
  };

  return (
    <div id="NationAddPopup" style={{ display: show ? "" : "none" }}>
      <div className="popup_header">
        <h2 className="title">지정국가 선택</h2>
        <div className="btns">
          <button className="btn_add" onClick={onClickSubmit}>
            추가하기
          </button>
          <button className="icon_exit" onClick={() => setShow(false)}></button>
        </div>
      </div>

      <div className="popup_content">
        <label style={{ float: "left", marginRight: 29 }}>
          <span>국가선택</span>
          <select defaultValue="default" ref={nation_ref}>
            <option value="default" hidden disabled>
              국가선택
            </option>
            {tradeMarkCountry.map((el, idx) => {
              return <option value={idx}>{el}</option>;
            })}
          </select>
          <button
            className="icon_badge_more_blue"
            onClick={onClickNationAdd}
            style={{ position: "relative", top: 10, left: 5 }}
          />
        </label>
        <div className="wrap_list">
          <table className="list">
            <thead>
              <tr>
                <th width="8%">순번</th>
                <th width="8%">국가</th>
                <th>국가 명칭</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>{tbody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TradeMarkNationAddPopup;
