import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransactionWithdrawal.scss";
import DocTradeMyTransactionWithdrawalList from './DocTradeMyTransactionWithdrawalList';
import CommonAPI from "../../API/common";
import DocTradeSelectDate from "./common/DocTradeSelectDate";

function DocTradeMyTransactionWithdrawal() {
    const [curPage, setCurPage] = useState(1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [resData, setResData] = useState({items: []});

    const getList = (params) => {
        let payload = {
            page: curPage,
            count: 100,
            start_at: startDate,
            end_at: endDate,
            ...params
        };
        CommonAPI.getWithDrawAsCompany(payload).then(res => setResData(res.data));
    };

    const onChangeDate = (sdate, edate) => {
        setStartDate(sdate);
        setEndDate(edate);
    }

    return (
        <div id="docTradeMyTransactionWithdrawal">
            <div className="withdrawal">
                <div className="withdrawal_box">
                    <h2 className="withdrawal_box_title">출금 전 안내사항</h2>
                    <ul className="withdrawal_box_list-wrap">
                        <li className="withdrawal_box_list">
                            <em className="style_blue">&#91;대기중&#93;</em> 출금 신청이 된 상태입니다.
                        </li>
                        <li className="withdrawal_box_list">
                            <em className="style_blue">&#91;입금완료&#93;</em> 회원님의 통장으로 요청금액이 입금되었습니다.
                        </li>
                        <li className="withdrawal_box_list">
                            <em className="style_red">&#91;계좌오류&#93;</em> 계좌번호가 잘못 되어 입금이 불가능 합니다.
                        </li>
                        <li className="withdrawal_box_list">
                            <em className="style_red">&#91;예금주불일치&#93;</em> 회원명과 예금주가 불일치 합니다.
                        </li>
                    </ul>
                </div>
                <div className="withdrawal_content">
                    <div className="withdrawal_content_title-wrap">
                        <h2 className="withdrawal_content_title">출금 내역</h2>
                        <p className="withdrawal_content_info">거래내역은 전자금융거래법에 의해 삭제가 불가하며, 최근 6개월 이내 거래내역만 확인 가능합니다.</p>
                    </div>
                    <div className="withdrawal_content_table">
                        <DocTradeSelectDate getList={getList}
                                            onChangeDate={onChangeDate}
                                            setCurPage={setCurPage}
                        />
                        <table className="withdrawal_content_table_content">
                            <caption className="hidden">출금내역</caption>
                            <colgroup>
                                <col className="number" width="40px"/>
                                <col className="bank" width="140px"/>
                                <col className="account-number" width="135px"/>
                                <col className="application-fee" width="200px"/>
                                <col className="withdrawal-date" width="80px"/>
                                <col className="deposit-date" width="170px"/>
                                <col className="status" width="115px"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">순 번</th>
                                    <th scope="col">은행</th>
                                    <th scope="col">계좌번호</th>
                                    <th scope="col">신청금</th>
                                    <th scope="col">출금 신청일</th>
                                    <th scope="col">입금일</th>
                                    <th scope="col">처리상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                <DocTradeMyTransactionWithdrawalList data={resData}/>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocTradeMyTransactionWithdrawal;