import React, { useState } from "react";
import "./css/ContnetDetail.scss";
import SlickSlider from "./SlickSlider";
import ImageViewer from "./ImageViewer";

function ContentDetail({ history, path, pathParam, page_title, title, update_at, content, files, onClickDownload, preview }) {
    const [viewer, setViewer] = useState(false);
    const [curIndex, setCurIndex] = useState(0);
    const [viewerShow, setViewerShow] = useState(false);

    let file_list = [];

    if (files) {
        file_list = files.map((item, index) => {
            return (
                <li key={index} onClick={() => onClickDownload(item.idx)}>
                    {item.file_name + "." + item.file_type}
                </li>
            );
        });
    }

    const onClickPrevViewer = () => {
        setCurIndex(curIndex - 1);
    };

    const onClickNextViewer = () => {
        setCurIndex(curIndex + 1);
    };

    const showViewer = (idx) => {
        setCurIndex(idx);
        setViewerShow(true);
    };

    return (
        <div id="ContentDetail">
            <p className="page_title pc">{page_title}</p>
            <div className="post">
                <h2 className="title">{title}</h2>
                <p className="post_info">게시일 : {update_at}</p>
                <div className="post_content sun-editor-editable" dangerouslySetInnerHTML={{ __html: content }} />
                {
                    Boolean(preview?.length)
                    &&
                    <SlickSlider list={preview || []} onClick={() => setViewer(false)} />
                }
                {
                    Boolean(file_list.length) &&
                    <div className="attachment_file">
                        <h2>첨부파일</h2>
                        <ul>
                            {file_list}
                        </ul>
                    </div>
                }
            </div>
            <button className="btn_list" onClick={() => history.replace(`${path}/list?${pathParam || ''}`)}>글 목록으로</button>

            {
                viewerShow
                &&
                <ImageViewer
                    curIndex={curIndex}
                    postData={preview}
                    onClickClose={() => setViewerShow(false)}
                    onClickPrevViewer={onClickPrevViewer}
                    onClickNextViewer={onClickNextViewer}
                />
            }
        </div>
    )
}

export default ContentDetail;
