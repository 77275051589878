import "./css/ContentLayout.scss";

const ContentLayout = ({ title, content, isTable, minH, borderBtm = true }) => {
  return (
    <div
      className={`ContentLayout ${borderBtm ? "" : "border_none"}`}
      style={minH ? { minHeight: minH } : { minHeight: "auto" }}
    >
      <strong className="layout_tit">{title}</strong>
      <div className={`layount_content ${isTable ? "table_layout" : ""}`}>
        {content}
      </div>
    </div>
  );
};

export default ContentLayout;
