import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import MobileSignupTypeItem from "../MobileSignupTypeItem";
import _ from "lodash";

function SignupTypeIpnow() {
	const history = useHistory();
	const [checkedList, setCheckedList] = useState([false, false, false, false]);
	const [moreList, setMoreList] = useState([false, false, false, false]);

	const typeJson = [
		{
			title: '법인기업 · 대학 · 연구소',
			desc: 'IP자동 관리 및 수익화제공 정부,지자체<br/>과제정보 제공 다양한 분석자료 제공',
			icon_className: 'icon_company',
			type: 'company'
		},
		{
			title: '특허사무소',
			desc: '거래하는 특허권자와<br/>자동 온라인 연결 및 업무 처리',
			icon_className: 'icon_agent',
			type: 'agent'
		},
		{
			title: '투자자',
			desc: '투자유치 희망 기업 자동 연결<br/>(제공 자료 열람)',
			icon_className: 'icon_investor',
			type: 'investor'
		},
		{
			title: '개인',
			desc: '다양한 개인 명의 서비스 제공<br/>(개인 가입과 법인가입은 별도)',
			icon_className: 'icon_personal',
			type: 'personal'
		}
	];

	useEffect(() => {
		document.getElementById("container").style.minHeight = "auto";
	}, []);

	const onClickChecked = (index) => {
		let copy = _.cloneDeep(checkedList);
		let arr = [false, false, false];
		arr[index] = !copy[index];
		setCheckedList(arr);
	};

	const onClickMoreToggle = (index) => {
		let copy = _.cloneDeep(moreList);
		copy[index] = !copy[index];
		setMoreList(copy);
	};

	const onClickNext = () => {
		let find_idx = checkedList.findIndex(item => item);

		if (find_idx == -1) {
			alert("가입 유형을 선택해주세요");
			return;
		}
		let type = typeJson[find_idx].type;

		history.push(`/signup/terms?type=${type}`);
	};
	return (
		<>
			<div className="pc">
				<div className="notify">
					<h2>회원가입이 필요한 서비스입니다.</h2>
					<p>아래에 해당되는 항목에만 가입하세요.</p>
				</div>
				<div className="select_type">
					<div className="tab">
						<h2 className="title">{typeJson[0].title}</h2>
						<img className="icon_chat" src={require('../../../assets/images/common/icon_pc_company.png').default} alt="이미지"/>
						<p dangerouslySetInnerHTML={ {__html: typeJson[0].desc} }/>
						<p className="alert"/>
						<Link to={`terms?type=${typeJson[0].type}`}>가입</Link>
					</div>
					<div className="tab">
						<h2 className="title">{typeJson[1].title}</h2>
						<img className="icon_chat" src={require('../../../assets/images/common/icon_pc_agent.png').default} alt="이미지"/>
						<p dangerouslySetInnerHTML={ {__html: typeJson[1].desc} }/>
						<p className="alert"/>
						<Link to={`terms?type=${typeJson[1].type}`}>가입</Link>
					</div>
					<div className="tab">
						<h2 className="title">{typeJson[2].title}</h2>
						<img className="icon_chat" src={require('../../../assets/images/common/icon_pc_investor.png').default} alt="이미지"/>
						<p dangerouslySetInnerHTML={ {__html: typeJson[2].desc} }/>
						<p className="alert"/>
						<Link to={`terms?type=${typeJson[2].type}`}>가입</Link>
					</div>
					<div className="tab">
						<h2 className="title">{typeJson[3].title}</h2>
						<img className="icon_chat" src={require('../../../assets/images/common/icon_pc_personal.png').default} alt="이미지"/>
						<p dangerouslySetInnerHTML={ {__html: typeJson[3].desc} }/>
						<p className="alert">
							<i className="icon_alert_circle"/>
							<span>대표님은 법인 가입요망</span>
						</p>
						<Link to={`terms?type=${typeJson[3].type}`}>가입</Link>
					</div>
				</div>
			</div>
			<div className="mo mobile">
				<h2 className="page_title">회원가입</h2>
				{
					typeJson.map((item, index) => {
						return <MobileSignupTypeItem key={index}
													 title={item.title}
													 desc={item.desc}
													 icon_className={item.icon_className}
													 checked={checkedList[index]}
													 moreState={moreList[index]}
													 onClickMoreToggle={() => onClickMoreToggle(index)}
													 onChangeChecked={() => onClickChecked(index)}/>
					})
				}
				<div className="alert">
					<i className="icon_alert_circle"/>
					<p>선택한 이용목적에 따라 서비스 이용에 차이가 발생합니다.</p>
				</div>

				<button className="btn btn_next" onClick={onClickNext}>다음</button>
			</div>
		</>
	)
}

export default SignupTypeIpnow;