import "../css/AdminDummyAccount.scss";
import { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";

const AdminDummyAccount = () => {
  const token = window.sessionStorage.getItem("login_token");
  const [data, setData] = useState({
    id: "",
    password: "",
  });
  const [updateData, setUpdateData] = useState({
    id: "",
    password: "",
  });
  const [userList, setUserList] = useState([]);
  const [hidePassword, setHidePassword] = useState(true);
  const headers = {
    "x-token": token,
  };
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const arr = [];
    await AdminAPI.getDummyAccount(headers)
      .then((res) =>
        res.data.items.map((el) => {
          arr.push({ ...el, isComplete: false });
        })
      )
      .then(() => setUserList(arr));
  };

  const addAcount = async () => {
    await AdminAPI.addDummyAccount(data, headers)
      .then((res) => {
        alert("계정 생성이 완료되었습니다.");
        window.location.reload();
      })
      .catch(() => alert("잘못된 형식입니다."));
  };

  const onChangeData = (e) => {
    if (e.target.name === "id") {
      setData({ ...data, id: e.target.value });
    } else if (e.target.name === "password") {
      setData({ ...data, password: e.target.value });
    }
  };

  const deleteItem = async (idx) => {
    await AdminAPI.deleteDummyAccount(idx, headers).then((res) => {
      alert("삭제되었습니다.");
      window.location.reload();
    });
  };

  const toggleUpdate = (idx, item) => {
    setUpdateData({ id: item.id });
    const newList = userList.map((el) => {
      if (el.idx === idx) {
        const updateItem = {
          ...el,
          isComplete: !el.isComplete,
        };
        return updateItem;
      }
      return el;
    });
    setUserList(newList);
  };

  const onChangeUpdateData = (e) => {
    if (e.target.name === "update_id") {
      setUpdateData({ ...updateData, id: e.target.value });
    } else if (e.target.name === "update_password") {
      setUpdateData({ ...updateData, password: e.target.value });
    }
  };

  const updateAccount = async (idx) => {
    await AdminAPI.updateDummyAccount(idx, updateData, headers).then((res) => {
      alert("수정되었습니다.");
      window.location.reload();
    });
  };
  return (
    <div id="AdminDummyAccount">
      <p className="title">공고 등록 계정 생성</p>
      <div className="input_items">
        <div className="input_item">
          <p>아이디</p>
          <input
            type="text"
            placeholder="아이디를 입력해주세요."
            name="id"
            value={data.id}
            onChange={onChangeData}
          />
        </div>
        <div className="input_item">
          <p>비밀번호</p>
          <input
            type={hidePassword ? "password" : "text"}
            placeholder="비밀번호를 입력해주세요."
            name="password"
            value={data.password}
            onChange={onChangeData}
          />
          <i
            className={hidePassword ? "icon_view" : "icon_hidden"}
            onClick={() => setHidePassword(!hidePassword)}
          />
        </div>
        <button className="create_button" onClick={() => addAcount()}>
          계정 생성
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>계정</th>
            <th>수정</th>
            <th>삭제</th>
          </tr>
        </thead>
        <tbody>
          {userList?.map((el) => {
            return (
              <tr>
                <td>
                  {el.isComplete ? (
                    <>
                      <div>
                        <p>아이디</p>
                        <input
                          type="text"
                          name="update_id"
                          value={updateData.id}
                          onChange={onChangeUpdateData}
                        />
                      </div>
                      <div>
                        <p>비밀번호</p>
                        <input
                          type={hidePassword ? "password" : "text"}
                          name="update_password"
                          value={updateData.password}
                          onChange={onChangeUpdateData}
                        />
                        <i
                          className={hidePassword ? "icon_view" : "icon_hidden"}
                          onClick={() => setHidePassword(!hidePassword)}
                        />
                      </div>
                    </>
                  ) : (
                    el.id
                  )}
                </td>

                <td>
                  {el.isComplete ? (
                    <>
                      <button
                        className="update_btn"
                        onClick={() => updateAccount(el.idx)}
                      >
                        완료
                      </button>
                      <button
                        className="update_btn"
                        onClick={() => toggleUpdate(el.idx, el)}
                      >
                        취소
                      </button>
                    </>
                  ) : (
                    <button
                      className="update_btn"
                      onClick={() => toggleUpdate(el.idx, el)}
                    >
                      수정
                    </button>
                  )}
                </td>
                <td>
                  <button
                    className="delete_btn"
                    onClick={() => deleteItem(el.idx)}
                  >
                    삭제
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDummyAccount;
