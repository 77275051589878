import React from "react";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function InnerDatePickerAndFileRow({
  title,
  titleClass,
  preventTitle,
  data,
  condition,
  setKey,
  haveFile,
  onChangeDatePickerJson,
  onClickDownPopShow,
  onClickUpPopShow,
  uploadBtnShow,
  withCheckpoint,
  onOpenReport
}) {
  let date_content = data.date !== null;

  return (
    <div className="row">
      <h2 className={`title ${titleClass || ""}`}>
        {title}
        {date_content && !preventTitle ? "일" : ""}
       {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      <div className="btns">
        {date_content ? (
          <span>{window.$Global.convertDate(data.inner_date)} </span>
        ) : (
          <>
            {!condition && (
              <span>
                {data.inner_date
                  ? window.$Global.convertDate(data.inner_date)
                  : "N/A"}{" "}
              </span>
            )}
          </>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {condition && (
            <DatePickerCustom
              data={data.inner_date}
              onChangeDatePicker={(date) =>
                onChangeDatePickerJson(setKey, date)
              }
              readOnly={date_content}
            />
          )}
          <button
            className={haveFile ? "icon_download_attached" : "icon_download"}
            onClick={onClickDownPopShow}
          />
          {uploadBtnShow ? (
            <button className="icon_upload" onClick={onClickUpPopShow} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default InnerDatePickerAndFileRow;
