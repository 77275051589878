import React, { useState } from "react";
import DatePickerCustom from "../../../../datepicker/DatePickerCustom";
import SALES_API from "../../../../../API/annualPay/sales";
import SharedStyles from "../../../css/sales/shared.module.scss";

const PackageForm = ({ initialValues, onSubmit, onClose, university }) => {
  const [formData, setFormData] = useState(initialValues ?? {});
  const [loading, setloading] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();
    setloading(true);

    let result;
    const payload = {
      company_idx: university.company_idx,
      packageDate: window.$Global.convertDate(formData.packageDate).substring(0, 7),
      fee: formData.fee,
    };

    try {
      result = !initialValues.idx
        ? await SALES_API.uploadNewPackage(payload)
        : await SALES_API.updatePackage({ ...payload, idx: initialValues.idx });
      onSubmit({ status: "SUCCESS", type: "NEW", message: result.data.message });
    } catch (error) {
      if (error.response) {
        onSubmit({ status: "ERROR", type: "NEW", message: error.response.data.message });
      } else {
        onSubmit({ status: "ERROR", type: "NEW", message: error.message });
      }
    } finally {
      setloading(false);
    }
  };

  const handleTextInputsChange = (field, value) => setFormData((prevState) => ({ ...prevState, [field]: value }));

  return (
    <form onSubmit={submitHandler} className={SharedStyles["sales-form"]}>
      {loading && <span className={`spinner ${SharedStyles["spinner"]}`} />}

      <button className={SharedStyles["close-btn"]} onClick={onClose} type="button">
        <i className="icon_exit" />
      </button>
      <h1>Package 추가하기</h1>
      <div>
        <label htmlFor="originator">발생기관</label>
        <input type="text" disabled value={university.name} />
      </div>
      <div>
        <label htmlFor="occurrence_date">적용날짜*</label>
        <DatePickerCustom
          required={true}
          data={formData.packageDate}
          onChangeDatePicker={(pickedDate) =>
            setFormData((prevState) => ({ ...prevState, packageDate: new Date(pickedDate).getTime() }))
          }
        />
      </div>
      <div>
        <label htmlFor="fee">금액*</label>
        <input
          type="number"
          required
          id="fee"
          value={formData.fee}
          onChange={(event) => handleTextInputsChange("fee", event.target.value)}
        />
      </div>
      <div>
        <label htmlFor="memo">메모</label>
        <textarea
          rows={5}
          id="memo"
          value={formData.memo}
          onChange={(event) => handleTextInputsChange("memo", event.target.value)}
        ></textarea>
      </div>

      <button type="submit">저장</button>
    </form>
  );
};

export default PackageForm;
