import React, { useEffect, useState, useCallback } from "react";
import PatentAPI from "../../../API/patent";
import "../css/IpcStatistic.scss";
import { debounce } from "lodash";

const IpcStatistic = () => {
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [rowData, setRowData] = useState([]);

  const getIpcData = async (newPage, initialLoad = false) => {
    try {
      const res = await PatentAPI.getIPC({
        page: newPage,
        count: count,
        flag: 1,
      });
      if (res.data?.ipcData?.length > 0) {
        if (initialLoad) {
          setRowData(res.data.ipcData);
        } else {
          setRowData((prevRowData) => [...prevRowData, ...res.data.ipcData]);
        }
      }
    } catch (error) {
      console.error("Error fetching IPC data:", error);
    }
  };

  const handleScroll = useCallback(
    debounce((event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setPage((prevPage) => prevPage + 1);
      }
    }, 200),
    []
  );

  useEffect(() => {
    getIpcData(1, true); // 첫 로딩 시 초기 데이터 가져오기
  }, []);

  useEffect(() => {
    if (page > 1) {
      getIpcData(page);
    }
  }, [page]);

  return (
    <div
      id="IpcStatistic"
      onScroll={handleScroll}
      style={{ width: 330, height: 400, overflowY: "scroll" }}
    >
      <table className="ipc-table">
        <thead>
          <tr>
            <th className="grid-header" style={{ width: "110px" }}>
              Main IPC
            </th>
            <th className="grid-header" style={{ width: "100px" }}>
              출원건수
            </th>
            <th className="grid-header" style={{ width: "100px" }}>
              IPC 비율
            </th>
          </tr>
        </thead>
        <tbody>
          {rowData.map((row, index) => {
            const allRowData = [...rowData];
            const sortedData = allRowData.sort((a, b) => b.rate - a.rate);
            const rank =
              sortedData.findIndex((item) => item.ipc_code === row.ipc_code) +
              1;

            let backgroundColor = "#ffffff";
            let color = "#333333";
            if (rank <= 5) {
              backgroundColor = "rgba(44, 89, 133, 1)";
              color = "#ffffff";
            } else if (rank <= 10) {
              backgroundColor = "rgba(44, 89, 133, 0.8)";
              color = "#ffffff";
            } else if (rank <= 15) {
              backgroundColor = "rgba(44, 89, 133, 0.6)";
            } else if (rank <= 20) {
              backgroundColor = "rgba(44, 89, 133, 0.4)";
            } else {
              backgroundColor = "rgba(44, 89, 133, 0.2)";
            }

            return (
              <tr key={index}>
                <td className="grid-cell">{row.ipc_code}</td>
                <td className="grid-cell">{row.count}</td>
                <td className="grid-cell" style={{ backgroundColor, color }}>
                  {row.rate}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IpcStatistic;
