import React, { useContext } from "react";
import CommonContext from "../../../store/Common";
import Const from "../const";
import memberContext from "../../../store/Member";
import CommonAPI from "../../../API/common";
import { useHistory } from "react-router-dom";
import config from "../../../const/config";

function TaskTableBody({
  showType,
  stateType,
  list,
  field,
  onClickDetail,
  onClickInterest,
  onClickShowTaskFile,
}) {
  const history = useHistory();
  const { isMobile } = useContext(CommonContext);
  const regExp1 =
    /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9])/;
  const regExp2 =
    /\d{4}.(0[1-9]|1[012]).(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-4]):(0[0-9]|[1-5][0-9])/;
  // const bizAll = showType == Const.showType.bizAll;
  const bizOrganized = showType == Const.showType.bizOrganized;
  const bizMatching = showType == Const.showType.bizMatching;

  const bizAll = stateType === Const.stateType.all;
  const bizReceiving = stateType === Const.stateType.receiving;
  const bizScheduled = stateType === Const.stateType.scheduled;

  const memberInfo = useContext(memberContext);
  const notLogin =
    window.$Global.isEmptyObject(memberInfo) ||
    memberInfo.idx == config.testerIdx;

  const goBizMatchingURL = (idx) => {
    history.push(`/matchingBiz/detail/${idx}`);
  };

  const onClickRedirectPopup = (ready, url, idx) => {
    // if (!notLogin && ready == 0) {
    //   if (memberInfo.idx) {
    //     CommonAPI.getTaskInfo(idx).then((res) => res.data);
    //   }
    //   window.open(url, "_blank");
    // } else {
    //   onClickDetail(idx);
    // }
    onClickDetail(idx);
  };

  const onClickCondition = (item) => {
    if (bizAll || bizReceiving || bizScheduled) {
      onClickRedirectPopup(item.ready, item.uri, item.idx);
    } else if (bizOrganized) {
      onClickDetail(item.idx, item.title);
    }
  };

  const GetDDay = (deadline_date) => {
    let today = new Date();
    let day = new Date(deadline_date.date);
    let gap = day.getTime() - today.getTime();
    let result = Math.ceil(gap / (1000 * 60 * 60 * 24));

    return "마감 D-" + result;
  };

  const DummyTableBody = () => {
    return (
      <>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
        <tr></tr>
      </>
    );
  };
  return (
    <tbody>
      {Boolean(list?.length)
        ? list?.map((item, idx) => {
            let state = window.$Global.matchJsonToStr(
              field,
              "state",
              item.state
            );
            let stateClassName = "";
            switch (state) {
              case "마감":
                stateClassName = "end";
                break;
              case "접수마감":
                stateClassName = "end";
                break;
              case "접수예정":
                stateClassName = "schedule";
                break;
              case "접수중":
                stateClassName = "ing";
                break;
              case "제한없음":
                stateClassName = "all";
                break;
            }
            return (
              <>
                <tr key={idx}>
                  {
                    <td className="interest">
                      {!bizMatching && (
                        <button
                          className={`${
                            item.is_interest ? "icon_fav_on" : "icon_fav_off"
                          }`}
                          onClick={() =>
                            onClickInterest(item.idx, item.is_interest)
                          }
                        />
                      )}
                    </td>
                  }
                  {(bizAll || bizOrganized || bizReceiving || bizScheduled) && (
                    <>
                      {!isMobile && (
                        <td
                          className="summary"
                          onClick={() => onClickCondition(item)}
                        >
                          <div>
                            {(bizOrganized ||
                              ((bizAll || bizReceiving || bizScheduled) &&
                                item.ready === 1)) && (
                              <>
                                <i className="icon_alert_circle" />
                                <div className="hover_show">
                                  <h2>[사업 미리보기]</h2>
                                  <div
                                    className="summary_content"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.description.replace(
                                          /<br>/gi,
                                          "\n"
                                        ) || "미리보기 내용이 없습니다.",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </td>
                      )}
                      <td
                        className="table_title"
                        onClick={() => onClickCondition(item)}
                      >
                        <p>{item.title}</p>
                      </td>
                      {!isMobile && (
                        <>
                          <td
                            className="state"
                            onClick={() => onClickDetail(item.idx)}
                          >
                            <p className={stateClassName}>
                              {state === "접수중" ? "접수 중" : state}
                            </p>
                          </td>
                          <td
                            onClick={() => onClickCondition(item)}
                            className="reception_deadline_date"
                          >
                            {isNaN(item.reception_at)
                              ? regExp1.test(item.reception_at) ||
                                regExp2.test(item.reception_at)
                                ? window.$Global.convertDate(
                                    new Date(item.reception_at)
                                  )
                                : item.reception_at
                              : window.$Global.convertDate(item.reception_at)}
                          </td>
                          <td
                            onClick={() => {
                              onClickCondition(item);
                              console.log(isNaN(item.deadline_at));
                            }}
                            className="reception_deadline_date"
                          >
                            {isNaN(item.deadline_at)
                              ? regExp1.test(item.deadline_at) ||
                                regExp2.test(item.deadline_at)
                                ? window.$Global.convertDate(
                                    new Date(item.deadline_at)
                                  )
                                : item.deadline_at
                              : window.$Global.convertDate(item.deadline_at)}
                          </td>
                          <td
                            className="money"
                            onClick={() => onClickCondition(item)}
                          >
                            {isNaN(item.amount)
                              ? item.amount
                              : window.$Global.commaify(item.amount)}
                          </td>
                        </>
                      )}
                      <td
                        className="day"
                        onClick={() => onClickCondition(item)}
                      >
                        {item.state === 2 ? (
                          <p>
                            {isNaN(item.receive_d_day)
                              ? item.receive_d_day
                              : item.receive_d_day &&
                                "접수 D-" +
                                  (item.receive_d_day == 0
                                    ? "day"
                                    : item.receive_d_day)}
                          </p>
                        ) : (
                          <p>
                            {isNaN(item.d_day) ? (
                              regExp1.test(item.deadline_at) ||
                              regExp2.test(item.deadline_at) ? (
                                <GetDDay date={item.deadline_at} />
                              ) : (
                                item.d_day
                              )
                            ) : (
                              item.d_day &&
                              "마감 D-" + (item.d_day == 0 ? "day" : item.d_day)
                            )}
                          </p>
                        )}
                        {/*<p>{isNaN(item.d_day) ? item.d_day : item.d_day && 'D-' + (item.d_day == 0 ? 'day' : item.d_day)}</p>*/}
                      </td>
                      {!isMobile && (
                        <>
                          {/*<td onClick={() => onClickCondition(item)}>{item.department_name}</td>*/}
                          <td onClick={() => onClickShowTaskFile(item)}>
                            {Boolean(item.major_file) && (
                              <i className="icon_download_blue" />
                            )}
                          </td>
                        </>
                      )}
                    </>
                  )}
                  {bizMatching && (
                    <>
                      <td
                        className="table_title"
                        onClick={() => goBizMatchingURL(item.idx)}
                      >
                        {item.title}
                      </td>
                      {!isMobile && (
                        <>
                          <td onClick={() => goBizMatchingURL(item.idx)}>
                            {item.company_name}
                          </td>
                          <td onClick={() => goBizMatchingURL(item.idx)}>
                            {window.$Global.commaify(item.amount)}
                          </td>
                          <td onClick={() => goBizMatchingURL(item.idx)}>
                            {window.$Global.convertDate(item.announcement_at)}
                          </td>
                          <td onClick={() => goBizMatchingURL(item.idx)}>
                            {window.$Global.convertDate(item.reception_at)}
                          </td>
                          <td onClick={() => goBizMatchingURL(item.idx)}>
                            {window.$Global.convertDate(item.deadline_at)}
                          </td>
                        </>
                      )}
                    </>
                  )}
                  {!isMobile && <td>{item.hit}</td>}
                </tr>
              </>
            );
          })
        : window.$Global.notTd(10, "조회된 데이터가 없습니다")}
      {Boolean(list.length) && list.length < 5 && <DummyTableBody />}
    </tbody>
  );
}

export default TaskTableBody;
