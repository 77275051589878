import "./css/AdminAnnualFeeCompare.scss";
import * as XLSX from "xlsx";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useLocation, useHistory } from "react-router-dom";
import AdminAPI from "../../../API/admin";
import { useGridApi } from "ag-grid-react";
import DeleteButtonRenderer from "../util/deleteButtonRenderer";
import axios from "axios";
import PatentAPI from "../../../API/patent";

export const columnCentered = {
  headerClass: "text-center",
  cellStyle: {
    textAlign: "center",
    // Add the following if you are using .ag-header-cell-menu-button
    // and column borders are set to none.
    // marginLeft: '-16px'
  },
};

const columnData = [
  {
    field: "No",
    isChecked: true,
    hide: false,
    width: 63,
  },
  {
    field: "체크",
    isChecked: true,
    hide: false,
    width: 63,
  },
  {
    field: "명칭",
    isChecked: true,
    hide: false,
    width: 200,
  },
  {
    field: "국가",
    isChecked: true,
    hide: false,
    width: 100,
  },
  {
    field: "출원번호",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "출원일",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "등록번호",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "등록일",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "공동출원인",
    isChecked: false,
    hide: true,
    width: 200,
  },
  {
    field: "권리자",
    isChecked: false,
    hide: true,
    width: 200,
  },
  {
    field: "IPNOW_Comment",
    isChecked: true,
    editable: true,
    hide: false,
    width: 350,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      rows: 15,
      cols: 50,
    },
  },
  {
    field: "User_Comment",
    isChecked: true,
    editable: false,
    hide: false,
    width: 350,
  },
  {
    field: "Final_Comment",
    isChecked: true,
    editable: true,
    hide: false,
    width: 350,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      rows: 15,
      cols: 50,
    },
  },
];

const userColumnData = [
  {
    field: "No",
    isChecked: true,
    hide: false,
    width: 63,
  },
  {
    field: "체크",
    isChecked: true,
    hide: false,
    width: 63,
  },
  {
    field: "명칭",
    isChecked: true,
    hide: false,
    width: 200,
  },
  {
    field: "국가",
    isChecked: true,
    hide: false,
    width: 100,
  },
  {
    field: "출원번호",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "출원일",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "등록번호",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "등록일",
    isChecked: true,
    hide: false,
    width: 150,
  },
  {
    field: "공동출원인",
    isChecked: true,
    hide: false,
    width: 200,
  },
  {
    field: "권리자",
    isChecked: true,
    hide: false,
    width: 200,
  },
  {
    field: "IPNOW_Comment",
    isChecked: true,
    editable: true,
    hide: false,
    width: 350,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      rows: 15,
      cols: 50,
    },
  },
  {
    field: "User_Comment",
    isChecked: true,
    editable: false,
    hide: false,
    width: 350,
  },
  {
    field: "Final_Comment",
    isChecked: true,
    editable: true,
    hide: false,
    width: 350,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    cellEditorParams: {
      rows: 15,
      cols: 50,
    },
  },
];

const searchSelectOption = [
  {
    key: 0,
    name: "명칭",
    value: "명칭",
  },
  {
    key: 1,
    name: "국가",
    value: "국가",
  },
  {
    key: 2,
    name: "출원번호",
    value: "출원번호",
  },
  {
    key: 3,
    name: "출원일",
    value: "출원일",
  },
  {
    key: 4,
    name: "등록번호",
    value: "등록번호",
  },
  {
    key: 5,
    name: "등록일",
    value: "등록일",
  },
  {
    key: 6,
    name: "공동출원인",
    value: "공동출원인",
  },
  {
    key: 7,
    name: "권리자",
    value: "권리자",
  },
];

const AdminAnnualFeeCompare = () => {
  const [confirmtoggle, setConfirmToggle] = useState(false);
  const history = useHistory();
  const [gridKey, setGridKey] = useState(0);
  const gridApiRef = useRef(null);
  const gridApiRef2 = useRef(null);
  const [showArrow, setShowArrow] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [originalApplyNumber, setOriginalApplyNumber] = useState([]);
  const [selectedRowsForUserGrid, setSelectedRowsForUserGrid] = useState([]);
  const [selectedRowsForUserGrid2, setSelectedRowsForUserGrid2] = useState([]);
  const [sortedSelectedRowsForUserGrid, setsortedSelectedRowsForUserGrid] =
    useState([]);
  const [sendSelectedRows, setsendselectedrows] = useState([]);
  const location = useLocation();
  const { year, month, company_idx } = location.state;
  const ipnowGridRef = useRef();
  const userGridRef = useRef();
  const [columnDefs, setColumnDefs] = useState(columnData);
  const [uploadFileName, setUploadFileName] = useState("");
  const [isTabClick, setIsTabClick] = useState(false);
  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth() + 1;
  const [sortedRowsLength, setSortedRowsLength] = useState(0);
  const [initipnowdata, setinitipnowdata] = useState(0);
  const [hyphenData, sethyphendata] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchOption, setSearchOption] = useState({
    option: "명칭",
    keyword: "",
  });
  const [selectOption, setSelectOption] = useState("");
  const [isCallApiCheck, setIsCallApiCheck] = useState(false);
  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(hyphenData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Blob 생성
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // 파일 다운로드 링크 생성
    const fileName = "IPNOW IP Data.xlsx";
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // 링크 클릭 및 리소스 정리
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadExcel = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("year", currentYear);
    formData.append("month", month2);
    let payload = {
      currentyear: currentYear,
      currentmonth: month2,
      file: formData,
    };
    AdminAPI.annualPayExcelUpload(company_idx, formData)
      .then((response) => {
        if (response.status === 200) {
          alert("업로드 완료되었습니다.");
        } else {
          alert("업로드에 실패했습니다. 다시 시도해주세요.");
        }
      })
      .catch((error) => {
        alert("업로드에 실패했습니다. 다시 시도해주세요.");
        console.error("업로드 실패:", error);
      });
  };

  const handleConfirmClick = () => {
    setConfirmToggle(!confirmtoggle); // 클릭할 때마다 상태를 토글합니다.
  };

  const onChangeYear = (e) => {
    //setPatentNationArr([]);
    //setPayStateArr([]);
    setCurrentYear(Number(e.target.value));
  };

  const onChangeMonth = (e) => {
    //setPatentNationArr([]);
    //setPayStateArr([]);
    setMonth(Number(e.target.value));
  };

  const [month2, setMonth] = useState(month);
  const [currentYear, setCurrentYear] = useState(year);
  const [selectedRows, setselectedRows] = useState([]);

  useEffect(() => {
    setselectedRows(selectedRows);
  }, [selectedRows]);

  const handleRowSelectedForIpnowGrid = (event) => {
    const selectedRowss = event.api.getSelectedRows();
    // 선택된 행들의 데이터를 원하는 방식으로 처리할 수 있습니다.
    setsendselectedrows(selectedRowss);
    setselectedRows(selectedRowss);
  };

  const handleRowSelectedForUserGrid = (event) => {
    const selectedNodes = gridApiRef.current.getSelectedNodes();
    const selectedRows = gridApiRef.current.getSelectedRows();
    setSelectedRowsForUserGrid(selectedRows);
    setSelectedRowsForUserGrid2(selectedRows);
  };

  const [ipnowData, setIpnowData] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [ipnowDataComment, setIpnowDataComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [ipnowDataFinalComment, setIpnowDataFinalComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [ipnowDataUserComment, setIpnowDataUserComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [userData, setUserData] = useState({
    rows: [],
    compareRows: [],
    columns: userColumnData,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [userDataComment, setUserDataComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [userDataFinalComment, setUserDataFinalComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  const [userDataUserComment, setUserDataUserComment] = useState({
    rows: [],
    compareRows: [],
    columns: columnDefs,
    ipCount: 0,
    checkIpCount: 0,
  });

  useEffect(() => {
    if (ipnowDataComment && ipnowDataComment["IPNOW_Comment"] !== undefined) {
      // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
      const matchedRow = initipnowdata.find(
        (item) => item["No"] === ipnowDataComment["No"]
      );

      // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
      let payload = {
        comment: ipnowDataComment["IPNOW_Comment"],
        row: matchedRow,
        currentyear: currentYear,
      };

      AdminAPI.annualPayComment(company_idx, payload).then((response) => {
        if (response.status === 200) {
          alert("저장되었습니다.");
        } else {
          alert("저장 중 오류가 발생했습니다.");
        }
      });
    } else {
    }
  }, [ipnowDataComment]);

  useEffect(() => {
    if (
      ipnowDataFinalComment &&
      ipnowDataFinalComment["Final_Comment"] !== undefined
    ) {
      // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
      const matchedRow = initipnowdata.find(
        (item) => item["No"] === ipnowDataFinalComment["No"]
      );

      // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
      let payload = {
        comment: ipnowDataFinalComment["Final_Comment"],
        row: matchedRow,
        currentyear: currentYear,
      };

      AdminAPI.annualPayFinalComment(company_idx, payload).then((response) => {
        if (response.status === 200) {
          alert("저장되었습니다.");
        } else {
          alert("저장 중 오류가 발생했습니다.");
        }
      });
    } else {
    }
  }, [ipnowDataFinalComment]);

  useEffect(() => {
    if (
      ipnowDataUserComment &&
      ipnowDataUserComment["User_Comment"] !== undefined
    ) {
      // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
      const matchedRow = initipnowdata.find(
        (item) => item["No"] === ipnowDataUserComment["No"]
      );

      // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
      let payload = {
        comment: ipnowDataUserComment["User_Comment"],
        row: matchedRow,
        currentyear: currentYear,
      };
      AdminAPI.annualPayUserComment(company_idx, payload).then((response) => {
        if (response.status === 200) {
          alert("저장되었습니다.");
        } else {
          alert("저장 중 오류가 발생했습니다.");
        }
      });
    } else {
    }
  }, [ipnowDataUserComment]);

  useEffect(() => {
    if (userDataComment && userDataComment["IPNOW_Comment"] !== undefined) {
      // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
      const matchedRow = userData.rows.find(
        (item) =>
          item["No"] === userDataComment["No"] &&
          item["명칭"] === userDataComment["명칭"]
      );

      if (matchedRow.IPNOW_Comment == null) {
        matchedRow.IPNOW_Comment = " ";
      }

      // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
      let payload = {
        comment: userDataComment["IPNOW_Comment"],
        row: matchedRow,
        currentyear: currentYear,
      };

      AdminAPI.annualPayUserDataComment(company_idx, payload).then(
        (response) => {
          if (response.status === 200) {
            alert("저장되었습니다.");
          } else {
            alert("저장 중 오류가 발생했습니다.");
          }
        }
      );
    } else {
    }
  }, [userDataComment]);

  useEffect(() => {
    if (
      userDataFinalComment &&
      userDataFinalComment["Final_Comment"] !== undefined
    ) {
      // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
      let matchedRow = userData.rows.find(
        (item) =>
          item["No"] === userDataFinalComment["No"] &&
          item["명칭"] === userDataFinalComment["명칭"]
      );

      if (matchedRow.Final_Comment == null) {
        matchedRow.Final_Comment = " ";
      }

      // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
      let payload = {
        comment: userDataFinalComment["Final_Comment"],
        row: matchedRow,
        currentyear: currentYear,
      };

      AdminAPI.annualPayUserDataComment(company_idx, payload).then(
        (response) => {
          if (response.status === 200) {
            alert("저장되었습니다.");
          } else {
            alert("저장 중 오류가 발생했습니다.");
          }
        }
      );
    } else {
    }
  }, [userDataFinalComment]);

  // useEffect(() => {
  //   if (
  //     ipnowDataUserComment &&
  //     ipnowDataUserComment["User_Comment"] !== undefined
  //   ) {
  //     // 'Comment' 속성이 존재하고, 값이 undefined가 아닐 때 실행될 코드
  //     const matchedRow = initipnowdata.find(
  //       (item) => item["No"] === ipnowDataUserComment["No"]
  //     );
  //
  //     // ipnowDataComment의 '출원번호' 값과 일치하는 객체 찾기
  //     let payload = {
  //       comment: ipnowDataUserComment["User_Comment"],
  //       row: matchedRow,
  //       currentyear: currentYear,
  //     };
  //     AdminAPI.annualPayUserComment(company_idx, payload).then((response) => {
  //       if (response.status === 200) {
  //         alert("저장되었습니다.");
  //       } else {
  //         alert("저장 중 오류가 발생했습니다.");
  //       }
  //     });
  //   } else {
  //   }
  // }, [ipnowDataUserComment]);

  const [tempRow, setTempRow] = useState({
    ipnow: [],
    user: [],
  });

  const [isChecked, setIsChecked] = useState(false);
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
    };
  }, []);

  // 전체 선택
  const selectAllCheckboxes = (gridApi, getRowClass, isChecked) => {
    const allDisplayedRows = gridApi.getModel().rowsToDisplay;

    allDisplayedRows.forEach((rowNode) => {
      const rowClass = getRowClass(rowNode);
      if (rowClass === "show-checkbox" && isChecked(rowNode.data)) {
        gridApi.selectNode(rowNode, true);
      }
    });
  };

  const onGridReadyCallback = (params) => {
    const gridApi = params.api;

    // 칼럼 클릭 이벤트 리스너 추가
    const onColumnClicked = (event) => {
      // 클릭된 칼럼 정보(event.column)를 사용하여 원하는 동작 수행
      selectAllCheckboxes(gridApi, getRowClass, isChecked);
    };

    // 칼럼 클릭 이벤트 리스너 등록
    gridApi.addEventListener("columnClicked", onColumnClicked);
  };
  // 전체 선택

  const handleFileUpload = async (e, type) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    setSelectedFile(file);
    setUploadFileName(file.name);
    const fileReader = new FileReader();
    let data,
      tmp = [];
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      if (!e.target) return;
      const bufferArray = e.target.result;
      const fileInformation = XLSX.read(bufferArray, {
        type: "buffer",
        cellText: false,
        cellDates: true,
      });
      const sheetName = fileInformation.SheetNames[0];
      const rawData = fileInformation.Sheets[sheetName];
      data = XLSX.utils
        .sheet_to_json(rawData, { raw: false, dateNF: "yyyy-mm-dd" })
        .sort((a, b) => {
          if (a["출원번호"] < b["출원번호"]) {
            return -1;
          }
          if (a["출원번호"] > b["출원번호"]) {
            return 1;
          }
          return 0;
        });
      setTempRow({ ...tempRow, [type]: data });
    };
  };

  const handleUserApiDataUpload = async () => {
    let params = {
      year: year,
      month: month2,
      count: 9999,
      company_idx: company_idx,
    };

    let data = await PatentAPI.getAnnualPayApiList(params).then(
      (res) => res.data.items
    );

    let arr = [];

    data.map((item, index) => {
      item["No"] = index + 1;
      item.apply_at = window.$Global.convertDate(item.apply_at);
      item.register_at = window.$Global.convertDate(item.register_at);
      let obj = {
        idx: item.idx,
        No: item["No"],
        명칭: item.invention_name,
        국가: item.nation_code,
        출원번호: item.apply_number,
        출원일: item.apply_at,
        등록번호: item.register_number,
        등록일: item.register_at,
        공동출원인: item.joint_applicant_list.toString(),
        권리자: item.joint_owner_list.toString(),
        체크: "",
        IPNOW_Comment: item.ipnow_comment,
        User_Comment: item.user_comment,
        Final_Comment: item.final_comment,
        patent_idx: item.patent_idx,
        p_type: item.ptype, // 특허,상표,디자인 구분하기 위한 변수
      };
      arr.push(obj);
    });
    setTempRow({ ...tempRow, user: arr });
    setUserDataComment({ ...userDataComment, ipCount: arr.length, rows: arr });
    setUserDataFinalComment({
      ...userDataFinalComment,
      ipCount: arr.length,
      rows: arr,
    });
    setUserDataUserComment({
      ...userDataUserComment,
      ipCount: arr.length,
      rows: arr,
    });
    setIsCallApiCheck(true);
  };

  useEffect(() => {
    if (selectOption === "api") {
      loadData("user");
    }
  }, [tempRow.user]);

  useEffect(() => {
    setSelectedFile(selectedFile);
  }, [selectedFile]);

  useEffect(() => {
    setsortedSelectedRowsForUserGrid(sortedSelectedRowsForUserGrid);
  }, [sortedSelectedRowsForUserGrid]);

  const alertIsEmpty = () => {
    if (ipnowData.rows.length === 0 || userData.rows.length === 0) {
      alert("IPNOW Data 또는 User Data에 값이 없습니다.");
      return true;
    }
  };

  const compareData = (checked) => {
    const temp1 = tempRow.ipnow.map((item) => {
      return { ...item };
    });

    const temp2 = tempRow.user.map((item) => {
      return { ...item };
    });

    const ipnowEmptyData = temp1.filter(
      (item) =>
        !temp2.some(
          (sub_item) =>
            sub_item["출원번호"].replace(/[-\/\\^$*+?.,()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.,()|[\]{}]/g, "") &&
            sub_item["국가"] === item["국가"]
        ) // 국가, 출원번호 비교
    );

    const userEmptyData = temp2.filter(
      (item) =>
        !temp1.some(
          (sub_item) =>
            sub_item["출원번호"].replace(/[-\/\\^$*+?.,()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.,()|[\]{}]/g, "") &&
            sub_item["국가"] === item["국가"]
        ) // 국가, 출원번호 비교
    );

    if (checked) {
      loadCheckedData("ipnow", userEmptyData, ipnowEmptyData);
      loadCheckedData("user", ipnowEmptyData, userEmptyData);
    } else {
      loadData("ipnow", userEmptyData, ipnowEmptyData);
      loadData("user", ipnowEmptyData, userEmptyData);
    }
  };

  useEffect(() => {
    setsortedSelectedRowsForUserGrid([]);
    loadData("user");
    loadData("ipnow");
  }, [confirmtoggle]);

  /* 화살표 클릭 */
  const handleArrowClick = () => {
    setShowArrow(false);
    setShowSaveButton(true);
    const ipnowGridApi = ipnowGridRef.current.api;
    for (let i = selectedRowsForUserGrid.length - 1; i >= 0; i--) {
      const checkValue = selectedRowsForUserGrid[i]["체크"];

      if (checkValue !== "✓") {
        selectedRowsForUserGrid.splice(i, 1); // '✓'가 아닌 행을 배열에서 삭제
      }
    }
    const mergedRows = [
      ...sortedSelectedRowsForUserGrid,
      ...selectedRowsForUserGrid,
    ];
    setsortedSelectedRowsForUserGrid(mergedRows);

    selectedRowsForUserGrid.forEach((row) => {
      // 선택된 행을 ipnowGrid로 이동
      ipnowGridApi.applyTransaction({ add: [row] });
    });

    const newRows = gridApiRef.current
      .getSelectedNodes()
      .map((node) => node.data);
    newRows.forEach((row) => {
      row.checkbox = true; // 새로운 속성으로 체크박스 정보 추가
    });
    const rowsToRemove = selectedRowsForUserGrid.filter(
      (row) => !newRows.some((newRow) => newRow.id === row.id)
    );
    const rowCount = ipnowGridRef.current.api.getDisplayedRowCount(); // 현재 표시된 행의 개수 가져오기
    const lastRowIndex = rowCount - 1;
    gridApiRef.current.applyTransaction({ remove: selectedRowsForUserGrid });
    ipnowGridRef.current.api.ensureIndexVisible(lastRowIndex, "bottom");
    //setSelectedRowsForUserGrid2(selectedRowsForUserGrid)
  };

  const handleArrowClickToRight = () => {
    const ipnowGridApi = ipnowGridRef.current.api;
    const gridApi = gridApiRef.current;

    // ipnowGrid에서 선택된 행 가져오기
    const selectedRowsForIpnowGrid = ipnowGridApi
      .getSelectedNodes()
      .map((node) => node.data);
    const updatedSortedRows = sortedSelectedRowsForUserGrid.filter(
      (sortedRow) => {
        return !selectedRowsForIpnowGrid.some((selectedRow) => {
          return sortedRow["출원번호"] === selectedRow["출원번호"];
        });
      }
    );
    setsortedSelectedRowsForUserGrid(updatedSortedRows);
    // 가져온 행을 gridApi로 이동
    selectedRowsForIpnowGrid.forEach((row) => {
      gridApi.applyTransaction({ add: [row] });
    });
    // ipnowGrid에서 이동된 행 제거
    ipnowGridApi.applyTransaction({ remove: selectedRowsForIpnowGrid });

    //setsortedSelectedRowsForUserGrid(updatedSortedRows )
    const rowCount = ipnowGridRef.current.api.getDisplayedRowCount(); // 현재 표시된 행의 개수 가져오기
    const lastRowIndex = rowCount - 1;
    gridApi.ensureIndexVisible(lastRowIndex, "bottom");
  };

  /* 화살표 스타일 */
  const arrowStyle = {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: "20px solid blue" /* 화살표 색상 및 크기 설정 */,
    cursor:
      "pointer" /* 마우스 커서를 포인터로 변경하여 클릭 가능함을 나타냄 */,
    position: "relative" /* 위치 지정 */,
    top: "300px" /* 아래로 500px 이동 */,
    transform: "rotate(-270deg)",
  };

  const rightArrowStyle = {
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: "20px solid blue" /* 화살표 색상 및 크기 설정 */,
    cursor:
      "pointer" /* 마우스 커서를 포인터로 변경하여 클릭 가능함을 나타냄 */,
    position: "relative" /* 위치 지정 */,
    top: "300px" /* 아래로 500px 이동 */,
    transform: "rotate(-90deg)" /* 우측 방향을 가리키도록 회전 */,
  };

  /* useEffect(() => {
    if (currentYear == year && month2 == month) {

    } else {
      onGridReady();
    }
  }, [
    currentYear,
    month2,
  ]); */

  useEffect(() => {
    setGridKey((prevKey) => prevKey + 1);
    setIsCallApiCheck(false);
  }, [currentYear, month2]);

  useEffect(() => {
    setSortedRowsLength(sortedSelectedRowsForUserGrid.length);
  }, [sortedSelectedRowsForUserGrid]);

  useEffect(() => {
    const ipnowGridApi = ipnowGridRef.current.api;

    sortedSelectedRowsForUserGrid.forEach((row) => {
      // 선택된 행을 ipnowGrid로 이동
      ipnowGridApi.applyTransaction({ add: [row] });
    });

    const gridApi = gridApiRef.current;
    sortedSelectedRowsForUserGrid.forEach((row) => {
      gridApi.applyTransaction({ remove: [row] });
    });
    setSelectedRowsForUserGrid(sortedSelectedRowsForUserGrid);
  }, [isChecked]);

  /* 체크박스 확인*/
  const handleConfirmation = () => {
    setShowArrow(true); // Show arrow
    setShowSaveButton(false);
    const ipnowGridApi = ipnowGridRef.current.api;

    const selectedNodesForDestinationGrid = ipnowGridApi.getSelectedNodes();
    const selectedRowsForDestinationGrid = selectedNodesForDestinationGrid.map(
      (node) => node.data
    );
    const originalApplyNumberWithoutSpecialChars = originalApplyNumber.map(
      (num) => {
        if (num) {
          if (typeof num === "number") {
            num = num.toString(); // 숫자인 경우 문자열로 변환
          }
          return num.replace(/[-\/\\^$*+?.()|[\]{}]/g, "");
        }
      }
    );

    // const matchingIndexes = sendSelectedRows.map((row) => {
    //   const applyNumberToFind = row["출원번호"]; // 특수문자가 이미 제거된 출원번호를 가져옵니다.
    //
    //   // Find all indexes of the originalApplyNumber without special characters that match the applyNumberToFind
    //   const indexes = originalApplyNumberWithoutSpecialChars.reduce(
    //     (acc, originalRow, index) => {
    //       if (originalRow === applyNumberToFind) {
    //         acc.push(index);
    //       }
    //       return acc;
    //     },
    //     []
    //   );
    //
    //   return indexes;
    // });

    if (sendSelectedRows.length === 0) {
      // If there is no data to save, display an alert
      alert("저장할 데이터가 없습니다.");
      return;
    }
    sendSelectedRows.forEach((row, index) => {
      const applyNumberToFind = row["출원번호"]; // 특수문자가 이미 제거된 출원번호를 가져옵니다.

      // Find the index in originalApplyNumber that matches applyNumberToFind
      const matchingIndex = originalApplyNumber.findIndex(
        (originalRow) => originalRow === applyNumberToFind
      );

      if (matchingIndex !== -1) {
        sendSelectedRows[index]["출원번호"] =
          originalApplyNumber[matchingIndex];
      }
    });

    // let count = 0;
    // sendSelectedRows.forEach((row) => {
    //   const 출원번호 = row["출원번호"];
    //   row["출원번호"] = originalApplyNumber[matchingIndexes[count]];
    //   count++;
    // });

    // If there is data to save, display a confirmation popup
    const confirmed = window.confirm("데이터를 저장하시겠습니까?");
    if (confirmed) {
      AdminAPI.annualPayDiff(company_idx, sendSelectedRows)
        .then((response) => {
          // Clear selection after successful saving
          const allRowNodes = ipnowGridApi.getRenderedNodes();
          allRowNodes.forEach((node) => {
            node.setSelected(false);
          });

          // Display success message after successful saving
          alert("데이터가 저장되었습니다.");
          handleConfirmClick();
        })
        .catch((error) => {
          // Display error message when API call fails (returns status 500)
          alert("이미 저장한 데이터가 포함되어 있습니다.");
          console.error(error); // Optional: Log the error for further investigation
        });
    }
  };

  useEffect(() => {
    setOriginalApplyNumber(originalApplyNumber);
  }, [originalApplyNumber]);

  const loadData = async (type, emptyData = [], existData = []) => {
    if (selectOption === "api" && type === "user" && !isCallApiCheck) {
      await handleUserApiDataUpload();
    }
    let rows = type === "ipnow" ? tempRow.ipnow : tempRow.user;
    rows = emptyData ? rows.concat(emptyData) : rows;
    let updatedOriginalApplyNumber = [...originalApplyNumber];
    rows.map((item, index) => {
      if (item["출원번호"] !== undefined) {
        item["출원번호"] = item["출원번호"].toString();
      }
      if (
        !updatedOriginalApplyNumber.includes(item["출원번호"]) &&
        !updatedOriginalApplyNumber.some((num) => {
          const compareNum = item["출원번호"];
          if (compareNum !== undefined) {
            compareNum.replace(/[-\/\\^$*+?.()|[\]{}]/g, "");
          }
          return (
            !compareNum ||
            num.replace(/[-\/\\^$*+?.()|[\]{}]/g, "") === compareNum
          );
        })
      ) {
        updatedOriginalApplyNumber.push(item["출원번호"]);
      }

      item["No"] = index + 1;
      if (typeof item["출원번호"] === "number") {
        item["출원번호"] = item["출원번호"].toString(); // 출원번호가 숫자인 경우 문자열로 변환
      }

      // if (typeof item["출원번호"] === "string") {
      //   item["출원번호"] = item["출원번호"].replace(
      //     /[-\/\\^$*+?.()|[\]{}]/g,
      //     ""
      //   );
      // }

      if (
        emptyData.some(
          (el) =>
            el["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") &&
            el["국가"] === item["국가"]
        )
      ) {
        return (item["체크"] = "-");
      } else if (
        existData.some(
          (el) =>
            el["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") &&
            el["국가"] === item["국가"]
        )
      ) {
        return (item["체크"] = "✓");
      } else {
        return (item["체크"] = "O");
      }
    });

    getRowClass(rows);

    if (type === "ipnow") {
      setIpnowData({
        ...ipnowData,
        rows: rows,
        ipCount: tempRow.ipnow.length + emptyData.length,
      });
    } else {
      setUserData({
        ...userData,
        rows: rows,
        ipCount: tempRow.user.length + emptyData.length,
      });
    }
    setOriginalApplyNumber(updatedOriginalApplyNumber);
  };

  const loadCheckedData = (type, emptyData = [], existData = []) => {
    let rows = type === "ipnow" ? tempRow.ipnow : tempRow.user;
    rows = emptyData ? rows.concat(emptyData) : rows;

    rows.map((item) => {
      if (
        emptyData.some(
          (el) =>
            el["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") &&
            el["국가"] === item["국가"]
        )
      ) {
        sethyphendata((prevHyphenData) => [...prevHyphenData, item]);
        return (item["체크"] = "-");
      } else if (
        existData.some(
          (el) =>
            el["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") ===
              item["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") &&
            el["국가"] === item["국가"]
        )
      ) {
        const filteredHyphenData = hyphenData.filter(
          (el) =>
            !(
              el["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") ===
                item["출원번호"].replace(/[-\/\\^$*+?.()|[\]{}]/g, "") &&
              el["국가"] === item["국가"]
            )
        );

        sethyphendata(filteredHyphenData);
        return (item["체크"] = "✓");
      } else {
        return (item["체크"] = "O");
      }
    });

    rows.sort((a, b) => {
      if (a["출원번호"] < b["출원번호"]) {
        return -1;
      }
      if (a["출원번호"] > b["출원번호"]) {
        return 1;
      }
      return 0;
    });

    rows = rows.filter((item) => item["체크"] !== "O");

    if (type === "ipnow") {
      setIpnowData({
        ...ipnowData,
        rows: rows,
        checkIpCount: rows.length,
      });
    } else {
      setUserData({
        ...userData,
        rows: rows,
        checkIpCount: rows.length,
      });
    }
  };

  const getRowClass = (params) => {
    let data = params.data || params;

    if (data["체크"] === "-") {
      return "my-shaded-effect";
    } else if (data["체크"] === "✓") {
      return "show-checkbox";
    }
  };

  const handleToggle = (e) => {
    if (alertIsEmpty()) {
      return;
    }
    compareData(e.target.checked);
    setIsChecked(!isChecked);
    const ipnowGridApi = ipnowGridRef.current.api;
    if (!isChecked) {
      sortedSelectedRowsForUserGrid.forEach((row) => {
        ipnowGridApi.applyTransaction({ add: [row] });
      });

      const newRows = gridApiRef.current
        .getSelectedNodes()
        .map((node) => node.data);
      newRows.forEach((row) => {
        row.checkbox = true; // 새로운 속성으로 체크박스 정보 추가
      });
      const rowsToRemove = sortedSelectedRowsForUserGrid.filter(
        (row) => !newRows.some((newRow) => newRow.id === row.id)
      );
      const rowCount = ipnowGridRef.current.api.getDisplayedRowCount();
      const lastRowIndex = rowCount - 1;
      gridApiRef.current.applyTransaction({
        remove: sortedSelectedRowsForUserGrid,
      });
    }
  };

  const onGridReady = (params) => {
    const api = params.api;
    gridApiRef.current = api;
    gridApiRef2.current = api;
    let payload = {
      year: currentYear,
      month: month2,
      count: 9999,
    };
    /* 연차료 다른 Row */
    //const testA = AdminAPI.annualPayDiff(company_idx, payload)
    //console.log(testA)
    /* 연차료 다른 Row */
    AdminAPI.getAdminCompanyPatentList(company_idx, payload).then((res) => {
      setinitipnowdata(res.data.items);
      res.data.items.sort((a, b) => {
        if (a["apply_number"] < b["apply_number"]) {
          return -1;
        }
        if (a["apply_number"] > b["apply_number"]) {
          return 1;
        }
        return 0;
      });

      let arr = [];
      res.data.items.map((item, index) => {
        let obj = {};
        item["No"] = index + 1;
        item.apply_at = window.$Global.convertDate(item.apply_at);
        item.register_at = window.$Global.convertDate(item.register_at);
        obj = {
          No: item["No"],
          명칭: item.invention_name,
          국가: item.nation_code,
          출원번호: item.apply_number,
          출원일: item.apply_at,
          등록번호: item.register_number,
          등록일: item.register_at,
          공동출원인: item.joint_applicant_list.toString(),
          권리자: item.joint_owner_list.toString(),
          체크: "",
          IPNOW_Comment: item.comment,
          User_Comment: item.user_comment,
          Final_Comment: item.final_comment,
          patent_idx: item.patent_idx,
          p_type: item.ptype, // 특허,상표,디자인 구분하기 위한 변수
        };
        arr.push(obj);
      });
      setIpnowData({ ...ipnowData, ipCount: arr.length, rows: arr });
      setIpnowDataComment({ ...ipnowData, ipCount: arr.length, rows: arr });
      setIpnowDataFinalComment({
        ...ipnowData,
        ipCount: arr.length,
        rows: arr,
      });
      setIpnowDataUserComment({ ...ipnowData, ipCount: arr.length, rows: arr });
      setTempRow({ ...tempRow, ipnow: arr });
    });
  };

  const handleTab = (e, item) => {
    item.isChecked = e.target.checked;
    item.hide = !e.target.checked;

    ipnowGridRef.current.columnApi.applyColumnState({
      state: [
        ...columnDefs,
        {
          colId: item.field,
          hide: !e.target.checked,
        },
      ],
    });

    userGridRef.current.columnApi.applyColumnState({
      state: [
        ...columnDefs,
        {
          colId: item.field,
          hide: !e.target.checked,
        },
      ],
    });

    setColumnDefs([...columnDefs]);
  };

  const handleSearch = () => {
    let filterArrI = tempRow.ipnow.filter((item) => {
      return (
        item[searchOption.option] &&
        item[searchOption.option].includes(searchOption.keyword)
      );
    });

    let filterArrU = tempRow.user.filter((item) => {
      return (
        item[searchOption.option] &&
        item[searchOption.option].includes(searchOption.keyword)
      );
    });

    setIpnowData({
      ...ipnowData,
      rows: filterArrI,
    });

    setUserData({
      ...userData,
      rows: filterArrU,
    });
  };

  const onBtExport = useCallback(async () => {
    ipnowGridRef.current.api.exportDataAsExcel();
  }, []);

  const getShowAPICompnayList = () => {
    if (company_idx === "SUF89HHTL1J0YXYS") return true;
    else return false;
  };

  const updateApi = () => {
    console.log(userData);
  };
  return (
    <div id="AdminAnnualFeeCompare">
      <header>
        <div className="header_left">
          <div className="search_wrapper">
            <select
              onChange={(e) =>
                setSearchOption({
                  ...searchOption,
                  option: e.target.value,
                })
              }
            >
              {searchSelectOption.map((item) => {
                return (
                  <option key={item.key} value={item.value} name={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              value={searchOption.keyword}
              onChange={(e) =>
                setSearchOption({ ...searchOption, keyword: e.target.value })
              }
              onKeyUp={(e) => e.key === "Enter" && handleSearch()}
            />
            <i className="icon_search_blue" onClick={handleSearch} />
          </div>
        </div>
        <div className="header_right">
          <button>
            IP Data 확인 필요 리스트 보기
            <label className="switch">
              <input
                type="checkbox"
                checked={isChecked}
                onClick={(e) => handleToggle(e)}
              />
              <span className="slider round" />
            </label>
          </button>
          <button onClick={onBtExport}>
            EXCEL 다운로드
            <i className="icon_download_attached" />
          </button>
          {/*<button onClick={onClickTestAPI}>*/}
          {/*  API TEST*/}
          {/*  <i className="icon_download_attached" />*/}
          {/*</button>*/}
          <div className="tab_setting_wrapper">
            <button onClick={() => setIsTabClick(!isTabClick)}>Tab 설정</button>
            {isTabClick ? (
              <div className="tab_wrapper">
                {columnDefs.map((item, index) => {
                  return (
                    <label>
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={(e) => handleTab(e, item)}
                      />
                      <span>{item.field}</span>
                    </label>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </header>
      <section>
        <div className="data_wrapper">
          <div className="compare_data">
            <div className="upload_wrapper">
              <div className="select_wrapper">
                <p>연월 변경</p>
                <select value={currentYear} onChange={(e) => onChangeYear(e)}>
                  {Array.from({ length: 26 }, (_, i) => nowYear - 10 + i)
                    .reverse()
                    .map((y) => (
                      <option key={y} value={y}>
                        {y}년
                      </option>
                    ))}
                </select>
                <select value={month2} onChange={(e) => onChangeMonth(e)}>
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                    <option key={m} value={m}>
                      {m}월
                    </option>
                  ))}
                </select>
              </div>
              <div className="btn_wrapper">
                <button onClick={downloadExcel}>
                  IPNOW Data 다운로드
                  <i className="icon_download_attached" />
                </button>
                <button
                  onClick={() =>
                    history.replace(`/admin/annual/${company_idx}`, {
                      year: currentYear,
                      month: month2,
                    })
                  }
                >
                  목록으로 돌아가기
                </button>
              </div>
            </div>
            <div className="compare_data_header">
              <p>
                <span className="color_blue">IPNOW IP Data</span>
                <p>
                  총{" "}
                  <p className="count">
                    <span className="color_blue">
                      {window.$Global.commaify(
                        ipnowData.checkIpCount + sortedRowsLength
                      )}
                    </span>
                    /
                    {window.$Global.commaify(
                      ipnowData.ipCount + sortedRowsLength
                    )}
                    건
                  </p>
                </p>
              </p>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                key={gridKey}
                ref={ipnowGridRef}
                rowData={ipnowData.rows}
                columnDefs={[
                  {
                    headerName: "",
                    field: "checkbox",
                    width: 50,
                    checkboxSelection: (params) => {
                      const rowClass = getRowClass(params);
                      return (
                        rowClass === "show-checkbox" &&
                        params.data.checkbox === true
                      );
                    },
                    headerCheckboxSelection: true, // 수정: 헤더 체크박스 활성화
                    headerCheckboxSelectionFilteredOnly: true, // 수정: 필터링된 행만 선택하도록 설정
                    wrapText: true,
                    autoHeight: true,
                    resizable: true,
                  },
                  ...ipnowData.columns.map((column) => {
                    return {
                      ...column,
                      wrapText: true,
                      autoHeight: true,
                      resizable: true,
                      filter: "agSetColumnFilter",
                    };
                  }),
                  {
                    headerName: "삭제",
                    cellRenderer: (props) =>
                      DeleteButtonRenderer(props, company_idx, onGridReady),
                    width: 90,
                    wrapText: true,
                    autoHeight: true,
                    resizable: true,
                    ...columnCentered,
                  },
                ]}
                onCellValueChanged={(event) => {
                  if (event.colDef.field === "IPNOW_Comment") {
                    let eventdata = event.data;
                    setIpnowDataComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  } else if (event.colDef.field === "User_Comment") {
                    let eventdata = event.data;
                    setIpnowDataUserComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  } else if (event.colDef.field === "Final_Comment") {
                    let eventdata = event.data;
                    setIpnowDataFinalComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  }
                }}
                defaultColDef={defaultColDef}
                suppressDragLeaveHidesColumns={true}
                getRowClass={getRowClass}
                //onGridReady={onGridReady}
                suppressRowClickSelection={true}
                rowSelection="multiple"
                //rowMultiSelectWithClick='true'
                onSelectionChanged={(event) =>
                  handleRowSelectedForIpnowGrid(event)
                }
                isRowSelectable={(node) => {
                  const rowClass = getRowClass(node);
                  const isSelected = selectedRowsForUserGrid.some(
                    (selectedRow) =>
                      selectedRow.출원번호 === node.data.출원번호 &&
                      rowClass === "show-checkbox"
                  );
                  return isSelected;
                }}
                suppressCopyRowsToClipboard={true}
              />
            </div>
          </div>
          {/* 화살표 */}

          <div
            className="arrow"
            style={arrowStyle}
            onClick={handleArrowClick}
          ></div>
          <button
            className="save-button"
            onClick={handleConfirmation}
            style={{
              marginTop: "290px",
              width: "60px", // 버튼의 폭과 높이를 조정해주세요
              height: "40px",
              // 기타 버튼의 스타일 속성들을 필요에 따라 추가해주세요
            }}
          >
            저장하기
          </button>

          {/* 화살표2 */}

          <div
            className="arrow"
            style={rightArrowStyle}
            onClick={handleArrowClickToRight}
          ></div>
          <div className="compare_data">
            <div className="upload_wrapper">
              <div className={`filebox ${selectOption !== "file" && "none"}`}>
                {selectOption === "file" ? (
                  <>
                    <input
                      className="upload-name"
                      value={uploadFileName}
                      placeholder="첨부파일"
                    />
                    <label htmlFor="file">파일찾기</label>
                    <input
                      type="file"
                      id="file"
                      accept=".xlsx, xls"
                      onChange={(e) => handleFileUpload(e, "user")}
                    />
                  </>
                ) : selectOption === "api" ? (
                  <p className="text">
                    * API 데이터를 가져옵니다. 누락된 데이터가 존재할 수
                    있습니다.
                  </p>
                ) : null}
              </div>
              <div className="util_wrapper">
                <select
                  onChange={(e) => {
                    setSelectOption(e.target.value);
                  }}
                >
                  <option value="">선택</option>
                  <option value="file">EXCEL</option>
                  {getShowAPICompnayList() && <option value="api">API</option>}
                </select>
                <button
                  className="export_data"
                  disabled={selectOption === ""}
                  onClick={() => {
                    loadData("user");
                  }}
                >
                  데이터 불러오기
                </button>
                <div className="btn_wrapper">
                  <button
                    onClick={selectOption === "api" ? updateApi : uploadExcel}
                  >
                    Data 업로드
                    <i className="icon_download_attached" />
                  </button>
                </div>
              </div>
              {/*<button onClick={() => loadData("user")}>확인</button>*/}
            </div>

            <div className="compare_data_header">
              <p>
                <span className="color_blue">사용자 IP Data</span>
                <p>
                  총{" "}
                  <p className="count">
                    <span className="color_blue">
                      {window.$Global.commaify(
                        userData.checkIpCount - sortedRowsLength
                      )}
                    </span>
                    /
                    {window.$Global.commaify(
                      userData.ipCount - sortedRowsLength
                    )}
                    건
                  </p>
                </p>
              </p>
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: 400, width: "100%" }}
            >
              <AgGridReact
                key={gridKey}
                ref={userGridRef}
                rowData={userData.rows}
                columnDefs={[
                  {
                    headerName: "",
                    field: "checkbox",
                    width: 50,
                    checkboxSelection: (params) => {
                      const rowClass = getRowClass(params);
                      return rowClass === "show-checkbox" && isChecked;
                    },
                    headerCheckboxSelection: true, // 수정: 헤더 체크박스 활성화
                    headerCheckboxSelectionFilteredOnly: true, // 수정: 필터링된 행만 선택하도록 설정
                    wrapText: true,
                    autoHeight: true,
                    resizable: true,
                  },
                  ...userData.columns.map((column) => {
                    return {
                      ...column,
                      wrapText: true,
                      autoHeight: true,
                      resizable: true,
                      filter: "agSetColumnFilter",
                    };
                  }),
                ]}
                defaultColDef={defaultColDef}
                suppressDragLeaveHidesColumns={true}
                getRowClass={getRowClass}
                onCellValueChanged={(event) => {
                  if (event.colDef.field === "IPNOW_Comment") {
                    let eventdata = event.data;
                    console.log(event.data);
                    setUserDataComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  } else if (event.colDef.field === "User_Comment") {
                    let eventdata = event.data;
                    setUserDataUserComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  } else if (event.colDef.field === "Final_Comment") {
                    let eventdata = event.data;
                    setUserDataFinalComment(eventdata); // 'Comment' 컬럼 값 변경 시 comment 상태 업데이트
                  }
                }}
                // onRowDataUpdated={onRowDataUpdated}
                suppressRowClickSelection={true}
                onGridReady={onGridReady}
                rowMultiSelectWithClick="true"
                rowSelection="multiple"
                onSelectionChanged={(event) =>
                  handleRowSelectedForUserGrid(event)
                }
                isRowSelectable={(node) => {
                  const rowClass = getRowClass(node);
                  return rowClass === "show-checkbox" && isChecked;
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdminAnnualFeeCompare;
