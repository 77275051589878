const BillingInputRow = ({
  type, // input type
  data, // input value data
  readOnly, // readOnly = Boolean
  onChange, // onChange Function
  rowName, // p tag rowName
  widthOption, // widthOption = full or half
  moneySymbol, // moneySymbol = String or Array (option)
  textNumber, // textNumber (option)
  name, // input name data
  rowClassName,
  selectValue, // money symbol select box value
  selectName, // money symbol select box name
}) => {
  return (
    <div className={`billing_row`}>
      <p>
        {textNumber && <span>{textNumber}</span>}
        {rowName}
      </p>
      <div
        className={`billing_input ${widthOption || ""}`}
        style={{ backgroundColor: readOnly && "rgba(216,216,216,0.4)" }}
      >
        {moneySymbol ? (
          moneySymbol.length > 1 ? (
            <select
              className="money_symbol"
              value={selectValue}
              name={selectName}
              onChange={onChange}
            >
              {moneySymbol.map((item) => {
                return <option>{item}</option>;
              })}
            </select>
          ) : (
            <p className="money_symbol_text">{moneySymbol}</p>
          )
        ) : null}
        <input
          type={type}
          value={data}
          name={name}
          // value={type === "number" ? window.$Global.newCommaify(data) : data}
          readOnly={readOnly}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default BillingInputRow;
