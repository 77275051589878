import React from "react";
import ContentLoader from "react-content-loader"

function LoaderPlaceholder(props) {
	return (
		<ContentLoader
			speed={1.5}
			height={200}
			viewBox="0 0 500 170"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			style={{
				padding: 10,
				borderRadius: 5,
				boxShadow: "0 2px 4px 0 var(--color-Gallery)"
			}}
			{...props}
		>
			<rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
			<rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
			<rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
			<rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
			<rect x="0" y="88" rx="3" ry="3" width="250" height="6" />
			<rect x="0" y="106" rx="3" ry="3" width="178" height="6" />
			<circle cx="20" cy="20" r="20" />
		</ContentLoader>
	)
}

export default LoaderPlaceholder;