import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../../common/css/RelativeFileListView.scss';
import RelativeFileTable from '../../common/RelativeFileTable';
import TradeMarkAPI from "../../../API/trademark/index";
import MemberContext from "../../../store/Member";
import RelativeFileList from "../../common/RelativeFileList";

function RelativeFileListView() {
    const member = useContext(MemberContext);
    const {trademark_idx} = useParams();
    const [manageNumber, setManageNumber] = useState(null);
    const [nation, setNation] = useState(-1);
    const [props, setProps] = useState([]);
    const isCompany = window.$Global.checkAuth("특허회사", member.auth);
    const isAgent = window.$Global.checkAuth("대리인", member.auth);

    useEffect(async () => {
        let arr = [];
        let response;

        if (isCompany) {
            response = await TradeMarkAPI.getTradeMarkRelativeFiles(trademark_idx);
        } else if (isAgent) {
            response = await TradeMarkAPI.getTradeMarkRelativeFilesAsAgent(member.manage_company_idx, trademark_idx);
        }
        let data = response.data;
        setManageNumber(data.manage_number);
        setNation(data.nation);

        if (data.apply) {
            for (let i = 0; i < data.apply.length; i++) {
                data.apply[i].download = onClickDownload('apply');
                data.apply[i].t_idx = trademark_idx;
                data.apply[i].manage_company_idx = member.manage_company_idx;
            }
        }
        let apply_prop = {
            title: '출원',
            rows: data.apply || [],
        };
        arr.push(apply_prop);

        if (data.regist) {
            for (let i = 0; i < data.regist.length; i++) {
                data.regist[i].download = onClickDownload('regist');
                data.regist[i].t_idx = trademark_idx;
                data.regist[i].manage_company_idx = member.manage_company_idx;
            }
        }
        let regist_prop = {
            title: '등록',
            rows: data.regist || [],
        };
        arr.push(regist_prop);

        for (let i = 0; i < data.oa.length; i++) {
            let oa = data.oa[i];
            let concat_arr = [];

            if (oa.content.length) {
                for (let j = 0; j < oa.content.length; j++) {
                    oa.content[j].download = onClickDownload('content');
                    oa.content[j].t_idx = trademark_idx;
                    oa.content[j].oa_idx = oa.idx;
                    oa.content[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.content[j]);
                }
            }

            if (oa.apply.length) {
                for (let j = 0; j < oa.apply.length; j++) {
                    oa.apply[j].download = onClickDownload('oa_apply');
                    oa.apply[j].t_idx = trademark_idx;
                    oa.apply[j].oa_idx = oa.idx;
                    oa.apply[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.apply[j]);
                }
            }

            if (oa.opinion.length) {
                for (let j = 0; j < oa.opinion.length; j++) {
                    oa.opinion[j].download = onClickDownload('opinion');
                    oa.opinion[j].t_idx = trademark_idx;
                    oa.opinion[j].oa_idx = oa.idx;
                    oa.opinion[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.opinion[j]);
                }
            }

            if (concat_arr.length) {
                let oa_prop = {
                    title: 'OA' + Number(i + 1),
                    rows: concat_arr,
                };
                arr.push(oa_prop);
            }
        }

        setProps(arr);
    }, [member]);

    const onClickDownload = (type) => {
        switch (type) {
            case "apply":
                return isCompany ? TradeMarkAPI.downloadCompanyApplyFile : TradeMarkAPI.downloadAgentApplyFile;
            case "regist":
                return isCompany ? TradeMarkAPI.downloadCompanyRegistFile : TradeMarkAPI.downloadAgentyRegistFile;
            case "content":
                return isCompany ? TradeMarkAPI.downloadCompanyOaContentFile : TradeMarkAPI.downloadAgentOaContentFile;
            case "oa_apply":
                return isCompany ? TradeMarkAPI.downloadCompanyOaApplyFile : TradeMarkAPI.downloadAgentOaApplynFile;
            case "opinion":
                return isCompany ? TradeMarkAPI.downloadCompanyOaOpinionFile : TradeMarkAPI.downloadAgentOaOpinionFile;
        }
    };

    let tag =
        props.map((item, index) => {
            return <RelativeFileTable key={index} props={item}></RelativeFileTable>
        });

    return (
        <RelativeFileList title="관리번호" manage_number={manageNumber} nation={window.$Global.convertNationCodeToStr(nation)} tag={tag}/>
    );
}

export default React.memo(RelativeFileListView);
