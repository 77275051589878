import React, { useEffect, useRef, useState } from "react";
import Admin from "../../../../API/admin";
import Modal from "../../../common/Modal";
import SelectBox from "../../../common/selectBox/SelectBox";
import Styles from "../../css/AdminCheckPointTable.module.scss";
import transformAdminCheckpoints from "../../utils/transformAdminCheckpoints";
import CheckpointForm from "./checkpointForm/CheckpointForm";
import Feedback from "./feedbackModal/Feedback";

const TABLE_HEADERS = ["카테고리", "항목", "질문"];

const AdminCheckPointTable = () => {
  const [categoriesState, setCategoriesState] = useState({
    state: "LOADING",
    categories: [],
  });
  const [categoriesStateForModal, setCategoriesStateForModal] = useState({
    state: "LOADING",
    categories: [],
  });
  const [checkpointsState, setCheckpointsState] = useState({
    state: "LOADING",
    checkpoints: [],
  });
  const [selectedCategory, setselectedCategory] = useState({ name: "전체" });
  const [toggleRefetch, setToggleRefetch] = useState(false);

  useEffect(() => {
    // Fetch categories
    Admin.adminCheckPointCategoriesList({ flag: 1 })
      .then((result) => {
        const { categoryList } = result.data.data;
        const categoryListWithAll = [{ name: "전체" }, ...categoryList]
        setCategoriesStateForModal({
          state: "SUCCESS",
          categories: categoryList,
          error: null,
        });
        setCategoriesState({
          state: "SUCCESS",
          categories: categoryListWithAll,
          error: null,
        });
      })
      .catch((err) => {
        setCategoriesStateForModal({
          state: "ERROR",
          categories: [],
          error: err?.response.data?.message ?? err.message,
        });
        setCategoriesState({
          state: "ERROR",
          categories: [],
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, []);

  useEffect(() => {
    // Fetch checkpoints
    Admin.adminCheckPointList({ flag: 1 })
      .then((result) => {
        const { checkpointList } = result.data.data;
        setCheckpointsState({
          state: "SUCCESS",
          checkpoints: transformAdminCheckpoints(checkpointList),
          error: null,
        });
      })
      .catch((err) => {
        console.log(err);
        setCheckpointsState({
          state: "ERROR",
          checkpoints: [],
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, [toggleRefetch]);

  // Modal Logic
  const modalRef = useRef();
  const [activeModal, setActiveModal] = useState("");
  const [checkpointToWorkOn, setcheckpointToWorkOn] = useState(null);

  const openModalHandler = (modalType, selectedToWorkOn = {}) => {
    setActiveModal(modalType);
    setcheckpointToWorkOn(selectedToWorkOn);
    modalRef.current.open();
  };
  const closeModalHandler = () => {
    setActiveModal(null);
    modalRef.current.close();
  };

  const submitHandler = (responcePromise) => {
    modalRef.current.close();
    responcePromise
      .then((result) => {
        setActiveModal({ type: "SUCCESS", message: result.data.message });
      })
      .catch((error) => {
        if (error.response) setActiveModal({ type: "ERROR", message: error.response.data.message });
        else setActiveModal({ type: "ERROR", message: error?.message ?? "" });
      })
      .finally(() => {
        modalRef.current.open();
        setToggleRefetch(!toggleRefetch);
      });
  };

  const confirmDeleteHandler = (checkpoint) => {
    openModalHandler(
      { type: "CONFIRM_DELETE" },
      {
        idx: checkpoint.idx,
      }
    );
  };
  const deleteHandler = () => submitHandler(Admin.deleteCheckPointList({ idx: checkpointToWorkOn.idx }));

  const editHandler = (checkpoint) => {
    const category = categoriesState.categories.find(({ name }) => name === checkpoint["카테고리"]);
    openModalHandler(
      { type: "FORM", action: 'Edit' },
      {
        idx: checkpoint.idx,
        category,
        label: category.children?.find(({ name }) => name === checkpoint["항목"]),
        question: checkpoint["질문"],
      }
    );
  };

  const filterdCheckpoints =
    checkpointsState.state === "SUCCESS"
      ? selectedCategory.name !== "전체"
        ? checkpointsState.checkpoints.filter(({ 카테고리 }) => 카테고리 === selectedCategory.name)
        : checkpointsState.checkpoints
      : [];

  return (
    <>
      <div className={Styles["container"]}>
        <h1>체크포인트(상세보기)</h1>
        <div>
          <div>
            <h5>카테고리 지정 : </h5>
            <SelectBox
              className={Styles["select-div"]}
              withArrowIndicator={true}
              selectedOption={selectedCategory}
              name="category"
              isDisabled={categoriesState.state === "LOADING"}
              options={categoriesState.categories ?? []}
              onChange={(name, option) => setselectedCategory(option)}
            />
          </div>
          <button type="button" onClick={() => openModalHandler({ type: "FORM", action: 'Add' })}>
            추가하기
          </button>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {TABLE_HEADERS.map((headerName) => (
                  <th key={headerName}>{headerName}</th>
                ))}
                <th>관리</th>
              </tr>
            </thead>
            <tbody>
              {filterdCheckpoints.map((checkpoint) => (
                <tr key={checkpoint.idx}>
                  {TABLE_HEADERS.map((headerName) => (
                    <td kay={`${checkpoint.idx}-${headerName}`}>{checkpoint[headerName]}</td>
                  ))}
                  <td className={Styles["actions-cell"]}>
                    <div>
                      <button type="button" onClick={() => editHandler(checkpoint)}>
                        수정
                      </button>
                      <button type="button" onClick={() => confirmDeleteHandler(checkpoint)}>
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal ref={modalRef} onClose={closeModalHandler}>
        {activeModal?.type === "FORM" ? (
          <CheckpointForm
            checkpoint={checkpointToWorkOn ?? {}}
            categories={categoriesStateForModal.categories ?? []}
            title={activeModal.action === 'Add' ? '체크포인트 추가하기' : '체크포인트 수정하기'}
            actionType={activeModal.action}
            onSubmit={submitHandler}
            onClose={closeModalHandler}
          />
        ) : activeModal?.type === "CONFIRM_DELETE" ? (
          <Feedback type="CONFIRM_DELETE" onConfirm={deleteHandler} onCancel={closeModalHandler} />
        ) : activeModal?.type === "SUCCESS" ? (
          <Feedback type="SUCCESS" onConfirm={closeModalHandler} message={activeModal.message} />
        ) : activeModal?.type === "ERROR" ? (
          <Feedback type="ERROR" onCancel={closeModalHandler} message={activeModal.message} />
        ) : null}
      </Modal>
    </>
  );
};

export default AdminCheckPointTable;
