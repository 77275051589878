import React, { useState, useEffect, useRef } from "react";
import SharedStyles from "../../css/sales/shared.module.scss";
import { DEPOSITES_HEADER_FIELDS, PACKAGES_HEADER_FIELDS } from "../../constants/TablesFields";
import DepositeHeader from "./deposites/DepositeHeader";
import PackageHeader from "./packages/PackageHeader";
import DepositeForm from "./deposites/DepositeForm";
import SALES_API from "../../../../API/annualPay/sales";
import ConfirmModal from "../ui/confirm/ConfirmModal";
import Table from "../ui/table/Table";
import filterRecords from "../../util/sales/filterRecords";
import PackageForm from "./packages/PackageForm";

const DepositesAndPackages = ({ onCloseModal, modalType }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [recordsState, setRecordsState] = useState({ state: "IDLE", records: [] });

  //* Api Logic
  const [universitiesState, setUniversitiesState] = useState({
    state: "LOADING",
    universities: null,
    error: null,
  });

  const [filterValues, setFilterValues] = useState({
    university: { name: "기관 선택", trueValue: false },
    year: { name: "년", trueValue: false },
    month: { name: "월", trueValue: false },
  });
  const fetchedCompanyAndYear = useRef({ company_idx: "", year: 0 });

  useEffect(() => {
    SALES_API.getUniversities()
      .then((result) => {
        setUniversitiesState({ state: "SUCCESS", universities: result.data.data.userList, error: null });
      })
      .catch((err) => setUniversitiesState({ state: "ERROR", universities: null, error: err?.response.data?.message ?? err.message }));
  }, []);

  useEffect(() => {
    if (!filterValues.year.trueValue) return;
    const {
      university: { company_idx },
      year: { name: yearName },
    } = filterValues;
    const yearNumber = +yearName.replace("년", "");
    if (yearNumber === fetchedCompanyAndYear.current.year && company_idx === fetchedCompanyAndYear.current.company_idx)
      return;

    setRecordsState({ state: "LOADING", records: [] });
    fetchedCompanyAndYear.current = { company_idx, year: yearNumber };

    const recordsApi = modalType === "DEPOSITE" ? SALES_API.getDeposites : SALES_API.getPackages;
    recordsApi(company_idx, { year: yearNumber })
      .then((result) => {
        const { sum, depositList } = result.data.data;
        setRecordsState({
          sum,
          records: depositList,
          state: "SUCCESS",
        });
      })
      .catch((err) => {
        console.log(err);
        setRecordsState({
          state: "ERROR",
          message: err?.message ?? "오류가 발생했습니다. 다시 시도해 주세요.",
          records: [],
        });
      });
  }, [filterValues, modalType]);

  //* The record that will be Edited OR Deleted
  const [recordToWorkOn, setRecordToWorkOn] = useState(null);

  const updateFilterHandler = (name, option) =>
    setFilterValues((prevState) => ({ ...prevState, [name]: { ...option, trueValue: true } }));

  //* You will get a report after the request is made
  const submitHandler = (report) => {
    if (report.status === "SUCCESS") {
      setActiveModal({ type: "SUCCESS", message: report.message });
    } else if (report.status === "ERROR") {
      setActiveModal({ type: "ERROR", message: report.message });
    }

    setFilterValues({ ...filterValues });
    fetchedCompanyAndYear.current.year = 0;
  };

  const deleteRecordHandler = async () => {
    const deleteApi = modalType === "DEPOSITE" ? SALES_API.deleteDeposit : SALES_API.deletePackage;
    const result = await deleteApi({
      idx: recordToWorkOn,
    });
    submitHandler({ status: "SUCCESS", type: "DELETE", message: result.data.message });
  };

  const recordOperationHandler = (type, recordId) => {
    switch (type) {
      case "CONFIRM_DELETE":
        setActiveModal({ type: "CONFIRM_DELETE" });
        setRecordToWorkOn(recordId);
        break;
      case "NEW":
        setActiveModal({ type: "FORM" });
        setRecordToWorkOn(null);
        break;
      case "EDIT":
        setActiveModal({ type: "FORM" });
        setRecordToWorkOn(filterdRecords.find(({ idx }) => idx === recordId));
        break;
      default:
        break;
    }
  };

  // Computed Values
  const selectedMonth = filterValues.month.trueValue ? +filterValues.month.name.replace("월", "") : "";
  const filterdRecords = filterRecords({ records: recordsState.records, selectedMonth, type: modalType });

  const totalCost = modalType === "PACKAGE" ? filterdRecords.reduce((sum, { fee }) => (sum += fee), 0) : "";

  return (
    <div className={`${SharedStyles["main-modal"]}`}>
      {universitiesState.state === "ERROR" ? (
        <ConfirmModal type="ERROR" onCancel={onCloseModal} />
      ) : (
        <>
          <button className={SharedStyles["close-btn"]} onClick={onCloseModal} type="button">
            <i className="icon_exit" />
          </button>
          {modalType === "DEPOSITE" ? (
            <DepositeHeader
              depositesState={recordsState}
              universitiesState={universitiesState}
              updateFilterHandler={updateFilterHandler}
              filterValues={filterValues}
              onAdd={() => recordOperationHandler("NEW")}
            />
          ) : (
            <PackageHeader
              universitiesState={universitiesState}
              updateFilterHandler={updateFilterHandler}
              filterValues={filterValues}
              totalCost={totalCost}
              onAdd={() => recordOperationHandler("NEW")}
            />
          )}
          <div>
            {universitiesState.state === "LOADING" || recordsState.state === "LOADING" ? (
              <div className={SharedStyles["loading-div"]}>
                <span className="spinner" />
              </div>
            ) : recordsState.state === "IDLE" ? (
              <div className={SharedStyles["loading-div"]}>데이터 없습니다</div>
            ) : recordsState.state === "ERROR" ? (
              <div style={{ color: "red", fontSize: "1.4rem" }} className={SharedStyles["loading-div"]}>
                {recordsState.message}
              </div>
            ) : (
              <Table
                records={filterdRecords?.map((record, index) =>
                  modalType === "DEPOSITE"
                    ? {
                      ...record,
                      no: ++index,
                    }
                    : {
                      ...record,
                      no: ++index,
                      university: filterValues.university.name,
                      packageDate: `${record.year}년 ${record.month}월`,
                    }
                )}
                onDelete={(id) => recordOperationHandler("CONFIRM_DELETE", id)}
                onEdit={(id) => recordOperationHandler("EDIT", id)}
                headersFields={modalType === "DEPOSITE" ? DEPOSITES_HEADER_FIELDS : PACKAGES_HEADER_FIELDS}
              />
            )}
          </div>
          {activeModal?.type === "FORM" ? (
            <>
              {modalType === "DEPOSITE" ? (
                <DepositeForm
                  initialValues={
                    recordToWorkOn
                      ? {
                        ...recordToWorkOn,
                        occurrenceDate: new Date(recordToWorkOn.occurrence_date).getTime(),
                        collectionDate: new Date(recordToWorkOn.collection_date).getTime(),
                        depositAmount: recordToWorkOn.deposit_amount,
                      }
                      : {}
                  }
                  university={filterValues.university}
                  onSubmit={submitHandler}
                  onClose={() => setActiveModal(null)}
                />
              ) : (
                <PackageForm
                  initialValues={
                    recordToWorkOn
                      ? {
                        ...recordToWorkOn,
                        packageDate: new Date(`${recordToWorkOn.year}-${recordToWorkOn.month}`).getTime(),
                      }
                      : {}
                  }
                  university={filterValues.university}
                  onSubmit={submitHandler}
                  onClose={() => setActiveModal(null)}
                />
              )}
            </>
          ) : activeModal?.type === "CONFIRM_DELETE" ? (
            <ConfirmModal type="CONFIRM_DELETE" onConfirm={deleteRecordHandler} onCancel={() => setActiveModal(null)} />
          ) : activeModal?.type === "SUCCESS" ? (
            <ConfirmModal type="SUCCESS" onConfirm={() => setActiveModal(null)} message={activeModal.message} />
          ) : activeModal?.type === "ERROR" ? (
            <ConfirmModal type="ERROR" onCancel={() => setActiveModal(null)} message={activeModal.message} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default DepositesAndPackages;
