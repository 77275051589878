import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import "./css/MTradeMarkDetail.scss";
import {HorizontalBar} from "react-chartjs-2";
import AnnualPayAPI from "../../../API/annualPay";
import TradeMarkAPI from "../../../API/trademark";
import OneInRow from "./OneInRow";
import TwoInRow from "./TwoInRow";

function MTradeMarkDetail({history}) {
    const {trademark_idx} = useParams();
    const [tabType, setTabType] = useState(1);
    const [responseData, setResponseData] = useState([]);
    const [TradeMarkInfo, setTradeMarkInfo] = useState({
            members: [
                {
                    name: '',
                    share: 0
                }
            ]
    });

    const year = new Date().getFullYear();
    const backgroundColor = responseData.map(() => '#ffa600');
    const max = window.$Global.calcGraphMaxNumber(responseData.map(item => item.td_cost));
    const line_chart_width = Math.floor(window.$Global.getBrowserSize().width / 1.1);

    useEffect(async () => {
        let payload = {
            start_year: year,
            end_year: year + 13
        };
        let result = await AnnualPayAPI.getTradeMarkAnnualPay(trademark_idx, payload).then(res => res.data);

        setResponseData(result);
        TradeMarkAPI.getTradeMarkInfo(trademark_idx).then(res => setTradeMarkInfo(res.data));
    }, []);

    const m_data = {
        labels: responseData.map(item => item.year),
        datasets: [{
            data: responseData.map(item => item.td_cost),
            labels: [
                '등록',
                '출원',
                '포기'
            ],
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor,
            borderWidth: 0,
        }]
    };

    const m_annualFee_options = {
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        },
        responsive: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            xAxes: [{
                ticks: {
                    display: false,
                    beginAtZero: true,
                    min: 0,
                    max : max,
                    stepSize: max / 5,
                },
                gridLines: {
                    display : true,
                    borderDash: [4],
                    color: "#d8d8d8",
                    zeroLineColor: '#aaaaaa',
                    zeroLineWidth: 2,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: true
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor : '#959595',
                    fontSize: 10
                },

                gridLines: {
                    display : false,
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'right',
                color: '#959595',
                font: {
                    weight: 'bold',
                },
                formatter: function(value) {
                    return window.$Global.commaify(value);
                }
            }
        },
    };

    return (
        <div id="MTradeMarkDetail" className={tabType == 1 ? 'bg_tab1' : 'bg_tab2'}>
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2 className="page_title">상표 출원 상세</h2>
            </div>
            <ul className="tabs">
                <li className={`${tabType == 1 ? 'active' : ''}`} onClick={() => setTabType(1)}>
                    <p>상세정보</p>
                </li>
                <li className={`${tabType == 2 ? 'active' : ''}`} onClick={() => setTabType(2)}>
                    <p>연차료 관리</p>
                </li>
            </ul>
            {
                tabType == 1 &&
                <div className="wrap_info">
                    <div className="list">
                        <div className="item">
                            <OneInRow title="관리번호" value={TradeMarkInfo.manage_number} className="row_title"/>
                            <div className="br"/>
                            <TwoInRow title1="담당자" value1={TradeMarkInfo.manager_name} title2="대리인" value2={TradeMarkInfo.agent_company_name}/>
                            <OneInRow title="선행 상표조사" value={TradeMarkInfo.survey_req ? '유' : '무'} className="row_center"/>
                            <OneInRow title="상표 명칭" value={TradeMarkInfo.name} className="row_center"/>
                        </div>
                        <div className="item">
                            <OneInRow title="출원번호" value={TradeMarkInfo.apply_number} className="row_title"/>
                            <div className="br"/>
                            <OneInRow title="출원일" value={window.$Global.convertDate(TradeMarkInfo.apply_at)} className="row_center"/>
                            {
                                TradeMarkInfo.product_list?.map((item, idx) => {
                                    return <OneInRow key={idx} title={idx == 0 && `관련 제품명`} value={item.name} className="row_center"/>
                                })
                            }
                            {
                                TradeMarkInfo.product_class?.map((item, idx) => {
                                    return (
                                        <React.Fragment key={idx}>
                                            <OneInRow title={idx == 0 && `상품분류/지정상품`} value={item.class + '류' + '/' + (item.name || '미지정')} className="row_center"/>
                                        </React.Fragment>
                                    );
                                })
                            }
                            <OneInRow title="상품분류/지정상품" value={TradeMarkInfo.manage_number} className="row_center"/>
                            <OneInRow title="우선심사청구" value={TradeMarkInfo.priority_screen_req ? '유' : '무'} className="row_center"/>
                        </div>
                        {
                            TradeMarkInfo.oa?.map((item, idx) => {
                                return <div key={idx} className="item">
                                            <OneInRow title="OA 발행일" value={window.$Global.convertDate(item.published_at)} className="row_title row_center"/>
                                            <OneInRow title="OA 의견서 접수" value={item.opinion_upload_at} className="row_center"/>
                                            <OneInRow title="OA 제출일" value={window.$Global.convertDate(item.submission_at)} className="row_center"/>
                                            <OneInRow title="OA 연장 횟수" value={item.extension_cnt} className="row_center"/>
                                            <OneInRow title="OA 기한" value={window.$Global.convertDate(item.deadline)} className="row_center"/>
                                            <div className="br"/>
                                            <OneInRow title="출원공고 결정일" value={window.$Global.convertDate(item.apply_decision_at)} className="row_center"/>
                                            <OneInRow title="출원 공고일" value={window.$Global.convertDate(item.apply_at)} className="row_center"/>
                                            <OneInRow title="이의신청 기간" value={window.$Global.convertDate(item.objection_deadline)} className="row_center"/>
                                            <OneInRow title="이의신청 유무" value={item.objection_req ? '유' : '무'} className="row_center"/>
                                        </div>
                            })
                        }
                        <div className="item">
                            <OneInRow title="등록 결정일" value={window.$Global.convertDate(TradeMarkInfo.regist_decision_at)} className="row_title row_center"/>
                            <OneInRow title="등록료 납부 기한" value={window.$Global.convertDate(TradeMarkInfo.regist_payment_deadline)} className="row_title row_center"/>
                            <OneInRow title="등록 납부일/등록일" value={window.$Global.convertDate(TradeMarkInfo.regist_at)} className="row_center"/>
                            <OneInRow title="등록번호" value={TradeMarkInfo.regist_number} className="row_center"/>
                            <OneInRow title={'1차 갱신기간 만료일'} value={window.$Global.convertDate(TradeMarkInfo.first_renewal_expiration_deadline)} className="row_center"/>
                            <OneInRow title={'분할 납부 여부\n2차 납부 (예정)일'} value={window.$Global.convertDate(TradeMarkInfo.second_payment_scheduled_at)} className="row_center"/>
                            <OneInRow title="갱신신청 유무" value={TradeMarkInfo.renewal_req ? '유' : '무'} className="row_center"/>
                        </div>
                    </div>
                </div>
            }
            {
                tabType == 2 &&
                <div className="wrap_annualFee">
                    <HorizontalBar data={m_data} width={line_chart_width} height={480} options={m_annualFee_options}/>
                </div>
            }
        </div>
    );
}

export default MTradeMarkDetail;
