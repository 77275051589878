import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import InvestmentProcess from "../components/patentIntro/InvestmentProcess";
import InvestmentRequestList from "../components/admin/InvestmentRequestList";
import InvestmentRequestDetail from "../components/patentIntro/InvestmentRequestDetail";
import CustomSwitch from "./common/CustomSwitch";

function PatentIntroRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/process`} requireAuth={true} component={InvestmentProcess}/>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={InvestmentRequestList}/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={true} component={InvestmentRequestDetail}/>
		</CustomSwitch>
	)
}

export default PatentIntroRoute;