import React from 'react';

function InputAndFileRow({title, titleClassName, editing, content, haveFile, onChange, onClickDownPopShow, onClickUpPopShow}) {
    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                {
                    editing
                        ? <input type="text" value={content || undefined} onChange={onChange} />
                        : <p>{content ? content : 'N/A'}</p>
                }
                <button className={haveFile ? 'icon_download_attached' : 'icon_download'} onClick={onClickDownPopShow}/>
                <button className="icon_upload" onClick={onClickUpPopShow}/>
            </div>
        </div>
    );
}

export default InputAndFileRow;
