import React, {useEffect, useState} from "react";
import "./css/CyberPointChange.scss";
import WithdrawalInfo from "./WithdrawalInfo";
import CommonAPI from "../../API/common";
import CyberAPI from "../../API/cyber";

function CyberPointChange({history}) {
    const [myPoint, setMyPoint] = useState(0);
    const [bankInfo, setBankInfo] = useState({
        bank_account_number: null,
        bank_name: null
    });

    useEffect(() => {
        CyberAPI.getMyPoint().then(res => setMyPoint(res.data.amount))
        CyberAPI.getBankInfo().then(res => setBankInfo(res.data));
    }, []);

    const onClickChange = () => {
        if (myPoint - 500 < 5500) {
            alert("보유 포인트가 5500P미만 때문에 출금할 수 없습니다");
            return;
        }
        if (window.confirm(`${myPoint - 500}을 출금하시겠습니까?`)) {
            let payload = {
                amount: myPoint - 500,
                account_bank: bankInfo.bank_name,
                account_name: "123", // 계좌주인명의(string)
                account_number: bankInfo.bank_account_number
            };

            CommonAPI.withdraw(payload).then(res => {
                history.push('/cyber/pointChangeApply');
            });
        }
    };

    return (
        <div id="CyberPointChange">
            <h2 className="page_title">포인트 전환하기</h2>
            <p className="desc">수익금이 5,500원 이상일 때 출금가능합니다.</p>
            <p className="sum_point">보유 {window.$Global.commaify(myPoint)}P</p>

            <WithdrawalInfo/>

            <button className="btn btn_change" onClick={onClickChange}>포인트 전환하기</button>
        </div>
    )
}

export default CyberPointChange;