import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransactionWithdrawalList.scss";

function DocTradeMyTransactionWithdrawalList({data}) {
    // state 0: 대기중  1: 입금완료  2: 계좌오류  3: 예금주불일치
    let items = data.items;

    const getState = (n) => {
        let result = {
            className: "",
            name: ""
        };
        switch (n) {
            case 0:
                result.className = "style_blue";
                result.name = "대기중";
                break;
            case 1:
                result.className = "style_blue";
                result.name = "입금완료";
                break;
            case 2:
                result.className = "style_red";
                result.name = "계좌오류";
                break;
            case 3:
                result.className = "style_red";
                result.name = "예금주불일치";
                break;
        }

        return result;
    }

    return (
        <>
            {
                items.length
                ?
                    <>
                        {
                            items.map((item, idx) => {
                                let result = getState(item.state);
                                return (
                                    <tr key={idx} className="table_content_row docTradeMyTransactionWithdrawalList">
                                        <td className="table_content_number">{data.total_count - idx}</td>
                                        <td className="table_content_bank">{item.account_bank}</td>
                                        <td className="table_content_account-number">{item.account_number}</td>
                                        <td className="table_content_application-fee">{window.$Global.commaify(item.amount)}원</td>
                                        <td className="table_content_withdrawal-date">{window.$Global.convertDate(item.created_at)}</td>
                                        <td className="table_content_deposit-date">{window.$Global.convertDate(item.complete_at)}</td>
                                        <td className={`table_content_status ${result.className}`}>{result.name}</td>
                                    </tr>
                                )
                            })

                        }
                    </>
                : window.$Global.notTd(7, "조회된 출금 내역이 없습니다.")

            }
        </>
    )
}

export default DocTradeMyTransactionWithdrawalList;