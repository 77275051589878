const techLevels = {
  0: "N",
  1: "C",
  2: "CC",
  3: "CCC",
  4: "B",
  5: "BB",
  6: "BBB",
  7: "A",
  8: "AA",
  9: "AAA",
};

const levels = ["AAA", "AA", "A", "BBB", "BB", "B", "CCC", "CC", "C", "N"];

export default { techLevels, levels };
