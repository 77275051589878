import { useRef, useEffect } from 'react'

const useClickOutside = (closeHandler) => {
    const domNodeRef = useRef();

    useEffect(() => {
        const blurHandler = (event) => {
            if (!domNodeRef.current?.contains(event.target)) closeHandler(false);
        };
        document.addEventListener("mousedown", blurHandler);

        return () => document.removeEventListener("mousedown", blurHandler);
    }, [closeHandler]);

    return domNodeRef;
};

export default useClickOutside;