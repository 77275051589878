import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function MiniLoading({ show }) {
  return (
    <>
      {show && (
        <div id="MiniLoading">
          <CircularProgress style={{ color: "#4e7ce9" }} />
        </div>
      )}
    </>
  );
}

export default React.memo(MiniLoading);
