import React, {useEffect, useRef, useState} from "react";
import "./css/MarketInfoPopupCart.scss";
import CommonAPI from "../../API/common";
import MarketInfoCartItem from "./MarketInfoCartItem";
import config from "../../const/config";

function MarketInfoPopupCart({show, onClickPopupShow, onSuccess, onClose}) {
	const [cart, setCart] = useState([]);
	const [checkList, setCheckList] = useState(new Array(cart.length).fill(false));
	const [selectItem, setSelectItem] = useState({});
	const fileRef = useRef();

	useEffect(() => {
		getCartList();
	}, []);

	useEffect(() => {
		setCheckList(new Array(cart.length).fill(false));
		setSelectItem({});
	}, [show]);

	const getCartList = () => {
		CommonAPI.getMarketCartList().then(res => {
			let data = res.data;
			CommonAPI.getMarketImageList().then(res2 => {
				let copy = data.concat(res2.data);
				setCart(copy);
			});
		});
	};

	const onClickSelect = (e, obj, index) => {
		let check = new Array(cart.length).fill(false);
		check[index] = e.target.checked;
		if (e.target.checked) {
			setSelectItem(obj);
		} else {
			setSelectItem({});
		}
		setCheckList(check);
	};

	const onClickSuccess = () => {
		if (window.$Global.isEmptyObject(selectItem)) {
			alert("선택된 이미지가 없습니다");
			return;
		}
		onSuccess(selectItem)
	};

	const onChangeFile = (files) => {
		if (!config.imgTypeCheck.test(files.name)) {
			alert("이미지 파일만 업로드할 수 있습니다");
			return;
		}

		let formData = new FormData();
		formData.append("image", files);
		CommonAPI.addMarketImage(formData).then(() => {
			getCartList();
		});
	}

	const onClickImageDelete = (idx) => {
		CommonAPI.deleteMarketImage(idx).then(() => getCartList());
	}

	if (!show) return null;

	return (
		<div id="MarketInfoPopupCart">
			<div className="header">
				<h2>시장 정보 장바구니</h2>
				<button className="icon_exit ir_txt" onClick={onClose}>닫기</button>
			</div>
			<div className="body">
				<div className="body_head">
					<h2>내가 담은 그래프</h2>
					<div className="upload">
						<p>
							<i className="icon_alert_circle"/>
							<span>한개만 선택하실 수 있습니다</span>
						</p>
						<button className="btn_upload" onClick={() => fileRef.current.click()}>내 파일 업로드하기</button>
						<input type="file" ref={fileRef} accept="image/*" hidden onChange={e => onChangeFile(e.target.files[0])}/>
					</div>
				</div>
				<ul className="list custom_scroll">
					{
						cart.map((item, idx) => <MarketInfoCartItem index={idx} key={item.idx} item={item} checked={checkList[idx]}showPopup={() => onClickPopupShow(item)}
																			onClickImageDelete={onClickImageDelete} onClickCheck={onClickSelect}/>)
					}
				</ul>
			</div>
			<button className="btn_select_success" onClick={onClickSuccess}>선택 완료</button>
		</div>
	)
}

export default MarketInfoPopupCart;