import BillingInputRow from "./BillingInputRow";
import React from "react";
import DatePicker from "react-datepicker";
import "./css/BillingInfo.scss";
import AdminAPI from "../../../../API/admin";

const BillingInfo = ({ data, setData, handleChange, annualFeeData }) => {
  const deleteFile = (idx, index) => {
    if (window.confirm("해당 파일을 삭제하시겠습니까?")) {
      if (idx === undefined) {
        let arr = [...data.file].filter(
          (item, itemIndex) => itemIndex !== index
        );
        setData({ ...data, file: arr });
      } else {
        AdminAPI.deleteAdminPatentReceiptFile(
          annualFeeData.patent_idx,
          annualFeeData.annual_pay_idx,
          idx
        ).then(() => {
          let arr = [...data.file].filter((item) => item.idx !== idx);
          setData({ ...data, file: arr });
        });
      }
    }
  };

  return (
    <div id="BillingInfo">
      {data.billing_number && (
        <BillingInputRow
          type="text"
          data={data.billing_number}
          readOnly={true}
          onChange={handleChange}
          rowName="청구번호"
          widthOption="full"
        />
      )}
      <BillingInputRow
        type="text"
        data={annualFeeData.nation}
        readOnly={true}
        onChange={handleChange}
        rowName="국가"
        widthOption="half"
      />
      <div className="billing_row">
        <p>구분</p>
        <select name="type" onChange={handleChange} value={data.type}>
          <option value={0}>연차료</option>
        </select>
      </div>
      <div className="billing_row">
        <p>청구일자</p>
        <DatePicker
          selected={data.billing_date}
          onChange={(date) => {
            setData({ ...data, billing_date: date });
          }}
          dateFormat="yyyy-MM-dd"
          isClearable
        />
      </div>
      <div className="billing_row">
        <p>송금일자</p>
        <DatePicker
          selected={data.remittance_date}
          onChange={(date) => {
            setData({ ...data, remittance_date: date });
          }}
          dateFormat="yyyy-MM-dd"
          isClearable
        />
      </div>
      {annualFeeData.nation !== "KR" && (
        <>
          <BillingInputRow
            type="number"
            data={data.remittance_exchange_rate}
            name="remittance_exchange_rate"
            readOnly={false}
            onChange={handleChange}
            rowName="송금환율"
            widthOption="full"
          />
          <BillingInputRow
            type="number"
            data={data.ipnow_exchange_rate}
            name="ipnow_exchange_rate"
            readOnly={false}
            onChange={handleChange}
            rowName="IPNOW 적용 환율"
            widthOption="full"
          />
        </>
      )}
      <BillingInputRow
        type="text"
        data={data.receiver}
        readOnly={true}
        onChange={handleChange}
        rowName="수신자"
        widthOption="full"
      />
      <BillingInputRow
        type="text"
        data={data.bank_name}
        name="bank_name"
        readOnly={false}
        onChange={handleChange}
        rowName="은행명"
        widthOption="full"
      />
      <BillingInputRow
        type="text"
        data={data.account_number}
        name="account_number"
        readOnly={false}
        onChange={handleChange}
        rowName="계좌번호"
        widthOption="full"
      />
      <BillingInputRow
        type="text"
        data={data.account_name}
        name="account_name"
        readOnly={false}
        onChange={handleChange}
        rowName="예금주"
        widthOption="full"
      />
      <div className="billing_row">
        <p>내역</p>
        <textarea value={data.history} onChange={handleChange} name="history" />
      </div>
      <div className="billing_row">
        <p>첨부파일</p>
        <div className="file_list_items">
          {Array.from(data?.file).map((el, index) => {
            return (
              <div className="file_list_item">
                <p>{el.name || el.file_name + "." + el.file_type}</p>
                <i
                  className="icon_badge_del"
                  onClick={() => deleteFile(el.idx, index)}
                />
              </div>
            );
          })}
          <div className="file_input">
            <label htmlFor="file">첨부파일 등록하기</label>
            <input
              type="file"
              name="file"
              id="file"
              multiple
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingInfo;
