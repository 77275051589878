/** @jsxImportSource @emotion/react */

import React, {useEffect, useState} from "react";
import * as styles from "./download.style";
import ResearchDownloadAPI from "../../../API/test/research/research";

function TestResearchDownload() {
    const [keyword, setKeyword] = useState('');

    const download = async () => {
        await ResearchDownloadAPI.asyncDownload(
            'common/research/download',
            {keyword}
        );
    }
    
    const downloadCompany = async () => {
        await ResearchDownloadAPI.asyncDownload(
            'common/company/patent/downloadSummary',
            {companyIdx: keyword}
        );
    };

    return (
        <div css={styles.container}>
            <div>
                키워드
                <input type={"text"} onChange={(e) => setKeyword(e.target.value)}/>
            </div>
            <div>
                <button onClick={download}>
                    다운하기
                </button>
                <br/>
                <button onClick={downloadCompany}>
                    회사다운하기
                </button>
            </div>
        </div>
    )
}

export default TestResearchDownload;
