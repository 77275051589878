import API from '../../util/api';

export default {
    getList(payload) {
        return API.get('/common/tutorial', {params: payload});
    },
    getInfo(tutorial_idx) {
        return API.get(`/common/tutorial/${tutorial_idx}`);
    },
    download(tutorial_idx, file_idx) {
        window.$Global.download(`/admin/tutorial/${tutorial_idx}/file/${file_idx}/download`);
    }
}
