import React, { useEffect,  useState } from "react";
import "./css/DocTradeTransaction.scss";
import TemplateAPI from "../../API/template";
import SlickSlider from '../common/SlickSlider';
import { Link, useParams } from "react-router-dom";
import ImageViewer from "../common/ImageViewer";

function DocTradeTransaction() {
    const { idx } = useParams();
    const [template, setTemplate] = useState({
        preview: []
    });
    const [curIndex, setCurIndex] = useState(0);
    const [viewerShow, setViewerShow] = useState(false);

    useEffect(() => {
        TemplateAPI.getTemplate(idx).then(res => setTemplate(res.data));
    }, []);

    const onClickPrevViewer = () => {
        setCurIndex(curIndex - 1);
    };

    const onClickNextViewer = () => {
        setCurIndex(curIndex + 1);
    };

    const showViewer = (idx) => {
        setCurIndex(idx);
        setViewerShow(true);
    };

    const download = (idx) => {
        TemplateAPI.download(idx);
    }

    return (
        <div id="docTradeTransaction">
            <div className="pc">
                <div className="intro">
                    <div className="intro_title">{template.title}</div>
                    <SlickSlider list={template.preview || []} onClick={showViewer} />
                    {
                        viewerShow
                        &&
                        <ImageViewer
                            curIndex={curIndex}
                            postData={template}
                            onClickClose={() => setViewerShow(false)}
                            onClickPrevViewer={onClickPrevViewer}
                            onClickNextViewer={onClickNextViewer}
                        />
                    }
                    <div className="intro_section style-title">
                        <dl className="intro_section_list">
                            <dt className="intro_section_title">파일형식</dt>
                            <dd className="intro_section_info ">{template.file_type?.toUpperCase()}</dd>
                        </dl>
                        <dl className="intro_section_list">
                            <dt className="intro_section_title">금액</dt>
                            <dd className="intro_section_info style">{window.$Global.commaify(template.price)}원</dd>
                        </dl>
                        {
                            Boolean(template.is_buy)
                                ? <Link to={`/docTrade/my?tab=1`} className="intro_section_button">구매완료</Link>
                                // ? <button className="intro_section_button" onClick={() => download(organisms.idx)}>다운</button>
                                : <Link to={`../payment/${idx}`} className="intro_section_button">구매하기</Link>
                        }
                    </div>
                    <div className="intro_section">
                        <p className="intro_section_title style">소&nbsp;개&nbsp;글</p>
                    </div>
                    <div className="intro_section">
                        <p className="intro_section_content" dangerouslySetInnerHTML={{ __html: template.content }} />
                    </div>
                    <div className="intro_section_button-wrap">
                        <Link to="/docTrade/list" className="intro_section_listLink">목&nbsp;&nbsp;록</Link>
                    </div>
                </div>
            </div>
            <div className="mo">
                <div className="intro">
                    {/* <div className="intro_title">{organisms.title}</div> */}
                    <div className="intro_section">
                        <SlickSlider list={template.preview || []} onClick={showViewer} />
                    </div>
                    {
                        viewerShow
                        &&
                        <ImageViewer
                            curIndex={curIndex}
                            postData={template}
                            onClickClose={() => setViewerShow(false)}
                            onClickPrevViewer={onClickPrevViewer}
                            onClickNextViewer={onClickNextViewer}
                        />
                    }
                    <div className="intro_section">
                        <div className="intro_section_info-wrap">
                            <div className="intro_section_info">
                                <span className="intro_section_info_class">{template.file_type?.toUpperCase()}</span>
                                <span className="intro_section_info_date">{window.$Global.convertDate(template.created_at)} 등록</span>
                            </div>
                            <div className="intro_section_title">{template.title}</div>
                            <div className="intro_section_price-wrap">
                                <span className="intro_section_price">{window.$Global.commaify(template.price)}원</span>
                                {/*<button type="button" className="intro_section_btnDel">삭제</button>*/}
                            </div>
                        </div>
                        <div className="intro_section_format">
                            <div className="intro_section_format_title">파일형식</div>
                            <div className="intro_section_format_text">{template.file_type?.toUpperCase()}</div>
                        </div>
                        <div className="intro_section_comment">
                            <h3 className="intro_section_comment_title">Comment</h3>
                            <p className="intro_section_comment_text">{template.content}</p>
                        </div>
                    </div>
                    {/*{*/}
                    {/*    Boolean(organisms.is_buy)*/}
                    {/*        ? <Link to={`/docTrade/my?tab=1`} className="intro_btnPurchase_complete">샘플 구매완료</Link>*/}
                    {/*        : <Link to={`../payment/${idx}`} className="intro_btnPurchase">샘플 구매하기</Link>*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    )
}

export default DocTradeTransaction;