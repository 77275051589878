export default function transformAdminCheckpoints(checkpointList = []) {
  const checkPointsCategories = Object.keys(checkpointList);
  const tableData = [];

  checkPointsCategories.forEach((categoryName) => {
    checkpointList[categoryName]?.forEach(({ label, question, idx }) => {
      tableData.push({
        idx,
        카테고리: categoryName,
        항목: label,
        질문: question,
      });
    });
  });

  return tableData;
}
