import Pagination from "../../common/Pagination";
import React, { useEffect, useState } from "react";
import "./css/AdminInventorList.scss";
import AdminAPI from "../../../API/admin";
import { useParams } from "react-router-dom";
import qs from "query-string";

const AdminInventorList = ({ history, location }) => {
  const { allCompanyNameSearch } = qs.parse(location.search);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [inventorList, setInventorList] = useState([]);
  const [searchInput, setSearchInput] = useState(allCompanyNameSearch || "");

  useEffect(() => {
    getAdminInventorList();
  }, [curPage]);

  const getAdminInventorList = () => {
    let params = {
      page: curPage,
      count: 10,
      inventorName: searchInput,
    };
    AdminAPI.getAdminInventorList(params).then((res) => {
      setLast(res.data.last);
      setInventorList(res.data.items);
    });
  };

  return (
    <div id="AdminInventorList">
      <div className="header">
        <div className="inventor_search_wrap">
          <input
            type="text"
            placeholder="찾고싶은 발명자의 이름을 입력하세요."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && getAdminInventorList()}
          />
          <i
            className="icon_search_gray"
            onClick={() => getAdminInventorList()}
          />
        </div>
        <button onClick={() => history.push("/admin/annual")}>
          목록으로 돌아가기
        </button>
      </div>
      <table className="inventor_list">
        <colgroup>
          <col width="50px" />
          <col width="70px" />
          <col width="140px" />
          <col width="140px" />
          <col width="300px" />
          <col width="100px" />
          <col width="150px" />
          <col width="150px" />
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>이름</th>
            <th>구분</th>
            <th>특허번호</th>
            <th>발명의 명칭</th>
            <th>등록일</th>
            <th>전화번호</th>
            <th>이메일</th>
            <th>주소</th>
          </tr>
        </thead>
        <tbody>
          {inventorList.map((item, index) => {
            return (
              <tr
                onClick={() =>
                  history.push({
                    pathname: `/admin/inventor/patent/list`,
                    search: `?allCompanyNameSearch=${searchInput}`,
                    state: item,
                  })
                }
              >
                <td>{(curPage - 1) * 10 + (index + 1)}</td>
                <td>{item.inventor_name}</td>
                <td>{item.company_name}</td>
                <td>{item.apply_number}</td>
                <td>{item.invention_title}</td>
                <td>{item.apply_at}</td>
                <td>{item.user_tel_no}</td>
                <td>{item.user_email}</td>
                <td>{item.user_addr}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination curPage={curPage} onClick={setCurPage} lastNum={last} />
    </div>
  );
};

export default AdminInventorList;
