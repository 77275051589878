import React, {useEffect, useState} from 'react';
import './css/InvestmentRequestList.scss';
import AdminAPI from "../../API/admin/index";
import InfiniteScroll from "react-infinite-scroll-component";

function InvestmentRequestList({history}) {

    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        let payload = {
            page: 1,
            count: count,
        };
        getInvestorList(payload);
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    const getInvestorList = (payload, type) => {
        AdminAPI.getRequestInvestmentList(payload).then(res => {
            let get_data = res.data;
            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
            }
            setData(get_data);

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const nextInvestorList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getInvestorList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            let state = item.state == 1 ? "승인 대기" : item.state == 2 ? "반려" : "승인 완료";
            return (
                <tr>
                    <td>{index + 1}</td>
                    <td>{item.company_name}</td>
                    <td>{item.req_amount}</td>
                    <td>{item.representative}</td>
                    <td>{item.license_number}</td>
                    <td>{item.company_phone}</td>
                    <td>
                        <p>{item.core_tech_desc}</p>
                    </td>
                    <td>
                        <p>{item.business_model_desc}</p>
                    </td>
                    <td className={item.state == 2 ? "color_red" : item.state == 3 ? "color_blue" : ""}>{state}</td>
                    <td><button className="icon_more_red" onClick={() => onClickPageGo(item.idx)}/></td>
                </tr>
            );
        }));
    };

    const onClickPageGo = (idx) => {
        history.push(`/patentIntro/detail/${idx}`);
    };

    return (
        <div id="InvestmentRequestList">
            <div className="header">
                <h2 className="title">특허 활용 정보</h2>
            </div>
            <div className="notice">현재 총 {data.total_count || 0}건의 투자요청 건이 있습니다.</div>
            <div id="scrollableDiv" className="wrap_list hover">
                <InfiniteScroll
                    dataLength={tbody.length ? data.items.length : 0}
                    next={nextInvestorList}
                    hasMore={hasMore}
                    scrollableTarget="scrollableDiv">
                    <table className="list">
                        <thead>
                            <tr>
                                <th width="5%">순번</th>
                                <th>회사명</th>
                                <th>투자 요청 금액</th>
                                <th>대표자</th>
                                <th>사업자 등록번호</th>
                                <th>회사 전화번호</th>
                                <th>핵심 특허기술</th>
                                <th>비지니스모델소개</th>
                                <th>승인 상태</th>
                                <th>상세보기</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default InvestmentRequestList;
