import React, {useEffect, useState} from "react";
import "../css/AdminMarketCategory.scss";
import AdminAPI from "../../../API/admin";
import { List, arrayMove } from 'react-movable';
import _ from "lodash";

function AdminMarketCategory() {
	const [category, setCategory] = useState({});
	const [selectBigIdx, setSelectBigIdx] = useState(-1);
	const [commonKeyword, setCommonKeyword] = useState("")
	const [keyword, setKeyword] = useState("");
	const [updateKeyword, setUpdateKeyword] = useState("");
	const [category_list, setCategoryList] = useState([]);
	const [selectCategoryList, setSelectCategoryList] = useState([]);
	const small_category_list = category?.small_category_list;
	const isSmall = selectBigIdx != -1;

	useEffect(() => {
		setCategoryList(category?.category_list || []);
		setSelectCategoryList(category?.category_list || []);
	}, [category]);

	useEffect(() => {
		getList();
	}, []);

	const getList = () => {
		AdminAPI.getMarketCategory().then(res => {
			console.log(res.data);
			setCategory(res.data);
		})
	};

	const addCategory = (type, parent_idx) => {
		let payload = {
			parent_idx: parent_idx, // 상위 카테고리 idx(int)
			name: type ? commonKeyword : keyword, // 이름(string)
			type: type, // 분류(int)
		};
		AdminAPI.addMarketCategory(payload).then(() => {
			setKeyword("");
			setCategory({});
			setSelectBigIdx(-1);
			alert("등록되었습니다.");
			getList();
		});
	}

	const updateCategory = () => {
		let payload = {
			name: updateKeyword,
		};
		AdminAPI.updateMarketCategory(selectBigIdx, payload).then(() => {
			setKeyword("");
			setCategory({});
			setSelectBigIdx(-1);
			alert("수정되었습니다.");
			getList();
		});
	};

	const deleteCategory = (type, idx) => {
		AdminAPI.deleteMarketCategory(idx).then(() => {
			if (type == 0) { // 대분류 삭제 시
				setSelectBigIdx(-1);
				setUpdateKeyword("");
			} else {
				setKeyword("");
				setCategory({});
				setSelectBigIdx(-1);
			}
			alert("삭제되었습니다.");
			getList();
		});
	}

	const onChangeCategory = (idx) => {
		setSelectBigIdx(idx);
		setSelectCategoryList(category_list?.find(item => item.idx == idx)?.child || []);
		setUpdateKeyword(category.category_list.find(item => item.idx == idx)?.name || "");
	}

	const onChangeDrag = (oldIndex, newIndex) => {
		let changeItems = _.cloneDeep(arrayMove(selectCategoryList, oldIndex, newIndex));
		changeItems.forEach((item, idx) => item.order = idx + 1);

		AdminAPI.updateMarketCategoryOrder({category_list: changeItems}).then(() => {
			setSelectCategoryList(changeItems);
		});
	}

	if (window.$Global.isEmptyObject(category)) return null;

	return (
		<div id="AdminMarketCategory">
			<div className="articles">
				<div className="field">
					<span>분류</span>
					<ul>
						<li>
							<select onChange={e => onChangeCategory(e.target.value)}>
								<option value={-1} defaultChecked>선택</option>
								{
									category_list?.map(item => <option value={item.idx}>{item.name}</option>)
								}
							</select>
							{
								isSmall
								&&
								<>
									<input type="text" value={updateKeyword} onChange={e => setUpdateKeyword(e.target.value)}/>
									<button onClick={updateCategory} className="update_btn">수정</button>
									<button className="delete_btn"onClick={() => deleteCategory(0, selectBigIdx)}>삭제</button>
								</>
							}
						</li>
					</ul>
				</div>
				{
					isSmall
					?
					<>
						<div className="field">
							<span>세부 분류</span>
							<ul>
								{
									isSmall
									&&
									selectCategoryList.map(item => {
										return (
											<li>
												{item.name}
												<button className="icon_badge_del ir_txt" onClick={() => deleteCategory(1, item.idx)}>삭제</button>
											</li>
										)
									})
								}
							</ul>
						</div>
						<div className="field">
							<span>세부 분류 추가</span>
							<input type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/>
							<button onClick={() => addCategory(0, selectBigIdx)} className="add_btn">추가</button>
						</div>
					</>
					:
						<div className="field">
							<span>분류 추가</span>
							<input type="text" value={keyword} onChange={e => setKeyword(e.target.value)}/>
							<button onClick={() => addCategory(0, null)} className="add_btn">추가</button>
						</div>
				}
			</div>

			<h2 style={{fontSize: 22}}>세부 분류</h2>
			<List
				values={selectCategoryList}
				onChange={({ oldIndex, newIndex }) => onChangeDrag(oldIndex, newIndex)}
				renderList={({ children, props, isDragged }) => (
					<table style={{marginTop: 20}}>
						<thead>
						<tr>
							<th className="order">게시순서</th>
							<th className="section_name">분류명</th>
							<th className="manage">관리</th>
						</tr>
						</thead>
						<tbody {...props}>{children}</tbody>
					</table>
				)}
				renderItem={({ value, index, props, isDragged, isSelected }) => {
					const _btnShow = isDragged ? { opacity: 0 } : { opacity: 1 };
					const isNew = !Boolean(value.idx);
					const _newDisplay = isNew ? {display: "none"} : {}
					const row = (
						<tr
							{...props}
							style={{
								...props.style,
								cursor: isDragged ? 'grabbing' : 'inherit',
								backgroundColor: isDragged || isSelected ? '#EEE' : '#fafafa'
							}}
						>
							<td className="order">{(index || 0) + 1}</td>
							<td className="section_name">{value.name}</td>
							<td className="manage" style={{..._btnShow}}>
								<div className="btn_list">
									<button className="btn_move" style={_newDisplay} data-movable-handle>이동</button>
									<button className="btn_delete" onClick={() => deleteCategory(1, value.idx)}>삭제</button>
								</div>
							</td>
						</tr>
					);
					return isDragged ? (
						<table style={{ ...props.style, borderSpacing: 0 }}>
							<tbody>{row}</tbody>
						</table>
					) : row;
				}}
			/>

			<div className="articles" style={{marginTop: 80}}>
				<div className="field">
					<span>공통 분류</span>
					<ul>
						{
							small_category_list.map(item => {
								return (
									<li>
										{item.name}
										<button className="icon_badge_del ir_txt" onClick={() => deleteCategory(1, item.idx)}>삭제</button>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div className="field">
					<span>공통 분류 추가</span>
					<input type="text" value={commonKeyword} onChange={e => setCommonKeyword(e.target.value)}/>
					<button onClick={() => addCategory(1, null)} className="add_btn">추가</button>
				</div>
			</div>
		</div>
	)
}

export default AdminMarketCategory;
