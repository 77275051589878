import React, {useContext, useEffect, useState} from "react";
import "../css/MobileUserManage.scss";
import AccountAPI from "../../../API/account";
import memberContext from "../../../store/Member";
import MobileListItem from "./MobileListItem";

function MobileUserManage({history}) {
    const {auth} = useContext(memberContext);
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isAgent = window.$Global.checkAuth("대리인", auth);

    const [master, setMaster] = useState([]);
    const [admin, setAdmin] = useState([]); // 관리자
    const [manager, setManger] = useState([]); // 담당자
    const [tradeCompany, setTradeCompany] = useState([]);
    const [agent, setAgent] = useState([]);
    const [code, setCode] = useState('');

    const codeInput = React.useRef();

    useEffect(() => {
        if (isCompany) {
            AccountAPI.getAccountInfo().then(res => setCode(res.data.code));
            AccountAPI.getAccountCompany().then(res => {
                let data = res.data;

                setMaster(
                    data.master?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );
                setAdmin(
                    data.manager?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );
                setManger(
                    data.researcher?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );
                setAgent(
                    data?.agent?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position="특허사무소"/>;
                    })
                );
            })
        } else if (isAgent) {
            AccountAPI.getAccountAgent().then(async res => {
                let data = res.data;

                setMaster(
                    data?.master?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );

                setAdmin(
                    data?.manager?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );

                setManger(
                    data?.in_charge?.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position={item.position}/>;
                    })
                );
            });

            AccountAPI.getCompanyToAgentList().then(res => {
                setTradeCompany(
                    res.data.map((item, idx) => {
                        return <MobileListItem key={idx} email={item.email} name={item.name} position="회사 계정"/>;
                    })
                )
            });

            AccountAPI.getAccountAgent().then(res => setCode(res.data.code));
        }
    }, []);

    return (
        <div id="MobileUserManage">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2>관리자 페이지</h2>
            </div>

            <div className="personal_link">
                <input ref={codeInput} value={`ipnow.co.kr/invite?code=${code}`} readOnly/>
                <button onClick={() => window.$Global.copy(codeInput)}>개인가입 링크 복사하기</button>
            </div>

            <div className="account_area">
                <h2 className="area_title">관리자</h2>
                <ul>
                    {admin}
                </ul>
            </div>
            {
                isCompany &&
                <div className="account_area">
                    <h2 className="area_title">거래기업</h2>
                    <ul>
                        {agent}
                    </ul>
                </div>
            }

            {
                isAgent &&
                <div className="account_area">
                    <h2 className="area_title">특허사무소</h2>
                    <ul>
                        {tradeCompany}
                    </ul>
                </div>
            }
        </div>
    );
}

export default MobileUserManage;
