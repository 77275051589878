import React from "react";
import "./css/CyberMain.scss";

function CyberMain() {
    return (
        <div id="CyberMain">
            <div className="cybermain">
                <div className="cybermain_boxTop">
                    <h2 className="cybermain_boxTop_title">나의 등록기업 확보하기</h2>
                    <p className="cybermain_boxTop_text">
                        <span className="style">내가 추천한 기업이 등록을 한 경우</span>
                        <br />
                        나에게 그 기업에 대한 권한이 제공됩니다.
                        <br/>
                        <br/>
                    </p>
                </div>
                <div className="cybermain_content">
                    <p className="cybermain_content_text">
                        <span className="style">등록된 기업이 요금제 가입 시</span>
                        <br />결제금액의 18%가 나에게 지급됩니다.
                    </p>
                    <div className="cybermain_content_sub-text">
                        <p className="cybermain_content_sub-text_list">
                            <span className="icon-style">스탠다드 요금제 월정액</span>
                        </p>
                        <p className="cybermain_content_sub-text_list">
                            <span className="icon-style">경쟁기업 기술역량 · 등록 특허 등급 검색하기 월정액</span>
                        </p>
                        <p className="cybermain_content_sub-text_list">
                            <span className="icon-style">모든 공공 사업 ONE Click 찾기 월정액</span>
                        </p>
                    </div>
                    <h2 className="cybermain_content_title">나의 등록기업 확보하기 방법</h2>
                    <ul className="cybermain_content_list-wrap">
                        <li className="cybermain_content_list">
                            <p className="cybermain_content_list_text">
                                <span className="cybermain_content_list_text_title">“추천할 기업” 등록 하기</span>
                                <br />
                                <span className="cybermain_content_list_text_subtitle">이미 등록 된 기업일 경우 자동으로 알려줍니다.</span>
                            </p>
                        </li>
                        <li className="cybermain_content_list">
                            <p className="cybermain_content_list_text">
                                <span className="cybermain_content_list_text_title">내가 등록한 기업이 10일 내 가입</span>
                                <br />
                                <span className="cybermain_content_list_text_subtitle">10일 동안 다른기업에게 유출되지않습니다.</span>
                            </p>
                        </li>
                        <li className="cybermain_content_sub-list">
                            ※ 한 기업당 최대 3회 우선권 등록&amp;보유 가능<br />3회 소진 후 6개월 후부터 우선권 보유가 가능합니다.
                        </li>
                        <li className="cybermain_content_list">
                            <p className="cybermain_content_list_text">
                                <span className="cybermain_content_list_text_title">내가 등록한 기업이 가입 완료 시</span>
                                <br />
                                <span className="cybermain_content_list_text_subtitle">등록 한 기업이 가입 후 유료전환 시<br />월정액 결제 요금의 18%가 지급됩니다.</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CyberMain;