import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeSampleRegisterIndividualList.scss";

function DocTradeSampleRegisterIndividualList({list, onClickDelete}) {
	return (
		<>
		{
			list.map((item, idx) => {
				return (
					<tr key={idx} className="docTradeSampleRegisterIndividualList">
						<td>{item.name}</td>
						<td>
							<button onClick={() => onClickDelete(idx)}>
								<span className="icon_badge_del"></span>
							</button>
						</td>
					</tr>
				)
			})
		}
		</>
	)
}

export default DocTradeSampleRegisterIndividualList;