import React, {useContext, useRef} from "react";
import "./css/MarketInfoMainCart.scss";
import memberContext from "../../store/Member";
import {Link} from "react-router-dom";
import MarketInfoCartItem from "./MarketInfoCartItem";

function MarketInfoMainCart({location, cartArr, showPopup, onClickImageDelete, onChangeFile, onClickCheck, onClickMoPopupClose}) {
	const memberInfo = useContext(memberContext);
	const mi_cart_max_cnt = memberInfo.mi_cart_max_cnt || 0;
	const fileRef = useRef();

	if (!cartArr.length) return null;

	return (
		<div id="MarketInfoMainCart" className="MarketCart pc">
			<div className="MarketCart_header">
				<h2>시장 정보 보기 장바구니</h2>
				<Link to={`/marketInfo/edit${location.search}`}>작성하기</Link>
				<button className="icon_exit ir_txt mo" onClick={onClickMoPopupClose}>닫기</button>
			</div>
			<div className="MarketCart_body">
				<div className="MarketCart_body_header">
					<h2>내가 담은 그래프</h2>
					<div className="inner">
						<p>
							<i className="icon_alert_circle"/>
							<span className="pc">최대 {mi_cart_max_cnt}개까지 담을 수 있습니다.</span>
							<span className="mo">작성은 PC에서 이용하실 수 있습니다.</span>
						</p>
						<button onClick={() => fileRef.current.click()}>내 파일 업로드하기</button>
						<input type="file" ref={fileRef} accept="image/*" hidden onChange={e => onChangeFile(e.target.files[0])}/>
					</div>
				</div>
				<ul className="custom_scroll">
					{
						cartArr.map(item => {
							const notUserUploadImage = !item.title; // 유저가 업로드한 이미지가 아닌 경우 참
							return (
								<MarketInfoCartItem key={item.idx} item={item} hideCheckBox={notUserUploadImage} checked={true} showPopup={() => showPopup(item, "graph")}
													onClickImageDelete={onClickImageDelete} onClickCheck={onClickCheck}
								/>
							)
						})
					}
				</ul>
			</div>
		</div>
	)
}

export default MarketInfoMainCart;