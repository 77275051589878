import React, {useEffect, useState} from "react";
import "./css/MAnnualCountView.scss";
import {Line} from "react-chartjs-2";
import AnnualPayAPI from "../../../API/annualPay";

function MAnnualCountView({history}) {
    let year_arr = [];
    const curYear = new Date().getFullYear();
    const pastYear = curYear - 7;
    const line_chart_width = Math.floor(window.$Global.getBrowserSize().width / 1.1);
    const no_data = window.$Global.notTd(8, '출원·등록되지 않은 항목입니다.');

    const [type, setType] = useState('patent');
    const [annualData, setAannualData] = useState({apply: [], register: []});
    const [selectYear, setSelectYear] = useState(curYear);
    const [resData, setResData] = useState({});
    const [applyTag, setApplyTag] = useState(no_data);
    const [registerTag, setRegisterTag] = useState(no_data);

    const isPatent = type == "patent";
    const isTradeMark = type == "tradeMark";

    for (let i = pastYear; i <= curYear; i++) {
        year_arr.push(i.toString().substr(2) + '년');
    }

    useEffect(async () => {
        AnnualPayAPI.getIPAnnualList(pastYear, curYear).then(res => {
            let apply = res.data.apply;
            let register = res.data.register;

            let apply_keys = Object.keys(apply);
            let register_keys = Object.keys(register);

            let apply_arr = [];
            let register_arr = [];

            for (let i in apply_keys) {
                apply_arr.push({cnt: apply[apply_keys[i]].cnt});
            }

            for (let i in register_keys) {
                register_arr.push({cnt: register[register_keys[i]].cnt});
            }
            setAannualData({apply: apply_arr, register: register_arr});
        });
    }, []);

    useEffect(async () => {
        AnnualPayAPI.getAnnualCount({year: selectYear}).then(res => setResData(res.data));
    }, [selectYear]);

    useEffect(() => {
        if (window.$Global.isEmptyObject(resData)) return;
        let res_apply = {};
        let res_register = {};
        let result_apply = no_data;
        let result_register = no_data;

        if (isPatent) {
            res_apply = resData.patent.apply;
            res_register = resData.patent.register;

            if (res_apply.length) {
                result_apply = res_apply.map((item, idx) => getApplyTag(idx, item));
            }
            if (res_register.length) {
                result_register = res_register.map((item, idx) => getRegistTag(idx, item));
            }
        } else if (isTradeMark) {
            res_apply = resData.trademark.apply;
            res_register = resData.trademark.register;

            if (res_apply.length) {
                result_apply = res_apply.map((item, idx) => getApplyTag(idx, item));
            }
            if (res_register.length) {
                result_register = res_register.map((item, idx) => getRegistTag(idx, item));
            }
        }
        setApplyTag(result_apply);
        setRegisterTag(result_register);
    }, [resData, type]);

    const getApplyTag = (idx, item) => {
        return <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
                    <td>{item.manage_number || '관리번호 N/A'}</td>
                    <td>{item.apply_number}</td>
                </tr>;
    };

    const getRegistTag = (idx, item) => {
        return <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
                    <td>{item.manage_number || '관리번호 N/A'}</td>
                    <td>{item.register_number || item.regist_number}</td>
                </tr>;
    };


    const m_annual_count_data = () => {
        return {
            labels: year_arr,
            datasets: [
                {
                    label: '출원갯수',
                    data: annualData.apply.map(item => item.cnt),
                    backgroundColor: 'rgb(255, 172, 18)',
                    borderColor: 'rgba(255, 172, 18, 0.2)',
                    pointRadius: 6,
                    pointHoverRadius: 6,
                    borderWidth: 4,
                    lineTension: 0,
                    fill: false,
                },
                {
                    label: '등록갯수',
                    data: annualData.register.map(item => item.cnt),
                    backgroundColor: 'rgb(0, 149, 174)',
                    borderColor: 'rgba(0, 149, 174, 0.2)',
                    pointRadius: 6,
                    pointHoverRadius: 6,
                    borderWidth: 4,
                    lineTension: 0,
                    fill: false,
                }
            ],
        }
    };

    const max = window.$Global.calcGraphMaxNumber(annualData.apply.map(item => item.cnt).concat(annualData.register.map(item => item.cnt)));

    const m_annual_count_options = {
        legend: {
            display: false
        },
        onClick: function (e, item) {
            let index = item.length ? item[0]._index : this.scales['x-axis-0'].getValueForPixel(e.x);

            if (Number.isInteger(index)) {
                setSelectYear(pastYear + index);
            }
        },
        responsive: false,
        maintainAspectRatio: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            yAxes: [{
                ticks: {
                    display: true,
                    beginAtZero: true,
                    stepSize : max / 3,
                    max : max,
                    maxTicksLimit: 5,
                    callback: (value) => Math.floor(value)
                },
                gridLines: {
                    display : true,
                    borderDash: [3],
                    color: "#d8d8d8",
                    zeroLineColor: '#d8d8d8',
                    zeroLineWidth: 2,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: true
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#000000",
                },
                gridLines: {
                    display : false,
                }
            }]
        },
        tooltips: {
            enabled: false
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: '#443018',
                font: {
                    weight: 'bold'
                }
            }
        },
    };

    return (
        <div id="MAnnualCountView">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2 className="page_title">연도별 데이터</h2>
            </div>

            <div className="wrap_chart">
                <Line
                    data={m_annual_count_data}
                    width={line_chart_width}
                    height={190}
                    options={m_annual_count_options}
                />

                <div className="legend">
                    <div className="item">
                        <div className="circle blue"/>
                        <p>등록갯수</p>
                    </div>
                    <div className="item">
                        <div className="circle yellow"/>
                        <p>출원갯수</p>
                    </div>
                </div>

                <div className="alert">
                    <i className="icon_alert_circle"/>
                    <p>년도를 클릭하시면 아래 리스트에서 자세히 볼 수 있습니다.</p>
                </div>
            </div>

            <div className="wrap_table">
                <div className="l_head">
                    <h2>{selectYear}년 출원·등록 리스트</h2>
                    <select onChange={e => setType(e.target.value)}>
                        <option value={"patent"}>특허</option>
                        <option value={"tradeMark"}>상표</option>
                    </select>
                </div>

                <h2 className="table_title">출원</h2>
                <div className="table-box-wrap">
                    <div className="wrap_list" id="scrollableDiv">
                        <table className="list">
                            <thead>
                                <tr className="no_focus_outline">
                                    <th>건수</th>
                                    <th>국가</th>
                                    <th>관리번호</th>
                                    <th>출원번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applyTag}
                            </tbody>
                        </table>
                    </div>
                </div>

                <h2 className="table_title" style={{marginTop: 18}}>등록</h2>
                <div className="table-box-wrap">
                    <div className="wrap_list" id="scrollableDiv">
                        <table className="list">
                            <thead>
                            <tr className="no_focus_outline">
                                <th>건수</th>
                                <th>국가</th>
                                <th>관리번호</th>
                                <th>등록번호</th>
                            </tr>
                            </thead>
                            <tbody>
                                {registerTag}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MAnnualCountView;
