import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MarketInfoCategory from "../components/marketInfo/MarketInfoCategory";
import MarketInfoMain from "../components/marketInfo/MarketInfoMain";
import MarketInfoMainTest from "../components/marketInfo/MarketInfoMainTest";
import MarketInfoEdit from "../components/marketInfo/edit/MarketInfoEdit";
import MarketInfoMy from "../components/marketInfo/MarketInfoMy";
import MarketInfoRequest from "../components/marketInfo/request/MarketInfoRequest";
import CustomSwitch from "./common/CustomSwitch";
import MarketInfoRequestEnd from "../components/marketInfo/request/MarketInfoRequestEnd";
import MarketInfoTest from "../components/marketInfo/MarketInfoTest";

function MarketInfoRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/category`}
        requireAuth={false}
        component={MarketInfoCategory}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/list`}
        requireAuth={false}
        component={MarketInfoMain}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/test1`}
        requireAuth={false}
        component={MarketInfoTest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/test`}
        requireAuth={false}
        component={MarketInfoMainTest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/edit`}
        requireAuth={true}
        component={MarketInfoEdit}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/my`}
        requireAuth={true}
        component={MarketInfoMy}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/request`}
        requireAuth={true}
        component={MarketInfoRequest}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/request/end`}
        requireAuth={true}
        component={MarketInfoRequestEnd}
      />
    </CustomSwitch>
  );
}

export default MarketInfoRoute;
