import React, { useState, useEffect } from "react";
import "./css/PopupPatentInfo.scss";
import CheckBox from "../common/CheckBox";
import PatentInfoApplicationAPI from "../../API/patentApplication";
import PatentAPI from "../../API/patent";
import TableRow from "../patentApplicationInfo/row/TableRow";

function PopupPatentInfo({ idx, show, onClickRegister, onClickClose }) {
  const [data, setData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  const [tbody, setTbody] = useState([]);
  const columnShow = {
    checkbox: true,
    no: true,
    name: true,
    patent_no: true,
    nation: true,
    apply_number: true,
    register_number: true,
    state: true,
    invention_name: true,
    product_state: true,
    relative_file: false,
    amount: true,
  };

  useEffect(async () => {
    let result = {};
    if (idx == "self") {
      result = await PatentInfoApplicationAPI.getPatentApplicationInfo().then(
        (res) => res.data
      );
    } else if (idx == "US") {
      result = await PatentAPI.getFreeUsApplyPatentList().then(
        (res) => res.data
      );
    }
    if (!window.$Global.isEmptyObject(result)) {
      setData(result.filter((item) => item.patent.length > 0));
      setCheckboxList(
        result.filter((item) => item.patent.length > 0).map(() => false)
      );
    }
  }, []);

  useEffect(() => {
    setAllChecked(false);
    setCheckboxList(checkboxList.map(() => false));
  }, [show]);

  useEffect(() => {
    if (data) {
      drawTable(data);
    }
  }, [data, checkboxList]);

  const drawTable = (patent) => {
    let tags = [];

    for (let i = 0; i < patent.length; i++) {
      let data = patent[i];
      let first_patent = data.patent.length ? data.patent[0] : {};
      let sales = data.sales;
      let rowSpan = data.patent.length;

      tags.push(
        <TableRow
          key={i}
          editing={false}
          rowSpan={rowSpan || 1}
          emptyInfo={window.$Global.isEmptyObject(first_patent)}
          number={i + 1}
          nation_string={first_patent.nation_string}
          apply_number={first_patent.apply_number}
          patent_idx={first_patent.idx}
          product_idx={data.idx}
          name={data.name}
          state={first_patent.state}
          invention_name={first_patent.invention_name}
          product_state={first_patent.product_state}
          cur_year={new Date().getFullYear()}
          sales={sales}
          register_number={first_patent.register_number}
          afterShow={true}
          columnShow={columnShow}
          checkboxList={checkboxList}
          setCheckboxList={setCheckboxList}
          index={i}
        />
      );

      for (let j = 1; j < rowSpan; j++) {
        let item = data.patent[j];

        tags.push(
          <TableRow
            key={i + "-" + j}
            editing={false}
            rowSpan={rowSpan || 1}
            number={j + 1}
            nation_string={item.nation_string}
            apply_number={item.apply_number}
            patent_idx={item.idx}
            product_idx={data.idx}
            state={item.state}
            invention_name={item.invention_name}
            product_state={item.product_state}
            sales={sales}
            register_number={item.register_number}
            afterShow={false}
            columnShow={columnShow}
            checkboxList={checkboxList}
            setCheckboxList={setCheckboxList}
            index={i}
          />
        );
      }
    }

    if (tags.length == 0) {
      tags = [window.$Global.notTd(12, "매칭되는 특허가 없습니다")];
    }
    setTbody(tags);
  };

  const onClickAllCheck = () => {
    setAllChecked(!allChecked);
    setCheckboxList(checkboxList.map(() => !allChecked));
  };

  const onClickSubmit = () => {
    let arr = [];

    for (let i = 0; i < checkboxList.length; i++) {
      if (checkboxList[i]) {
        arr.push(data[i]);
      }
    }
    onClickRegister(arr);
  };

  if (!show) return null;

  return (
    <div id="PopupPatentInfo">
      <div className="header">
        <div className="inner">
          <h2>특허 활용 정보</h2>
          <p>
            <i className="icon_alert_circle" />
            <span>
              출원 기준 국내 1년미만, PCT 30개월 미만의 특허만 출원 가능합니다.{" "}
            </span>
          </p>
        </div>
        <div className="btns">
          <button className="btn_register" onClick={onClickSubmit}>
            등록
          </button>
          <button className="icon_exit" onClick={onClickClose} />
        </div>
      </div>
      <div className="table-box-wrap not_align">
        <div className="wrap_list">
          <table>
            <thead>
              <tr>
                <th>
                  <CheckBox
                    checked={allChecked}
                    onChangeChecked={onClickAllCheck}
                  />
                </th>
                <th>순번</th>
                <th>
                  제품/
                  <br />
                  서비스
                </th>
                <th>순번</th>
                <th>국가</th>
                <th>출원번호</th>
                <th>
                  Smart5
                  <br />
                  등급
                </th>
                <th>
                  NOW
                  <br />
                  사업성
                </th>
                <th>등록번호</th>
                <th>현재상태</th>
                <th>
                  발명의
                  <br />
                  명칭
                </th>
                <th>적용유무</th>
                <th colSpan="5">매출액 (전년 - 추후 3년)</th>
              </tr>
            </thead>
            <tbody>{tbody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PopupPatentInfo;
