import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import ConsortiumMain from "../components/consortium/ConsortiumMain";
import ConsortiumDetail from "../components/consortium/ConsortiumDetail";
import ConsortiumModify from "../components/consortium/ConsortiumModify";
import ConsortiumManage from "../components/consortium/ConsortiumManage";
import CustomSwitch from "./common/CustomSwitch";

function ConsortiumRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={false} component={ConsortiumMain}/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={true} component={ConsortiumDetail}/>
			<UserAuthRoute exact path={`${match.path}/modify`} requireAuth={true} component={ConsortiumModify}/>
			<UserAuthRoute exact path={`${match.path}/manage`} requireAuth={true} component={ConsortiumManage}/>
		</CustomSwitch>
	)
}

export default ConsortiumRoute;