import React from "react";
import CustomSwitch from "./common/CustomSwitch";
import {Route} from "react-router-dom";
import links from "../const/links";
import MobileService from "../components/mobile/MobileService";
import MobileMore from "../components/mobile/MobileMore";

function MobileRoute() {
	return (
		<CustomSwitch>
			<Route exact path={links.mobile.service} component={MobileService}/>
			<Route exact path={links.mobile.more} component={MobileMore}/>
		</CustomSwitch>
	)
}

export default MobileRoute;