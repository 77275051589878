import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../../common/css/RelativeFileListView.scss';
import RelativeFileTable from '../../common/RelativeFileTable';
import DesignAPI from "../../../API/design";
import MemberContext from "../../../store/Member";
import RelativeFileList from "../../common/RelativeFileList";

function RelativeFileListView() {
    const member = useContext(MemberContext);
    const {design_idx} = useParams();
    const [manageNumber, setManageNumber] = useState(null);
    const [nation, setNation] = useState(-1);
    const [props, setProps] = useState([]);
    const isCompany = window.$Global.checkAuth("특허회사", member.auth);
    const isAgent = window.$Global.checkAuth("대리인", member.auth);

    useEffect(async () => {
        if (window.$Global.isEmptyObject(member)) return;
        let arr = [];

        let response;
        if (isCompany) {
            response = await DesignAPI.getRelationFile(design_idx);
        } else if (isAgent) {
            response = await DesignAPI.getRelationFileAsAgent(member.manage_company_idx, design_idx);
        }
        let data = response.data;
        setManageNumber(data.manage_number);
        setNation(data.nation);

        for (let i = 0; i < data.design_file.length; i++) {
            data.design_file[i].download = onClickDownload('design_file');
            data.design_file[i].t_idx = design_idx;
            data.design_file[i].manage_company_idx = member.manage_company_idx;
        }

        let apply_prop = {
            title: '출원 / 등록',
            rows: data.design_file || [],
        };
        arr.push(apply_prop);

        for (let i = 0; i < data.oa.length; i++) {
            let oa = data.oa[i];
            let concat_arr = [];

            for (let j = 0; j < oa.length; j++) {
                oa[j].download = onClickDownload('oa');
                oa[j].t_idx = design_idx;
                oa[j].manage_company_idx = member.manage_company_idx;
                concat_arr.push(oa[j]);
            }

            if (concat_arr.length) {
                let oa_prop = {
                    title: 'OA',
                    rows: concat_arr,
                };
                arr.push(oa_prop);
            }
        }
        setProps(arr);
    }, [member]);

    const onClickDownload = (type) => {
        switch (type) {
            case "design_file":
                return isCompany ? DesignAPI.downloadFile : DesignAPI.downloadDesginFileAsAgent;
            case "oa":
                return isCompany ? DesignAPI.downloadOaFile : DesignAPI.downloadOaFileAsAgent;
        }
    };

    let tag =
        props.map((item, index) => {
            return <RelativeFileTable key={index} props={item}></RelativeFileTable>
        });

    return (
        <RelativeFileList title="관리번호" manage_number={manageNumber} nation={window.$Global.convertNationCodeToStr(nation)} tag={tag}/>
    );
}

export default React.memo(RelativeFileListView);
