import React from "react";
import BillingInputRow from "./BillingInputRow";

const BillingFooter = ({
  next,
  setNext,
  onClick,
  data,
  nation,
  validateData,
}) => {
  const handleNext = () => {
    if (next) {
      setNext(!next);
      return;
    }

    let check = validateData();

    if (!check) {
      return;
    } else {
      setNext(!next);
    }
  };

  return (
    <div className="annual_fee_billing_footer">
      {next && (
        <BillingInputRow
          type="number"
          data={data.total_amount}
          readOnly={true}
          moneySymbol={"₩"}
          rowName="총 금액"
          widthOption="full"
        />
      )}
      {!next ? (
        <button onClick={handleNext}>다&nbsp; 음</button>
      ) : (
        <div className="btn_wrapper">
          <button onClick={handleNext}>이&nbsp; 전</button>
          <button onClick={onClick}>등&nbsp; 록</button>
        </div>
      )}
    </div>
  );
};

export default BillingFooter;
