import React from "react";
import "./css/BannerIpnow.scss";
import FindBizChange from "./FindBizChange";
import CountUp from "react-countup";

function BannerIpnow({ history }) {
  return (
    <div id="BannerIpnow">
      <div className="txt">
        <div>
          <h2 className="title">
            <p>지식&amp;무형자산</p>
            <em> 밸류 업 &middot; 수익화 &middot; 경쟁사 분석</em>
          </h2>
          <p className="semi_title">
            <span>돈 버는</span> 통합 플랫폼
          </p>
          <p className="s_title">
            기업 성공의 열쇠, 소중한 특허를 엑셀로 방치 하십니까?
            <br />
            전용 플랫폼으로 수익화 하시겠습니까?
          </p>
          <button
            className="banner_btn"
            onClick={() => history.push("/signup/type")}
          >
            무료로 시작하기
          </button>
        </div>
        <div className="count_wrapper">
          <p className="head_text">현재 아이피나우는</p>
          <p className="count">
            <CountUp start={0} end={293574} duration={2.75} separator="," />
            <span>개</span>
            <span> 기업</span>
          </p>
          <p className="count">
            <CountUp start={0} end={1564617} duration={2.75} separator="," />
            <span>명</span>
            <span> 개인</span>
          </p>
          <p className="semi_text">
            <span>IP·DB 보유</span> 및 <span>자동 정보</span>를 제공 중입니다.
          </p>
        </div>
      </div>
      <img
        src={require("../../assets/images/intro/bg-ipnow-banner.png").default}
        alt="IPNOW_BANNER"
        className="banner_image"
      />
    </div>
  );
}

export default BannerIpnow;
