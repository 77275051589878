import React, {useEffect, useState} from "react";
import "./css/FamilyAddPopup.scss";
import PatentAPI from "../../../API/patent";
import CheckBox from "../../common/CheckBox";
import message from "../../../const/message";

function FamilyAddPopup({onClickAdd, onClose}) {
	const [checkList, setCheckList] = useState([]);
	const [patentList, setPatentList] = useState([]);
	useEffect(() => {
		let payload = {
			page: 1,
			count: 1000
		};
		PatentAPI.getPatentList(payload).then(res => {
			let data = res.data.items;
			setPatentList(data);
			setCheckList(new Array(data.length).fill(false));
		});
	}, []);

	const onChecked = (e, idx) => {
		let copy = new Array(patentList.length).fill(false);
		copy[idx] = e.target.checked;
		setCheckList(copy);
	}

	const onClickCall = () => {
		const idx = checkList.findIndex(item => item);
		if (idx === -1) {
			alert(message.errorChoice);
			return;
		}
		const select_patent_idx = patentList[idx].idx;
		onClickAdd(select_patent_idx);
	}

	return (
		<div id="FamilyAddPopup">
			<div className="popup_header">
				<h2 className="title">패밀리 추가</h2>
				<div className="btns">
					<button className="btn_add" onClick={onClickCall}>추가하기</button>
					<button className="icon_exit ir_txt" onClick={onClose}>닫기 버튼</button>
				</div>
			</div>
			<div className="wrap_list custom_scroll">
				<table className="list">
					<thead>
						<tr>
							<th width="5%"/>
							<th width="8%">국가</th>
							<th>특허번호</th>
							<th>발명의 명칭</th>
						</tr>
					</thead>
					<tbody>
					{
						patentList?.map((item, idx) => {
							return (
								<tr>
									<td>
										<CheckBox checked={checkList[idx]} onChangeChecked={(e) => onChecked(e, idx)}/>
									</td>
									<td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
									<td>{item.apply_number}</td>
									<td>{item.invention_name}</td>
								</tr>
							)
						})
					}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default FamilyAddPopup;