import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import FreeRequestList from "../components/freeApply/FreeRequestList";
import FreeUsApply from "../components/freeApply/FreeUsApply";
import CustomSwitch from "./common/CustomSwitch";

function FreeApplyRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={FreeRequestList}/>
			<UserAuthRoute exact path={`${match.path}/:idx`} requireAuth={true} component={FreeUsApply}/>
		</CustomSwitch>
	)
}

export default FreeApplyRoute;