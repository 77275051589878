import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/ExpositionMain.scss";
import Pagination from "../common/Pagination";
import CommonAPI from "../../API/common";
import ContentPhotoList from "../common/ContentPhotoList";
import ContentPhotoItem from "../common/ContentPhotoItem";

function ExpositionMain() {
	return (
		<div id="expositionMain">
			<div className="pc">
				<div className="expositionMain">
					<div className="expositionMain_box-wrap">
						<div className="expositionMain_box">
							<div className="expositionMain_box_title">
								교육&middot;행사&middot;경진 대회
							</div>
							<p className="expositionMain_box_sub">
								귀사에 도움이 되는 각종 교육, 행사와 경진대회를 한번에 찾아 보실 수 있습니다.
							</p>
						</div>
						{/*<button type="button" className="expositionMain_box_btn">*/}
						{/*	튜토리얼 바로가기*/}
						{/*</button>*/}
					</div>
					<ContentPhotoList
						title={"교육·행사·경진 대회"}
						styleHandler={"col-1-2"}
						path={"/exposition"}
						category={3}
						getListAPI={CommonAPI.getNoticeList}
					/>
				</div>
			</div>
			<div className="mo">
				<div className="expositionMain">
					<div className="expositionMain_top">
						<h2 className="expositionMain_title">행사&middot;경진대회</h2>
						<p className="expositionMain_subtitle">
							귀사에 도움이 되는 각종 교육, 행사와 경진대회를<br />
							한 번에 찾아보실 수 있습니다
						</p>
					</div>
					<div className="expositionMain_content">
						<ContentPhotoList
							title={"행사·경진 대회"}
							styleHandler={"m-col-1-1 style"}
							path={"/exposition"}
							category={3}
							getListAPI={CommonAPI.getNoticeList}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ExpositionMain;