import API from '../../util/api';

export default {
    getCompanyGrade() {
        return API.get('/manager/company/grade');
    },
    getCompanyConfig() {
        return API.get('/manager/company/config');
    },
    updateCompanyConfig(payload) {
        return API.post('/manager/company/config', payload);
    }
};
