import React, {useEffect, useState} from "react";
import "./css/CyberAccount.scss";
import WithdrawalInfo from "./WithdrawalInfo";
import CyberAPI from "../../API/cyber";

function CyberAccount() {
    const [bankInfo, setBankInfo] = useState({
        bank_account_number: null,
        bank_name: null
    });
    useEffect(() => {
        CyberAPI.getBankInfo().then(res => setBankInfo(res.data));
    }, []);

    const onClickUpdate = () => {
        CyberAPI.updateBankInfo(bankInfo).then(() => {
            alert("수정되었습니다");
        });
    }

    return (
        <div id="CyberAccount">
            <h2 className="page_title">계좌번호 관리</h2>

            <div className="form">
                <div className="field">
                    <div className="head">
                        <h2>은행명*</h2>
                        <p>*필수입력항목</p>
                    </div>
                    <input type="text" value={bankInfo.bank_name} onChange={e => setBankInfo({...bankInfo, bank_name: e.target.value})}/>
                </div>
                <div className="field">
                    <div className="head">
                        <h2>계좌번호*</h2>
                    </div>
                    <div className="body">
                        <input type="text" value={bankInfo.bank_account_number} onChange={e => setBankInfo({...bankInfo, bank_account_number: e.target.value})}/>
                        <button onClick={onClickUpdate}>수정</button>
                    </div>
                </div>
            </div>
            <WithdrawalInfo/>
        </div>
    )
}

export default CyberAccount;