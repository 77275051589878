import React from "react";
import "./css/CyberMenuBar.scss";
import {Link} from "react-router-dom";

function CyberMenuBar() {
    return (
        <div id="CyberMenuBar">
            <Link to="/cyber">
                <img src={require('../../assets/images/cyber/icon_home.svg').default} alt="이미지"/>
                <span>메인</span>
            </Link>
            <Link to="/cyber/add">
                <img src={require('../../assets/images/cyber/icon_add_company.svg').default} alt="이미지"/>
                <span>기업등록</span>
            </Link>
            <Link to="/cyber/added">
                <img src={require('../../assets/images/cyber/icon_added_company.svg').default} alt="이미지"/>
                <span>등록된기업</span>
            </Link>
            <Link to="/cyber/point">
                <img src={require('../../assets/images/cyber/icon_point.svg').default} alt="이미지"/>
                <span>내 포인트</span>
            </Link>
        </div>
    )
}

export default CyberMenuBar;