import React from 'react';
import "./css/PopupMobileTaskDetail.scss";
import WithPreventScroll from "../../HOC/WithPreventScroll";

function PopupMobileTaskDetail({show, field, item, showMoPopupFile}) {
	const SettingByHOC = () => {
		return (<></>)
	};

	const ComponentWithDimmed = WithPreventScroll(SettingByHOC, show);

	if (window.$Global.isEmptyObject(item) || !show) return null;

	return (
		<div id="PopupMobileTaskDetail" className="mo remove_scrollbar">
			<ComponentWithDimmed/>
			<div className="taskDetail">
				<div className="taskDetail_box">
					<dl className="list_table header">
						<dt className="list_table_title">
							공고 명
						</dt>
						<dd className="list_table_info style_title">
							{item.title}
						</dd>
						<dt className="list_table_title">
							세부&nbsp;&nbsp;공고
						</dt>
						<dd className="list_table_info">
							{item.title_detail}
						</dd>
					</dl>
					<dl className="list_table info">
						<dt className="list_table_title col-1-4">
							공고기관
						</dt>
						<dd className="list_table_info col-1-4">
							{item.department_name}
						</dd>
						<dt className="list_table_title col-1-4">
							지역명
						</dt>
						<dd className="list_table_info col-1-4">
							{window.$Global.matchJsonToStr(field, "region", item.region)}
						</dd>
						<dt className="list_table_title">
							현황
						</dt>
						<dd className="list_table_info">
							{window.$Global.matchJsonToStr(field, "state", item.state)}
						</dd>
						<dt className="list_table_title">
							지원대상
						</dt>
						<dd className="list_table_info">
							{window.$Global.matchJsonToStr(field, "support_target", item.support_target)}
						</dd>
						<dt className="list_table_title">
							분야
						</dt>
						<dd className="list_table_info">
							{window.$Global.matchJsonToStr(field, "field", item.field)}
						</dd>
					</dl>
					<dl className="list_table subinfo">
						<dt className="list_table_title">
							접수일
						</dt>
						<dd className="list_table_info">
							{isNaN(item.reception_at) ? item.reception_at : window.$Global.convertDate(item.reception_at)}
						</dd>
						<dt className="list_table_title">
							마감 일시
						</dt>
						<dd className="list_table_info">
							{isNaN(item.deadline_at) ? item.deadline_at : window.$Global.convertDateTime(item.deadline_at)}
						</dd>
						<dt className="list_table_title">
							금액
						</dt>
						<dd className="list_table_info">
							{isNaN(item.amount) ? item.amount : window.$Global.commaify(item.amount)}
						</dd>
					</dl>
					<dl className="list_table reference">
						<dt className="list_table_title">
							문의처
						</dt>
						<dd className="list_table_info">
							<p>{item.inquries}</p>
							{
								Boolean(item.file.length)
								&&
								<button type="button" className="btnCopy" onClick={showMoPopupFile}>첨부파일</button>
							}
						</dd>
					</dl>
				</div>
				{
					item.uri && <button className="btnNotice" onClick={() => window.open(item.uri, "_blank")}>공고문 바로가기</button>
				}
				{
					Boolean(item.content_core.length)
					&&
					<div className="taskDetail_box style_table">
						{
							item.content_core.map((item2, idx2) => {
								return (
									<dl key={idx2} className="list_table">
										<dt className="list_table_title">
											{item2.title}
										</dt>
										<dd className="list_table_info sun-editor-editable"  dangerouslySetInnerHTML={ {__html: item2.content} }/>
									</dl>
								)
							})
						}
					</div>
				}
			</div>
		</div>
	);
}

export default PopupMobileTaskDetail;