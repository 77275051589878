import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import React, { useContext } from "react";
import DatePickerRow from "./common/DatePickerRow";
import PatentContext from "../../../store/Patent";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import InputAndFileRow from "./common/InputAndFileRow";

function FormAgentKr({
  ListOaTag,
  managerSelectTag,
  inventorListTag,
  onClickDownPopShow,
  onClickUpPopShow,
  checkAuth,
  onClickClaim,
  onClickFirstClaim,
  onChangeInput,
  onChangeDatePicker,
}) {
  const patentInfo = useContext(PatentContext);

  return (
    <div className="form">
      <div className="field">
        <div className="rows">
          <InputRow
            title="대리인 관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.agent_manage_number}
            onChange={onChangeInput}
            setKey="agent_manage_number"
          />
          {/*</div>*/}
          {/*<div className="rows">*/}
          <NormalRow title="관리번호" content={patentInfo.manage_number} />
          <NormalRow title="유형" content={patentInfo.type} />
          {/*<div className="row">*/}
          {/*<h2 className="title">Family 출원<Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`}/></h2>*/}
          {/*<p>{patentInfo.family_cnt}건</p>*/}
          {/*</div>*/}
        </div>
        <div className="rows">
          <NormalRow title="담당자" content={patentInfo.manager_name} />
          <NormalRow
            title="발명자 1"
            content={patentInfo.inventor.length && patentInfo.inventor[0].name}
          />
          <div className="row">
            <h2 className="title">
              대리인 담당자
              <i
                className="icon_info"
                data-content={window.$Global.getInfoMessage("대리인 담당자")}
              />
            </h2>
            {patentInfo.editing ? (
              <select
                defaultValue={patentInfo.agent_idx || 0}
                onChange={(e) =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    agent_idx: e.target.value,
                    agent_name: e.target.options[e.target.selectedIndex].text,
                  })
                }
              >
                <option value={0} disabled hidden>
                  선택
                </option>
                {managerSelectTag}
              </select>
            ) : (
              <p>{patentInfo.agent_name ? patentInfo.agent_name : "N/A"}</p>
            )}
          </div>
        </div>
        {inventorListTag}
        <div className="rows">
          <DateAndFileRow
            title={`디자인 창작물 ${checkAuth("특허회사") ? "접수" : ""}`}
            date={patentInfo.invention_created_at}
            condition={patentInfo.editing}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_invent, { type: 0 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_invent, { type: 0 })
            }
            uploadBtnShow={checkAuth("담당자")}
            haveFile={Boolean(patentInfo.invention_file_cnt)}
          />
        </div>
      </div>
      <div className="field">
        <div className="rows">
          <NormalRow
            title={`초안작성 지시 접수${patentInfo.draft_order_at ? "일" : ""}`}
            content={patentInfo.draft_order_at}
            txtType="date"
          />
          <InnerDatePickerAndFileRow
            title="초안 전달"
            data={patentInfo.draftReceivingDate}
            condition={patentInfo.editing}
            dateBtnHide={true}
            uploadBtnShow={true}
            haveFile={Boolean(patentInfo.draft_file_cnt)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft, { type: 0 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_draft, { type: 0 })
            }
          />
          <DateAndFileRow
            title={`초안 수정본 접수${
              patentInfo.draftModifiedUploadDate.date !== null ? "일" : ""
            }`}
            date={patentInfo.draftModifiedUploadDate.date}
            condition={patentInfo.editing}
            uploadBtnShow={false}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft, { type: 3 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_draft, { type: 3 })
            }
            haveFile={Boolean(patentInfo.draft_modified_file_cnt)}
          />
        </div>
        <div className="rows">
          <InnerDatePickerAndFileRow
            title={"초안\n최종본 전달"}
            data={patentInfo.draftFinalDate}
            condition={patentInfo.editing}
            dateBtnHide={true}
            uploadBtnShow={true}
            haveFile={Boolean(patentInfo.draft_final_file_cnt)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_draft, { type: 4 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_draft, { type: 4 })
            }
          />
          <div className="row no_data" />
          <NormalRow
            title="출원 지시 접수"
            titleClassName="color_blue"
            content={patentInfo.apply_order_at}
            txtType="date"
          />
        </div>
      </div>
      <div className="field">
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_apply, { type: 5 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_apply, { type: 5 })
            }
          />
          <DatePickerRow
            title="출원일"
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="PCT 출원 기한"
            titleClassName="color_blue"
            content={patentInfo.pct_apply_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="inventionName"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="inventionNameEn"
          />
          <div className="row no_data" />
        </div>
        <div className="rows">
          <NormalRow
            title="심사청구"
            content={patentInfo.screen_req ? "유" : "무"}
          />
          <NormalRow
            title="심사청구일"
            content={patentInfo.screen_req_at}
            txtType="date"
          />
          <NormalRow
            title="심사청구 기한"
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      {ListOaTag}
      <div className="field">
        <div className="rows">
          <DatePickerRow
            title="등록 결정일"
            titleClassName="color_blue"
            condition={patentInfo.editing}
            data={patentInfo.register_decision_at}
            dataKey="register_decision_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <DatePickerRow
            title="등록일"
            condition={patentInfo.editing}
            data={patentInfo.register_at}
            dataKey="register_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
            maxDate={new Date()}
          />
        </div>
        <div className="rows">
          <InputAndFileRow
            title="등록번호"
            content={patentInfo.register_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.register_file_cnt)}
            onChange={(e) => onChangeInput("register_number", e.target.value)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.agent_register, { type: 6 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.agent_register, { type: 6 })
            }
          />
          <NormalRow
            title="존속기간 만료일"
            titleClassName="color_blue"
            content={patentInfo.expiration_at}
            contentClassName="color_blue"
            txtType="date"
          />
          <NormalRow
            title="등록기한"
            titleClassName="color_blue"
            content={patentInfo.register_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="관납료 납부기한"
            titleClassName="color_blue"
            content={patentInfo.payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      <div className="field">
        <div className="rows">
          <NormalRow
            title="납부년차"
            titleClassName="color_blue"
            content={
              patentInfo.payment_year && patentInfo.payment_year + "년차"
            }
            contentClassName="color_blue"
          />
          <NormalRow
            title="연차료 기한"
            titleClassName="color_blue"
            content={patentInfo.annual_payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        {/*<div className="rows">*/}
        {/*<NormalRow title='연차관리 회사' content={patentInfo.annual_payment_manage_company}/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default FormAgentKr;
