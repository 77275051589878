import React from "react";
import "chartjs-plugin-datalabels";
import TaskMain from "../taskManage/TaskMain";
import IntroRedirectList from "../intro/organisms/IntroRedirectList";

function HomeTaskManage() {
  return (
    <div id="HomeTaskManage">
      <div className="pc">
        <TaskMain />
      </div>
    </div>
  );
}

export default HomeTaskManage;
