import React from "react";
import "./css/MarketInfoSideBar.scss";

const MarketInfoSideBar = ({ category }) => {
  return (
    <div id="MarketInfoSideBar">
      <ul>
        {category.map((el, index) => {
          return (
            <li key={index} onClick={() => alert("준비중인 기능입니다.")}>
              {el.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MarketInfoSideBar;
