const Annuity = ({ cost, year }) => {
  return (
    <div id="Annuity">
      <strong className="cost">{cost} 원</strong>
      <p>{year}년 예상연차료</p>
    </div>
  );
};

export default Annuity;
