import React, { useEffect, useState } from "react";
import "./css/AnnualFeePerCost.scss";
import AnnualPay from "../../API/annualPay";
import AnnualFeeReviewState from "./AnnualFeeReviewState";
import AdminAPI from "../../API/admin";
import CommonAPI from "../../API/common";

const AnnualFeePerCost = ({
  annualFeeData,
  setShowPopup,
  isAdmin,
  refresh,
}) => {
  // , and / 로 구분
  const [perCostData, setPerCostData] = useState({
    management_name: [],
    expense_name: [],
    rignt_holder_name: [],
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    initData();
  }, []);

  const onClickSave = () => {
    let payload = {
      idx: annualFeeData.patent_idx,
      management_name: perCostData.management_name.join(","),
      expense_name: perCostData.expense_name.join(","),
      rignt_holder_name: perCostData.rignt_holder_name.join(","),
    };

    if (isAdmin) {
      AdminAPI.updateAnnualPerCostData(payload).then(() => {
        refresh();
      });
    } else {
      CommonAPI.updateAnnualPerCostData(payload).then(() => {
        refresh();
      });
    }

    setShowPopup(false);
  };

  const onClickCancel = () => {
    initData();
    setIsEdit(false);
  };

  const initData = () => {
    setPerCostData({
      management_name: annualFeeData.management_name?.split(/[\/,]+/) || [],
      expense_name: annualFeeData.expense_name?.split(/[\/,]+/) || [],
      rignt_holder_name: annualFeeData.rignt_holder_name?.split(/[\/,]+/) || [],
    });
  };

  const onClickAdd = (name) => {
    setPerCostData((prev) => ({
      ...prev,
      [name]: [...prev[name], ""],
    }));
  };

  const onChangeText = (e, item, index, name) => {
    const newValue = e.target.value;
    const updateArr = perCostData[name];
    updateArr[index] = newValue;

    setPerCostData((prev) => ({
      ...prev,
      [name]: [...updateArr],
    }));
  };

  const handleDelete = (e, item, index, name) => {
    const updateArr = perCostData[name];
    updateArr.splice(index, 1);

    setPerCostData((prev) => ({
      ...prev,
      [name]: [...updateArr],
    }));
  };

  return (
    <div id="AnnualFeePerCost">
      <header>
        <div>
          <h2>연차료 관리주체/비용분담률</h2>
          <i className="icon_exit_gray" onClick={() => setShowPopup(false)} />
        </div>
      </header>
      <section>
        <div>
          <p>
            <span>관리주체</span>
            {isEdit && (
              <i
                className="icon_plus"
                onClick={() => onClickAdd("management_name")}
              />
            )}
          </p>
          <div>
            {perCostData?.management_name.map((item, index) => {
              return isEdit ? (
                <div className="input_wrapper">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) =>
                      onChangeText(e, item, index, "management_name")
                    }
                  />

                  {index !== 0 && (
                    <i
                      className="icon_del_trashcan"
                      onClick={(e) =>
                        handleDelete(e, item, index, "management_name")
                      }
                    />
                  )}
                </div>
              ) : (
                <p>{item}</p>
              );
            })}
          </div>
        </div>
        <div>
          <p>
            <span>비용분담률</span>
            {isEdit && (
              <i
                className="icon_plus"
                onClick={() => onClickAdd("expense_name")}
              />
            )}
          </p>
          <div>
            {perCostData?.expense_name.map((item, index) => {
              return isEdit ? (
                <div className="input_wrapper">
                  <input
                    type="text"
                    value={item}
                    placeholder="ex) 기업명 : 100%"
                    onChange={(e) =>
                      onChangeText(e, item, index, "expense_name")
                    }
                  />
                  {index !== 0 && (
                    <i
                      className="icon_del_trashcan"
                      onClick={(e) =>
                        handleDelete(e, item, index, "expense_name")
                      }
                    />
                  )}
                </div>
              ) : (
                <p>{item}</p>
              );
            })}
          </div>
        </div>
        <div>
          <p>
            <span>권리인명</span>
            {isEdit && (
              <i
                className="icon_plus"
                onClick={() => onClickAdd("rignt_holder_name")}
              />
            )}
          </p>
          <div>
            {perCostData?.rignt_holder_name.map((item, index) => {
              return isEdit ? (
                <div className="input_wrapper">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) =>
                      onChangeText(e, item, index, "rignt_holder_name")
                    }
                  />
                  {index !== 0 && (
                    <i
                      className="icon_del_trashcan"
                      onClick={(e) =>
                        handleDelete(e, item, index, "rignt_holder_name")
                      }
                    />
                  )}
                </div>
              ) : (
                <p>{item}</p>
              );
            })}
          </div>
        </div>
      </section>
      <footer>
        {isEdit ? (
          <>
            <button onClick={onClickSave}>저장</button>
            <button onClick={onClickCancel}>취소</button>
          </>
        ) : (
          <button onClick={() => setIsEdit(true)}>수정</button>
        )}
      </footer>
    </div>
  );
};

export default AnnualFeePerCost;
