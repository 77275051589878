import React from 'react';

function FileRow({title, titleClassName, onClickDownPopShow, onClickUpPopShow}) {
    return (
        <div className="row">
            <h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
            <div className="btns">
                <button className="icon_download" onClick={onClickDownPopShow}/>
                <button className="icon_upload" onClick={onClickUpPopShow}/>
            </div>
        </div>
    );
}

export default FileRow;
