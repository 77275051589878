import React, {useContext, useEffect, useState} from "react";
import "./css/ConsortiumManage.scss";
import {Link} from "react-router-dom";
import config from "./const";
import ConsortiumAPI from "../../API/consortium";
import Pagination from "../common/Pagination";
import qs from "query-string";

function ConsortiumManage({history, location}) {
	const type = qs.parse(location.search).type || 0;
	const [searchType, setSearchType] = useState(type || 0); // 0 공급, 1 수요
	const [resData, setResData] = useState({items: []});
	const [curPage, setCurPage] = useState(1);

	const supply = config.supply; // 0 공급
	const demand = config.demand; // 1 수요

	const isSupply = searchType == supply;
	const isDemand = searchType == demand;

	const typeStr = searchType == config.supply ? '내 기업 소개' : '필요 기업 찾기';

	const commonQuery = `&postType=add`;
	const supplyLink = `modify?type=${supply}${commonQuery}`;
	const demandLink = `modify?type=${demand}${commonQuery}`;
	const postLink = isSupply ? supplyLink : demandLink

	const haveData = Boolean(resData.items.length);

	useEffect(() => {
		getList(1);
		setCurPage(1);
		history.push(`?type=${searchType}`);
	}, [searchType]);

	const getList = (page) => {
		let params = {
			page: page || curPage,
			count: 20,
			type: searchType,
			show_type: 2 // 2 내가 올린거 보기
		};
		ConsortiumAPI.getList(params).then(res => setResData(res.data));
	};

	const goDetail = (consortium_idx) => {
		history.push(`detail/${consortium_idx}`);
	};

	return (
		<div id="ConsortiumManage">
			<div className="consortiumManage">
				<div className="consortiumManage_tab">
					<h2 className="consortiumManage_tab_title">컨소시엄 관리</h2>
					<ul className="consortiumManage_tab_list-wrap">
						<li className={`consortiumManage_tab_list ${isSupply ? 'on' : ''}`}>
							<button type="button" className="consortiumManage_tab_btn" onClick={() => setSearchType(supply)}>
								내 기업 소개 등록 목록
							</button>
						</li>
						<li className={`consortiumManage_tab_list ${isDemand ? 'on' : ''}`}>
							<button type="button" className="consortiumManage_tab_btn" onClick={() => setSearchType(demand)}>
								필요 기업 찾기 등록 목록
							</button>
						</li>
					</ul>
				</div>
				<div className="consortiumManage_content">
					<div className="consortiumManage_content_top">
						<h3 className="consortiumManage_content_title">등록한 {typeStr}</h3>
						<Link to={postLink} className="consortiumManage_content_btn">{typeStr} 등록</Link>
					</div>
					<table className="consortiumManage_table">
									<caption className="hidden">등록 한 공급기업 테이블</caption>
									<colgroup>
										<col width="5%" />
										<col width="*" />
										<col width="98px" />
										<col width="98px" />
										<col width="98px" />
										<col width="98px" />
									</colgroup>
									<thead className="consortiumManage_table_head">
										<tr className="consortiumManage_table_row">
											<th className="consortiumManage_table_col">순번</th>
											<th className="consortiumManage_table_col">제목</th>
											<th className="consortiumManage_table_col">기업유형</th>
											<th className="consortiumManage_table_col">사업분야</th>
											<th className="consortiumManage_table_col">산업분야</th>
											<th className="consortiumManage_table_col">자세히 보기</th>
										</tr>
									</thead>
									<tbody className="consortiumManage_table_body">
										{
											Boolean(resData.items.length)
											?
											resData.items.map((item, idx) => {
												return (
													<tr className="consortiumManage_table_row" key={idx}>
														<td  className="consortiumManage_table_col">{resData.total_count - idx}</td>
														<td className="consortiumManage_table_col">{item.company_name}</td>
														<td className="consortiumManage_table_col">{item.company_type}</td>
														<td className="consortiumManage_table_col">{item.business_field}</td>
														<td className="consortiumManage_table_col">{item.industry_field}</td>
														<td className="consortiumManage_table_col">
															<button type="button" className="consortiumManage_table_col_btn" onClick={() => goDetail(item.idx)}>자세히 보기</button>
														</td>
													</tr>
												)
											})
											: window.$Global.notTd(6, "등록한 정보가 없습니다")
										}
									</tbody>
								</table>
				</div>
			</div>
			{
				haveData && <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
			}
		</div>
	);
}

export default ConsortiumManage;