import React, { useState } from "react";
import "./css/BannerFindX.scss";
import { Link } from "react-router-dom";
import links from "../../const/links";

function BannerFindX({ history }) {
  const [keyword, setKeyword] = useState("");

  const onSearch = () => {
    history.push(`${links.taskManage}?searchKeyword=${keyword}`);
  };

  return (
    <div id="BannerFindX">
      <div className="bg" />
      <div className="wrap">
        <div className="inner">
          <img
            className="findx_logo"
            src={require("../../assets/images/common/logo_findBiz.svg").default}
            alt="로고 이미지"
          />
          <h2>모든 공공사업 ONE Click 찾기</h2>
          <p>
            정부 · 공공기관 · 지자체 사업, 한곳에서 한꺼번에 확인하고 원하는
            사업가 뜨면 메일로 바로 알려줍니다.
          </p>
          <div className="search_form">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") onSearch();
              }}
              placeholder="사업제목 또는 사업내용을 검색하세요"
            />
            <button className="icon_search_white" onClick={onSearch} />
          </div>
          <div className="links">
            <Link to={links.taskManage}>
              <img
                src={
                  require("../../assets/images/home/btn_task_view.png").default
                }
                alt="이미지"
              />
            </Link>
            <div className="link_br" />
            <Link to="/taskManage/setting">
              <img
                src={
                  require("../../assets/images/home/btn_task_notify.png")
                    .default
                }
                alt="이미지"
              />
            </Link>
          </div>
        </div>
        <div className="wrap_image">
          <img
            className="icon_findx"
            src={require("../../assets/images/home/icon_findx.svg").default}
            alt="이미지"
          />
        </div>
      </div>
    </div>
  );
}

export default BannerFindX;
