function PatentMailResChkAuth() {
  return (
    <div id="PatentMailResChkAuth">
      <div className="annual_mail_page">
        <div className="top">
          <div className="title">
            <i className="icon_annual_response"></i>
            <div className="txt">
              <strong>발명자 본인 인증</strong>
              <p>본 설문 응답을 위해 이름과 생년월일을 확인합니다.</p>
            </div>
          </div>
          <div className="steps">
            <p className="one on">1</p>
            <p className="two">2</p>
          </div>
        </div>
        <div className="auth_form">
          <ul>
            <li>
              <strong>이&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;름</strong>
              <input type="text" name="name" />
            </li>
            <li>
              <strong>생년월일<br />(6자리)</strong>
              <input type="text" name="birth" />
            </li>
          </ul>
        </div>
        <div className="btns">
          <button>확인</button>
        </div>
      </div>
    </div>
  )
}

export default PatentMailResChkAuth;