import React, {useEffect, useRef, useState} from "react";
import "../css/MarketInfoEdit.scss";
import MarketInfoPopupCart from "../MarketInfoPopupCart";
import convertCategory from "../js/convertCategory";
import CommonAPI from "../../../API/common";
import qs from "query-string";
import _ from "lodash";
import MarketInfoPopupDetail from "../MarketInfoPopupDetail";
import Validator from "../../../util/validator";

function MarketInfoEdit({history, location}) {
	const maxLength = 1000;
	const titleRef = useRef();
	const {category_idx1, category_idx2, idx} = qs.parse(location.search);
	const [category, setCategory] = useState([]);
	const select_category = convertCategory(category, category_idx1, category_idx2);

	const [selectIndex, setSelectIndex] = useState(-1);
	const [document, setDocument] = useState({
		title: "", // 제목(string)
		content: [], // 내용(Object)
		category1_idx: category_idx1, // 대분류_idx(int)
		category2_idx: category_idx2 // 중분류_idx(int)
	});

	const [cartPopupShow, setCartPopupShow] = useState(false);
	const [detailPopupShow, setDetailPopupShow] = useState(false);
	const [detailPopupInfo, setDetailPopupInfo] = useState({});

	useEffect(() => {
		if (idx) CommonAPI.getMarketDocument(idx).then(res => setDocument(res.data));
		CommonAPI.getMarketCategory().then(res => setCategory(res.data));
	}, []);


	const onClickDetailShowPopup = (item) => {
		setDetailPopupShow(true);
		setDetailPopupInfo(item);
	};

	const onClickPopupShow = (index) => {
		setSelectIndex(index);
		setCartPopupShow(true);
	}
	
	const onChangeDoc = (key, value) => {
		let copy = _.cloneDeep(document);
		copy[key] = value;
		setDocument(copy);
	}

	const addContent = (type) => {
		let copy = _.cloneDeep(document);
		if (type == "text") {
			copy.content.push(
				{
					type: "text",
					value: ""
				}
			)
		} else {
			copy.content.push(
				{
					type: "image",
				}
			)
		}
		setDocument(copy);
	};

	const onChangeTextDocContent = (index, value) => {
		if (value.length > maxLength) {
			alert("최대 입력 가능 글자 수를 초과하였습니다");
			return;
		}
		let copy = _.cloneDeep(document);
		copy.content[index].value = value;
		setDocument(copy);
	};

	const deleteContent = (index) => {
		let copy = _.cloneDeep(document);
		copy.content.splice(index, 1);
		setDocument(copy);
	}

	const deleteContentImage = (index) => {
		let copy = _.cloneDeep(document);
		copy.content[index].mi_idx = "";
		copy.content[index].my_idx = "";
		copy.content[index].image_key = "";
		setDocument(copy);
	}

	const onClickSelectItem = (item) => {
		if (selectIndex > -1) {
			let copy = _.cloneDeep(document);
			if (item.mi_idx) {
				copy.content[selectIndex].mi_idx = item.mi_idx;
			} else {
				copy.content[selectIndex].my_idx = item.idx;
			}
			copy.content[selectIndex].image_key = item.image_key;
			setDocument(copy);
			setCartPopupShow(false);
		}
	}

	const onClickSaveAndDownload = async () => {
		if (Validator.refValidator([titleRef])) {

			if (!document.content.length) {
				alert("내용을 입력해주세요");
				return;
			}

			let validatorTxt = document.content.filter(item => item.type == "text" && !Boolean(item.value.length)).length;

			if (validatorTxt) {
				alert("글 내용을 입력해주세요");
				return
			}

			let validatorImage = document.content.filter(item => item.type == "image" && !Boolean(item.mi_idx || item.my_idx)).length;

			if (validatorImage) {
				alert("이미지를 선택해주세요");
				return
			}

			if (idx) {
				await CommonAPI.updateMarketDocument(idx, document);
			} else {
				await CommonAPI.addMarketDocument(document);
			}
			alert("저장되었습니다");
			history.replace("my");
		}
	};

	return (
		<div id="MarketInfoEdit">
			<div className="header">
				<h2>시장 정보 작성하기</h2>
				<ul className="category">
					<li>
						<h2>대분류</h2>
						<p>{select_category.b_category || document.category1_name}</p>
					</li>
					<li>
						<h2>중분류</h2>
						<p>{select_category.s_category || document.category2_name}</p>
					</li>
				</ul>
			</div>
			<div className="step">
				<h2>Step.1 제목 작성하기</h2>
			</div>
			<div className="step1">
				<h2>제목 작성</h2>
				<input type="text" placeholder="제목을 입력해주세요" data-name="제목" ref={titleRef} autoFocus={true}
					   value={document.title} onChange={e => onChangeDoc("title" , e.target.value)} />
			</div>
			<div className="step">
				<h2>Step.2 내용 작성하기</h2>
			</div>
			{
				document.content.map((item, idx) => {
					if (item.type == "text") {
						return (
							<div className="step2">
								<div className="step2_header">
									<h2 className="step2_header_title">Type.글쓰기 / 설명을 작성해주세요</h2>
									<div className="step2_header_info">
										<p>
											<i className="icon_alert_circle"/>
											<span>최대 입력 가능한 글자 수 {maxLength}자 제한</span>
										</p>
										<button className="btn_delete" onClick={() => deleteContent(idx)}>삭제하기</button>
									</div>
								</div>
								<div className="step2_body">
									<div className="step2_body_head">
										<div className="desc_info">
											<h2>설명란</h2>
											<p>설명글을 입력해주세요.</p>
										</div>
										<div className="txt_info">
											<h2>현대 최대 글자수</h2>
											<p><span>{item.value.length}</span>/{maxLength}자</p>
										</div>
									</div>
									<textarea className="custom_scroll" placeholder="설명글을 입력해주세요"
											  value={item.value} onChange={e => onChangeTextDocContent(idx, e.target.value)}/>
								</div>
							</div>
						)
					} else {
						return (
							<div className="step2">
								<div className="step2_header">
									<h2 className="step2_header_title">Type.이미지 첨부 / 이미지를 골라주세요</h2>
									<div className="step2_header_info">
										<button className="btn_delete" onClick={() => deleteContent(idx)}>삭제하기</button>
									</div>
								</div>
								<div className="step2_body">
									<div className="step2_body_head">
										<div className="desc_info">
											<h2>등록된 이미지 첨부란</h2>
										</div>
									</div>
									<div className="image_attach_list">
										{
											item.image_key
											?
												<div className="item image">
													<img src={window.$Global.getCDN(item.image_key)} alt="이미지" onContextMenu={window.$Global.blockRightClick}/>
													<button className="icon_badge_del ir_txt" onClick={() => deleteContentImage(idx)}>삭제</button>
												</div>
											:
												<div className="item get_image" onClick={() => onClickPopupShow(idx)}>
													<i className="icon_upload"/>
													<p>장바구니에서 이미지 가져오기</p>
												</div>
										}
									</div>
								</div>
							</div>
						)
					}
				})
			}
			<div className="btns">
				<button className="btn_txt" onClick={() => addContent("text")}>
					<i className="icon_white_add"/>
					<p>글 박스 추가하기</p>
				</button>

				<button className="btn_image" onClick={() => addContent("image")}>
					<i className="icon_white_add"/>
					<p>이미지 박스 추가하기</p>
				</button>
			</div>
			<div className="step">
				<h2>Step.3 작성한 시장정보 저장하기</h2>
			</div>
			<button className="btn_save" onClick={onClickSaveAndDownload}>저장</button>

			<MarketInfoPopupCart show={cartPopupShow} onClickPopupShow={onClickDetailShowPopup} onSuccess={onClickSelectItem} onClose={() => setCartPopupShow(false)}/>
			<MarketInfoPopupDetail show={detailPopupShow} item={detailPopupInfo} onClose={() => setDetailPopupShow(false)}/>
		</div>
	)
}

export default MarketInfoEdit;