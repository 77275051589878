import { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import "./css/AdminAnnualManage.scss";
import { Link, useHistory } from "react-router-dom";

const AdminAnnualManage = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = () => {
    let params = {
      keyword: search,
    };
    AdminAPI.getAdminMngCompanyAnnual(params).then((res) => {
      setData(res.data);
    });
  };
  return (
    <div id="AdminAnnualManage">
      <div className="annual_wrapper">
        <h2>연차료 납부 관리</h2>
        <div className="header_btn">
          <button
            className="btn_search_name"
            onClick={() => history.push("/admin/annual/inventor/list")}
          >
            이름으로 발명자 찾기
          </button>
        </div>
        <div className="annual_search_wrapper">
          <div className="search_box">
            <p>대학·기관명 검색하기</p>
            <div className="search_input">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && getCompany()}
              />
              <i className="icon_m_search" onClick={() => getCompany()} />
            </div>
          </div>
        </div>
        <div className="annual_user_list">
          <div className="list_header">
            <p>고객 리스트</p>
          </div>
          <div className="annual_list_grid">
            {data?.map((el) => {
              return (
                <Link
                  to={{
                    pathname: `/admin/annual/${el.company_idx}`,
                  }}
                >
                  <div className="annual_list_grid_item">
                    <p>
                      {el.name}
                      <br />
                      <span>바로가기</span>
                    </p>
                    <i className="icon_double_arrow_right" />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {/*<div className="annual_search_wrapper">*/}
      {/*  <div className="search_box">*/}
      {/*    <p>대학·기관명 검색하기</p>*/}
      {/*    <div className="search_input">*/}
      {/*      <input*/}
      {/*        type="text"*/}
      {/*        value={search}*/}
      {/*        onChange={(e) => setSearch(e.target.value)}*/}
      {/*      />*/}
      {/*      <i*/}
      {/*        className="icon_del_small_with_bg_gray"*/}
      {/*        onClick={() => setSearch("")}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default AdminAnnualManage;
