import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "./css/InvestmentRequestDetail.scss";
import InputRow from "./row/InputRow";
import IntroRow from "./row/IntroRow";
import TableRow from "../patentApplicationInfo/row/TableRow";

import InvestmentAPI from "../../API/investment/index";
import InvestorAPI from "../../API/investor/index";
import AccountAPI from "../../API/account/index";
import AdminAPI from "../../API/admin/index";

import NormalRow from "./row/NormalRow";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import _ from "lodash";
import PopupPatentInfo from "./PopupPatentInfo";
import PopupInvestorList from "./PopupInvestorList";
import memberContext from "../../store/Member";
import CheckBox from "../common/CheckBox";

function InvestmentRequestDetail({ history }) {
  let investRow = [];

  const { idx } = useParams(); // self 일 경우 기업이 조회, idx일 경우 투자자
  const { auth, admin } = useContext(memberContext);
  const [companyInfo, setCompanyInfo] = useState({});
  const [editing, setEditing] = useState(false);
  const [historySaveInvestment, setHistorySaveInvestment] = useState({});
  const [investment, setInvestment] = useState({});
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const [isNew, setIsNew] = useState(false);
  const disableUpload = investment.state == 1 || investment.state == 3;
  const [tbody, setTbody] = useState([]);

  const [deleteHide, setDeleteHide] = useState(false);
  const [popupInvestorListShow, setPopupInvestorListShow] = useState(false);
  const [popupPatentInfoShow, setPopupPatentInfoShow] = useState(false);
  const [downPopupShow, setDownPopupShow] = useState(false);
  const [upPopupShow, setUpPopupShow] = useState(false);
  const [fileViewList, setFileViewList] = useState([]);
  const [uploadFileViewList, setUploadFileViewList] = useState([]);
  const [popupProp, setPopupProp] = useState({
    type: null,
    idx: null,
    investor_idx: null,
  });
  const columnShow = {
    checkbox: false,
    no: true,
    name: true,
    patent_no: true,
    nation: true,
    apply_number: true,
    register_number: true,
    state: true,
    invention_name: true,
    product_state: true,
    relative_file: false,
    amount: true,
  };

  // 관리자에서 사용할 변수
  const isAdmin = false;
  const [comment, setComment] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);

  useEffect(async () => {
    if (idx == "self") {
      const company = await AccountAPI.getAccountInfo().then((res) => {
        setCompanyInfo(res.data);
        return res.data;
      });
      await InvestmentAPI.getCompanyInvestmentInfo().then((res) => {
        let local_data = res.data;
        let product_idx_arr = [];

        if (window.$Global.isEmptyObject(local_data)) {
          let initData = {
            business_model_desc: "",
            comment: "",
            company_desc: "",
            company_phone: "",
            core_tech_desc: "",
            created_at: 0,
            idx: "",
            in_charge_phone: "",
            investor: [],
            license_number: company.licence_number,
            product: [],
            representative: company.representative,
            req_amount: "",
            state: 0,
          };
          setHistorySaveInvestment(initData);
          setInvestment(initData);
          setIsNew(true);
          setEditing(true);
          return;
        }

        for (let i = 0; i < local_data.product.length; i++) {
          for (let j = 0; j < local_data.product[i].patent.length; j++) {
            product_idx_arr.push(local_data.product[i].patent[j].idx);
          }
        }
        let investor_idx_arr = local_data.investor.map((item) => item.idx);

        local_data.patent_list = product_idx_arr;
        local_data.investor_list = investor_idx_arr;

        setInvestment(local_data);
        setHistorySaveInvestment(local_data);
      });
    } else {
      if (isAdmin) {
        // 관리자일 때
        AdminAPI.getRequestInvestmentView(idx).then((res) => {
          setInvestment(res.data);
          setComment(res.data.comment);
        });
      } else {
        // 투자자일 때
        InvestorAPI.getRequestInvestmentView(idx).then((res) => {
          setInvestment(res.data);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!window.$Global.isEmptyObject(investment)) {
      drawPatentInfoTable();
    }
  }, [investment, editing]);

  const drawPatentInfoTable = () => {
    let tags = [];

    for (let i = 0; i < investment.product.length; i++) {
      let data = investment.product[i];
      let first_patent = data.patent.length ? data.patent[0] : {};
      let sales = data.sales;
      let rowSpan = data.patent.length;

      tags.push(
        <TableRow
          key={i}
          editing={false}
          rowSpan={rowSpan || 1}
          emptyInfo={window.$Global.isEmptyObject(first_patent)}
          number={i + 1}
          nation_string={first_patent.nation_string}
          apply_number={first_patent.apply_number}
          patent_idx={first_patent.idx}
          product_idx={data.idx}
          name={data.name}
          state={first_patent.state}
          invention_name={first_patent.invention_name}
          product_state={first_patent.product_state}
          cur_year={new Date().getFullYear()}
          sales={sales}
          register_number={first_patent.register_number}
          afterShow={true}
          columnShow={columnShow}
          index={i}
          deleteEditing={editing}
          onClickDelete={onClickPatentInfoDelete}
        />
      );

      for (let j = 1; j < rowSpan; j++) {
        let item = data.patent[j];

        tags.push(
          <TableRow
            key={i + "-" + j}
            editing={false}
            rowSpan={rowSpan || 1}
            number={j + 1}
            nation_string={item.nation_string}
            apply_number={item.apply_number}
            patent_idx={item.idx}
            product_idx={data.idx}
            state={item.state}
            invention_name={item.invention_name}
            product_state={item.product_state}
            sales={sales}
            register_number={item.register_number}
            afterShow={false}
            columnShow={columnShow}
            index={i}
            deleteEditing={editing}
            onClickDelete={onClickPatentInfoDelete}
          />
        );
      }
    }
    setTbody(tags);
  };

  const onClickPatentInfoDelete = (idx) => {
    var copyArr = investment.product.slice(0);
    copyArr.splice(idx, 1);
    setInvestment({ ...investment, product: copyArr });
  };

  const onClickInvestDelete = (idx) => {
    var copyArr = investment.investor.slice(0);
    copyArr.splice(idx, 1);
    setInvestment({ ...investment, investor: copyArr });
  };

  const onClickSave = (type) => {
    let product_idx_arr = [];

    for (let i = 0; i < investment.product.length; i++) {
      for (let j = 0; j < investment.product[i].patent.length; j++) {
        product_idx_arr.push(investment.product[i].patent[j].idx);
      }
    }

    let investor_idx_arr = investment.investor.map((item) => item.idx);

    let copy = _.cloneDeep(investment);
    copy.patent_list = product_idx_arr;
    copy.investor_list = investor_idx_arr;

    if (!copy.req_amount.length) {
      alert("투자 요청 금액을 입력해주세요");
      return;
    }

    if (!copy.company_phone.length) {
      alert("회사 전화번호를 입력해주세요");
      return;
    }

    if (!copy.in_charge_phone.length) {
      alert("담당자 전화번호를 입력해주세요");
      return;
    }

    if (!copy.core_tech_desc.length) {
      alert("핵심 특허기술을 입력해주세요");
      return;
    }

    if (!copy.business_model_desc.length) {
      alert("비지니스 모델을 입력해주세요");
      return;
    }

    if (!copy.company_desc.length) {
      alert("회사소개를 입력해주세요");
      return;
    }
    if (type == "submit") {
      copy.state = 1;
    }

    InvestmentAPI.updateInvestment(copy).then((res) => {
      setEditing(false);
      setInvestment(copy);
      window.$Global.saveToast();
    });
  };

  const onClickCancle = () => {
    setInvestment(historySaveInvestment);
    setEditing(false);
  };

  const onChangeInput = (e, key) => {
    const copiedObj = _.cloneDeep(investment);
    copiedObj[key] = e.target.value;
    setInvestment(copiedObj);
  };

  const onClickDeleteFileInDownload = async (idx) => {
    switch (popupProp.type) {
      case "introduce":
        await InvestmentAPI.deleteIntroduceFile(idx);
        break;
      case "core":
        await InvestmentAPI.deleteCoreFile(idx);
        break;
      case "business":
        await InvestmentAPI.deleteBusinessFile(idx);
        break;
    }
    alert("삭제되었습니다");
    setFileViewList(
      fileViewList.filter((file) => {
        return file.idx !== idx;
      })
    );
  };

  const onClickDownPopShow = async (type, idx) => {
    if (type == "investor" || isInvestor || isAdmin) {
      setPopupProp({ ...popupProp, type: type, investor_idx: idx });
      setDeleteHide(true);
    } else {
      setPopupProp({ ...popupProp, type: type });
      setDeleteHide(false);
    }

    let res = [];

    switch (type) {
      case "introduce":
        if (isInvestor) {
          res = await InvestorAPI.getIntroduceFileList(investment.idx);
        } else if (isAdmin) {
          res = await AdminAPI.getIntroduceFileList(investment.idx);
        } else {
          res = await InvestmentAPI.getIntroduceFileList();
        }
        break;
      case "core":
        if (isInvestor) {
          res = await InvestorAPI.getCoreFileList(investment.idx);
        } else if (isAdmin) {
          res = await AdminAPI.getCoreFileList(investment.idx);
        } else {
          res = await InvestmentAPI.getCoreFileList();
        }
        break;
      case "business":
        if (isInvestor) {
          res = await InvestorAPI.getBusinessFileList(investment.idx);
        } else if (isAdmin) {
          res = await AdminAPI.getBusinessFileList(investment.idx);
        } else {
          res = await InvestmentAPI.getBusinessFileList();
        }
        break;
      case "investor":
        if (isAdmin) {
          res = await AdminAPI.getInvestorFileList(idx);
        } else if (!isInvestor) {
          res = await InvestmentAPI.getInvestorFileList(idx);
        }
        break;
    }
    setDownPopupShow(true);
    setFileViewList(res.data);
  };

  const onClickUpPopShow = (type) => {
    setPopupProp({ ...popupProp, type: type });
    setUpPopupShow(true);
  };

  const onClickDownload = (idx) => {
    switch (popupProp.type) {
      case "introduce":
        if (isInvestor) {
          InvestorAPI.downloadIntroduceFile(investment.idx, idx);
        } else if (isAdmin) {
          AdminAPI.downloadIntroduceFile(idx);
        } else {
          InvestmentAPI.downloadIntroduceFile(idx);
        }
        break;
      case "core":
        if (isInvestor) {
          InvestorAPI.downloadCoreFile(investment.idx, idx);
        } else if (isAdmin) {
          AdminAPI.downloadCoreFile(idx);
        } else {
          InvestmentAPI.downloadCoreFile(idx);
        }
        break;
      case "business":
        if (isInvestor) {
          InvestorAPI.downloadBusinessFile(investment.idx, idx);
        } else if (isAdmin) {
          AdminAPI.downloadBusinessFile(idx);
        } else {
          InvestmentAPI.downloadBusinessFile(idx);
        }
        break;
      case "investor":
        if (isAdmin) {
          AdminAPI.downloadInvestorFile(popupProp.investor_idx, idx);
        } else {
          InvestmentAPI.downloadInvestorFile(popupProp.investor_idx, idx);
        }
        break;
    }
  };

  const onClickUpload = async () => {
    var formData = new FormData();

    uploadFileViewList.forEach((item) => {
      formData.append("file", item.file);
    });

    switch (popupProp.type) {
      case "introduce":
        await InvestmentAPI.uploaIntroduceFile(formData);
        break;
      case "core":
        await InvestmentAPI.uploadCoreFile(formData);
        break;
      case "business":
        await InvestmentAPI.uploadBusinessFile(formData);
        break;
      default:
        alert("버그 발생");
        return;
    }
    alert("업로드되었습니다");
    setUpPopupShow(false);
    setUploadFileViewList([]);
  };

  const onClickRegisterPatentInfo = (arr) => {
    let copy = _.cloneDeep(investment);
    let concat_arr = copy.product.concat(arr);
    copy.product = window.$Global.deduplicationToJSON(concat_arr);
    setInvestment(copy);
    setPopupPatentInfoShow(false);
  };

  const onClickRegisterInvestor = (arr) => {
    let copy = _.cloneDeep(investment);
    let concat_arr = copy.investor.concat(arr);
    copy.investor = window.$Global.deduplicationToJSON(concat_arr);
    copy.investor = concat_arr;
    setInvestment(copy);
    setPopupInvestorListShow(false);
  };

  const onClickSubmit = () => {
    if (
      window.confirm("제출한 후에는 수정하실 수 없습니다\n제출하시겠습니까?")
    ) {
      onClickSave("submit");
    }
  };

  const onClickAdminConfirm = (state) => {
    let msg = state == 3 ? "승인" : "반려";

    if (!agreeCheck) {
      alert("투자 요청 확인에 동의해주세요");
      return;
    }

    if (window.confirm(`투자 요청을 ${msg}하시겠습니까?`)) {
      if (state == 2 && comment.length == 0) {
        alert("반려 사유를 적어주세요");
        return;
      }

      let payload = {
        state: state,
        comment: state == 2 ? comment : "",
      };

      AdminAPI.RequestInvestmentConfirm(idx, payload).then(() => {
        history.push("/admin/invest");
      });
    }
  };

  if (investment.investor) {
    investRow = investment.investor.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            {editing ? (
              <button
                className="btn_remove icon_badge_del"
                onClick={() => onClickInvestDelete(index)}
              />
            ) : (
              index + 1
            )}
          </td>
          <td>{window.$Global.convertInvestTypeToStr(item.type_detail)}</td>
          <td>{item.name}</td>
          <td>{item.intrest_field}</td>
          <td>
            <button
              className="icon_download"
              onClick={() => onClickDownPopShow("investor", item.idx)}
            />
          </td>
          <td
            className="btn_url"
            onClick={() => window.open(item.url, "_blank")}
          >
            바로가기
          </td>
        </tr>
      );
    });
  }

  let stateTag = [];

  if (investment.state == 1) {
    stateTag = <p className="state">승인대기중입니다</p>;
  } else if (investment.state == 2) {
    stateTag = <p className="state red">반려되었습니다</p>;
  } else if (investment.state == 3) {
    stateTag = <p className="state green">승인이 완료되었습니다</p>;
  }

  return (
    <div
      id="InvestmentRequestDetail"
      className={disableUpload || isInvestor || isAdmin ? "disable_upload" : ""}
    >
      <div className="header">
        <h2 className="title">투자 요청 신청서 {isNew && "작성"}</h2>
        <div className="content">
          <ul>
            <li>{companyInfo.name}</li>
            <li>투자 요청 금액 {investment.req_amount}</li>
          </ul>
          {(investment.state == 0 || investment.state == 2) && !isAdmin && (
            <div className="btns">
              {editing ? (
                <>
                  <button className="btn_save" onClick={onClickSave}>
                    {isNew ? "등록" : "저장"}
                  </button>
                  {!isNew && (
                    <button className="btn_cancle" onClick={onClickCancle}>
                      취소
                    </button>
                  )}
                </>
              ) : (
                <>
                  <button
                    className="btn_update"
                    onClick={() => setEditing(true)}
                  >
                    수정
                  </button>
                  <button className="btn_submit" onClick={onClickSubmit}>
                    제출하기
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        {!isInvestor && investment.state != 0 && stateTag}
      </div>
      <div className="field">
        <div className="rows">
          <NormalRow
            title="회사명"
            titleClassName="extra_bold"
            content={investment.company_name || companyInfo.name}
          />
          <div className="row no_data"></div>
          <InputRow
            title="투자 요청 금액"
            content={investment.req_amount}
            condition={editing}
            onChange={(e) => onChangeInput(e, "req_amount")}
          />
        </div>
        <div className="rows">
          <NormalRow
            title="대표자"
            content={investment.representative || companyInfo.representative}
          />
          <NormalRow
            title="사업자 등록 번호"
            content={investment.license_number || companyInfo.license_number}
          />
          <InputRow
            title="회사 전화번호"
            content={investment.company_phone || companyInfo.company_phone}
            condition={editing}
            onChange={(e) => onChangeInput(e, "company_phone")}
          />
        </div>
        <div className="rows">
          <div className="row no_data"></div>
          <div className="row no_data"></div>
          <InputRow
            title="담당자 전화번호"
            content={investment.in_charge_phone}
            condition={editing}
            onChange={(e) => onChangeInput(e, "in_charge_phone")}
          />
        </div>
      </div>
      <div className="field">
        <IntroRow
          title="핵심 특허기술"
          smallTitle="특허 기술 설명란"
          fileTitle="첨부파일"
          introDesc={investment.core_tech_desc}
          condition={editing}
          onChange={(e) => onChangeInput(e, "core_tech_desc")}
          autoFocus={true}
          onClickDownPopShow={() => onClickDownPopShow("core")}
          onClickUpPopShow={() => onClickUpPopShow("core")}
        />
        <div className="table_list">
          <div className="header">
            <h2 className="title">보유 특허 리스트</h2>
            {editing && (
              <button
                className="btn_add"
                onClick={() => setPopupPatentInfoShow(true)}
              >
                추가하기
              </button>
            )}
          </div>
          <div className="table-box-wrap not_align">
            <div className="wrap_list">
              <table>
                <thead>
                  <tr>
                    <th>순번</th>
                    <th>
                      제품/
                      <br />
                      서비스
                    </th>
                    <th>순번</th>
                    <th>국가</th>
                    <th>출원번호</th>
                    <th>
                      Smart5
                      <br />
                      등급
                    </th>
                    <th>
                      Now
                      <br />
                      사업성
                    </th>
                    <th>등록번호</th>
                    <th>현재상태</th>
                    <th>
                      발명의
                      <br />
                      명칭
                    </th>
                    <th>적용유무</th>
                    <th colSpan="5">매출액 (전년 - 추후 3년)</th>
                  </tr>
                </thead>
                <tbody>{tbody}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="field">
        <IntroRow
          title="비지니스 모델 소개"
          smallTitle="비지니스 모델 소개란 "
          fileTitle="사업계획서 첨부파일"
          introDesc={investment.business_model_desc}
          condition={editing}
          onChange={(e) => onChangeInput(e, "business_model_desc")}
          onClickDownPopShow={() => onClickDownPopShow("business")}
          onClickUpPopShow={() => onClickUpPopShow("business")}
        />
      </div>
      <div className="field">
        <IntroRow
          title="회사 소개"
          smallTitle="기업 소개"
          fileTitle="회사 소개자료 보기"
          introDesc={investment.company_desc}
          condition={editing}
          onChange={(e) => onChangeInput(e, "company_desc")}
          onClickDownPopShow={() => onClickDownPopShow("introduce")}
          onClickUpPopShow={() => onClickUpPopShow("introduce")}
        />

        {window.$Global.checkAuth("특허회사", auth) && (
          <div className="table_list">
            <div className="header">
              <h2 className="title">노출 투자자 설정</h2>
              {editing && (
                <button
                  className="btn_add"
                  onClick={() => setPopupInvestorListShow(true)}
                >
                  추가하기
                </button>
              )}
            </div>
            <table>
              <thead>
                <tr>
                  <th width="5%">순번</th>
                  <th>투자자 유형</th>
                  <th>투자자 명칭</th>
                  <th width="40%">관심 분야</th>
                  <th>회사 소개 자료</th>
                  <th>홈페이지 바로가기</th>
                </tr>
              </thead>
              <tbody>{investRow}</tbody>
            </table>
          </div>
        )}
      </div>
      {!isAdmin && !isInvestor && investment.state == 2 && (
        <div
          className={`wrap_comment ${investment.state == 2 ? "" : "reject"}`}
        >
          <h2 className="title">{investment.state == 2 ? "반려" : ""} 사유</h2>
          <div className="comment">{investment.comment}</div>
        </div>
      )}
      {isAdmin && (
        <>
          <div className="wrap_comment">
            <h2 className="title">반려 사유</h2>
            <textarea
              className="comment"
              defaultValue={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <div className="wrap_agree">
            <p>투자요청에 관한 모든 것을 확인하고 처리합니다.</p>
            <CheckBox
              checked={agreeCheck}
              onChangeChecked={() => setAgreeCheck(!agreeCheck)}
            />
          </div>

          <div className="wrap_btns">
            <button
              className="btn_approval"
              onClick={() => onClickAdminConfirm(3)}
            >
              승인하기
            </button>
            <button
              className="btn_unable"
              onClick={() => onClickAdminConfirm(2)}
            >
              반려
            </button>
          </div>
        </>
      )}
      <PopupInvestorList
        idx={idx}
        show={popupInvestorListShow}
        onClickRegister={onClickRegisterInvestor}
        onClickClose={() => setPopupInvestorListShow(false)}
      />
      <PopupPatentInfo
        idx={idx}
        show={popupPatentInfoShow}
        onClickRegister={onClickRegisterPatentInfo}
        onClickClose={() => setPopupPatentInfoShow(false)}
      />

      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
        deleteHide={deleteHide}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
      />
    </div>
  );
}

export default InvestmentRequestDetail;
