import "../css/Box.scss";

const Box = ({ title, comp }) => {
  return (
    <div id="Box">
      <strong className="tit">{title}</strong>
      <div className="content">{comp}</div>
    </div>
  );
};

export default Box;
