import React from "react";
import SelectBox from "../../ui/selectBox/SelectBox";
import SharedStyles from '../../../css/sales/shared.module.scss';

const DepositeHeader = ({ universitiesState, updateFilterHandler, depositesState, filterValues, onAdd }) => {
  return (
    <header className={`${SharedStyles["deposite-header"]}`}>
      <div className={`${SharedStyles["selectboxes-div"]}`}>
        <SelectBox
          selectedOption={filterValues.university}
          name="university"
          isDisabled={universitiesState.state === "LOADING"}
          options={universitiesState.universities ?? []}
          onChange={updateFilterHandler}
        />
        <SelectBox
          selectedOption={filterValues.year}
          name="year"
          isDisabled={!filterValues.university.trueValue}
          options={[{ name: "2023년" }, { name: "2024년" }, { name: "2025년" }]}
          onChange={updateFilterHandler}
        />
        <SelectBox
          selectedOption={filterValues.month}
          name="month"
          isDisabled={!filterValues.year.trueValue}
          options={Array.from({ length: 12 }, (_, index) => ({ name: `${index + 1}월` }))}
          onChange={updateFilterHandler}
        />
      </div>

      <div>
        <p>매출 : {window.$Global.numberWithCommas(depositesState.sum?.total_amount ?? 0)}</p>
        <p>수금: {window.$Global.numberWithCommas(depositesState.sum?.deposit_amount ?? 0)}</p>
        <p>미수금: {window.$Global.numberWithCommas(depositesState.sum?.non_deposit_amount ?? 0)}</p>
      </div>

      <button disabled={!filterValues.university.trueValue} type="button" onClick={onAdd}>
        수금액 추가하기
      </button>
    </header>
  );
};

export default DepositeHeader;
