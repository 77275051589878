import React, {useState} from "react";
import "./css/PopupTrade.scss";

function PopupTrade({trader, value, showType, onClickSuccess, onClickReject, onClickClose}) {
    const [price, setPrice] = useState('');
    const defaultPrice = window.$Global.commaify(value) + '원';

    const numberRegx = (str) => {
        setPrice(window.$Global.numberRegx(str));
    }

    return (
        <div id="PopupTrade">
            <div className="popup">
                {
                    showType == 0
                    &&
                    <>
                        <h2 className="pop_title">거래 진행하기</h2>
                        <p className="price">{defaultPrice}</p>
                        <div className="btns">
                            <button className="btn_request" onClick={onClickSuccess}>거래 요청</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </>
                }
                {
                    showType == 1
                    &&
                    <>
                        <h2 className="pop_title">완료되었습니다</h2>
                        <p className="desc">판매자에게 요청을 보냈습니다</p>
                        <button className="btn_confirm" onClick={onClickClose}>확인</button>
                    </>
                }
                {
                    showType == 2
                    &&
                    <>
                        <h2 className="pop_title">거래 제안하기</h2>
                        <input type="text"
                               value={window.$Global.commaify(price)}
                               onChange={e => numberRegx(e.target.value)}
                               placeholder="거래 제안 금액을 입력해주세요"/>
                        <p className="alert">가격 제안은 1번만 가능합니다</p>
                        <div className="btns">
                            <button className="btn_request" onClick={() => onClickSuccess(price)}>제안</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </>
                }
                {
                    showType == 3
                    &&
                    <>
                        <h2 className="pop_title">거래 역제안하기</h2>
                        <p className="request">제안금액 {defaultPrice}</p>
                        <input type="text"
                               value={window.$Global.commaify(price)}
                               onChange={e => numberRegx(e.target.value)}
                               placeholder="역제안 금액을 입력해주세요"/>
                        <div className="btns">
                            <button className="btn_request" onClick={onClickSuccess}>제안</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </>
                }
                {
                    showType == 4
                    &&
                    <>
                        <h2 className="pop_title">받은 거래 제안</h2>
                        <p className="name">{trader}</p>
                        <p className="sell_price">{defaultPrice}</p>
                        <p className="alert_notify">진행 시 다른 거래요청은 자동거절 됩니다.</p>
                        <div className="btns">
                            <button className="btn_request" onClick={onClickSuccess}>진행</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </>
                }
                {
                    showType == 5
                    &&
                    <div className="wrap_success">
                        <h2 className="pop_title">거래가 성립되었습니다</h2>
                        <button className="btn_confirm" onClick={onClickClose}>확인</button>
                    </div>
                }
                {
                    showType == 6
                    &&
                    <>
                        <h2 className="pop_title">거절되었습니다</h2>
                        <p className="desc">받은 요청리스트에서 삭제됩니다.</p>
                        <button className="btn_confirm" onClick={onClickClose}>확인</button>
                    </>
                }
                {
                    showType == 7
                    &&
                    <>
                        <h2 className="pop_title">내가 받은 거래 제안</h2>
                        <button className="icon_exit" onClick={onClickClose}/>
                        <p className="name">{trader}</p>
                        <p className="sell_price">{defaultPrice}</p>
                        <p className="alert_notify">진행 시 다른 거래요청은 자동거절 됩니다.</p>
                        <div className="btns">
                            <button className="btn_request" onClick={onClickSuccess}>진행</button>
                            <button className="btn_cancel" onClick={onClickReject}>거절</button>
                        </div>
                    </>
                }
                {
                    showType == 8
                    &&
                    <>
                        <h2 className="pop_title">거래 역제안하기</h2>
                        <p className="alert">제안 금액 {defaultPrice}</p>
                        <input type="text"
                               value={window.$Global.commaify(price)}
                               onChange={e => numberRegx(e.target.value)}
                               placeholder="거래 제안 금액을 입력해주세요"/>
                        <div className="btns">
                            <button className="btn_request" onClick={() => onClickSuccess(price)}>제안</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </>
                }
                {
                    showType == 9
                    &&
                    <>
                        <h2 className="pop_title">완료되었습니다</h2>
                        <p className="desc">구매자에게 제안을 보냈습니다</p>
                        <button className="btn_confirm" onClick={onClickClose}>확인</button>
                    </>
                }
                {
                    showType == 10
                    &&
                    <div className="wrap_success">
                        <h2 className="pop_title">삭제하시겠습니까?</h2>
                        <div className="btns">
                            <button className="btn_request" onClick={() => onClickSuccess(price)}>삭제</button>
                            <button className="btn_cancel" onClick={onClickClose}>취소</button>
                        </div>
                    </div>
                }
                {
                    showType == 11
                    &&
                    <>
                        <h2 className="pop_title">거래 제안</h2>
                        <button className="icon_exit" onClick={onClickClose}/>
                        <p className="name">판매자측 거래 역제안 금액</p>
                        <p className="sell_price">{defaultPrice}</p>
                        <p className="alert_notify">거절 후 가격 제안은 불가능 합니다.</p>
                        <div className="btns">
                            <button className="btn_request" onClick={onClickSuccess}>수락</button>
                            <button className="btn_cancel" onClick={onClickReject}>거절</button>
                        </div>
                    </>
                }
                {
                    showType == 12
                    &&
                    <div className="wrap_success">
                        <h2 className="pop_title">제안을 거절하셨습니다</h2>
                        <button className="btn_confirm" onClick={onClickClose}>확인</button>
                    </div>
                }
            </div>
            <div className="dimmed"/>
        </div>
    )
}

export default PopupTrade;