import React from "react";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

function DatePickerOaAndFileRow({
  title,
  titleClassName,
  index,
  data,
  dataKey,
  haveFile,
  onChangeDatePickerJson,
  onClickDownPopShow,
  onClickUpPopShow,
  dateBtnHide,
  withCheckpoint,
  onOpenReport
}) {
  return (
    <div className="row">
      <h2 className={`title ${titleClassName || ""}`}>
        {title}
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      <div className="btns">
        {dateBtnHide && (
          <span>{data ? window.$Global.convertDate(data) : "N/A"}</span>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          {dateBtnHide ? (
            ""
          ) : (
            <DatePickerCustom
              data={data}
              onChangeDatePicker={(date) =>
                onChangeDatePickerJson(index, dataKey, date)
              }
            />
          )}
          <button
            className={haveFile ? "icon_download_attached" : "icon_download"}
            onClick={onClickDownPopShow}
          />
          <button className="icon_upload" onClick={onClickUpPopShow} />
        </div>
      </div>
    </div>
  );
}

export default DatePickerOaAndFileRow;
