import BillingInputRow from "./BillingInputRow";
import AnnualPay from "../../../../API/annualPay";
import { useEffect, useState } from "react";

const BillingKRCost = ({
  sendData,
  setSendData,
  annualFeeData,
  handleChange,
}) => {
  const [moneySymbol, setMoneySymbol] = useState([]);

  useEffect(() => {
    AnnualPay.getAnnualPayCurrencySymbol({
      nation_code: annualFeeData.nation,
    }).then((res) => {
      setMoneySymbol(Object.values(res.data.money_symbol));
      console.log(Object.values(res.data.money_symbol));
    });
  }, []);

  return (
    <div>
      <BillingInputRow
        type="number"
        data={sendData.annual_pay_amount}
        name="annual_pay_amount"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="1"
        rowName="연차료 금액"
        widthOption="full"
      />
      <BillingInputRow
        type="number"
        data={sendData.agent_fee}
        name="agent_fee"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="2"
        rowName="IPNOW 수수료"
        widthOption="full"
      />
      <BillingInputRow
        type="number"
        data={sendData.surtax}
        name="surtax"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="3"
        rowName="수수료 부가세"
        widthOption="full"
      />
    </div>
  );
};

export default BillingKRCost;
