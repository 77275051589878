import React, { useEffect, useState } from "react";
import "./css/SideBanner.scss";
import { Link } from "react-router-dom";

const SideBanner = () => {
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isHome = window.location.pathname === "/";
  const [scrollY, setScrollY] = useState(0);
  const onClickChangeView = () => {
    window.$Global.toggleViewType();
  };

  const logit = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", logit);
    };
  });

  return (
    <div
      id="SideBanner"
      style={{
        top: scrollY > 200 ? (isViewTypeIpnow ? -100 : -160) : -50,
      }}
    >
      {isViewTypeIpnow ? (
        <>
          <img
            className="side_banner_item"
            onClick={onClickChangeView}
            src={
              require("../../assets/images/intro/banner-side1-ipnow.png")
                .default
            }
            alt="사이드 배너"
          />
          <img
            className="side_banner_item"
            src={
              require("../../assets/images/intro/banner-side2-ipnow.png")
                .default
            }
            alt="사이드 배너"
          />
        </>
      ) : (
        <>
          <img
            className="side_banner_item"
            onClick={onClickChangeView}
            src={require("../../assets/images/intro/banner-side1.png").default}
            alt="사이드 배너"
          />
          <Link to="/intro/price">
            <img
              className="side_banner_item"
              src={
                require("../../assets/images/intro/banner-side2.png").default
              }
              alt="사이드 배너"
            />
          </Link>
          <img
            className="side_banner_item"
            src={
              require("../../assets/images/intro/banner-side2-ipnow.png")
                .default
            }
            alt="사이드 배너"
          />
        </>
      )}
    </div>
  );
};

export default SideBanner;
