import React from "react";

function DateAndFileRow({
  rowClassName,
  title,
  titleClassName,
  date,
  haveFile,
  onClickDownPopShow,
  onClickUpPopShow,
  uploadBtnShow,
}) {
  return (
    <div className={`row ${rowClassName ? rowClassName : ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
        <i
          className="icon_info"
          data-content={window.$Global.getInfoMessage(title)}
        />
      </h2>
      <div className="btns">
        {<span>{date == null ? "N/A" : window.$Global.convertDate(date)}</span>}
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className={haveFile ? "icon_download_attached" : "icon_download"}
            onClick={onClickDownPopShow}
          />

          {uploadBtnShow ? (
            <button className="icon_upload" onClick={onClickUpPopShow} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default DateAndFileRow;
