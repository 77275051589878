import React, { useEffect, useState } from "react";
import "./css/ResearchTechMain.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import CommonAPI from "../../API/common";
import Pagination from "../common/Pagination";
import message from "../../const/message";
import convertCategory from "../marketInfo/js/convertCategory";

function ResearchTechMain() {
  const history = useHistory();
  const location = useLocation();
  const { category_idx1, category_idx2 } = qs.parse(location.search);
  const [category, setCategory] = useState([]);
  const [data, setData] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [selectTarget, setSelectTarget] = useState(null);
  const count = 100;

  useEffect(() => {
    CommonAPI.getMarketCategory().then((res) => setCategory(res.data));
    getList(curPage);
  }, []);

  const getList = (page) => {
    let payload = {
      page: page,
      count: count,
      category1_idx: category_idx1,
      category2_idx: category_idx2,
    };
    CommonAPI.getResearchList(payload).then((res) => {
      setData(res.data);
      setCurPage(page);
    });
  };

  const searchList = (page) => {
    let payload = {
      page: page,
      count: count,
      category1_idx: category_idx1,
      category2_idx: category_idx2,
      keyword: keyword,
    };
    CommonAPI.getResearchList(payload).then((res) => {
      setData(res.data);
      setCurPage(page);
      setSearchState(true);
    });
  };

  const onClickCancel = () => {
    setKeyword("");
    setSearchState(false);
    getList(1);
  };

  const changePage = (page) => {
    return searchState ? getList(page) : searchList(page);
  };

  const onClickNext = (selectTarget) => {
    history.push(
      `patent?idx=${selectTarget}&category_idx1=${category_idx1}&category_idx2=${category_idx2}`
    );
  };

  const getCategory = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    return result.b_category + ">" + result.s_category;
  };

  return (
    <div id="ResearchTechMain">
      <div className="tutorial-box pc">
        <div className="wrap_image">
          <img
            src={require("../../assets/images/common/icon-bulb.svg").default}
            alt="이미지"
          />
        </div>
        <div className="inner">
          <h2>선행기술 조사 작성하기</h2>
          <p>
            R&D 과제 제안서에 필수적인 선행기술 정보를 비즈내비에서 확인하고
            작성해 보세요.
            <br />
            KIPRIS 문장검색을 기반으로 한 유사도 상위 100건 특허와 최상위 5건
            특허의
            <br />
            동향 정보를 간편한 그래프와 텍스트로 제공해 드립니다.
          </p>
        </div>
      </div>

      <div className="wrap_category">
        <div className="category">
          <i className="icon_m_menu" />
          <p>{getCategory()}</p>
        </div>
        <Link to="category">
          <span>목록으로 돌아가기</span>
          <i className="icon_more_arrow_right" />
        </Link>
      </div>

      <div className="keyword_find pc">
        <h2>제목으로 검색</h2>
        <input
          type="text"
          placeholder="검색어를 입력하세요"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyUp={(e) => e.key === "Enter" && searchList(1)}
        />
        <button
          className="icon_search_gray ir_txt"
          onClick={() => searchList(1)}
        >
          검색
        </button>
        {searchState && (
          <button
            className="icon_del_small_with_bg ir_txt"
            onClick={onClickCancel}
          >
            검색 취소
          </button>
        )}
      </div>
      <table>
        <colgroup>
          <col width="5%" />
          <col width="55%" />
          <col width="35%" />
          <col width="5%" />
        </colgroup>
        <thead>
          <tr>
            <th>번호</th>
            <th>선행기술 조사명</th>
            <th>선행기술 키워드</th>
            <th>선택</th>
          </tr>
        </thead>
        <tbody>
          {data.items?.map((item, idx) => {
            return (
              <tr key={item.idx}>
                <td>{data.total_count - idx - (curPage - 1) * 20}</td>
                <td>{item.title}</td>
                <td>{item.keyword}</td>
                <td>
                  <button
                    className="icon_next_black"
                    onClick={() => onClickNext(item.idx)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Pagination curPage={curPage} lastNum={data.last} onClick={changePage} />
    </div>
  );
}

export default ResearchTechMain;
