import "../css/DashBoardTop.scss";

const DashBoardTop = ({ title }) => {
  return (
    <div id="DashBoardTop">
      <img
        src={require("../../../assets/images/common/logo_ipnow.svg").default}
        style={{ position: "relative", width: 84 }}
        alt="logo"
      />
      <div className="menu_tit">
        <strong>{title}</strong>
        {/* <div className="download">
          <button>
            <i className="icon_pdf2"></i>
          </button>
          <button>
            <i className="icon_excel"></i>
          </button>
          <button>
            <i className="icon_image"></i>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default DashBoardTop;
