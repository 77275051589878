import React from "react";
import DatePickerCustom from "../../datepicker/DatePickerCustom";

function DatePickerRow({
  title,
  titleClassName,
  rowClassName,
  conditions,
  data,
  dataClassName,
  onChangeDatePicker,
  maxDate,
}) {
  return (
    <div className="row">
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
      </h2>
      <div className="btns">
        {!conditions && (
          <span className={dataClassName ? dataClassName : ""}>
            {data > 0 ? window.$Global.convertDate(data) : "N/A"}{" "}
          </span>
        )}
        {conditions && (
          <DatePickerCustom
            data={data}
            onChangeDatePicker={onChangeDatePicker}
            maxDate={maxDate}
          />
        )}
      </div>
    </div>
  );
}

export default DatePickerRow;
