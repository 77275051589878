import React, {useContext, useRef} from "react";
import {Line} from "react-chartjs-2";
import CommonContext from "../../../store/Common";

function LineBizCnt({biz_cnt, customSize, width, height}) {
    const chartRef = useRef();
    const {isMobile} = useContext(CommonContext);
    biz_cnt = biz_cnt["7"];

    if (window.$Global.isEmptyObject(biz_cnt)) {
        let arr = [];
        let json = {};
        let date = new Date();

        while (arr.length < 7) {
            if (arr.length > 0) {
                date.setDate(date.getDate() - 1);
            }

            if (date.getDay() == 0) { // 일요일
                date.setDate(date.getDate() - 2);
            }

            if (date.getDay() == 6) { // 토요일
                date.setDate(date.getDate() - 1);
            }

            arr.push(
                {
                    [window.$Global.convertDate(date)] : {
                        total_count: 0,
                        organized_count: 0
                    }
                }
            );
        }
        arr.reverse();

        for (let i = 0; i < arr.length; i++) {
            let keys = Object.keys(arr[i]);
            let key = keys[0];
            json[key] = arr[i][key];
        }

        biz_cnt = json;
    }

    const biz_cnt_data = () => {
        let keys = Object.keys(biz_cnt);
        let day = keys.map(item => item.substr(8) + '일');

        return {
            labels: day,
            datasets: [
                {
                    label: '전체 사업 정보',
                    data: keys.map(item => biz_cnt[item].total_count),
                    backgroundColor: isMobile ? 'rgba(255, 255, 255, 0)' : 'rgb(131, 207, 255)',
                    borderColor: isMobile ? 'rgba(255, 255, 255, 0.4)' : 'rgba(131, 207, 255, 0.4)',
                    borderWidth: isMobile ? 4 : 6,
                    lineTension: 0,
                    fill: true,
                    order: 1
                },
                {
                    label: '정리된 공고',
                    data: keys.map(item => biz_cnt[item].organized_count != 0 ? biz_cnt[item].organized_count + 100 : 0),
                    backgroundColor: isMobile ? 'rgba(255, 255, 255, 0)' : 'rgb(45, 153, 255)',
                    borderColor: isMobile ? 'rgb(255, 255, 255)' : 'rgba(45, 153, 255, 0.2)',
                    borderWidth: isMobile ? 4 : 6,
                    lineTension: 0,
                    fill: true,
                    order: 0
                },
            ],
        };
    }

    let biz_cnt_keys = Object.keys(biz_cnt);
    let biz_cnt_max = window.$Global.calcGraphMaxNumber(biz_cnt_keys.map(key => biz_cnt[key].total_count + biz_cnt[key].organized_count)) || 200;

    if (biz_cnt_max < 100) {
        biz_cnt_max = 200;
    }

    const biz_cnt_data_options = {
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem) => window.$Global.commaify(tooltipItem.value) + "건"
            }
        },
        maintainAspectRatio: customSize ? false : true,
        responsive: customSize ? false : true,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            yAxes: [{
                ticks: {
                    display: true,
                    beginAtZero: true,
                    max : biz_cnt_max,
                    stepSize: biz_cnt_max / 4,
                    maxTicksLimit: 5,
                    fontColor: isMobile ? "#ffffff" : '#443018',
                    callback: value => Math.floor(value) + "건"
                },
                gridLines: {
                    display : true,
                    borderDash: [3],
                    drawBorder: !isMobile,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: isMobile ? "#ffffff" : '#e8e8e8',
                    zeroLineColor: 'rgba(255,255,255, 0.6)',
                    zeroLineWidth: 0,
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: isMobile ? "#ffffff" : '#443018',
                },
                gridLines: {
                    display : false,
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: isMobile ? '#ffffff' : "#443018",
                font: {
                    weight: 'bold'
                },
                formatter: function(value, a) {
                    if (isMobile) {
                        if (a.dataIndex % 2 == 1) {
                            return window.$Global.commaify(value);
                        } else {
                            return "";
                        }
                    } else {
                        return window.$Global.commaify(value);
                    }
                }
            }
        }
    };

    return (
        <Line
            data={biz_cnt_data}
            width={width || 465}
            height={height || 320}
            options={biz_cnt_data_options}
            ref={chartRef}
        />
    )
}

export default LineBizCnt;