import React, { useCallback, useContext, useEffect, useState } from "react";
import "./css/ResearchTechCategoryList.scss";
import CommonAPI from "../../API/common";
import _ from "lodash";
import CommonContext from "../../store/Common";
import { Link } from "react-router-dom";

function ResearchTechCategoryList({ history }) {
  const { isMobile } = useContext(CommonContext);
  const [historyCategory, setHistoryCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchState, setSearchState] = useState(false);
  const [relateKeywordList, setRelateKeywordList] = useState([]);

  const getRelateKeyword = async (value) => {
    const keywordList = value
      .replace(/ and | AND /g, "||")
      .replace(/ or | OR /g, "||")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .split("||");

    for (const k of keywordList) {
      if (k.toString().length < 2) return;
    }

    CommonAPI.getPatentRelateKeyword(value).then((res) => {
      setRelateKeywordList(res.data);
    });
  };

  const debounceHandler = useCallback(
    _.debounce(async (value) => {
      getRelateKeyword(value);
    }, 150),
    []
  );

  const onChangeSearchKeyword = (e) => {
    e.persist();
    const inputKeyword = e.target.value;
    setSearchKeyword(inputKeyword);

    if (inputKeyword.toString().length > 1) {
      debounceHandler(inputKeyword);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getResearchCategory().then((res) => {
      let data = res.data.category_list;
      setCategory(data);
      setHistoryCategory(data);
    });
  };

  const onClickView = (idx1, idx2) => {
    history.push(`list?category_idx1=${idx1}&category_idx2=${idx2}`);
  };

  const onClickSearch = () => {
    let copy = _.cloneDeep(historyCategory);
    let result = copy.filter((item) => {
      let child = item.child.filter((item2) => {
        if (item2.name.indexOf(keyword.replace(/(\s*)/g, "")) > -1) {
          return item2;
        }
      });

      item.child = child;
      if (child.length) return child;
    });
    setCategory(result);
    setSearchState(true);
  };

  const onClickKeywordSearch = () => {
    history.push(`patent?keyword=${searchKeyword}`);
  };

  const onClickRelateKeyword = (e) => {
    const val = e.target.innerText;

    setSearchKeyword(searchKeyword + " and " + val);
    debounceHandler(searchKeyword + " and " + val);
  };

  const onClickSearchCancel = () => {
    setKeyword("");
    setSearchState(false);
    setCategory(historyCategory);
  };

  return (
    <div id="ResearchTechCategoryList">
      <div className="tutorial-box pc">
        <div className="wrap_image">
          <img
            src={require("../../assets/images/common/icon-bulb.svg").default}
            alt="이미지"
          />
        </div>
        <div className="inner">
          <h2>선행기술 조사 작성하기</h2>
          <p>
            R&D 과제 제안서에 필수적인 선행기술 정보를 비즈내비에서 확인하고
            작성해 보세요.
            <br />
            KIPRIS 문장검색을 기반으로 한 유사도 상위 100건 특허와 최상위 5건
            특허의
            <br />
            동향 정보를 간편한 그래프와 텍스트로 제공해 드립니다.
          </p>
        </div>
      </div>
      <h2 className="title">키워드로 특허 검색하기</h2>
      <ul className={"relate_keyword_wrapper"}>
        {relateKeywordList
          .slice(0, 10)
          .map((v) => v.keyword)
          .map((item) => {
            return (
              <li className={"relate_keyword"} onClick={onClickRelateKeyword}>
                {item}
              </li>
            );
          })}
      </ul>
      <div className="head pc keyword">
        <div className="search">
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchKeyword}
            onChange={onChangeSearchKeyword}
            onKeyUp={(e) => e.key === "Enter" && onClickKeywordSearch()}
          />
          {searchState && (
            <button
              className="icon_del_small_with_bg"
              onClick={onClickSearchCancel}
            />
          )}
          <button
            className="icon_search_gray ir_txt"
            onClick={onClickKeywordSearch}
          >
            검색
          </button>
        </div>
        <div className="help">
          <p className="text">※키워드가 많을수록 정확하고 빠른 검색 지원</p>
          <p className="text green">ex) 로봇 and 인공지능(O)</p>
          <p className="text red">ex) 로봇(X)</p>
        </div>
      </div>
      <div className="head mo">
        <div className="search keyword">
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={searchKeyword}
            onChange={onChangeSearchKeyword}
            onKeyUp={(e) => e.key === "Enter" && onClickKeywordSearch()}
          />
          {searchState && (
            <button
              className="icon_del_small_with_bg"
              onClick={onClickSearchCancel}
            />
          )}
          <button
            className="icon_search_gray ir_txt"
            onClick={onClickKeywordSearch}
          >
            검색
          </button>
        </div>
      </div>

      <h2 className="title">분야별 Report 작성 분야 선택하기</h2>
      <div className="head pc">
        <div className="search">
          <input
            type="text"
            placeholder="검색어를 입력하세요"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyUp={(e) => e.key === "Enter" && onClickSearch()}
          />
          {searchState && (
            <button
              className="icon_del_small_with_bg"
              onClick={onClickSearchCancel}
            />
          )}
          <button className="icon_search_gray ir_txt" onClick={onClickSearch}>
            검색
          </button>
        </div>
        <div className="links">
          <Link to="my" className="btn_my">
            나의 선행기술
          </Link>
          {/*<Link to="request" className="btn_write">*/}
          {/*	<i className="icon_posting_pencil"/>*/}
          {/*	<span>선행기술조사 요청/span>*/}
          {/*</Link>*/}
        </div>
      </div>
      <div className="wrap_category">
        {category?.map((item) => {
          return (
            <div className="category">
              <div className="category_b_title">
                <i className="icon_m_menu" />
                <p>{item.name}</p>
              </div>
              <ul className="category_s_list">
                {item.child?.map((child) => {
                  let checkDataLength = !child.research_cnt;
                  return (
                    <li
                      className={checkDataLength ? "not_data" : ""}
                      onClick={() => onClickView(item.idx, child.idx)}
                    >
                      {checkDataLength &&
                        (isMobile ? (
                          <p>준비중</p>
                        ) : (
                          <img
                            className="pc"
                            src={
                              require("../../assets/images/marketInfo/icon_badge_ready.svg")
                                .default
                            }
                            alt="준비중 이미지"
                          />
                        ))}
                      {child.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ResearchTechCategoryList;
