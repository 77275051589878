import { useContext, useMemo, useState } from "react";
import TradeMarkContext from "../../store/TradeMark";
import "./css/NationListPopUp.scss";

const NationListPopUp = ({ nationListArr = [], setNationListArr, setIsNationPopupClick }) => {
  const tradeMark = useContext(TradeMarkContext);

  const [searchResult, setSearchResult] = useState([]);

  const regionsSearchList = useMemo(() => {
    const regionsList = [];
    nationListArr.forEach(({ name, nationList }) => {
      nationList.forEach((region) => {
        regionsList.push({
          id: Object.values(region)[0],
          name: Object.keys(region)[0],
          fullName: `(${name}) ${Object.keys(region)[0]} ${window.$Global.convertNationName(Object.values(region)[0])}`,
        });
      });
    });
    return regionsList;
  }, [nationListArr]);

  const searchHandler = (keyword) => {
    keyword
      ? setSearchResult(
        regionsSearchList.filter(({ fullName }) => fullName.toLowerCase().includes(keyword.trim().toLowerCase()))
      )
      : setSearchResult([]);
  };

  const selectRegion = (nationId, nationStr) => {
    tradeMark.setTradeMark({
      ...tradeMark,
      nation: nationId,
      nation_string: nationStr,
    });
    setIsNationPopupClick(false);
  };

  return (
    <div id="NationListPopUp">
      <header>
        <h2>지역 선택</h2>
        <div>
          <input type="search" name="region" onInput={(event) => searchHandler(event.target.value)} autoFocus />
          {!!searchResult.length && (
            <ul>
              {searchResult.map(({ id, fullName, name }) => (
                <li key={id} onClick={() => selectRegion(id, name)}>
                  {fullName}
                </li>
              ))}
            </ul>
          )}
        </div>
        <i className="icon_exit" onClick={() => setIsNationPopupClick(false)} />
      </header>

      <div className="nation_list_wrapper">
        <ul>
          {nationListArr?.map((item) => {
            return (
              <li
                key={item.name}
                className="nation_list_first"
                onClick={() => {
                  item.isOpen = !item.isOpen;
                  setNationListArr([...nationListArr]);
                }}
              >
                <p>
                  <i className={item.isOpen ? "icon_arrow_down_gray" : "icon_arrow_right_s"} />
                  <span>{item.name}</span>
                  {window.$Global.convertCountryName(item.name)}
                </p>
                {item.isOpen && (
                  <ul>
                    {item.nationList?.map((subItem) => {
                      return (
                        <li
                          key={Object.values(subItem)[0]}
                          className="nation_list_second"
                          onClick={() => {
                            selectRegion(Object.values(subItem)[0], Object.keys(subItem)[0]);
                          }}
                        >
                          <p>
                            <i className="icon_arrow_right_s" />
                            <span>{Object.keys(subItem)[0]}</span>

                            {window.$Global.convertNationName(Object.values(subItem))}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default NationListPopUp;
