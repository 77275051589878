import { useEffect, useState } from "react";
import TradeMarkAPI from "../../API/trademark";
import "./css/TradeMarkEventPopup.scss";
import { useHistory } from "react-router-dom";

const TradeMarkEventPopup = ({
  setEventPopupShow,
  data,
  setData,
  setSaveData,
  eventPopupType,
}) => {
  const history = useHistory();
  const [eventList, setEventList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (Boolean(searchInput)) getEvent();
  }, [searchInput]);

  const getEvent = () => {
    TradeMarkAPI.getIdxFromManageNumber({ search: searchInput }).then((res) => {
      setEventList(res.data);
    });
  };

  const onClickNavigate = (idx) => {
    if (idx) {
      window.open(
        `${window.location.origin}/tradeMark/detail/${idx}`,
        "_blank"
      );
      // history.push(`/tradeMark/detail/${idx}`);
      setEventPopupShow(false);
    } else {
      alert("이동할 수 없는 상표 입니다.");
      return;
    }
  };

  const onClickAdd = (item) => {
    if (item.idx) {
      if (eventPopupType === "invest") {
        setData({ ...data, invest_manage_number: item.manage_number });
        setEventPopupShow(false);
        return;
      }

      TradeMarkAPI.getTradeMarkInfo(item.idx).then((res) => {
        if (eventPopupType === "overseas") {
          setData({
            ...data,
            international_manage_number: res.data.manage_number,
            international_regist_number: res.data.regist_number,
            international_apply_at: res.data.apply_at,
          });
        } else if (eventPopupType === "madrid") {
          setData({ ...data, basic_manage_number: res.data.manage_number });
          setSaveData({
            basic_apply_number: res.data.basic_apply_number,
            basic_apply_at: res.data.basic_apply_at,
            basic_regist_number: res.data.regist_number,
            basic_regist_at: res.data.basic_regist_at,
          });
        }
        setEventPopupShow(false);
      });
    } else {
      alert("존재하지 않는 상표입니다.");
    }
  };

  return (
    <div id="TradeMarkEventPopup">
      <header>
        <p>관련 사건번호 찾기</p>
        <i
          className="icon_exit_gray"
          onClick={() => setEventPopupShow(false)}
        />
      </header>
      <section>
        <div className="search_wrapper">
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder="관리번호를 입력하세요."
          />
          <i className="icon_search_gray" />
        </div>
        <div className="table_wrapper">
          <table className="event_table">
            <thead>
              <tr>
                <th>No.</th>
                <th>관리 번호</th>
                <th>해당 상표로 이동</th>
                <th>관리번호 등록</th>
              </tr>
            </thead>
            <tbody className="custom_scroll">
              {eventList?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item.manage_number}</td>
                    <td>
                      <button
                        className="btn_go"
                        onClick={() => onClickNavigate(item.idx)}
                      >
                        이동
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn_go"
                        onClick={() => onClickAdd(item)}
                      >
                        등록
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default TradeMarkEventPopup;
