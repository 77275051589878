import React, { useEffect, useState } from "react";
import ConsultingAPI from "../../API/consulting";
import Pagination from "../common/Pagination";
import "./css/ConsultComMain.scss";
import { Link } from "react-router-dom";

function ConsultComMain() {
  const [curPage, setCurPage] = useState(1);
  const [resData, setResData] = useState({ items: [] });

  useEffect(() => {
    let params = {
      page: curPage,
      count: 10,
    };

    ConsultingAPI.getList(params).then((res) => setResData(res.data));
  }, [curPage]);

  return (
    <div id="consultComMain">
      {/*<div className="pc">*/}
      <div className="consultComMain">
        <section className="consultComMain_box-wrap">
          <div className="consultComMain_box">
            <div className="consultComMain_box_title">컨설팅 기업 보기</div>
            <p className="consultComMain_box_sub">
              정부 &middot; 공공기관 &middot; 지자체 사업, 과제 공고를 지원,
              수행할 때 필요한 컨설팅 기업을 여기서 찾아보세요.
            </p>
          </div>
          {/* <button type="button" className="consultComMain_box_btn">
                            튜토리얼 바로가기
                        </button> */}
        </section>
        <section className="consultComMain_content">
          <div className="consultComMain_content_title">
            <h2>컨설팅 기업 보기</h2>
          </div>
          <ul className="consultComMain_content_list_wrap">
            {resData.items.map((item) => {
              return (
                <li className="consultComMain_content_list">
                  <div className="consultComMain_content_list_img">
                    <Link to={`/consultCom/detail/${item.idx}`}>
                      <img
                        src={window.$Global.getCDN(item.img_key)}
                        alt={item.title + "로고"}
                      />
                    </Link>
                  </div>
                  <div className="consultComMain_content_list_text">
                    <h3 className="consultComMain_content_list_text_title">
                      {item.title}
                    </h3>
                    <div className="table_list">
                      <p className="table_list_field">{item.field}</p>
                      <div className="table_list_item">
                        <p className="table_list_title">
                          주&nbsp;&nbsp;&nbsp;&nbsp;소
                        </p>
                        <p className="table_list_info">{item.address}</p>
                      </div>
                      <div className="table_list_item">
                        <p className="table_list_title">대표번호</p>
                        <p className="table_list_info">{item.phone}</p>
                      </div>
                      <div className="table_list_item">
                        <p className="table_list_title">이 메 일</p>
                        <p className="table_list_info">{item.email}</p>
                      </div>
                    </div>
                    <button
                      className="btnMore"
                      onClick={() => window.open(item.url, "_blank")}
                    >
                      홈페이지 바로가기
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="consultComMain_content_pagination-wrap">
            <Pagination
              curPage={curPage}
              lastNum={resData.last}
              onClick={setCurPage}
            />
          </div>
        </section>
      </div>
      {/*</div>*/}
    </div>
  );
}

export default ConsultComMain;
