import CommonAPI from "../../../API/common";

export default {
    convertCommunityCategory() {
        return  CommonAPI.getCommunityCategory().then(res => {
            let data = res.data;
            let keys = Object.keys(data);

            let arr = [];
            for (let idx in keys) {
                let str = "";
                if (keys[idx] == "news") {
                    str = "서비스 소식";
                } else if (keys[idx] == "media") {
                    str = "언론보도";
                } else if (keys[idx] == "now_love") {
                    str = "NOW 사랑방";
                }

                arr.push({
                    name: str,
                    value: data[keys[idx]]
                });
            }
            return arr;
        });
    },

    convertCommunityCategoryNo(category, num) {
        let result = category.find(item => item.value == num);
        return result?.name;
    },

    convertServiceCategory() {
        return CommonAPI.getCommunityCategoryVer2().then(res => {
            let data = res.data;
            let keys = Object.keys(data);

            let arr = [];
            for (let idx in keys) {
                let str = "";
                if (keys[idx] == "competition") {
                    str = "교육·행사·경진대회";
                } else if (keys[idx] == "briefing") {
                    str = "사업설명회";
                } else if (keys[idx] == "government_support") {
                    str = "정부지원 정책 알림";
                }

                arr.push({
                    name: str,
                    value: data[keys[idx]].value
                });
            }
            return arr;
        });
    },
};