import React, {useEffect, useState} from "react";
import ConsultingAPI from "../../API/consulting";
import "./css/ConsultComDetail.scss";
import {Link, useParams} from 'react-router-dom';
import CommonAttachPopup from "../common/CommonAttachPopup";

let { kakao } = window;
function ConsultComDetail() {
    const {idx} = useParams();
    const [info, setInfo] = useState({});
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        ConsultingAPI.getInfoUser(idx).then(res => {
            let data = res.data;
            setInfo(data);

            if (data.lat && data.lon) {
                let container = document.getElementById('map');
                let options = {
                    center: new kakao.maps.LatLng(data.lat, data.lon),
                    level: 3
                };
                let map = new kakao.maps.Map(container, options);
                // 마커를 생성합니다
                let marker = new kakao.maps.Marker({
                    // 마커가 표시될 위치입니다
                    position: options.center
                });

                // 마커가 지도 위에 표시되도록 설정합니다
                marker.setMap(map);
            }
        });
    }, []);

    const download = (file_idx) => {
        ConsultingAPI.download(idx, file_idx);
    };

    return (
        <div id="consultComDetail">
            <div className="pc">
                <div className="consultComDetail">
                    <h2 className="consultComDetail_title">컨설팅 기업 보기</h2>
                    <div className="consultComDetail_content">
                        <section className="consultComDetail_content_info">
                            <div className="consultComDetail_content_info_img">
                                <img src={window.$Global.getCDN(info.img_key)} alt={info.title + "로고"} />
                            </div>
                            <div className="consultComDetail_content_info_content">
                                <h4 className="consultComDetail_content_info_content_title">
                                    {info.title}
                                </h4>
                                <dl className="list">
                                    <dt className="list_title">
                                        기 업 명
                                    </dt>
                                    <dd className="list_info">
                                        {info.company_name}
                                    </dd>
                                    <dt className="list_title">
                                        분&nbsp;&nbsp;&nbsp;&nbsp;야
                                    </dt>
                                    <dd className="list_info">
                                        {info.field}
                                    </dd>
                                    <dt className="list_title">
                                        연 락 처
                                    </dt>
                                    <dd className="list_info">
                                        {info.phone}
                                    </dd>
                                    <dt className="list_title">
                                        주&nbsp;&nbsp;&nbsp;&nbsp;소
                                    </dt>
                                    <dd className="list_info">
                                        {info.address}
                                    </dd>
                                </dl>
                                <div className="consultComDetail_content_info_content_text-wrap">
                                    <p className="text">
                                        {info.description}
                                    </p>
                                    <div className="btn-wrap">
                                        <a href={info.url} target="_blank" className="copy">홈페이지 바로가기</a>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="consultComDetail_content_intro">
                            <div className="consultComDetail_content_intro_title-wrap">
                                <h5 className="consultComDetail_content_intro_title">
                                    회사 소개
                                </h5>
                                <div className="consultComDetail_content_intro_file">
                                    <label htmlFor="introFile" className="consultComDetail_content_intro_file_title">회사 소개 자료</label>
                                    <button type="button" className="consultComDetail_content_intro_file_btn" onClick={() => setShowPopup(true)}>회사 소개자료 다운받기</button>
                                </div>
                            </div>
                            <div className="consultComDetail_content_intro_section">
                                <div className="content">
                                    {info.introduce}
                                </div>
                            </div>
                        </section>
                        <section className="consultComDetail_content_character">
                            <h5 className="consultComDetail_content_character_title">강점분야 ·특징</h5>
                            <div className="consultComDetail_content_character_section">
                                <div className="content">
                                    {info.introduce_strength}
                                </div>
                            </div>
                        </section>
                        <section className="consultComDetail_content_map">
                            <h5 className="consultComDetail_content_map_title">장소안내</h5>
                            <div className="consultComDetail_content_map_section">
                                {
                                    info.lat && <div id="map"/>
                                }
                                <p className="consultComDetail_content_map_section_addr">
                                    {info.address}
                                </p>
                            </div>
                        </section>
                        <div className="consultComDetail_content_btn-wrap">
                            <Link to="/consultCom/list" className="btn_list">목&nbsp;&nbsp;록</Link>
                        </div>
                    </div>
                </div>
            </div>

            <CommonAttachPopup list={info.introduce_file || []} show={showPopup} onClickDownload={download} onClickClose={() => setShowPopup(false)}/>
        </div>
    )
}

export default ConsultComDetail;