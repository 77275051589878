import React from "react";
import ApplyField from "./common/ApplyField";
import DefaultKRField from "./common/DefaultKRField";
import BankField from "./common/BankField";
import ClaimFieldAsDesign from "./common/ClaimFieldAsDesign";
import CostFieldAsDesign from "./common/CostFieldAsDesign";

function CostDetailDesignKR({edit}) {
    return (
        <div className="form">
            <DefaultKRField edit={edit}/>
            <ApplyField/>
            <ClaimFieldAsDesign edit={edit}/>
            <CostFieldAsDesign edit={edit}/>
            <BankField edit={edit}/>
        </div>
    )
}

export default CostDetailDesignKR;
