import React, { useContext } from "react";
import "./css/MobileBottomGNB.scss";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";
import links from "../../const/links";
import MemberContext from "../../store/Member";

function MobileBottomGNB() {
  const isViewTypeIpnow = window.$Global.isIpnow();
  const { isMobile } = useContext(CommonContext);
  const { idx } = useContext(MemberContext);
  const pathname = window.location.pathname;
  const homeURL = pathname === links.home || pathname === links.intro.main;
  const serviceURL = pathname === links.mobile.service;
  const moreURL = pathname === links.mobile.more;

  if (isViewTypeIpnow || !isMobile) return null;

  return (
    <div id="MobileBottomGNB">
      {!idx && (
        <Link to="/signup/type" className="link_signup">
          <p>
            비즈내비, 지금 바로 <span>무료 회원 가입</span> 하기!
          </p>
          <i className="icon_more_arrow_right_white" />
        </Link>
      )}
      <ul>
        <li>
          <Link to={links.mobile.service}>
            <div className={`wrap_icon ${serviceURL ? "on" : ""}`}>
              <i className={`icon_service`} />
              <i className="icon_service_start" />
            </div>
            <p>수주 받기</p>
          </Link>
        </li>
        <li>
          <Link to="/">
            <i className={`icon_home ${homeURL ? "on" : ""}`} />
            <p>HOME</p>
          </Link>
        </li>
        <li>
          <Link to={links.mobile.more}>
            <i className={`icon_more ${moreURL ? "on" : ""}`} />
            <p>더보기</p>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default MobileBottomGNB;
