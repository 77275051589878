import React, { useState } from "react";
import "./css/MailTypePop.scss";
import ResponseStatus from "./ResponseStatus";

function MailTypePop({ setShowMailTypePop, setMailType, setShowMail }) {
  const mailTypes = [
    {
      idx: "0",
      title: "연차료 납부 검토",
    },
    {
      idx: "1",
      title: "연차료 납부 포기 · 양수",
    },
  ];
  const [selMailType, setSelMailType] = useState(mailTypes[0].idx);

  const handleChange = (e) => {
    setSelMailType(e.target.value);
  };

  const writeMail = () => {
    setMailType(selMailType);
    setShowMail(true);
  };

  return (
    <div id="MailTypePop">
      <div className="title">
        <strong>이메일 양식 불러오기</strong>
      </div>
      <div className="cont">
        <ul>
          {mailTypes.map((type, index) => (
            <li key={type.idx}>
              <input
                type="radio"
                name="type"
                value={type.idx}
                onChange={handleChange}
                defaultChecked={index === 0}
              />
              <span>{type.title}</span>
            </li>
          ))}
        </ul>
        <div className="btns">
          <button onClick={() => setShowMailTypePop(false)}>닫기</button>
          <button
            className="bg"
            onClick={() => {
              writeMail();
              setShowMailTypePop(false);
            }}
          >
            작성하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default MailTypePop;
