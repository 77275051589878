import React, {useContext, useEffect, useState} from "react";
import BillingContext from "../../../../store/Billing";

function ClaimFieldAsDesign({edit}) {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    const large_class_kr = ["출원", "중간", "등록", "심판", "기타"];
    const large_class_for = ["출원 전", "출원", "중간", "등록", "기타"];
    const large_class_pct = ["출원 전", "출원"];

    const [largeClass, setLargeClass] = useState([]);
    const [middleClass, setMiddleClass] = useState([]);
    const [baseType, setBaseType] = useState(0);

    const middle_class_kr = {
        출원: ["심사청구"],
        중간: ["자진보정", "기간연장", "중간사건(OA대응)"],
        등록: ["등록"],
        심판: ["심판청구", "심판중간(의견서 제출)", "심판중간(답변서 제출)", "심판성사"],
        기타: ["연차료 관리", "명의변경 및 실시권설정등록", "정보변경", "기타 분석 업무", "그외업무(기타)"]
    };
    const middle_class_for = {
        출원전: ["선행기술조사"],
        출원: ["출원(개별국 출원)", "심사청구", "우선심사청구", "계속출원", "분할출원", "Reissue", "EP 개별국 진입"],
        중간: ["OA", "출원유지료(EP, CA, AU 등)", "IDS(US)", "자진보정"],
        등록: ["등록비용"],
        기타: ["기타비용(정보변경, 통지서 송부 등)", "기타비용(클레임차트 작성, 분석비용)"]
    };
    const middle_class_pct = {
        출원전: ["선행기술조사"],
        출원: ["출원(국제단계)", "출원(국내단계)"],
    };

    useEffect(() => {
        if (billObj.nation == 0) {
            setLargeClass(large_class_kr);
            setMiddleClass(middle_class_kr);
        } else if (billObj.nation == 1) {
            setLargeClass(large_class_pct);
            setMiddleClass(middle_class_pct);
        } else {
            setLargeClass(large_class_for);
            setMiddleClass(middle_class_for);
        }
    }, []);

    useEffect(() => {
        let type = 0;

        if (billObj.nation == 0) {
            type = 1;
            if (billObj.type > 6 && billObj.type < 11) {
                type = 8;
            } else if (billObj.type == 11) {
                type = 11;
            } else if (billObj.type > 11 && billObj.type < 16) {
                type = 12;
            } else if (billObj.type > 15 && billObj.type < 21) {
                type = 16;
            }
        } else if (billObj.nation == 1) {
            type = 36;
            if (billObj.type == 36) {
                type = 36;
            } else if (billObj.type > 36 && billObj.type < 39) {
                type = 37;
            }
        } else {
            type = 22;
            if (billObj.type == 21) {
                type = 21;
            } else if (billObj.type > 21 && billObj.type < 29) {
                type = 22;
            } else if (billObj.type > 28 && billObj.type < 32) {
                type = 29;
            } else if (billObj.type == 33) {
                type = 33;
            } else if (billObj.type > 33 && billObj.type < 36) {
                type = 34;
            }
        }
        if (!billObj.idx) {
            billing.func.onChangeInput(type, "type", billing);
        }
        setBaseType(type);
    }, []);

    const getType = (type) => {
        if (type > -1 && type < 7) {
            return "출원";
        } else if (type > 6 && type < 11) {
            return "중간";
        } else if (type == 11) {
            return "등록";
        } else if (type > 11 && type < 16) {
            return "심판";
        } else if (type > 15 && type < 21) {
            return "기타";
        } else if (type == 21) {
            return "출원전";
        } else if (type > 21 && type < 29) {
            return "출원";
        } else if (type > 28 && type < 33) {
            return "중간";
        } else if (type == 33) {
            return "등록";
        } else if (type > 33 && type < 36) {
            return "기타";
        } else if (type == 36) {
            return "출원전";
        } else if (type > 36 && type < 39) {
            return "출원";
        }
    };

    const onChangeLarge = (e) => {
        let keys = Object.keys(middleClass);
        let selected_text = keys[e.target.selectedIndex].replace(" ", "");
        let type = 0;

        if (billObj.nation == 0) {
            switch (selected_text) {
                case "출원":
                    type = 2;
                    break;
                case "중간":
                    type = 8;
                    break;
                case "등록":
                    type = 11;
                    break;
                case "심판":
                    type = 12;
                    break;
                case "기타":
                    type = 16;
                    break;
            }
        } else if (billObj.nation > 1) {
            switch (selected_text) {
                case "출원전":
                    type = 21;
                    break;
                case "출원":
                    type = 22;
                    break;
                case "중간":
                    type = 29;
                    break;
                case "등록":
                    type = 33;
                    break;
                case "기타":
                    type = 34;
                    break;
            }
        } else {
            switch (selected_text) {
                case "출원전":
                    type = 36;
                    break;
                case "출원":
                    type = 37;
                    break;
            }
        }
        setBaseType(type);
        initInput(type);
    };

    const onChangeMiddle = (e) => {
        let type = baseType + e.target.selectedIndex;
        initInput(type);
    };

    const initInput = (type) => {
        let bill = {
            ...billObj,
            agent_commission: 0,
            basic_fee: 0,
            claim_amount: 0,
            commission_dc: 0,
            dependent_claim_cnt: 0,
            dependent_ec: 0,
            deposit_amount: 0,
            drawing_cnt: 0,
            drawing_ec: 0,
            ed_cnt: 0,
            etc_fee: 0,
            for_basic_fee: 0,
            for_drawing_fee: 0,
            for_etc_fee: 0,
            for_gc: 0,
            for_remittance_fee: 0,
            for_sum_commission: 0,
            for_sum_total: 0,
            for_surtax: 0,
            for_translate_fee: 0,
            gc: 0,
            independent_claim_cnt: 0,
            independent_ec: 0,
            judgement_at: 0,
            oa_cnt: 0,
            referee_number: "",
            referee_type: "",
            screen_claim_cnt: 0,
            statement_page_cnt: 0,
            statement_page_ec: 0,
            submission_cnt: 0,
            sum_commission: 0,
            sum_total_domestic: 0,
            surtax: 0,
            time_charge_ec: 0,
            total_claim_cnt: 0,
            total_claim_ec: 0,
            type: type,
            work_hour: 0,
            work_hour_rate: 0,
        };
        billing.func.onChangeInput(bill, null, billing);
    };

    let large_option = largeClass.map((item, index) => <option key={index} value={item}>{item}</option>);
    let middle_option = middleClass[getType(billObj.type)]?.map((item, index) => <option key={index} value={item}>{item}</option>);

    let selected_large = largeClass.find(item => item.replace(" ", "") == getType(billObj.type));
    let selected_middle_index = billObj.type - baseType;
    let selected_middle = middleClass[getType(billObj.type)]?.[selected_middle_index];

    return (
        <>
            <div className="field">
                <div className="rows">
                    <div className="row">
                        <h2 className="title">청구내용(대분류)</h2>
                        {
                            edit
                            ?
                                <select value={selected_large} onChange={onChangeLarge} dir="rtl">
                                    {large_option}
                                </select>
                            :   <p>{selected_large}</p>
                        }
                    </div>
                    <div className="row">
                        <h2 className="title">청구내용(중분류)</h2>
                        {
                            edit
                            ?
                                <select value={selected_middle} onChange={onChangeMiddle} dir="rtl">
                                    {middle_option}
                                </select>
                            :   <p>{selected_middle}</p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClaimFieldAsDesign;
