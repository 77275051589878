import React from "react";
import SelectBox from "../../ui/selectBox/SelectBox";
import SharedStyles from "../../../css/sales/shared.module.scss";

const PackageHeader = ({ universitiesState, filterValues, updateFilterHandler, onAdd, totalCost = 0 }) => {
  return (
    <header>
      <div className={SharedStyles["selectboxes-div"]}>
        <SelectBox
          selectedOption={filterValues.university}
          name="university"
          isDisabled={universitiesState.state === "LOADING"}
          options={universitiesState.universities ?? []}
          onChange={updateFilterHandler}
        />
        <SelectBox
          selectedOption={filterValues.year}
          name="year"
          isDisabled={!filterValues.university.trueValue}
          options={[{ name: "2023년" }, { name: "2024년" }, { name: "2025년" }]}
          onChange={updateFilterHandler}
        />
        <SelectBox
          selectedOption={filterValues.month}
          name="month"
          isDisabled={!filterValues.year.trueValue}
          options={Array.from({ length: 12 }, (_, index) => ({ name: `${index + 1}월` }))}
          onChange={updateFilterHandler}
        />
      </div>

      <p>Package 비용 : {`${window.$Global.numberWithCommas(totalCost)}`}원</p>

      <button disabled={!filterValues.university.trueValue} type="button" onClick={onAdd}>
        비용추가하기
      </button>
    </header>
  );
};

export default PackageHeader;
