import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";
import PatentAPI from "../../API/patent";

const TradeMarkEtc = () => {
  const history = useHistory();
  const { trademark_idx } = useParams();
  const fileRef = React.useRef();
  const [tradeMarkData, setTradeMarkData] = useState({});
  const [resData, setResData] = useState([]);

  useEffect(() => {
    TradeMarkAPI.getTradeMarkInfo(trademark_idx).then((res) =>
      setTradeMarkData(res.data)
    );
    getETCFile();
  }, []);

  const getETCFile = () => {
    TradeMarkAPI.getTradeMarkEtcFile(trademark_idx).then((res) =>
      setResData(res.data.items)
    );
  };

  const onClickDownload = (idx) => {
    TradeMarkAPI.downloadTradeMarkEtcFile(trademark_idx, idx);
  };

  const onClickDelete = (idx) => {
    TradeMarkAPI.deleteTradeMarkEtcFile(trademark_idx, idx).then(() => {
      alert("삭제되었습니다");
      getETCFile();
    });
  };

  const uploadFile = (e) => {
    let files = e.target.files;
    let formData = new FormData();
    Array.from(files).forEach((file) => formData.append("file", file));

    TradeMarkAPI.uploadTradeMarkEtcFile(trademark_idx, formData).then(() => {
      alert("업로드되었습니다");
      getETCFile();
    });
  };

  let tbody = [];

  if (resData.length) {
    tbody = resData.map((item, idx) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.file_name + "." + item.file_type}</td>
          <td>{window.$Global.convertDate(item.created_at)}</td>
          <td>
            <button
              className="icon_download"
              onClick={() => onClickDownload(item.idx)}
            />
          </td>
          <td onClick={() => onClickDelete(item.idx)}>삭제하기</td>
        </tr>
      );
    });
  } else {
    tbody = window.$Global.notTd(5, "기타 관련 파일이 존재하지 않습니다");
  }

  return (
    <div id="PatentETC">
      <div className="header">
        <h2 className="title">기타 관련 파일</h2>
        <div className="patent_info">
          관리번호{" "}
          {tradeMarkData.manage_number != "null"
            ? tradeMarkData.manage_number
            : "N/A"}
          <i
            className={`icon_flag_${window.$Global.convertNationCodeToStr(
              tradeMarkData.nation
            )} flag`}
          />
        </div>
        <div className="btns">
          <button
            className="btn_upload"
            onClick={() => fileRef.current.click()}
          >
            업로드하기
          </button>
          <button className="btn_save" onClick={() => history.goBack()}>
            이전
          </button>
        </div>
      </div>
      <table className="list">
        <thead>
          <tr>
            <th>순번</th>
            <th>파일명</th>
            <th>업로드일</th>
            <th>다운</th>
            <th>삭제하기</th>
          </tr>
        </thead>
        <tbody>{tbody}</tbody>
      </table>
      <input type="file" ref={fileRef} onChange={uploadFile} multiple hidden />
    </div>
  );
};

export default TradeMarkEtc;
