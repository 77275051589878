import API from '../../util/api';

export default {
    getNotification(company_idx) {
        return API.get(`/member/notification`, {params: {company_idx: company_idx}});
    },
    notifyConfirm(idx) {
        return API.post(`/member/notification/${idx}`);
    },
    notifyDelete(idx) {
        return API.delete(`/member/notification/${idx}`);
    }
};
