function PatentMailResChk() {
  return(
    <div id="PatentMailResChk">
      <div className="annual_mail_page">
        <div className="top">
          <div className="title">
            <i className="icon_annual_res_chk"></i>
            <div className="txt">
              <strong>연차료 납부 검토 발명자 응답 요청</strong>
              <p>본 특허권 유지에 대하여 연차료 납부 여부에 대한<br /> 
                결정 및 기타 의견을 부탁 드립니다.</p>
            </div>
          </div>
          <div className="steps">
            <p className="one">1</p>
            <p className="two on">2</p>
          </div>
        </div>
        <div className="auth_form">
          <div className="chk_res">
            <ul>
              <li className="yes">
                <strong>
                  <i className="icon_annual_chk"></i>
                  납부 희망
                </strong>
                <p>
                  연차료 납부를<br />
                  희망하는 경우 클릭하세요.
                </p>
                <input type="radio" name="response" />
              </li>
              <li className="no">
                <strong>
                  <i className="icon_annual_reject"></i>
                  납부 거부
                </strong>
                <p>
                  연차료 납부를<br />
                  비희망하는 경우 클릭하세요.
                </p>
                <input type="radio" name="response" />
              </li>
            </ul>
          </div>
          <div className="etc_box">
            <div className="etc">
              <strong>
                  <i className="icon_annual_etc"></i>
                  기타
              </strong>
              <textarea name="etc" />
              <input type="radio" name="response" />
            </div>
          </div>
        </div>
        <div className="btns">
          <button>제출하기</button>
        </div>
      </div>
    </div>
  )
}

export default PatentMailResChk;