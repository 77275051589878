import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/MyPrice.scss";
import _ from "lodash";
import Menu from "./common/Menu";
import CheckBox from "../common/CheckBox";
import AccountAPI from "../../API/account";
import memberContext from "../../store/Member";
import PaymentAPI from "../../API/payment";
import CommonAPI from "../../API/common";
import qs from "query-string";

const { IMP } = window;
IMP.init("imp60657743");

function MyPrice({ history, location }) {
  const { idx, company, email, auth } = useContext(memberContext);
  const { pageType, testMode } = qs.parse(location.search);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const [isChanged, setIsChanged] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [standardPriceCheckList, setStandardPriceCheckList] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [nowPriceCheckList, setNowPriceCheckList] = useState({
    searchPrice: [true, false],
    findXPrice: [true, false, false, false],
    standardPrice: [true, false, false, false, false],
  });

  const [usePriceInfo, setUsePriceInfo] = useState({
    freeStatus: false,
    payed: false, // 결제 여부
    standard: {
      pay_option: null,
      ipnow_expiration_at: 0,
    },
    nowPlus: {
      findx_expiration_at: 0,
      ip_search_expiration_at: 0,
    },
  }); // 이용 요금제 정보

  const [hasIpCnt, setHasIpCnt] = useState({ patent: 0, tradeMark: 0 });

  const [sumStandardPrice, setSumStandardPrice] = useState(0);
  const [sumNowPlusPrice, setSumNowPlusPrice] = useState({
    searchPrice: 0,
    findXPrice: 0,
    standardPrice: 0,
  });

  const [testPrice, setTestPrice] = useState(22000); // 테스트에 사용되는 변수
  const isTestMode = testMode == "test";

  const standardPriceTable = [
    {
      name: "A구간",
      cnt: "1~5건",
      price: 120000,
    },
    {
      name: "B구간",
      cnt: "6~20건",
      price: 240000,
    },
    {
      name: "C구간",
      cnt: "21~50건",
      price: 360000,
    },
    {
      name: "D구간",
      cnt: "51~70건",
      price: 480000,
    },
    {
      name: "E구간",
      cnt: "71~100건",
      price: 600000,
    },
    {
      name: "협의 요금제",
      cnt: "301건 이상",
      price: "협의 금액",
    },
    {
      name: "베이직 요금제",
      cnt: "301건 이상",
      price: "무료",
    },
  ];

  const nowPlusTable = {
    searchPrice: [
      {
        name: "6개월",
        price: 60000,
      },
      {
        name: "12개월",
        price: 100000,
      },
    ],
    findXPrice: [
      {
        name: "1개월",
        price: 20000,
      },
      {
        name: "6개월",
        price: 80000,
      },
      {
        name: "12개월",
        price: 120000,
      },
    ],
  };

  useEffect(() => {
    AccountAPI.getAccountInfo().then((res) => {
      let copy = _.cloneDeep(usePriceInfo);
      let data = res.data;
      let pay_option = data.pay_option;
      pay_option =
        pay_option != null ? pay_option : standardPriceTable.length - 1;

      let ipnow_expiration_at = data.ipnow_expiration_at;
      let result = window.$Global.getDday(ipnow_expiration_at);
      let findx_expiration_at = data.findx_expiration_at;
      let ip_search_expiration_at = data.ip_search_expiration_at;

      copy.payed = Boolean(
        data.pay_option != null ||
          findx_expiration_at ||
          ip_search_expiration_at
      );
      copy.standard = {
        pay_option: pay_option,
        ipnow_expiration_at: ipnow_expiration_at,
      };
      copy.nowPlus = {
        findx_expiration_at: findx_expiration_at,
        ip_search_expiration_at: ip_search_expiration_at,
      };

      if (pay_option == 6) {
        // 무료 이용자
        if (result > 0) {
          copy.freeStatus = true;
        }
      } else {
        copy.freeStatus = false;
      }
      setUsePriceInfo(copy);
    });

    let payload = {};
    payload.start_year = 0;
    payload.end_year = 0;
    CommonAPI.getHaveIpCnt().then((res) => setHasIpCnt(res.data));
  }, []);

  useEffect(() => {
    let standardIdx = standardPriceCheckList.findIndex((item) => item);
    let standardPrice =
      standardIdx > -1 ? standardPriceTable[standardIdx].price : 0;
    let searchPriceIdx = nowPriceCheckList.searchPrice.findIndex(
      (item) => item
    );
    let searchPrice =
      searchPriceIdx > -1 ? nowPlusTable.searchPrice[searchPriceIdx].price : 0;
    let findXPriceIdx = nowPriceCheckList.findXPrice.findIndex((item) => item);
    let findXPrice =
      findXPriceIdx > -1 ? nowPlusTable.findXPrice[findXPriceIdx].price : 0;

    setSumNowPlusPrice({
      standardPrice: standardPrice + standardPrice / 10,
      searchPrice: searchPrice + searchPrice / 10,
      findXPrice: findXPrice + findXPrice / 10,
    });
  }, [nowPriceCheckList, standardPriceCheckList]);

  useEffect(() => {
    if (pageType == "payment") {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [pageType]);

  const onClickStandardPayment = () => {
    const data = {
      pg: "inicis",
      pay_method: "card",
      merchant_uid: `mid_${new Date().getTime()}`,
      amount: isTestMode ? testPrice : sumStandardPrice,
      customer_uid: isTestMode && idx, // 자동 결제
      name: "IPNOW 요금제 결제",
      buyer_name: company,
      buyer_email: email,
    };
    IMP.request_pay(data, callback);
  };

  const onClickNowPlusPayment = () => {
    let name = "IPNOW 결제";
    //let price = sumNowPlusPrice.findXPrice + sumNowPlusPrice.standardPrice;
    let price = sumNowPlusPrice.standardPrice;
    let add_payload = {};
    let findXPriceIndex = nowPriceCheckList.findXPrice.findIndex(
      (item) => item
    );
    let standardPriceIndex = standardPriceCheckList.findIndex((item) => item);

    if (findXPriceIndex === 1 || findXPriceIndex === 2) {
      findXPriceIndex += 1;
    }

    console.log(price);
    console.log(findXPriceIndex);
    console.log(standardPriceIndex);

    add_payload = {
      findx: findXPriceIndex === -1 ? 0 : 1,
      ...(findXPriceIndex !== -1 && { findx_option: findXPriceIndex }),
      ...(standardPriceIndex !== -1 && { pay_option: standardPriceIndex }),
    };

    /* switch (key) {
      case "searchPrice":
        name = "관심기업 기술역량, 등록특허 등급 검색";
        price = sumNowPlusPrice.searchPrice;
        add_payload = {
          ip_search: 1,
          ip_search_option: nowPriceCheckList.searchPrice.findIndex(
            (item) => item
          ),
        };
        break;
      case "findXPrice":
        name = "모든 공공사업 ONE Click 찾기 BIZNAVI";
        price = sumNowPlusPrice.findXPrice;
        add_payload = {
          findx: 1,
          findx_option: nowPriceCheckList.findXPrice.findIndex((item) => item),
        };
        break;
    }*/

    const data = {
      pg: "inicis",
      pay_method: "card",
      merchant_uid: `mid_${new Date().getTime()}`,
      amount: isTestMode ? testPrice : price,
      name: name,
      buyer_name: company,
      buyer_email: email,
    };

    IMP.request_pay(data, (response) => {
      const { success, merchant_uid, imp_uid, error_msg } = response;

      if (success) {
        let payload = {
          imp_uid: imp_uid,
          ...add_payload,
        };
        PaymentAPI.payment(payload).then((res) => {
          alert("결제가 완료되었습니다");
          history.push("/manage/price");
        });
      } else {
        alert(`결제 실패: ${error_msg}`);
      }
    });
  };

  const callback = (response) => {
    const { success, merchant_uid, imp_uid, error_msg } = response;

    if (success) {
      let payload = {
        imp_uid: imp_uid,
        pay_option: standardPriceCheckList.findIndex((item) => item == true),
      };
      PaymentAPI.payment(payload).then((res) => {
        alert("결제가 완료되었습니다");
        history.push("/manage/price");
      });
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  };

  const onClickStandardCheck = (e, index) => {
    let copy = _.cloneDeep(standardPriceCheckList);
    for (let i = 0; i < copy.length; i++) {
      copy[i] = false;
    }
    copy[index] = e.target.checked;
    setStandardPriceCheckList(copy);
  };

  const onClickNowPlusCheck = (e, key, index) => {
    let copy_all = _.cloneDeep(nowPriceCheckList);
    let copy = copy_all[key];

    for (let i = 0; i < copy.length; i++) {
      copy[i] = false;
    }
    copy[index] = e.target.checked;
    setNowPriceCheckList(copy_all);
  };

  const sumCost = (findXPrice, standardPrice) => {
    //const number = findXPrice + standardPrice;
    // 비즈내비 이용가격 뺌
    const number = standardPrice;
    return number.toLocaleString("en-AU");
  };
  const StandardBox = () => {
    return (
      <div className="standard box">
        <div className="header">
          <h2 className="title">올인원 특허관리 플랫폼, 아이피나우</h2>
          <div className="has_ip_cnt">
            <p className="has_ip_cnt_txt">현재 보유 IP 건 수</p>
            <div className="list">
              <div className="item">
                <h2>특허</h2>
                <p>
                  {hasIpCnt.patent_cnt}
                  <span>건</span>
                </p>
              </div>
              <div className="item">
                <h2>상표</h2>
                <p>
                  {hasIpCnt.trademark_cnt}
                  <span>건</span>
                </p>
              </div>
              <div className="item">
                <h2>디자인</h2>
                <p>
                  {hasIpCnt.design_cnt}
                  <span>건</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>선&nbsp;&nbsp;택</th>
              <th>구간 별</th>
              <th>건수 별</th>
              <th>가&nbsp;&nbsp;격/년</th>
              <th>제공 서비스</th>
            </tr>
          </thead>
          <tbody>
            {standardPriceTable.map((item, idx) => {
              if (idx > 4) return;
              return (
                <tr key={idx}>
                  <td>
                    <CheckBox
                      checked={standardPriceCheckList[idx]}
                      onChangeChecked={(e) => onClickStandardCheck(e, idx)}
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.cnt}</td>
                  <td>{window.$Global.commaify(item.price)}</td>
                  {idx == 0 && (
                    <td className="td_content" rowSpan={6}>
                      <div className="wrap_txt">
                        <p className="wrap_txt_header">
                          쉽고 편한 지식재산권 올인원 관리 서비스
                        </p>
                        <div className="wrap_txt_body first">
                          <i className="icon_check_orange" />
                          <p>
                            기업 맞춤 지식재산권 현황 <span>대시보드</span>와
                            <span>알림</span>으로
                            <br /> 특허를 쉽고 간편하게 관리할 수 있어요.
                          </p>
                        </div>
                        <div className="wrap_txt_body">
                          <i className="icon_check_orange" />
                          <div>
                            <p>
                              자체적 IP 관리는 물론, 특허사무소를 등록하고
                              협업할 수 있어요.
                            </p>
                          </div>
                        </div>
                        <div className="wrap_txt_body">
                          <i className="icon_check_orange" />
                          <div>
                            <p>
                              연차료 현황부터 IP 수익화에 필요한 데이터까지,
                              <br />
                              기업운영과 성장에 필요한 맞춤 IP 정보를
                              제공해드려요.
                            </p>
                          </div>
                        </div>
                        <div className="wrap_txt_footer">
                          <div>
                            <p>
                              *조건변동으로 인한 요금제 구간 변경 시 1년간
                              이전금액이 적용 됩니다.
                            </p>
                            <p>
                              *유효 IP기준 301건 이상은 협의 후 요금제가
                              적용됩니다.
                            </p>
                          </div>
                          <div>
                            <p>
                              <span>IP 301건 이상</span> 서비스 도입 문의 :{" "}
                              <span>070-4249-1023</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
            <tr>
              <div className="payment_area">
                <div className="wrap_price">
                  <h2>금&nbsp;&nbsp;액</h2>
                  <p className="vat">(VAT 포함 10%)</p>
                  <p className="sum_price">
                    {window.$Global.commaify(sumNowPlusPrice.standardPrice)}원
                  </p>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const NowPlus = () => {
    return (
      <div className="wrap_now_plus">
        {/*{!testMode && (
          <div className="box">
            <div className="header">
              <h2 className="title">대체불가 기업성장 패키지, 비즈내비</h2>
              <div className="inner">
                <h2>개별구매 서비스</h2>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>선&nbsp;&nbsp;택</th>
                  <th>사용 기간</th>
                  <th>가&nbsp;&nbsp;격</th>
                  <th>제공 서비스</th>
                </tr>
              </thead>
              <tbody>
                {nowPlusTable.findXPrice.map((item, idx) => {
                  return (
                    <tr>
                      <td>
                        <CheckBox
                          checked={nowPriceCheckList.findXPrice[idx]}
                          onChangeChecked={(e) => {
                            onClickNowPlusCheck(e, "findXPrice", idx);
                          }}
                        />
                      </td>
                      <td
                        style={{
                          fontFamily: nowPriceCheckList.findXPrice[idx]
                            ? "NanumSquareOTF_acEB"
                            : "NanumSquareOTF_acR",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        style={{
                          fontFamily: nowPriceCheckList.findXPrice[idx]
                            ? "NanumSquareOTF_acEB"
                            : "NanumSquareOTF_acR",
                        }}
                      >
                        {window.$Global.commaify(item.price)}
                      </td>
                      {idx == 0 && (
                        <td
                          className="td_content"
                          rowSpan={nowPlusTable.findXPrice.length + 1}
                        >
                          <div className="wrap_txt">
                            <p className="wrap_txt_header">
                              정부사업 수주 올인원 서비스 패키지
                            </p>
                            <div className="wrap_txt_body">
                              <i className="icon_check_blue" />
                              <div>
                                <p>
                                  전문가의 노하우를 담은
                                  <span>사업계획서 양식 서비스</span>로<br />
                                  빠르고 간편하게 정부사업에 참여해 보세요.
                                  <i className="icon_badge_open" />
                                </p>
                              </div>
                            </div>
                            <div className="wrap_txt_body">
                              <i className="icon_check_blue" />
                              <div>
                                <p>
                                  사업계획서·IR 작성에 바로 활용할 수 있는
                                  <br />
                                  <span>시장정보·선행기술정보</span> 데이터를
                                  제공해드려요.(각각 월 5건, 3건)
                                </p>
                              </div>
                            </div>
                            <div className="wrap_txt_body">
                              <i className="icon_check_blue" />
                              <div>
                                <p>
                                  독보적인 1500여 개 중앙부처·지자체·공공기관의
                                  <br />
                                  <span>정부사업 정보</span>를 확인하고,
                                  최신공고 알림을 받으세요.
                                </p>
                              </div>
                            </div>
                            <div className="wrap_txt_body">
                              <i className="icon_check_blue" />
                              <div>
                                <p>
                                  <span>제안서 샘플마켓</span>에서 퀄리티높은
                                  샘플을 거래하고,
                                  <br />
                                  정부사업 합격 성공률을 높여 보세요.
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <div className="payment_area">
                    <div className="wrap_price">
                      <h2>금&nbsp;&nbsp;액</h2>
                      <p className="vat">(VAT 포함 10%)</p>
                      <p className="sum_price">
                        {window.$Global.commaify(sumNowPlusPrice.findXPrice)}원
                      </p>
                    </div>
                    <div className="wrap_payment">
                      <p className="terms">
                        취소 환불 규정{" "}
                        <Link to={"/terms/payment"}>자세히 알아보기</Link>
                      </p>
                      <button
                        className="btn_payment"
                        onClick={() => onClickNowPlusPayment("findXPrice")}
                      >
                        결제
                      </button>
                    </div>
                  </div>
                </tr>
              </tbody>
            </table>
          </div>
        )}*/}

        {testMode && (
          <div className="box">
            <div className="header">
              <h2 className="title">
                모든 공공사업 ONE Click 찾기 FINDBIZ 정기 결제
              </h2>
              <div className="inner">
                <h2>개별구매 서비스</h2>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>선&nbsp;&nbsp;택</th>
                  <th>사용 기간</th>
                  <th>가&nbsp;&nbsp;격</th>
                  <th>제공 서비스</th>
                </tr>
              </thead>
              <tbody>
                {nowPlusTable.findXPrice.map((item, idx) => {
                  if (idx > 0) return null;
                  return (
                    <tr>
                      <td>
                        <CheckBox
                          checked={nowPriceCheckList.findXPrice[idx]}
                          onChangeChecked={(e) =>
                            onClickNowPlusCheck(e, "findXPrice", idx)
                          }
                        />
                      </td>
                      <td>{item.name}(정기 결제)</td>
                      <td>{window.$Global.commaify(item.price)}</td>
                      {idx == 0 && (
                        <td
                          className="td_content"
                          rowSpan={nowPlusTable.findXPrice.length}
                        >
                          <div className="wrap_txt">
                            <i className="icon_check_green" />
                            <div>
                              <h2>
                                우리회사에 맞는 정부 · 공공 · 지자체 과제찾기
                                서비스
                              </h2>
                              <p>
                                - 모든과제를 One Click으로 한번에 검색가능
                                <br />
                                - 우리회사에 최적화된 과제 알림제공
                                <br />- 해당과제에 맞는 실 제안서 샘플들 제공
                              </p>
                            </div>
                          </div>
                          <div className="wrap_txt">
                            <i className="icon_check_green" />
                            <div>
                              <h2>기업 매칭 시스템 제공</h2>
                              <p>
                                - 과제 신청 시 함께 하고자 하는 최적의 기업 매칭
                                제공 (추후 예정)
                              </p>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="payment_area">
              <div className="wrap_price">
                <h2>총 구입 금액</h2>
                <p className="vat">(VAT 포함 10%)</p>
                <p className="sum_price">
                  {window.$Global.commaify(sumNowPlusPrice.findXPrice)}원
                </p>
              </div>
              <div className="wrap_payment">
                <p className="terms">
                  취소 환불 규정{" "}
                  <Link to={"/terms/payment"}>자세히 알아보기</Link>
                </p>
                <button
                  className="btn_payment"
                  onClick={() => onClickNowPlusPayment()}
                >
                  결제
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div id="MyPrice">
      {isTestMode && (
        <input
          type="number"
          value={testPrice}
          onChange={(e) => setTestPrice(e.target.value)}
        />
      )}
      <Menu number={2} />
      <div className="form">
        <div className="area">
          <div className="header">
            <h2 className="page_title">
              {isChanged ? "이용요금" : "사용 중인 이용 요금제 정보"}
              <p>아이피나우 요금제 결제 페이지입니다.</p>
            </h2>
            {!isChanged && (
              <button
                className="btn_change"
                onClick={() => history.push("?pageType=payment")}
              >
                요금제 결제
              </button>
            )}
          </div>
        </div>
        {isChanged ? (
          <>
            {isViewTypeIpnow ? (
              <>
                {!isAgent && !isInvestor && <StandardBox />}
                <NowPlus />
              </>
            ) : (
              <>
                <NowPlus />
                {!isAgent && !isInvestor && <StandardBox />}
              </>
            )}
            <div className="payment_cost">
              <div className="payment_area">
                <div className="wrap_price">
                  <h2>총 구입 금액</h2>
                  <p className="vat">(VAT 포함 10%)</p>
                  <p className="sum_price">
                    {sumCost(
                      sumNowPlusPrice.findXPrice,
                      sumNowPlusPrice.standardPrice
                    )}
                    원
                  </p>
                </div>
                <div className="wrap_payment">
                  <p className="terms">
                    취소 환불 규정{" "}
                    <Link to={"/terms/payment"}>자세히 알아보기</Link>
                  </p>
                  <button
                    className="btn_payment"
                    onClick={() => onClickNowPlusPayment("")}
                  >
                    결제하기
                  </button>
                </div>
              </div>
            </div>
            <br />
          </>
        ) : (
          <>
            <div className="box date">
              {usePriceInfo.payed ? (
                <p>멤버십을 이용 중입니다.</p>
              ) : usePriceInfo.freeStatus ? (
                <p>
                  남아있는 무료 체험 기간이 <span>D-일</span> 남았습니다.
                </p>
              ) : (
                <p>멤버십 이용기간이 끝났습니다.</p>
              )}
            </div>
            {(usePriceInfo.payed || usePriceInfo.freeStatus) && (
              <table className="info_table">
                <thead>
                  <tr>
                    <th>요금제</th>
                    <th>제공내용</th>
                    <th>이용 종료일</th>
                  </tr>
                </thead>
                <tbody>
                  {usePriceInfo.payed && (
                    <>
                      {/*{usePriceInfo.nowPlus.findx_expiration_at > 0 ? (
                        <tr>
                          <td>
                            <span>BIZNAVI - PRO 요금제</span>
                          </td>
                          <td>
                            <p>
                              정부과제 검색 ·요약본 · 실시간 알림 서비스
                              <br />
                              산업별 시장정보 리포트 월 5건 다운로드
                              <br />
                              키워드 선행기술 리포트 월 3건 다운로드
                              <br />
                              경쟁사 핵심기술 · 기술역량 조회 서비스
                            </p>
                          </td>
                          <td>
                            {window.$Global.convertDateKor(
                              usePriceInfo.nowPlus.findx_expiration_at
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <span>BIZNAVI - BASIC</span>
                          </td>
                          <td>
                            <p>
                              PRO 멤버십 시작하고
                              <br /> 더 많은 서비스를 이용해 보세요
                            </p>
                          </td>
                          <td>
                            {window.$Global.convertDateKor(
                              usePriceInfo.nowPlus.findx_expiration_at
                            )}
                          </td>
                        </tr>
                      )}*/}
                      {usePriceInfo.standard.ipnow_expiration_at > 0 ? (
                        <tr>
                          <td>
                            <span>
                              특허관리 시스템 - STANDARD{" "}
                              {
                                standardPriceTable[
                                  usePriceInfo.standard.pay_option
                                ].name
                              }
                            </span>
                          </td>
                          <td>
                            특허·디자인·상표권{" "}
                            {
                              standardPriceTable[
                                usePriceInfo.standard.pay_option
                              ].cnt
                            }
                          </td>
                          <td>
                            {window.$Global.convertDateKor(
                              usePriceInfo.standard.ipnow_expiration_at
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            <span>특허관리 시스템 - BASIC</span>
                          </td>
                          <td>
                            STANDRAD 멤버십으로
                            <br />
                            특허 관리를 다시 시작해 보세요
                          </td>
                          <td>
                            {window.$Global.convertDateKor(
                              usePriceInfo.standard.ipnow_expiration_at
                            )}
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MyPrice;
