import React, { useEffect, useState } from "react";
import "./css/DocTradeMyTransactionPurchaseList.scss";
import DocTradeMyTransactionPurchaseRow from './DocTradeMyTransactionPurchaseRow';
import TemplateAPI from "../../API/template";

function DocTradeMyTransactionPurchaseList() {
    const [curPage, setCurPage] = useState(1);
    const [resData, setResData] = useState({items: []});

    useEffect(() => {
        getList();
    }, []);

    const getList = (page) => {
        let payload = {
            page: page || curPage,
            count: 100,
        };
        TemplateAPI.getBuyTemplateList(payload).then(res => setResData(res.data));
    };

    return (
        <div id="docTradeMyTransactionPurchaseList">
            <div className="mytransaction_section-purchase">
                <div className="mytransaction_section">
                    <h2 className="mytransaction_section_title">나의 구매 확인하기</h2>
                    <table className="mytransaction_section_table">
                        <colgroup>
                            <col width="40px" />
                            <col width="473px" />
                            <col width="130px" />
                            <col width="130px" />
                            <col width="77px" />
                        </colgroup>
                        <thead className="mytransaction_section_table_thead">
                            <tr>
                                <th className="num-title">순 번</th>
                                <th className="transaction-title">자료명</th>
                                <th className="type-title">다운로드 유효기간</th>
                                <th className="date-title">구매일</th>
                                <th className="detail-title">다운</th>
                            </tr>
                        </thead>
                        <tbody className="section_table_tbody">
                            <DocTradeMyTransactionPurchaseRow data={resData}/>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default DocTradeMyTransactionPurchaseList;