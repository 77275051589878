import React from "react";

const ReceiptInfoKR = ({ annualFeeData, data }) => {
  return (
    <>
      <div className="column_2">
        <p>수&emsp;신</p>
        <p>{data.company_name}</p>
      </div>
      <div>
        <p>청구 일자</p>
        <p>{window.$Global.convertDate(data.created_at) || 0}</p>
      </div>
      <div className="column_margin">
        <p>청구번호</p>
        <p>{data.billing_number}</p>
      </div>
      <div>
        <p>출원 번호</p>
        <p>{data.apply_number}</p>
      </div>
      <div>
        <p>등록번호</p>
        <p>{annualFeeData.register_number}</p>
      </div>
      <div className="column_2">
        <p>발명의 명칭</p>
        <p>{data.invention_name}</p>
      </div>
      <div className="column_2">
        <p>내역</p>
        <p>{data.history !== "null" && data.history}</p>
      </div>
    </>
  );
};

export default ReceiptInfoKR;
