import React, { useEffect, useState } from "react";
import "./css/TaskManageChartSilder.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonAPI from "../../API/common";
import LineBizCnt from "../intro/chart/LineBizCnt";
import BarBizTypeCnt from "../intro/chart/BarBizTypeCnt";
import DoughnutTotalAmount from "../intro/chart/DoughnutTotalAmount";

function TaskManageChartSlider() {
  const [taskStatistic, setTaskStatistic] = useState({
    biz_cnt: {
      7: [],
    }, // 최근 일별 biz 개수
    type_cnt: {
      7: {
        바이오: 0,
        빅데이터: 0,
        인공지능: 0,
        드론: 0,
        컨텐츠: 0,
        마케팅: 0,
      },
    }, // 주요 사업별 갯수
    daily_amount: {
      7: 0,
    }, // 지난 일주일 과제 금액
  });
  const totalAmount = window.$Global.numberToKorean(
    Object.keys(taskStatistic.daily_amount["7"]).reduce(
      (accumulator, currentValue) =>
        accumulator + taskStatistic.daily_amount["7"][currentValue].amount,
      0
    )
  );

  useEffect(() => {
    CommonAPI.getTaskStatistic().then((res) => setTaskStatistic(res.data));
  }, []);

  const line_chart_width = Math.floor(
    window.$Global.getBrowserSize().width / 1.3
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20px",
  };

  return (
    <div id="TaskManageChartSlider">
      <h2 className="area_title">비즈내비 사업 분석</h2>

      <div className="chart_area">
        <Slider {...settings}>
          <div className="wrap_chart">
            <div className="chart">
              <div className="header">
                <h2>최근 일별 biz 개수</h2>
              </div>
              <div className="legend">
                <div className="legend_data">
                  <div className="circle total" />
                  <h2>전체 사업</h2>
                </div>
                <div className="legend_data">
                  <div className="circle organized" />
                  <h2>정리된 사업</h2>
                </div>
              </div>
              <LineBizCnt
                biz_cnt={taskStatistic.biz_cnt}
                width={line_chart_width}
                height={180}
              />
            </div>
          </div>
          <div className="wrap_chart">
            <div className="chart">
              <div className="header">
                <h2>최근 한달 주요 사업별 갯수</h2>
              </div>
              <BarBizTypeCnt
                type_cnt={taskStatistic.type_cnt}
                width={line_chart_width}
                height={180}
              />
            </div>
          </div>
          <div className="wrap_chart">
            <div className="chart amount">
              <div className="header">
                <h2>지난 일주일 사업 총 금액</h2>
              </div>
              <DoughnutTotalAmount
                daily_amount={taskStatistic.daily_amount}
                textShow={true}
                innerText={totalAmount.substr(0, totalAmount.indexOf("조") + 1)}
                width={line_chart_width}
                height={180}
                colors={[]}
              />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default TaskManageChartSlider;
