import React, { useContext, useEffect, useState } from "react";
import "./css/Table.scss";
import memberContext from "../../store/Member";
import config from "../../const/config";
import message from "../../const/message";

function Table({
  props,
  onClickAdd,
  onClickAddComplete,
  onClickEdit,
  onClickEditCancle,
  onClickEditComplete,
  onClickDelete,
  companyCodeAddAPI,
  requestInvite,
}) {
  const thead_keys = Object.keys(props.thead); // thead key들을 저장하는 변수
  const [btn_add_type, setBtn_add_type] = useState(true);
  const [thead, setThead] = useState([]); // 표 thead
  const [new_account, setNewAccount] = useState({}); // 신규 계정 저장하는 변수
  const [new_account_refs, setNewAccountRefs] = useState({}); // 신규 계정 추가 input
  const [companyCode, setCompanyCode] = useState("");
  const { auth } = useContext(memberContext);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  let edit_arr_refs = {}; // 계정 수정 시 input을 담을 배열

  useEffect(() => {
    let json = {};
    let json_ref = {};
    let thead_tag = [];
    for (var i = 0; i < thead_keys.length; i++) {
      var key = thead_keys[i];
      thead_tag.push(
        <th key={i} style={props.style[i]}>
          {props.thead[key]}
        </th>
      );
      setThead(thead_tag);
      // 동적 json 생성
      if (key === "idx") continue;
      json[key] = "";
      json_ref[key + "Ref"] = React.createRef();
    }
    setNewAccountRefs(json_ref);
    setNewAccount(json);
  }, []);

  let tr = props.tbody.map((item, index) => {
    let account_td = [];

    if (item.type === "access") {
      for (var i = 0; i < thead_keys.length; i++) {
        var key = thead_keys[i];
        if (key === "type") continue;

        if (key === "idx") {
          account_td.push(
            <td key={`account_td_${key}_${index}`}>{index + 1}</td>
          );
          continue;
        } else if (key == "auth") {
          account_td.push(
            <td key={`account_td_${key}_${index}`}>
              <select
                name={key}
                ref={new_account_refs[key + "Ref"]}
                onChange={(e) => inputChange(e)}
              >
                {isCompany && (
                  <>
                    <option value={0} defaultChecked>
                      경영진
                    </option>
                    <option value={1}>관리자</option>
                    <option value={2}>발명자</option>
                  </>
                )}
                {isAgent && (
                  <>
                    <option value={0} defaultChecked>
                      경영진
                    </option>
                    <option value={1}>관리자</option>
                    <option value={2}>담당자</option>
                  </>
                )}
              </select>
            </td>
          );
        } else if (key == "position") {
          account_td.push(
            <td key={`new_td_${key}_${index}`}>
              <input
                type="text"
                name={key}
                data-name={props.thead[key]}
                ref={new_account_refs[key + "Ref"]}
                onChange={(e) => inputChange(e)}
              />
            </td>
          );
        } else {
          account_td.push(
            <td key={`account_td_${key}_${index}`}>
              {key === "password" ? convertStar(item[key]) : item[key]}
            </td>
          );
        }
      }
      return (
        <tr key={`account_tr_${index}`}>
          {account_td}
          <td key={`account_td_btns_${index}`} className="btns">
            <button
              className="icon_badge_ok"
              onClick={() =>
                requestInvite(
                  0,
                  item,
                  new_account_refs["authRef"].current.value,
                  new_account_refs["positionRef"].current.value
                )
              }
            />
            <button
              className="icon_badge_del"
              onClick={() => {
                requestInvite(1, item);
              }}
            />
          </td>
        </tr>
      );
    } else if (item.type === "account") {
      for (var i = 0; i < thead_keys.length; i++) {
        var key = thead_keys[i];
        if (key === "type") continue;
        if (key === "idx") {
          account_td.push(
            <td key={`account_td_${key}_${index}`}>{index + 1}</td>
          );
          continue;
        }
        account_td.push(
          <td key={`account_td_${key}_${index}`}>
            {key === "password" ? convertStar(item[key]) : item[key]}
          </td>
        );
      }
      return (
        <tr key={`account_tr_${index}`}>
          {account_td}
          <td key={`account_td_btns_${index}`} className="btns">
            {props.rank === 3 || props.rank === 9 ? null : (
              <button
                className="icon_edit"
                onClick={() => onClickEditTr(item)}
              />
            )}
            <button
              className="icon_badge_del"
              onClick={() => {
                onClickDeleteTr(item.company_idx, item.idx);
              }}
            />
          </td>
        </tr>
      );
    } else if (item.type === "new") {
      let new_td = [];

      for (var i = 0; i < thead_keys.length; i++) {
        var key = thead_keys[i];
        if (key === "type" || key === "idx") continue;

        if (key === "password") {
          new_td.push(
            <td key={`new_td_${key}_${index}`}>
              <input
                type="password"
                name={key}
                data-name={props.thead[key]}
                ref={new_account_refs[key + "Ref"]}
                onChange={(e) => inputChange(e)}
              />
            </td>
          );
          continue;
        }

        new_td.push(
          <td key={`new_td_${key}_${index}`}>
            <input
              type="text"
              name={key}
              data-name={props.thead[key]}
              ref={new_account_refs[key + "Ref"]}
              onChange={(e) => inputChange(e)}
            />
          </td>
        );
      }

      return (
        <tr key={`new_tr_${index}`}>
          <td key={`new_td_${index}`}>신규추가</td>
          {new_td}
          <td key={`new_td_btns_${index}`} className="btns">
            <button
              className="icon_badge_del"
              onClick={() => {
                onClickDeleteTr(0);
              }}
            />
          </td>
        </tr>
      );
    } else if (item.type === "edit") {
      let edit_td = [];

      edit_arr_refs[item.idx] = {};
      edit_arr_refs[item.idx]["idx"] = item.idx;

      for (var i in thead_keys) {
        var key = thead_keys[i];
        if (key === "idx") continue;
        edit_arr_refs[item.idx][key + "Ref"] = React.createRef();

        if (key === "password") {
          edit_td.push(
            <td key={`edit_td_${key}_${index}`}>
              <input
                type="password"
                name={key}
                data-name={props.thead[key]}
                ref={edit_arr_refs[item.idx][key + "Ref"]}
                onChange={(e) => {
                  inputArrChange(e, item.idx);
                }}
              />
            </td>
          );
          continue;
        }
        edit_td.push(
          <td key={`edit_td_${key}_${index}`}>
            <input
              type="text"
              name={key}
              data-name={props.thead[key]}
              defaultValue={item[key]}
              ref={edit_arr_refs[item.idx][key + "Ref"]}
              onChange={(e) => {
                inputArrChange(e, item.idx);
              }}
            />
          </td>
        );
      }
      return (
        <tr key={`edit_tr_${index}`}>
          <td key={`edit_td_$index}`}>{Number(index) + 1}</td>
          {edit_td}
          <td key={`edit_td_btns_${index}`} className="btns">
            <button
              className="icon_check_black"
              onClick={() => onClickEditCompleteTr(edit_arr_refs[item.idx])}
            />
            <button
              className="icon_badge_del"
              onClick={() => onClickEditCancelTr(item)}
            />
          </td>
        </tr>
      );
    }
  });

  if (tr.length === 0) {
    tr.push(
      <tr key={0}>
        <td className="not_styled">조회된 데이터가 없습니다.</td>
      </tr>
    );
  }

  const inputChange = (e) => {
    new_account[e.target.name] = e.target.value;
  };
  const inputArrChange = (e, index) => {
    edit_arr_refs[index][`${e.target.name}Ref`].current.value = e.target.value;
  };

  const onClickBtn = () => {
    // 추가하기 눌렀을 때
    if (btn_add_type) {
      onClickAdd(props.type);
      setBtn_add_type(!btn_add_type);
      // 추가 완료 눌렀을 때
    } else {
      for (var key in new_account_refs) {
        if (new_account_refs[key].current.value.length == 0) {
          alert(`${new_account_refs[key].current.dataset.name}을 입력해주세요`);
          new_account_refs[key].current.focus();
          return;
        }
      }
      // 추가가 정상적으로 되었으면 상태 변경
      onClickAddComplete(props.type, new_account).then((res) => {
        if (res) {
          setBtn_add_type(!btn_add_type);
        }
      });
    }
  };

  const onClickEditTr = (account) => {
    onClickEdit(props.type, account.idx);
  };

  const onClickEditCompleteTr = (edit_account) => {
    let keys = Object.keys(edit_account);

    let update_account = {
      type: "account",
      idx: edit_account.idx,
    };

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = edit_account[key].current?.value;
      if (key === "idx") continue;
      if (key !== "passwordRef" && value.length === 0) {
        alert(`${edit_account[key].current.dataset.name}을(를) 입력해주세요`);
        edit_account[key].current.focus();
        return;
      }
      if (key == "emailRef" && !value.match(config.emailReg)) {
        alert(message.errorEmailRegx);
        edit_account[key].current.focus();
        return;
      }
      update_account[key.replace("Ref", "")] = edit_account[key].current.value;
    }
    onClickEditComplete(props.type, update_account);
  };

  const onClickEditCancelTr = (account) => {
    onClickEditCancle(props.type, account.idx);
  };

  const onClickDeleteTr = (company_idx, idx) => {
    if (company_idx === 0) {
      setBtn_add_type(true);
      onClickDelete(props.type, company_idx, idx);
      return;
    }
    if (window.confirm("삭제하시겠습니까?")) {
      onClickDelete(props.type, company_idx, idx);
    }
  };

  function convertStar(password) {
    let star = "";
    for (var i = 0; i < password.length; i++) {
      star += "*";
    }
    return star;
  }

  const onClickAddCompany = () => {
    if (companyCode.length == 0) return;
    companyCodeAddAPI(companyCode, props.rank);
    setCompanyCode("");
  };

  const codeInput = React.useRef();

  return (
    <div className="ManageUserTable">
      {props.addDisable && (
        <div className="company_code">
          <h2>등록하려는 회사의 코드 입력</h2>
          <input
            type="text"
            placeholder="회사 코드를 입력해주세요"
            value={companyCode}
            onChange={(e) => setCompanyCode(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") onClickAddCompany();
            }}
          />
          <button onClick={onClickAddCompany}>등록</button>
        </div>
      )}
      {props.link && (
        <>
          <input
            type="text"
            value={`ipnow.co.kr/invite?code=${props.code}`}
            ref={codeInput}
            className="code"
            readOnly
          />
          <button
            className="btn_link btn"
            onClick={() => window.$Global.copy(codeInput)}
          >
            개인 가입 링크 복사
          </button>
        </>
      )}
      <div className="btn_control">
        {!props.addDisable &&
          !props.link &&
          (btn_add_type ? (
            <button className="btn_add btn" onClick={onClickBtn}>
              <i className="icon_plus_white" />
              <span>추가하기</span>
            </button>
          ) : (
            <button className="btn_add_complete btn" onClick={onClickBtn}>
              추가완료
            </button>
          ))}
      </div>
      <div className="title">
        {props.title}
        <div className="require">
          {props.require ? `${props.title} 한 명을 반드시 등록 해주세요` : ""}
        </div>
      </div>
      <table>
        <thead>
          <tr>
            {thead}
            <th style={{ width: "10%" }}></th>
          </tr>
        </thead>
        <tbody>{tr}</tbody>
      </table>
    </div>
  );
}

export default Table;
