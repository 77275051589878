import React, { useState, useRef, useEffect } from "react";
import "../css/sales/AnnualFeeSales.scss";
import AnnualSalesFeeTable from "./annualFee/AnnualSalesFeeTable";
import Modal from "../../common/Modal";
import SALES_API from "../../../API/annualPay/sales";
import transformUniversitiesIncome from "../util/sales/transformUniversitiesIncome";
import DepositesAndPackages from "./depositesAndPackages/DepositesAndPackages";

const YEAR = new Date().getFullYear();

const AnnualFeeSales = () => {
  const [activeYear, setActiveYear] = useState(YEAR);
  const modalRef = useRef();

  // Modal Logic
  const [activeModal, setActiveModal] = useState(null);

  const openModalHandler = (modalType) => {
    setActiveModal(modalType);
    modalRef.current.open();
  };
  const closeModalHandler = () => {
    setActiveModal(null);
    modalRef.current.close();
  };

  // Api Logic
  const [universitiesIncomeState, setuniversitiesIncomeState] = useState({
    state: "LOADING",
    universitiesIncome: null,
    universitiesList: null,
    error: null,
  });
  useEffect(() => {
    setuniversitiesIncomeState({ state: "LOADING" });
    SALES_API.getUniversitiesIncome(activeYear)
      .then((result) => {
        const parsedResponce = transformUniversitiesIncome(result.data);
        const companiesList = result?.data?.data.annualPayDepositAllList.map(({ companyName }) => companyName);
        setuniversitiesIncomeState({
          state: "SUCCESS",
          universitiesIncome: parsedResponce,
          universitiesList: companiesList,
          error: null,
        });
      })
      .catch((err) => {
        setuniversitiesIncomeState({
          state: "ERROR",
          error: { message: err?.response.data?.message ?? "오류가 발생했습니다. 다시 시도해 주세요!!!" },
        });
        console.log(err);
      });
  }, [activeYear]);

  return (
    <>
      <div id="AnnualSalesFee">
        <div>
          <div className="title">
            <h2>전체 연차료 지급 리스트</h2>
          </div>

          <div>
            <div>
              <span>연월 선택 : </span>
              <select onChange={(e) => setActiveYear(e.target.value)} value={activeYear}>
                {[YEAR - 1, YEAR, YEAR + 1].map((y) => (
                  <option key={y} value={y}>
                    {y}년
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="btns-wrapper">
            <button type="button">매출관리</button>

            <div>
              <button onClick={() => openModalHandler("DEPOSITE")} type="button">
                수금액입력
              </button>
              <button onClick={() => openModalHandler("PACKAGE")} type="button">
                Package 입력
              </button>
            </div>
          </div>
        </div>

        {universitiesIncomeState.state === "LOADING" ? (
          <div></div>
        ) : universitiesIncomeState.state === "SUCCESS" ? (
          <>
            {universitiesIncomeState.universitiesIncome.length ? (
              <AnnualSalesFeeTable
                yearFeeds={universitiesIncomeState.universitiesIncome ?? []}
                universityList={universitiesIncomeState.universitiesList ?? []}
              />
            ) : (
              <div>데이터가 설정되지 않았거나 올해</div>
            )}
          </>
        ) : universitiesIncomeState.state === "ERROR" ? (
          <div>{universitiesIncomeState.error.message}</div>
        ) : null}
      </div>
      <Modal ref={modalRef}>
        {activeModal === "DEPOSITE" ? (
          <DepositesAndPackages onCloseModal={closeModalHandler} modalType="DEPOSITE" />
        ) : activeModal === "PACKAGE" ? (
          <DepositesAndPackages onCloseModal={closeModalHandler} modalType="PACKAGE" />
        ) : null}
      </Modal>
    </>
  );
};

export default AnnualFeeSales;
