import React from "react";
import AdminAPI from "../../../API/admin";

const style = {
  backgroundColor: "red",
  padding: "5px 10px",
  borderRadius: "4px",
  color: "white",
};

export default (props, company_idx, onGridReady) => {
  const buttonClicked = () => {
    if (!props.data.patent_idx) {
      alert("특허 idx값이 없습니다.");
      return;
    } else {
      const boolean = window.confirm("삭제하시겠습니까?");
      let payload = {
        patent_idx: props.data.patent_idx,
        ptype: props.data.p_type,
      };
      if (boolean) {
        AdminAPI.annualPayDeleteItem(company_idx, payload)
          .then((res) => {
            alert(`삭제되었습니다.`);
          })
          .then(() => onGridReady(props));
      }
    }
  };

  return (
    <button style={style} onClick={() => buttonClicked()}>
      삭제
    </button>
  );
};
