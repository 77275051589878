import qs from "query-string";
import AdminAPI from "../../API/admin";
import { useEffect } from "react";
const MailingRecept = ({ location }) => {
  const { q } = qs.parse(location.search);

  /* useEffect(() => {
    // 컴포넌트가 마운트될 때 호출되는 useEffect를 사용하여 API를 호출합니다.
    if (q) {
      // URL의 'q' 파라미터가 존재하는 경우에만 API 호출
      const payload = { q }; // API 호출 시 전송할 데이터
      //AdminAPI.confirm_IsPaid(payload); // AdminAPI 메서드 호출
    }
  }, [q]); */

  const payload = { q }; // API 호출 시 전송할 데이터

  // useEffect(() => {
  //   AdminAPI.confirm_IsRecipt(payload); // AdminAPI 메서드 호출
  // },[]);

  return (
    <div>
      <p>메일 납부/미납부 컴포넌트 입니다.</p>
      <p>Query String = {q}</p>
    </div>
  );
};

export default MailingRecept;
