import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./PatentETC.scss";
import PatentAPI from "../../../API/patent";
import MemberContext from "../../../store/Member";

function PatentETC() {
  const { manage_company_idx, auth } = useContext(MemberContext);
  const { patent_idx } = useParams();
  const fileRef = React.useRef();
  const [resData, setResData] = useState([]);
  const [patentInfo, setPatentInfo] = useState({
    manage_number: "",
    nation: "",
  });

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  useEffect(() => {
    if (isCompany) {
      PatentAPI.getInfo(patent_idx).then((res) => setPatentInfo(res.data));
    } else if (isAgent) {
      PatentAPI.getInfoAsAgent(manage_company_idx, patent_idx).then((res) =>
        setPatentInfo(res.data)
      );
    }
    getETCFile();
  }, []);

  const getETCFile = () => {
    if (isCompany) {
      PatentAPI.getETC(patent_idx).then((res) => setResData(res.data));
    } else if (isAgent) {
      PatentAPI.getETCAsAgent(manage_company_idx, patent_idx).then((res) =>
        setResData(res.data)
      );
    }
  };

  const onClickDownload = (idx) => {
    if (isCompany) {
      PatentAPI.downloadETC(patent_idx, idx);
    } else if (isAgent) {
      PatentAPI.downloadETCAsAgent(manage_company_idx, patent_idx, idx);
    }
  };

  const onClickDelete = (idx) => {
    if (isAgent) {
      PatentAPI.deleteETCAsAgent(manage_company_idx, patent_idx, idx).then(
        () => {
          alert("삭제되었습니다");
          getETCFile();
        }
      );
    } else if (isCompany) {
      PatentAPI.deleteETCAsCompany(patent_idx, idx).then(() => {
        alert("삭제되었습니다");
        getETCFile();
      });
    }
  };

  const uploadFile = (e) => {
    let files = e.target.files;
    let formData = new FormData();
    Array.from(files).forEach((file) => formData.append("file", file));

    if (isAgent) {
      PatentAPI.uploadETCAsAgent(manage_company_idx, patent_idx, formData).then(
        () => {
          alert("업로드되었습니다");
          getETCFile();
        }
      );
    } else if (isCompany) {
      PatentAPI.uploadETCAsCompany(patent_idx, formData).then(() => {
        alert("업로드되었습니다");
        getETCFile();
      });
    }
  };

  let tbody = [];

  if (resData.length) {
    tbody = resData.map((item, idx) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.file_name + "." + item.file_type}</td>
          <td>{item.name}</td>
          <td>{window.$Global.convertDate(item.created_at)}</td>
          <td>
            <button
              className="icon_download"
              onClick={() => onClickDownload(item.idx)}
            />
          </td>
          <td onClick={() => onClickDelete(item.idx)}>삭제하기</td>
        </tr>
      );
    });
  } else {
    tbody = window.$Global.notTd(5, "기타 관련 파일이 존재하지 않습니다");
  }

  return (
    <div id="PatentETC">
      <div className="header">
        <h2 className="title">기타 관련 파일</h2>
        <div className="patent_info">
          관리번호{" "}
          {patentInfo.manage_number != "null"
            ? patentInfo.manage_number
            : "N/A"}
          <i
            className={`icon_flag_${window.$Global.convertNationCodeToStr(
              patentInfo.nation
            )} flag`}
          />
        </div>
        <div className="btns">
          <button
            className="btn_upload"
            onClick={() => fileRef.current.click()}
          >
            업로드하기
          </button>
        </div>
      </div>
      <table className="list">
        <thead>
          <tr>
            <th>순번</th>
            <th>파일명</th>
            <th>업로더</th>
            <th>업로드일</th>
            <th>다운</th>
            <th>삭제하기</th>
          </tr>
        </thead>
        <tbody>{tbody}</tbody>
      </table>
      <input type="file" ref={fileRef} onChange={uploadFile} multiple hidden />
    </div>
  );
}

export default PatentETC;
