import React, { useEffect, useState, useContext } from "react";
import "../css/AdminServicePosting.scss";

import qs from "query-string";
import _ from "lodash";
import Admin from "../../../API/admin";
import memberContext from "../../../store/Member";

function AdminCheckPointPosting({ history, location }) {
  const [subFlag, setSubFlag] = useState(null);
  const isPartTimer = !Boolean(useContext(memberContext).idx);
  const historyCategory = qs.parse(location.search).category;
  const subflag = qs.parse(location.search).subFlag;
  const [data, setData] = useState({ question: "", answer: "" });
  const { idx, type, category } = qs.parse(location.search);
  const isView = type == "view";
  const isEdit = type == "edit";
  const [postInput, setPostInput] = useState({
    question: data.question || "",
    answer: data.answer || "",
    flag: "",
    subFlag: data.subFlag || "",
  });
  const [categoryState, setCategoryState] = useState(null);
  const commonParam = `${location.pathname}?idx=${idx}&category=${historyCategory}`;
  const listPath = isPartTimer
    ? "/admin/AlbaMarket/list"
    : `/admin/manage/list?category=${historyCategory}`;

  useEffect(() => {
    if (data.question) {
      setPostInput((prevInput) => ({
        ...prevInput,
        question: data.question,
      }));
    }
  }, [data.question]);

  useEffect(() => {
    if (data.answer) {
      setPostInput((prevInput) => ({
        ...prevInput,
        answer: data.answer,
      }));
    }
  }, [data.answer]);

  useEffect(() => {
    if (data.subFlag) {
      setPostInput((prevInput) => ({
        ...prevInput,
        subFlag: data.subFlag,
      }));
    }
  }, [data.subFlag]);

  useEffect(() => {
    const payload = {
      flag: 2,
    };
    if (subflags(categoryState)) {
      Admin.adminCheckPointList(payload).then((res) => {
        const checkpointList =
          res.data.data.checkpointList[subflags(categoryState)];
        if (checkpointList) {
          const foundItem = checkpointList.find(
            (item) => item.idx === Number(idx)
          );

          if (foundItem) {
            setData(foundItem);
          }
        }
      });
    }
  }, [idx, type]);

  useEffect(() => {

    const newSubFlag = options.find((option) => option.label === categoryState)?.value || options[0]?.value;
  
    setPostInput((prevInput) => ({
      ...prevInput,
      subFlag: newSubFlag || options[0].value, 
    }));
  }, [categoryState, options]);
  const subflags = () => {
    const payload = {
      flag: 2,
    };
    Admin.adminCheckPointList(payload).then((res) => {
      const checkpointList = res.data.data.checkpointList;

      function findCategoryByIdx(checkpointList, idx) {
        for (const category in checkpointList) {
          const foundItem = checkpointList[category].find(
            (item) => item.idx === Number(idx)
          );
          if (foundItem) {
            return { category, item: foundItem };
          }
        }
        return null;
      }

      const result = findCategoryByIdx(checkpointList, idx);

      if (result) {
        setData(result.item);
        setCategoryState(result.category);
      }
    });
  };
  const onClickRemove = async () => {
    const payload = {
      idx: idx,
    };
    const check = window.confirm("체크 포인트를 삭제하시겠습니까?");
    if (check) {
      await Admin.deleteCheckPointList(payload);
      alert("체크 포인트가 삭제되었습니다.");
      history.push(listPath);
    }
  };

  const onClickEditGo = () => {
    const payload = {
      flag: 2,
    };

    const check = window.confirm("수정하시겠습니까");
    if (check) {
      history.push(listPath);

      history.push(`${commonParam}&type=edit`);
    }
    Admin.adminCheckPointList(payload).then((res) => {
      const checkpointList = res.data.data.checkpointList;

      function findCategoryByIdx(checkpointList, idx) {
        for (const category in checkpointList) {
          const foundItem = checkpointList[category].find(
            (item) => item.idx === Number(idx)
          );
          if (foundItem) {
            return { category, item: foundItem };
          }
        }
        return null;
      }

      const result = findCategoryByIdx(checkpointList, idx);

      if (result) {
        setData(result.item);
        setCategoryState(result.category);
      }
    });
  };

  const onClickEdit = async () => {
    await onClickSuccess(true);
  };

  const onClickSuccess = async (edit) => {
    if (postInput.question.trim() === "") {
      alert("질문을 입력해주세요");
      return;
    }

    if (postInput.answer.trim() === "") {
      alert("답변을 입력해주세요");
      return;
    }

    let payload = {
      idx: idx,
      flag: 2,
      subFlag: Number(postInput.subFlag),
      question:
        postInput.question.trim() !== "" ? postInput.question : data.question,
      answer: postInput.answer.trim() !== "" ? postInput.answer : data.answer,
    };

    const check = window.confirm("수정하시겠습니까");
    if (check) {
      await Admin.editCheckPointList(payload);
      alert("수정되었습니다");

      const selectedOption = options.find(
        (option) => option.value === payload.subFlag
      );

      const commonParam = `${location.pathname}?idx=${idx}&category=${historyCategory}`;

      history.push(`${commonParam}&type=view`);
    }
  };

  const options = [
    { value: 1, label: "특허 출원 준비" },
    { value: 2, label: "특허 출원 심사" },
    { value: 3, label: "등록유지 관리" },
  ];

  const handleSubFlagChange = (e) => {
    const selectedValue = e.target.value;
    setPostInput({ ...postInput, subFlag: selectedValue });
  };

  const addQuestion = async () => {
    let payload = {
      flag: 2,
      subFlag: postInput.subFlag,
      question: postInput.question,
      answer: postInput.answer,
    };

    await Admin.addCheckPointList(payload);

    alert("등록되었습니다");

    history.push(listPath);
  };
  return (
    <div id="AdminServicePosting">
      {isView ? (
        <h2 className="page_title">체크포인트 상세</h2>
      ) : isEdit ? (
        <h2 className="page_title">체크포인트 수정</h2>
      ) : (
        <h2 className="page_title">체크포인트 등록</h2>
      )}
      <div className="articles">
        <div className="field">
          <span>카테고리</span>
          {isView ? (
            <>{categoryState}</>
          ) : (
            <select value={postInput.subFlag} onChange={handleSubFlagChange}>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="field">
          <span>질문</span>
          {isView ? (
            <p>{data.question}</p>
          ) : (
            <input
              type="text"
              value={postInput.question}
              onChange={(e) =>
                setPostInput({ ...postInput, question: e.target.value })
              }
              placeholder="질문을 입력하세요"
            />
          )}
        </div>
        <div className="field">
          <span>답변</span>
          {isView ? (
            <div>
              {postInput.answer.split("\n").map((line, index) => (
                <p key={index}>{line.trim() !== "" ? line : <br />}</p>
              ))}
            </div>
          ) : (
            <textarea
              type="text"
              value={postInput.answer}
              onChange={(e) =>
                setPostInput({ ...postInput, answer: e.target.value })
              }
              placeholder="답변을 입력하세요"
            />
          )}
        </div>
      </div>

      <div className="btns">
        {isView && (
          <button className="btn_post" onClick={onClickEditGo}>
            수정
          </button>
        )}
        {isView ? (
          <button className="btn_post" onClick={onClickRemove}>
            삭제
          </button>
        ) : isEdit ? (
          <button className="btn_post" onClick={onClickEdit}>
            수정 완료
          </button>
        ) : (
          <button className="btn_post" onClick={addQuestion}>
            등록
          </button>
        )}
        <button className="btn_list" onClick={() => history.push(listPath)}>
          목록
        </button>
      </div>
    </div>
  );
}

export default AdminCheckPointPosting;
