import React from "react";
import { Route } from "react-router-dom";
import IntroContainer from "../components/intro/IntroContainer";
import UsagePrice from "../components/intro/UsagePrice";
import CustomerService from "../components/intro/CustomerService";
import Loans from "../components/intro/Loans";
import Investment from "../components/intro/Investment";
import IntroService from "../components/intro/IntroService";
import CustomSwitch from "./common/CustomSwitch";
import IntroServiceDetail from "../components/intro/IntroServiceDetail";
import RenewalDashBoard from "../components/intro/findbiz/RenewalDashBoard";
import IntroGovProject from "../components/intro/IntroGovProject";
import IntroServiceIntro from "../components/intro/IntroServiceIntro";

function IntroRoute({ match }) {
  return (
    <CustomSwitch>
      <Route exact path={`${match.path}/page`} component={IntroService} />
      <Route exact path={`${match.path}/page2`} component={IntroGovProject} />
      <Route
        exact
        path={`${match.path}/page/detail`}
        component={IntroServiceDetail}
      />
      <Route exact path={`${match.path}/main`} component={IntroContainer} />
      <Route exact path={`${match.path}/price`} component={UsagePrice} />
      <Route exact path={`${match.path}/service`} component={CustomerService} />
      <Route exact path={`${match.path}/service`} component={CustomerService} />
      <Route
        exact
        path={`${match.path}/service/detail`}
        component={IntroServiceIntro}
      />
      <Route exact path={`${match.path}/loans`} component={Loans} />
      <Route exact path={`${match.path}/investment`} component={Investment} />
      <Route
        exact
        path={`${match.path}/v2/dashboard`}
        component={RenewalDashBoard}
      />
    </CustomSwitch>
  );
}

export default IntroRoute;
