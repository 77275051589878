import API from "../../util/api";

export default {
    getTemplateList(params) {
        return API.get('/common/task/template', {params: params});
    },

    getTemplate(template_idx) {
        return API.get(`/common/task/template/${template_idx}`);
    },

    getCategory() {
        return API.get('/common/task/template/category');
    },

    getPointUsage(params) {
        return API.get('/common/company/amount/log', {params: params});
    },

    getPaymentTemplateList(params) {
        return API.get('/common/task/template/payment', {params: params});
    },

    getBuyTemplateList(params) {
        return API.get('/common/task/template/buy', {params: params});
    },

    payment(payload) {
        return API.post('/common/task/template/payment', payload);
    },

    download(buy_idx) {
        return window.$Global.download(`/common/task/template/buy/${buy_idx}/download`);
    },

    addSample(payload) {
        return API.post('/common/task/template', payload);
    }
}