import React from "react";

function ProcessStep({text, state}) {

    let tag = [];
    if (text == "서지 사항") {
        if (state > 2) {
            tag.push(<i className="icon_check_black"/>);
        } else {
            tag.push(<div className="circle green"/>);
        }
    } else if (text == "출원") {
        if (state > 8 && state < 21) {
            tag.push(<i className="icon_check_black"/>);
        } else if (state > 2 && 8 >= state) {
            tag.push(<div className="circle green"/>);
        } else if (state > 21 && 25 >= state) {
            tag.push(<div className="circle green"/>);
        }
    } else if (text == "등록") {
        if (state == 12) {
            tag.push(<i className="icon_check_black"/>);
        } else if (state > 8 && 11 >= state) {
            tag.push(<div className="circle green"/>);
        }
    } else if (text == "비용 관리") {
        if (state == 12) {
            tag.push(<div className="circle green"/>);
        }
    } else if (text == "중간 사건") {
        if (state > 10 && 12 >= state){
            tag.push(<i className="icon_check_black"/>);
        } else if (state > 12 && 21 >= state) {
            tag.push(<div className="circle green"/>);
        }
    }

    return (
        <div className="process_step">
            {tag}
            <h2 className="f_title">{text}</h2>
        </div>
    );
}

export default ProcessStep;
