import MarketInfoHeader from "../MarketInfoHeader";
import "../css/MarketInfoRequestEnd.scss";
import React from "react";
import { useHistory } from "react-router-dom";

const MarketInfoRequestEnd = () => {
  const history = useHistory();
  return (
    <div id="MarketInfoRequestEnd">
      <MarketInfoHeader />
      <div className="wrap">
        <h2 className="title">시장 정보 작성 요청</h2>
        <p className="sub_title">감사합니다.</p>
        <div className="button_wrap">
          <button onClick={() => history.push("/marketInfo/category")}>
            시장정보 메인으로 돌아가기
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarketInfoRequestEnd;
