import { cacheAdapterEnhancer } from 'axios-extensions';
import config from "../const/config";
const axios = require('axios');

const axiosOptions = {
    baseURL: config.url + '/api/v1',
    timeout: 6 * 1000 * 10 * 10 * 2, // 20분
    headers: {
        'x-token': sessionStorage.getItem(config.keyToken), 'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0'
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
}

const instance = axios.create(axiosOptions);

//* No interceptors is attached to this instance
export const AXIOS = axios.create(axiosOptions);

export default instance;
