import React, {useContext, useEffect, useState} from "react";
import qs from "query-string";
import memberContext from "../../store/Member";
import "./css/SignupInvite.scss";
import API from "../../util/api";
import MemberAPI from "../../API/member";
import MobileAlertPopup from "./MobileAlertPopup";

function SignupInvite({history, location}) {
    const {code} = qs.parse(location.search);
    const {auth} = useContext(memberContext);
    const [signInfo, setSignInfo] = useState({
        name: '',
        id: '',
        email: '',
        password: '',
        password_confirm: '',
    });

    const [checkId, setCheckId] = useState(false);
    const [checkEmail, setCheckEmail] = useState(false);
    const [checkPassword, setCheckPassword] = useState(null);
    const [nextButtonState, setNextButtonState] = useState(false);
    const [popupShow, setPopupShow] = useState(false);

    useEffect(() => {
        setNextButtonState(false);
        // 모바일
        if (signInfo.name && signInfo.id && signInfo.email && signInfo.password && signInfo.password_confirm && checkId && checkEmail && checkPassword) {
            setNextButtonState(true);
        }
    });

    useEffect(() => {
        if (!signInfo.password) return;
        if (signInfo.password === signInfo.password_confirm) {
            setCheckPassword(true);
        } else {
            setCheckPassword(false);
        }
    }, [signInfo.password, signInfo.password_confirm]);

    const onClickCheckId = () => {
        if (!signInfo.id) {
            alert("아이디를 입력해주세요");
            return;
        }
        API.post('/member/id_check', {id: signInfo.id}).then(() => {
            alert("사용가능한 아이디입니다");
            setCheckId(true);
        });
    };

    const onClickCheckEmail = () => {
        if (!signInfo.email) {
            alert("이메일을 입력해주세요");
            return;
        }
        API.post('/member/email_check', {email: signInfo.email}).then(() => {
            alert("사용가능한 이메일입니다");
            setCheckEmail(true);
        });
    };

    const onClickJoin = () => {
        let payload = {
            code: code,
            ...signInfo
        };

        MemberAPI.inviteJoin(payload).then(res => {
            setPopupShow(true);
        });
    };

    const onClickConfirm = () => {
        setPopupShow(false);
        history.push('/');
    };

    if (!code) {
        alert("잘못된 링크입니다\n주소를 확인하여 주세요");
        window.$Global.redirectHome(auth);
    }

    return (
        <div id="SignupInvite">
            <h2 className="page_title">회원 가입</h2>

            <div className="form">
                <div className="field">
                    <p className="f_title">이름*</p>
                    <p className="required">*필수입력항목</p>
                    <div className="wrap_input">
                        <input type="text" value={signInfo.name} onChange={(e) => setSignInfo({...signInfo, name: e.target.value})}/>
                    </div>
                </div>
                <div className="field">
                    <p className="f_title">이메일*</p>
                    <div className="wrap_input_validator">
                        <input type="text" value={signInfo.email} onChange={(e) => setSignInfo({...signInfo, email: e.target.value})}/>
                        <button className={`btn_check ${checkEmail ? 'btn_no' : ''}`} onClick={onClickCheckEmail}>중복확인</button>
                    </div>
                </div>
                <div className="field">
                    <p className="f_title">아이디*</p>
                    <div className="wrap_input_validator">
                        <input type="text" value={signInfo.id} onChange={(e) => setSignInfo({...signInfo, id: e.target.value})}/>
                        <button className={`btn_check ${checkId ? 'btn_no' : ''}`} onClick={onClickCheckId}>중복확인</button>
                    </div>
                </div>
                <div className="field">
                    <p className="f_title">비밀번호*</p>
                    <div className="wrap_input">
                        <input type="password" value={signInfo.password} onChange={(e) => setSignInfo({...signInfo, password: e.target.value})}/>
                    </div>
                </div>
                <div className="field">
                    <p className="f_title">비밀번호 확인*</p>
                    <div className="wrap_input">
                        <input type="password" value={signInfo.password_confirm} onChange={(e) => setSignInfo({...signInfo, password_confirm: e.target.value})}/>
                    </div>
                    {
                        (checkPassword != null) && <p className={`alert_msg ${checkPassword ? 'true': 'false'}`}>비밀번호가 {checkPassword ? '일치합니다': '일치하지 않습니다'}</p>
                    }
                </div>
                <button className={`btn ${nextButtonState ? '' : 'btn_no'}`} onClick={onClickJoin}>완료</button>
            </div>

            {
                popupShow &&
                <MobileAlertPopup title={'가입 요청되었습니다.'}
                                  desc={`관리자 승인 후 서비스 이용이 가능합니다.`}
                                  onConfirm={onClickConfirm}/>
            }
        </div>
    );
}

export default SignupInvite;
