import React from "react";

function DynamicInputRow({title, titleClass, content, editing, onClickCategoryDelete, onChange}) {
    return (
        <div className={`row ${titleClass || ''}`}>
            <h2 className="title">
                {title}
                {editing && <button className="icon_badge_del" onClick={onClickCategoryDelete}/>}
            </h2>
            {
                editing
                    ? <input type="text" value={content || ''} onChange={onChange}/>
                    : <p>{content}</p>
            }
        </div>
    );
}

export default DynamicInputRow;
