import React, { useContext, useState } from "react";
import "./MobilePCServiceAlert.scss";
import CheckBox from "../common/CheckBox";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";
import CommonAPI from "../../API/common";
import login from "../login/Login";

function MobilePCServiceAlert() {
  const { isMobile } = useContext(CommonContext);
  const isViewTypeIpnow = window.$Global.isIpnow();
  const [moHideSevenDayCheck, setMoHideSevenDayCheck] = useState(false);
  const [pcHideSevenDayCheck, setPcHideSevenDayCheck] = useState(false);
  const [moHide, setMoHide] = useState(false);
  const [pcHide, setPcHide] = useState(true);

  const onMoClickClose = () => {
    if (moHideSevenDayCheck) {
      window.$Global.setCookie("popup_mo", "moHide", 1);
    }
    setMoHide(true);
  };

  const onPcClickClose = () => {
    if (pcHideSevenDayCheck) {
      window.$Global.setCookie("popup_pc", "pcHide", 1);
    }
    setPcHide(true);
  };

  if (
    Boolean(window.$Global.getCookie("popup_mo")) ||
    moHide ||
    isViewTypeIpnow
  )
    return null;

  if (
    Boolean(window.$Global.getCookie("popup_pc")) ||
    pcHide ||
    isViewTypeIpnow
  )
    return null;

  const clickCount = () => {
    CommonAPI.addVisitCount({
      type: 1,
    }).then((res) => console.log(res));
  };

  return (
    <div id="MobilePCServiceAlert">
      {
        isMobile && (
          <div className="popup">
            <div className="content">
              <img
                src={
                  require("../../assets/images/common/logo_findBiz.svg").default
                }
                className="logo"
                alt="이미지"
              />
              <p className="title">정부사업 수주 필수템</p>
              <h2 className="desc1">
                <span>정리된 공고</span>부터 <b>제안서에 필요한</b>
                <br />
                <span>시장정보</span>와 <span>선행기술</span>까지
                <br />
              </h2>
              <p className="desc2">
                정부사업 수주의 A to Z,
                <br />
                비즈내비에서 모두 확인하세요.
              </p>
              <Link
                to="/signup/type"
                className="btn_join"
                onClick={() => setMoHide(true)}
              >
                비즈내비 가입하기
              </Link>

              <p className="notify">
                <b>비즈내비</b>는 <b>PC</b>에서 이용하시면 더욱 좋습니다.
              </p>
              <img
                src={require("../../assets/images/common/bg1.svg").default}
                className="bg bg1"
                alt="이미지"
              />
              <img
                src={require("../../assets/images/common/bg2.svg").default}
                className="bg"
                alt="이미지"
              />
            </div>
            <div className="btns">
              <div className="btn">
                <CheckBox
                  text="오늘은 더 이상 보지 않기"
                  checked={moHideSevenDayCheck}
                  onChangeChecked={() =>
                    setMoHideSevenDayCheck(!moHideSevenDayCheck)
                  }
                />
              </div>
              <div className="btn_close" onClick={onMoClickClose}>
                <i className="icon_exit" />
                <p>닫기</p>
              </div>
            </div>
          </div>
        )
        //   <div className="pc_popup">
        //   <a
        //     href="https://incrisel.co.kr/"
        //     target="_blank"
        //     onClick={() => {
        //       clickCount();
        //     }}
        //   >
        //     <img
        //       src={
        //         require("../../assets/images/common/bg_incrisel.png").default
        //       }
        //       className="bg"
        //       alt="이미지"
        //     />
        //   </a>
        //   <div className="btns">
        //     <div className="btn">
        //       <CheckBox
        //         text="오늘은 더 이상 보지 않기"
        //         checked={pcHideSevenDayCheck}
        //         onChangeChecked={() =>
        //           setPcHideSevenDayCheck(!pcHideSevenDayCheck)
        //         }
        //       />
        //     </div>
        //     <div className="btn_close" onClick={onPcClickClose}>
        //       <i className="icon_exit" />
        //       <p>닫기</p>
        //     </div>
        //   </div>
        // </div>
      }
    </div>
  );
}

export default MobilePCServiceAlert;
