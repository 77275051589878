import "./css/RndPlanSecond.scss";
import SideBar from "../../SideBar";
import RnDText from "../RnDText";
import FirstInfoText from "../../FirstInfoText";
import questionMark from "../../../../assets/images/businessPlan/icon-information-question-mark.svg";
import Info from "../../../../API/info";
import prevButton from "../../../../assets/images/businessPlan/button-prev.svg";
import nextButton from "../../../../assets/images/businessPlan/button-next.svg";
import buttonIcon from "../../../../assets/images/businessPlan/button-add-col.svg";
import { useEffect, useState } from "react";
import BringingUpIcon from "../../BringingUpIcon";
import ReferenceIcon from "../../ReferenceIcon";
import { useHistory } from "react-router-dom";
import SecondInfoText from "../../SecondInfoText";
import CommonAPI from "../../../../API/common";
import _ from "lodash";

const RnDPlanSecond = () => {
  useEffect(() => {
    CommonAPI.getBusinessData(window.sessionStorage.getItem("itemIdx")).then(
      (res) => {
        getDefaultData(res.data);
        console.log(res.data);
        setResponseData(res.data);
      }
    );
  }, []);
  const history = useHistory();
  const [textArea, setTextArea] = useState([
    {
      step: "1",
      text: "1",
      placeholder: "1",
    },
    {
      step: "1",
      text: "1",
      placeholder: "1",
    },
  ]);
  const [responseData, setResponseData] = useState({});
  const [secondPageData, setSecondPageData] = useState({
    summary: ["1", "1", "1", "1"],
    standard_classification: ["1", "1", "1"],
    technology_classification: ["1", "1", "1"],
    rnd_name: ["1", "1", "1"],
    cost: ["1", "1", "1", "1", "1"],
    stepRadioBtn: "1",
    technical_maturity: ["1", "1"],
    rnd_project: ["1", "1"],
    textarea_data: ["1", "1"],
    textarea_content: "1",
    keyword_kr: ["1", "1", "1", "1", "1"],
    keyword_en: ["1", "1", "1", "1", "1"],
  });

  const getDefaultData = (data) => {
    data.content.forEach((item) => {
      if (item.subject === "요약문") {
        console.log(item);
        let [techMatFirst, techMatLast] =
          item.content[8][8].content.split("\n");

        let content = {
          summary: [
            item.content[0][2].content,
            item.content[0][8].content,
            item.content[1][2].content,
            item.content[1][8].content,
          ],
          standard_classification: [
            item.content[2][4].content,
            item.content[2][6].content,
            item.content[2][8].content,
          ],
          technology_classification: [
            item.content[3][4].content,
            item.content[3][6].content,
            item.content[3][8].content,
          ],
          rnd_name: [
            item.content[4][2].content,
            item.content[5][2].content,
            item.content[6][2].content,
          ],
          cost: ["1", "1", "1", "1", "1"],
          technical_maturity: [
            techMatFirst.substring(
              techMatFirst.length - 2,
              techMatFirst.length - 1
            ),
            techMatLast.substring(
              techMatLast.length - 2,
              techMatLast.length - 1
            ),
          ],
          rnd_project: [
            item.content[9][2].content,
            item.content[10][2].content,
          ],
          textarea_data: [
            item.content[11][4].content,
            item.content[12][4].content,
          ],
          textarea_content: item.content[15][2].content,
          keyword_kr: [
            item.content[16][2].content,
            item.content[16][4].content,
            item.content[16][6].content,
            item.content[16][7].content,
            item.content[16][8].content,
          ],
          keyword_en: [
            item.content[17][2].content,
            item.content[17][4].content,
            item.content[17][6].content,
            item.content[17][7].content,
            item.content[17][8].content,
          ],
        };
        setSecondPageData(content);
        content = [
          {
            step: item.content[13][2].content.substring(0, 1),
            text: item.content[13][4].content,
            placeholder: "1",
          },
          {
            step: item.content[13][2].content.substring(0, 1),
            text: item.content[14][4].content,
            placeholder: "1",
          },
        ];
        setTextArea(content);
      }
    });
  };
  const addTextArea = () => {
    const inputData = [
      {
        step: "",
        text: "",
        placeholder: "N",
      },
      {
        step: "",
        text: "",
        placeholder: "N",
      },
    ];
    setTextArea(textArea.concat(inputData));
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    if (name === "stepRadioBtn" || name === "textarea_content") {
      setSecondPageData({
        ...secondPageData,
        [name]: value,
      });
    } else {
      if (name === "step" || name === "text") {
        const textAreaInput = [...textArea];
        textAreaInput[idx][name] = value;
        setTextArea(textAreaInput);
      } else {
        secondPageData[name][idx] = value;
        setSecondPageData({ ...secondPageData });
      }
    }
  };

  const sendData = () => {
    let textAreaArr = [];
    const contents = [
      [
        {
          type: "text",
          content: "사업명",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.summary[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "총괄연구개발식별번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.summary[1],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "내역사업명",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.summary[2],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "연구개발과제번호",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.summary[3],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "기술분류",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "국가과학기술표준분류",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "1순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.standard_classification[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "2순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.standard_classification[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "3순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.standard_classification[2],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "부처기술분류(해당 시 작성)",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "1순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.technology_classification[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "2순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.technology_classification[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "3순위 소분류 코드명",
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.technology_classification[2],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "총괄연구개발명(해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.rnd_name[0],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "연구개발과제명",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.rnd_name[1],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "전체 연구개발기간",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.rnd_name[2],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "총 연구개발비",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: `총 ${secondPageData.cost[0]} 천원\n
        (정부지원연구개발비: ${secondPageData.cost[1]} 천원, 기관부담연구개발비 : ${secondPageData.cost[2]} 천원,\n
        지방자치단체지원연구개발비: ${secondPageData.cost[3]}천원, 그 외 지원연구개발비: ${secondPageData.cost[4]}천원)`,
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "연구개발단계",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: `기초[${
            secondPageData.stepRadioBtn === "base" ? "√" : " "
          }] 응용[${
            secondPageData.stepRadioBtn === "apply" ? "√" : " "
          }] 개발[${
            secondPageData.stepRadioBtn === "development" ? "√" : " "
          }]\n기타(위 3가지에 해당되지 않는 경우)[${
            secondPageData.stepRadioBtn === "etc" ? "√" : " "
          }]`,
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "기술성숙도",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: `착수시점 기준(${secondPageData.technical_maturity[0]})\n종료시점 목표(${secondPageData.technical_maturity[1]})`,
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "연구개발과제 유형\n(해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.rnd_project[0],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "연구개발과제 특성\n(해당 시 작성)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.rnd_project[1],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "연구개발\n목표 및 내용",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "최종목표",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.textarea_data[0],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "전체 내용",
          option: { background: true, color: "#E6E6E6", border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.textarea_data[1],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
    ];

    textArea.map((el, index) => {
      let data = [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content:
            index % 2 === 0 ? `${el.step}단계\n(해당 시 작성)` : "||&병합&||",
          option: {
            background: true,
            color: "#E6E6E6",
            border: { type: 0 },
          },
        },
        {
          type: "text",
          content: index % 2 === 0 ? "목표" : "내용",
          option: {
            background: true,
            color: "#E6E6E6",
            border: { type: 0 },
          },
        },
        {
          type: "text",
          content: el.text,
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ];
      textAreaArr.push(data);
    });

    for (const p of textAreaArr) {
      contents.push(p);
    }

    contents.push(
      [
        {
          type: "text",
          content: "연구개발성과\n활용계획 및\n기대 효과",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
            border_right: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.textarea_content,
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
      ],
      [
        {
          type: "text",
          content: "국문핵심어\n(5개 이내)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_kr[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_kr[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_kr[2],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.keyword_kr[3],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.keyword_kr[4],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ],
      [
        {
          type: "text",
          content: "영문핵심어\n(5개 이내)",
          option: {
            background: true,
            color: "#E6E6E6",
            border_top: { type: 0 },
            border_bottom: { type: 0 },
          },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_en[0],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_en[1],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: secondPageData.keyword_en[2],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.keyword_en[3],
          option: { border: { type: 0 } },
        },
        {
          type: "text",
          content: secondPageData.keyword_en[4],
          option: { border_top: { type: 0 }, border_bottom: { type: 0 } },
        },
      ]
    );
    const data = {
      subject: "요약문",
      type: "table",
      content: [...contents],
    };
    sendDataToAPI(data);
  };

  const sendDataToAPI = (contentData) => {
    responseData.content = _.uniqBy(responseData.content, "subject");
    responseData.content.forEach((parentItem, index) => {
      if (parentItem.subject === contentData.subject) {
        responseData.content[index].content = contentData.content;
        setResponseData({ ...responseData });
      }
    });

    if (window.sessionStorage.getItem("itemIdx") !== null) {
      const payload = {
        title: "R&D 사업계획서",
        content: [...responseData.content],
      };
      CommonAPI.updateBusinessData(
        window.sessionStorage.getItem("itemIdx"),
        payload
      ).then(() => history.push("/plan/rnd/list/third"));
    } else {
      const payload = {
        title: "R&D 사업계획서",
        category1_idx: 1,
        category2_idx: 11,
        content: [contentData],
      };
      CommonAPI.addBusinessData(payload).then(() =>
        history.push("/plan/rnd/list/first")
      );
    }
    // window.$Global
    //   .fileDownloadSync("test/hwp", {
    //     data: {
    //       idx: 11,
    //       content: [data],
    //     },
    //   })
    //   .then((r) => console.log(r));
  };
  return (
    <div className="wrapper">
      <SideBar />
      <div className="rnd_wrapper">
        <RnDText index={2} />
        <div className="rnd_plan">
          <div className="plan_two_wrapper">
            <div className="plan_text">
              <div>
                <p>요 약 문</p>
                <SecondInfoText idx={0} />
              </div>
              <div>
                <span>※ 요약문은 5쪽 이내로 작성합니다.</span>
              </div>
            </div>
            <div className="plan_grid_items rnd_plan_item">
              <div className="plan_grid_item">
                <p>
                  사업명
                  <br />
                </p>
                <input
                  type="text"
                  name="summary"
                  placeholder="산업통상자원부"
                  value={secondPageData.summary[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  총괄연구개발
                  <br />
                  식별번호
                </p>
                <input
                  type="text"
                  name="summary"
                  placeholder="(해당 시 작성)"
                  value={secondPageData.summary[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="plan_grid_item">
                <p>
                  내역 사업명
                  <br />
                  <span className="extra_text" style={{ padding: 0 }}>
                    해당시 작성
                  </span>
                </p>
                <input
                  type="text"
                  name="summary"
                  value={secondPageData.summary[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
              </div>
              <div className="plan_grid_item">
                <p>연구개발과제번호</p>
                <input
                  type="text"
                  name="summary"
                  value={secondPageData.summary[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
              </div>
            </div>
            <div className="tech_classification">
              <div className="head_text">
                <p className="big_text">기술분류</p>
                <SecondInfoText idx={1} />
              </div>
              <div className="tech_classification_item">
                <div className="text_wrapper">
                  <span className="small_text">국가과학기술표준분류</span>
                </div>
                <div className="tech_classification_code">
                  <p>1순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    placeholder="ND0701"
                    value={secondPageData.standard_classification[0]}
                    onChange={(e) => handleChange(e, 0)}
                  />
                </div>
                <div className="tech_classification_code">
                  <p>2순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    placeholder="ND0703"
                    value={secondPageData.standard_classification[1]}
                    onChange={(e) => handleChange(e, 1)}
                  />
                </div>
                <div className="tech_classification_code">
                  <p>3순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="standard_classification"
                    placeholder="ND0710"
                    value={secondPageData.standard_classification[2]}
                    onChange={(e) => handleChange(e, 2)}
                  />
                </div>
              </div>
              <div className="tech_classification_item">
                <div className="text_wrapper">
                  <p className="small_text">
                    부처기술분류
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </p>
                </div>
                <div className="tech_classification_code">
                  <p>1순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    placeholder="SF0709"
                    value={secondPageData.technology_classification[0]}
                    onChange={(e) => handleChange(e, 0)}
                  />
                </div>
                <div className="tech_classification_code">
                  <p>2순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    placeholder="ND0701"
                    value={secondPageData.technology_classification[1]}
                    onChange={(e) => handleChange(e, 1)}
                  />
                </div>
                <div className="tech_classification_code">
                  <p>3순위 소분류 코드명</p>
                  <input
                    type="text"
                    name="technology_classification"
                    placeholder="LB1105"
                    value={secondPageData.technology_classification[2]}
                    onChange={(e) => handleChange(e, 2)}
                  />
                </div>
              </div>
            </div>
            <div className="tech_name">
              <div className="tech_name_item rnd_name">
                <p className="small_text">총괄연구개발명</p>
                <input
                  type="text"
                  name="rnd_name"
                  placeholder="연구개발계획서 표지에 기재한 총괄연구개발명을 기재합니다."
                  value={secondPageData.rnd_name[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">연구개발과제명</p>
                <input
                  type="text"
                  name="rnd_name"
                  placeholder="연구개발계획서 표지에 기재한 연구개발과제명을 기재합니다."
                  value={secondPageData.rnd_name[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">전체연구개발기간</p>
                <input
                  type="text"
                  name="rnd_name"
                  placeholder="연구개발계획서 표지에 기재한 연구개발과제의 전체 연구개발기간을 기재합니다."
                  value={secondPageData.rnd_name[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
              </div>
            </div>
            <div className="grid_wrapper agency_and_director">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "13px",
                }}
              >
                <p className="big_text">총 연구개발비</p>
                <SecondInfoText idx={2} />
              </div>

              <div className="plan_grid_items">
                <div className="plan_grid_item">
                  <p>총 개발비</p>
                  <input
                    type="text"
                    name="cost"
                    placeholder="연구개발계획서 표지에 기재한 연구개발과제의 총 연구개발비를 기재합니다."
                    style={{ width: "860px" }}
                    value={secondPageData.cost[0]}
                    onChange={(e) => handleChange(e, 0)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p className="small_text" style={{ paddingRight: 0 }}>
                    정부지원연구개발비
                  </p>
                  <input
                    type="text"
                    name="cost"
                    placeholder="천원"
                    style={{ width: "363px" }}
                    value={secondPageData.cost[1]}
                    onChange={(e) => handleChange(e, 1)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p className="small_text" style={{ paddingRight: 0 }}>
                    기관분담연구개발비
                  </p>
                  <input
                    type="text"
                    name="cost"
                    placeholder="천원"
                    style={{ width: "363px" }}
                    value={secondPageData.cost[2]}
                    onChange={(e) => handleChange(e, 2)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p className="small_text">
                    지방자치단체
                    <br />
                    지원 연구개발비
                  </p>
                  <input
                    type="text"
                    name="cost"
                    placeholder="천원"
                    style={{ width: "363px" }}
                    value={secondPageData.cost[3]}
                    onChange={(e) => handleChange(e, 3)}
                  />
                </div>
                <div className="plan_grid_item">
                  <p className="small_text">
                    그 외<br />
                    지원 연구개발비
                  </p>
                  <input
                    type="text"
                    name="cost"
                    placeholder="천원"
                    style={{ width: "363px" }}
                    value={secondPageData.cost[4]}
                    onChange={(e) => handleChange(e, 4)}
                  />
                </div>
              </div>
            </div>
            <div className="plan_contest_btn">
              <p className="small_text" style={{ minWidth: "80px" }}>
                연구개발단계
              </p>
              <SecondInfoText idx={3} />
              <div className="plan_radio_btn">
                <input
                  type="radio"
                  name="stepRadioBtn"
                  id="base"
                  value="base"
                  onChange={handleChange}
                />
                <label htmlFor="base">기&emsp;&emsp;초</label>
              </div>
              <div className="plan_radio_btn">
                <input
                  type="radio"
                  name="stepRadioBtn"
                  id="apply"
                  value="apply"
                  onChange={handleChange}
                />
                <label htmlFor="apply">응&emsp;&emsp;용</label>
              </div>
              <div className="plan_radio_btn">
                <input
                  type="radio"
                  name="stepRadioBtn"
                  id="development"
                  value="development"
                  onChange={handleChange}
                />
                <label htmlFor="development">개&emsp;&emsp;발</label>
              </div>
              <div className="plan_radio_btn">
                <input
                  type="radio"
                  name="stepRadioBtn"
                  id="etc"
                  value="etc"
                  onChange={handleChange}
                />
                <label htmlFor="etc">
                  기&emsp;&emsp;타(위 3가지에 해당되지 않는 경우)
                </label>
              </div>
            </div>
            <div className="plan_develop_step">
              <p className="small_text" style={{ minWidth: "80px" }}>
                기술성숙도
              </p>
              <SecondInfoText idx={4} />
              <p>착수시점 기준</p>
              <input
                type="text"
                name="technical_maturity"
                placeholder="N단계"
                value={secondPageData.technical_maturity[0]}
                onChange={(e) => handleChange(e, 0)}
              />
              <p>종료시점 목표</p>
              <input
                type="text"
                name="technical_maturity"
                placeholder="N단계"
                value={secondPageData.technical_maturity[1]}
                onChange={(e) => handleChange(e, 1)}
              />
            </div>
            <div className="rnd_project_wrapper">
              <div className="tech_name_item rnd_name">
                <p className="small_text">
                  연구개발과제 유형
                  <br />
                  <span className="extra_text">(해당 시 작성)</span>
                </p>
                <input
                  type="text"
                  name="rnd_project"
                  placeholder="입력하세요."
                  value={secondPageData.rnd_project[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="tech_name_item rnd_name">
                <p className="small_text">
                  연구개발과제 특성
                  <br />
                  <span className="extra_text">(해당 시 작성)</span>
                </p>
                <input
                  type="text"
                  name="rnd_project"
                  placeholder="입력하세요."
                  value={secondPageData.rnd_project[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
            </div>
            <div className="rnd_objective_content">
              <div className="rnd_objective_content_head">
                <div className="head_text">
                  <p className="big_text">연구개발 목표 및 내용</p>
                  <SecondInfoText idx={5} />
                </div>
                <button onClick={addTextArea}>
                  <img src={buttonIcon} alt="addColIcon" />
                </button>
              </div>
              <div className="rnd_objective_content_items">
                <div className="rnd_objective_content_item">
                  <div>
                    <p className="small_text">최종 목표</p>
                    <BringingUpIcon />
                    <ReferenceIcon />
                  </div>
                  <textarea
                    placeholder="□&#13;&#10;◦&#13;&#10;&nbsp;-"
                    name="textarea_data"
                    value={secondPageData.textarea_data[0]}
                    onChange={(e) => handleChange(e, 0)}
                  />
                  <p className="content_length">0/최소 1000자</p>
                </div>
                <div className="rnd_objective_content_item">
                  <div>
                    <p className="small_text">전체 내용</p>
                    <BringingUpIcon />
                    <ReferenceIcon />
                  </div>
                  <textarea
                    placeholder="□&#13;&#10;◦&#13;&#10;&nbsp;-"
                    name="textarea_data"
                    value={secondPageData.textarea_data[1]}
                    onChange={(e) => handleChange(e, 1)}
                  />
                  <p className="content_length">0/최소 1000자</p>
                </div>
                {textArea?.map((data, idx) => {
                  const { step, text } = data;
                  return (
                    <div className="rnd_objective_content_item">
                      <div>
                        <p className="small_text">
                          <input
                            type="text"
                            placeholder={data.placeholder}
                            name="step"
                            value={step || ""}
                            onChange={(e) => handleChange(e, idx)}
                          />
                          단계 {idx % 2 === 1 ? "내용" : "목표"}
                        </p>
                        <BringingUpIcon />
                        <ReferenceIcon />
                      </div>
                      <textarea
                        placeholder="□&#13;&#10;◦&#13;&#10;&nbsp;-"
                        value={text || ""}
                        name="text"
                        onChange={(e) => handleChange(e, idx)}
                      />
                      <p className="content_length">
                        0/최소 {idx % 2 === 1 ? "1000" : "500"}자
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="rnd_effective">
              <div className="head_text">
                <p className="big_text">연구개발 목표 및 내용</p>
                <SecondInfoText idx={6} />
              </div>
              <div className="rnd_effective_items">
                <div className="rnd_effective_item">
                  <div>
                    <BringingUpIcon />
                    <ReferenceIcon />
                  </div>
                  <textarea
                    name="textarea_content"
                    value={secondPageData.textarea_content}
                    onChange={handleChange}
                  />
                  <p className="content_length">0/최소 1000자</p>
                </div>
              </div>
            </div>
            <div className="rnd_keyword">
              <p className="small_text">&lt;표&gt; 핵심어</p>
              <div>
                <p className="small_text">국&emsp;문</p>
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_kr"
                  value={secondPageData.keyword_kr[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_kr"
                  value={secondPageData.keyword_kr[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_kr"
                  value={secondPageData.keyword_kr[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_kr"
                  value={secondPageData.keyword_kr[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_kr"
                  value={secondPageData.keyword_kr[4]}
                  onChange={(e) => handleChange(e, 4)}
                />
              </div>
              <div>
                <p className="small_text">영&emsp;문</p>
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_en"
                  value={secondPageData.keyword_en[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_en"
                  value={secondPageData.keyword_en[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_en"
                  value={secondPageData.keyword_en[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_en"
                  value={secondPageData.keyword_en[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
                <input
                  type="text"
                  placeholder="IT"
                  name="keyword_en"
                  value={secondPageData.keyword_en[4]}
                  onChange={(e) => handleChange(e, 4)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="prev_next_button">
          <button
            className="prev_button"
            onClick={() => history.push("/plan/rnd/list/first")}
          >
            <img src={prevButton} alt="prevButton" />
          </button>
          <button className="next_button" onClick={() => sendData()}>
            <img src={nextButton} alt="nextButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RnDPlanSecond;
