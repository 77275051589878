import SearchInterested from "../home/SearchInterested";
import "./IpnowIntroTechPopUp.scss";
import React, { useRef } from "react";

const IpnowIntroTechPopUp = ({ setShowPopup }) => {
  const outSection = useRef();

  console.log(setShowPopup);
  return (
    <div
      id="IpnowIntroTechPopUp"
      ref={outSection}
      onClick={(e) => {
        if (outSection.current === e.target) {
          setShowPopup(false);
        }
      }}
    >
      <SearchInterested />
      <i className="icon_exit" onClick={() => setShowPopup(false)} />
    </div>
  );
};

export default IpnowIntroTechPopUp;
