import React from 'react';
import './css/SignUpAlertPopup.scss';

function SignupAlertPopup({onClick}) {
    return (
        <div id="SignUpAlertPopup">
            <i className="icon_bulb"/>
            <h2>보유 IP(특허 · 상표 · 디자인)를<br/>자동으로 불러오시겠습니까?</h2>
            <div className="noti">
                <i className="icon_badge_info"/>미공개 건은 자동입력 되지 않습니다.
            </div>
            <button className='btn_confirm' onClick={onClick}>예</button>
        </div>
    );
}

export default SignupAlertPopup;
