import { useContext, useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import MemberContext from "../../../store/Member";
import ChartTest from "./ChartTest";

const ChartSearch = ({ company_idx }) => {
  const { admin } = useContext(MemberContext);
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (company_idx) {
      AdminAPI.getMailing(company_idx).then((res) => {
        setInfo(res.data);
      });
    }
  }, [company_idx]);

  if (window.$Global.isEmptyObject(info)) return null;

  return <ChartTest info={info} company_idx={company_idx} />;
};

export default ChartSearch;
