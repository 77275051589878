import { useContext, useEffect, useRef, useState } from "react";
import API from "../../util/api";
import memberContext from "../../store/Member";
import { Link } from "react-router-dom/cjs/react-router-dom";
import CheckBox from "../common/CheckBox";
import _ from "lodash";

const Family = ({ patent_idx, setNationList }) => {
  const treeRef = useRef();
  const { auth, manage_company_idx } = useContext(memberContext);
  const [targetPatent, setTargetPatent] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [patentTree, setPatentTree] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectOn, setSelectOn] = useState(false);
  const [showPopupFamily, setShowPopupFamily] = useState(false);
  const [selectFamilyOn, setSelectFamilyOn] = useState(false);
  const [singleClassName, setSingleClassName] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [checkedPatent, setCheckedPatent] = useState({});
  const [disableMerge, setDisableMerge] = useState(false);

  useEffect(() => {
    drawTree(treeData);
  }, [treeData, selectOn, selectFamilyOn, checkedList]);

  useEffect(() => {
    initAPI();
  }, [auth]);

  const initAPI = async () => {
    let result = [];
    if (window.$Global.checkAuth("특허회사", auth)) {
      result = await API.get(`/manager/patent/${patent_idx}/tree`).then(
        (res) => res.data
      );
    } else {
      if (manage_company_idx == undefined) return;
      result = await API.get(
        `/agent/company/${manage_company_idx}/patent/${patent_idx}/tree`
      ).then((res) => res.data);
    }
    setNationList(result);
    setTargetPatent(result.find((item) => item.idx === patent_idx));
    setTreeData(result);
  };

  const drawTree = (res) => {
    let data = res.concat();
    let arr = [];
    let arr_root = data.filter((item) => item.parent_idx === null);

    if (arr_root.length === 1) {
      setSingleClassName("single");
    }

    arr_root.map((item, idx) => {
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );

      return arr.push(
        <li
          key={`${item.idx}_li_${idx}`}
          className={`level_1 ${arr_root.length === 1 ? "single" : ""}`}
        >
          {getItemTag(item, nation)}
          {drawChildPatentRecursion(data, item.idx)}
        </li>
      );
    });
    setPatentTree(arr);
  };

  const getItemTag = (item, nation) => {
    let tag = (
      <>
        <div className="idx">
          {selectOn || selectFamilyOn ? (
            <CheckBox
              checked={checkedList[item.idx] || false}
              onChangeChecked={() => {
                let arr = _.cloneDeep(checkedList);
                arr[item.idx] = !arr[item.idx];
                if (arr[item.idx]) {
                  setCheckedPatent(item);
                } else {
                  setCheckedPatent({});
                }
                if (treeData.find((data) => data.idx == item.idx).parent_idx) {
                  setDisableMerge(true);
                } else {
                  setDisableMerge(false);
                }
                setCheckedList(arr);
              }}
            />
          ) : (
            item.depth
          )}
        </div>
        <div className="flag">
          <i className={`icon_flag_${nation} flag`} />
          <div className="flag_txt">{nation.toUpperCase()}</div>
        </div>
        <div className="info">
          <div className="no">{item.apply_number || "출원 정보 입력 중"}</div>
          <div className="date">
            {item.apply_at ? window.$Global.convertDate(item.apply_at) : ""}
          </div>
        </div>
      </>
    );

    if (selectOn || selectFamilyOn) {
      return <div className="node">{tag}</div>;
    } else {
      return (
        <Link to={`/patent/detail/${item.idx}`} className="node">
          {tag}
        </Link>
      );
    }
  };

  const drawChildPatentRecursion = (data, parent_idx) => {
    let next_patent_arr = data.filter((item) => {
      if (item.dp === 1) {
        return item.parent_idx === parent_idx;
      }
    });

    if (next_patent_arr.length) {
      // for (let i = 0; i < next_patent_arr.length; i++) {
      let item = next_patent_arr[0];
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      return (
        <ul className={`level_${item.depth}`}>
          <li key={`${item.idx}_li`}>
            {getItemTag(item, nation)}
            {drawChildPatentRecursion(data, item.idx)}
          </li>
          {drawSamePatent(data, item.parent_idx, item.idx, item.depth)}
        </ul>
      );
      // }
    }
  };

  const drawSamePatent = (data, parent_idx, self_idx, current_depth) => {
    let same_patent_arr = data.filter(
      (item) =>
        (item.parent_idx === parent_idx &&
          item.depth === current_depth &&
          self_idx !== item.idx) ||
        item.dp == 0
    );

    // for (let i = 0; i < same_patent_arr.length; i++) {
    if (same_patent_arr.length) {
      let item = same_patent_arr[0];
      // let item = same_patent_arr[i];
      let nation = window.$Global.convertNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      return (
        <>
          <li key={`${item.idx}_li`} className={item.dp == 0 ? "dp_0" : ""}>
            {getItemTag(item, nation)}
            {drawChildPatentRecursion(data, item.idx, item.depth)}
          </li>
          {drawSamePatent(data, item.parent_idx, item.idx, item.depth)}
        </>
      );
    }
    // }
  };

  return (
    <div className="tree custom_scroll" ref={treeRef}>
      <ul className={`root ${singleClassName}`}>{patentTree}</ul>
    </div>
  );
};

export default Family;
