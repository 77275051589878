import SideBar from "../../SideBar";
import RnDText from "../RnDText";
import prevButton from "../../../../assets/images/businessPlan/button-prev.svg";
import nextButton from "../../../../assets/images/businessPlan/button-next.svg";
import FirstInfoText from "../../FirstInfoText";
import BringingUpIcon from "../../BringingUpIcon";
import buttonIcon from "../../../../assets/images/businessPlan/button-add-col.svg";
import "./css/RndPlanFifth.scss";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CommonAPI from "../../../../API/common";
import _ from "lodash";

const RnDPlanFifth = () => {
  useEffect(() => {
    CommonAPI.getBusinessData(window.sessionStorage.getItem("itemIdx")).then(
      (res) => {
        getDefaultData(res.data);
        console.log(res.data);
        setResponseData(res.data);
      }
    );
  }, []);

  const history = useHistory();
  const [responseData, setResponseData] = useState({});
  const tableRef = useRef();
  const [fifthPageData, setFifthPageData] = useState({
    targetValueTotal: ["1", "1", "1", "100"],
  });
  const [supportPlan, setSupportPlan] = useState({
    first_first: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    first_last: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    first_subtotal: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    last_first: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    last_last: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    last_subtotal: [
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
      "1",
    ],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
  });

  const [agentUsePlan, setAgentUsePlan] = useState({
    agency_name: ["아이티엘", "1"],
    personnel_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    material_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_rnd_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_burden: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    activity_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    research_allowance: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    subtotal: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    indirect_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    another_support_fund: ["1", "1", "1"],
    base_amount: ["1", "1", "1"],
  });

  const [annualUsePlan, setAnnualUsePlan] = useState({
    personnel_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    material_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_rnd_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_burden: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    activity_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    research_allowance: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    subtotal: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    indirect_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    another_support_fund: ["1", "1", "1"],
    base_amount: ["1", "1", "1"],
  });

  const [supervisorUsePlan, setSupervisorUsePlan] = useState({
    agent_name: "1",
    personnel_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    material_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_rnd_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_burden: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    activity_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    research_allowance: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    subtotal: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    indirect_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    another_support_fund: ["1", "1", "1"],
    base_amount: ["1", "1", "1"],
  });

  const [jointUsePlan, setJointUsePlan] = useState({
    agent_name: "1",
    personnel_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    material_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_rnd_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_burden: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    activity_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    research_allowance: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    subtotal: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    indirect_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    another_support_fund: ["1", "1", "1"],
    base_amount: ["1", "1", "1"],
  });

  const [commissionUsePlan, setCommissionUsePlan] = useState({
    agent_name: "1",
    personnel_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    student_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_general: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    equipment_expense_special: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    material_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_rnd_expense: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    rnd_burden: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    activity_expenses: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    research_allowance: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    subtotal: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    indirect_cost: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    total: ["1", "1", "1", "1", "1", "1", "1", "1", "1"],
    another_support_fund: ["1", "1", "1"],
    base_amount: ["1", "1", "1"],
  });

  const [techPlan, setTechPlan] = useState([
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      construction_method: "1",
      standard: "1",
      quantity: "1",
      construction_cost: "1",
      construction_period: "1",
      install_place: "1",
    },
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      construction_method: "1",
      standard: "1",
      quantity: "1",
      construction_cost: "1",
      construction_period: "1",
      install_place: "1",
    },
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      construction_method: "1",
      standard: "1",
      quantity: "1",
      construction_cost: "1",
      construction_period: "1",
      install_place: "1",
    },
  ]);

  const [utilizationPlan, setUtilizationPlan] = useState([
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      operate_period: "1",
      annual_expenses: "1",
      project_cost: "1",
      cash_sortation: "1",
      personnel_number: "1",
      util_plan: "1",
      install_place: "1",
    },
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      operate_period: "1",
      annual_expenses: "1",
      project_cost: "1",
      cash_sortation: "1",
      personnel_number: "1",
      util_plan: "1",
      install_place: "1",
    },
    {
      agency_name: "1",
      facility_name: "1",
      sortation: "1",
      operate_period: "1",
      annual_expenses: "1",
      project_cost: "1",
      cash_sortation: "1",
      personnel_number: "1",
      util_plan: "1",
      install_place: "1",
    },
  ]);

  const [targetValue, setTargetValue] = useState([
    {
      selectIndicator: "1",
      step: "1",
      first_step: "1",
      last_step: "1",
      total: "1",
      weight_value: "1",
    },
  ]);

  const [performanceIndicators, setPerformanceIndicators] = useState([
    {
      evaluationItem: "1",
      unit: "1",
      importance: "1",
      world_highest_level: "1",
      domestic_level: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      basis: "1",
    },
  ]);

  const [evaluation, setEvaluation] = useState([
    {
      evaluation_items: "1",
      evaluation_method: "1",
      evaluation_environment: "1",
    },
  ]);
  // const [supportPlan, setSupportPlan] = useState({
  //   first_first: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   first_last: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   first_subtotal: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   last_first: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   last_last: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   last_subtotal: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
  // });
  //
  // const [agentUsePlan, setAgentUsePlan] = useState({
  //   agency_name: ["", ""],
  //   personnel_expense: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   material_cost: ["", "", "", "", "", "", "", "", ""],
  //   rnd_expenses: ["", "", "", "", "", "", "", "", ""],
  //   joint_rnd_expense: ["", "", "", "", "", "", "", "", ""],
  //   rnd_burden: ["", "", "", "", "", "", "", "", ""],
  //   activity_expenses: ["", "", "", "", "", "", "", "", ""],
  //   research_allowance: ["", "", "", "", "", "", "", "", ""],
  //   subtotal: ["", "", "", "", "", "", "", "", ""],
  //   indirect_cost: ["", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", ""],
  //   another_support_fund: ["", "", ""],
  //   base_amount: ["", "", ""],
  // });
  //
  // const [annualUsePlan, setAnnualUsePlan] = useState({
  //   personnel_expense: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   material_cost: ["", "", "", "", "", "", "", "", ""],
  //   rnd_expenses: ["", "", "", "", "", "", "", "", ""],
  //   joint_rnd_expense: ["", "", "", "", "", "", "", "", ""],
  //   rnd_burden: ["", "", "", "", "", "", "", "", ""],
  //   activity_expenses: ["", "", "", "", "", "", "", "", ""],
  //   research_allowance: ["", "", "", "", "", "", "", "", ""],
  //   subtotal: ["", "", "", "", "", "", "", "", ""],
  //   indirect_cost: ["", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", ""],
  //   another_support_fund: ["", "", ""],
  //   base_amount: ["", "", ""],
  // });
  //
  // const [supervisorUsePlan, setSupervisorUsePlan] = useState({
  //   agent_name: "",
  //   personnel_expense: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   material_cost: ["", "", "", "", "", "", "", "", ""],
  //   rnd_expenses: ["", "", "", "", "", "", "", "", ""],
  //   joint_rnd_expense: ["", "", "", "", "", "", "", "", ""],
  //   rnd_burden: ["", "", "", "", "", "", "", "", ""],
  //   activity_expenses: ["", "", "", "", "", "", "", "", ""],
  //   research_allowance: ["", "", "", "", "", "", "", "", ""],
  //   subtotal: ["", "", "", "", "", "", "", "", ""],
  //   indirect_cost: ["", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", ""],
  //   another_support_fund: ["", "", ""],
  //   base_amount: ["", "", ""],
  // });
  //
  // const [jointUsePlan, setJointUsePlan] = useState({
  //   agent_name: "",
  //   personnel_expense: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   material_cost: ["", "", "", "", "", "", "", "", ""],
  //   rnd_expenses: ["", "", "", "", "", "", "", "", ""],
  //   joint_rnd_expense: ["", "", "", "", "", "", "", "", ""],
  //   rnd_burden: ["", "", "", "", "", "", "", "", ""],
  //   activity_expenses: ["", "", "", "", "", "", "", "", ""],
  //   research_allowance: ["", "", "", "", "", "", "", "", ""],
  //   subtotal: ["", "", "", "", "", "", "", "", ""],
  //   indirect_cost: ["", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", ""],
  //   another_support_fund: ["", "", ""],
  //   base_amount: ["", "", ""],
  // });
  //
  // const [commissionUsePlan, setCommissionUsePlan] = useState({
  //   agent_name: "",
  //   personnel_expense: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   student_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_general: ["", "", "", "", "", "", "", "", ""],
  //   equipment_expense_special: ["", "", "", "", "", "", "", "", ""],
  //   material_cost: ["", "", "", "", "", "", "", "", ""],
  //   rnd_expenses: ["", "", "", "", "", "", "", "", ""],
  //   joint_rnd_expense: ["", "", "", "", "", "", "", "", ""],
  //   rnd_burden: ["", "", "", "", "", "", "", "", ""],
  //   activity_expenses: ["", "", "", "", "", "", "", "", ""],
  //   research_allowance: ["", "", "", "", "", "", "", "", ""],
  //   subtotal: ["", "", "", "", "", "", "", "", ""],
  //   indirect_cost: ["", "", "", "", "", "", "", "", ""],
  //   total: ["", "", "", "", "", "", "", "", ""],
  //   another_support_fund: ["", "", ""],
  //   base_amount: ["", "", ""],
  // });
  //
  // const [techPlan, setTechPlan] = useState([
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     construction_method: "",
  //     standard: "",
  //     quantity: "",
  //     construction_cost: "",
  //     construction_period: "",
  //     install_place: "",
  //   },
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     construction_method: "",
  //     standard: "",
  //     quantity: "",
  //     construction_cost: "",
  //     construction_period: "",
  //     install_place: "",
  //   },
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     construction_method: "",
  //     standard: "",
  //     quantity: "",
  //     construction_cost: "",
  //     construction_period: "",
  //     install_place: "",
  //   },
  // ]);
  //
  // const [utilizationPlan, setUtilizationPlan] = useState([
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     operate_period: "",
  //     annual_expenses: "",
  //     project_cost: "",
  //     cash_sortation: "",
  //     personnel_number: "",
  //     util_plan: "",
  //     install_place: "",
  //   },
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     operate_period: "",
  //     annual_expenses: "",
  //     project_cost: "",
  //     cash_sortation: "",
  //     personnel_number: "",
  //     util_plan: "",
  //     install_place: "",
  //   },
  //   {
  //     agency_name: "",
  //     facility_name: "",
  //     sortation: "",
  //     operate_period: "",
  //     annual_expenses: "",
  //     project_cost: "",
  //     cash_sortation: "",
  //     personnel_number: "",
  //     util_plan: "",
  //     install_place: "",
  //   },
  // ]);
  //
  // const [targetValue, setTargetValue] = useState([
  //   {
  //     selectIndicator: "",
  //     step: "",
  //     first_step: "",
  //     last_step: "",
  //     total: "",
  //     weight_value: "",
  //   },
  // ]);
  //
  // const [performanceIndicators, setPerformanceIndicators] = useState([
  //   {
  //     evaluationItem: "",
  //     unit: "",
  //     importance: "",
  //     world_highest_level: "",
  //     domestic_level: "",
  //     first_start_year: "",
  //     first_end_year: "",
  //     last_start_year: "",
  //     last_end_year: "",
  //     basis: "",
  //   },
  // ]);
  //
  // const [evaluation, setEvaluation] = useState([
  //   {
  //     evaluation_items: "",
  //     evaluation_method: "",
  //     evaluation_environment: "",
  //   },
  // ]);

  const getSelectData = (data) => {
    if (data === "전담기관 등록ㆍ기탁지표") {
      return "agency";
    } else if (data === "연구개발과제 특성 반영 지표") {
      return "project";
    }
  };

  const getDefaultData = (data) => {
    let content;
    data.content.forEach((item, index) => {
      if (item.subject === "연구개발비 지원 부담 계획 표") {
        console.log(item);
        supportPlan["first_first"] = [
          item.content[3][2].content,
          item.content[3][3].content,
          item.content[3][4].content,
          item.content[3][5].content,
          item.content[3][6].content,
          item.content[3][7].content,
          item.content[3][8].content,
          item.content[3][9].content,
          item.content[3][10].content,
          item.content[3][11].content,
          item.content[3][12].content,
          item.content[3][13].content,
          item.content[3][14].content,
          item.content[3][15].content,
        ];
        supportPlan["first_last"] = [
          item.content[4][2].content,
          item.content[4][3].content,
          item.content[4][4].content,
          item.content[4][5].content,
          item.content[4][6].content,
          item.content[4][7].content,
          item.content[4][8].content,
          item.content[4][9].content,
          item.content[4][10].content,
          item.content[4][11].content,
          item.content[4][12].content,
          item.content[4][13].content,
          item.content[4][14].content,
          item.content[4][15].content,
        ];
        supportPlan["first_subtotal"] = [
          item.content[5][3].content,
          item.content[5][4].content,
          item.content[5][5].content,
          item.content[5][6].content,
          item.content[5][7].content,
          item.content[5][8].content,
          item.content[5][9].content,
          item.content[5][10].content,
          item.content[5][11].content,
          item.content[5][12].content,
          item.content[5][13].content,
          item.content[5][14].content,
          item.content[5][15].content,
        ];
        supportPlan["last_first"] = [
          item.content[6][2].content,
          item.content[6][3].content,
          item.content[6][4].content,
          item.content[6][5].content,
          item.content[6][6].content,
          item.content[6][7].content,
          item.content[6][8].content,
          item.content[6][9].content,
          item.content[6][10].content,
          item.content[6][11].content,
          item.content[6][12].content,
          item.content[6][13].content,
          item.content[6][14].content,
          item.content[6][15].content,
        ];
        supportPlan["last_last"] = [
          item.content[7][2].content,
          item.content[7][3].content,
          item.content[7][4].content,
          item.content[7][5].content,
          item.content[7][6].content,
          item.content[7][7].content,
          item.content[7][8].content,
          item.content[7][9].content,
          item.content[7][10].content,
          item.content[7][11].content,
          item.content[7][12].content,
          item.content[7][13].content,
          item.content[7][14].content,
          item.content[7][15].content,
        ];
        supportPlan["last_subtotal"] = [
          item.content[8][3].content,
          item.content[8][4].content,
          item.content[8][5].content,
          item.content[8][6].content,
          item.content[8][7].content,
          item.content[8][8].content,
          item.content[8][9].content,
          item.content[8][10].content,
          item.content[8][11].content,
          item.content[8][12].content,
          item.content[8][13].content,
          item.content[8][14].content,
          item.content[8][15].content,
        ];
        supportPlan["total"] = [
          item.content[9][3].content,
          item.content[9][4].content,
          item.content[9][5].content,
          item.content[9][6].content,
          item.content[9][7].content,
          item.content[9][8].content,
          item.content[9][9].content,
          item.content[9][10].content,
          item.content[9][11].content,
          item.content[9][12].content,
          item.content[9][13].content,
          item.content[9][14].content,
          item.content[9][15].content,
        ];
        setSupportPlan({ ...supportPlan });
      } else if (item.subject === "연구개발기관별 사용계획 표") {
        console.log(item);
        agentUsePlan["agency_name"] = [
          item.content[4][0].content,
          item.content[7][0].content,
        ];
        agentUsePlan["personnel_expense"] = [
          item.content[4][2].content,
          item.content[5][2].content,
          item.content[6][2].content,
          item.content[7][2].content,
          item.content[8][2].content,
          item.content[9][2].content,
          item.content[10][2].content,
          item.content[11][2].content,
          item.content[12][2].content,
        ];
        agentUsePlan["student_expense_general"] = [
          item.content[4][3].content,
          item.content[5][3].content,
          item.content[6][3].content,
          item.content[7][3].content,
          item.content[8][3].content,
          item.content[9][3].content,
          item.content[10][3].content,
          item.content[11][3].content,
          item.content[12][3].content,
        ];
        agentUsePlan["student_expense_special"] = [
          item.content[4][4].content,
          item.content[5][4].content,
          item.content[6][4].content,
          item.content[7][4].content,
          item.content[8][4].content,
          item.content[9][4].content,
          item.content[10][4].content,
          item.content[11][4].content,
          item.content[12][4].content,
        ];
        agentUsePlan["equipment_expense_general"] = [
          item.content[4][5].content,
          item.content[5][5].content,
          item.content[6][5].content,
          item.content[7][5].content,
          item.content[8][5].content,
          item.content[9][5].content,
          item.content[10][5].content,
          item.content[11][5].content,
          item.content[12][5].content,
        ];
        agentUsePlan["equipment_expense_special"] = [
          item.content[4][6].content,
          item.content[5][6].content,
          item.content[6][6].content,
          item.content[7][6].content,
          item.content[8][6].content,
          item.content[9][6].content,
          item.content[10][6].content,
          item.content[11][6].content,
          item.content[12][6].content,
        ];
        agentUsePlan["material_cost"] = [
          item.content[4][7].content,
          item.content[5][7].content,
          item.content[6][7].content,
          item.content[7][7].content,
          item.content[8][7].content,
          item.content[9][7].content,
          item.content[10][7].content,
          item.content[11][7].content,
          item.content[12][7].content,
        ];
        agentUsePlan["rnd_expenses"] = [
          item.content[4][8].content,
          item.content[5][8].content,
          item.content[6][8].content,
          item.content[7][8].content,
          item.content[8][8].content,
          item.content[9][8].content,
          item.content[10][8].content,
          item.content[11][8].content,
          item.content[12][8].content,
        ];
        agentUsePlan["joint_rnd_expense"] = [
          item.content[4][9].content,
          item.content[5][9].content,
          item.content[6][9].content,
          item.content[7][9].content,
          item.content[8][9].content,
          item.content[9][9].content,
          item.content[10][9].content,
          item.content[11][9].content,
          item.content[12][9].content,
        ];
        agentUsePlan["rnd_burden"] = [
          item.content[4][10].content,
          item.content[5][10].content,
          item.content[6][10].content,
          item.content[7][10].content,
          item.content[8][10].content,
          item.content[9][10].content,
          item.content[10][10].content,
          item.content[11][10].content,
          item.content[12][10].content,
        ];
        agentUsePlan["activity_expenses"] = [
          item.content[4][11].content,
          item.content[5][11].content,
          item.content[6][11].content,
          item.content[7][11].content,
          item.content[8][11].content,
          item.content[9][11].content,
          item.content[10][11].content,
          item.content[11][11].content,
          item.content[12][11].content,
        ];
        agentUsePlan["research_allowance"] = [
          item.content[4][12].content,
          item.content[5][12].content,
          item.content[6][12].content,
          item.content[7][12].content,
          item.content[8][12].content,
          item.content[9][12].content,
          item.content[10][12].content,
          item.content[11][12].content,
          item.content[12][12].content,
        ];
        agentUsePlan["subtotal"] = [
          item.content[4][13].content,
          item.content[5][13].content,
          item.content[6][13].content,
          item.content[7][13].content,
          item.content[8][13].content,
          item.content[9][13].content,
          item.content[10][13].content,
          item.content[11][13].content,
          item.content[12][13].content,
        ];
        agentUsePlan["indirect_cost"] = [
          item.content[4][14].content,
          item.content[5][14].content,
          item.content[6][14].content,
          item.content[7][14].content,
          item.content[8][14].content,
          item.content[9][14].content,
          item.content[10][14].content,
          item.content[11][14].content,
          item.content[12][14].content,
        ];
        agentUsePlan["total"] = [
          item.content[4][15].content,
          item.content[5][15].content,
          item.content[6][15].content,
          item.content[7][15].content,
          item.content[8][15].content,
          item.content[9][15].content,
          item.content[10][15].content,
          item.content[11][15].content,
          item.content[12][15].content,
        ];
        agentUsePlan["another_support_fund"] = [
          item.content[4][16].content,
          item.content[7][16].content,
          item.content[10][16].content,
        ];
        agentUsePlan["base_amount"] = [
          item.content[4][17].content,
          item.content[7][17].content,
          item.content[10][17].content,
        ];
      } else if (item.subject === "연차별 사용계획 표") {
      } else if (item.subject === "주관연구개발기관 연차별 사용계획 제목") {
        supervisorUsePlan["agent_name"] = item.content.split("명")[1];
      } else if (item.subject === "주관연구개발기관 연차별 사용계획 표") {
        supervisorUsePlan["personnel_expense"] = [
          item.content[4][2].content,
          item.content[5][2].content,
          item.content[6][2].content,
          item.content[7][2].content,
          item.content[8][2].content,
          item.content[9][2].content,
          item.content[10][2].content,
          item.content[11][2].content,
          item.content[12][2].content,
        ];
        supervisorUsePlan["student_expense_general"] = [
          item.content[4][3].content,
          item.content[5][3].content,
          item.content[6][3].content,
          item.content[7][3].content,
          item.content[8][3].content,
          item.content[9][3].content,
          item.content[10][3].content,
          item.content[11][3].content,
          item.content[12][3].content,
        ];
        supervisorUsePlan["student_expense_special"] = [
          item.content[4][4].content,
          item.content[5][4].content,
          item.content[6][4].content,
          item.content[7][4].content,
          item.content[8][4].content,
          item.content[9][4].content,
          item.content[10][4].content,
          item.content[11][4].content,
          item.content[12][4].content,
        ];
        supervisorUsePlan["equipment_expense_general"] = [
          item.content[4][5].content,
          item.content[5][5].content,
          item.content[6][5].content,
          item.content[7][5].content,
          item.content[8][5].content,
          item.content[9][5].content,
          item.content[10][5].content,
          item.content[11][5].content,
          item.content[12][5].content,
        ];
        supervisorUsePlan["equipment_expense_special"] = [
          item.content[4][6].content,
          item.content[5][6].content,
          item.content[6][6].content,
          item.content[7][6].content,
          item.content[8][6].content,
          item.content[9][6].content,
          item.content[10][6].content,
          item.content[11][6].content,
          item.content[12][6].content,
        ];
        supervisorUsePlan["material_cost"] = [
          item.content[4][7].content,
          item.content[5][7].content,
          item.content[6][7].content,
          item.content[7][7].content,
          item.content[8][7].content,
          item.content[9][7].content,
          item.content[10][7].content,
          item.content[11][7].content,
          item.content[12][7].content,
        ];
        supervisorUsePlan["rnd_expenses"] = [
          item.content[4][8].content,
          item.content[5][8].content,
          item.content[6][8].content,
          item.content[7][8].content,
          item.content[8][8].content,
          item.content[9][8].content,
          item.content[10][8].content,
          item.content[11][8].content,
          item.content[12][8].content,
        ];
        supervisorUsePlan["joint_rnd_expense"] = [
          item.content[4][9].content,
          item.content[5][9].content,
          item.content[6][9].content,
          item.content[7][9].content,
          item.content[8][9].content,
          item.content[9][9].content,
          item.content[10][9].content,
          item.content[11][9].content,
          item.content[12][9].content,
        ];
        supervisorUsePlan["rnd_burden"] = [
          item.content[4][10].content,
          item.content[5][10].content,
          item.content[6][10].content,
          item.content[7][10].content,
          item.content[8][10].content,
          item.content[9][10].content,
          item.content[10][10].content,
          item.content[11][10].content,
          item.content[12][10].content,
        ];
        supervisorUsePlan["activity_expenses"] = [
          item.content[4][11].content,
          item.content[5][11].content,
          item.content[6][11].content,
          item.content[7][11].content,
          item.content[8][11].content,
          item.content[9][11].content,
          item.content[10][11].content,
          item.content[11][11].content,
          item.content[12][11].content,
        ];
        supervisorUsePlan["research_allowance"] = [
          item.content[4][12].content,
          item.content[5][12].content,
          item.content[6][12].content,
          item.content[7][12].content,
          item.content[8][12].content,
          item.content[9][12].content,
          item.content[10][12].content,
          item.content[11][12].content,
          item.content[12][12].content,
        ];
        supervisorUsePlan["subtotal"] = [
          item.content[4][13].content,
          item.content[5][13].content,
          item.content[6][13].content,
          item.content[7][13].content,
          item.content[8][13].content,
          item.content[9][13].content,
          item.content[10][13].content,
          item.content[11][13].content,
          item.content[12][13].content,
        ];
        supervisorUsePlan["indirect_cost"] = [
          item.content[4][14].content,
          item.content[5][14].content,
          item.content[6][14].content,
          item.content[7][14].content,
          item.content[8][14].content,
          item.content[9][14].content,
          item.content[10][14].content,
          item.content[11][14].content,
          item.content[12][14].content,
        ];
        supervisorUsePlan["total"] = [
          item.content[4][15].content,
          item.content[5][15].content,
          item.content[6][15].content,
          item.content[7][15].content,
          item.content[8][15].content,
          item.content[9][15].content,
          item.content[10][15].content,
          item.content[11][15].content,
          item.content[12][15].content,
        ];
        supervisorUsePlan["another_support_fund"] = [
          item.content[4][16].content,
          item.content[7][16].content,
          item.content[10][16].content,
        ];
        supervisorUsePlan["base_amount"] = [
          item.content[4][17].content,
          item.content[7][17].content,
          item.content[10][17].content,
        ];
      } else if (item.subject === "공동연구개발기관 연차별 사용계획 제목") {
        jointUsePlan["agent_name"] = item.content.split("명")[1];
      } else if (item.subject === "공동연구개발기관 연차별 사용계획 표") {
        jointUsePlan["personnel_expense"] = [
          item.content[4][2].content,
          item.content[5][2].content,
          item.content[6][2].content,
          item.content[7][2].content,
          item.content[8][2].content,
          item.content[9][2].content,
          item.content[10][2].content,
          item.content[11][2].content,
          item.content[12][2].content,
        ];
        jointUsePlan["student_expense_general"] = [
          item.content[4][3].content,
          item.content[5][3].content,
          item.content[6][3].content,
          item.content[7][3].content,
          item.content[8][3].content,
          item.content[9][3].content,
          item.content[10][3].content,
          item.content[11][3].content,
          item.content[12][3].content,
        ];
        jointUsePlan["student_expense_special"] = [
          item.content[4][4].content,
          item.content[5][4].content,
          item.content[6][4].content,
          item.content[7][4].content,
          item.content[8][4].content,
          item.content[9][4].content,
          item.content[10][4].content,
          item.content[11][4].content,
          item.content[12][4].content,
        ];
        jointUsePlan["equipment_expense_general"] = [
          item.content[4][5].content,
          item.content[5][5].content,
          item.content[6][5].content,
          item.content[7][5].content,
          item.content[8][5].content,
          item.content[9][5].content,
          item.content[10][5].content,
          item.content[11][5].content,
          item.content[12][5].content,
        ];
        jointUsePlan["equipment_expense_special"] = [
          item.content[4][6].content,
          item.content[5][6].content,
          item.content[6][6].content,
          item.content[7][6].content,
          item.content[8][6].content,
          item.content[9][6].content,
          item.content[10][6].content,
          item.content[11][6].content,
          item.content[12][6].content,
        ];
        jointUsePlan["material_cost"] = [
          item.content[4][7].content,
          item.content[5][7].content,
          item.content[6][7].content,
          item.content[7][7].content,
          item.content[8][7].content,
          item.content[9][7].content,
          item.content[10][7].content,
          item.content[11][7].content,
          item.content[12][7].content,
        ];
        jointUsePlan["rnd_expenses"] = [
          item.content[4][8].content,
          item.content[5][8].content,
          item.content[6][8].content,
          item.content[7][8].content,
          item.content[8][8].content,
          item.content[9][8].content,
          item.content[10][8].content,
          item.content[11][8].content,
          item.content[12][8].content,
        ];
        jointUsePlan["joint_rnd_expense"] = [
          item.content[4][9].content,
          item.content[5][9].content,
          item.content[6][9].content,
          item.content[7][9].content,
          item.content[8][9].content,
          item.content[9][9].content,
          item.content[10][9].content,
          item.content[11][9].content,
          item.content[12][9].content,
        ];
        jointUsePlan["rnd_burden"] = [
          item.content[4][10].content,
          item.content[5][10].content,
          item.content[6][10].content,
          item.content[7][10].content,
          item.content[8][10].content,
          item.content[9][10].content,
          item.content[10][10].content,
          item.content[11][10].content,
          item.content[12][10].content,
        ];
        jointUsePlan["activity_expenses"] = [
          item.content[4][11].content,
          item.content[5][11].content,
          item.content[6][11].content,
          item.content[7][11].content,
          item.content[8][11].content,
          item.content[9][11].content,
          item.content[10][11].content,
          item.content[11][11].content,
          item.content[12][11].content,
        ];
        jointUsePlan["research_allowance"] = [
          item.content[4][12].content,
          item.content[5][12].content,
          item.content[6][12].content,
          item.content[7][12].content,
          item.content[8][12].content,
          item.content[9][12].content,
          item.content[10][12].content,
          item.content[11][12].content,
          item.content[12][12].content,
        ];
        jointUsePlan["subtotal"] = [
          item.content[4][13].content,
          item.content[5][13].content,
          item.content[6][13].content,
          item.content[7][13].content,
          item.content[8][13].content,
          item.content[9][13].content,
          item.content[10][13].content,
          item.content[11][13].content,
          item.content[12][13].content,
        ];
        jointUsePlan["indirect_cost"] = [
          item.content[4][14].content,
          item.content[5][14].content,
          item.content[6][14].content,
          item.content[7][14].content,
          item.content[8][14].content,
          item.content[9][14].content,
          item.content[10][14].content,
          item.content[11][14].content,
          item.content[12][14].content,
        ];
        jointUsePlan["total"] = [
          item.content[4][15].content,
          item.content[5][15].content,
          item.content[6][15].content,
          item.content[7][15].content,
          item.content[8][15].content,
          item.content[9][15].content,
          item.content[10][15].content,
          item.content[11][15].content,
          item.content[12][15].content,
        ];
        jointUsePlan["another_support_fund"] = [
          item.content[4][16].content,
          item.content[7][16].content,
          item.content[10][16].content,
        ];
        jointUsePlan["base_amount"] = [
          item.content[4][17].content,
          item.content[7][17].content,
          item.content[10][17].content,
        ];
      } else if (item.subject === "위탁연구개발기관 연차별 사용계획 제목") {
        commissionUsePlan["agent_name"] = item.content.split("명")[1];
      } else if (item.subject === "위탁연구개발기관 연차별 사용계획 표") {
        commissionUsePlan["personnel_expense"] = [
          item.content[4][2].content,
          item.content[5][2].content,
          item.content[6][2].content,
          item.content[7][2].content,
          item.content[8][2].content,
          item.content[9][2].content,
          item.content[10][2].content,
          item.content[11][2].content,
          item.content[12][2].content,
        ];
        commissionUsePlan["student_expense_general"] = [
          item.content[4][3].content,
          item.content[5][3].content,
          item.content[6][3].content,
          item.content[7][3].content,
          item.content[8][3].content,
          item.content[9][3].content,
          item.content[10][3].content,
          item.content[11][3].content,
          item.content[12][3].content,
        ];
        commissionUsePlan["student_expense_special"] = [
          item.content[4][4].content,
          item.content[5][4].content,
          item.content[6][4].content,
          item.content[7][4].content,
          item.content[8][4].content,
          item.content[9][4].content,
          item.content[10][4].content,
          item.content[11][4].content,
          item.content[12][4].content,
        ];
        commissionUsePlan["equipment_expense_general"] = [
          item.content[4][5].content,
          item.content[5][5].content,
          item.content[6][5].content,
          item.content[7][5].content,
          item.content[8][5].content,
          item.content[9][5].content,
          item.content[10][5].content,
          item.content[11][5].content,
          item.content[12][5].content,
        ];
        commissionUsePlan["equipment_expense_special"] = [
          item.content[4][6].content,
          item.content[5][6].content,
          item.content[6][6].content,
          item.content[7][6].content,
          item.content[8][6].content,
          item.content[9][6].content,
          item.content[10][6].content,
          item.content[11][6].content,
          item.content[12][6].content,
        ];
        commissionUsePlan["material_cost"] = [
          item.content[4][7].content,
          item.content[5][7].content,
          item.content[6][7].content,
          item.content[7][7].content,
          item.content[8][7].content,
          item.content[9][7].content,
          item.content[10][7].content,
          item.content[11][7].content,
          item.content[12][7].content,
        ];
        commissionUsePlan["rnd_expenses"] = [
          item.content[4][8].content,
          item.content[5][8].content,
          item.content[6][8].content,
          item.content[7][8].content,
          item.content[8][8].content,
          item.content[9][8].content,
          item.content[10][8].content,
          item.content[11][8].content,
          item.content[12][8].content,
        ];
        commissionUsePlan["joint_rnd_expense"] = [
          item.content[4][9].content,
          item.content[5][9].content,
          item.content[6][9].content,
          item.content[7][9].content,
          item.content[8][9].content,
          item.content[9][9].content,
          item.content[10][9].content,
          item.content[11][9].content,
          item.content[12][9].content,
        ];
        commissionUsePlan["rnd_burden"] = [
          item.content[4][10].content,
          item.content[5][10].content,
          item.content[6][10].content,
          item.content[7][10].content,
          item.content[8][10].content,
          item.content[9][10].content,
          item.content[10][10].content,
          item.content[11][10].content,
          item.content[12][10].content,
        ];
        commissionUsePlan["activity_expenses"] = [
          item.content[4][11].content,
          item.content[5][11].content,
          item.content[6][11].content,
          item.content[7][11].content,
          item.content[8][11].content,
          item.content[9][11].content,
          item.content[10][11].content,
          item.content[11][11].content,
          item.content[12][11].content,
        ];
        commissionUsePlan["research_allowance"] = [
          item.content[4][12].content,
          item.content[5][12].content,
          item.content[6][12].content,
          item.content[7][12].content,
          item.content[8][12].content,
          item.content[9][12].content,
          item.content[10][12].content,
          item.content[11][12].content,
          item.content[12][12].content,
        ];
        commissionUsePlan["subtotal"] = [
          item.content[4][13].content,
          item.content[5][13].content,
          item.content[6][13].content,
          item.content[7][13].content,
          item.content[8][13].content,
          item.content[9][13].content,
          item.content[10][13].content,
          item.content[11][13].content,
          item.content[12][13].content,
        ];
        commissionUsePlan["indirect_cost"] = [
          item.content[4][14].content,
          item.content[5][14].content,
          item.content[6][14].content,
          item.content[7][14].content,
          item.content[8][14].content,
          item.content[9][14].content,
          item.content[10][14].content,
          item.content[11][14].content,
          item.content[12][14].content,
        ];
        commissionUsePlan["total"] = [
          item.content[4][15].content,
          item.content[5][15].content,
          item.content[6][15].content,
          item.content[7][15].content,
          item.content[8][15].content,
          item.content[9][15].content,
          item.content[10][15].content,
          item.content[11][15].content,
          item.content[12][15].content,
        ];
        commissionUsePlan["another_support_fund"] = [
          item.content[4][16].content,
          item.content[7][16].content,
          item.content[10][16].content,
        ];
        commissionUsePlan["base_amount"] = [
          item.content[4][17].content,
          item.content[7][17].content,
          item.content[10][17].content,
        ];
      } else if (item.subject === "연구시설 장비 구축계획 표") {
        content = [
          {
            agency_name: item.content[1][0].content,
            facility_name: item.content[1][1].content,
            sortation: item.content[1][2].content,
            construction_method: item.content[1][3].content,
            standard: item.content[1][4].content,
            quantity: item.content[1][5].content,
            construction_cost: item.content[1][6].content,
            construction_period: item.content[1][7].content,
            install_place: item.content[1][8].content,
          },
          {
            agency_name: item.content[2][0].content,
            facility_name: item.content[2][1].content,
            sortation: item.content[2][2].content,
            construction_method: item.content[2][3].content,
            standard: item.content[2][4].content,
            quantity: item.content[2][5].content,
            construction_cost: item.content[2][6].content,
            construction_period: item.content[2][7].content,
            install_place: item.content[2][8].content,
          },
          {
            agency_name: item.content[3][0].content,
            facility_name: item.content[3][1].content,
            sortation: item.content[3][2].content,
            construction_method: item.content[3][3].content,
            standard: item.content[3][4].content,
            quantity: item.content[3][5].content,
            construction_cost: item.content[3][6].content,
            construction_period: item.content[3][7].content,
            install_place: item.content[3][8].content,
          },
        ];
        setTechPlan(content);
      } else if (item.subject === "연구시설 장비 운영 활용계획 표") {
        content = [
          {
            agency_name: item.content[2][0].content,
            facility_name: item.content[2][1].content,
            sortation: item.content[2][2].content,
            operate_period: item.content[2][3].content,
            annual_expenses: item.content[2][4].content,
            project_cost: item.content[2][5].content,
            cash_sortation: item.content[2][6].content,
            personnel_number: item.content[2][7].content,
            util_plan: item.content[2][8].content,
            install_place: item.content[2][9].content,
          },
          {
            agency_name: item.content[3][0].content,
            facility_name: item.content[3][1].content,
            sortation: item.content[3][2].content,
            operate_period: item.content[3][3].content,
            annual_expenses: item.content[3][4].content,
            project_cost: item.content[3][5].content,
            cash_sortation: item.content[3][6].content,
            personnel_number: item.content[3][7].content,
            util_plan: item.content[3][8].content,
            install_place: item.content[3][9].content,
          },
          {
            agency_name: item.content[4][0].content,
            facility_name: item.content[4][1].content,
            sortation: item.content[4][2].content,
            operate_period: item.content[4][3].content,
            annual_expenses: item.content[4][4].content,
            project_cost: item.content[4][5].content,
            cash_sortation: item.content[4][6].content,
            personnel_number: item.content[4][7].content,
            util_plan: item.content[4][8].content,
            install_place: item.content[4][9].content,
          },
        ];
        setUtilizationPlan(content);
      } else if (item.subject === "성과지표 및 목표치 표") {
        fifthPageData["targetValueTotal"] = [
          item.content[2][2].content,
          item.content[2][3].content,
          item.content[2][4].content,
          item.content[2][5].content,
        ];
        content = [
          {
            selectIndicator: getSelectData(item.content[1][0].content),
            step: item.content[1][1].content,
            first_step: item.content[1][2].content,
            last_step: item.content[1][3].content,
            total: item.content[1][4].content,
            weight_value: item.content[1][5].content,
          },
        ];
        setTargetValue(content);
      } else if (item.subject === "결과물의 성능지표 표") {
        console.log(item);
        content = [
          {
            evaluationItem: item.content[2][0].content,
            unit: item.content[2][1].content,
            importance: item.content[2][2].content,
            world_highest_level: item.content[2][3].content,
            domestic_level: item.content[2][4].content,
            first_start_year: item.content[2][5].content.split("~")[0],
            first_end_year: item.content[2][5].content.split("~")[1],
            last_start_year: item.content[2][6].content.split("~")[0],
            last_end_year: item.content[2][6].content.split("~")[1],
            basis: item.content[2][7].content,
          },
        ];
        setPerformanceIndicators(content);
      } else if (item.subject === "평가방법 및 평가환경 표") {
        content = [
          {
            evaluation_items: item.content[1][1].content,
            evaluation_method: item.content[1][2].content,
            evaluation_environment: item.content[1][3].content,
          },
        ];
        setEvaluation(content);
      }
    });
  };
  const handleChange = (e, idx, secondIdx) => {
    const { name, value } = e.target;
    if (name === "annualUsePlan") {
      annualUsePlan[secondIdx][idx] = value;
      setAnnualUsePlan({ ...annualUsePlan });
    } else if (name === "supervisorUsePlan") {
      supervisorUsePlan[secondIdx][idx] = value;
      setSupervisorUsePlan({ ...supervisorUsePlan });
    } else if (name === "jointUsePlan") {
      jointUsePlan[secondIdx][idx] = value;
      setJointUsePlan({ ...jointUsePlan });
    } else if (name === "commissionUsePlan") {
      commissionUsePlan[secondIdx][idx] = value;
      setCommissionUsePlan({ ...commissionUsePlan });
    } else if (name === "fifthPageData") {
      fifthPageData[secondIdx][idx] = value;
      setFifthPageData({ ...fifthPageData });
    } else if (name === "supportPlan") {
      supportPlan[secondIdx][idx] = value;
      setSupportPlan({ ...supportPlan });
    } else if (name === "agentUsePlan") {
      agentUsePlan[secondIdx][idx] = value;
      setAgentUsePlan({ ...agentUsePlan });
    }
  };

  const handleChangeTable = (e, index, itemName) => {
    const { name, value } = e.target;

    if (name === "techPlan") {
      techPlan[index][itemName] = value;
      setTechPlan([...techPlan]);
    } else if (name === "utilizationPlan") {
      utilizationPlan[index][itemName] = value;
      setUtilizationPlan([...utilizationPlan]);
    } else if (name === "targetValue") {
      targetValue[index][itemName] = value;
      setTargetValue([...targetValue]);
    } else if (name === "performanceIndicators") {
      performanceIndicators[index][itemName] = value;
      setPerformanceIndicators([...performanceIndicators]);
    } else if (name === "evaluation") {
      evaluation[index][itemName] = value;
      setEvaluation([...evaluation]);
    }
  };

  const addTableRows = (e) => {
    const { name } = e.target;
    let inputRows;
    console.log(name);
    if (name === "techPlan") {
      inputRows = {
        agency_name: "",
        facility_name: "",
        sortation: "",
        construction_method: "",
        standard: "",
        quantity: "",
        construction_cost: "",
        construction_period: "",
        install_place: "",
      };
      setTechPlan([...techPlan, inputRows]);
    } else if (name === "utilizationPlan") {
      inputRows = {
        agency_name: "",
        facility_name: "",
        sortation: "",
        operate_period: "",
        annual_expenses: "",
        project_cost: "",
        cash_sortation: "",
        personnel_number: "",
        util_plan: "",
        install_place: "",
      };
      setUtilizationPlan([...utilizationPlan, inputRows]);
    } else if (name === "targetValue") {
      inputRows = {
        selectIndicator: "",
        step: "",
        first_step: "",
        last_step: "",
        total: "",
        weight_value: "",
      };
      setTargetValue([...targetValue, inputRows]);
    } else if (name === "performanceIndicators") {
      inputRows = {
        evaluationItem: "",
        unit: "",
        importance: "",
        world_highest_level: "",
        domestic_level: "",
        first_start_year: "",
        first_end_year: "",
        last_start_year: "",
        last_end_year: "",
        basis: "",
      };
      setPerformanceIndicators([...performanceIndicators, inputRows]);
    } else if (name === "evaluation") {
      inputRows = {
        evaluation_items: "",
        evaluation_method: "",
        evaluation_environment: "",
      };
      setEvaluation([...evaluation, inputRows]);
    }
  };

  const sendData = () => {
    let techPlanArr = [
      [
        {
          type: "text",
          content: "연구개발기관명",
        },
        {
          type: "text",
          content: "연구시설ㆍ장비명",
        },
        {
          type: "text",
          content: "현금/현물구분",
        },
        {
          type: "text",
          content: "구축방식",
        },
        {
          type: "text",
          content: "규격",
        },
        {
          type: "text",
          content: "수량",
        },
        {
          type: "text",
          content: "구축비용",
        },
        {
          type: "text",
          content: "구축기간",
        },
        {
          type: "text",
          content: "구축지간",
        },
      ],
    ];
    techPlan.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency_name,
        },
        {
          type: "text",
          content: el.facility_name,
        },
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.construction_method,
        },
        {
          type: "text",
          content: el.standard,
        },
        {
          type: "text",
          content: el.quantity,
        },
        {
          type: "text",
          content: el.construction_cost,
        },
        {
          type: "text",
          content: el.construction_period,
        },
        {
          type: "text",
          content: el.install_place,
        },
      ];
      techPlanArr.push(data);
    });

    let utilizationPlanArr = [
      [
        {
          type: "text",
          content: "연구개발기관명",
        },
        {
          type: "text",
          content: "연구시설명",
        },
        {
          type: "text",
          content: "기존/신규 구분",
        },
        {
          type: "text",
          content: "운영기간",
        },
        {
          type: "text",
          content: "비용",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "전담인력수",
        },
        {
          type: "text",
          content: "활용계획",
        },
        {
          type: "text",
          content: "설치장소",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "연간운영비용",
        },
        {
          type: "text",
          content: "과제반영비용",
        },
        {
          type: "text",
          content: "현금/현물 구분",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    utilizationPlan.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency_name,
        },
        {
          type: "text",
          content: el.facility_name,
        },
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.operate_period,
        },
        {
          type: "text",
          content: el.annual_expenses,
        },
        {
          type: "text",
          content: el.project_cost,
        },
        {
          type: "text",
          content: el.cash_sortation,
        },
        {
          type: "text",
          content: el.personnel_number,
        },
        {
          type: "text",
          content: el.util_plan,
        },
        {
          type: "text",
          content: el.install_place,
        },
      ];
      utilizationPlanArr.push(data);
    });

    let targetValueArr = [
      [
        {
          type: "text",
          content: "성과지표명",
        },
        {
          type: "text",
          content: "단계",
        },
        {
          type: "text",
          content: "1단계(yy~yy)",
        },
        {
          type: "text",
          content: "n단계(yy~yy)",
        },
        {
          type: "text",
          content: "계",
        },
        {
          type: "text",
          content: "가중치(%)",
        },
      ],
    ];

    targetValue.map((el) => {
      let data = [
        {
          type: "text",
          content: `${
            el.selectIndicator === "agency"
              ? "전담기관 등록ㆍ기탁지표"
              : "연구개발과제 특성 반영 지표"
          }`,
        },
        {
          type: "text",
          content: el.step,
        },
        {
          type: "text",
          content: el.first_step,
        },
        {
          type: "text",
          content: el.last_step,
        },
        {
          type: "text",
          content: el.total,
        },
        {
          type: "text",
          content: el.weight_value,
        },
      ];
      targetValueArr.push(data);
    });

    targetValueArr.push([
      {
        type: "text",
        content: "계",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: fifthPageData.targetValueTotal[0],
      },
      {
        type: "text",
        content: fifthPageData.targetValueTotal[1],
      },
      {
        type: "text",
        content: fifthPageData.targetValueTotal[2],
      },
      {
        type: "text",
        content: fifthPageData.targetValueTotal[3],
      },
    ]);

    let performanceIndicatorsArr = [
      [
        {
          type: "text",
          content: "평가 항목",
        },
        {
          type: "text",
          content: "단위",
        },
        {
          type: "text",
          content: "전체 항목에서 차지하는 비중 (%)",
        },
        {
          type: "text",
          content: "세계 최고수준 보유국/보유기관",
        },
        {
          type: "text",
          content: "연구개발 전 국내 수준",
        },
        {
          type: "text",
          content: "연구개발 목표치",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "목표 설정 근거",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "성능수준",
        },
        {
          type: "text",
          content: "성능수준",
        },
        {
          type: "text",
          content: "1단계(yy~yy)",
        },
        {
          type: "text",
          content: "n단계(yy~yy)",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    performanceIndicators.map((el) => {
      let data = [
        {
          type: "text",
          content: el.evaluationItem,
        },
        {
          type: "text",
          content: el.unit,
        },
        {
          type: "text",
          content: el.importance,
        },
        {
          type: "text",
          content: el.world_highest_level,
        },
        {
          type: "text",
          content: el.domestic_level,
        },
        {
          type: "text",
          content: `${el.first_start_year}~${el.first_end_year}`,
        },
        {
          type: "text",
          content: `${el.last_start_year}~${el.last_end_year}`,
        },
        {
          type: "text",
          content: el.basis,
        },
      ];
      performanceIndicatorsArr.push(data);
    });

    let evaluationArr = [
      [
        {
          type: "text",
          content: "순번",
        },
        {
          type: "text",
          content: "평가항목\n(성능지표)",
        },
        {
          type: "text",
          content: "평가방법",
        },
        {
          type: "text",
          content: "평가환경",
        },
      ],
    ];

    evaluation.map((el, index) => {
      let data = [
        {
          type: "text",
          content: index + 1,
        },
        {
          type: "text",
          content: el.evaluation_items,
        },
        {
          type: "text",
          content: el.evaluation_method,
        },
        {
          type: "text",
          content: el.evaluation_environment,
        },
      ];
      evaluationArr.push(data);
    });

    const contentData = [
      {
        subject: "연구개발비 지원 부담 계획 제목",
        type: "text",
        content:
          "2. 연구개발비 사용에 관한 계획\n 1) 연구개발비 지원ㆍ부담계획",
      },
      {
        subject: "연구개발비 지원 부담 계획 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "구분",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "정부지원\n연구개발비",
            },
            {
              type: "text",
              content: "기관부담\n연구개발비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "그 외 기관 등의 지원금",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "지방자치단체",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "기타( )",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "단계",
            },
            {
              type: "text",
              content: "연차",
            },
            {
              type: "text",
              content: "연구개발기관명\n(기관역할)",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: "합계",
            },
          ],
          [
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: supportPlan.first_first[0],
            },
            {
              type: "text",
              content: supportPlan.first_first[1],
            },
            {
              type: "text",
              content: supportPlan.first_first[2],
            },
            {
              type: "text",
              content: supportPlan.first_first[3],
            },
            {
              type: "text",
              content: supportPlan.first_first[4],
            },
            {
              type: "text",
              content: supportPlan.first_first[5],
            },
            {
              type: "text",
              content: supportPlan.first_first[6],
            },
            {
              type: "text",
              content: supportPlan.first_first[7],
            },
            {
              type: "text",
              content: supportPlan.first_first[8],
            },
            {
              type: "text",
              content: supportPlan.first_first[9],
            },
            {
              type: "text",
              content: supportPlan.first_first[10],
            },
            {
              type: "text",
              content: supportPlan.first_first[11],
            },
            {
              type: "text",
              content: supportPlan.first_first[12],
            },
            {
              type: "text",
              content: supportPlan.first_first[13],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: supportPlan.first_last[0],
            },
            {
              type: "text",
              content: supportPlan.first_last[1],
            },
            {
              type: "text",
              content: supportPlan.first_last[2],
            },
            {
              type: "text",
              content: supportPlan.first_last[3],
            },
            {
              type: "text",
              content: supportPlan.first_last[4],
            },
            {
              type: "text",
              content: supportPlan.first_last[5],
            },
            {
              type: "text",
              content: supportPlan.first_last[6],
            },
            {
              type: "text",
              content: supportPlan.first_last[7],
            },
            {
              type: "text",
              content: supportPlan.first_last[8],
            },
            {
              type: "text",
              content: supportPlan.first_last[9],
            },
            {
              type: "text",
              content: supportPlan.first_last[10],
            },
            {
              type: "text",
              content: supportPlan.first_last[11],
            },
            {
              type: "text",
              content: supportPlan.first_last[12],
            },
            {
              type: "text",
              content: supportPlan.first_last[13],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[0],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[1],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[2],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[3],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[4],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[5],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[6],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[7],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[8],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[9],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[10],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[11],
            },
            {
              type: "text",
              content: supportPlan.first_subtotal[12],
            },
          ],
          [
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: supportPlan.last_first[0],
            },
            {
              type: "text",
              content: supportPlan.last_first[1],
            },
            {
              type: "text",
              content: supportPlan.last_first[2],
            },
            {
              type: "text",
              content: supportPlan.last_first[3],
            },
            {
              type: "text",
              content: supportPlan.last_first[4],
            },
            {
              type: "text",
              content: supportPlan.last_first[5],
            },
            {
              type: "text",
              content: supportPlan.last_first[6],
            },
            {
              type: "text",
              content: supportPlan.last_first[7],
            },
            {
              type: "text",
              content: supportPlan.last_first[8],
            },
            {
              type: "text",
              content: supportPlan.last_first[9],
            },
            {
              type: "text",
              content: supportPlan.last_first[10],
            },
            {
              type: "text",
              content: supportPlan.last_first[11],
            },
            {
              type: "text",
              content: supportPlan.last_first[12],
            },
            {
              type: "text",
              content: supportPlan.last_first[13],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: supportPlan.last_first[0],
            },
            {
              type: "text",
              content: supportPlan.last_first[1],
            },
            {
              type: "text",
              content: supportPlan.last_first[2],
            },
            {
              type: "text",
              content: supportPlan.last_first[3],
            },
            {
              type: "text",
              content: supportPlan.last_first[4],
            },
            {
              type: "text",
              content: supportPlan.last_first[5],
            },
            {
              type: "text",
              content: supportPlan.last_first[6],
            },
            {
              type: "text",
              content: supportPlan.last_first[7],
            },
            {
              type: "text",
              content: supportPlan.last_first[8],
            },
            {
              type: "text",
              content: supportPlan.last_first[9],
            },
            {
              type: "text",
              content: supportPlan.last_first[10],
            },
            {
              type: "text",
              content: supportPlan.last_first[11],
            },
            {
              type: "text",
              content: supportPlan.last_first[12],
            },
            {
              type: "text",
              content: supportPlan.last_first[13],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[0],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[1],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[2],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[3],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[4],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[5],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[6],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[7],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[8],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[9],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[10],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[11],
            },
            {
              type: "text",
              content: supportPlan.last_subtotal[12],
            },
          ],
          [
            {
              type: "text",
              content: "총계",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: supportPlan.total[0],
            },
            {
              type: "text",
              content: supportPlan.total[1],
            },
            {
              type: "text",
              content: supportPlan.total[2],
            },
            {
              type: "text",
              content: supportPlan.total[3],
            },
            {
              type: "text",
              content: supportPlan.total[4],
            },
            {
              type: "text",
              content: supportPlan.total[5],
            },
            {
              type: "text",
              content: supportPlan.total[6],
            },
            {
              type: "text",
              content: supportPlan.total[7],
            },
            {
              type: "text",
              content: supportPlan.total[8],
            },
            {
              type: "text",
              content: supportPlan.total[9],
            },
            {
              type: "text",
              content: supportPlan.total[10],
            },
            {
              type: "text",
              content: supportPlan.total[11],
            },
            {
              type: "text",
              content: supportPlan.total[12],
            },
          ],
        ],
      },
      {
        subject: "연구개발기관별 사용계획 제목",
        type: "text",
        content: "2) 연구개발비 사용계획\n(1) 연구개발기관별 사용계획",
      },
      {
        subject: "연구개발기관별 사용계획 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "연구개발기관명",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비 외 지원금",
            },
            {
              type: "text",
              content: "연구수당계상기준금액",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직접비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "간접비",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "인건비",
            },
            {
              type: "text",
              content: "학생인건비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구시설ㆍ장비비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구재료비",
            },
            {
              type: "text",
              content: "위탁연구개발비",
            },
            {
              type: "text",
              content: "국제공동연구개발비",
            },
            {
              type: "text",
              content: "연구개발부담비",
            },
            {
              type: "text",
              content: "연구활동비",
            },
            {
              type: "text",
              content: "연구수당",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: agentUsePlan.agency_name[0],
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[0],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[0],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[0],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[0],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[0],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[0],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[0],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[0],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[0],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[0],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[0],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[0],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[0],
            },
            {
              type: "text",
              content: agentUsePlan.total[0],
            },
            {
              type: "text",
              content: agentUsePlan.another_support_fund[0],
            },
            {
              type: "text",
              content: agentUsePlan.base_amount[0],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[1],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[1],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[1],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[1],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[1],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[1],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[1],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[1],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[1],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[1],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[1],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[1],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[1],
            },
            {
              type: "text",
              content: agentUsePlan.total[1],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[2],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[2],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[2],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[2],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[2],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[2],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[2],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[2],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[2],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[2],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[2],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[2],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[2],
            },
            {
              type: "text",
              content: agentUsePlan.total[2],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: agentUsePlan.agency_name[1],
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[3],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[3],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[3],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[3],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[3],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[3],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[3],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[3],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[3],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[3],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[3],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[3],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[3],
            },
            {
              type: "text",
              content: agentUsePlan.total[3],
            },
            {
              type: "text",
              content: agentUsePlan.another_support_fund[1],
            },
            {
              type: "text",
              content: agentUsePlan.base_amount[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[4],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[4],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[4],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[4],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[4],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[4],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[4],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[4],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[4],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[4],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[4],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[4],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[4],
            },
            {
              type: "text",
              content: agentUsePlan.total[4],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[5],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[5],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[5],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[5],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[5],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[5],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[5],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[5],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[5],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[5],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[5],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[5],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[5],
            },
            {
              type: "text",
              content: agentUsePlan.total[5],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "총계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[6],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[6],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[6],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[6],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[6],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[6],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[6],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[6],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[6],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[6],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[6],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[6],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[6],
            },
            {
              type: "text",
              content: agentUsePlan.total[6],
            },
            {
              type: "text",
              content: agentUsePlan.another_support_fund[2],
            },
            {
              type: "text",
              content: agentUsePlan.base_amount[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[7],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[7],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[7],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[7],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[7],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[7],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[7],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[7],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[7],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[7],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[7],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[7],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[7],
            },
            {
              type: "text",
              content: agentUsePlan.total[7],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: agentUsePlan.personnel_expense[8],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_general[8],
            },
            {
              type: "text",
              content: agentUsePlan.student_expense_special[8],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_general[8],
            },
            {
              type: "text",
              content: agentUsePlan.equipment_expense_special[8],
            },
            {
              type: "text",
              content: agentUsePlan.material_cost[8],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_expenses[8],
            },
            {
              type: "text",
              content: agentUsePlan.joint_rnd_expense[8],
            },
            {
              type: "text",
              content: agentUsePlan.rnd_burden[8],
            },
            {
              type: "text",
              content: agentUsePlan.activity_expenses[8],
            },
            {
              type: "text",
              content: agentUsePlan.research_allowance[8],
            },
            {
              type: "text",
              content: agentUsePlan.subtotal[8],
            },
            {
              type: "text",
              content: agentUsePlan.indirect_cost[8],
            },
            {
              type: "text",
              content: agentUsePlan.total[8],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
        ],
      },
      // {
      //   subject: "연차별 사용계획 제목",
      //   type: "text",
      //   content: "(2) 연차별 사용계획",
      // },
      // {
      //   subject: "연차별 사용계획 표",
      //   type: "table",
      //   content: [
      //     [
      //       {
      //         type: "text",
      //         content: "연차",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "연구개발비",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "연구개발비 외 지원금",
      //       },
      //       {
      //         type: "text",
      //         content: "연구수당계상기준금액",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "직접비",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "간접비",
      //       },
      //       {
      //         type: "text",
      //         content: "합계",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "인건비",
      //       },
      //       {
      //         type: "text",
      //         content: "학생인건비",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "연구시설ㆍ장비비",
      //       },
      //       {
      //         type: "text",
      //         content: "=&병합&=",
      //       },
      //       {
      //         type: "text",
      //         content: "연구재료비",
      //       },
      //       {
      //         type: "text",
      //         content: "위탁연구개발비",
      //       },
      //       {
      //         type: "text",
      //         content: "국제공동연구개발비",
      //       },
      //       {
      //         type: "text",
      //         content: "연구개발부담비",
      //       },
      //       {
      //         type: "text",
      //         content: "연구활동비",
      //       },
      //       {
      //         type: "text",
      //         content: "연구수당",
      //       },
      //       {
      //         type: "text",
      //         content: "소계",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "일반",
      //       },
      //       {
      //         type: "text",
      //         content: "특례",
      //       },
      //       {
      //         type: "text",
      //         content: "일반",
      //       },
      //       {
      //         type: "text",
      //         content: "특례",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "1",
      //       },
      //       {
      //         type: "text",
      //         content: "현금",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.another_support_fund[0],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.base_amount[0],
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "현물",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[1],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "소계",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[2],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "N",
      //       },
      //       {
      //         type: "text",
      //         content: "현금",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[3],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.another_support_fund[1],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.base_amount[1],
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "현물",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[4],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[4],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "소계",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[5],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[5],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "총계",
      //       },
      //       {
      //         type: "text",
      //         content: "현금",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[6],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.another_support_fund[2],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.base_amount[2],
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "현물",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[7],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[7],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //     [
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "합계",
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.personnel_expense[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_general[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.student_expense_special[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_general[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.equipment_expense_special[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.material_cost[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_expenses[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.joint_rnd_expense[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.rnd_burden[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.activity_expenses[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.research_allowance[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.subtotal[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.indirect_cost[8],
      //       },
      //       {
      //         type: "text",
      //         content: annualUsePlan.total[8],
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //       {
      //         type: "text",
      //         content: "||&병합&||",
      //       },
      //     ],
      //   ],
      // },
      {
        subject: "주관연구개발기관 연차별 사용계획 제목",
        type: "text",
        content: `(3)연구개발기관별-연차별 사용계획\n가. 주관연구개발기관명 ${supervisorUsePlan.agent_name}`,
      },
      {
        subject: "주관연구개발기관 연차별 사용계획 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "연차",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비 외 지원금",
            },
            {
              type: "text",
              content: "연구수당계상기준금액",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직접비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "간접비",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "인건비",
            },
            {
              type: "text",
              content: "학생인건비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구시설ㆍ장비비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구재료비",
            },
            {
              type: "text",
              content: "위탁연구개발비",
            },
            {
              type: "text",
              content: "국제공동연구개발비",
            },
            {
              type: "text",
              content: "연구개발부담비",
            },
            {
              type: "text",
              content: "연구활동비",
            },
            {
              type: "text",
              content: "연구수당",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.another_support_fund[0],
            },
            {
              type: "text",
              content: supervisorUsePlan.base_amount[0],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[1],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[2],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[3],
            },
            {
              type: "text",
              content: supervisorUsePlan.another_support_fund[1],
            },
            {
              type: "text",
              content: supervisorUsePlan.base_amount[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[4],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[4],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[5],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[5],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "총계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[6],
            },
            {
              type: "text",
              content: supervisorUsePlan.another_support_fund[2],
            },
            {
              type: "text",
              content: supervisorUsePlan.base_amount[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[7],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[7],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: supervisorUsePlan.personnel_expense[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_general[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.student_expense_special[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_general[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.equipment_expense_special[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.material_cost[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_expenses[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.joint_rnd_expense[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.rnd_burden[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.activity_expenses[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.research_allowance[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.subtotal[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.indirect_cost[8],
            },
            {
              type: "text",
              content: supervisorUsePlan.total[8],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
        ],
      },
      {
        subject: "공동연구개발기관 연차별 사용계획 제목",
        type: "text",
        content: `나. 공동연구개발기관명 ${jointUsePlan.agent_name}`,
      },
      {
        subject: "공동연구개발기관 연차별 사용계획 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "연차",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비 외 지원금",
            },
            {
              type: "text",
              content: "연구수당계상기준금액",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직접비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "간접비",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "인건비",
            },
            {
              type: "text",
              content: "학생인건비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구시설ㆍ장비비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구재료비",
            },
            {
              type: "text",
              content: "위탁연구개발비",
            },
            {
              type: "text",
              content: "국제공동연구개발비",
            },
            {
              type: "text",
              content: "연구개발부담비",
            },
            {
              type: "text",
              content: "연구활동비",
            },
            {
              type: "text",
              content: "연구수당",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[0],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[0],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[0],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[0],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[0],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[0],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[0],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[0],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[0],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[0],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[0],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[0],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[0],
            },
            {
              type: "text",
              content: jointUsePlan.total[0],
            },
            {
              type: "text",
              content: jointUsePlan.another_support_fund[0],
            },
            {
              type: "text",
              content: jointUsePlan.base_amount[0],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[1],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[1],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[1],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[1],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[1],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[1],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[1],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[1],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[1],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[1],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[1],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[1],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[1],
            },
            {
              type: "text",
              content: jointUsePlan.total[1],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[2],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[2],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[2],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[2],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[2],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[2],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[2],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[2],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[2],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[2],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[2],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[2],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[2],
            },
            {
              type: "text",
              content: jointUsePlan.total[2],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[3],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[3],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[3],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[3],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[3],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[3],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[3],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[3],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[3],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[3],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[3],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[3],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[3],
            },
            {
              type: "text",
              content: jointUsePlan.total[3],
            },
            {
              type: "text",
              content: jointUsePlan.another_support_fund[1],
            },
            {
              type: "text",
              content: jointUsePlan.base_amount[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[4],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[4],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[4],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[4],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[4],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[4],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[4],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[4],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[4],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[4],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[4],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[4],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[4],
            },
            {
              type: "text",
              content: jointUsePlan.total[4],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[5],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[5],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[5],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[5],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[5],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[5],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[5],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[5],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[5],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[5],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[5],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[5],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[5],
            },
            {
              type: "text",
              content: jointUsePlan.total[5],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "총계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[6],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[6],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[6],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[6],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[6],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[6],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[6],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[6],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[6],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[6],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[6],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[6],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[6],
            },
            {
              type: "text",
              content: jointUsePlan.total[6],
            },
            {
              type: "text",
              content: jointUsePlan.another_support_fund[2],
            },
            {
              type: "text",
              content: jointUsePlan.base_amount[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[7],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[7],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[7],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[7],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[7],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[7],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[7],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[7],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[7],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[7],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[7],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[7],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[7],
            },
            {
              type: "text",
              content: jointUsePlan.total[7],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: jointUsePlan.personnel_expense[8],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_general[8],
            },
            {
              type: "text",
              content: jointUsePlan.student_expense_special[8],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_general[8],
            },
            {
              type: "text",
              content: jointUsePlan.equipment_expense_special[8],
            },
            {
              type: "text",
              content: jointUsePlan.material_cost[8],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_expenses[8],
            },
            {
              type: "text",
              content: jointUsePlan.joint_rnd_expense[8],
            },
            {
              type: "text",
              content: jointUsePlan.rnd_burden[8],
            },
            {
              type: "text",
              content: jointUsePlan.activity_expenses[8],
            },
            {
              type: "text",
              content: jointUsePlan.research_allowance[8],
            },
            {
              type: "text",
              content: jointUsePlan.subtotal[8],
            },
            {
              type: "text",
              content: jointUsePlan.indirect_cost[8],
            },
            {
              type: "text",
              content: jointUsePlan.total[8],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
        ],
      },
      {
        subject: "위탁연구개발기관 연차별 사용계획 제목",
        type: "text",
        content: `다. 위탁연구개발기관명 ${commissionUsePlan.agent_name}`,
      },
      {
        subject: "위탁연구개발기관 연차별 사용계획 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "연차",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구개발비 외 지원금",
            },
            {
              type: "text",
              content: "연구수당계상기준금액",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직접비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "간접비",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "인건비",
            },
            {
              type: "text",
              content: "학생인건비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구시설ㆍ장비비",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "연구재료비",
            },
            {
              type: "text",
              content: "위탁연구개발비",
            },
            {
              type: "text",
              content: "국제공동연구개발비",
            },
            {
              type: "text",
              content: "연구개발부담비",
            },
            {
              type: "text",
              content: "연구활동비",
            },
            {
              type: "text",
              content: "연구수당",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "일반",
            },
            {
              type: "text",
              content: "특례",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[0],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[0],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[0],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[0],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[0],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[0],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[0],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[0],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[0],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[0],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[0],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[0],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[0],
            },
            {
              type: "text",
              content: commissionUsePlan.total[0],
            },
            {
              type: "text",
              content: commissionUsePlan.another_support_fund[0],
            },
            {
              type: "text",
              content: commissionUsePlan.base_amount[0],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[1],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[1],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[1],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[1],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[1],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[1],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[1],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[1],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[1],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[1],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[1],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[1],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[1],
            },
            {
              type: "text",
              content: commissionUsePlan.total[1],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[2],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[2],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[2],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[2],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[2],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[2],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[2],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[2],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[2],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[2],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[2],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[2],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[2],
            },
            {
              type: "text",
              content: commissionUsePlan.total[2],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "n",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[3],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[3],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[3],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[3],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[3],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[3],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[3],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[3],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[3],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[3],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[3],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[3],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[3],
            },
            {
              type: "text",
              content: commissionUsePlan.total[3],
            },
            {
              type: "text",
              content: commissionUsePlan.another_support_fund[1],
            },
            {
              type: "text",
              content: commissionUsePlan.base_amount[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[4],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[4],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[4],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[4],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[4],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[4],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[4],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[4],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[4],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[4],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[4],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[4],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[4],
            },
            {
              type: "text",
              content: commissionUsePlan.total[4],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "소계",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[5],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[5],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[5],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[5],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[5],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[5],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[5],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[5],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[5],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[5],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[5],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[5],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[5],
            },
            {
              type: "text",
              content: commissionUsePlan.total[5],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "총계",
            },
            {
              type: "text",
              content: "현금",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[6],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[6],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[6],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[6],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[6],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[6],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[6],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[6],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[6],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[6],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[6],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[6],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[6],
            },
            {
              type: "text",
              content: commissionUsePlan.total[6],
            },
            {
              type: "text",
              content: commissionUsePlan.another_support_fund[2],
            },
            {
              type: "text",
              content: commissionUsePlan.base_amount[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "현물",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[7],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[7],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[7],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[7],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[7],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[7],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[7],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[7],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[7],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[7],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[7],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[7],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[7],
            },
            {
              type: "text",
              content: commissionUsePlan.total[7],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "합계",
            },
            {
              type: "text",
              content: commissionUsePlan.personnel_expense[8],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_general[8],
            },
            {
              type: "text",
              content: commissionUsePlan.student_expense_special[8],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_general[8],
            },
            {
              type: "text",
              content: commissionUsePlan.equipment_expense_special[8],
            },
            {
              type: "text",
              content: commissionUsePlan.material_cost[8],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_expenses[8],
            },
            {
              type: "text",
              content: commissionUsePlan.joint_rnd_expense[8],
            },
            {
              type: "text",
              content: commissionUsePlan.rnd_burden[8],
            },
            {
              type: "text",
              content: commissionUsePlan.activity_expenses[8],
            },
            {
              type: "text",
              content: commissionUsePlan.research_allowance[8],
            },
            {
              type: "text",
              content: commissionUsePlan.subtotal[8],
            },
            {
              type: "text",
              content: commissionUsePlan.indirect_cost[8],
            },
            {
              type: "text",
              content: commissionUsePlan.total[8],
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
          ],
        ],
      },
      {
        subject: "연구시설 장비 구축계획 제목",
        type: "text",
        content:
          "3) 연구시설ㆍ장비 구축ㆍ운영계획(해당 시 작성합니다)\n(1) 연구시설ㆍ장비 구축계획(구축비용이 3천만원 이상인 경우에는 필수로 작성합니다)",
      },
      {
        subject: "연구시설 장비 구축계획 표",
        type: "table",
        content: techPlanArr,
      },
      {
        subject: "연구시설 장비 운영 활용계획 제목",
        type: "text",
        content: "(2) 연구시설ㆍ장비 운영ㆍ활용계획",
      },
      {
        subject: "연구시설 장비 운영 활용계획 표",
        type: "table",
        content: utilizationPlanArr,
      },
      {
        subject: "성과지표 및 목표치 제목",
        type: "text",
        content:
          "3. 평가기준 및 평가방법\n(해당 시 작성하며, 작성 시에는 연구개발과제 특성에 따라 선택적으로 항목 적용이 가능합니다)\n1) 성과지표 및 목표치",
      },
      {
        subject: "성과지표 및 목표치 표",
        type: "table",
        content: targetValueArr,
      },
      {
        subject: "결과물의 성능지표 제목",
        type: "text",
        content: "(1) 결과물의 성능지표",
      },
      {
        subject: "결과물의 성능지표 표",
        type: "table",
        content: performanceIndicatorsArr,
      },
      {
        subject: "평가방법 및 평가환경 제목",
        type: "text",
        content: "(2) 연구시설ㆍ장비 운영ㆍ활용계획",
      },
      {
        subject: "평가방법 및 평가환경 표",
        type: "table",
        content: evaluationArr,
      },
    ];
    sendDataToAPI(contentData);
  };

  const sendDataToAPI = (contentData) => {
    const business_idx = window.sessionStorage.getItem("itemIdx");

    responseData.content = _.uniqBy(responseData.content, "subject");
    responseData.content.forEach((parentItem, index) => {
      contentData.forEach((childItem) => {
        if (parentItem.subject === childItem.subject) {
          responseData.content[index].content = childItem.content;
          setResponseData({ ...responseData });
        }
      });
    });

    if (business_idx !== null) {
      const payload = {
        title: "R&D 사업계획서",
        content: [...responseData.content],
      };
      console.log(payload.content.length);
      CommonAPI.updateBusinessData(business_idx, payload)
        .then(() => {
          CommonAPI.getBusinessData(business_idx).then((res) =>
            console.log(res.data)
          );
        })
        .then(() =>
          window.$Global
            .fileDownloadSync(`/common/business/${business_idx}/download`)
            .then((r) => console.log(r))
        );
    }
  };

  return (
    <div className="wrapper">
      <SideBar />
      <div className="rnd_wrapper fifth">
        <RnDText index={5} />
        <div className="rnd_plan">
          <div className="plan_text">
            <div>
              <h2>Step. 2 연구개발비 사용에 관한 계획</h2>
            </div>
            <div>
              <span>*연구개발과제 협약 시 제출하는 계획입니다</span>
            </div>
          </div>
          <div className="plan_text" style={{ paddingTop: 0 }}>
            <div>
              <p className="big_text">1) 연구개발비 지원ㆍ부담계획</p>
              <BringingUpIcon />
            </div>
            <div>
              <span>단 위 : 천원</span>
            </div>
          </div>
          <div className="rnd_cost_wrapper">
            <table className="rnd_cost_table">
              <colgroup>
                <col width={36} />
                <col width={33} />
                <col width={134} />
                <col width={88} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th
                    rowSpan={2}
                    colSpan={3}
                    style={{ backgroundColor: "#d8d8d8" }}
                  >
                    구분
                  </th>
                  <th rowSpan={2}>
                    정부지원
                    <br />
                    연구개발비
                  </th>
                  <th
                    rowSpan={2}
                    colSpan={3}
                    style={{ backgroundColor: "#d8d8d8" }}
                  >
                    기관부담연구개발비
                  </th>
                  <th colSpan={6} style={{ backgroundColor: "#d8d8d8" }}>
                    그 외 기관 등의 지원금
                  </th>
                  <th rowSpan={2} colSpan={3}>
                    합계
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    삭제
                  </th>
                </tr>
                <tr style={{ height: "22px" }}>
                  <th colSpan={3}>그 외 기관 등의 지원금</th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    기타( )
                  </th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    단계
                  </th>
                  <th>연차</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    연구개발기관명
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    현 물
                  </th>
                  <th>합 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3} style={{ border: "1px solid #f0f0f0" }}>
                    1
                  </td>
                  <td style={{ border: "1px solid #f0f0f0" }}>1</td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[0]}
                      onChange={(e) => handleChange(e, 0, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[1]}
                      onChange={(e) => handleChange(e, 1, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[2]}
                      onChange={(e) => handleChange(e, 2, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[3]}
                      onChange={(e) => handleChange(e, 3, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[4]}
                      onChange={(e) => handleChange(e, 4, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[5]}
                      onChange={(e) => handleChange(e, 5, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[6]}
                      onChange={(e) => handleChange(e, 6, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[7]}
                      onChange={(e) => handleChange(e, 7, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[8]}
                      onChange={(e) => handleChange(e, 8, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[9]}
                      onChange={(e) => handleChange(e, 9, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[10]}
                      onChange={(e) => handleChange(e, 10, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[11]}
                      onChange={(e) => handleChange(e, 11, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[12]}
                      onChange={(e) => handleChange(e, 12, "first_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_first[13]}
                      onChange={(e) => handleChange(e, 13, "first_first")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #f0f0f0" }}>N</td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[0]}
                      onChange={(e) => handleChange(e, 0, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[1]}
                      onChange={(e) => handleChange(e, 1, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[2]}
                      onChange={(e) => handleChange(e, 2, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[3]}
                      onChange={(e) => handleChange(e, 3, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[4]}
                      onChange={(e) => handleChange(e, 4, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[5]}
                      onChange={(e) => handleChange(e, 5, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[6]}
                      onChange={(e) => handleChange(e, 6, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[7]}
                      onChange={(e) => handleChange(e, 7, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[8]}
                      onChange={(e) => handleChange(e, 8, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[9]}
                      onChange={(e) => handleChange(e, 9, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[10]}
                      onChange={(e) => handleChange(e, 10, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[11]}
                      onChange={(e) => handleChange(e, 11, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[12]}
                      onChange={(e) => handleChange(e, 12, "first_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_last[13]}
                      onChange={(e) => handleChange(e, 13, "first_last")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p className="total_text small_text">소&emsp;계</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[9]}
                      onChange={(e) => handleChange(e, 9, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[10]}
                      onChange={(e) => handleChange(e, 10, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[11]}
                      onChange={(e) => handleChange(e, 11, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.first_subtotal[12]}
                      onChange={(e) => handleChange(e, 12, "first_subtotal")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3} style={{ border: "1px solid #f0f0f0" }}>
                    N
                  </td>
                  <td style={{ border: "1px solid #f0f0f0" }}>1</td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[0]}
                      onChange={(e) => handleChange(e, 0, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[1]}
                      onChange={(e) => handleChange(e, 1, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[2]}
                      onChange={(e) => handleChange(e, 2, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[3]}
                      onChange={(e) => handleChange(e, 3, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[4]}
                      onChange={(e) => handleChange(e, 4, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[5]}
                      onChange={(e) => handleChange(e, 5, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[6]}
                      onChange={(e) => handleChange(e, 6, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[7]}
                      onChange={(e) => handleChange(e, 7, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[8]}
                      onChange={(e) => handleChange(e, 8, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[9]}
                      onChange={(e) => handleChange(e, 9, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[10]}
                      onChange={(e) => handleChange(e, 10, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[11]}
                      onChange={(e) => handleChange(e, 11, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[12]}
                      onChange={(e) => handleChange(e, 12, "last_first")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_first[13]}
                      onChange={(e) => handleChange(e, 13, "last_first")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #f0f0f0" }}>N</td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[0]}
                      onChange={(e) => handleChange(e, 0, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[1]}
                      onChange={(e) => handleChange(e, 1, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[2]}
                      onChange={(e) => handleChange(e, 2, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[3]}
                      onChange={(e) => handleChange(e, 3, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[4]}
                      onChange={(e) => handleChange(e, 4, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[5]}
                      onChange={(e) => handleChange(e, 5, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[6]}
                      onChange={(e) => handleChange(e, 6, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[7]}
                      onChange={(e) => handleChange(e, 7, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[8]}
                      onChange={(e) => handleChange(e, 8, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[9]}
                      onChange={(e) => handleChange(e, 9, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[10]}
                      onChange={(e) => handleChange(e, 10, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[11]}
                      onChange={(e) => handleChange(e, 11, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[12]}
                      onChange={(e) => handleChange(e, 12, "last_last")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_last[13]}
                      onChange={(e) => handleChange(e, 13, "last_last")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <p className="total_text small_text">소&emsp;계</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[9]}
                      onChange={(e) => handleChange(e, 9, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[10]}
                      onChange={(e) => handleChange(e, 10, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[11]}
                      onChange={(e) => handleChange(e, 11, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.last_subtotal[12]}
                      onChange={(e) => handleChange(e, 12, "last_subtotal")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <p className="total_text small_text">총&emsp;계</p>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[9]}
                      onChange={(e) => handleChange(e, 9, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[10]}
                      onChange={(e) => handleChange(e, 10, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[11]}
                      onChange={(e) => handleChange(e, 11, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="supportPlan"
                      value={supportPlan.total[12]}
                      onChange={(e) => handleChange(e, 12, "total")}
                    />
                  </td>
                  <td>
                    <button className="icon_del_trashcan" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <p className="big_text">2) 연구개발비 사용계획</p>
          </div>
          <div className="plan_text" style={{ paddingTop: 0 }}>
            <div>
              <p className="small_text">(1) 연구개발기관별 사용계획</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <span>단 위 : 천원</span>
            </div>
          </div>
          <div className="overflow_plan_table_div">
            <table className="overflow_plan_table">
              <colgroup>
                <col width={35} />
                <col width={200} />
                <col width={100} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
              </colgroup>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 목
                  </th>
                  <th rowSpan={2} colSpan={2}>
                    항 목
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    연구개발기관명:
                    <input
                      type="text"
                      placeholder="OOO"
                      style={{ height: "24px" }}
                      name="agentUsePlan"
                      value={agentUsePlan.agency_name[0]}
                      onChange={(e) => handleChange(e, 0, "agency_name")}
                    />
                  </th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    연구개발기관명:
                    <input
                      type="text"
                      placeholder="OOO"
                      style={{ height: "24px" }}
                      name="agentUsePlan"
                      value={agentUsePlan.agency_name[1]}
                      onChange={(e) => handleChange(e, 1, "agency_name")}
                    />
                  </th>
                  <th colSpan={3}>총 계</th>
                </tr>
                <tr style={{ height: "30px" }}>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직<br />
                    <br />접<br />
                    <br />비
                  </td>
                  <td colSpan={2}>인건비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[0]}
                      onChange={(e) => handleChange(e, 0, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[1]}
                      onChange={(e) => handleChange(e, 1, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[2]}
                      onChange={(e) => handleChange(e, 2, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[3]}
                      onChange={(e) => handleChange(e, 3, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[4]}
                      onChange={(e) => handleChange(e, 4, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[5]}
                      onChange={(e) => handleChange(e, 5, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[6]}
                      onChange={(e) => handleChange(e, 6, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[7]}
                      onChange={(e) => handleChange(e, 7, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.personnel_expense[8]}
                      onChange={(e) => handleChange(e, 8, "personnel_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    학생 인건비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.student_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    연구시설‧장비비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.equipment_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구 재료비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[0]}
                      onChange={(e) => handleChange(e, 0, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[1]}
                      onChange={(e) => handleChange(e, 1, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[2]}
                      onChange={(e) => handleChange(e, 2, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[3]}
                      onChange={(e) => handleChange(e, 3, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[4]}
                      onChange={(e) => handleChange(e, 4, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[5]}
                      onChange={(e) => handleChange(e, 5, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[6]}
                      onChange={(e) => handleChange(e, 6, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[7]}
                      onChange={(e) => handleChange(e, 7, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.material_cost[8]}
                      onChange={(e) => handleChange(e, 8, "material_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>위탁연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>국제공동연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[0]}
                      onChange={(e) => handleChange(e, 0, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[1]}
                      onChange={(e) => handleChange(e, 1, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[2]}
                      onChange={(e) => handleChange(e, 2, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[3]}
                      onChange={(e) => handleChange(e, 3, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[4]}
                      onChange={(e) => handleChange(e, 4, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[5]}
                      onChange={(e) => handleChange(e, 5, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[6]}
                      onChange={(e) => handleChange(e, 6, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[7]}
                      onChange={(e) => handleChange(e, 7, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.joint_rnd_expense[8]}
                      onChange={(e) => handleChange(e, 8, "joint_rnd_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구개발부담비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.rnd_burden[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_burden")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구활동비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.activity_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "activity_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구수당</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[0]}
                      onChange={(e) => handleChange(e, 0, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[1]}
                      onChange={(e) => handleChange(e, 1, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[2]}
                      onChange={(e) => handleChange(e, 2, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[3]}
                      onChange={(e) => handleChange(e, 3, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[4]}
                      onChange={(e) => handleChange(e, 4, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[5]}
                      onChange={(e) => handleChange(e, 5, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[6]}
                      onChange={(e) => handleChange(e, 6, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[7]}
                      onChange={(e) => handleChange(e, 7, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.research_allowance[8]}
                      onChange={(e) => handleChange(e, 8, "research_allowance")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>소 계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "subtotal")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>간접비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[0]}
                      onChange={(e) => handleChange(e, 0, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[1]}
                      onChange={(e) => handleChange(e, 1, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[2]}
                      onChange={(e) => handleChange(e, 2, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[3]}
                      onChange={(e) => handleChange(e, 3, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[4]}
                      onChange={(e) => handleChange(e, 4, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[5]}
                      onChange={(e) => handleChange(e, 5, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[6]}
                      onChange={(e) => handleChange(e, 6, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[7]}
                      onChange={(e) => handleChange(e, 7, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.indirect_cost[8]}
                      onChange={(e) => handleChange(e, 8, "indirect_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>합계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="agentUsePlan"
                      value={agentUsePlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구개발비 외 지원금</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.another_support_fund[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.another_support_fund[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.another_support_fund[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "another_support_fund")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구수당 계상 기준금액</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.base_amount[0]}
                      onChange={(e) => handleChange(e, 0, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="agentUsePlan"
                      value={agentUsePlan.base_amount[1]}
                      onChange={(e) => handleChange(e, 1, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="agentUsePlan"
                      value={agentUsePlan.base_amount[2]}
                      onChange={(e) => handleChange(e, 2, "base_amount")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text"> (2) 연차별 사용계획</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <span>단 위 : 천원</span>
            </div>
          </div>
          <div className="overflow_plan_table_div">
            <table className="overflow_plan_table">
              <colgroup>
                <col width={35} />
                <col width={200} />
                <col width={100} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
              </colgroup>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 목
                  </th>
                  <th rowSpan={2} colSpan={2}>
                    항 목
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    연차 1
                  </th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    연차 N
                  </th>
                  <th colSpan={3}>총 계</th>
                </tr>
                <tr style={{ height: "30px" }}>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직<br />
                    <br />접<br />
                    <br />비
                  </td>
                  <td colSpan={2}>인건비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[0]}
                      onChange={(e) => handleChange(e, 0, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[1]}
                      onChange={(e) => handleChange(e, 1, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[2]}
                      onChange={(e) => handleChange(e, 2, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[3]}
                      onChange={(e) => handleChange(e, 3, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[4]}
                      onChange={(e) => handleChange(e, 4, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[5]}
                      onChange={(e) => handleChange(e, 5, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[6]}
                      onChange={(e) => handleChange(e, 6, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[7]}
                      onChange={(e) => handleChange(e, 7, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.personnel_expense[8]}
                      onChange={(e) => handleChange(e, 8, "personnel_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    학생 인건비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.student_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    연구시설‧장비비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.equipment_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구 재료비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[0]}
                      onChange={(e) => handleChange(e, 0, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[1]}
                      onChange={(e) => handleChange(e, 1, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[2]}
                      onChange={(e) => handleChange(e, 2, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[3]}
                      onChange={(e) => handleChange(e, 3, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[4]}
                      onChange={(e) => handleChange(e, 4, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[5]}
                      onChange={(e) => handleChange(e, 5, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[6]}
                      onChange={(e) => handleChange(e, 6, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[7]}
                      onChange={(e) => handleChange(e, 7, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.material_cost[8]}
                      onChange={(e) => handleChange(e, 8, "material_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>위탁연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>국제공동연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[0]}
                      onChange={(e) => handleChange(e, 0, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[1]}
                      onChange={(e) => handleChange(e, 1, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[2]}
                      onChange={(e) => handleChange(e, 2, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[3]}
                      onChange={(e) => handleChange(e, 3, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[4]}
                      onChange={(e) => handleChange(e, 4, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[5]}
                      onChange={(e) => handleChange(e, 5, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[6]}
                      onChange={(e) => handleChange(e, 6, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[7]}
                      onChange={(e) => handleChange(e, 7, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.joint_rnd_expense[8]}
                      onChange={(e) => handleChange(e, 8, "joint_rnd_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구개발부담비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.rnd_burden[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_burden")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구활동비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.activity_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "activity_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구수당</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[0]}
                      onChange={(e) => handleChange(e, 0, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[1]}
                      onChange={(e) => handleChange(e, 1, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[2]}
                      onChange={(e) => handleChange(e, 2, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[3]}
                      onChange={(e) => handleChange(e, 3, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[4]}
                      onChange={(e) => handleChange(e, 4, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[5]}
                      onChange={(e) => handleChange(e, 5, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[6]}
                      onChange={(e) => handleChange(e, 6, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[7]}
                      onChange={(e) => handleChange(e, 7, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.research_allowance[8]}
                      onChange={(e) => handleChange(e, 8, "research_allowance")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>소 계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "subtotal")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>간접비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[0]}
                      onChange={(e) => handleChange(e, 0, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[1]}
                      onChange={(e) => handleChange(e, 1, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[2]}
                      onChange={(e) => handleChange(e, 2, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[3]}
                      onChange={(e) => handleChange(e, 3, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[4]}
                      onChange={(e) => handleChange(e, 4, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[5]}
                      onChange={(e) => handleChange(e, 5, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[6]}
                      onChange={(e) => handleChange(e, 6, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[7]}
                      onChange={(e) => handleChange(e, 7, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.indirect_cost[8]}
                      onChange={(e) => handleChange(e, 8, "indirect_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>합계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="annualUsePlan"
                      value={annualUsePlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구개발비 외 지원금</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.another_support_fund[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.another_support_fund[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.another_support_fund[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "another_support_fund")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구수당 계상 기준금액</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.base_amount[0]}
                      onChange={(e) => handleChange(e, 0, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="annualUsePlan"
                      value={annualUsePlan.base_amount[1]}
                      onChange={(e) => handleChange(e, 1, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="annualUsePlan"
                      value={annualUsePlan.base_amount[2]}
                      onChange={(e) => handleChange(e, 2, "base_amount")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>(3) 연구개발기관별-연차별 사용계획</p>
          </div>
          <div>
            <div className="name_research">
              <p className="small_text">가. 주관연구개발기관명:</p>
              <input
                type="text"
                placeholder="OOOOOOOOOO"
                value={supervisorUsePlan.agent_name}
                name="supervisorUsePlan"
                onChange={(e) =>
                  setSupervisorUsePlan({
                    ...supervisorUsePlan,
                    agent_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="plan_text">
              <div>
                <p className="small_text">연차별 사용계획</p>
                <FirstInfoText />
                <BringingUpIcon />
              </div>
              <div>
                <span>단 위 : 천원</span>
              </div>
            </div>
          </div>
          <div className="overflow_plan_table_div">
            <table className="overflow_plan_table">
              <colgroup>
                <col width={35} />
                <col width={200} />
                <col width={100} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
              </colgroup>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 목
                  </th>
                  <th rowSpan={2} colSpan={2}>
                    항 목
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    연차 1
                  </th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    연차 N
                  </th>
                  <th colSpan={3}>총 계</th>
                </tr>
                <tr style={{ height: "30px" }}>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직<br />
                    <br />접<br />
                    <br />비
                  </td>
                  <td colSpan={2}>인건비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[0]}
                      onChange={(e) => handleChange(e, 0, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[1]}
                      onChange={(e) => handleChange(e, 1, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[2]}
                      onChange={(e) => handleChange(e, 2, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[3]}
                      onChange={(e) => handleChange(e, 3, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[4]}
                      onChange={(e) => handleChange(e, 4, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[5]}
                      onChange={(e) => handleChange(e, 5, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[6]}
                      onChange={(e) => handleChange(e, 6, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[7]}
                      onChange={(e) => handleChange(e, 7, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.personnel_expense[8]}
                      onChange={(e) => handleChange(e, 8, "personnel_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    학생 인건비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.student_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    연구시설‧장비비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.equipment_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구 재료비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[0]}
                      onChange={(e) => handleChange(e, 0, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[1]}
                      onChange={(e) => handleChange(e, 1, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[2]}
                      onChange={(e) => handleChange(e, 2, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[3]}
                      onChange={(e) => handleChange(e, 3, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[4]}
                      onChange={(e) => handleChange(e, 4, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[5]}
                      onChange={(e) => handleChange(e, 5, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[6]}
                      onChange={(e) => handleChange(e, 6, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[7]}
                      onChange={(e) => handleChange(e, 7, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.material_cost[8]}
                      onChange={(e) => handleChange(e, 8, "material_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>위탁연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>국제공동연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[0]}
                      onChange={(e) => handleChange(e, 0, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[1]}
                      onChange={(e) => handleChange(e, 1, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[2]}
                      onChange={(e) => handleChange(e, 2, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[3]}
                      onChange={(e) => handleChange(e, 3, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[4]}
                      onChange={(e) => handleChange(e, 4, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[5]}
                      onChange={(e) => handleChange(e, 5, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[6]}
                      onChange={(e) => handleChange(e, 6, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[7]}
                      onChange={(e) => handleChange(e, 7, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.joint_rnd_expense[8]}
                      onChange={(e) => handleChange(e, 8, "joint_rnd_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구개발부담비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.rnd_burden[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_burden")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구활동비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.activity_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "activity_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구수당</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[0]}
                      onChange={(e) => handleChange(e, 0, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[1]}
                      onChange={(e) => handleChange(e, 1, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[2]}
                      onChange={(e) => handleChange(e, 2, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[3]}
                      onChange={(e) => handleChange(e, 3, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[4]}
                      onChange={(e) => handleChange(e, 4, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[5]}
                      onChange={(e) => handleChange(e, 5, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[6]}
                      onChange={(e) => handleChange(e, 6, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[7]}
                      onChange={(e) => handleChange(e, 7, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.research_allowance[8]}
                      onChange={(e) => handleChange(e, 8, "research_allowance")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>소 계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "subtotal")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>간접비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[0]}
                      onChange={(e) => handleChange(e, 0, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[1]}
                      onChange={(e) => handleChange(e, 1, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[2]}
                      onChange={(e) => handleChange(e, 2, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[3]}
                      onChange={(e) => handleChange(e, 3, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[4]}
                      onChange={(e) => handleChange(e, 4, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[5]}
                      onChange={(e) => handleChange(e, 5, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[6]}
                      onChange={(e) => handleChange(e, 6, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[7]}
                      onChange={(e) => handleChange(e, 7, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.indirect_cost[8]}
                      onChange={(e) => handleChange(e, 8, "indirect_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>합계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구개발비 외 지원금</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.another_support_fund[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.another_support_fund[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.another_support_fund[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "another_support_fund")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구수당 계상 기준금액</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.base_amount[0]}
                      onChange={(e) => handleChange(e, 0, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.base_amount[1]}
                      onChange={(e) => handleChange(e, 1, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="supervisorUsePlan"
                      value={supervisorUsePlan.base_amount[2]}
                      onChange={(e) => handleChange(e, 2, "base_amount")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="name_research">
              <p className="small_text">나. 공동연구개발기관명:</p>
              <input
                type="text"
                placeholder="OOOOOOOOOO"
                value={jointUsePlan.agent_name}
                name="supervisorUsePlan"
                onChange={(e) =>
                  setJointUsePlan({
                    ...jointUsePlan,
                    agent_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="plan_text">
              <div>
                <p className="small_text">연차별 사용계획</p>
                <FirstInfoText />
                <BringingUpIcon />
              </div>
              <div>
                <span>단 위 : 천원</span>
              </div>
            </div>
          </div>
          <div className="overflow_plan_table_div">
            <table className="overflow_plan_table">
              <colgroup>
                <col width={35} />
                <col width={200} />
                <col width={100} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
              </colgroup>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 목
                  </th>
                  <th rowSpan={2} colSpan={2}>
                    항 목
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    연차 1
                  </th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    연차 N
                  </th>
                  <th colSpan={3}>총 계</th>
                </tr>
                <tr style={{ height: "30px" }}>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직<br />
                    <br />접<br />
                    <br />비
                  </td>
                  <td colSpan={2}>인건비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[0]}
                      onChange={(e) => handleChange(e, 0, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[1]}
                      onChange={(e) => handleChange(e, 1, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[2]}
                      onChange={(e) => handleChange(e, 2, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[3]}
                      onChange={(e) => handleChange(e, 3, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[4]}
                      onChange={(e) => handleChange(e, 4, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[5]}
                      onChange={(e) => handleChange(e, 5, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[6]}
                      onChange={(e) => handleChange(e, 6, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[7]}
                      onChange={(e) => handleChange(e, 7, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.personnel_expense[8]}
                      onChange={(e) => handleChange(e, 8, "personnel_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    학생 인건비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.student_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    연구시설‧장비비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.equipment_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구 재료비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[0]}
                      onChange={(e) => handleChange(e, 0, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[1]}
                      onChange={(e) => handleChange(e, 1, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[2]}
                      onChange={(e) => handleChange(e, 2, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[3]}
                      onChange={(e) => handleChange(e, 3, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[4]}
                      onChange={(e) => handleChange(e, 4, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[5]}
                      onChange={(e) => handleChange(e, 5, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[6]}
                      onChange={(e) => handleChange(e, 6, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[7]}
                      onChange={(e) => handleChange(e, 7, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.material_cost[8]}
                      onChange={(e) => handleChange(e, 8, "material_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>위탁연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>국제공동연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[0]}
                      onChange={(e) => handleChange(e, 0, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[1]}
                      onChange={(e) => handleChange(e, 1, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[2]}
                      onChange={(e) => handleChange(e, 2, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[3]}
                      onChange={(e) => handleChange(e, 3, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[4]}
                      onChange={(e) => handleChange(e, 4, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[5]}
                      onChange={(e) => handleChange(e, 5, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[6]}
                      onChange={(e) => handleChange(e, 6, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[7]}
                      onChange={(e) => handleChange(e, 7, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.joint_rnd_expense[8]}
                      onChange={(e) => handleChange(e, 8, "joint_rnd_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구개발부담비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.rnd_burden[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_burden")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구활동비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.activity_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "activity_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구수당</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[0]}
                      onChange={(e) => handleChange(e, 0, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[1]}
                      onChange={(e) => handleChange(e, 1, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[2]}
                      onChange={(e) => handleChange(e, 2, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[3]}
                      onChange={(e) => handleChange(e, 3, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[4]}
                      onChange={(e) => handleChange(e, 4, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[5]}
                      onChange={(e) => handleChange(e, 5, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[6]}
                      onChange={(e) => handleChange(e, 6, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[7]}
                      onChange={(e) => handleChange(e, 7, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.research_allowance[8]}
                      onChange={(e) => handleChange(e, 8, "research_allowance")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>소 계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "subtotal")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>간접비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[0]}
                      onChange={(e) => handleChange(e, 0, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[1]}
                      onChange={(e) => handleChange(e, 1, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[2]}
                      onChange={(e) => handleChange(e, 2, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[3]}
                      onChange={(e) => handleChange(e, 3, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[4]}
                      onChange={(e) => handleChange(e, 4, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[5]}
                      onChange={(e) => handleChange(e, 5, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[6]}
                      onChange={(e) => handleChange(e, 6, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[7]}
                      onChange={(e) => handleChange(e, 7, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.indirect_cost[8]}
                      onChange={(e) => handleChange(e, 8, "indirect_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>합계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="jointUsePlan"
                      value={jointUsePlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구개발비 외 지원금</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.another_support_fund[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.another_support_fund[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.another_support_fund[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "another_support_fund")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구수당 계상 기준금액</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.base_amount[0]}
                      onChange={(e) => handleChange(e, 0, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="jointUsePlan"
                      value={jointUsePlan.base_amount[1]}
                      onChange={(e) => handleChange(e, 1, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="jointUsePlan"
                      value={jointUsePlan.base_amount[2]}
                      onChange={(e) => handleChange(e, 2, "base_amount")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="name_research">
              <p className="small_text">다. 위탁연구개발기관명:</p>
              <input
                type="text"
                placeholder="OOOOOOOOOO"
                name="commissionUsePlan"
                onChange={(e) =>
                  setCommissionUsePlan({
                    ...commissionUsePlan,
                    agent_name: e.target.value,
                  })
                }
              />
            </div>
            <div className="plan_text">
              <div>
                <p className="small_text">연차별 사용계획</p>
                <FirstInfoText />
                <BringingUpIcon />
              </div>
              <div>
                <span>단 위 : 천원</span>
              </div>
            </div>
          </div>
          <div className="overflow_plan_table_div">
            <table className="overflow_plan_table">
              <colgroup>
                <col width={35} />
                <col width={200} />
                <col width={100} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
              </colgroup>
              <thead>
                <tr style={{ height: "30px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 목
                  </th>
                  <th rowSpan={2} colSpan={2}>
                    항 목
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    연차 1
                  </th>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    연차 N
                  </th>
                  <th colSpan={3}>총 계</th>
                </tr>
                <tr style={{ height: "30px" }}>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 금
                  </th>
                  <th>현 물</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    소 계
                  </th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                  <th>현 금</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현 물
                  </th>
                  <th>소 계</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직<br />
                    <br />접<br />
                    <br />비
                  </td>
                  <td colSpan={2}>인건비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[0]}
                      onChange={(e) => handleChange(e, 0, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[1]}
                      onChange={(e) => handleChange(e, 1, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[2]}
                      onChange={(e) => handleChange(e, 2, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[3]}
                      onChange={(e) => handleChange(e, 3, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[4]}
                      onChange={(e) => handleChange(e, 4, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[5]}
                      onChange={(e) => handleChange(e, 5, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[6]}
                      onChange={(e) => handleChange(e, 6, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[7]}
                      onChange={(e) => handleChange(e, 7, "personnel_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.personnel_expense[8]}
                      onChange={(e) => handleChange(e, 8, "personnel_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    학생 인건비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "student_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.student_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "student_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={2} style={{ borderRight: "1px solid #f0f0f0" }}>
                    연구시설‧장비비
                  </td>
                  <td>일반</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_general")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_general[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_general")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>특례</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[3]}
                      onChange={(e) =>
                        handleChange(e, 3, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[4]}
                      onChange={(e) =>
                        handleChange(e, 4, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[5]}
                      onChange={(e) =>
                        handleChange(e, 5, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[6]}
                      onChange={(e) =>
                        handleChange(e, 6, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[7]}
                      onChange={(e) =>
                        handleChange(e, 7, "equipment_expense_special")
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.equipment_expense_special[8]}
                      onChange={(e) =>
                        handleChange(e, 8, "equipment_expense_special")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구 재료비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[0]}
                      onChange={(e) => handleChange(e, 0, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[1]}
                      onChange={(e) => handleChange(e, 1, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[2]}
                      onChange={(e) => handleChange(e, 2, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[3]}
                      onChange={(e) => handleChange(e, 3, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[4]}
                      onChange={(e) => handleChange(e, 4, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[5]}
                      onChange={(e) => handleChange(e, 5, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[6]}
                      onChange={(e) => handleChange(e, 6, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[7]}
                      onChange={(e) => handleChange(e, 7, "material_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.material_cost[8]}
                      onChange={(e) => handleChange(e, 8, "material_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>위탁연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>국제공동연구개발비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[0]}
                      onChange={(e) => handleChange(e, 0, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[1]}
                      onChange={(e) => handleChange(e, 1, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[2]}
                      onChange={(e) => handleChange(e, 2, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[3]}
                      onChange={(e) => handleChange(e, 3, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[4]}
                      onChange={(e) => handleChange(e, 4, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[5]}
                      onChange={(e) => handleChange(e, 5, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[6]}
                      onChange={(e) => handleChange(e, 6, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[7]}
                      onChange={(e) => handleChange(e, 7, "joint_rnd_expense")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.joint_rnd_expense[8]}
                      onChange={(e) => handleChange(e, 8, "joint_rnd_expense")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구개발부담비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[0]}
                      onChange={(e) => handleChange(e, 0, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[1]}
                      onChange={(e) => handleChange(e, 1, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[2]}
                      onChange={(e) => handleChange(e, 2, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[3]}
                      onChange={(e) => handleChange(e, 3, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[4]}
                      onChange={(e) => handleChange(e, 4, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[5]}
                      onChange={(e) => handleChange(e, 5, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[6]}
                      onChange={(e) => handleChange(e, 6, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[7]}
                      onChange={(e) => handleChange(e, 7, "rnd_burden")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.rnd_burden[8]}
                      onChange={(e) => handleChange(e, 8, "rnd_burden")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구활동비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[0]}
                      onChange={(e) => handleChange(e, 0, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[1]}
                      onChange={(e) => handleChange(e, 1, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[2]}
                      onChange={(e) => handleChange(e, 2, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[3]}
                      onChange={(e) => handleChange(e, 3, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[4]}
                      onChange={(e) => handleChange(e, 4, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[5]}
                      onChange={(e) => handleChange(e, 5, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[6]}
                      onChange={(e) => handleChange(e, 6, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[7]}
                      onChange={(e) => handleChange(e, 7, "activity_expenses")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.activity_expenses[8]}
                      onChange={(e) => handleChange(e, 8, "activity_expenses")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>연구수당</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[0]}
                      onChange={(e) => handleChange(e, 0, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[1]}
                      onChange={(e) => handleChange(e, 1, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[2]}
                      onChange={(e) => handleChange(e, 2, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[3]}
                      onChange={(e) => handleChange(e, 3, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[4]}
                      onChange={(e) => handleChange(e, 4, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[5]}
                      onChange={(e) => handleChange(e, 5, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[6]}
                      onChange={(e) => handleChange(e, 6, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[7]}
                      onChange={(e) => handleChange(e, 7, "research_allowance")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.research_allowance[8]}
                      onChange={(e) => handleChange(e, 8, "research_allowance")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>소 계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[0]}
                      onChange={(e) => handleChange(e, 0, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[1]}
                      onChange={(e) => handleChange(e, 1, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[2]}
                      onChange={(e) => handleChange(e, 2, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[3]}
                      onChange={(e) => handleChange(e, 3, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[4]}
                      onChange={(e) => handleChange(e, 4, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[5]}
                      onChange={(e) => handleChange(e, 5, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[6]}
                      onChange={(e) => handleChange(e, 6, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[7]}
                      onChange={(e) => handleChange(e, 7, "subtotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.subtotal[8]}
                      onChange={(e) => handleChange(e, 8, "subtotal")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>간접비</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[0]}
                      onChange={(e) => handleChange(e, 0, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[1]}
                      onChange={(e) => handleChange(e, 1, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[2]}
                      onChange={(e) => handleChange(e, 2, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[3]}
                      onChange={(e) => handleChange(e, 3, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[4]}
                      onChange={(e) => handleChange(e, 4, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[5]}
                      onChange={(e) => handleChange(e, 5, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[6]}
                      onChange={(e) => handleChange(e, 6, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[7]}
                      onChange={(e) => handleChange(e, 7, "indirect_cost")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.indirect_cost[8]}
                      onChange={(e) => handleChange(e, 8, "indirect_cost")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>합계</td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[0]}
                      onChange={(e) => handleChange(e, 0, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[1]}
                      onChange={(e) => handleChange(e, 1, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[2]}
                      onChange={(e) => handleChange(e, 2, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[3]}
                      onChange={(e) => handleChange(e, 3, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[4]}
                      onChange={(e) => handleChange(e, 4, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[5]}
                      onChange={(e) => handleChange(e, 5, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[6]}
                      onChange={(e) => handleChange(e, 6, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OOO"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[7]}
                      onChange={(e) => handleChange(e, 7, "total")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.total[8]}
                      onChange={(e) => handleChange(e, 8, "total")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구개발비 외 지원금</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.another_support_fund[0]}
                      onChange={(e) =>
                        handleChange(e, 0, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.another_support_fund[1]}
                      onChange={(e) =>
                        handleChange(e, 1, "another_support_fund")
                      }
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.another_support_fund[2]}
                      onChange={(e) =>
                        handleChange(e, 2, "another_support_fund")
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>연구수당 계상 기준금액</td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.base_amount[0]}
                      onChange={(e) => handleChange(e, 0, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="800,000"
                      name="commissionUsePlan"
                      value={commissionUsePlan.base_amount[1]}
                      onChange={(e) => handleChange(e, 1, "base_amount")}
                    />
                  </td>
                  <td colSpan={3}>
                    <input
                      type="text"
                      placeholder="OO백만원"
                      name="commissionUsePlan"
                      value={commissionUsePlan.base_amount[2]}
                      onChange={(e) => handleChange(e, 2, "base_amount")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>3) 연구시설ㆍ장비 구축ㆍ운영계획</p>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(1) 연구시설ㆍ장비 구축계획</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <span>구축비용이 3천만원 이상인 경우에는 필수로 작성합니다.</span>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="techPlan"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div>
            <table>
              <colgroup>
                <col width={93} />
                <col width={140} />
                <col width={80} />
                <col width={140} />
                <col width={80} />
                <col width={45} />
                <col width={85} />
                <col width={110} />
                <col width={110} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th>연구개발기관명</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    연구시설ㆍ장비명
                  </th>
                  <th>
                    현금/현물
                    <br />
                    구분
                  </th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    구축방식
                  </th>
                  <th>규 격</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    수량
                  </th>
                  <th>구축비용</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    구축기간
                  </th>
                  <th>설치장소</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {techPlan.map((data, index) => {
                  const {
                    agency_name,
                    facility_name,
                    sortation,
                    construction_method,
                    standard,
                    quantity,
                    construction_cost,
                    construction_period,
                    install_place,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={agency_name}
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={facility_name}
                          placeholder="입력하세요"
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "facility_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={sortation}
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={construction_method}
                          placeholder="입력하세요"
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "construction_method")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={standard}
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "standard")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={quantity}
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "quantity")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={construction_cost}
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "construction_cost")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={construction_period}
                          placeholder="입력하세요"
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "construction_period")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={install_place}
                          placeholder="입력하세요"
                          name="techPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "install_place")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(2) 연구시설ㆍ장비 운영ㆍ활용계획</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="utilizationPlan"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div>
            <table>
              <colgroup>
                <col width={93} />
                <col width={93} />
                <col width={140} />
                <col width={90} />
                <col width={85} />
                <col width={85} />
                <col width={85} />
                <col width={85} />
                <col width={85} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th rowSpan={2}>
                    연구개발
                    <br />
                    기관명
                  </th>
                  <th rowSpan={2}>연구시설명</th>
                  <th rowSpan={2}>기존/신규 구분</th>
                  <th rowSpan={2}>운영기간</th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    비 용
                  </th>
                  <th rowSpan={2}>전담인력 수</th>
                  <th rowSpan={2}>활용계획</th>
                  <th rowSpan={2}>설치장소</th>
                  <th rowSpan={2}>삭제</th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    연간운영비용
                  </th>
                  <th>과제반영비용</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    현금/현물 구분
                  </th>
                </tr>
              </thead>
              <tbody>
                {utilizationPlan.map((data, index) => {
                  const {
                    agency_name,
                    facility_name,
                    sortation,
                    operate_period,
                    annual_expenses,
                    project_cost,
                    cash_sortation,
                    personnel_number,
                    util_plan,
                    install_place,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={agency_name}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={facility_name}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "facility_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={sortation}
                          placeholder="입력하세요"
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={operate_period}
                          placeholder="yy-yy"
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "operate_period")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={annual_expenses}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "annual_expenses")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={project_cost}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "project_cost")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={cash_sortation}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "cash_sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={personnel_number}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "personnel_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={util_plan}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "util_plan")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={install_place}
                          name="utilizationPlan"
                          onChange={(e) =>
                            handleChangeTable(e, index, "install_place")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="big_text">3) 평가기준 및 평가방법</p>
              <FirstInfoText />
            </div>
            <div>
              <span>
                해당 시 작성하며, 작성 시에는 연구개발과제 특성에 따라
                선택적으로 항목 적용이 가능합니다.
              </span>
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">1) 성과지표 및 목표치</p>
              <BringingUpIcon />
            </div>
            <div>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="targetValue"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="rnd_target">
            <table className="rnd_target_table">
              <colgroup>
                <col width={130} />
                <col width={75} />
                <col width={140} />
                <col width={135} />
                <col width={135} />
                <col width={135} />
                <col width={135} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    성과 지표명
                  </th>
                  <th>
                    1단계
                    <br />
                    <span className="extra_text">(YY-YY)</span>
                  </th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    N단계
                    <br />
                    <span className="extra_text">(YY-YY)</span>
                  </th>
                  <th>계</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    가중치
                    <br />
                    <span className="extra_text">(%)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {targetValue.map((data, index) => {
                  const {
                    selectIndicator,
                    step,
                    first_step,
                    last_step,
                    total,
                    weight_value,
                  } = data;
                  return (
                    <tr>
                      <td colSpan={2}>
                        <select
                          defaultValue={selectIndicator}
                          name="targetValue"
                          onChange={(e) =>
                            handleChangeTable(e, index, "selectIndicator")
                          }
                        >
                          <option value="agency">
                            전담기관 등록ㆍ기탁지표
                          </option>
                          <option value="project">
                            연구개발과제 특성 반영 지표
                          </option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={step}
                          placeholder="단계"
                          name="targetValue"
                          onChange={(e) => handleChangeTable(e, index, "step")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={first_step}
                          name="targetValue"
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_step")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={last_step}
                          name="targetValue"
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_step")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={total}
                          name="targetValue"
                          onChange={(e) => handleChangeTable(e, index, "total")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="targetValue"
                          value={weight_value}
                          onChange={(e) =>
                            handleChangeTable(e, index, "weight_value")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={3}>
                    <span className="total_text">합 계</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="fifthPageData"
                      value={fifthPageData.targetValueTotal[0]}
                      onChange={(e) => handleChange(e, 0, "targetValueTotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="fifthPageData"
                      value={fifthPageData.targetValueTotal[1]}
                      onChange={(e) => handleChange(e, 1, "targetValueTotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="fifthPageData"
                      value={fifthPageData.targetValueTotal[2]}
                      onChange={(e) => handleChange(e, 2, "targetValueTotal")}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="100"
                      name="fifthPageData"
                      value={fifthPageData.targetValueTotal[3]}
                      onChange={(e) => handleChange(e, 3, "targetValueTotal")}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>2) 성능지표 및 측정방법</p>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(1) 결과물의 성능지표 </p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="performanceIndicators"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div>
            <table>
              <colgroup>
                <col width={125} />
                <col width={45} />
                <col width={80} />
                <col width={90} />
                <col width={90} />
                <col width={67} />
                <col width={20} />
                <col width={67} />
                <col width={67} />
                <col width={20} />
                <col width={67} />
                <col width={145} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th rowSpan={3}>
                    평가 항목 <br />
                    <span className="extra_text">(주요성능 Spec1)</span>
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    단위
                  </th>
                  <th rowSpan={3}>
                    전체 항목에서 차지하는비중
                    <br />
                    <span className="extra_text">2) (%)</span>
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    세계최고 수준
                    <br />
                    <span className="extra_text">보유국 / 보유기업</span>
                  </th>
                  <th rowSpan={2}>
                    연구개발
                    <br />전 국내주순
                  </th>
                  <th colSpan={6} style={{ backgroundColor: "#d8d8d8" }}>
                    연구개발 목표치
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    목표 설정 근거
                  </th>
                  <th rowSpan={3}>삭제</th>
                </tr>
                <tr>
                  <th
                    rowSpan={2}
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    1단계 (yy~yy)
                  </th>
                  <th rowSpan={2} colSpan={3}>
                    N단계 (yy~yy)
                  </th>
                </tr>
                <tr style={{ height: "27px" }}>
                  <th>성능수준</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    성능수준
                  </th>
                </tr>
              </thead>
              <tbody>
                {performanceIndicators.map((data, index) => {
                  const {
                    evaluationItem,
                    unit,
                    importance,
                    world_highest_level,
                    domestic_level,
                    first_start_year,
                    first_end_year,
                    last_start_year,
                    last_end_year,
                    basis,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          value={evaluationItem}
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "evaluationItem")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={unit}
                          name="performanceIndicators"
                          onChange={(e) => handleChangeTable(e, index, "unit")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={importance}
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "importance")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={world_highest_level}
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "world_highest_level")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={domestic_level}
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "domestic_level")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={first_start_year}
                          placeholder="OO"
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_start_year")
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <input
                          type="text"
                          value={first_end_year}
                          placeholder="OO"
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={last_start_year}
                          placeholder="OO"
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_start_year")
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <input
                          type="text"
                          value={last_end_year}
                          placeholder="OO"
                          name="performanceIndicators"
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={basis}
                          name="performanceIndicators"
                          onChange={(e) => handleChangeTable(e, index, "basis")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(2) 평가방법 및 평가환경 </p>
              <BringingUpIcon />
            </div>
            <div>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="evaluation"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div>
            <table>
              <colgroup>
                <col width={67} />
                <col width={142} />
                <col width={416} />
                <col width={285} />
                <col width={46} />
              </colgroup>
              <thead>
                <tr>
                  <th>순번</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    평가항목 (성능지표)
                  </th>
                  <th>평가방법</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    평가환경
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {evaluation.map((data, index) => {
                  const {
                    evaluation_items,
                    evaluation_method,
                    evaluation_environment,
                  } = data;
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          value={evaluation_items}
                          name="evaluation"
                          onChange={(e) =>
                            handleChangeTable(e, index, "evaluation_items")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={evaluation_method}
                          name="evaluation"
                          onChange={(e) =>
                            handleChangeTable(e, index, "evaluation_method")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={evaluation_environment}
                          name="evaluation"
                          onChange={(e) =>
                            handleChangeTable(
                              e,
                              index,
                              "evaluation_environment"
                            )
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="prev_next_button">
          <button
            className="prev_button"
            onClick={() => history.push("/plan/rnd/list/fourth")}
          >
            <img src={prevButton} alt="prevButton" />
          </button>
          <button
            className="next_button"
            // onClick={() => alert("마지막 페이지 입니다.")}
            onClick={() => sendData()}
          >
            <img src={nextButton} alt="nextButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RnDPlanFifth;
