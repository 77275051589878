import React, { useState } from "react";
import InfoCompanyAPI from "../../../API/infoCompany";
import _ from "lodash";
import ChartTest from "./ChartTest";
import ChartSearch from "./ChartSearch";
import "../css/ChartInput.scss";

const ChartInput = () => {
  const [payload, setPayload] = useState({
    name: "",
    company_code: "",
  });

  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [mouse, setMouse] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordList, setKeywordList] = useState([]);
  const show = mouse && Boolean(keywordList.length);

  const onClickSearch = () => {
    InfoCompanyAPI.getInfoCompany(keyword).then((res) => {
      setMouse(true);
      setKeywordList(res.data);
    });
  };

  const onClickClose = () => {
    setMouse(false);
    setKeyword("");
    setKeywordList([]);
    setPayload({ name: "", company_code: "" });
  };

  const choiceKeyword = (name, code) => {
    setMouse(false);
    setPayload({ ...payload, name: name, company_code: code });

    let copy = _.cloneDeep(selectCompanyList);
    copy.push({ name: name, company_code: code });
    setSelectCompanyList(copy);
  };
  return (
    <div id="ChartInput">
      <div className="field">
        <span>검색</span>
        <input
          type="text"
          value={keyword}
          placeholder="회사명을 입력하여 검색하실 수 있습니다"
          onChange={(e) => setKeyword(e.target.value)}
          onKeyUp={(e) => e.key === "Enter" && onClickSearch()}
          onFocus={() => setMouse(true)}
        />
        {show && (
          <button className="icon_badge_del ir_txt" onClick={onClickClose}>
            닫기
          </button>
        )}
        {show && (
          <ul className="keyword_list">
            {keywordList.map((item, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => choiceKeyword(item.name, item.company_code)}
                >
                  <p>
                    <b>회사명:</b>
                    {item.name}
                  </p>
                  <p>
                    <b>주소:</b>
                    {item.location}
                  </p>
                  <p>
                    <b>법인번호:</b>
                    {item.corporate_number}
                  </p>
                  <p>
                    <b>사업자 등록번호:</b>
                    {item.license_number}
                  </p>
                </li>
              );
            })}
          </ul>
        )}
        <button className="btn_search" onClick={onClickSearch}>
          검색
        </button>
      </div>
      <ChartSearch company_idx={payload.company_code} />
    </div>
  );
};

export default ChartInput;
