import Menu from "./common/Menu";
import "./css/ManageTradeMark.scss";
import React, { useEffect, useState } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
import ManageTradeMarkForm from "./ManageTradeMarkForm";

const ManageTradeMark = () => {
  const location = useLocation();
  const { category } = qs.parse(location.search);
  const [number, setNumber] = useState(0);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    settingNumber();
  }, [category]);

  const settingNumber = () => {
    let num = 0,
      name = "";

    switch (category) {
      case "applicant":
        num = 8;
        name = "출원인 관리";
        break;
      case "rightHolder":
        num = 9;
        name = "최종권리자 관리";
        break;
      case "trademarkName":
        num = 10;
        name = "상표명칭 관리";
        break;
      case "judgmentState":
        num = 11;
        name = "판결승소상태 관리";
        break;
      case "disputeCase":
        num = 12;
        name = "분쟁사건종류 관리";
        break;
      case "disputeCompany":
        num = 13;
        name = "분쟁중인기업 관리";
        break;
      case "historyJobTitle":
        num = 14;
        name = "히스토리업무제목 관리";
        break;
      case "selectboxDB":
        num = 15;
        name = "셀렉DB 관리";
        break;
    }
    setNumber(num);
    setCategoryName(name);
  };

  return (
    <div id="ManageTradeMark">
      <Menu number={number} />
      <ManageTradeMarkForm category={category} categoryName={categoryName} />
    </div>
  );
};

export default ManageTradeMark;
