import React from "react";

function MobileListItem({name, email, position}) {
    return (
        <li>
            <div className="info">
                <h2>{name}</h2>
                <p>{email}</p>
            </div>
        </li>
    )
}

export default MobileListItem;
