import React from "react";
import "./css/CyberChangeApply.scss"
import {Link} from "react-router-dom";

function CyberChangeApply() {
    return (
        <div id="CyberChangeApply">
            <i className="icon_check_green"/>
            <h2>출금 신청이 완료되었습니다.</h2>
            <p>
                신청한 다음 영업일 회원님의 은행계좌에 입금해드립니다.<br/>
                (은행 영업일 기준)
            </p>
            
            <Link to="/cyber" className="btn">메인으로</Link>
        </div>
    )
};

export default CyberChangeApply;