import React from "react";
import "./css/CyberSignupSuccess.scss"
import {Link} from "react-router-dom";

function CyberSignupSuccess() {
    return (
        <div id="CyberSignupSuccess">
            <i className="icon_check_green"/>
            <h2>가입이 완료되었습니다.</h2>
            <p>가입하신 정보로 로그인해주세요</p>
            
            <Link to="/cyber/login" className="btn">로그인</Link>
        </div>
    )
}

export default CyberSignupSuccess;