import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./css/IntroIP.scss";
import InfoAPI from "../../API/info/index";

function IntroIP() {
    const [news, setNews] = useState([]);
    const [youtube, setYoutube] = useState([]);

    useEffect(() => {
        let payload = {
            keyword: "",
            count: 2,
            page: 1
        };

        InfoAPI.getNews(payload).then(res => {
            setNews(res.data.items.map((item, index) => {
               return (
                   <div className="item" key={index} onClick={() => window.open(item.url, "_blank")}>
                       <img src={window.$Global.getCDN(item.thumbnail_key)} alt="이미지"/>
                       <div className="item_info">
                           <h2>{item.title}</h2>
                           <p>{item.content}</p>
                       </div>
                   </div>
               );
            }));
        });

        InfoAPI.getYoutube(payload).then(res => {
            setYoutube(res.data.items.map((item, index) => {
                return (
                    <div className="item" key={index} onClick={() => window.open(item.url, "_blank")}>
                        <img src={window.$Global.getCDN(item.thumbnail_key)} alt="이미지"/>
                        <div className="item_info youtube">
                            <h2>{item.title}</h2>
                            <p>{item.content}</p>
                        </div>
                    </div>
                );
            }));
        });
    }, []);

    return (
        <div id="IntroIP">
            <div className="header">
                <h2 className="title">IP 담보 대출이란</h2>
            </div>
            <div className="step">
                <img src={require('../../assets/images/common/bg_patent_info.png').default} alt="이미지"/>
            </div>

            <div className="wrap_content">
                <div className="info">
                    <div className="header">
                        <h2>뉴스자료</h2>
                        <Link to="/application/content/news">더보기</Link>
                    </div>
                    <div className="content_list">
                        {news}
                    </div>
                </div>
                <div className="info">
                    <div className="header">
                        <h2>Youtube</h2>
                        <Link to="/application/content/youtube">더보기</Link>
                    </div>
                    <div className="content_list">
                        {youtube}
                    </div>
                </div>
            </div>

            <img className="desc_auto" src={require('../../assets/images/ip/bg_ipnow_patent_auto.png').default} alt="이미지"/>
        </div>
    );
}

export default IntroIP;
