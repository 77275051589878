import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import '../../common/css/RelativeFileListView.scss';
import RelativeFileTable from '../../common/RelativeFileTable';
import PatentAPI from "../../../API/patent";
import MemberContext from "../../../store/Member";
import RelativeFileList from "../../common/RelativeFileList";

function RelativeFileListView() {
    const member = useContext(MemberContext);
    const {patent_idx} = useParams();
    const [manageNumber, setManageNumber] = useState(null);
    const [nation, setNation] = useState(-1);
    const [props, setProps] = useState([]);
    const isCompany = window.$Global.checkAuth("특허회사", member.auth);
    const isAgent = window.$Global.checkAuth("대리인", member.auth);

    useEffect(async () => {
        if (window.$Global.isEmptyObject(member)) return;
        let arr = [];

        let response;
        if (isCompany) {
            response = await PatentAPI.getRelationFile(patent_idx);
        } else if (isAgent) {
            response = await PatentAPI.getRelationFileAsAgent(member.manage_company_idx, patent_idx);
        }
        let data = response.data;
        setManageNumber(data.manage_number);
        setNation(data.nation);

        if (data.apply) {
            for (let i = 0; i < data.apply.length; i++) {
                data.apply[i].download = onClickDownload('apply');
                data.apply[i].t_idx = patent_idx;
                data.apply[i].manage_company_idx = member.manage_company_idx;
            }
        }

        let survey_prop = {
            title: '출원 전',
            rows: data.survey || []
        };

        arr.push(survey_prop);

        let apply_prop = {
            title: '출원',
            rows: data.apply || [],
        };
        arr.push(apply_prop);

        if (data.regist) {
            for (let i = 0; i < data.regist.length; i++) {
                data.regist[i].download = onClickDownload('regist');
                data.regist[i].t_idx = patent_idx;
                data.regist[i].manage_company_idx = member.manage_company_idx;
            }
        }
        let regist_prop = {
            title: '등록',
            rows: data.regist || [],
        };
        arr.push(regist_prop);

        for (let i = 0; i < data.oa.length; i++) {
            let oa = data.oa[i];
            let concat_arr = [];

            if (oa.content.length) {
                for (let j = 0; j < oa.content.length; j++) {
                    oa.content[j].download = onClickDownload('content');
                    oa.content[j].t_idx = patent_idx;
                    oa.content[j].oa_idx = oa.idx;
                    oa.content[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.content[j]);
                }
            }

            if (oa.draft.length) {
                for (let j = 0; j < oa.draft.length; j++) {
                    oa.draft[j].download = onClickDownload('draft');
                    oa.draft[j].t_idx = patent_idx;
                    oa.draft[j].oa_idx = oa.idx;
                    oa.draft[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.draft[j]);
                }
            }

            if (oa.opinion.length) {
                for (let j = 0; j < oa.opinion.length; j++) {
                    oa.opinion[j].download = onClickDownload('opinion');
                    oa.opinion[j].t_idx = patent_idx;
                    oa.opinion[j].oa_idx = oa.idx;
                    oa.opinion[j].manage_company_idx = member.manage_company_idx;
                    concat_arr.push(oa.opinion[j]);
                }
            }

            if (concat_arr.length) {
                let oa_prop = {
                    title: 'OA' + Number(i + 1),
                    rows: concat_arr,
                };
                arr.push(oa_prop);
            }
        }
        setProps(arr);
    }, [member]);

    const onClickDownload = (type) => {
        switch (type) {
            case "apply":
                return isCompany ? PatentAPI.downloadApplyFile : PatentAPI.downloadApplyFileAsAgent;
            case "regist":
                return isCompany ? PatentAPI.downloadRegistFile : PatentAPI.downloadRegistFileAsAgent;
            case "content":
                return isCompany ? PatentAPI.downloadOaContentFile : PatentAPI.downloadOaContentFileAsAgent;
            case "draft":
                return isCompany ? PatentAPI.downloadOaDradtFile : PatentAPI.downloadOaDradtFileAsAgent;
            case "opinion":
                return isCompany ? PatentAPI.downloadOaOpinionFile : PatentAPI.downloadOaOpinionFileAsAgent;
        }
    };

    let tag =
        props.map((item, index) => {
            return <RelativeFileTable key={index} props={item}></RelativeFileTable>
        });

    return (
        <RelativeFileList title="관리번호" manage_number={manageNumber} nation={window.$Global.convertNationCodeToStr(nation)} tag={tag}/>
    );
}

export default React.memo(RelativeFileListView);
