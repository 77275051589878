import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ContentDetail from "../common/ContentDetail";
import CommonAPI from "../../API/common/index";

function NotifyDetail({history}) {
    const {idx} = useParams();
    const [postData, setPostData] = useState({});

    useEffect(() => {
        CommonAPI.getNoticeInfo(idx).then(res => setPostData(res.data));
    }, []);

    const fileDownload = (file_idx) => {
        CommonAPI.downloadNotice(idx, file_idx);
    };

    return (
        <div id="NofityDetail">
            <ContentDetail history={history} path="/notify" page_title="공지사항" title={postData.title} update_at={window.$Global.convertDate(postData.created_at)}
                           content={postData.content} files={postData.files} onClickDownload={fileDownload}/>
        </div>
    )
}
export default NotifyDetail;
