import {css} from "@emotion/react";

export const container = css`
  padding: 10px;
`;

export const typeWrap = css`
  display: flex;
  column-gap: 10px;

  button {
    height: 40px;
    width: 150px;
    font-size: 20px;
    cursor: pointer;
  }
`;

export const taskBody = css`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  row-gap: 30px;
  flex-wrap: wrap
`;

export const taskInfoWrap = css`
  width: 600px;
`;

export const taskTitle = css`
  font-size: 24px;
  background-color: #f1f1f1;
`;

export const taskListWrap = css`
  padding: 10px;
  margin-top: 10px;
  overflow-y: auto;
  height: 400px;
`;

const itemCss = css`
  cursor: pointer;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
`;
export const taskItemWrap = (bidderType) => css`
  background-color: #${bidderType === 2 ? '85cccc' : 'ffffff'};
  ${itemCss};
`;

export const companyInfoWrap = (isWinner) => css`
  background-color: #${isWinner === 1 ? '85cccc' : 'ffffff'};
  ${itemCss};
  column-gap: 30px;
`;

export const finaInfoWrap = css`
  width: 1500px;
`

export const finaItemWrap = css`
  ${itemCss};
  column-gap: 30px;
`;

export const finaInfo = (able) => css`
  display: flex;
  width: 100px;
  color: ${able ? "blue" : "red"};
`;

export const rivalListWrap = css`
  width: 800px;
`;

export const rivalInfoWrap = css`
  width: 400px;
`;