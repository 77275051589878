import CommonAPI from "../../API/common";
import { useEffect, useState } from "react";
import "./IncriselPopupLog.scss";
const IncriselPopupLog = () => {
  const [countData, setCountData] = useState();
  const [ageData, setAgeData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    checkAPI();
  }, []);

  const checkAPI = () => {
    CommonAPI.getVisitCount({
      type: 1,
    }).then((res) => {
      console.log(res.data.ageRange);
      setCountData(res.data);
      setAgeData(Object.entries(res.data.ageRange));
    });
  };

  return (
    <div id="IncriselPopupLog">
      <table>
        <thead>
          <tr>
            <th colSpan={2}>일별 전체 방문자수</th>
          </tr>
        </thead>
        <tbody>
          {countData?.dailyVisitCount.map((el) => {
            return (
              <tr>
                <td>{el.date}</td>
                <td>{el.cnt}명</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th colSpan={2}>일별 회원 방문자수</th>
          </tr>
        </thead>
        <tbody>
          {countData?.dailyVisitMemberCount.map((el) => {
            return (
              <tr>
                <td>{el.date}</td>
                <td>{el.cnt}명</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <table className="age">
        <thead>
          <tr>
            <th colSpan={3}>방문자 나이대</th>
          </tr>
        </thead>
        <tbody>
          {ageData?.map((el) => {
            return (
              <>
                <tr>
                  <td>{el[0]}</td>
                  {Object.entries(el[1]).map((el) => {
                    console.log(el);
                    if (el[0] === "null") {
                      return;
                    }
                    return (
                      <tr className="count">
                        <td>
                          {el[0]}대 &emsp;{el[1]}명
                        </td>
                      </tr>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IncriselPopupLog;
