import IncidentPopup from "../common/IncidentPopup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";
import API from "../../util/api";
import memberContext from "../../store/Member";
import CheckBox from "../common/CheckBox";
import _ from "lodash";
import TradeMarkIncidentPopup from "./TradeMarkIncidentPopup";
import TradeMarkIncidentPopup2 from "./TradeMarkIncidentPopup2";
const TradeMarkFamily = () => {
  const { trademark_idx } = useParams();
  const history = useHistory();
  const treeRef = useRef();
  const { auth, manage_company_idx } = useContext(memberContext);
  const [targetTradeMark, setTargetTradeMark] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [singleClassName, setSingleClassName] = useState("");
  const [selectOn, setSelectOn] = useState(false);
  /* ===병합용=== */
  const [selectOn2, setSelectOn2] = useState(false);
  /* ===병합용=== */
  const [selectFamilyOn, setSelectFamilyOn] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [checkedTradeMark, setCheckedTradeMark] = useState({});
  const [disableMerge, setDisableMerge] = useState(false);
  const [tradeMarkTree, setTradeMarkTree] = useState([]);
  const [showPopupFamily, setShowPopupFamily] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  useEffect(() => {
    drawTree(treeData);
  }, [treeData, selectOn, selectFamilyOn, checkedList, selectOn2]);

  useEffect(() => {
    initAPI();
  }, [auth]);

  const initAPI = async () => {
    let result = [];
    result = await TradeMarkAPI.getTradeMarkFamily(trademark_idx).then(
      (res) => res.data
    );
    console.log('TradeMarkFamily initAPI = ' + JSON.stringify(result))
    setTargetTradeMark(result.find((item) => item.idx === trademark_idx));
    setTreeData(result);
    return result;
  };

  const drawTree = (res) => {
    let data = res.concat();
    let arr = [];
    let arr_root = data.filter((item) => item.parent_idx === null);
    if (arr_root.length === 1) {
      setSingleClassName("single");
    }

    arr_root.map((item, idx) => {
      let nation = window.$Global.convertTradeMarkNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );

      return arr.push(
        <li
          key={`${item.idx}_li_${idx}`}
          className={`level_1 ${arr_root.length === 1 ? "single" : ""}`}
        >
          {getItemTag(item, nation)}
          {drawChildTradeMarkRecursion(data, item.idx)}
        </li>
      );
    });
    setTradeMarkTree(arr);
  };

  const getItemTag = (item, nation) => {
    let tag = (
      <>
        <div className="idx">
          {selectOn || selectFamilyOn || selectOn2 ? (
            <CheckBox
              checked={checkedList[item.idx] || false}
              onChangeChecked={() => {
                let arr = _.cloneDeep(checkedList);
                arr[item.idx] = !arr[item.idx];
                if (arr[item.idx]) {
                  setCheckedTradeMark(item);
                } else {
                  setCheckedTradeMark({});
                }
                if (treeData.find((data) => data.idx == item.idx).parent_idx) {
                  setDisableMerge(true);
                } else {
                  setDisableMerge(false);
                }
                setCheckedList(arr);
              }}
            />
          ) : (
            item.depth
          )}
        </div>
        <div className="flag">
          <i className={`icon_flag_${nation} flag`} />
          <div className="flag_txt">{nation}</div>
        </div>
        <div className="info">
          <div className="no">{item.apply_number || "출원 정보 입력 중"}</div>
          <div className="date">
            {item.apply_at ? window.$Global.convertDate(item.apply_at) : ""}
          </div>
        </div>
      </>
    );

    if (selectOn || selectFamilyOn || selectOn2) {
      return <div className="node">{tag}</div>;
    } else {
      return (
        <Link to={`/tradeMark/detail/${item.idx}`} className="node">
          {tag}
        </Link>
      );
    }
  };

  const drawSameTradeMark = (data, parent_idx, self_idx, current_depth) => {
    let same_trademark_arr = data.filter(
      (item) =>
        (item.parent_idx === parent_idx &&
          item.depth === current_depth &&
          self_idx !== item.idx) ||
        item.dp == 0
    );
    /*console.log('drawSameTradeMark = ' + parent_idx)*/
    if (same_trademark_arr.length) {
      let item = same_trademark_arr[0];
      let nation = window.$Global.convertTradeMarkNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      return (
        <>
          <li key={`${item.idx}_li`} className={item.dp == 0 ? "dp_0" : ""}>
            {getItemTag(item, nation)}
            {drawChildTradeMarkRecursion(data, item.idx, item.depth)}
          </li>
          {drawSameTradeMark(data, item.parent_idx, item.idx, item.depth)}
        </>
      );
    }
  };
  
  const drawChildTradeMarkRecursion = (data, trademark_idx) => {
    /*console.log(data);*/
    
    let next_trademark_arr = data.filter((item) => {
      return item.parent_idx === trademark_idx;
    });

    if (next_trademark_arr.length) {
      let item = next_trademark_arr[0];
      let nation = window.$Global.convertTradeMarkNationCodeToStr(item.nation);
      data.splice(
        data.findIndex((obj) => obj.idx === item.idx),
        1
      );
      console.log('next_trademark_arr.data = ' + data);
      console.log('next_trademark_arr.parent_idx = ' + item.parent_idx);
      console.log('next_trademark_arr.depth = ' + item.depth);
      
      return (
        <ul className={`level_${item.depth}`}>
          <li key={`${item.idx}_li`}>
            {getItemTag(item, nation)}
            {drawChildTradeMarkRecursion(data, item.idx)}
          </li>
          {drawSameTradeMark(data, item.parent_idx, item.idx, item.depth)}
        </ul>
        
      );
    }
  };

  const onClickSelectHide = () => {
    setSelectOn(false);
    setSelectOn2(false);
    setSelectFamilyOn(false);
  };

  const onClickSelectShow = () => {
    setSelectOn(true);
  };

  /* ===병합용=== */
  const onClickSelectShow2 = () => {
    setSelectOn2(true);
  };
  /* ===병합용=== */
  const onClickShowPopup = () => {
    if (window.$Global.isEmptyObject(checkedTradeMark)) {
      alert("특허를 선택해주세요");
      return;
    }
    setShowPopup(true);
  };

  const onClickShowPopup2 = () => {
    if (window.$Global.isEmptyObject(checkedTradeMark)) {
      alert("특허를 선택해주세요");
      return;
    }
    setShowPopup2(true);
  };

  const onClickFamilyTradeMarkAdd = async (payload) => {
    
    await API.post(
      `/manager/trademark/${checkedTradeMark.idx}/family_register`,
      payload
    );
    console.log('onClickFamilyTradeMarkAdd = ' + payload)
    setShowPopup(false);
    setSelectOn(false);
    setSelectOn2(false);
    alert("추가되었습니다");
    setCheckedList([]);
    initAPI();
  };
  
  const onClickFamilyTradeMarkchange = async (payload) => {
    /*console.log('onClickFamilyTradeMarkchange의 trademark_idx = ' + trademark_idx)
    */
    console.log('onClickFamilyTradeMarkchange의 checkedTradeMark.idx = ' + checkedTradeMark.idx)
    
    /*
    console.log('onClickFamilyTradeMarkchange의 payload = ' + JSON.stringify(payload))
    console.log('onClickFamilyTradeMarkchange의 data = ' + data)
    console.log('onClickFamilyTradeMarkchange의 parent_idx = ' + parent_idx)
    console.log('onClickFamilyTradeMarkchange의 self_idx = ' + self_idx)
    console.log('onClickFamilyTradeMarkchange의 current_depth = ' + item.current_depth)
    console.log('onClickFamilyTradeMarkchange의 item.parent_idx = ' + item.parent_idx);
    console.log('onClickFamilyTradeMarkchange의 item.idx = ' + item.idx);
    console.log('onClickFamilyTradeMarkchange의 item.depth = ' + item.depth);*/
    console.log('onClickFamilyTradeMarkchange의 payload = ' + payload)
    await API.post(
      `/manager/trademark/${checkedTradeMark.idx}/parentChange`,  
      payload
    );
    setShowPopup2(false);
    setSelectOn(false);
    setSelectOn(false);
    alert("추가되었습니다");
    setCheckedList([]);
    initAPI();
  };


  const onClickHidePopup = () => {
    setShowPopup(false);
  };

  const onClickHidePopup2 = () => {
    setShowPopup2(false);
  };

  return (
    <div id="Family">
      <div className="header">
        <div>
          <h2 className="title">Family 페이지</h2>
          <ul className="patent_no_list">
            <li>
              특허 출원번호 {targetTradeMark.apply_number || "N/A"}
              <i
                className={`icon_flag_${window.$Global.convertTradeMarkNationCodeToStr(
                  targetTradeMark.nation
                )} flag`}
              />
            </li>
          </ul>
        </div>
        <div className="btns">
          {window.$Global.checkAuth("특허회사", auth) && (
            <>
              {(selectOn || selectFamilyOn) ? (
                <>
                  <button
                    className="btn_cancle"
                    onClick={onClickSelectHide}
                  >
                    취소
                  </button>
                  {selectOn && (
                    <button
                      className="btn_add"
                      onClick={onClickShowPopup}
                    >
                      선택 추가
                    </button>
                  )}
                </>
              ) : (
                selectOn2 || selectFamilyOn ? (
                  <>
                    <button
                      className="btn_cancle"
                      onClick={onClickSelectHide}
                    >
                      취소
                    </button>
                    <button
                      className="btn_add"
                      onClick={onClickShowPopup2}
                    >
                      병합
                    </button>
                  </>
                ) : (
                  <div>
                    <button
                      className="btn_add"
                      style={{ marginRight: 20 }}
                      onClick={onClickSelectShow}
                    >
                      사건 추가
                      
                    </button>
                    <button
                      className="btn_add"
                      style={{ marginRight: 20 }}
                      onClick={onClickSelectShow2}
                    >
                      병합
                    </button>
                    <button
                      className="btn_cancle"
                      onClick={() => history.goBack()}
                    >
                      이전
                    </button>
                  </div>
                  
                )
              )}
            </>
          )}
          
        </div>
        
      </div>
      
      <div className="tree custom_scroll" ref={treeRef}>
        <ul className={`root ${singleClassName}`}>{tradeMarkTree}</ul>
      </div>
      
      {showPopup && (
        <TradeMarkIncidentPopup
          disableMerge={disableMerge}
          onClickAdd={onClickFamilyTradeMarkAdd}
          onClickClose={onClickHidePopup}
        />
      )}
      {showPopup2 && (
        <TradeMarkIncidentPopup2
          disableMerge={disableMerge}
          onClickAdd={onClickFamilyTradeMarkchange}
          onClickClose={onClickHidePopup2}
        />
      )}
      
    </div>
    
  );

};

export default TradeMarkFamily;
