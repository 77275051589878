import { useState } from "react";
import DashBoardTop from "./ipStatistic/DashBoardTop";
import IpStatisticNew from "./IpStatisticNew";
import "./css/IpStatisticPage.scss";

const IpStatisticPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    { title: "자산실사", content: <IpStatisticNew /> },
    // { title: "기술이전후보", content: "기술이전후보" },
    // { title: "기업상세정보", content: "기업상세정보" },
  ];

  return (
    <div id="IpStatisticPage">
      <DashBoardTop title={tabs[activeTab].title} />
      <div className="tab-titles">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-title ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default IpStatisticPage;
