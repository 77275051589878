import React from "react";

function ChildNationRow({ ep, onClick, withCheckpoint, onOpenReport }) {
  return (
    <div className="row">
      <h2 className="title">
        지정국가
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage("지정국가")} />
      </h2>
      <p style={{ display: "flex", alignItems: "center" }}>
        {ep.map((item) => window.$Global.convertChildNationCodeToStr(item.child_nation)).join(",")}
        <button className="icon_plus" onClick={onClick} />
      </p>
    </div>
  );
}

export default ChildNationRow;
