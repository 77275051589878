import React, { useContext, useEffect, useState } from "react";
import "./css/ProductPopUp.scss";
import TradeMarkAPI from "../../API/trademark";
import TradeMarkContext from "../../store/TradeMark";

const ProductPopUp = ({ setIsProductionPopupClick }) => {
  const tradeMark = useContext(TradeMarkContext);
  const [classInfo, setClassInfo] = useState([]);
  const [classes, setClasses] = useState(tradeMark.product_classification);

  useEffect(() => {
    TradeMarkAPI.getProductClass().then((res) => {
      setClassInfo(res.data.items);
    });
  }, []);

  const handleClass = (e) => {
    if (e.target.checked) {
      // Add the class to the array if it's checked
      setClasses([...classes, Number(e.target.value)]);
    } else {
      // Remove the class from the array if it's unchecked
      const updatedClasses = classes.filter(
        (value) => value !== Number(e.target.value)
      );
      setClasses(updatedClasses);
    }
  };

  const handleAllClass = (type) => {
    if (type === "allChecked") {
      setClasses(Array.from(Array(45), (_, index) => index + 1));
    } else if (type === "allCancelChecked") {
      setClasses([]);
    }
  };

  return (
    <div id="ProductPopUp">
      <div className="header">
        <h2>상품분류 추가하기</h2>
        <div>
          <button
            className="btn_edit"
            onClick={() => {
              handleAllClass("allChecked");
            }}
          >
            전체 선택
          </button>
          <button
            className="btn_cancel"
            onClick={() => {
              handleAllClass("allCancelChecked");
            }}
          >
            전체 선택 해제
          </button>
          <button
            className="btn_save"
            onClick={() => {
              tradeMark.setTradeMark({
                ...tradeMark,
                product_classification: classes,
              });
              setIsProductionPopupClick(false);
            }}
          >
            등록
          </button>
        </div>
      </div>
      <ul className="product_list">
        {classInfo.map((item) => {
          return (
            <li key={item.class}>
              <input
                type="checkbox"
                checked={classes.includes(Number(item.class))}
                value={Number(item.class)}
                onChange={(e) => handleClass(e)}
              />
              <span>제{item.class}류 - </span>
              <p>{item.product}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProductPopUp;
