import React from "react";
import "./css/MobileErrorPopup.scss";

function MobileAlertPopup({title, email, desc, onConfirm}) {
    return (
        <div id="MobileErrorPopup">
            <div className="dimmed"/>
            <div className="popup">
                <h2>{title}</h2>
                <p className="admin_email">{email ? `관리자 ${email}` : ''}</p>
                <p className="desc">
                    {desc}
                </p>
                <button className="btn" onClick={onConfirm}>확인</button>
            </div>
        </div>
    );
}

export default MobileAlertPopup;
