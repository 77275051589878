export default {
  showType: {
    bizAll: 0,
    bizOrganized: 1,
    bizMatching: 2,
  },
  stateType: {
    all: -1,
    receiving: 4,
    scheduled: 2,
  },
};
