import React, {useEffect, useState} from "react";
import "../css/ResearchTechRequestList.scss";
import CommonAPI from "../../../API/common";
import {Link} from "react-router-dom";
import Pagination from "../../common/Pagination";

function ResearchTechRequestList() {
	const [curPage, setCurPage] = useState(1);
	const [tbody, setTbody] = useState([]);
	const [ downloadCnt, setDownloadCnt ] = useState(0);

	const count = 20;

	useEffect(() => {
		getCompanyInfo();
	}, []);

	useEffect(() => {
		let params = {
			page: 1,
			count: count
		};
		CommonAPI.getResearchDocumentList(params).then(res => {
			let data = res.data;
			setTbody(data);
		});
	}, [curPage]);

	const download = (idx) => {
		CommonAPI.downloadDocument(idx);
		getCompanyInfo();
	};

	const getCompanyInfo = () => {
		CommonAPI.getCompanyInfo().then(res => {
			const data = res.data;
			setDownloadCnt(data.research_download_cnt);
		});
	}

	return (
		<div className="researchTechRequestList">
			<div className="researchTechRequestList_content">
				<div className="header">
					<h2>선행기술 조사 리스트</h2>
					<div className="download_cnt">잔여 다운로드 <span>{downloadCnt}</span>회</div>
				</div>

				<div className="table-box-wrap">
					<div className="wrap_list" id="scrollableDiv">
						<table>
							<thead>
								<tr>
									<th>순번</th>
									<th>분류</th>
									<th>제목</th>
									<th>작성 날짜</th>
									<th>불러오기</th>
									<th>다운로드</th>
								</tr>
							</thead>
							<tbody>
							{
								tbody.items?.map((item, idx) => {
									return (
										<tr key={item.idx}>
											<td>{tbody.total_count - idx}</td>
											<td>
												{
													item?.keyword_idx ? '키워드검색' : item.category1_name + '>' + item.category2_name
												}
											</td>
											<td>{item.title}</td>
											<td>{window.$Global.convertDate(item.created_at)}</td>
											<td>
												<Link to={`edit?doc_idx=${item.idx}`} className="btn_load">불러오기</Link>
											</td>
											<td>
												<button className="icon_download ir_txt" onClick={() => download(item.idx)}>다운로드</button>
											</td>
										</tr>
									)
								})
							}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<Pagination curPage={curPage} lastNum={tbody.last} onClick={setCurPage} />
		</div>
	)
}

export default ResearchTechRequestList;