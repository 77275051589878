import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import "./css/AnnualFeeManage.scss";
import AnnualFeeChart from "./AnnualFeeChart";
import AnnualPayAPI from "../../API/annualPay";
import qs from "query-string";
import CommonAPI from "../../API/common";
import { nationType } from "../../types/nationType";

function AnnualFeeManage({ history, location }) {
  const {
    selectYear,
    selectMonth,
    nation,
    nation_string,
    manage_number,
    register_number,
    deadline_at,
    apply_number,
    companyName,
    type,
    document_number,
  } = qs.parse(location.search);

  const { type_idx } = useParams();
  const year = new Date().getFullYear();
  const remove_width = 6.25; // 비활성년도 만큼 hr width를 줄일 값
  const isPatent = type == "patent";
  const isTradeMark = type == "tradeMark";
  const isDesign = type == "design";
  const [arr, setArr] = useState([]); // 그래프 데이터
  const [width, setWidth] = useState(0);
  const [stateShow, setStateShow] = useState(false); // 딜레이 때문에 사용
  const [disable_year, setDisableYear] = useState(0); // 비활성 년도
  const [state, setState] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (width) {
      setStateShow(true);
    }
  }, [width]);

  useEffect(async () => {
    let result = [];

    if (isPatent) {
      let payload = await CommonAPI.getOption(0).then((res) => {
        let option = res.data[0]?.option || {
          start_year: year,
          end_year: year + 15,
        };
        return option;
      });
      result = await AnnualPayAPI.getPatentAnnualPay(type_idx, payload).then(
        (res) => res.data
      );
    } else if (isTradeMark) {
      let payload = {
        start_year: year,
        end_year: year + 17,
      };
      result = await AnnualPayAPI.getTradeMarkAnnualPay(type_idx, payload).then(
        (res) => res.data
      );
    } else if (isDesign) {
      let payload = {
        start_year: year,
        end_year: year + 17,
      };
      result = await AnnualPayAPI.getDesignAnnualPay(type_idx, payload).then(
        (res) => res.data
      );
    }

    let arr = result.filter((item) => item.year >= year).slice(0, 17);

    if (!arr.length) return;

    setArr(
      arr.map((item) =>
        item.year <= deadline_at
          ? Number.isInteger(item.cost)
            ? item.cost
            : item.td_cost
          : 0
      )
    );

    setDisableYear(
      arr[arr.length - 1].year - deadline_at < 0
        ? 0
        : arr[arr.length - 1].year - deadline_at
    );
    setState(
      arr.map((item, index) => {
        if (index > 16) return null;
        if (item.year == year) {
          return (
            <div key={index} className="state current">
              현재
            </div>
          );
        } else if (item.year == deadline_at) {
          return (
            <div key={index} className="state red">
              만료
            </div>
          );
        } else {
          return <div key={index} className="state" />;
        }
      })
    );
  }, []);

  const callback = (axes_width) => {
    setWidth(axes_width);
  };

  const calcEndPoint = () => {
    return "calc(" + remove_width * disable_year + "%" + " + " + 35 + "px)";
  };

  return (
    <div id="AnnualFeeManage">
      <div className="header">
        <div>
          <h2 className="title">연차료 관리</h2>
          <p>
            관리번호 {nation} {manage_number}
            <i className={`icon_flag_${nation}`} />
          </p>
          <p>등록번호 {register_number}</p>
          <ul>
            <li>
              <Link to={`/${type}/relativeFile/${type_idx}`}>
                <p>관련 파일</p>
                <i className="icon_attached_file" />
              </Link>
            </li>
            {isPatent && (
              <li>
                <Link to={`/${type}/family/${type_idx}`}>
                  <p>Family</p>
                  <i className="icon_home" />
                </Link>
              </li>
            )}
            {isPatent && (
              <>
                <li>
                  <div
                    onClick={() => {
                      setActiveTab(0);
                      setShowPopup(true);
                    }}
                  >
                    <p>특허 정보</p>
                    <i className="icon_home" />
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setActiveTab(1);
                      setShowPopup(true);
                    }}
                  >
                    <p>명세서</p>
                    <i className="icon_home" />
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setActiveTab(2);
                      setShowPopup(true);
                    }}
                  >
                    <p>연관 기업</p>
                    <i className="icon_home" />
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      setActiveTab(3);
                      setShowPopup(true);
                    }}
                  >
                    <p>시장 정보</p>
                    <i className="icon_home" />
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      history.push(`/patent/grade/${type_idx}`);
                    }}
                  >
                    <p>특허 등급</p>
                    <i className="icon_home" />
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
        <button
          onClick={() =>
            history.replace(`/annual/${year}/list`, {
              year: selectYear,
              month: selectMonth,
            })
          }
        >
          목록으로 돌아가기
        </button>
      </div>
      <AnnualFeeChart
        callback={callback}
        arr_data={arr || []}
        redirect={() => {}}
        deadline_at={deadline_at}
      />
      {stateShow && (
        <>
          <div
            className="annual_fee_state"
            style={{
              width: "calc(100%" + " - " + width + "px)",
              padding: "0 16px",
            }}
          >
            <div className="start_point" />
            <hr
              style={{
                width:
                  "calc(100% - 66px - " + disable_year * remove_width + "%)",
              }}
            />
            <div className="end_point" style={{ right: calcEndPoint() }} />
            <div className="wrap_state">{state}</div>
          </div>
        </>
      )}
      {showPopup && (
        <div className="modal">
          <iframe
            src={`https://biznavi.co.kr/researchTech/ipnow/patent/${
              nation_string === "KR"
                ? apply_number.replace(/-/g, "")
                : document_number
            }/detail?nation=${
              nation == 3 ? "EP" : nation_string
            }&companyName=${companyName}&tab=${activeTab}`}
            width="100%"
            height="100%"
          />
          <button onClick={() => setShowPopup(false)}>
            <i className="icon_exit" />
          </button>
        </div>
      )}
    </div>
  );
}

export default AnnualFeeManage;
