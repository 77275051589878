import dashboardImg from "../../../assets/images/intro/mo-dashboard.jpg";
import "../css/RenewalDashBoard.scss";
import { useState } from "react";

const RenewalDashBoard = () => {
  const [popupShow, setPopupShow] = useState(true);
  return (
    <div id="RenewalDashBoard">
      <div className="show_dashboard">
        <img src={dashboardImg} />
      </div>
      {popupShow && (
        <div className="popup_wrapper">
          <div className="popup">
            <div className="popup_info_text">
              <p className="green f16 g_sans">Biznavi 2.0ver</p>
              <p className="f16">쉽고 빠르게 · 모든 업무가 가능한</p>
              <p className="green f30">
                PC버전 대시보드를
                <br /> 확인하세요 !
              </p>
            </div>
            <div className="popup_info_text">
              <p className="f14">Mobile 버전과 같이</p>
              <p className="f32">5월 중</p>
              <p className="gradient f32">GRAND OPEN</p>
            </div>
            <div>
              <button onClick={() => setPopupShow(false)}>
                대시보드 살펴보기
              </button>
            </div>
            <i
              className="icon_del_small_with_bg_gray"
              onClick={() => setPopupShow(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default RenewalDashBoard;
