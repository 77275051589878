import React from "react";

function NormalRow({
  rowClassName,
  title,
  titleClassName,
  txtType,
  content,
  contentClassName,
  component,
  withCheckpoint,
  onOpenReport,
}) {
  return (
    <div className={`row ${rowClassName || ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage(title)} />
      </h2>
      {content || content === null ? (
        <p className={contentClassName ? contentClassName : ""}>
          {content == null ? "N/A" : txtType === "date" ? window.$Global.convertDate(content) : content}
        </p>
      ) : (
        component
      )}
    </div>
  );
}

export default NormalRow;
