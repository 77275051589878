import React, { useContext, useEffect, useState } from "react";
import "./css/ConsortiumDetail.scss";
import {Link, useParams} from 'react-router-dom';
import ConsortiumAPI from "../../API/consortium";
import memberContext from "../../store/Member";
import config from "./const";
import CommonAttachPopup from "../common/CommonAttachPopup";
import MobilePopupContext from "../../store/MobilePopup";
import MobilePopupAttach from "../common/MobilePopupAttach";

function ConsortiumDetail({history}) {
	const MobilePopup = useContext(MobilePopupContext);
	const member = useContext(memberContext);
	const { idx } = useParams();
	const [showPopup, setShowPopup] = useState(false);
	const [resData, setResData] = useState({
		introduce_file: []
	});
	const myCompany = member.company_idx == resData.company_idx;
	const typeStr = resData.type == config.supply ? '내 기업 소개하기' : '필요 기업 찾기';
	const editQuery = `&postType=edit&idx=${resData.idx}`;
	const modifyLink = `../modify?type=${resData.type}${editQuery}`;
	const listLink = `../list?type=${resData.type}`;

	const haveIntroduceFile = Boolean(resData.introduce_file.length);

	const [moPopupFileShow, setMoPopupFileShow] = useState(false); // 모바일 파일 다운 팝업

	useEffect(() => { // 헤더 close 이벤트 발생 시
		if (!MobilePopup.mobilePopup.mobileShow) {
			setMoPopupFileShow(false);
		}
	}, [MobilePopup.mobilePopup]);

	const onClickMobileIntroduceFile = () => {
		let str = "회사소개 자료";
		let payload = {
			...MobilePopup.mobilePopup,
			mobileShow: true,
			mobileHeaderTitle: str,
		};
		MobilePopup.setMobilePopup(payload);
		setMoPopupFileShow(true);
	};

	useEffect(() => {
		ConsortiumAPI.getInfo(idx).then(res => setResData(res.data));
	}, []);

	const download = (file_idx) => {
		ConsortiumAPI.download(idx, file_idx);
	}

	const deletePost = () => {
		if (window.confirm("삭제하시겠습니까?")) {
			ConsortiumAPI.deletePost(idx).then(() => {
				alert("삭제되었습니다");
				history.replace("../list");
			});
		}
	}

	return (
		<div id="ConsortiumDetail">
			<div className="pc">
				<div className="consortiumDetail">
					<div className="consortiumDetail_title-wrap">
						<h2 className="consortiumDetail_title">
							{typeStr}
						</h2>
						{
							myCompany
							&&
							<div className="consortiumDetail_btnTop-wrap">
								<Link to={modifyLink} className="consortiumDetail_btn-modify">
									수&nbsp;&nbsp;정
								</Link>
								<button type="button" className="consortiumDetail_btn-del" onClick={deletePost}>
									삭&nbsp;&nbsp;제
								</button>
							</div>
						}
					</div>
					<div className="consortiumDetail_info">
						<div className="consortiumDetail_info_img">
							<img src={window.$Global.getCDN(resData.profile_image_key)} alt={resData.company_name} />
						</div>
						<div className="consortiumDetail_info_table">
							<dl className="list_table">
								<dt className="list_table_title">
									업체명
								</dt>
								<dd className="list_table_info col-1-1">
									{resData.company_name}
								</dd>
								<dt className="list_table_title">
									대표자
								</dt>
								<dd className="list_table_info col-1-2">
									{resData.representative}
								</dd>
								<dt className="list_table_title">
									기업유형
								</dt>
								<dd className="list_table_info col-1-2">
									{resData.company_type}
								</dd>
								<dt className="list_table_title">
									사업자 등록번호
								</dt>
								<dd className="list_table_info col-1-2">
									{resData.license_number}
								</dd>
								<dt className="list_table_title">
									연락처
								</dt>
								<dd className="list_table_info col-1-2">
									{resData.phone}
								</dd>
								<dt className="list_table_title">
									사업분야
								</dt>
								<dd className="list_table_info col-1-2">
									{resData.business_field}
								</dd>
								<dt className="list_table_title">
									산업분야
								</dt>
								<dd className="list_table_info col-1-2">
									<span className="style-ellipsis">
										{resData.industry_field}
									</span>
								</dd>
								<dt className="list_table_title">
									소재지
								</dt>
								<dd className="list_table_info col-1-1">
									{resData.location}
								</dd>
							</dl>
						</div>
					</div>
					<div className="consortiumDetail_textarea">
						<div className="consortiumDetail_textarea_title-wrap">
							<h3 className="consortiumDetail_textarea_title">
								회사 소개
							</h3>
							<dl className="list_table">
								<dt className="list_table_title">회사 소개자료</dt>
								{
									haveIntroduceFile
									&&
									<dd className="list_table_info">
										<button className="icon_download ir_txt" onClick={() => setShowPopup(true)}>파일 올리기</button>
									</dd>
								}
							</dl>
						</div>
						<textarea name="comIntro" id="comIntro" className="consortiumDetail_textarea_content" value={resData.desc} readOnly/>
					</div>
					<div className="consortiumDetail_btn-wrap">
						<Link to={listLink} className="consortiumDetail_btn">목&nbsp;&nbsp;록</Link>
					</div>
				</div>
			</div>
			<div className="mo">
				<div className="consortiumDetail">
					<div className="consortiumDetail_box">
						<div className="consortiumDetail_title">
							<dl className="list_table">
								<dt className="list_table_title">
									업체 명
								</dt>
								<dd className="list_table_info">
									{resData.company_name}
								</dd>
							</dl>
						</div>
						<div className="consortiumDetail_info">
							<dl className="list_table">
								<dt className="list_table_title">
									대표자
								</dt>
								<dd className="list_table_info">
									{resData.representative}
								</dd>
								<dt className="list_table_title">
									기업유형
								</dt>
								<dd className="list_table_info">
									{resData.company_type}
								</dd>
								<dt className="list_table_title">
									사업자 등록번호
								</dt>
								<dd className="list_table_info">
									{resData.license_number}
								</dd>
								<dt className="list_table_title">
									사업분야
								</dt>
								<dd className="list_table_info">
									{resData.business_field}
								</dd>
								<dt className="list_table_title">
									산업분야
								</dt>
								<dd className="list_table_info">
									{resData.industry_field}
								</dd>
							</dl>
							<div className="consortiumDetail_info_viewLogo">
								<span>로고 미리보기</span>
								<button type="button" className="consortiumDetail_info_viewLogo_btn" onClick={() => window.$Global.imagePopup(window.$Global.getCDN(resData.profile_image_key))}>
									로고 미리보기
								</button>
							</div>
						</div>
						<div className="consortiumDetail_subInfo">
							<dl className="list_table">
								<dt className="list_table_title">
									연락처
								</dt>
								<dd className="list_table_info">
									{resData.phone}
								</dd>
								<dt className="list_table_title">
									소재지
								</dt>
								<dd className="list_table_info">
									{resData.location}
								</dd>
							</dl>
						</div>
						<div className="consortiumDetail_text">
							<div className="consortiumDetail_text_title">
								회사 소개 · 자료
							</div>
							<p className="consortiumDetail_text_info">
								{resData.desc}
							</p>
							{
								haveIntroduceFile
								&&
								<div className="consortiumDetail_text_btn" onClick={onClickMobileIntroduceFile}>
									<span>회사 소개자료</span>
									<button type="button" className="consortiumDetail_text_btn_intro">
										회시 소개자료
									</button>
								</div>
							}
						</div>
					</div>
				</div>
			</div>

			<CommonAttachPopup list={resData.introduce_file} show={showPopup}
							   onClickDownload={download}
							   onClickClose={() => setShowPopup(false)}/>

			<MobilePopupAttach show={moPopupFileShow} item={resData.introduce_file} onDownload={download}/>
		</div>
	);
}

export default ConsortiumDetail;