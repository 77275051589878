import React, { useContext, useState } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";

function Footer() {
  const { isMobile } = useContext(CommonContext);
  const [show, setShow] = useState(false);
  const isViewTypeIpnow = window.$Global.isIpnow();

  if (isMobile) return null;

  return (
    <footer>
      <div className="wrap_footer pc">
        <div className="info">
          <p className="company_name">주식회사 아이피나우</p>
          <p>법인등록번호: 180111-1360047 / 대표이사: 황차동</p>
          <p>사업자등록번호: 376-86-02011</p>
          <br />
          <p>본사: (48480) 부산광역시 남구 신선로 2, 410호(우암동)</p>
          <p>
            지사: (06744)서울특별시 서초구 바우뫼로 175, 4F/5F (양재동,
            창덕빌딩)
          </p>
          <p>
            베트남: 7th floor, Kailash building B21 - D21, Dich Vong Hau ward,
            Cau Giay distric, Ha Noi, Viet Nam
          </p>
          <p>대표전화: 070-4249-1023 | 팩스: 02-3463-2200</p>
          <p>이메일: ipnow@ipnow.co.kr</p>
          <p>ⓒ Copyright © 2024 IPNOW. All rights reserved.</p>
        </div>
        <div>
          <ul className="links">
            <li>
              <Link to="/intro/service/detail">기능소개</Link>
            </li>
            {/*{isViewTypeIpnow && (*/}
            {/*  <li>*/}
            {/*    <Link to="/people">PEOPLE</Link>*/}
            {/*  </li>*/}
            {/*)}*/}
            <li>
              <Link to="/intro/price">이용가격</Link>
            </li>
            <li>
              <Link to="/intro/service">고객문의</Link>
            </li>
          </ul>
          <div className="list_link">
            <a href="https://www.facebook.com/biznavi.co.kr" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-facebook-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a
              href="https://www.instagram.com/biznavi__official"
              target="_blank"
            >
              <img
                src={
                  require("../../assets/images/footer/icon-instagram-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a href="https://blog.naver.com/findbiz_official" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-blog-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
            <a href="https://media.findbiz.co.kr/" target="_blank">
              <img
                src={
                  require("../../assets/images/footer/icon-media-link.svg")
                    .default
                }
                alt="이미지"
              />
            </a>
          </div>
        </div>
        <p className="terms_link">
          <Link to="/terms/use">서비스 이용약관</Link>
          <span>|</span>
          <Link to="/terms/private">개인정보 처리방침</Link>
        </p>
      </div>
      <div className="wrap_footer mo">
        <button className="btn_toggle" onClick={() => setShow(!show)}>
          <span>{show ? "접기" : "펼치기"}</span>
          <i
            className={`${
              show ? "icon_more_arrow_up_white" : "icon_more_white"
            }`}
          />
        </button>
        <div className="contact">
          <p className="title">IPNOW에게 문의하기</p>
          <div>
            <i className="icon_m_call" />
            <p>070-4249-1023</p>
          </div>
          <div>
            <i className="icon_m_fax" />
            <p>02-3463-2200</p>
          </div>
          <div>
            <i className="icon_m_mail" />
            <p>ipnow@ipnow.co.kr</p>
          </div>
        </div>
        {show && (
          <>
            <p className="line" />

            <div className="info">
              <p>법인명(단체명): 주식회사 아이피나우</p>
              <p>대표이사: 황차동</p>
              <p>법인등록번호: 180111-1360047</p>
              <p>사업자등록번호: 376-86-02011</p>
              <p>본사: (48480) 부산광역시 남구 신선로 2, 410호(우암동)</p>
              {/* <p className="text_indent">피엔유 에이백 2층 비7호 (장전동)</p> */}
              <p>지사: (06744)서울특별시 서초구 바우뫼로 175, 4F/5F</p>
              <p className="text_indent">(양재동, 창덕빌딩)</p>
              <p>베트남: 7th floor, Kailash building B21 - D21,</p>
              <p className="text_indent">
                Dich Vong Hau ward, Cau Giay distric, Ha Noi,
              </p>
              <p className="text_indent">Viet Nam</p>
            </div>

            <p className="line" />

            <ul className="links">
              <li>
                <Link to="/intro/service/detail">기능소개</Link>
              </li>
              {/*<li>*/}
              {/*  <Link to="/intro/price">이용가격</Link>*/}
              {/*</li>*/}
              <li>
                <Link to="/intro/service">고객문의</Link>
              </li>
              <li>
                <Link to="/terms/use">서비스 이용약관</Link>
              </li>
              <li>
                <Link to="/terms/private">개인정보 처리방침</Link>
              </li>
            </ul>
          </>
        )}

        <p className="copyright">
          ⓒ Copyright © 2024 IPNOW. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
