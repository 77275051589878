import React, {useState} from "react";
import "../css/AdminTaskService.scss";

function AdminTaskService() {
	const [title, setTitle] = useState('');
	const [file, setFile] = useState(null);

	return (
		<div id="AdminTaskService">
			<h2 className="page_title">서비스 게시글 등록</h2>
			<div className="articles">
				<div className="field">
					<span>제목</span>
					<input type="text"/>
				</div>
				<div className="field">
					<span>내용</span>
					<input type="text"/>
				</div>
				<div className="field">
					<span>출처</span>
					<input type="text"/>
				</div>
				<div className="field">
					<span>이미지</span>
					<input type="file"/>
				</div>
			</div>
			
			<button className="btn_upload">업로드하기</button>
		</div>
	)
}

export default AdminTaskService;