import React from "react";
import "../css/ResearchTechRequestEdit.scss";

function ResearchTechRequestEdit() {
  return (
    <div id="ResearchTechRequestEdit">
      <div className="tutorial-box pc">
        <div className="wrap_image">
          <img
            src={require("../../../assets/images/common/icon-bulb.svg").default}
            alt="이미지"
          />
        </div>
        <div className="inner">
          <h2>선행기술 조사 요청하기</h2>
          <p>
            비즈내비에게 요청하는 선행기술 조사 키워드나 문장을 최대한 많이 적어
            주세요.{" "}
          </p>
        </div>
      </div>
      <div className="contents">
        <h2 className="page_title">선행기술 조사 요청</h2>
        <div className="field">
          <p>찾고자 하는 선행기술 명 :</p>
          <input type="text" placeholder="입력해 주세요" />
        </div>

        <div className="field">
          <p>선행기술 키워드 : </p>
          <div className="inputs">
            <input type="text" placeholder="키워드를 입력해 주세요" />
            <input type="text" placeholder="키워드를 입력해 주세요" />
            <input type="text" placeholder="키워드를 입력해 주세요" />
            <input type="text" placeholder="키워드를 입력해 주세요" />
          </div>
        </div>

        <div className="field">
          <p>선행기술 키워드 : </p>
          <textarea placeholder="찾고자 하는 선행기술 분야에 대해 간략하게 작성해 주세요" />
        </div>
      </div>

      <button className="btn_request">요청하기</button>
    </div>
  );
}

export default ResearchTechRequestEdit;
