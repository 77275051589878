import API from "../../util/api";

export default {
    getList(payload) {
        return API.get('/manager/market/trademark', {params: payload});
    },

    getInfo(idx) {
        return API.get(`/manager/market/trademark/${idx}`);
    },

    addTrade(payload) {
        return API.post(`/manager/market/trademark`, payload);
    },

    updateTrade(idx, payload) {
        return API.patch(`/manager/market/trademark/${idx}`, payload);
    },

    deleteTrade(idx) {
        return API.delete(`/manager/market/trademark/${idx}`);
    },

    getMyTradeHistory(type) {
        return API.get(`/manager/market/trademark/my_history`, {params: {type: type}});
    },

    proposal(idx, payload) {
        return API.post(`/manager/market/trademark/${idx}/proposal`, payload);
    },

    reProposal(idx, proposal_idx, payload) {
        return API.patch(`/manager/market/trademark/${idx}/proposal/${proposal_idx}/re`, payload);
    },

    acceptProposal(idx, proposal_idx, payload) {
        return API.patch(`/manager/market/trademark/${idx}/proposal/${proposal_idx}`, payload);
    },

    deleteProposal(idx, proposal_idx) {
        return API.delete(`/manager/market/trademark/${idx}/proposal/${proposal_idx}`);
    },

    getKeywordList() {
        return API.get(`/manager/market/trademark/interest`);
    },

    addKeyword(payload) {
        return API.post(`/manager/market/trademark/interest`, payload);
    },

    deleteKeyword(interest_idx) {
        return API.delete(`/manager/market/trademark/interest/${interest_idx}`);
    }
}