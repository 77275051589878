import config from "../../const/config";
import "./css/TradeMarkDetail.scss";
import React, { useContext, useEffect, useState } from "react";
import TradeMarkContext from "../../store/TradeMark";
import TradeMarkFileTypeContext from "../../store/TradeMarkFileType";
import Dispute from "../../store/TradeMarkDispute";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";
import { tradeMarkNationType } from "../../types/nationType";
import MemberContext from "../../store/Member";
import DatePickerCustom from "../datepicker/DatePickerCustom";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import _ from "lodash";
import InputRow from "./row/InputRow";
import SelectRow from "../patent/detail/common/SelectRow";
import FileRow from "./row/FileRow";
import ToggleButtonRow from "./row/ToggleButtonRow";
import InputAndFileRow from "./row/InputAndFileRow";
import DatePickerRow from "./row/DatePickerRow";
import NormalRow from "../patent/detail/common/NormalRow";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import { result } from "lodash/object";

const TradeMarkDispute = () => {
  const img_type = config.imgType;
  const history = useHistory();
  const location = useLocation();
  const { trademark_idx } = useParams();
  const { auth, manage_company_idx } = useContext(MemberContext);
  let trademarkDispute = useContext(Dispute);
  const tradeMark = location.state.tradeMarkData;
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const [showAddList, setShowAddList] = useState(false);
  const [tradeMarkDisputeData, setTradeMarkDisputeData] =
    useState(trademarkDispute);
  const [fileType, setFileType] = useState(location.state.tradeMarkFileType);
  const [downPopupShow, setDownPopupShow] = useState(false); // 팝업 보여주기 여부
  const [upPopupShow, setUpPopupShow] = useState(false); // 팝업 보여주기 여부
  const [fileImage, setFileImage] = useState("");
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [popupProp, setPopupProp] = useState({ type: null, oa_idx: null });
  const [secondIdx, setSecondIdx] = useState(null);
  const [downloadable, setDownloadable] = useState(true); // 다운로드 버튼 노출 여부
  const [disputeTypeSelectTag, setDisputeTypeSelectTag] = useState(null);
  const [middleTypeSelectTag, setMiddleTypeSelectTag] = useState(null);
  const [statutoryTypeSelectTag, setStatutoryTypeSelectTag] = useState(null);
  const disputeType = ["없음", "이의신청", "심판", "소송"];
  const middleType = [
    "없음",
    "취소심판",
    "무효심판",
    "거절불복심판",
    "권리범위",
    "확인심판",
  ];
  const statutoryType = ["없음", "특허심판원", "특허법원", "대법원"];

  useEffect(() => {
    initTradeMarkDispute();
  }, []);

  const initTradeMarkDispute = () => {
    TradeMarkAPI.getTradeMarkDispute(trademark_idx).then((res) => {
      setTradeMarkDisputeData({
        ...res.data,
        editing: false,
      });
    });
    setDisputeTypeSelectTag(
      disputeType.map((item, idx) => <option value={idx - 1}>{item}</option>)
    );
    setMiddleTypeSelectTag(
      middleType.map((item, idx) => <option value={idx - 1}>{item}</option>)
    );
    setStatutoryTypeSelectTag(
      statutoryType.map((item, idx) => <option value={idx - 1}>{item}</option>)
    );
  };

  const onClickEditingStart = () => {
    setTradeMarkDisputeData({ ...tradeMarkDisputeData, editing: true });
  };

  const onClickEditingCancel = () => {
    setTradeMarkDisputeData({ ...tradeMarkDisputeData, editing: false });
  };

  const onClickSave = () => {
    TradeMarkAPI.updateTradeMarkDispute(
      trademark_idx,
      tradeMarkDisputeData.idx,
      tradeMarkDisputeData
    ).then(() => {
      initTradeMarkDispute();
    });
  };

  const addHistoryList = () => {
    const history = {
      title: "",
      work_at: 0,
      content: "",
      instruction_deadline: 0,
      legal_deadline: 0,
      etc: "",
      isEdit: true,
    };
    tradeMarkDisputeData.history_list.push(history);
    setTradeMarkDisputeData({ ...tradeMarkDisputeData });
  };

  const handleHistoryInput = (e, idx) => {
    tradeMarkDisputeData.history_list[idx][e.target.name] = e.target.value;
    setTradeMarkDisputeData({ ...tradeMarkDisputeData });
  };

  const handleHistoryDelete = (idx) => {
    tradeMarkDisputeData.history_list.splice(idx, 1);
    setTradeMarkDisputeData({ ...tradeMarkDisputeData });
  };

  const onClickDownPopShow = async (type, oa_idx, second_idx) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });
    let result = [];
    switch (type) {
      case "survey":
        result = await TradeMarkAPI.getCompanySurveyFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "apply":
        result = await TradeMarkAPI.getCompanyApplyFiles(trademark_idx).then(
          (res) => res.data
        );
        break;
      case "image":
        if (tradeMark.image_name) {
          result = [
            {
              idx: "",
              file_name: tradeMark.image_name,
              file_type: tradeMark.image_type,
              created_at: "",
            },
          ];
        }
        break;
      case "correction":
        result = await TradeMarkAPI.getTradeMarkCorrectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx
        ).then((res) => res.data.items);
        break;
      case "judge":
        result = await TradeMarkAPI.getTradeMarkJudgeFiles(
          trademark_idx,
          tradeMarkDisputeData.idx
        ).then((res) => res.data.items);
        break;
      case "objection":
        result = await TradeMarkAPI.getTradeMarkObjectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx
        ).then((res) => res.data.items);
        break;
      case "dispute_giveup":
        result = await TradeMarkAPI.getTradeMarkDisputeGiveUpFiles(
          trademark_idx,
          tradeMarkDisputeData.idx
        ).then((res) => res.data.items);
        break;
      case "dispute_history":
        result = await TradeMarkAPI.getTradeMarkDisputeHistoryFiles(
          trademark_idx,
          second_idx,
          tradeMarkDisputeData.idx
        ).then((res) => res.data.fileList);
        break;
    }
    setFileViewList(result);
    setDownPopupShow(true);
  };

  const onClickDownload = (idx) => {
    switch (popupProp.type) {
      case "survey":
        TradeMarkAPI.downloadCompanySurveyFile(trademark_idx, idx);
        break;
      case "apply":
        TradeMarkAPI.downloadCompanyApplyFile(trademark_idx, idx);
        break;
      case "image":
        TradeMarkAPI.downloadTradeMarkImage(trademark_idx);
        break;
      case "correction":
        TradeMarkAPI.downloadTradeMarkCorrectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "judge":
        TradeMarkAPI.downloadTradeMarkJudgeFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "objection":
        TradeMarkAPI.downloadTradeMarkObjectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "dispute_giveup":
        TradeMarkAPI.downloadTradeMarkDisputeGiveUpFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "dispute_history":
        TradeMarkAPI.downloadTradeMarkDisputeHistoryFiles(
          trademark_idx,
          secondIdx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
    }
  };

  const onClickUpload = async () => {
    let formData = new FormData();

    uploadFileViewList.forEach((item) => {
      formData.append("file", item.file);
    });

    switch (popupProp.type) {
      case "correction":
        await TradeMarkAPI.uploadTradeMarkCorrectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          formData
        ).then((res) => res.data);
        break;
      case "judge":
        await TradeMarkAPI.uploadTradeMarkJudgeFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          formData
        ).then((res) => res.data);
        break;
      case "objection":
        await TradeMarkAPI.uploadTradeMarkObjectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          formData
        ).then((res) => res.data);
        break;
      case "dispute_giveup":
        await TradeMarkAPI.uploadTradeMarkDisputeGiveUpFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          formData
        ).then((res) => res.data);
        break;
      case "dispute_history":
        await TradeMarkAPI.uploadTradeMarkDisputeHistoryFiles(
          trademark_idx,
          secondIdx,
          tradeMarkDisputeData.idx,
          formData
        ).then((res) => res.data);
        break;
    }
    alert("업로드되었습니다");
    setUpPopupShow(false);
    setUploadFileViewList([]);
  };

  const onClickDeleteFileInDownload = async (idx) => {
    if (
      popupProp.type === "image" ||
      popupProp.type === "survey" ||
      popupProp.type === "apply"
    ) {
      alert("삭제할 수 없는 파일 입니다.");
      return;
    }
    switch (popupProp.type) {
      case "correction":
        await TradeMarkAPI.deleteTradeMarkCorrectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "judge":
        await TradeMarkAPI.deleteTradeMarkJudgeFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "objection":
        await TradeMarkAPI.deleteTradeMarkObjectionFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "dispute_giveup":
        await TradeMarkAPI.deleteTradeMarkDisputeGiveUpFiles(
          trademark_idx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
      case "dispute_history":
        await TradeMarkAPI.deleteTradeMarkDisputeHistoryFiles(
          trademark_idx,
          secondIdx,
          tradeMarkDisputeData.idx,
          idx
        );
        break;
    }
    alert("삭제되었습니다");
    setFileViewList(
      fileViewList.filter((file) => {
        return file.idx !== idx;
      })
    );
  };

  const onClickUpPopShow = (type, oa_idx) => {
    setPopupProp({ ...popupProp, type: type, oa_idx: oa_idx });
    if (type === "dispute_history" && oa_idx === undefined) {
      alert("한 차례 저장 완료 후 업로드 가능합니다.");
      return;
    } else {
      setUpPopupShow(true);
    }
    let result = [];
    switch (type) {
      case "correction":
        result = tradeMarkDisputeData.fileList.correction;
        break;
      case "judge":
        result = tradeMarkDisputeData.fileList.judge;
        break;
      case "objection":
        result = tradeMarkDisputeData.fileList.objection;
        break;
      case "dispute_giveup":
        result = tradeMarkDisputeData.fileList.dispute_giveup;
        break;
      case "dispute_history":
        result = tradeMarkDisputeData.fileList.dispute_history;
        break;
    }
    setUploadFileViewList(result);
  };

  let tag = tradeMark?.product_class.map((item, index) => {
    if (index == 0) return null;
    return (
      <div className="rows" key={index}>
        <div className="row">
          <h2 className="title">상품 분류 / 지정상품</h2>
          <div className="btns">
            <p>{`제 ${item.class}류`}</p>
            {/*{tradeMarkDisputeData.editing && (*/}
            {/*  <>*/}
            {/*    <button*/}
            {/*      className="icon_plus"*/}
            {/*      onClick={() => setClassificationPopupShow(true)}*/}
            {/*    />*/}
            {/*    <button*/}
            {/*      className="icon_badge_del"*/}
            {/*      onClick={() => onClickDeleteClass(index)}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*)}*/}
          </div>
        </div>
        <div className="row">
          <p>{item.product}</p>
        </div>
      </div>
    );
  });

  return (
    <div id="TradeMarkDispute">
      <div className="header">
        <h2 className="title">상표 분쟁 상세 페이지</h2>
        <div className="info">
          <div className="manage_no">출원번호 {tradeMark?.apply_number}</div>
          <div className="apply_no">등록번호 {tradeMark?.regist_number}</div>
          <div>국가: {tradeMark?.nation_string}</div>

          <button className="state">
            {window.$Global.convertTradeMarkStateCodeToStr(tradeMark?.state)}
          </button>
        </div>

        <div className="option">
          <ul className="link_tab">
            {isCompany && (
              <li>
                <Link
                  to={`/tradeMark/dispute/memo/${trademark_idx}/${tradeMarkDisputeData.idx}`}
                >
                  <h2 className="title">분쟁 메모</h2>
                  <i className="icon_pencil" />
                </Link>
              </li>
            )}
            {/*{isCompany && (*/}
            {/*  <li>*/}
            {/*    <Link className={""} to={""}>*/}
            {/*      <h2 className="title">연차료 관리</h2>*/}
            {/*      <i className="icon_patent_payment" />*/}
            {/*    </Link>*/}
            {/*  </li>*/}
            {/*)}*/}
            {/*<li>*/}
            {/*  <Link to={""}>*/}
            {/*    <h2 className="title">Family</h2>*/}
            {/*    <i className="icon_home" />*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to={""}>*/}
            {/*    <h2 className="title">기타 관련 서류</h2>*/}
            {/*    <i className="icon_attached_file" />*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to={`/tradeMarkDisputeData/dispute/${trademark_idx}`}>*/}
            {/*    <h2 className="title">분쟁(심판/소송)</h2>*/}
            {/*    <i className="icon_pencil" />*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to={""}>*/}
            {/*    <h2 className="title">기타사건(계약)</h2>*/}
            {/*    <i className="icon_attached_file" />*/}
            {/*  </Link>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to={""}>*/}
            {/*    <h2 className="title">조사(선행상표)</h2>*/}
            {/*    <i className="icon_attached_file" />*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </div>

        <div className="btns">
          {tradeMarkDisputeData.editing ? (
            <>
              <button className="btn_save" onClick={onClickSave}>
                저장
              </button>
              <button className="btn_cancle" onClick={onClickEditingCancel}>
                취소
              </button>
            </>
          ) : (
            <>
              <button className="btn_edit" onClick={onClickEditingStart}>
                수정
              </button>
              <button className="btn_save" onClick={() => history.goBack()}>
                이전
              </button>
            </>
          )}
        </div>
      </div>
      <div className={`form ${downloadable ? "" : "not_download"}`}>
        <div className="field">
          <div className="field_header">
            <h5 className="title">서지사항</h5>
          </div>
          <div className="rows">
            {isCompany ? (
              <InputRow
                title="관리번호"
                titleClass="color_blue"
                condition={false}
                content={tradeMark.manage_number}
              />
            ) : (
              <InputRow
                title="관리번호"
                titleClass="color_blue"
                condition={false}
                content={tradeMark.agent_manage_number}
              />
            )}
            <div className="row no_data" />
            <div className="row no_data">
              <h2 className="title color_blue" />
              <div className="btns">
                <button
                  className={
                    tradeMark.image_key
                      ? "icon_download_attached"
                      : "icon_download"
                  }
                  onClick={() => onClickDownPopShow(fileType.image)}
                />
                <button
                  className="icon_upload"
                  onClick={() => onClickUpPopShow(fileType.image)}
                />
              </div>
            </div>
          </div>
          <div className="rows">
            <SelectRow
              title="담당자"
              content={tradeMark.manager_name}
              condition={false}
            />
            {isCompany ? (
              <SelectRow
                title="대리인"
                content={tradeMark.agent_company_name}
                condition={false}
              />
            ) : (
              <SelectRow
                title="대리인 담당자"
                content={tradeMark.agent_name}
                condition={false}
              />
            )}
            <div className="thumbnail">
              {tradeMark.image_key ? (
                tradeMark.image_type == "svg" ? (
                  <span>미리보기를 지원하지 않는 파일형식</span>
                ) : (
                  <img
                    src={window.$Global.getCDN(tradeMark.image_key)}
                    alt="이미지"
                    onError={(error) => console.log(error)}
                  />
                )
              ) : fileImage ? (
                <img src={fileImage} alt="sample" />
              ) : (
                <span>미리보기</span>
              )}
            </div>
          </div>
          <div className="rows">
            <FileRow
              editing={tradeMark.editing}
              title="선행 상표조사 의뢰"
              haveFile={Boolean(tradeMark.survey_file_cnt)}
              onClickDownPopShow={() => onClickDownPopShow(fileType.survey)}
            />
            <ToggleButtonRow
              title="선행 상표조사"
              condition={false}
              selected={tradeMark.survey_req}
            />
          </div>
          <div className="rows">
            <div className="row" style={{ width: "580px" }}>
              <InputRow
                title="분쟁 명칭"
                condition={tradeMarkDisputeData.editing}
                content={tradeMarkDisputeData.name}
                onChange={(e) =>
                  setTradeMarkDisputeData({
                    ...tradeMarkDisputeData,
                    name: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="rows">
            <SelectRow
              title="분쟁분류"
              content={disputeType[tradeMarkDisputeData.dispute_type + 1]}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  dispute_type: e.target.value,
                })
              }
              condition={isCompany && tradeMarkDisputeData.editing}
              contentIdx={tradeMarkDisputeData.dispute_type}
              selectTag={disputeTypeSelectTag}
            />
            <SelectRow
              title="중분류"
              content={middleType[tradeMarkDisputeData.middle_type + 1]}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  middle_type: e.target.value,
                })
              }
              condition={isCompany && tradeMarkDisputeData.editing}
              contentIdx={tradeMarkDisputeData.middle_type}
              selectTag={middleTypeSelectTag}
            />
            <SelectRow
              title="계류법정"
              content={statutoryType[tradeMarkDisputeData.statutory_type + 1]}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  statutory_type: e.target.value,
                })
              }
              condition={isCompany && tradeMarkDisputeData.editing}
              contentIdx={tradeMarkDisputeData.statutory_type}
              selectTag={statutoryTypeSelectTag}
            />
          </div>
        </div>
        <div className="field">
          <div className="field_header">
            <h5 className="title">출원</h5>
          </div>
          <div className="rows">
            <InputAndFileRow
              editing={false}
              title="출원번호"
              titleClassName="color_blue"
              content={tradeMark.apply_number}
              haveFile={Boolean(tradeMark.apply_file_cnt)}
              onClickDownPopShow={() => onClickDownPopShow(fileType.apply)}
            />
            <DatePickerRow
              title="출원일"
              condition={false}
              data={tradeMark.apply_at}
            />
            <SelectRow
              title="관련 제품명"
              content={
                tradeMark.product_list[0]
                  ? tradeMark.product_list[0].name
                  : "N/A"
              }
              condition={false}
              contentIdx={
                tradeMark.product_list[0] ? tradeMark.product_list[0].idx : 0
              }
            />
          </div>
          <div className="rows">
            <div className="row">
              <h2 className="title">상품분류 / 지정상품</h2>
              <div className="btns">
                <p>
                  {tradeMark.product_class[0]
                    ? `제 ${tradeMark.product_class[0].class}류`
                    : "미선택"}
                </p>
              </div>
            </div>
            <div className="row">
              <p>
                {tradeMark.product_class[0]
                  ? tradeMark.product_class[0].product
                  : "지정상품이 선택되지 않았습니다."}
              </p>
            </div>
            <NormalRow
              title="우선심사청구"
              component={
                <ToggleButtonWithSpan
                  text=""
                  className={tradeMark.editing ? "not_editing" : "not_editing"}
                  condition={{ on: "유", off: "무" }}
                  selected={tradeMark.priority_screen_req}
                />
              }
            />
          </div>
          {tag}
        </div>
        <div className="field">
          <div className="rows">
            <InputRow
              title="청구인 원고"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.claimant_plaintiff}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  claimant_plaintiff: e.target.value,
                })
              }
            />
            <InputRow
              title="피청구인 원고"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.respondent_plaintiff}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  respondent_plaintiff: e.target.value,
                })
              }
            />
          </div>
          <div className="rows">
            <InputRow
              title="청구인 피고"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.claimant_defendant}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  claimant_defendant: e.target.value,
                })
              }
            />
            <InputRow
              title="피청구인 피고"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.respondent_defendant}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  respondent_defendant: e.target.value,
                })
              }
            />
          </div>
          <div className="rows">
            <FileRow
              editing={tradeMarkDisputeData.editing}
              title="보정서"
              onClickDownPopShow={() => onClickDownPopShow(fileType.correction)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.correction)}
            />
            <FileRow
              editing={tradeMarkDisputeData.editing}
              title="판결"
              onClickDownPopShow={() => onClickDownPopShow(fileType.judge)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.judge)}
            />
          </div>
          <div className="rows">
            <DatePickerRow
              title="마감일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.correction_deadline}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  correction_deadline: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="송달일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.correction_serve_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  correction_serve_at: new Date(e).getTime(),
                })
              }
            />
          </div>
          <div className="rows">
            <DatePickerRow
              title="청구일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.correction_charge_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  correction_charge_at: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="결정일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.correction_decision_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  correction_decision_at: new Date(e).getTime(),
                })
              }
            />
            <InputRow
              title="결정내용"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.correction_decision_desc}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  correction_decision_desc: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="field">
          <div className="rows">
            <DatePickerRow
              title="심판 전치일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.referee_transposition_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  referee_transposition_at: new Date(e).getTime(),
                })
              }
            />
            <InputRow
              title="심판 전치 결과"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.referee_transposition_result}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  referee_transposition_result: e.target.value,
                })
              }
            />
          </div>
          <div className="rows">
            <FileRow
              editing={tradeMarkDisputeData.editing}
              title="불복 제기"
              onClickDownPopShow={() => onClickDownPopShow(fileType.objection)}
              onClickUpPopShow={() => onClickUpPopShow(fileType.objection)}
            />
            <FileRow
              editing={tradeMarkDisputeData.editing}
              title="포기"
              onClickDownPopShow={() =>
                onClickDownPopShow(fileType.dispute_giveup)
              }
              onClickUpPopShow={() => onClickUpPopShow(fileType.dispute_giveup)}
            />
          </div>
          <div className="rows">
            <DatePickerRow
              title="마감일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.objection_deadline}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  objection_deadline: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="포기일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.give_up_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  give_up_at: new Date(e).getTime(),
                })
              }
            />
          </div>
          <div className="rows">
            <DatePickerRow
              title="청구일"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.objection_charge_at}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  objection_charge_at: new Date(e).getTime(),
                })
              }
            />
            <DatePickerRow
              title="내용"
              condition={tradeMarkDisputeData.editing}
              data={tradeMarkDisputeData.give_up_desc}
              onChangeDatePicker={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  give_up_desc: new Date(e).getTime(),
                })
              }
            />
            <InputRow
              title="최종결과"
              titleClass="color_blue"
              condition={tradeMarkDisputeData.editing}
              content={tradeMarkDisputeData.final_result}
              onChange={(e) =>
                setTradeMarkDisputeData({
                  ...tradeMarkDisputeData,
                  final_result: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="field">
          <div className="field_header">
            <h5 className="title">History</h5>
            {tradeMarkDisputeData.editing && (
              <button className="add_btn" onClick={addHistoryList}>
                추가하기
              </button>
            )}
          </div>
          <table className="history_table">
            <colgroup>
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={50} />
              {tradeMarkDisputeData.editing && (
                <>
                  <col width={50} />
                  <col width={50} />
                </>
              )}
            </colgroup>
            <thead>
              <tr>
                <th>업무제목</th>
                <th>업무일자</th>
                <th>업무진행내용</th>
                <th>지시요청기한</th>
                <th>법정기한</th>
                <th>비고</th>
                <th>파일</th>
                {tradeMarkDisputeData.editing && (
                  <>
                    <th>수정</th>
                    <th>삭제</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {tradeMarkDisputeData.history_list.map((item, idx) => {
                return item.isEdit ? (
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="title"
                        value={item.title}
                        onChange={(e) => handleHistoryInput(e, idx)}
                      />
                    </td>
                    <td>
                      <div className="history_date">
                        <p>{window.$Global.convertDate(item.work_at)}</p>
                        <DatePickerCustom
                          data={item.work_at}
                          onChangeDatePicker={(e) => {
                            item.work_at = new Date(e).getTime();
                            setTradeMarkDisputeData({
                              ...tradeMarkDisputeData,
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="content"
                        value={item.content}
                        onChange={(e) => handleHistoryInput(e, idx)}
                      />
                    </td>
                    <td>
                      <div className="history_date">
                        <p>
                          {window.$Global.convertDate(
                            item.instruction_deadline
                          )}
                        </p>
                        <DatePickerCustom
                          data={item.instruction_deadline}
                          onChangeDatePicker={(e) => {
                            item.instruction_deadline = new Date(e).getTime();
                            setTradeMarkDisputeData({
                              ...tradeMarkDisputeData,
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="history_date">
                        <p>{window.$Global.convertDate(item.legal_deadline)}</p>
                        <DatePickerCustom
                          data={item.legal_deadline}
                          onChangeDatePicker={(e) => {
                            item.legal_deadline = new Date(e).getTime();
                            setTradeMarkDisputeData({
                              ...tradeMarkDisputeData,
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        name="etc"
                        value={item.etc}
                        onChange={(e) => handleHistoryInput(e, idx)}
                      />
                    </td>
                    <td>
                      <i
                        className="icon_download"
                        onClick={() => {
                          onClickDownPopShow(
                            fileType.dispute_history,
                            null,
                            item.idx
                          );
                          setSecondIdx(item.idx);
                        }}
                      />
                      <i
                        className="icon_upload"
                        onClick={() => {
                          onClickUpPopShow(fileType.dispute_history);
                          setSecondIdx(item.idx);
                        }}
                      />
                    </td>
                    {tradeMarkDisputeData.editing && (
                      <>
                        <td>
                          <i
                            className="icon_checkbox_blue"
                            onClick={() => {
                              item.isEdit = !item.isEdit;
                              setTradeMarkDisputeData({
                                ...tradeMarkDisputeData,
                              });
                            }}
                          />
                        </td>
                        <td>
                          <i
                            className="icon_badge_del"
                            onClick={() => handleHistoryDelete(idx)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ) : (
                  <tr>
                    <td>{item.title}</td>
                    <td>{window.$Global.convertDate(item.work_at)}</td>
                    <td>{item.content}</td>
                    <td>
                      {window.$Global.convertDate(item.instruction_deadline)}
                    </td>
                    <td>{window.$Global.convertDate(item.legal_deadline)}</td>
                    <td>{item.etc}</td>
                    <td>
                      <i
                        className="icon_download"
                        onClick={() => {
                          onClickDownPopShow(
                            fileType.dispute_history,
                            null,
                            item.idx
                          );
                          setSecondIdx(item.idx);
                        }}
                      />
                      <i
                        className="icon_upload"
                        onClick={() => {
                          onClickUpPopShow(fileType.dispute_history, item.idx);
                          setSecondIdx(item.idx);
                        }}
                      />
                    </td>
                    {tradeMarkDisputeData.editing && (
                      <>
                        <td>
                          <i
                            className="icon_arrow_right_s"
                            onClick={() => {
                              item.isEdit = !item.isEdit;
                              setTradeMarkDisputeData({
                                ...tradeMarkDisputeData,
                              });
                            }}
                          />
                        </td>
                        <td>
                          <i
                            className="icon_badge_del"
                            onClick={() => handleHistoryDelete(idx)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
        setFileImage={setFileImage}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
        setFileImage={setFileImage}
      />
    </div>
  );
};

export default TradeMarkDispute;
