import React from "react";
import CheckBox from "../common/CheckBox";

function MarketInfoMainItem({item, cartArr, showPopup, onClickCheck}) {
	let hasContent = Boolean(item.content || item.content_image_list.length);
	let checked = Boolean(cartArr.findIndex(cart => (cart.mi_idx || cart.idx) === item.idx) > -1);

	return (
		<div className="marketInfoMain_item">
			<img src={window.$Global.getCDN(item.image_key)} alt="이미지" onContextMenu={window.$Global.blockRightClick}/>
			<div className="info">
				<h2>{item.title}</h2>
				<p>{window.$Global.convertDate(item.made_at, ' ')}</p>
			</div>
			<div className="etc">
				<div>
					<button onClick={() => showPopup(item, "graph")}>
						<span>자세히보기</span>
						<i className="icon_alert_circle"/>
					</button>
					{
						hasContent
						&&
						<button onClick={() => showPopup(item, "content")}>
							<span>관련글보기</span>
							<i className="icon_alert_circle"/>
						</button>
					}
				</div>
				<CheckBox text="선택하기" checked={checked} onChangeChecked={e => onClickCheck(e, item)}/>
			</div>
		</div>
	);
}

export default MarketInfoMainItem;
