import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import {Link} from 'react-router-dom';
import './PatentListView.scss';
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import CheckBox from "../common/CheckBox";
import API from "../../util/api";
import MemberContext from "../../store/Member";
import InfiniteScroll from "react-infinite-scroll-component";
import DesignAPI from "../../API/design";

function DesignListView() {
    let table_head_tag = [], tab_tags = [], options_tag = [], sort_tags = [];
    const {auth, manage_company_idx} = useContext(MemberContext);
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isAgent = window.$Global.checkAuth("대리인", auth);
    const keyword_ref = React.useRef();
    const [tabState, setTabState] = useState(false); // Tab 설정 활성화 여부
    const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
    const [table_head, setTableHead] = useState([]); // 테이블 헤더값
    const [keywordType, setKeywordType] = useState(0);
    const [tbody, setTbody] = useState([window.$Global.notTd(20, "데이터를 불러오는 중입니다")]);
    const [tr_ref, setTrRef] = useState([]); // tr ref
    const [agentList, setAgentList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [nationList, setNationList] = useState([]);
    const [agent, setAgent] = useState(null);
    const [state, setState] = useState(null);
    const [nation, setNation] = useState(0);
    const [moreTab, setMoreTab] = useState({nation: true, state: true, agent: true});
    const [filterCheckList, setFilterCheckList] = useState({nation: [], state: [], agent: []});
    // tab 설정 state
    // text: checkbox 이름, key: api 통신할 때의 key 값, checked: true인 값이 table_head에 추가됨(초기 값이 기본 체크 값이 됨), defaultChecked: 초기화를 위해 기본 체크값이 어떤 거였는지 알기 위해 정의한 값,
    // 초기 checked 1인 값이 테이블 기본 head 값이 됨.
    const [btns_state_tab, setBtnsStateTab] = useState([]);
    // sort state
    // text: checkbox 이름, key: api 통신할 때의 key 값, checked: 체크 값(초기 값이 초기 체크 값이 됨)
    const [btns_state_sort, setBtnsStateSort] = useState([
        {text:'전체', key: 31, checked: 1},
        {text:'등록', key: 1, checked: 0},
        {text:'출원', key: 2, checked: 0},
        {text:'미출원', key: 4, checked: 0},
        {text:'최종거절', key: 8, checked: 0},
        {text:'포기(취하, 완료)', key: 16, checked: 0}
    ]);

    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [data, setData] = useState({});
    const [mouse, setMouse] = useState(false);

    useEffect(async () => {
        if (window.localStorage.getItem("patent_tab_init")) {
            window.localStorage.setItem("patent_total_number", "1");
            window.localStorage.setItem("patent_manage_number", "1");
            window.localStorage.setItem("patent_external_family", "1");
            window.localStorage.setItem("patent_nation_string", "1");
            window.localStorage.setItem("patent_type", "1");
            window.localStorage.setItem("patent_apply_number", "1");
            window.localStorage.setItem("patent_apply_at", "1");
            window.localStorage.setItem("patent_state", "1");
            window.localStorage.setItem("patent_register_number", "1");
            window.localStorage.setItem("patent_register_at", "1");
            window.localStorage.setItem("patent_agent_company_name", "1");
            window.localStorage.setItem("patent_researcher", "0");
            window.localStorage.setItem("patent_invention_name", "0");
            window.localStorage.setItem("patent_invention_name_en", "0");
            window.localStorage.setItem("patent_agent_name", "0");
        } else {
            setSelectedBasics(false);
        }

        let tab = [
            {text:'건수', key: 'total_number', type: null, checked: window.localStorage.getItem("patent_total_number") == null ? 1 : Number(window.localStorage.getItem("patent_total_number")), defaultChecked: 1},
            {text:'관리번호', key: 'manage_number', type: 0, checked: window.localStorage.getItem("patent_manage_number") == null ? 1 : Number(window.localStorage.getItem("patent_manage_number")), defaultChecked: 1},
            // {text:'Family', key: 'external_family', type: null, checked: window.localStorage.getItem("patent_external_family") == null ? 1 : Number(window.localStorage.getItem("patent_external_family")), defaultChecked: 1},
            {text:'국가', key: 'nation_string', type: 1, checked: window.localStorage.getItem("patent_nation_string") == null ? 1 : Number(window.localStorage.getItem("patent_nation_string")), defaultChecked: 1},
            {text:'종류', key: 'type', type: 2, checked: window.localStorage.getItem("patent_type") == null ? 1 : Number(window.localStorage.getItem("patent_type")), defaultChecked: 1},
            {text:'출원번호', key: 'apply_number', type: 17, checked: window.localStorage.getItem("patent_apply_number") == null ? 1 : Number(window.localStorage.getItem("patent_apply_number")), defaultChecked: 1},
            {text:'출원일', key: 'apply_at', type: 5, checked: window.localStorage.getItem("patent_apply_at") == null ? 1 : Number(window.localStorage.getItem("patent_apply_at")), defaultChecked: 1},
            {text:'현재상태', key: 'state', type: 10, checked: window.localStorage.getItem("patent_state") == null ? 1 : Number(window.localStorage.getItem("patent_state")), defaultChecked: 1},
            {text:'등록번호', key: 'register_number', type: 6, checked: window.localStorage.getItem("patent_register_number") == null ? 1 : Number(window.localStorage.getItem("patent_register_number")), defaultChecked: 1},
            {text:'등록일', key: 'register_at', type: 7, checked: window.localStorage.getItem("patent_register_at") == null ? 1 : Number(window.localStorage.getItem("patent_register_at")), defaultChecked: 1},
            {text:'특허사무소', key: 'agent_company_name', type: 11, checked: window.localStorage.getItem("patent_agent_company_name") == null ? 1 : Number(window.localStorage.getItem("patent_agent_company_name")), defaultChecked: 1},
            {text:'발명자', key: 'researcher', type: 3, checked: window.localStorage.getItem("patent_researcher") == null ? 0 : Number(window.localStorage.getItem("patent_researcher")), defaultChecked: 0},
            {text:'국문명칭', key: 'invention_name', type: 15, checked: window.localStorage.getItem("patent_invention_name") == null ? 0 : Number(window.localStorage.getItem("patent_invention_name")), defaultChecked: 0},
            {text:'영문명칭', key: 'invention_name_en', type: 16, checked: window.localStorage.getItem("patent_invention_name_en") == null ? 0 : Number(window.localStorage.getItem("patent_invention_name_en"))}
        ];

        let result = {};

        if (isCompany) {
            result = await API.get('/manager/design/field', { cache: true }).then(res => res.data);
        } else if (isAgent) {
            result = await API.get(`/agent/company/${manage_company_idx}/design/field`, { cache: true }).then(res => res.data);
            tab.splice(6);
            tab = tab.filter(item => item.text != "특허사무소")
        }

        if (!window.$Global.isEmptyObject(result)) {
            setAgentList(result.agent);
            setStateList(result.state);
            setNationList(result.nation);
            setFilterCheckList({
                nation: result.nation.map(() => false).concat([true]),
                state: result.state.map(() => false).concat([true]),
                agent: result.agent.map(() => false).concat([true])
            });
        }
        setBtnsStateTab(tab);
    }, []);

    useEffect(() => {
        drawPatentList({
            page: 1,
            count: count,
            options: btns_state_sort.filter(item => item.checked).reduce((x, y) => x + y.key, 0),
            keyword: keyword_ref.current ? keyword_ref.current.value : "",
            type: keywordType,
            sort_nation: nation,
            sort_state: state,
            sort_agent: agent
        });
        setCurPage(1);
    }, [btns_state_sort]);

    useEffect(() => {
        initData(data.items);
    }, [data, btns_state_tab]);

    const onClickMore = (e) => {
        let idx =  e.target.parentNode.dataset.idx || e.target.dataset.idx;
        let target = e.target.nodeName.toLowerCase() === 'tr' ? e.target : e.target.parentNode;
        let tr = tr_ref[idx];

        tr_ref.forEach(item => {
            if (item.current) {
                item.current.style['display'] = 'none';
                item.current.previousElementSibling.className = '';
            }
        });

        if (tr.current.style.display !== 'block') {
            tr.current.style['display'] = 'block';
            target.className = 'active';
        }
    };

    const onClickHideDetailMore = (e) => {
        let idx = e.target.dataset.idx || e.target.parentNode.dataset.idx;
        tr_ref[idx].current.style.display = 'none';
        e.target.parentNode.parentNode.previousElementSibling.className = null;
    };

    const onChangeTabChecked = (input, text) => {
        let copy_btns_state_tab = btns_state_tab.concat([]);
        var item = copy_btns_state_tab.find(item => item.text === text);

        item.checked = Number(input.target.checked);
        setBtnsStateTab(copy_btns_state_tab);

        if (item.checked) {
            table_head_tag.push(<th>{item.text}</th>);
            setTableHead(table_head.concat([item.text]));
        } else {
            let findItem = table_head.find((item) => {
                return item === item.text;
            });
            let idx = table_head.indexOf(findItem);
            setTableHead(table_head.splice(idx, 1));
        }
    };

    const onChangeSortChecked = (input, text) => {
        let copy_sort = _.cloneDeep(btns_state_sort);

        if (input.target.checked) {
            if (text != "전체") {
                copy_sort[0].checked = false;
            } else {
                copy_sort.map((item, index) => {
                    if (index > 0) {
                        copy_sort[index].checked = false;
                    }
                });
            }
        }
        copy_sort.find(item => item.text === text).checked = Number(input.target.checked);

        if (!copy_sort.filter(item => item.checked).length) {
            alert('최소 한개 이상은 선택해야 합니다.');
            copy_sort.find(item => item.text === text).checked = true;
            return;
        }
        setBtnsStateSort(copy_sort);
    };

    const onChagneKeywordType = (e) => {
        setKeywordType(e.target.value);
    };

    const onClickToggleSelectedBasics = () => {
        // 기본으로 설정 ON 일때 체크 값 기본으로 설정
        if (!selectedBasics) {
            let copy_tab = window.$Global.cloneObject(btns_state_tab);
            let copy_arr_tab = Object.keys(copy_tab).map(key => copy_tab[key]);
            copy_arr_tab.forEach(item => {
                item.checked = item.defaultChecked;
            });
            setBtnsStateTab(copy_arr_tab);
        }
        setSelectedBasics(!selectedBasics);
    };

    const saveTab = () => {
        let copy_tab = window.$Global.cloneObject(btns_state_tab);
        let copy_arr_tab = Object.keys(copy_tab).map(key => copy_tab[key]);

        // 기본 탭 설정일때
        if (selectedBasics) {
            window.localStorage.setItem("patent_tab_init", "1");
            copy_arr_tab.forEach(item => {
                item.checked = item.defaultChecked;
            });
        } else {
            window.localStorage.removeItem("patent_tab_init");
            copy_arr_tab.forEach(item => {
                window.localStorage.setItem("patent_" + item.key, item.checked);
            });
        }
        setTabState(false);
    };

    const initData = (data) => {
        if (!data) return;
        let selectedBasics_tab;
        if (isCompany) {
            selectedBasics_tab = btns_state_tab.filter((item) => item.checked);
        } else {
            selectedBasics_tab = btns_state_tab.filter(item => item.text != "특허사무소").filter((item) => item.checked);
        }

        let manage_number = selectedBasics_tab.filter(obj => obj.key === 'manage_number').length;
        let external_family = selectedBasics_tab.filter(obj => obj.key === 'external_family').length;
        let nation_string = selectedBasics_tab.filter(obj => obj.key === 'nation_string').length;
        let type = selectedBasics_tab.filter(obj => obj.key === 'type').length;
        let apply_number = selectedBasics_tab.filter(obj => obj.key === 'apply_number').length;
        let apply_at = selectedBasics_tab.filter(obj => obj.key === 'apply_at').length;
        let state = selectedBasics_tab.filter(obj => obj.key === 'state').length;
        let register_number = selectedBasics_tab.filter(obj => obj.key === 'register_number').length;
        let register_at = selectedBasics_tab.filter(obj => obj.key === 'register_at').length;
        let agent_company_name = selectedBasics_tab.filter(obj => obj.key === 'agent_company_name').length;
        let researcher = selectedBasics_tab.filter(obj => obj.key === 'researcher').length;
        let open_number = selectedBasics_tab.filter(obj => obj.key === 'open_number').length;
        let open_at = selectedBasics_tab.filter(obj => obj.key === 'open_at').length;
        let invention_name = selectedBasics_tab.filter(obj => obj.key === 'invention_name').length;
        let invention_name_en = selectedBasics_tab.filter(obj => obj.key === 'invention_name_en').length;
        let agent_name = selectedBasics_tab.filter(obj => obj.key === 'agent_name').length;

        if (data.length) {
            let arr_tbody =
                data.map((item, index) => {
                    let type_str = window.$Global.convertTypeToText(item.type);
                    tr_ref[index] = React.createRef();

                    return (
                        <React.Fragment key={index}>
                            <tr data-idx={index} className={index === 0 ? 'active' : ''} onClick={onClickMore}>
                                <td>{item.order}</td>
                                {manage_number ? <td className="manage_number">{item.manage_number || 'N/A'}</td> : null}
                                {/*{external_family ? <td className="external_family">{item.family}</td> : null}*/}
                                {nation_string ? <td className="nation_string">{item.nation_string}</td> : null}
                                {type ? <td className="type">{type_str}</td> : null}
                                {apply_number ? <td className="apply_number">{item.apply_number || 'N/A'}</td> : null}
                                {apply_at ? <td className="apply_at">{window.$Global.convertDate(item.apply_at)}</td> : null}
                                {state ? <td className="state">{window.$Global.convertStateToText(item.state)}</td> : null}
                                {register_number ? <td className="register_number">{item.register_number}</td> : null}
                                {register_at ? <td className="register_at">{window.$Global.convertDate(item.register_at)}</td> : null}
                                {agent_company_name ? <td className="agent_company_name">{item.agent_company_name}</td> : null}
                                {researcher ? <td className="researcher">{item.researcher}</td> : null}
                                {open_number ? <td className="open_number">{item.open_number}</td> : null}
                                {open_at ? <td className="open_at">{window.$Global.convertDate(item.open_at)}</td> : null}
                                {invention_name ? <td className="invention_name">{item.invention_name}</td> : null}
                                {invention_name_en ? <td className="invention_name_en">{item.invention_name_en}</td> : null}
                                {agent_name ? <td className="agent_name">{item.agent_name}</td> : null}
                            </tr>
                            <tr className={`more_view`} style={index === 0 ? {'display' : "block"} : null } ref={tr_ref[index]}>
                                <td key={`${item.idx}_detail`}>
                                    <Link className="detail_info" to={`detail/${item.idx}`}>
                                        <h2 className="title">상세정보</h2>
                                        <p>자세히보기<i className="icon_more_black"/></p>
                                        <i className="icon_detail"/>
                                    </Link>
                                    <Link className="relative_file" to={`relativeFile/${item.idx}`}>
                                        <h2 className="title">관련파일</h2>
                                        <p>자세히보기<i className="icon_more_black"/></p>
                                        <i className="icon_attached_file"/>
                                    </Link>
                                    {
                                        isCompany &&
                                        <Link className={`payment ${item.register_at ? "" : "no_click"}`}
                                              to={`/annual/manage/${item.idx}?nation=${window.$Global.convertNationCodeToStr(item.nation)}&manage_number=${item.manage_number || ""}&register_number=${item.register_number || ""}&deadline_at=${item.apply_at ? new Date(item.apply_at).getFullYear() + 20 : ""}&type=design`}>
                                            <h2 className="title">연차료 관리</h2>
                                            <p>자세히보기<i className="icon_more_black"/></p>
                                            <i className="icon_patent_payment"/>
                                        </Link>
                                    }
                                    <button  className="icon_exit" data-idx={index} onClick={onClickHideDetailMore}/>
                                </td>
                            </tr>
                        </React.Fragment>
                    );
                });
            setTrRef(tr_ref);
            setTbody(arr_tbody);
        } else {
            setTbody([window.$Global.notTd(20, "데이터가 없습니다")]);
        }
    };

    const drawPatentList = async (params, type) => {
        let param = params || {
            page: curPage,
            count: count,
            options: btns_state_sort.filter(item => item.checked).reduce((x, y) => x + y.key, 0),
            keyword: keyword_ref.current ? keyword_ref.current.value : "",
            type: keywordType,
            sort_nation: nation,
            sort_state: state,
            sort_agent: agent
        };
        let result = [];
        if (isCompany) {
            result = await DesignAPI.getDesignList(param).then(res => res.data);
        } else if (isAgent) {
            result = await DesignAPI.getDesignListAsAgent(manage_company_idx, param).then(res => res.data);
        }
        if (type == "next") {
            let copy = _.cloneDeep(data);
            result.items = copy.items.concat(result.items);
        }
        setData(result);
        if (result.page == result.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    const onClickMoreStateDisplay = (type) => {
        setMoreTab({...moreTab, [type]: !moreTab[type]});
    };

    const onClickFilter = (nation_code, state_code, agent_idx) => {
        setNation(nation_code);
        setState(state_code);
        setAgent(agent_idx);
        let param = {
            page: 1,
            count: count,
            options: btns_state_sort.filter(item => item.checked).reduce((x, y) => x + y.key, 0),
            keyword: keyword_ref.current ? keyword_ref.current.value : "",
            type: keywordType,
            sort_nation: nation_code,
            sort_state: state_code,
            sort_agent: agent_idx
        };
        setCurPage(1);
        drawPatentList(param);
    };

    const searchPatent = () => {
        let param = {
            page: 1,
            count: count,
            options: btns_state_sort.filter(item => item.checked).reduce((x, y) => x + y.key, 0),
            keyword: keyword_ref.current ? keyword_ref.current.value : "",
            type: keywordType,
            sort_nation: nation,
            sort_state: state,
            sort_agent: agent
        };
        setCurPage(1);
        drawPatentList(param);
    };

    const nextList = () => {
        let param = {
            page: curPage + 1,
            count: count,
            options: btns_state_sort.filter(item => item.checked).reduce((x, y) => x + y.key, 0),
            keyword: keyword_ref.current ? keyword_ref.current.value : "",
            type: keywordType,
            sort_nation: nation,
            sort_state: state,
            sort_agent: agent
        };
        setCurPage(curPage + 1);
        drawPatentList(param, "next");
    };

    const onClickFilterCheckBox = (e, text, index, type) => {
        let copy = _.cloneDeep(filterCheckList);

        copy[type] = copy[type].map((item, idx) => {
            if (idx != index) {
                return false;
            }
        });
        copy[type][index] = e.target.checked;

        if (copy[type].findIndex(item => item == true) == -1) {
            copy[type][copy[type].length - 1] = true;
        }
        setFilterCheckList(copy);

        if (copy[type][index]) {
            if (type == "nation") {
                onClickFilter(text, state, agent);
            } else if (type == "state") {
                onClickFilter(nation, text, agent);
            } else if (type == "agent") {
                onClickFilter(nation, state, text);
            }
        } else {
            if (type == "nation") {
                onClickFilter(null, state, agent);
            } else if (type == "state") {
                onClickFilter(nation, null, agent);
            } else if (type == "agent") {
                onClickFilter(nation, state, null);
            }
        }
        onClickMoreStateDisplay(type);
    };

    const onBlur = (type) => {
        if (!mouse) {
            setMoreTab({...moreTab, [type]: true});
        }
    };

    btns_state_tab.forEach((item, i) => {
        if (item.checked) {
            // if (item.text === "국가") {
                // let tag =
                //     nationList.map((item, index) => {
                //         return (
                //             <li key={index} tabIndex={0} onBlur={() => onBlur('nation')}>
                //                 <CheckBox key={i} text={window.$Global.convertNationCodeToStr(item).toUpperCase()} checked={filterCheckList.nation[index] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, item, index, "nation")}/>
                //             </li>
                //         );
                //     });
                // table_head_tag.push(
                //     <th key={i} className={moreTab.nation ? "dp_none": ""} style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 40}} onBlur={() => onBlur('nation')}>
                //         {item.text}
                //         <button className="icon_more_white" onClick={() => onClickMoreStateDisplay('nation')}/>
                //         <ul onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                //             <li tabIndex={0} onBlur={() => onBlur('nation')}>
                //                 <CheckBox key={i} text="전체" checked={filterCheckList.nation[nationList.length] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, null, nationList.length, "nation")}/>
                //             </li>
                //             {tag}
                //         </ul>
                //     </th>
                // );
            // } else
            if (item.text === "현재상태") {
                let tag =
                    stateList.map((item, index) => {
                        return (
                            <li key={index} tabIndex={0} onBlur={() => onBlur('state')}>
                                <CheckBox key={i} text={window.$Global.convertStateToText(item)} checked={filterCheckList.state[index] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, item, index, "state")}/>
                            </li>
                        );
                    });
                table_head_tag.push(
                    <th key={i} className={moreTab.state ? "dp_none": ""} style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 40}} onBlur={() => onBlur('state')}>
                        {item.text}
                        <button className="icon_more_white" onClick={() => onClickMoreStateDisplay('state')}/>
                        <ul onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                            <li tabIndex={0} onBlur={() => onBlur('state')}>
                                <CheckBox key={i} text="전체" checked={filterCheckList.state[stateList.length] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, null, stateList.length, "state")}/>
                            </li>
                            {tag}
                        </ul>
                    </th>
                );
            } else if (item.text === "특허사무소") {
                let tag =
                    agentList.map((item, index) => {
                        return (
                            <li key={index} tabIndex={0} onBlur={() => onBlur('agent')}>
                                <CheckBox key={i} text={item.agent_name} checked={filterCheckList.agent[index] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, item.agent_company_idx, index, "agent")}/>
                            </li>
                        );
                    });
                table_head_tag.push(
                    <th key={i} className={moreTab.agent ? "dp_none": ""} style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: 40}} onBlur={() => onBlur('agent')}>
                        {item.text}
                        <button className="icon_more_white" onClick={() => onClickMoreStateDisplay('agent')}/>
                        <ul onMouseEnter={() => setMouse(true)} onMouseLeave={() => setMouse(false)}>
                            <li tabIndex={0} onBlur={() => onBlur('agent')}>
                                <CheckBox key={i} text="전체" checked={filterCheckList.agent[agentList.length] || false} onChangeChecked={(e) => onClickFilterCheckBox(e, null, agentList.length, "agent")}/>
                            </li>
                            {tag}
                        </ul>
                    </th>
                );
            } else {
                table_head_tag.push(<th key={i}>{item.text}</th>);

                if (item.text != "건수" && item.text != "Family" && item.text != "등록일" && item.text != "출원일" && item.text != '국가') {
                    options_tag.push(<option key={i} value={item.type}>{item.text}</option>);
                }
            }
        }

        tab_tags.push(
            <CheckBox key={i} text={item.text} checked={item.checked} onChangeChecked={onChangeTabChecked} disabled={selectedBasics}/>
        );
    });

    btns_state_sort.forEach((item, i) => {
        sort_tags.push(
            <CheckBox key={i} text={item.text} checked={item.checked} onChangeChecked={onChangeSortChecked}/>
        );
    });

    return (
        <div id="PatentView">
            <div className="header">
                <h2 className="title">디자인 전체</h2>
                <div className="btns">
                    {
                        tabState ?
                        <>
                            <ToggleButtonWithSpan text="Tab 기본 설정하기 " condition={{on: 'ON', off: 'OFF'}} selected={selectedBasics} toggleSelected={onClickToggleSelectedBasics}/>
                            <button className="btn_tab_setting_save" onClick={saveTab}>Tab 설정 저장</button>
                        </>
                        :
                        <>
                        {
                            isCompany && <Link className="btn_add" to="/design/detail/new">디자인 등록</Link>
                        }
                            {/*<button className="btn_excel_download">Excel Download</button>*/}
                            <button className="btn_tab_setting" onClick={() => setTabState(true)}>Tab 설정</button>
                        </>
                    }
                </div>
            </div>
            <div className="wrap_tab_btns">
                {tabState && tab_tags}
            </div>
            {
                tabState ||
                <>
                    <div className="wrap_search">
                        <select onChange={onChagneKeywordType}>
                            {options_tag}
                        </select>
                        <input type="text" className="input_search" ref={keyword_ref} placeholder="검색어를 입력해주세요" onKeyUp={(e) => {if(e.key === 'Enter') searchPatent();}}/>
                        <button className="icon_search_black" onClick={searchPatent}/>
                    </div>
                    <div className="sorting">{sort_tags}</div>
                </>
            }
            <div className="table-box-wrap">
                <div className="wrap_list" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={tbody.length}
                        next={nextList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table className="list">
                            <thead>
                                <tr className="no_focus_outline">
                                    {table_head_tag}
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}

export default React.memo(DesignListView);
