import React from "react";
import "./css/BillingHeader.scss";

const BillingHeader = ({ next, setShowPopup, data, annualFeeData }) => {
  return (
    <div id="BillingHeader">
      <div>
        <h2>
          {`청구서 ${data.billing_number ? "수정" : "등록"}`}
          <span>{annualFeeData.nation === "KR" ? "[국내]" : "[해외]"}</span>
        </h2>
        <i
          className="icon_exit_gray"
          onClick={() =>
            setShowPopup({
              billing: false,
            })
          }
        />
      </div>
      <p>{!next ? "- 기본 정보 -" : "- 비용 정보 -"}</p>
    </div>
  );
};

export default BillingHeader;
