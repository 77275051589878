import React from "react";

const ReceiptCostKR = ({ data }) => {
  return (
    <table>
      <colgroup>
        <col width="10%" />
        <col width="30%" />
        <col width="10%" />
        <col width="30%" />
      </colgroup>
      <thead>
        <tr>
          <th>청구내역</th>
          <th>청구 금액</th>
          <th>청구내역</th>
          <th>청구 금액</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>연차료 납부</td>
          <td>
            ₩{window.$Global.numberWithCommas(data?.annual_pay_amount || 0)}
          </td>
          <td>IPNOW 수수료</td>
          <td>₩{window.$Global.numberWithCommas(data?.agent_fee || 0)}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>수수료 부가세</td>
          <td>₩{window.$Global.numberWithCommas(data?.surtax || 0)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReceiptCostKR;
