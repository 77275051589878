import BillingInputRow from "./BillingInputRow";
import { useEffect, useState } from "react";
import AnnualPay from "../../../../API/annualPay";

const BillingOverseasCost = ({
  sendData,
  setSendData,
  annualFeeData,
  handleChange,
}) => {
  const [moneySymbol, setMoneySymbol] = useState([]);

  useEffect(() => {
    AnnualPay.getAnnualPayCurrencySymbol({
      nation_code: annualFeeData.nation,
    }).then((res) => {
      let symbolArr = Object.values(res.data.money_symbol);
      if (sendData.overseas_agent_fee_money_symbol === "") {
        sendData.overseas_agent_fee_money_symbol = symbolArr[0];
      }
      if (sendData.overseas_office_fee_money_symbol === "") {
        sendData.overseas_office_fee_money_symbol = symbolArr[0];
      }
      if (sendData.ipnow_fees_money_symbol === "") {
        sendData.ipnow_fees_money_symbol = symbolArr[0];
      }
      setMoneySymbol(symbolArr);
      setSendData({ ...sendData });
    });
  }, []);

  return (
    <div>
      <BillingInputRow
        type="number"
        data={sendData.overseas_office_fee_calc}
        name="overseas_office_fee_calc"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="1"
        rowName="해외 관납료(환율 적용)"
        widthOption="full"
      />
      <div className="sub_field">
        <BillingInputRow
          type="number"
          data={sendData.overseas_office_fee}
          name="overseas_office_fee"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={moneySymbol}
          rowName="해외 관납료"
          widthOption="full"
          selectName="overseas_office_fee_money_symbol"
          selectValue={sendData.overseas_office_fee_money_symbol}
        />
      </div>
      <BillingInputRow
        type="number"
        data={sendData.overseas_agent_fee_calc}
        name="overseas_agent_fee_calc"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="2"
        rowName="해외 대리인 수수료(환율 적용)"
        widthOption="full"
      />
      <div className="sub_field">
        <BillingInputRow
          type="number"
          data={sendData.overseas_agent_fee}
          name="overseas_agent_fee"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={moneySymbol}
          rowName="해외 대리인 수수료"
          widthOption="full"
          selectName="overseas_agent_fee_money_symbol"
          selectValue={sendData.overseas_agent_fee_money_symbol}
        />
      </div>
      <BillingInputRow
        type="number"
        data={sendData.total_charge}
        name="total_charge"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="3"
        rowName="해외수수료 합"
        widthOption="full"
      />
      <div className="sub_field">
        <BillingInputRow
          type="number"
          data={sendData.overseas_fees}
          name="overseas_fees"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={"₩"}
          rowName="해외수수료"
          widthOption="full"
        />
        <BillingInputRow
          type="number"
          data={sendData.remittance_fee}
          name="remittance_fee"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={"₩"}
          rowName="송금수수료"
          widthOption="full"
        />
        <BillingInputRow
          type="number"
          data={sendData.computer_fee}
          name="computer_fee"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={"₩"}
          rowName="전신료"
          widthOption="full"
        />
      </div>
      <BillingInputRow
        type="number"
        data={sendData.ipnow_fees_calc}
        name="ipnow_fees_calc"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="4"
        rowName="IPNOW 수수료(환율 적용)"
        widthOption="full"
      />
      <div className="sub_field">
        <BillingInputRow
          type="number"
          data={sendData.ipnow_fees}
          name="ipnow_fees"
          readOnly={false}
          onChange={handleChange}
          moneySymbol={moneySymbol}
          rowName="IPNOW 수수료"
          widthOption="full"
          selectName="ipnow_fees_money_symbol"
          selectValue={sendData.ipnow_fees_money_symbol}
        />
      </div>
      <BillingInputRow
        type="number"
        data={sendData.surtax}
        name="surtax"
        readOnly={false}
        onChange={handleChange}
        moneySymbol={"₩"}
        textNumber="5"
        rowName="부가세"
        widthOption="full"
      />
    </div>
  );
};

export default BillingOverseasCost;
