import React from 'react';
import "./css/IntroContainer.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IntroIpNow from "./ipnow/IntroIpNow";
import IntroFindBiz from "./findbiz/IntroFindBiz";
import {useLocation} from "react-router-dom";

function IntroContainer({ history }) {
    const location = useLocation();
    const isViewTypeIpnow = window.$Global.isIpnow(location);

    return (
        <div id="IntroContainer">
        {
            isViewTypeIpnow
            ? <IntroIpNow history={history}/>
            : <IntroFindBiz/>
        }
        </div>
    );
}

export default IntroContainer;
