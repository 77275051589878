import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";

const TradeMarkMemo = () => {
  const history = useHistory();
  const { trademark_idx } = useParams();
  const [tradeMark, setTradeMark] = useState({});

  useEffect(() => {
    TradeMarkAPI.getTradeMarkInfo(trademark_idx).then((res) => {
      setTradeMark(res.data);
    });
  }, []);

  const onClickSaveMemo = () => {
    TradeMarkAPI.updateTradeMarkCompany(trademark_idx, tradeMark).then(() =>
      alert("저장되었습니다.")
    );
  };

  return (
    <div id="TradeMarkMemo">
      <div className="header">
        <h2 className="title">특허 메모</h2>
        <div className="patent_info">
          관리번호
          {tradeMark.manage_number != "null" ? tradeMark.manage_number : "N/A"}
          <i
            className={`icon_flag_${window.$Global.convertNationCodeToStr(
              tradeMark.nation
            )} flag`}
          />
        </div>
        <div className="btns">
          <button className="btn_save" onClick={onClickSaveMemo}>
            저장
          </button>
          <button className="btn_save" onClick={() => history.goBack()}>
            이전
          </button>
        </div>
      </div>

      <div className="memo">
        <p>메모 내용</p>
        <textarea
          placeholder="해당 특허의 중요내용에 대해 기록해 놓으시고, 추후 활용하세요."
          value={tradeMark.memo?.replace(/(<br>|<br\/>|<br \/>)/g, "\r\n")}
          onChange={(e) => setTradeMark({ ...tradeMark, memo: e.target.value })}
          autoFocus
        />
      </div>
    </div>
  );
};

export default TradeMarkMemo;
