import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./css/CostDetail.scss";
import CostDetailPatentKR from "./detail/CostDetailPatentKR";
import CostDetailPatentOverseas from "./detail/CostDetailPatentOverseas";
import PatentAPI from "../../API/patent";
import TradeMarkAPI from "../../API/trademark";
import DesignAPI from "../../API/design";
import CostAPI from "../../API/cost/index";
import MemberContext from "../../store/Member";
import _ from "lodash";
import BillingContext from "../../store/Billing";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import CostDetailTradeMarkKR from "./detail/CostDetailTradeMarkKR";
import CostDetailDesignKR from "./detail/CostDetailDesignKR";
import { patentCostType } from "../../types/patentCostType";
/*
    type : patent, trademark
    type_idx : 특허 및 상표 idx
    cost_idx : new 일때 신규 생성, 기존 존재한 정보를 보려고 할땐 idx 값으로
*/
function CostDetail({ history }) {
  const { manage_company_idx } = useContext(MemberContext);
  const bill = useContext(BillingContext);
  const { type, type_idx, cost_idx } = useParams();
  const [editing, setEditing] = useState(false);
  const [billing, setBilling] = useState({});
  const [historyBilling, setHistoryBilling] = useState({});

  const isNew = cost_idx == "new";
  const isReject = billing.bill?.state == 2;
  const isComapnyConfirm = billing.bill?.state == 3 || billing.bill?.state == 4;
  const isKR = billing.bill?.nation == 0;
  const isPatent = type == "patent";
  const isTradeMark = type == "tradeMark";
  const isDesign = type == "design";

  const [downPopupShow, setDownPopupShow] = useState(false); // 팝업 보여주기 여부
  const [upPopupShow, setUpPopupShow] = useState(false); // 팝업 보여주기 여부
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
  const [popupProp, setPopupProp] = useState({ type: null });

  useEffect(async () => {
    let result = {};
    let initData = _.cloneDeep(bill);
    initData.func = initFunc;

    if (isNew) {
      if (isPatent) {
        result = await PatentAPI.getPatentInfoAsAgent(
          manage_company_idx,
          type_idx
        ).then((res) => res.data);
        initData.bill.invention_name = result.invention_name;
        initData.bill.invention_name_en = result.invention_name_en;
      } else if (isTradeMark) {
        result = await TradeMarkAPI.getTradeMarkInfoAgent(
          manage_company_idx,
          type_idx
        ).then((res) => res.data);
      } else if (isDesign) {
        result = await DesignAPI.getInfoAsAgent(
          manage_company_idx,
          type_idx
        ).then((res) => res.data);
      }
      initData.bill.nation = result.nation;
      initData.bill.manage_number = result.manage_number;
      initData.bill.agent_manage_number = result.agent_manage_number;
      initData.bill.manager_name = result.manager_name;
      initData.bill.company_info = [
        { location: null, name: result.company_name },
      ];
      initData.bill.apply_number = result.apply_number;
      initData.bill.apply_at = result.apply_at;
      initData.bill.patent_agent_name = result.agent_name;
      setEditing(true);
    } else {
      if (isPatent) {
        result = await CostAPI.getAgentPatentBilling(
          manage_company_idx,
          type_idx,
          cost_idx
        ).then((res) => res.data);
      } else if (isTradeMark) {
        result = await CostAPI.getAgentTradeMarkBilling(
          manage_company_idx,
          type_idx,
          cost_idx
        ).then((res) => res.data);
      } else if (isDesign) {
        result = await CostAPI.getDesignBillingAsAgent(
          manage_company_idx,
          type_idx,
          cost_idx
        ).then((res) => res.data);
      }
      initData.bill = result;
    }

    if (isPatent) {
      let class_list = [];

      let billObj = initData.bill;
      let type = await CostAPI.getPatentCostType(manage_company_idx);
      let type_index;

      if (billObj.nation === 0) {
        type_index = 0;
      } else if (billObj.nation === 1) {
        type_index = 2;
      } else {
        type_index = 1;
      }

      type = type.data.cost;

      let json = {};

      for (let k in type) {
        let type_obj = {};
        type_obj[patentCostType[k]] = {};
        for (let k1 in type[k]) {
          type_obj[patentCostType[k]][patentCostType[k + "_" + k1]] = [];
          for (let k2 in type[k][k1]) {
            type_obj[patentCostType[k]][patentCostType[k + "_" + k1]].push(
              patentCostType[k + "_" + k1 + "_" + k2] + "/" + type[k][k1][k2]
            );
            json[type[k][k1][k2]] = patentCostType[k + "_" + k1 + "_" + k2];
          }
        }
        class_list.push(type_obj);
      }

      let large_type = Object.keys(
        class_list[type_index][Object.keys(class_list[type_index])]
      );
      let middle_type =
        class_list[type_index][Object.keys(class_list[type_index])];

      initData.largeClass = large_type;
      initData.middleClass = middle_type;
    }

    setBilling(initData);
    setHistoryBilling(initData);
  }, []);

  const onClickSave = async () => {
    let payload = _.cloneDeep(billing.bill);
    if (billing.bill.type_direct) {
      payload.type = payload.type_direct;
    }

    if (isNew) {
      let formData = new FormData();
      for (let key in payload) {
        if (key == "attach_file" || key == "gf_file" || key == "etc_fee_file") {
          payload[key].forEach((item) => {
            formData.append(key, item.file);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }

      if (isPatent) {
        await CostAPI.addPatentBillingAsAgent(
          manage_company_idx,
          type_idx,
          formData
        );
      } else if (isTradeMark) {
        await CostAPI.addTradeMarkBillingAsAgent(
          manage_company_idx,
          type_idx,
          formData
        );
      } else if (isDesign) {
        await CostAPI.addDesignBillingAsAgent(
          manage_company_idx,
          type_idx,
          formData
        );
      }
      alert("청구서가 등록되었습니다");
      history.push("/cost/list?nation=1");
    } else {
      let copy = _.cloneDeep(billing);
      if (isPatent) {
        await CostAPI.updatePatentBillingAsAgent(
          manage_company_idx,
          type_idx,
          cost_idx,
          payload
        ).then((res) => {
          copy.bill.surtax = res.data.surtax;
          copy.bill.for_surtax = res.data.for_surtax;
          copy.bill.sum_total_domestic = res.data.sum_total_domestic;
          copy.bill.for_sum_total = res.data.for_sum_total;
          setBilling(copy);
        });
      } else if (isTradeMark) {
        await CostAPI.updateTradeMarkBillingAsAgent(
          manage_company_idx,
          type_idx,
          cost_idx,
          payload
        );
      } else if (isDesign) {
        await CostAPI.updateDesignBillingAsAgent(
          manage_company_idx,
          type_idx,
          cost_idx,
          payload
        );
      }
      window.$Global.saveToast();
      setEditing(false);
    }
  };

  const onClickCancle = () => {
    setEditing(false);
    setBilling(historyBilling);
  };

  const onChangeInput = (e, key, obj) => {
    let copy = _.cloneDeep(obj);
    copy.func = initFunc;
    if (key) {
      copy.bill[key] = e;
    } else {
      copy.bill = e;
    }
    setBilling(copy);
  };

  const onClickDownload = (idx) => {
    if (isPatent) {
      CostAPI.downloadAgentPatentCostFile(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    } else if (isTradeMark) {
      CostAPI.downloadAgentTradeMarkCostFile(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    } else if (isDesign) {
      CostAPI.downloadDesignCostFileAsAgent(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    }
  };

  const onClickDeleteFileInDownload = async (idx) => {
    if (isPatent) {
      await CostAPI.deletePatentAgentFile(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    } else if (isTradeMark) {
      await CostAPI.deleteTradeMarkAgentFile(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    } else if (isDesign) {
      await CostAPI.deleteDesignFile(
        manage_company_idx,
        type_idx,
        cost_idx,
        idx
      );
    }
    alert("삭제되었습니다");
    setFileViewList(
      fileViewList.filter((file) => {
        return file.idx !== idx;
      })
    );
  };

  const onClickUpload = async () => {
    let formData = new FormData();

    if (isNew) {
      let copy = _.cloneDeep(billing);
      copy.bill[popupProp.type] = uploadFileViewList;
      setBilling(copy);
    } else {
      let type_str = getType(popupProp.type);

      formData.append("type", type_str);
      uploadFileViewList.forEach((item) => formData.append("file", item.file));

      if (isPatent) {
        await CostAPI.uploadPatentCostFile(
          manage_company_idx,
          type_idx,
          cost_idx,
          formData
        );
      } else if (isTradeMark) {
        await CostAPI.uploadTradeMarkCostFile(
          manage_company_idx,
          type_idx,
          cost_idx,
          formData
        );
      } else if (isTradeMark) {
        await CostAPI.uploadDesignCostFile(
          manage_company_idx,
          type_idx,
          cost_idx,
          formData
        );
      }
      alert("업로드되었습니다");
    }
    setUpPopupShow(false);
    setUploadFileViewList([]);
  };

  const onClickDownPopShow = async (type) => {
    let type_str = getType(type);
    let result = [];
    if (isPatent) {
      result = await CostAPI.getAgentPatentCostFileList(
        manage_company_idx,
        type_idx,
        cost_idx,
        { type: type_str }
      ).then((res) => res.data);
    } else if (isTradeMark) {
      result = await CostAPI.getAgentTradeMarkCostFileList(
        manage_company_idx,
        type_idx,
        cost_idx,
        { type: type_str }
      ).then((res) => res.data);
    } else if (isDesign) {
      result = await CostAPI.getAgentDesignCostFileList(
        manage_company_idx,
        type_idx,
        cost_idx,
        { type: type_str }
      ).then((res) => res.data);
    }
    setFileViewList(result);
    setDownPopupShow(true);
  };

  const onClickUpPopShow = (type, obj) => {
    if (isNew) {
      setUploadFileViewList(obj[type] || []);
    }
    setPopupProp({ ...popupProp, type: type });
    setUpPopupShow(true);
  };

  const getType = (type) => {
    let type_str = 0;
    switch (type) {
      case "attach_file":
        type_str = 0;
        break;
      case "for_gf_file":
        type_str = 1;
        break;
      case "for_agent_commission_file":
        type_str = 2;
        break;
      case "for_etc_fee_file":
        type_str = 3;
        break;
      case "gf_file":
        type_str = 4;
        break;
      case "etc_fee_file":
        type_str = 5;
        break;
    }
    return type_str;
  };

  const initFunc = {
    onChangeInput,
    onClickDownPopShow,
    onClickUpPopShow,
  };

  const onClickDelete = (idx) => {
    let copyArr = uploadFileViewList.slice(0);
    copyArr.splice(idx, 1);

    let copy = _.cloneDeep(billing);
    copy.bill[popupProp.type] = copyArr;
    setBilling(copy);
    setUploadFileViewList(copyArr);
  };

  let component = [];

  if (isPatent) {
    if (isKR) {
      component = <CostDetailPatentKR edit={editing} />;
    } else {
      component = <CostDetailPatentOverseas edit={editing} />;
    }
  } else if (isTradeMark) {
    if (isKR) {
      component = <CostDetailTradeMarkKR edit={editing} />;
    }
  } else if (isDesign) {
    if (isKR) {
      component = <CostDetailDesignKR edit={editing} />;
    }
  }

  if (window.$Global.isEmptyObject(billing)) return null;

  return (
    <BillingContext.Provider value={billing}>
      <div id="CostDetail" className={`${editing && isNew ? "edit" : ""}`}>
        <div className="header">
          <h2>
            {isKR ? "국내" : "해외"}{" "}
            {isPatent ? "특허" : isTradeMark ? "상표" : "디자인"} 출원 비용
            상세페이지
          </h2>
          <div className="wrap_info">
            <div className="info">
              <p>
                출원번호 {billing.bill.apply_number}{" "}
                <i
                  className={`icon_flag_${window.$Global.convertNationCodeToStr(
                    billing.bill.nation
                  )}`}
                />
              </p>
              <p>청구번호 {billing.bill.billing_number || ""}</p>
            </div>
            {!isComapnyConfirm && (
              <div className="btns">
                {editing ? (
                  <>
                    <button className="btn_save" onClick={onClickSave}>
                      {isNew ? "등록" : "저장"}
                    </button>
                    {!isNew && (
                      <button className="btn_cancle" onClick={onClickCancle}>
                        취소
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="btn_update"
                    onClick={() => setEditing(true)}
                  >
                    수정
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {component}
        {isReject && (
          <div className="comment">
            <h2>수정 요청 Comment</h2>
            <p>{billing.bill.comment}</p>
          </div>
        )}
      </div>
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
        prop_onClickDelete={onClickDelete}
      />
    </BillingContext.Provider>
  );
}

export default CostDetail;
