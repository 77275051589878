import API from '../../util/api';

export default {
    getList(params) {
        return API.get('/common/lawfirm', {params: params});
    },

    getInfo(lawfirm_idx) {
        return API.get(`/common/lawfirm/${lawfirm_idx}`);
    },

    addPost(payload) {
        return API.post(`/admin/lawfirm`, payload);
    },

    updatePost(lawfirm_idx, payload) {
        return API.patch(`/admin/lawfirm/${lawfirm_idx}`, payload);
    },

    deletePost(lawfirm_idx, payload) {
        return API.delete(`/admin/lawfirm/${lawfirm_idx}`, payload);
    },

    updateIntroduceFile(lawfirm_idx, payload) {
        return API.post(`/admin/lawfirm/${lawfirm_idx}/introduce`, payload);
    },

    deleteIntroduceFile(lawfirm_idx, file_idx) {
        return API.delete(`/admin/lawfirm/${lawfirm_idx}/introduce/${file_idx}`);
    },

    download(lawfirm_idx, introduce_idx) {
        window.$Global.download(`/common/lawfirm/${lawfirm_idx}/introduce/${introduce_idx}/download`);
    }
};
