import React from "react";

function ClaimCountRow({ editing, claimCount, onChangeIndependence, onChangeDependent, withCheckpoint, onOpenReport }) {
  return (
    <div className="row">
      <h2 className="title">
        청구항 수<br />
        (독립항/종속항)
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage("청구항 수")} />
      </h2>
      {editing ? (
        <div>
          <input
            type="text"
            value={claimCount.independence}
            onChange={onChangeIndependence}
            style={{ width: "50px" }}
          />
          <span style={{ margin: "0 6px" }}>/</span>
          <input type="text" value={claimCount.dependent} onChange={onChangeDependent} style={{ width: "50px" }} />
        </div>
      ) : (
        <p>
          ({claimCount.independence || 0}/{claimCount.dependent || 0})
        </p>
      )}
    </div>
  );
}

export default ClaimCountRow;
