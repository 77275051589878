import React, {useEffect, useState} from "react";
import "../css/MarketInfoEditList.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";
import CommonAPI from "../../../API/common";
import _ from "lodash";

function MarketInfoEditList() {
	const [curPage, setCurPage] = useState(1);
	const [tbody, setTbody] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [ downloadCnt, setDownloadCnt ] = useState(0);

	const count = 20;

	useEffect(() => {
		let params = {
			page: 1,
			count: count
		};
		CommonAPI.getMarketDocumentList(params).then(res => {
			let data = res.data;
			setTbody(data);
			checkHasMore(1, data.total_count);
		});
		getCompanyInfo();
	}, []);

	const checkHasMore = (page, total_count) => {
		if (page < total_count) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	}

	const nextList = () => {
		let params = {
			page: curPage + 1,
			count: count
		}
		setCurPage(curPage + 1);
		CommonAPI.getMarketDocumentList(params).then(res => {
			let data = res.data;
			data.items = tbody.items.concat(data.items);
			setTbody(data);
			checkHasMore(params.page, data.total_count);
		})
	};

	const download = (idx) => {
		CommonAPI.downloadMarketDocument(idx);
		getCompanyInfo()
	};

	const deleteDoc = (idx, index) => {
		CommonAPI.deleteMarketDocument(idx).then(() => {
			let copy = _.cloneDeep(tbody);
			copy.items.splice(index, 1);
			copy.total_count -= 1;
			setTbody(copy);
		});
	};

	const getCompanyInfo = () => {
		CommonAPI.getCompanyInfo().then(res => {
			const data = res.data;
			setDownloadCnt(data.mi_download_cnt);
		});
	}


	return (
		<div className="marketInfoEditList">
			<div className="marketInfoEditList_content">
				<div className="header">
					<h2>시장 정보 작성 리스트</h2>
					<div className="download_cnt">잔여 다운로드 <span>{downloadCnt}</span>회</div>
				</div>

				<div className="table-box-wrap">
					<div className="wrap_list" id="scrollableDiv">
						<InfiniteScroll
							dataLength={tbody.length}
							next={nextList}
							hasMore={hasMore}
							scrollableTarget="scrollableDiv">
							<table>
								<thead>
								<tr>
									<th>순번</th>
									<th>분류</th>
									<th>제목</th>
									<th>작성날짜</th>
									<th>불러오기</th>
									<th>다운로드</th>
									<th>삭제</th>
								</tr>
								</thead>
								<tbody>
								{
									tbody.items?.map((item, idx) => {
										return (
											<tr key={item.idx}>
												<td>{tbody.total_count - idx}</td>
												<td>{item.category2_name}</td>
												<td>{item.title}</td>
												<td>{window.$Global.convertDate(item.created_at)}</td>
												<td>
													<Link to={`edit?idx=${item.idx}`} className="btn_load">불러오기</Link>
												</td>
												<td>
													<button className="icon_download ir_txt" onClick={() => download(item.idx)}>다운로드</button>
												</td>
												<td>
													<button className="icon_badge_del ir_txt" onClick={() => deleteDoc(item.idx, idx)}>삭제</button>
												</td>
											</tr>
										)
									})
								}
								</tbody>
							</table>
						</InfiniteScroll>
					</div>
				</div>
			</div>
		</div>
	)
}

export default MarketInfoEditList;