import React from "react";
import "./css/DocTradeMyTransactionPurchaseRow.scss";
import TemplateAPI from "../../API/template";

function DocTradeMyTransactionPurchaseRow({data}) {
    let items = data.items;

    const download = (idx) => {
        TemplateAPI.download(idx);
    };

    return (
        <>
            {
                items.length
                ?
                    items.map((item, idx) => {
                        return (
                            <tr key={idx} className="docTradeMyTransactionPurchaseRow">
                                <td className="num-info">{data.total_count - idx}</td>
                                <td className="transaction-info">
                                    <p className="transaction-info_text">{item.title}</p>
                                </td>
                                <td className="price-info">~{window.$Global.convertDate(item.expiration_at)}</td>
                                <td className="date-info">{window.$Global.convertDate(item.created_at)}</td>
                                <td className="detail-info">
                                    <button className="icon_download" onClick={() => download(item.idx)}/>
                                </td>
                            </tr>
                        )
                    })
                : window.$Global.notTd(7, "구매 내역이 없습니다")
            }
        </>
    )
}

export default DocTradeMyTransactionPurchaseRow;