import React from "react";
import "./css/SignupType.scss";
import SignupTypeFindBIz from "./findbiz/SignupType";
import SignupTypeIpnow from "./ipnow/SignupTypeIpnow";
import config from "../../const/config";
import { isView } from "core-js/internals/array-buffer-view-core";
import { useLocation } from "react-router-dom";

function SignupType() {
  const location = useLocation();
  const isViewTypeIpnow = window.$Global.isIpnow(location);

  return (
    <div id="SignupType">
      {isViewTypeIpnow ? <SignupTypeIpnow /> : <SignupTypeFindBIz />}
    </div>
  );
}

export default SignupType;
