import React from "react";
import MyPageIpnowAccountEdit from "./ipnow/MyPageIpnowAccountEdit";
import MyPageContainer from "./MyPageContainer";

function MyInfo() {
    const isViewTypeIpnow = window.$Global.isIpnow();
    return (
        <div id="MyInfo">
            {
                isViewTypeIpnow
                ? <MyPageIpnowAccountEdit/>
                : <MyPageContainer/>
            }
        </div>
    );
}

export default MyInfo;
