import API from "../../util/api";

export default {
    login(payload) {
        return API.post('/marketer/login', payload);
    },

    signup(payload) {
        return API.post('/marketer/sign_up', payload);
    },

    getCompanyRegister(params) {
        return API.get(`/marketer/company/regist`, {params: params});
    },

    getMyPoint(params) {
        return API.get(`/marketer/mypage/point`, {params: params});
    },

    getNotify(params) {
        return API.get(`/marketer/mypage/notification`, {params: params});
    },

    getBankInfo() {
        return API.get('/marketer/mypage/bank_info');
    },

    updateBankInfo(payload) {
        return API.patch('/marketer/mypage/bank_info', payload);
    },

    searchCompany(keyword) {
        return API.get('/marketer/company', {params: {keyword: keyword}});
    },

    addCompany(payload) {
        return API.post('/marketer/company', payload);
    },

    registCompany(company_idx) {
        return API.post(`/marketer/company/${company_idx}/regist`);
    }
}