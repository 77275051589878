import React, { useContext, useState } from "react";
import "./css/SearchInterested.scss";
import InfoCompanyAPI from "../../API/infoCompany";
import PopupGrade from "./PopupGrade";
import memberContext from "../../store/Member";
import SearchInterestedForm from "./SearchInterestedForm";

function SearchInterested(props) {
  const { all } = props;
  console.log(props);
  const input_ref = React.useRef();
  const [keyword, setKeyword] = useState("");
  const [keyword_list, setKeywordList] = useState("");
  const [keyword_list_show, setKeywordListShow] = useState(false);
  const [mouse, setMouse] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [popupShow, setPopupShow] = useState(false);
  const { auth } = useContext(memberContext);
  const isCompany = window.$Global.checkAuth("특허회사", auth);

  return (
    <>
      <div id="SearchInterested" className="wrap_search_company">
        <div className="search_txt">
          <p className="page_title">
            알고싶은 기업·경쟁기업의 기술등급 알아보기
          </p>
          <p className="second_title">종합·정량·정성평가</p>
          <p className="semi_title">
            <span>기술역량 등급</span> 및 <span>등록특허 등급</span> 제공
          </p>
        </div>
        <SearchInterestedForm />
      </div>
      {popupShow && (
        <PopupGrade
          companyName={companyName}
          companyCode={companyCode}
          onClickClose={() => setPopupShow(false)}
          all={all}
        />
      )}
    </>
  );
}

export default SearchInterested;
