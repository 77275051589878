import React, { useContext, useEffect, useState } from "react";
import "../css/AdminMailingTemplate.scss";
import AdminAPI from "../../../API/admin";
import MemberContext from "../../../store/Member";
import { Link } from "react-router-dom";
import config from "../../../const/config";
import ChartTest from "./ChartTest";
import ChartInput from "./ChartInput";

function AdminMailingTemplate({ history, company_code, isAll }) {
  const { admin } = useContext(MemberContext);
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (company_code) {
      if (admin) {
        AdminAPI.getMailing(company_code).then((res) => {
          setInfo(res.data);
        });
      } else {
        AdminAPI.getMailingUser(company_code).then((res) => setInfo(res.data));
      }
    }
  }, [company_code]);

  if (window.$Global.isEmptyObject(info)) return null;

  const annual_apply_cnt = Object.keys(info.annual_apply_cnt);
  const annual_register_cnt = Object.keys(info.annual_register_cnt);

  const onClickIPNOW = () => {
    history.push("/");
    sessionStorage.setItem(config.introSessionKey, "ipnow");
  };

  const onClickFindBiz = () => {
    history.push("/");
    sessionStorage.removeItem(config.introSessionKey);
  };

  return (
    <div id="AdminMailingTemplate">
      <div className={`mail ${isAll ? "" : "ipnowPage"}`}>
        <div className="mail_section-first" onClick={onClickIPNOW}>
          <span className="mail_section-first_top">WWW.IPNOW.CO.KR</span>
          <span className="mail_section-first_sub-title">
            편리한 기업 IP 자산관리/수익화 및 정부 공공 사업 정보 찾기
          </span>
          <h1 className="mail_section-first_title">
            {info.company_name}
            <em className="style-font_r"> 의</em>
            <br />
            <em className="style-font_b">향후 10년간 예상 연차료</em>는
            <br />
            <em className="style-font_b">{info.annual_pay_ten_year}</em>
            원이며, 절감이 필요합니다.
          </h1>
          <p className="mail_section-first_text">
            <span className="total-only">
              {info.company_name}의 <em className="style">IP정보현황</em>과{" "}
              <em className="style">개별특허등급</em> /{" "}
              <em className="style">기업기술등급</em>을 확인해 보세요.
              <br />
              <em className="style">정부,공공사업 정보 확인</em>
              <em>
                (Bizinfo, Smtech, NTIS, 조달청 포함{" "}
                <em className="style">1500여개 기관</em> 사업 정리)
              </em>
            </span>
            <span className="ipnow-only" style={{ textAlign: "center" }}>
              {info.company_name}의 <em className="style">IP정보현황</em>과{" "}
              <em className="style">개별특허등급</em>,{" "}
              <em className="style">기업기술등급</em>을 한눈에 파악하고
              <br />
              비전문가도 쉽게 관리 및 수익화 할수 있습니다.
            </span>
          </p>
          <div className="mail_section-first_box-first box-style">
            <h2 className="mail_section-first_box-first_title">
              {info.company_name}의 공개된 IP 정보 현황
            </h2>
            <p className="mail_section-first_box-first_sub-title">
              공개된 IP정보 기준으로 작성된 그래프 입니다.
            </p>
            <div className="mail_section-first_box-first_img-wrap">
              <div className="mail_section-first_box-first_img">
                <img
                  src={
                    require("../../../assets/images/mailling/bg_box_1_1.svg")
                      .default
                  }
                  alt="특허 차트 이미지 예시"
                />
                <em className="total">{info.patent_statistic.all}</em>
                <em className="register">
                  {info.patent_statistic.register_cnt}건
                </em>
                <em className="apply">{info.patent_statistic.apply_cnt}건</em>
                <em className="renounce">
                  {info.patent_statistic.give_up_cnt}건
                </em>
              </div>
              <div className="mail_section-first_box-first_img">
                <img
                  src={
                    require("../../../assets/images/mailling/bg_box_1_2.svg")
                      .default
                  }
                  alt="상표 차트 이미지 예시"
                />
                <em className="total">{info.trademark_statistic.all}</em>
                <em className="register">
                  {info.trademark_statistic.register_cnt}건
                </em>
                <em className="apply">
                  {info.trademark_statistic.apply_cnt}건
                </em>
                <em className="renounce">
                  {info.trademark_statistic.give_up_cnt}건
                </em>
              </div>
              <div className="mail_section-first_box-first_img">
                <img
                  src={
                    require("../../../assets/images/mailling/bg_box_1_3.svg")
                      .default
                  }
                  alt="예상 연차료 차트 이미지 예시"
                />
                <em className="year_1">{info.annual_pay[0].year}년</em>
                <em className="val_1">
                  {window.$Global.commaify(info.annual_pay[0].cost)}
                </em>
                <em className="year_2">{info.annual_pay[1].year}년</em>
                <em className="val_2">
                  {window.$Global.commaify(info.annual_pay[1].cost)}
                </em>
                <em className="year_3">{info.annual_pay[2].year}년</em>
                <em className="val_3">
                  {window.$Global.commaify(info.annual_pay[2].cost)}
                </em>
                <em className="year_4">{info.annual_pay[3].year}년</em>
                <em className="val_4">
                  {window.$Global.commaify(info.annual_pay[3].cost)}
                </em>
              </div>
              <div className="mail_section-first_box-first_img">
                <img
                  src={
                    require("../../../assets/images/mailling/bg_box_1_4.svg")
                      .default
                  }
                  alt="연도별 데이터 차트 이미지 예시"
                />
                <em className="year_1">{annual_register_cnt[0].substr(2)}년</em>
                <em className="val_1_1">
                  {info.annual_apply_cnt[annual_apply_cnt[0]].cnt}
                </em>
                <em className="val_1_2">
                  {info.annual_register_cnt[annual_register_cnt[0]].cnt}
                </em>
                <em className="year_2">{annual_register_cnt[1].substr(2)}년</em>
                <em className="val_2_1">
                  {info.annual_apply_cnt[annual_apply_cnt[1]].cnt}
                </em>
                <em className="val_2_2">
                  {info.annual_register_cnt[annual_register_cnt[1]].cnt}
                </em>
                <em className="year_3">{annual_register_cnt[2].substr(2)}년</em>
                <em className="val_3_1">
                  {info.annual_apply_cnt[annual_apply_cnt[2]].cnt}
                </em>
                <em className="val_3_2">
                  {info.annual_register_cnt[annual_register_cnt[2]].cnt}
                </em>
                <em className="year_4">{annual_register_cnt[3].substr(2)}년</em>
                <em className="val_4_1">
                  {info.annual_apply_cnt[annual_apply_cnt[3]].cnt}
                </em>
                <em className="val_4_2">
                  {info.annual_register_cnt[annual_register_cnt[3]].cnt}
                </em>
                <em className="year_5">{annual_register_cnt[4].substr(2)}년</em>
                <em className="val_5_1">
                  {info.annual_apply_cnt[annual_apply_cnt[4]].cnt}
                </em>
                <em className="val_5_2">
                  {info.annual_register_cnt[annual_register_cnt[4]].cnt}
                </em>
              </div>
            </div>
            <p className="mail_section-first_box-first_subtext">
              정확하고 상세한 자료는 가입을 하셔야 합니다.
            </p>
          </div>
          <div className="mail_section-first_box-second box-style">
            <p className="mail_section-first_box-second_title">
              귀사는
              <em className="style-font">종합평가</em>
              <em className="ico_ss">{info.grade_info.total}</em>
              <em className="style-font">, 정량평가</em>
              <em className="ico_s">{info.grade_info.quantity}</em>
              <em className="style-font">, 정성평가</em>
              <em className="ico_a">{info.grade_info.quality}</em> 등급 입니다.
            </p>
            <div className="mail_section-first_box-second_img">
              <img
                src={
                  require("../../../assets/images/mailling/ico_box_3.svg")
                    .default
                }
                alt="종합평가 차트 예시 이미지"
              />
            </div>
            <p className="mail_section-first_box-second_subtext">
              정확하고 상세한 자료는 가입을 하셔야 합니다.
            </p>
          </div>
          <div className="mail_section-first_text-box">
            <p className="mail_section-first_text-box_text">
              다양한 기능을 아이피나우에서 확인해 보세요
            </p>
            <Link to="/" className="mail_section-first_text-box_btn ir_txt">
              아이피나우 바로가기
            </Link>
          </div>
        </div>
        <div className="mail_section_second" onClick={onClickIPNOW}>
          <div className="mail_section_second_text">
            <p className="mail_section_second_sub-title">
              기업 성공의 열쇠, 소중한 특허를 엑셀로 방치 하십니까?
              <br />
              전용 플랫폼으로 수익화 하시겠습니까
            </p>
            <h1 className="mail_section_second_title">
              <em className="dot">돈</em> <em className="dot">버</em>
              <em className="dot">는</em> 통합 플랫폼
              <img
                src={
                  require("../../../assets/images/mailling/logo_ipnow.svg")
                    .default
                }
                alt="ipnow"
                className="mail_section_second_ttitle_logo"
              />
            </h1>
            <p className="mail_section_second_box-title">
              <em className="style-font">지식&amp;무형자산</em> 벨류 업, 수익화,
              경쟁사 분석 통합 플랫폼
            </p>
          </div>
          <div className="mail_section_second_box box-style">
            <h2 className="mail_section_second_box_title">
              아이피나우&nbsp;&nbsp;<span className="style-font"></span>
            </h2>
            <ul className="mail_section_second_box_list-wrap">
              <li className="mail_section_second_box_list">
                <em className="style-font_b">회사</em>{" "}
                <em className="style-font_eb">보유 IP 자동 확인 및 관리</em>{" "}
                <em className="style-font_b">
                  &#40;수작업 NO, 엑셀 관리 NO, 전문가 NO&#41;
                </em>
              </li>
              <li className="mail_section_second_box_list">
                특허 등급,기술역량 비교 진단{" "}
                <em className="style-font_b">
                  &#40;SMART5, 아이피나우 특허분석평가시스템&#41;
                </em>
              </li>
              <li className="mail_section_second_box_list">
                <em className="style-font_eb">연도별 예상 연차료</em>
                <em className="style-font_smallb">&#40;특허 유지비&#41;</em>
                <em className="style-font_eb">출원.등록 데이터 확인</em>
              </li>
              <li className="mail_section_second_box_list">
                지식&무형자산 투자유치{" "}
                <em className="style-font_b">
                  의 기회 &#40;담보대출, 무료 미국출원, 등록 및 분배&#41;
                </em>
              </li>
            </ul>
          </div>
          <ul className="mail_section_second_list-wrap">
            <li className="mail_section_second_list">
              지식&무형자산 <em className="style-font">가치창출, 분석 통합</em>{" "}
              플랫폼
            </li>
            <li className="mail_section_second_list">
              <em className="style-font">전문가 없이 쉽게</em> 이용할 수 있는
              플랫폼
            </li>
            <li className="mail_section_second_list">
              <em className="style-font">기업 293,574개, 개인 1,564,617명</em>{" "}
              IP <em className="style-font">DB 보유</em> 및 정보 제공&#40;21.09
              기준&#41;
            </li>
          </ul>
        </div>
        <div className="mail_section_third total-only" onClick={onClickFindBiz}>
          <div className="mail_section_third_title">
            <div className="mail_section_third_title_text">
              <em className="style-font_1">아이피나우</em>가 제공하는
              <br />
              <em className="style-font_2">또 다른 서비스</em>
            </div>
            <div className="mail_section_third_title_title-wrap">
              <img
                src={
                  require("../../../assets/images/mailling/logo_findbiz.svg")
                    .default
                }
                alt="FindBiz"
                className="mail_section_third_title_img"
              />
              <p className="mail_section_third_title_subtitle">비즈내비</p>
              <img
                className="mail_section_third_title_humImg"
                src={
                  require("../../../assets/images/mailling/bg_box_4.png")
                    .default
                }
                alt="사람 이미지"
              />
            </div>
          </div>
          <div className="mail_section_third_content">
            <div className="mail_section_third_content_title">
              쉽게 파악 가능한 정리된 사업 정보 제공
              <em className="style-font">찾는 사업 여기 다 있다</em>
              <button className="mail_section_third_content_title_link">
                정부사업의 모든 것 <em className="style-font">비즈내비</em>
              </button>
            </div>
            <div className="mail_section_third_content_img">
              <img
                src={
                  require("../../../assets/images/mailling/bg_box_2.png")
                    .default
                }
                alt="비즈내비 차트 예시 이미지"
              />
            </div>
            <div className="mail_section_third_content_text">
              <p className="mail_section_third_content_text_sub">
                연 25만건 이상의{" "}
                <em className="style-font">
                  정부&#183;공공기관&#183;지지체사업
                </em>
                를<br />
                한곳에서 빠르게 확인할 수 있는{" "}
                <em className="style-font">사업 찾기 서비스</em>
              </p>
              <p className="mail_section_third_content_text_title">
                사업 <em className="style-font">사업별 요약본 제공</em> 및 조건
                설정을 통한{" "}
                <em className="style-font">회사 맞춤 사업 자동 알림</em>
              </p>
            </div>
            <div className="mail_section_third_content_box-list">
              <div className="mail_section_third_content_box-list_img">
                <img
                  src={
                    require("../../../assets/images/mailling/bg_box_3.png")
                      .default
                  }
                  alt="이미지"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<table style={{"width": "630px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*    <tbody style={{"width": "100%", margin: "0 auto"}}>*/}
      {/*    <tr style={{"height": "55px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "55px", "height": "100%", "background-color": "#FEF6E5", "padding": "0", "text-align": "center"}}>*/}
      {/*            <a className="mail_link" href="https://www.ipnow.co.kr" target="_blank"*/}
      {/*               style={{"font-family:emoji;margin:23px 32px 40px auto;box-sizing:border-box;border:0;display:block;background:url(https://d1k4pifav5a1we.cloudfront.net/mailing/ico_btn_1.png) no-repeat 50% 50%;background-size:cover;width": "221px", "min-width": "221px", "max-height": "221px", "height": "33px", "min-height": "33px", "max-height": "33px", "text-indent": "-9999px"}}>*/}
      {/*                www.ipnow.co.kr*/}
      {/*            </a>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "55px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background-color": "#FEF6E5", "padding":0, "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_1_0_modi.png) no-repeat 50% 50%", "background-size": "cover"}}></td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "45px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background-color": "#FEF6E5", "padding": "0", "text-align": "center", "font-size": "30px", "color": "#626260", "font-weight": "700", "font-family": "emoji"}}>#company_name의*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "45px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_1_1.png) no-repeat 50% 50%", "background-size": "cover", "padding": "0"}}>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "55px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background-color": "#FEF6E5", "padding": "0", "text-align": "center", "font-size": "40px", "color": "#F0AB00", "font-weight": "700", "font-family": "emoji", "padding-bottom": "10px", "box-sizing": "border-box"}}>*/}
      {/*            #annual_cost_ten_year*/}
      {/*            <span style={{"font-size": "30px", "color": "#626260", "font-weight": "700", "font-family": "emoji"}}>입니다.</span>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "60px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background-color": "#FEF6E5", "padding": "30px 0 0", "text-align": "center", "font-size": "16px", "color": "#626261", "font-weight": "700", "font-family": "emoji", "margin": "0 auto", "padding-left": "16px"}}>*/}
      {/*            <span style={{"float": "left", "display": "block"}}>&middot #company_name</span>*/}
      {/*            <span*/}
      {/*                style={{"position": "relative", "float": "left", "display": "block", "width": "406px", "height": "18px", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/txt_3.png)", "top": "3px"}}></span>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"height": "120px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_1_2.png) no-repeat 50% 50%", "background-size": "cover", "padding": "0"}}>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"min-height": "40px"}}>*/}
      {/*        <td colSpan="2" style={{"padding": "0", "min-height": "40px", "background-color": "#FEF6E5"}}>*/}
      {/*		/!*<span*!/*/}
      {/*        /!*    style={{"display": "block", "width": "566px", "min-height": "40px", "margin": "0 auto", "background-color":"#F8F8F8", "font-family": "emoji", "font-size": "21px", "font-weight": "700", "color": "#604D30", "padding-left": "23px", "box-sizing": "border-box", "overflow": "hidden", "text-overflow": "ellipsis", "display": "-webkit-box", "-webkit-line-clamp": "2", "-webkit-box-orient": "vertical", "word-wrap": "break-word"}}>*!/*/}
      {/*		/!*	#company_name의 공개된 IP 정보 현황*!/*/}
      {/*		/!*</span>*!/*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "209px"}}>*/}
      {/*        <td*/}
      {/*            style={{"width": "315px", "height": "209px", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_2_1.png) no-repeat 50% 50%", "background-size": "cover", "padding": "0"}}>*/}
      {/*            <table*/}
      {/*                style={{"width": "100%", "height": "209px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*                <colgroup>*/}
      {/*                    <col width="66px"/>*/}
      {/*                        <col width="*"/>*/}
      {/*                            <col width="*"/>*/}
      {/*                </colgroup>*/}
      {/*                <tr>*/}
      {/*                    <td rowSpan="5" style={{"padding": "0"}}></td>*/}
      {/*                    <td colSpan="2" style={{"height": "78px", "padding": "0"}}></td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "20px"}}></td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "20px", "text-align": "center", "color": "#FF9A2A", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "38px", "padding-left": "34px", "box-sizing": "border-box"}}>*/}
      {/*                        #patent_register_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "42px", "text-align": "center", "color": "#fff", "font-size": "22px", "font-weight": "700", "font-family": "emoji", "padding-right": "48px", "padding-left": "54px", "box-sizing": "border-box"}}>*/}
      {/*                        #patent_all_cnt*/}
      {/*                    </td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "42px", "text-align": "center", "color": "#FF9A2A", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "34px", "padding-left": "36px", "box-sizing": "border-box"}}>*/}
      {/*                        #patent_apply_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "20px"}}></td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "20px", "text-align": "center", "color": "#fff", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "34px", "padding-left": "36px", "box-sizing": "border-box"}}>*/}
      {/*                        #patent_give_up_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2" style={{"height": "49px", "padding": "0"}}></td>*/}
      {/*                </tr>*/}
      {/*            </table>*/}
      {/*        </td>*/}
      {/*        <td*/}
      {/*            style={{"width": "315px", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_2_2.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*            <table*/}
      {/*                style={{"width": "100%", "height": "209px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*                <colgroup>*/}
      {/*                    <col width="*"/>*/}
      {/*                        <col width="*"/>*/}
      {/*                            <col width="66px"/>*/}
      {/*                </colgroup>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2" style={{"height": "78px", "padding": "0"}}></td>*/}
      {/*                    <td rowSpan="5" style={{"padding": "0"}}></td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "20px"}}></td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "20px", "text-align": "center", "color": "#FF9A2A", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "31px", "padding-left": "41px", "box-sizing": "border-box"}}>*/}
      {/*                        #trademark_register_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "42px", "text-align": "center", "color": "#fff", "font-size": "22px", "font-weight": "700", "font-family": "emoji", "padding-right": "41px", "padding-left": "59px", "box-sizing": "border-box"}}>*/}
      {/*                        #trademark_all_cnt*/}
      {/*                    </td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "42px", "text-align": "center", "color": "#FF9A2A", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "27px", "padding-left": "43px", "box-sizing": "border-box"}}>*/}
      {/*                        #trademark_apply_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"padding": "0", "height": "20px"}}></td>*/}
      {/*                    <td style={{"padding": "0", "width": "95px", "height": "20px", "text-align": "center", "color": "#fff", "font-size": "12px", "font-weight": "700", "font-family": "emoji", "padding-right": "27px", "padding-left": "43px", "box-sizing": "border-box"}}>*/}
      {/*                        #trademark_give_up_cnt*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2" style={{"height": "49px", "padding": "0"}}></td>*/}
      {/*                </tr>*/}
      {/*            </table>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "218px"}}>*/}
      {/*        <td*/}
      {/*            style={{"width": "315px", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_3_1.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*            <table*/}
      {/*                style={{"width": "100%", "height": "209px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*                <colgroup>*/}
      {/*                    <col width="66px"/>*/}
      {/*                        <col width="*"/>*/}
      {/*                            <col width="*"/>*/}
      {/*                                <col width="*"/>*/}
      {/*                                    <col width="*"/>*/}
      {/*                </colgroup>*/}
      {/*                <tr>*/}
      {/*                    <td rowSpan="5" style={{"padding": "0"}}></td>*/}
      {/*                    <td colSpan="4" style={{"padding": "0", "height": "40px"}}></td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2" style={{"padding": "0", "width": "123.5px", "height": "30px"}}></td>*/}
      {/*                    <td colSpan="2"*/}
      {/*                        style={{"padding": "0", "width": "123.5px", "height": "30px", "text-align": "center", "padding-right": "67px", "box-sizing": "border-box", "font-size": "11px", "font-weight": "700"}}>*/}
      {/*                        #cost3*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2"*/}
      {/*                        style={{"padding": "0", "width": "123.5px", "height": "55px", "text-align": "center", "padding-left": "65px", "padding-top": "10px", "box-sizing": "border-box", "font-size": "11px", "font-weight": "700"}}>*/}
      {/*                        #cost2*/}
      {/*                    </td>*/}
      {/*                    <td colSpan="2"*/}
      {/*                        style={{"padding": "0", "width": "123.5px", "height": "55px", "text-align": "center", "padding-left": "50px", "box-sizing": "border-box", "color": "#fff", "font-size": "11px", "font-weight": "700"}}>*/}
      {/*                        #cost4*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="2"*/}
      {/*                        style={{"padding": "0", "width": "123.5px", "height": "54px", "text-align": "center", "padding-right": "48px", "padding-top": "9px", "box-sizing": "border-box", "font-size": "11px", "font-weight": "700"}}>*/}
      {/*                        #cost1*/}
      {/*                    </td>*/}
      {/*                    <td colSpan="2" style={{"padding": "0", "width": "123.5px", "height": "54px"}}></td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"padding": "0", "width": "61.75px", "height": "30px", "text-align": "center", "font-size": "11px", "font-weight": "700", "box-sizing": "border-box", "padding-left": "12px"}}>*/}
      {/*                        #year1&#45380;*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"padding": "0", "width": "61.75px", "height": "30px", "text-align": "center", "font-size": "11px", "font-weight": "700", "box-sizing": "border-box", "padding-left": "3px"}}>*/}
      {/*                        #year2&#45380;*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"padding": "0", "width": "61.75px", "height": "30px", "text-align": "center", "font-size": "11px", "font-weight": "700", "box-sizing": "border-box", "padding-right": "3px"}}>*/}
      {/*                        #year3&#45380;*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"padding": "0", "width": "61.75px", "height": "30px", "text-align": "center", "font-size": "11px", "font-weight": "700", "box-sizing": "border-box", "padding-right": "12px"}}>*/}
      {/*                        #year4&#45380;*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*            </table>*/}
      {/*        </td>*/}
      {/*        <td*/}
      {/*            style={{"width": "315px", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_3_2.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*            <table*/}
      {/*                style={{"width": "100%", "height": "209px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*                <colgroup>*/}
      {/*                    <col width="*"/>*/}
      {/*                        <col width="*"/>*/}
      {/*                            <col width="*"/>*/}
      {/*                                <col width="*"/>*/}
      {/*                                    <col width="*"/>*/}
      {/*                                        <col width="66px"/>*/}
      {/*                </colgroup>*/}
      {/*                <tbody>*/}
      {/*                <tr>*/}
      {/*                    <td colSpan="5" style={{"padding": "0"}}></td>*/}
      {/*                    <td rowSpan="1" style={{"padding": "0"}}></td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-left": "22px", "padding-top": "20px"}}>*/}
      {/*                        #apply_cnt1*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-top": "30px", "padding-right": "2px"}}>*/}
      {/*                        #apply_cnt5*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "35px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "35px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "35px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "35px", "padding-top": "10px"}}>*/}
      {/*                        #apply_cnt4*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "35px"}}>*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-left": "22px", "padding-bottom": "25px"}}>*/}
      {/*                        #register_cnt1*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-left": "35px"}}>*/}
      {/*                        #register_cnt2*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-left": "20px", "padding-top": "10px"}}>*/}
      {/*                        #register_cnt3*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-top": "5px"}}>*/}
      {/*                        #register_cnt4*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "50px", "padding-bottom": "25px"}}>*/}
      {/*                        #register_cnt5*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px", "padding-left": "25px", "padding-bottom": "5px"}}>*/}
      {/*                        #apply_cnt2*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px", "padding-left": "10px", "padding-top": "5px"}}>*/}
      {/*                        #apply_cnt3*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px"}}>*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px"}}>*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                <tr>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px", "padding-left": "22px"}}>*/}
      {/*                        #year_line1년*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px", "padding-left": "20px"}}>*/}
      {/*                        #year_line2년*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px", "padding-left": "10px"}}>*/}
      {/*                        #year_line3년*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px"}}>*/}
      {/*                        #year_line4년*/}
      {/*                    </td>*/}
      {/*                    <td*/}
      {/*                        style={{"box-sizing": "border-box", "font-size": "11px", "font-weight": "700", "padding": "0", "width": "49.39px", "text-align": "center", "height": "23px"}}>*/}
      {/*                        #year_line5년*/}
      {/*                    </td>*/}
      {/*                </tr>*/}
      {/*                </tbody>*/}
      {/*            </table>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "72px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_4.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "90px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_5.png) no-repeat 50% 50%", "background-size": "cover", "padding": "0"}}>*/}
      {/*            <table*/}
      {/*                style={{"width": "100%", "height": "90px", "padding": "0", "margin:0 auto;border-collapse": "collapse", "border-spacing": "0"}}>*/}
      {/*                <tbody>*/}
      {/*                <colgroup>*/}
      {/*                    <col width="224px"/>*/}
      {/*                        <col width="114px"/>*/}
      {/*                            <col width="114px"/>*/}
      {/*                                <col width="*"/>*/}
      {/*                </colgroup>*/}
      {/*                <tr>*/}
      {/*                    <td style={{"text-align": "right", "padding": "30px 0 0 192px"}}>*/}
      {/*						<span*/}
      {/*                            style={{"display": "block", padding:0, margin:0, width:32, height:32, "background": "url(#total_grade_url) no-repeat 50% 50%"}}>*/}
      {/*						</span>*/}
      {/*                    </td>*/}
      {/*                    <td style={{ "text-align": "right", "padding": "30px 0 0 82px"}}>*/}
      {/*						<span*/}
      {/*                            style={{"display": "block", padding:0, margin:0, width:32, height:32, "background": "url(#total_grade_url) no-repeat 50% 50%"}}>*/}
      {/*						</span>*/}
      {/*                    </td>*/}
      {/*                    <td style={{"text-align": "right", "padding": "30px 0 0 82px"}}>*/}
      {/*						<span*/}
      {/*                            style={{"display": "block", padding:0, margin:0, width:32, height:32, "background": "url(#total_grade_url) no-repeat 50% 50%"}}>*/}
      {/*						</span>*/}
      {/*                    </td>*/}
      {/*                    <td></td>*/}
      {/*                </tr>*/}
      {/*                </tbody>*/}
      {/*            </table>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "341px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_6.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "137px"}}>*/}
      {/*        <td colSpan="2" style={{"width": "100%", "height": "137px"}}>*/}
      {/*            <a className="mail_link" href="https://www.ipnow.co.kr" target="_blank"*/}
      {/*               style={{"margin" : "29px auto 34px", "box-sizing" : "border-box", "display": "block", "min-width": "303px", "min-height": "72px", "width": "303px", "height": "72px", "max-width": "303px", "max-height": "303px", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/ico_btn.png) no-repeat 50% 50%", "text-indent": "-9999px"}}>*/}
      {/*                아이피나우 바로가기*/}
      {/*            </a>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    <tr style={{"width": "100%", "height": "2110px"}}>*/}
      {/*        <td colSpan="2"*/}
      {/*            style={{"width": "100%", "height": "100%", "background": "url(https://d1k4pifav5a1we.cloudfront.net/mailing/bg_img_2.png) no-repeat 50% 50%", "background-size": "cover"}}>*/}
      {/*        </td>*/}
      {/*    </tr>*/}
      {/*    </tbody>*/}
      {/*</table>*/}
    </div>
  );
}

export default AdminMailingTemplate;
