import React, {useState} from "react";
import './MailRefuse.scss';
import MailingAPI from "../../API/mailing/index";


import deny_mail_image from "../../assets/images/mailling/deny/deny_mail_image.svg";
import deny_button from "../../assets/images/mailling/deny/deny_button.svg";

function MailDeny() {
    const target = new URLSearchParams(window.location.search).get('target');
    const payload = {idx: target};

    async function onClickEmailRefuse () {
        let result = await MailingAPI.refuse(payload);
        result = result.data;

        if(result === true){
            alert('수신 거부가 완료되었습니다.');
        }else if(result.reject === 1){
            alert(`${result.reject_at.year}년 ${result.reject_at.month}월 ${result.reject_at.dat}일 ${result.reject_at.hour}시 ${result.reject_at.minute}분 ${result.reject_at.second}초에 이미 수신거부 완료되었습니다.`)
        }
    }

    return (
        <div id="MailDeny" className="mail_deny">
            <div className={"image_div"}>
                <img src={deny_mail_image} alt={"메일 수신거부 이미지"}
                     className={"mail_image"}
                />
            </div>
            <div className={"text_content"}>
                <p className={"s1"}>이메일을 수신거부 하시겠습니까?</p>
                <p className={"s2"}>메일 수신을 원치 않으시면 [메일 수신거부 하기]를 클릭해주세요.</p>
            </div>

            <div className={"button_div"}>
                <img className={"deny_button"} src={deny_button} onClick={onClickEmailRefuse}
                     alt={"이메일 수신거부 버튼"}
                />
            </div>

        </div>
    );
}

export default MailDeny;
