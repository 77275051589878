import React, {useState, useEffect} from "react";
import ContentTableList from "../common/ContentTableList";
import CommonAPI from "../../API/common/index";
import qs from "query-string";
import commonFunc from "../admin/commonFunc/common";

function CommunityListView({history, location}) {
    const {category} = qs.parse(location.search);
    const [categoryList, setCategoryList] = useState([]);
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [data, setData] = useState({});
    const count = 100;

    useEffect(() => {
        let payload = {
            page: 1,
            count: count,
            category: Number(category)
        };
        getList(payload);

        commonFunc.convertCommunityCategory().then(res => setCategoryList(res));
    }, []);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    const getList = (payload, type) => {
        CommonAPI.geCommunityList(payload).then(res => {
            let data = res.data;

            if (type == "next") {
                data.items = data.items.concat(data.items);
            }
            setData(data);

            if (data.page == data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const nextList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
        };
        setCurPage(curPage + 1);
        getList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            return (
                <tr key={index} onClick={() => onClickInfo(item.idx)}>
                    <td>{data.total_count - index}</td>
                    <td>
                        <div className="content">
                            {/*<img src={item.thumbnail_key ? `${window.$Global.getCDN()}${item.thumbnail_key}` : require('../../assets/images/common/img_news.png').default} alt="이미지"/>*/}
                            <h2>{item.title}</h2>
                        </div>
                    </td>
                    <td>{window.$Global.convertDate(item.created_at)}</td>
                </tr>
            );
        }));
    };

    const onClickInfo = (idx) => {
        history.push(`/community/detail/${idx}?category=${category}`);
    };

    return (
        <ContentTableList title={commonFunc.convertCommunityCategoryNo(categoryList, category)} data={data.items || []} content={tbody} drawList={nextList} hasMore={hasMore}/>
    );
}

export default CommunityListView;
