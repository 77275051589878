import {ApprovalState} from "../interface";

export function getApprovalStateToStr(state) {
	switch (Number(state)) {
		case ApprovalState.awaitCode:
			return ApprovalState.awaitStr;
		case ApprovalState.approvalCode:
			return ApprovalState.approvalStr;
		case ApprovalState.rejectCode:
			return ApprovalState.rejectStr;
	}
}