import API from "../../util/api";

export default {
  getList(params) {
    return API.get(`/common/consulting`, { params: params });
  },
  getCategoryList() {
    return API.get(`/common/consulting/category`);
  },
  getInfo(consulting_idx) {
    return API.get(`/admin/consulting/${consulting_idx}`);
  },

  getInfoUser(consulting_idx) {
    return API.get(`/common/consulting/${consulting_idx}`);
  },

  download(consulting_idx, introduce_idx) {
    window.$Global.download(
      `/common/consulting/${consulting_idx}/introduce/${introduce_idx}/download`
    );
  },

  deletePost(consulting_idx) {
    return API.delete(`/admin/consulting/${consulting_idx}`);
  },

  addPost(payload) {
    return API.post(`/admin/consulting`, payload);
  },

  updatePost(consulting_idx, payload) {
    return API.patch(`/admin/consulting/${consulting_idx}`, payload);
  },

  updateIntroduceFile(consulting_idx, payload) {
    return API.post(`/admin/consulting/${consulting_idx}/introduce`, payload);
  },

  deleteIntroduceFile(consulting_idx, introduce_idx) {
    return API.delete(
      `/admin/consulting/${consulting_idx}/introduce/${introduce_idx}`
    );
  },
  updateConsultFavoriteItem(consulting_idx) {
    return API.post(`/common/consulting/${consulting_idx}/favorite`);
  },
};
