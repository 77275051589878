import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Pie } from "react-chartjs-2";
import WorldMap from "react-svg-worldmap";
import geo from "./geo";
import "./css/WorldMap.scss";

const WorldMapChart = ({ colors, worldData }) => {
  const [positions, setPositions] = useState({});
  const geoData = geo;

  const data = useMemo(
    () => (Array.isArray(worldData?.worldmap) ? worldData.worldmap : []),
    [worldData]
  );

  const styleFunction = (context) => ({
    stroke: "transparent",
    strokeWidth: 1,
    hover: null, // 호버 효과 제거
  });

  const chartDataDict = useMemo(() => {
    if (!Array.isArray(worldData?.chart)) return {};
    return worldData.chart.reduce((acc, item) => {
      acc[item.nation] = item.chartData;
      return acc;
    }, {});
  }, [worldData]);

  const calculateCentroid = useCallback((coordinates) => {
    let totalX = 0,
      totalY = 0,
      totalPoints = 0;
    coordinates.forEach((polygon) => {
      polygon.forEach((ring) => {
        ring.forEach(([x, y]) => {
          totalX += x;
          totalY += y;
          totalPoints++;
        });
      });
    });
    return {
      top: `${90 - totalY / totalPoints}%`,
      left: `${((totalX / totalPoints + 180) / 360) * 100}%`,
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(worldData?.chart)) {
      const countryCodes = data.map((d) => d.country);
      let newPositions = {};
      geoData.features.forEach((feature) => {
        const { I: countryCode, C: coordinates } = feature;
        if (countryCodes.includes(countryCode)) {
          newPositions[countryCode] = calculateCentroid(coordinates);
        }
      });
      setPositions(newPositions);
    }
  }, [worldData, geoData, data, calculateCentroid]);

  const CustomChart = React.memo(({ countryName, style, worldChartData }) => {
    const memoizedWorldChartData = useMemo(
      () => worldChartData,
      [worldChartData]
    );

    return (
      <div className="chart-container" style={style}>
        <div>
          {/* style={{ width: "80px", height: "80px" }} */}
          <Pie
            data={memoizedWorldChartData}
            options={{
              maintainAspectRatio: false,
              legend: { display: false },
              tooltips: {
                callbacks: {
                  title: function (tooltipItems, data) {
                    return countryName;
                  },
                  label: function (tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const datasetLabel = data.labels[tooltipItem.index] || "";
                    const value = dataset.data[tooltipItem.index];
                    return `${datasetLabel}: ${window.$Global.commaify(
                      value
                    )}건`;
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  });

  const Legend = ({ chartData }) => {
    const labels = chartData.labels;
    const backgroundColors = chartData.datasets[0].backgroundColor;

    return (
      <div
        className="legend"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {labels.map((label, index) => (
          <div
            key={index}
            className="legend-item"
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <span
              className="legend-color"
              style={{
                backgroundColor: backgroundColors[index],
                width: "14px",
                height: "14px",
                borderRadius: "10px",
                display: "inline-block",
                marginRight: "4px",
              }}
            ></span>
            <span
              className="legend-label"
              style={{
                fontFamily: "Pretendard_light",
                fontSize: "14px",
                color: "#777",
              }}
            >
              {label}
            </span>
          </div>
        ))}
      </div>
    );
  };

  if (!worldData || !data.length || !Object.keys(chartDataDict).length) {
    return <div></div>;
  }

  return (
    <div
      id="WorldMap"
      style={{ position: "relative", width: "100%", height: "100%" }}
    >
      <WorldMap
        color={colors[0]}
        valueSuffix="건"
        size="md"
        data={data}
        tooltipTextFunction={() => ""}
        // styleFunction={styleFunction}
      />
      {Object.entries(positions).map(([countryCode, position]) => {
        const worldChartData = chartDataDict[countryCode];
        if (!worldChartData) return null;

        return (
          <CustomChart
            key={countryCode}
            countryName={countryCode}
            worldChartData={worldChartData}
            style={{
              position: "absolute",
              ...position,
              width: "80px",
              height: "80px",
              transform: "translate(-50%, -50%)",
            }}
          />
        );
      })}
      <Legend chartData={Object.values(chartDataDict)[0]} />
    </div>
  );
};

export default WorldMapChart;
