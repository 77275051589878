import React, { useContext, useRef } from "react";
import "../css/IntroFindBizPrice.scss";
import { Link, useHistory } from "react-router-dom";
import PaymentAPI from "../../../API/payment";
import memberContext from "../../../store/Member";

const { IMP } = window;
IMP.init("imp60657743");

function IntroFindBizPrice() {
  const { company, email, auth } = useContext(memberContext);
  const history = useHistory();
  const supportUrl = "https://forms.gle/Zncsy2wqeZzBv5N47";

  const priceRef = useRef(null);

  const showPrice = () => {
    priceRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const buyPro = () => {
    /*if (auth === undefined) {
      sessionStorage.setItem("historyURL", "/intro/price");
      history.push("/login");
    } else {
      const name = "모든 공공사업 ONE Click 찾기 FINDBIZ";
      const price = 132000;
      const add_payload = {
        findx: 1,
        findx_option: 3,
      };

      const data = {
        pg: "inicis",
        pay_method: "card",
        merchant_uid: `mid_${new Date().getTime()}`,
        amount: price,
        name: name,
        buyer_name: company,
        buyer_email: email,
      };

      IMP.request_pay(data, (response) => {
        const { success, merchant_uid, imp_uid, error_msg } = response;

        if (success) {
          let payload = {
            imp_uid: imp_uid,
            ...add_payload,
          };
          PaymentAPI.payment(payload).then((res) => {
            alert("결제가 완료되었습니다");
            history.push("/manage/price");
          });
        } else {
          alert(`결제 실패: ${error_msg}`);
        }
      });
    }*/
  };

  return (
    <div id="IntroFindBizPrice">
      <div className="intro_pc pc">
        <div className="top_banner_wrap">
          <img
            className="img"
            src={
              require("../../../assets/images/intro/new/pc/banner-00.png")
                .default
            }
            alt="이미지"
          />
        </div>
        <div className="notify_wh">
          <p>
            1500여 개 공공기관의 정부사업 공고, 제안서 작성을 위한 프리미엄
            정보, 최신 정보 콘텐츠 까지!
          </p>
        </div>

        <h2 className="page_title" ref={priceRef}>
          비즈내비 <br className="mo" />
          <span>멤버십 이용가격</span>
        </h2>
        <div className="notify_blue">
          <div className="sale">할인중</div>
          <div className="text">
            매월 커피 두 잔 가격으로 비즈니스를 위한 모든 정보를 받아보세요 !
          </div>
          <div className="coffee_img">
            <img
              src={
                require("../../../assets/images/common/coffee-icon.png").default
              }
              alt="이미지"
            />
          </div>
        </div>
        <div className="wrap_price_table_pc">
          <div className="price basic">
            <div className="top">
              <div className="wrap">베이직 BASIC</div>
              <img
                src={
                  require("../../../assets/images/common/icon/icon-eye.png")
                    .default
                }
                alt="이미지"
              />
            </div>

            <div className="middle">
              <div className="desc">무료 기업회원 가입하고</div>
              <div className="desc second">베이직 플랜 혜택을 누려보세요.</div>
            </div>

            <div className="bottom">
              <div className="amount">무료</div>
            </div>
          </div>

          <div className="price pro">
            <div className="top">
              <div className="wrap">
                <p>프로 Pro</p>
                <div className="additional pro">월 1만원!</div>
              </div>
              <img
                src={
                  require("../../../assets/images/common/icon/icon-good.png")
                    .default
                }
                alt="이미지"
              />
            </div>

            <div className="middle">
              <span className="desc pro">연 240,000 원</span>
              <span className="sale"> 50%할인</span>
            </div>

            <div className="bottom pro">
              <div className="amount_wrap">
                <span className="amount">120,000</span>
                <span className="won">원</span>
                <span className="year"> / 연간</span>
                <br />
                <span className="vat">VAT 10% 별도</span>
              </div>
              <Link to="/manage/price?pageType=payment">
                <button className="btn" onClick={buyPro}>
                  구매하기
                </button>
              </Link>
            </div>
          </div>
          <div className="price premium">
            <div className="top">
              <div className="wrap">
                <p>로켓 ROCKET</p>
                <div className="additional premium">Premium</div>
              </div>
              <div className="wrap1">
                <div className="etc">별도 협의</div>
                <img
                  src={
                    require("../../../assets/images/common/icon/icon-diamond.png")
                      .default
                  }
                  alt="이미지"
                />
              </div>
            </div>

            <div className="middle premium">
              <div className="desc">고객 기업에 딱 맞는 비즈내비만의</div>
              <div className="desc second">
                프리미엄 데이터 리포트를 제공합니다.
              </div>
            </div>

            <div className="bottom">
              <div className="amount_wrap">
                <span className="amount premium">프리미엄 리포트</span>
                <br />
                <span className="vat">문의 ipnow@ipnow.co.kr</span>
              </div>
              <button
                className="btn"
                onClick={() => window.open(supportUrl, "_blank")}
              >
                문의하기
              </button>
            </div>
          </div>
        </div>

        <div className="detail_desc">
          비즈내비 <span className="bold">플랜 상세 비교</span>
        </div>

        <div className="main_content">
          <img
            className="content"
            src={
              require("../../../assets/images/intro/new/pc/main-content.png")
                .default
            }
            alt="이미지"
          />
        </div>

        <Link to={"/signup/type"} className="btn_signup">
          지금 회원가입 하러 가기
        </Link>

        <div className="intro_wrap">
          <div className="intro_item">
            <img
              className="pc"
              src={
                require("../../../assets/images/intro/new/pc/intro1.png")
                  .default
              }
              alt="이미지"
            />
            <button onClick={showPrice} className="show_price">
              비즈내비 멤버십 구독하기
            </button>
          </div>
          <div className="intro_item">
            <img
              className="pc"
              src={
                require("../../../assets/images/intro/new/pc/intro2.png")
                  .default
              }
              alt="이미지"
            />
            <button onClick={showPrice} className="show_price">
              비즈내비 멤버십 구독하기
            </button>
          </div>
          <div className="intro_item">
            <img
              className="pc"
              src={
                require("../../../assets/images/intro/new/pc/intro3.png")
                  .default
              }
              alt="이미지"
            />
            <button onClick={showPrice} className="show_price">
              비즈내비 멤버십 구독하기
            </button>
          </div>
          <div className="intro_item">
            <img
              className="pc"
              src={
                require("../../../assets/images/intro/new/pc/intro4.png")
                  .default
              }
              alt="이미지"
            />
            <button onClick={showPrice} className="show_price">
              비즈내비 멤버십 구독하기
            </button>
          </div>
        </div>
      </div>

      <div className="intro mo">
        <h2 className="page_title">
          비즈내비 <br className="mo" />
          <span>멤버십 이용가격</span>
        </h2>
        <div className="notify">
          <p>지금 가입하면 2주가 무료!</p>
        </div>
        <div className="wrap_price_table">
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-price-12-mo.png").default
            }
            alt="이미지"
          />
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-price-6-mo.png").default
            }
            alt="이미지"
          />
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-price-1-mo.png").default
            }
            alt="이미지"
          />
        </div>

        <Link to={"/signup/type"} className="btn_signup">
          지금 회원가입 하러 가기
        </Link>

        <div className="subscription">
          <h2 className="title">
            비즈내비 <br className="mo" />
            <span>멤버십을 구독</span>하시면
          </h2>
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-subscription-1-mo.png")
                .default
            }
            alt="이미지"
          />
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-subscription-2-mo.png")
                .default
            }
            alt="이미지"
          />
          <img
            className="mo"
            src={
              require("../../../assets/images/intro/bg-subscription-4-mo.png")
                .default
            }
            alt="이미지"
          />

          <div className="desc">
            <p>
              · 이용권 구매 즉시 할인 가격이 적용되며,
              <br className="mo" /> 할인 기간이 종료된 이후부터는 정상가로
              결제됩니다.
            </p>
            <p>· 부가가치세(10%)가 별도로 부과됩니다.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroFindBizPrice;
