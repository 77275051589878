import React, { useContext } from "react";
import _ from "lodash";
import PatentContext from "../../../../store/Patent";

function ShareRow({ index, editing, inventor, patentData, setPatentData }) {
  let copy = _.cloneDeep(patentData);

  return (
    <div className="row">
      <h2 className="title">지분율</h2>
      {editing ? (
        <input
          type="number"
          value={inventor.share}
          style={{ width: 135 }}
          onChange={(e) => {
            copy.inventor[index].share = e.target.value;
            setPatentData(copy);
          }}
        />
      ) : (
        <p>{inventor.share}</p>
      )}
    </div>
  );
}

export default ShareRow;
