import React, {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router-dom';

import "./css/BillingView.scss";
import { useReactToPrint } from 'react-to-print';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import CostAPI from "../../API/cost/index";
import MemberContext from "../../store/Member";
import {patentCostTypeValue, patentCostTypeValueInfo} from "../../types/patentCostType";

function BillingView() {
    const {type, type_idx, idx} = useParams();
    const [billing, setBilling] = useState({});
    const [billingType, setBillingType] = useState("");
    const {auth, manage_company_idx} = useContext(MemberContext);
    const isPatent = type == "patent";
    const isTradeMark = type == "tradeMark";
    const isDesign = type == "design";
    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isAgent = window.$Global.checkAuth("대리인", auth);
    const isKr = billing.nation == 0;
    const isFor = billing.nation > 1;
    const isPCT = billing.nation == 1;
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(async () => {
        let result = {};
        if (isPatent) {
            if (isCompany) {
                result = await CostAPI.getPatentBilling(type_idx, idx).then(res => res.data).catch(error => console.log(error));
            } else if (isAgent) {
                result = await CostAPI.getAgentPatentBilling(manage_company_idx, type_idx, idx).then(res => res.data).catch(error => console.log(error));
            }

            let key;

            for(let k in patentCostTypeValue){
                if(patentCostTypeValue[k].indexOf(result.type.toString()) !== -1){
                    key = k;
                    break;
                }
            }

            setBillingType(key + '/' + patentCostTypeValueInfo[result.type.toString()]);
        } else if (isTradeMark) {
            if (isCompany) {
                result = await CostAPI.getTradeMarkBilling(type_idx, idx).then(res => res.data).catch(error => console.log(error));
            } else if (isAgent) {
                result = await CostAPI.getAgentTradeMarkBilling(manage_company_idx, type_idx, idx).then(res => res.data).catch(error => console.log(error));
            }
        } else if (isDesign) {
            if (isCompany) {
                result = await CostAPI.getDesignBilling(type_idx, idx).then(res => res.data).catch(error => console.log(error));
            } else if (isAgent) {
                result = await CostAPI.getDesignBillingAsAgent(manage_company_idx, type_idx, idx).then(res => res.data).catch(error => console.log(error));
            }
        }
        setBilling(result);
    }, [auth]);

    const download = () => {
        const divToDisplay = componentRef.current;
        html2canvas(divToDisplay).then(function(canvas) {
            const divImage = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                unit: "px",
                format: [550, 850]
            });
            pdf.addImage(divImage, 'PNG', 0, 0);
            pdf.save("청구서.pdf");
        });
    };

    const onClickDownload = (file_idx) => {
        if (isPatent) {
            CostAPI.downloadPatentCostFile(type_idx, idx, file_idx);
        } else if (isTradeMark) {
            CostAPI.downloadTradeMarkCostFile(type_idx, idx, file_idx);
        }
    };

    let tag = [];
    if (isPatent || isDesign) {
        if (isKr) {
            tag =
                <>
                    {
                        Boolean(billing.sum_commission) &&
                        <div className="row">
                            <div>
                                <p className="td">국내 출원 비용</p>
                                <p className="td">
                                    {window.$Global.commaify(billing.sum_commission)}
                                </p>
                            </div>

                        </div>
                    }
                    {
                        Boolean(billing.sum_commission) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">기본료</p>
                                <p className="td">{window.$Global.commaify(billing.basic_fee)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.total_claim_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">전체 청구항 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.total_claim_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.independent_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">독립항 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.independent_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.dependent_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">종속항 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.dependent_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.statement_page_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">명세서 페이지 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.statement_page_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.drawing_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">도면 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.drawing_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.time_charge_ec) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">Time Charge 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.time_charge_ec)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.gc) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">관납료</p>
                                <p className="td">{window.$Global.commaify(billing.gc)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.etc_fee) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">기타 비용</p>
                                <p className="td">{window.$Global.commaify(billing.etc_fee)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.work_hour * billing.work_hour_rate) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">Hourly Rate 가산금</p>
                                <p className="td">{window.$Global.commaify(billing.work_hour * billing.work_hour_rate)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.agent_commission) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">대리인 수수료</p>
                                <p className="td">{window.$Global.commaify(billing.agent_commission)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.commission_dc) &&
                        <div className="row">
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                            <div>
                                <p className="td">할인율(%)</p>
                                <p className="td">{window.$Global.commaify(billing.commission_dc)}</p>
                            </div>
                        </div>
                    }
                    {
                        Boolean(billing.surtax) &&
                        <div className="row border">
                            <div>
                                <p className="td">부가세</p>
                                <p className="td">{window.$Global.commaify(billing.surtax)}</p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                        </div>
                    }
                </>
        } else if (isFor) {
            tag.push(
                <>
                    {
                        Boolean(billing.sum_commission + billing.for_sum_commission) &&
                        <div className="row">
                            <div>
                                <p className="td">해외 출원 비용</p>
                                <p className="td">
                                    {window.$Global.commaify(billing.sum_commission + billing.for_sum_commission)}
                                </p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                        </div>
                    }
                </>
            );

            if (billing.type == 21) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">선행기술 조사비용</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 22) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.drawing_ec) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">도면작성료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.drawing_ec)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_translate_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">번역료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_translate_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 23) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">심사 청구비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">심사 청구비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 24) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">우선심사 신청비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">우선심사 신청비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type > 24 && billing.type < 28) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 28) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">개별국 진입비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">개별국 진입비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 29) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">OA대응 비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">OA대응 비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 30) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원 유지료(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원 유지료(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 31) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">IDS제출 비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">IDS제출 비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 32) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">자진보정 비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">자진보정 비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 33) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">등록 비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">등록 비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 34) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">기타 비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">기타 비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_remittance_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">해외송금 수수료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_remittance_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 35) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">기타 비용(국내대리인)(T/C)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type > 20 && billing.type < 36) {
                tag.push(
                    <>
                        {
                            Boolean(billing.for_gc) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">관납료</p>
                                    <p className="td">{window.$Global.commaify(billing.for_gc)}</p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            tag.push(
                <>
                    {
                        Boolean(billing.surtax + billing.for_surtax) &&
                        <div className="row border">
                            <div>
                                <p className="td">부가세</p>
                                <p className="td">{window.$Global.commaify(billing.surtax + billing.for_surtax)}</p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                        </div>
                    }
                </>
            );
        } else if (isPCT) {
            tag.push(
                <>
                    {
                        Boolean(billing.sum_commission + billing.for_sum_commission) &&
                        <div className="row">
                            <div>
                                <p className="td">PCT 출원 비용</p>
                                <p className="td">
                                    {window.$Global.commaify(billing.sum_commission + billing.for_sum_commission)}
                                </p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                        </div>
                    }
                </>
            );

            if (billing.type == 36) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">선행기술 조사비용</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            } else if (billing.type == 37) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                )
            } else if (billing.type == 38) {
                tag.push(
                    <>
                        {
                            Boolean(billing.basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(국내대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.for_basic_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">출원비용(해외대리인)</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_basic_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                )
            }
            if (billing.type == 37 || billing.type == 38) {
                tag.push(
                    <>
                        {
                            Boolean(billing.for_gc) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">관납료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_gc)}
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            Boolean(billing.drawing_ec) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">도면작성료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.drawing_ec)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                );
            }

            if (billing.type == 38) {
                tag.push(
                    <>
                        {
                            Boolean(billing.for_translate_fee) &&
                            <div className="row">
                                <div>
                                    <p className="td"/>
                                    <p className="td"/>
                                </div>
                                <div>
                                    <p className="td">번역료</p>
                                    <p className="td">
                                        {window.$Global.commaify(billing.for_translate_fee)}
                                    </p>
                                </div>
                            </div>
                        }
                    </>
                )
            }
            tag.push(
                <>
                    {
                        Boolean(billing.surtax + billing.for_surtax) &&
                        <div className="row border">
                            <div>
                                <p className="td">부가세</p>
                                <p className="td">{window.$Global.commaify(billing.surtax + billing.for_surtax)}</p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td"/>
                            </div>
                        </div>
                    }
                </>
            );
        }
    }

    if (window.$Global.isEmptyObject(billing)) return null;

    return (
        <div id="Wrap_BillingView">
            <div className="btns">
                <button className="btn_download" onClick={download}>PDF Download</button>
                <button className="btn_print" onClick={handlePrint}>프린트하기</button>
            </div>
            <div id="BillingView" ref={componentRef}>
                <div className="header">
                    <h2>청구서</h2>
                </div>
                <div className="agent_info">
                    <h2>{billing.agent_company_info[0]?.name}</h2>
                    <p>{billing.agent_company_info[0]?.location}</p>
                </div>
                <div className="wrap_data" style={{marginBottom: 60}}>
                    <div className="column">
                        <div className="row">
                            <p className="title">수신</p>
                            <p className="desc">{billing.company_info[0]?.name}</p>
                        </div>
                        <div className="row">
                            <p className="title">청구 일자</p>
                            <p className="desc">{window.$Global.convertDate(billing.real_claim_at)}</p>
                        </div>
                        <div className="row">
                            <p className="title">관리번호</p>
                            <p className="desc">{billing.manage_number}</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="row no_data"/>
                        <div className="row">
                            <p className="title">청구번호</p>
                            <p>{billing.billing_number}</p>
                        </div>
                        <div className="row">
                            <p className="title">대리인 관리번호</p>
                            <p className="desc">{billing.agent_manage_number}</p>
                        </div>
                    </div>
                </div>
                <div className="wrap_data">
                    <div className="column">
                        <div className="row">
                            <p className="title">출원번호</p>
                            <p className="desc">{billing.apply_number}</p>
                        </div>
                        <div className="row">
                            <p className="title">청구 내용</p>
                            <p className="desc">{billingType || "상표 출원"}</p>
                        </div>
                    </div>
                    {
                        isPatent &&
                        <div className="column">
                            <div className="row">
                                <p className="title">발명의 명칭</p>
                                <p className="desc">{billing.invention_name}</p>
                            </div>
                            <div className="row">
                                <p className="title">발명자</p>
                                <ul>
                                    {
                                        billing.patent.members.map((item, index) => {
                                            return <li key={index}>{item.name}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                </div>
                <div className="bill_info">
                    <div className="thead">
                        <div className="row">
                            <div>
                                <p className="th">청구내역</p>
                                <p className="th">청구금액</p>
                            </div>
                            <div>
                                <p className="th">세부내역</p>
                                <p className="th"/>
                            </div>
                        </div>
                    </div>
                    <div className="tbody">
                        {tag}
                        {
                            isTradeMark &&
                            <>
                                <div className="row">
                                    <div>
                                        <p className="td">국내 비용</p>
                                        <p className="td">
                                            {window.$Global.commaify(billing.domestic_fee)}
                                        </p>
                                    </div>
                                    {
                                        Boolean(billing.apply_basic_commission) &&
                                        <div>
                                            <p className="td">출원 기본 수수료</p>
                                            <p className="td">{window.$Global.commaify(billing.apply_basic_commission)}</p>
                                        </div>
                                    }
                                </div>
                                {
                                    Boolean(billing.apply_add_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">출원 추가 수수료</p>
                                            <p className="td">{window.$Global.commaify(billing.apply_add_commission)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.apply_basic_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">출원 관납료</p>
                                            <p className="td">{window.$Global.commaify(billing.apply_basic_fee)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.s_r_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">자기사용 등 법정요건</p>
                                            <p className="td">{window.$Global.commaify(billing.s_r_commission)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.s_r_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">자기사용 등 법정요건 관납료</p>
                                            <p className="td">{window.$Global.commaify(billing.s_r_fee)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.p_a_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">전문 조사기관</p>
                                            <p className="td">{window.$Global.commaify(billing.p_a_commission)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.p_a_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">전문 조사기관 관납료</p>
                                            <p className="td">{window.$Global.commaify(billing.p_a_fee)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.oa_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">거절이유대응 의견서/보정서</p>
                                            <p className="td">{window.$Global.commaify(billing.oa_commission)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.oa_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">거절이유대응 의견서/보정서 관납료</p>
                                            <p className="td">{window.$Global.commaify(billing.oa_fee)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.reg_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">등록수수료(성공보수금)</p>
                                            <p className="td">{window.$Global.commaify(billing.reg_commission)}</p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.reg_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">등록수수료 관납료</p>
                                            <p className="td">
                                                {window.$Global.commaify(billing.reg_fee)}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.renewal_basic_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">갱신 기본 수수료</p>
                                            <p className="td">
                                                {window.$Global.commaify(billing.renewal_basic_commission)}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.renewal_basic_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">갱신 기본 수수료 관납료</p>
                                            <p className="td">
                                                {window.$Global.commaify(billing.renewal_basic_fee)}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.renewal_add_commission) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">갱신 추가 수수료</p>
                                            <p className="td">
                                                {window.$Global.commaify(billing.renewal_add_commission)}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.renewal_add_fee) &&
                                    <div className="row">
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                        <div>
                                            <p className="td">갱신 추가 수수료 관납료</p>
                                            <p className="td">
                                                {window.$Global.commaify(billing.renewal_add_fee)}
                                            </p>
                                        </div>
                                    </div>
                                }
                                {
                                    Boolean(billing.surtax) &&
                                    <div className="row border">
                                        <div>
                                            <p className="td">부가세</p>
                                            <p className="td">{window.$Global.commaify(billing.surtax)}</p>
                                        </div>
                                        <div>
                                            <p className="td"/>
                                            <p className="td"/>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div className="tfoot">
                        <div className="row">
                            <div>
                                <p className="td">소계</p>
                                <p className="td">
                                    {
                                        window.$Global.commaify(
                                          !isTradeMark
                                                ? isKr ? billing.sum_total_domestic : billing.sum_total_domestic + billing.for_sum_total
                                                : (billing.domestic_fee + billing.surtax))
                                    }
                                </p>
                            </div>
                            <div>
                                <p className="td"/>
                                <p className="td">단위(KRW)</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    Boolean(billing.files.length) &&
                    <div className="wrap_data">
                        <div className="column">
                            <div className="row">
                                <p className="title">첨부파일</p>
                                <ul>
                                    {
                                        billing.files.map((item, index) => {
                                            return <li key={index} className="desc" onClick={() => onClickDownload(item.idx)}>{item.file_name}.{item.file_type}</li>;
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <div className="account">
                    계좌번호 : {billing.bank_name} {billing.account_number} (예금주 : {billing.account_owner})
                </div>
            </div>
        </div>
    )
}

export default BillingView;
