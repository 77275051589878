import React, {useEffect, useState} from "react";
import "./css/DocTradeSampleRegister.scss";
import DocTradeSampleRegisterFull from './DocTradeSampleRegisterFull';
import DocTradeSampleRegisterIndividual from './DocTradeSampleRegisterIndividual';
import TemplateAPI from "../../API/template";
import DocTradePopup from "./common/DocTradePopup";
import Terms from "./const/terms";
import qs from "query-string";

function DocTradeSampleRegister({location, history}) {
	const tab = qs.parse(location.search).tab || 0;

	const [terms, setTerms] = useState({title: '', desc: ''});
	const [popupShow, setPopupShow] = useState(false);
	const [category, setCategory] = useState([]);

	const isIndividual = tab == 0;
	const isMultiple = tab == 1;

	useEffect(() => {
		TemplateAPI.getCategory().then(res => setCategory(res.data));
	}, []);

	const onClickPopupShow = (key) => {
		setTerms(Terms[key]);
		setPopupShow(true);
	};

	return (
		<>
			<div id="docTradeSampleRegister">
				<div className="sample-register">
					<h3 className="sample-register_title">샘플 등록하기</h3>
					<div className="sample-register_nav-wrap">
						<ul className="sample-register_nav">
							<li className={`sample-register_list ${isIndividual ? 'active' : ''}`} onClick={() => history.push('?tab=0')}>
								<button className="sample-register_list_link">자료 개별 등록</button>
							</li>
							<li className={`sample-register_list ${isMultiple ? 'active' : ''}`} onClick={() => history.push('?tab=1')}>
								<button className="sample-register_list_link">자료 대량 등록</button>
							</li>
						</ul>
						<span className="sample-register_highlight emphasis">필수 입력항목입니다.</span>
					</div>
				</div>
			</div>
			{
				isIndividual && <DocTradeSampleRegisterIndividual history={history} category={category} onClickPopupShow={onClickPopupShow}/>
			}
			{
				isMultiple && <DocTradeSampleRegisterFull history={history} category={category} onClickPopupShow={onClickPopupShow}/>
			}
			{
				popupShow && <DocTradePopup terms={terms} onClose={() => setPopupShow(false)}/>
			}
		</>
	)
}

export default DocTradeSampleRegister;