import { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AdminIntegrationTaskCategory = () => {
  const [categoryList, setCategoryList] = useState([]);
  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = () => {
    AdminAPI.getTaskDepartmentList({ count: 100 }).then((res) => {
      setCategoryList(res.data.items);
    });
  };

  const handleChange = (result: any) => {
    if (!result.destination) return;
    const items = [...categoryList];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCategoryList(items);
  };

  return (
    <div>
      <div>
        <p>카테고리 추가</p>
        <input type="text" />
        <button>추가</button>
      </div>
      <div>
        <h2>카테고리</h2>
        <table>
          <thead>
            <tr>
              <th>게시순서</th>
              <th>카테고리명</th>
              <th>관리</th>
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleChange}>
            <Droppable droppableId="categoryList">
              {(provided) => (
                <tbody
                  className="categoryList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {categoryList.map((item, idx) => {
                    return (
                      <Draggable
                        key={idx}
                        draggableId={`test-${idx}`}
                        index={idx}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            isDragging={snapshot.isDragging}
                          >
                            <td>{idx + 1}</td>
                            <td>{item.name}</td>
                            <td>
                              <div {...provided.dragHandleProps}>이동</div>
                              <button>삭제</button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </div>
    </div>
  );
};

export default AdminIntegrationTaskCategory;
