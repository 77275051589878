import "./css/TaskNotifyNotLogin.scss";
import { useHistory } from "react-router-dom";

const TaskNotifyNotLogin = () => {
  const history = useHistory();

  return (
    <div id="TaskNotifyNotLogin">
      <div className="mo">
        <div className="notify_text">
          <p>가입만 해도 무료!</p>
          <p>관심 정부 과제</p>
          <p>
            실시간 메일<span>로</span>
          </p>
          <p>모두 받아보세요.</p>
        </div>
        <div className="notify_message">
          <div>
            <i className="icon_mail_gray" />
            <p>
              (주)아이피나우님,
              <br />
              <span>2023년 자율주행 기술개발 혁신 사업</span>
              공고가 도착했습니다.
            </p>
          </div>
          <div>
            <i className="icon_mail_gray" />
            <p>
              (주)아이피나우님,
              <br />
              <span>자율주행 배송로봇 개발용역</span>
              공고가 도착했습니다.
            </p>
          </div>
          <div>
            <i className="icon_mail_gray" />
            <p>
              (주)아이피나우님,
              <br />
              <span>자율주행 운송관리 플랫폼 구축 용역</span>
              공고가 도착했습니다.
            </p>
          </div>
        </div>
        <img
          src={
            require("../../assets/images/taskManage/img-mail-notify.png")
              .default
          }
        />
        <button onClick={() => history.push("/signup/type")}>
          회원가입 하기
        </button>
      </div>
      <div className="pc">
        <div className="notify_top">
          <div className="notify_text">
            <span>가입만 해도 무료!</span>
            <span>관심 정부 과제</span>
            <span>
              실시간 메일<span>로</span>
            </span>
            <span>모두 받아보세요.</span>
            <button onClick={() => history.push("/signup/type")}>
              회원가입 하기
            </button>
          </div>
          <img
            src={
              require("../../assets/images/taskManage/img-mail-notify.png")
                .default
            }
          />
        </div>
        <div className="notify_message">
          <p>
            <i className="icon_mail_gray" />
            (주)아이피나우님, <span>2023년 자율주행 기술개발 혁신 사업</span>
            공고가 도착했습니다.
          </p>
          <p>
            <i className="icon_mail_gray" />
            (주)아이피나우님, <span>자율주행 배송로봇 개발용역</span>
            공고가 도착했습니다.
          </p>
          <p>
            <i className="icon_mail_gray" />
            (주)아이피나우님, <span>자율주행 운송관리 플랫폼 구축 용역</span>
            공고가 도착했습니다.
          </p>
          <p style={{ opacity: 0.5 }}>
            <i className="icon_mail_gray" />
            (주)아이피나우님, <span>자율주행기술개발혁신(R&D)사업</span>
            공고가 도착했습니다.
          </p>
          <p style={{ opacity: 0.2 }}>
            <i className="icon_mail_gray" />
            (주)아이피나우님, <span>자율주행 시뮬레이션</span>
            공고가 도착했습니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaskNotifyNotLogin;
