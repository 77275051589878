export default {
    downloadTaskFile(payload) {
        window.$Global.newDownload(`test/dataVoucher/companyChart`, payload);
    },
    downloadTaskExcel(payload) {
        window.$Global.newDownload(`test/dataVoucher/excel`, payload);
    },

    downloadTaskWord(payload) {
        window.$Global.newDownload(`test/dataVoucher/chartTask`, payload);
    },
    downloadMiWord(payload) {
        window.$Global.newDownload(`test/dataVoucher/chartMi`, payload);
    },
    downloadResearchWord(payload) {
        window.$Global.newDownload(`test/dataVoucher/chartResearch`, payload);
    },

    async asyncDownload(url, payload) {
        await window.$Global.fileDownloadSync(url, payload);
    }

};
