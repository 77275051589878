import React from "react";

let tradeMarkFileType = React.createContext({
  survey: "",
  apply: "",
  oa_content: "",
  oa_opinion: "",
  oa_apply: "",
  oa_declare: "",
  oa_declare_extension: "",
  objection_content: "",
  objection_opinion: "",
  regist: "",
  reject: "",
  expiration: "",
  renewal: "",
  renew: "",
  renew_split: "",
  image: "",
  fp_image: "",
  drop: "",
  drop1: "",
  drop2: "",
  giveup: "",
  giveup1: "",
  giveup2: "",
  split: "",
  history: "",
  coexistence: "",
  registration: "",
  international: "",
  // 분쟁 파일 리스트
  correction: "",
  judge: "",
  objection: "",
  dispute_giveup: "",
  // 거절결정
  reject_exam_file1: "",
  reject_exam_file2: "",
  reject_exam_file3: "",
  refusal_decision_file1: "",
  refusal_decision_file2: "",
  refusal_decision_file3: "",
  trial_decision_patent_file1: "",
  trial_decision_patent_file2: "",
  trial_decision_patent_file3: "",
  trial_decision_supreme_file1: "",
  trial_decision_supreme_file2: "",
  is_case_file1: "",
});

export default tradeMarkFileType;
