import React, {useState, useEffect} from "react";
import "./css/SignUpCheck.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import AdminAPI from "../../API/admin/index";

function SignUpCheck({history}) {
    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [data, setData] = useState({});
    const [type, setType] = useState(0);
    const tableName = type == 0 ? "기업·대학·연구소" : type == 1 ? "특허사무소" : "투자자";

    useEffect(() => {
        const payload = {
            page: 1,
            count: count,
            type: type
        };
        setCurPage(1);
        getList(payload);
    }, [type]);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data]);

    const getList = (payload, type) => {
        AdminAPI.getSignInfoList(payload).then(res => {
            let get_data = res.data;
            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
            }
            setData(get_data);

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const nextInvestorList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
            type: type
        };
        setCurPage(curPage + 1);
        getList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            return (
                <tr>
                    <td>{data.items.length - index}</td>
                    <td>{item.name}</td>
                    <td>{item.license_number}</td>
                    <td>{item.representative}</td>
                    <td>{window.$Global.convertDate(item.birth_at)}</td>
                    <td>{item.company_phone}</td>
                    <td>{item.phone}</td>
                    <td>{item.id}</td>
                    <td>{item.email}</td>
                    <td>
                        <button className="btn_delete" onClick={() => onClickStop(item.idx)}>정지</button>
                    </td>
                    {/*<td>*/}
                    {/*    <button className="btn_delete" onClick={() => onClickDelete(item.idx)}>탈퇴</button>*/}
                    {/*</td>*/}
                </tr>
            );
        }));
    };

    const onClickStop = (idx) => {
        if (window.confirm("정말로 탈퇴시키겠습니까?")) {
            AdminAPI.stopMember(idx).then(() => {
                alert("탈퇴처리되었습니다");
                history.go(0);
            });
        }
    };

    const onClickDelete = (idx) => {
        if (window.confirm("정말로 탈퇴시키겠습니까?")) {
            AdminAPI.deleteMember(idx).then(() => {
                alert("탈퇴처리되었습니다");
                history.go(0);
            });
        }
    };

    const onClickChangeType = (type) => {
        setType(type);
    };

    return (
        <div id="SignUpCheck">
            <div className="header">
                <h2>가입자확인</h2>
                <ul className="tabs">
                    <li onClick={() => onClickChangeType(0)}>기업·대학·연구소</li>
                    <li onClick={() => onClickChangeType(1)}>특허사무소</li>
                    <li onClick={() => onClickChangeType(2)}>투자자</li>
                </ul>
            </div>

            <div className="wrap_table_title">
                <h2 className="table_title">{tableName}</h2>
                <button className="btn_download" onClick={() => AdminAPI.downloadJoDal()}>조달청 Excel 다운</button>
                <button className="btn_download" onClick={() => AdminAPI.downloadSignup()}>가입자 Excel 다운</button>
                <button className="btn_download" onClick={() => AdminAPI.downloadMarket()}>시장정보 작성 요청 다운</button>
            </div>
            <div className="table-box-wrap">
                <div id="scrollableDiv" className="wrap_list">
                    <InfiniteScroll
                        dataLength={data.length ? data.items.length : 5}
                        next={nextInvestorList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table>
                            <thead>
                                <tr>
                                    <th>가입수</th>
                                    <th>회사명</th>
                                    <th>사업자등록번호</th>
                                    <th>대표자</th>
                                    <th>설립일</th>
                                    <th>회사 전화번호</th>
                                    <th>담당자번호</th>
                                    <th>아이디</th>
                                    <th>이메일</th>
                                    <th>정지처리</th>
                                    {/*<th>탈퇴처리</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

export default SignUpCheck;
