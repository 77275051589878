import React, { useContext, useEffect, useState } from "react";
import "./css/DocTradeSamplePurchase.scss";
import {Link, useParams} from "react-router-dom";
import TemplateAPI from "../../API/template";
import CommonAPI from "../../API/common";
import Member from "../../store/Member";

const { IMP } = window;
function DocTradeSamplePurchase({history}) {
    const {idx} = useParams();
    const [ amount, setAmount ] = useState(0);
    const [usePoint, setUsePoint] = useState(0);
    const [selectPoint, setSelectPoint] = useState(0);
    const [template, setTemplate] = useState({
        preview: []
    });

    const [dcAmount, setDcAmount ] = useState(0);

    const sumPrice = parseInt(template.price*1.1);
    const [ finalPrice, setFinalPrice ] = useState(sumPrice - selectPoint - dcAmount);

    const [ findBizPermission, setFindBizPermission ] = useState(0);

    useEffect(() => {
        setFinalPrice(parseInt(template.price*1.1) - selectPoint - dcAmount);
    }, [ dcAmount, selectPoint, template ]);

    useEffect(() => {
        CommonAPI.getCompanyInfo().then(res => {
            setFindBizPermission(res.data.findx_permission);

            setAmount(res.data.amount);

            if(res.data.findx_permission === 1){
                setDcAmount(sumPrice - parseInt(sumPrice*0.95));
            }
        });
    }, [ template ]);

    useEffect(() => {
        TemplateAPI.getTemplate(idx).then(res => {
            setTemplate(res.data);
        });

    }, []);

    const onChangePoint = e => {
        let value = window.$Global.numberRegx(e.target.value);

        if (value > finalPrice) {
            value = finalPrice;
        } else if (Number(amount) < Number(value)) {
            value = amount;
        }
        setUsePoint(value || 0);
    }

    const onClickPointUse = () => {
        setSelectPoint(usePoint);
    };

    const deletePointUse = () => {
        setSelectPoint(0);
        setUsePoint(0);
    }

    const PaymentAPI = (payload) => {
        TemplateAPI.payment(payload).then(() => {
            alert('결제가 완료되었습니다');
            history.push("/docTrade/my?tab=1");
        });
    };

    const onClickPayment = () => {
        if (!finalPrice) {
            let payload = {
                use_amount: usePoint,
                template_list: [template.idx]
            };
            PaymentAPI(payload);
        } else {
            const data = {
                pg: 'inicis',
                pay_method: 'card',
                merchant_uid: `mid_${new Date().getTime()}`,
                amount: finalPrice,
                name: template.title,
                // customer_uid : 'your-customer-unique-id', // 자동 결제
                buyer_name: "",
                buyer_email: "",
            };
            IMP.request_pay(data, callback);
        }
    }

    const callback = (response) => {
        const {
            success,
            imp_uid,
            error_msg,
        } = response;

        if (success) {
            let payload = {
                imp_uid: imp_uid,
                use_amount: usePoint,
                template_list: [template.idx]
            };
            PaymentAPI(payload);
        } else {
            alert(`결제 실패: ${error_msg}`);
        }
    }


    return (
        <>
            <div id="docTradeSamplePurchase">
                <div className="pc">
                    <div className="sample-purchase">
                        <h3 className="sample-purchase_title">구매하기</h3>
                        <h4 className="sample-purchase_sub-title">주문내역</h4>
                        <table className="sample-purchase_table">
                            <caption className="hidden">주문내역</caption>
                            <colgroup>
                                <col width="65px" />
                                <col width="680px" />
                                <col width="205px" />
                            </colgroup>
                            <thead className="sample-purchase_thead">
                                <tr className="sample-purchase_table_list-head">
                                    <th className="col">순&nbsp;&nbsp;번</th>
                                    <th className="col">자료명</th>
                                    <th className="col">구매 금액 <em className="style">&#40;VAT별도&#41;</em></th>
                                </tr>
                            </thead>
                            <tbody className="sample-purchase_tbody">
                                <tr className="sample-purchase_table_list-content">
                                    <td className="sample-purchase_table_list-content_num">
                                        <p className="num">1</p>
                                    </td>
                                    <td className="sample-purchase_table_list-content_content">
                                        <p className="img-wrap">
                                            <img className="img" src={window.$Global.getCDN(template.preview_key)} alt={template.title}/>
                                        </p>
                                        <p className="info-wrap">
                                            <dl className="info">
                                                <dt className="info_title">구매 상품 명</dt>
                                                <dd className="info_content">{template.title}</dd>
                                                <dt className="info_title">파일형식</dt>
                                                <dd className="info_format">{template.file_type}</dd>
                                            </dl>
                                        </p>
                                    </td>
                                    <td className="sample-purchase_table_list-content_price-wrap">
                                        <p className="sample-purchase_table_list-content_price">
                                            <span className="price">{window.$Global.commaify(template.price)}원</span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <dl className="sample-purchase_price">
                            <dt className="sample-purchase_price_title">
                                <span className="title">구입 금액</span>
                                <span className="title-sub">&#40;VAT 포함 10%&#41;</span>
                                <span className="refund">취소 환불 규정
                                    <Link to="/terms/payment" className="refund_link">자세히 알아보기</Link>
                                </span>
                            </dt>
                            <dd className="sample-purchase_price_info">
                                <span className="price">{window.$Global.commaify(sumPrice)}원</span>
                            </dd>
                        </dl>
                        <div className="sample-purchase_point">
                            <h3 className="sample-purchase_point_title">포인트 결제</h3>
                            <table className="sample-purchase_point_table">
                                <colgroup>
                                    <col width="96px" />
                                    <col width="537px" />
                                    <col width="97px" />
                                    <col width="" />
                                </colgroup>
                                <tbody>
                                    <tr className="sample-purchase_point_hold">
                                        <th>총 보유 포인트</th>
                                        <td colSpan="3">
                                            <div className="sample-purchase_point_input">
                                                <label htmlFor=""></label>
                                                <input type="text" value={window.$Global.commaify(amount) || 0} readOnly />원
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="sample-purchase_point_use">
                                        <th>사용포인트</th>
                                        <td>
                                            <div className="sample-purchase_point_input">
                                                <label htmlFor="point" className="hidden">사용할 포인트 입력</label>
                                                <input type="text" name="point" id="point" value={window.$Global.commaify(usePoint)} onChange={onChangePoint}/>원
                                            </div>
                                            <button type="button" className="use-btn" onClick={onClickPointUse}>사용 하기</button>
                                            {
                                                Boolean(selectPoint)
                                                &&
                                                <button className="remove-btn_link" onClick={deletePointUse}>사용 포인트 입력 내용 지우기</button>
                                            }
                                        </td>
                                        {
                                            Boolean(selectPoint)
                                            &&
                                            <>
                                                <th>포인트 차감 금액</th>
                                                <td className="style">-{window.$Global.commaify(selectPoint)}원</td>
                                            </>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {findBizPermission === 1 &&
                            <div className="membership_dc">
                                <div className="desc">
                                    멤버쉽 할인 금액
                                </div>
                                <div className="price">
                                    &nbsp;&nbsp;-{dcAmount}원
                                </div>
                            </div>
                        }
                        <dl className="sample-purchase_price">
                            <dt className="sample-purchase_price_title">
                                <span className="title">총 구입 금액</span>
                            </dt>
                            <dd className="sample-purchase_price_info">
                                <span className="price">{window.$Global.commaify(finalPrice)}원</span>
                            </dd>
                        </dl>
                        <div className="sample-purchase_btn-warp">
                            <div className="sample-purchase_btn-warp_prev">
                                <Link to={`../view/${idx}`} className="btn-prev">이&nbsp;&nbsp;전</Link>
                            </div>
                            <div className="sample-purchase_btn-warp_payment">
                                <button className="btn-payment" onClick={onClickPayment}>결&nbsp;&nbsp;제</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mo">
                    <div className="sample-purchase">
                        <h3 className="sample-purchase_title">
                            주문내역
                        </h3>
                        <table className="sample-purchase_table">
                            <caption className="hidden">주문내역</caption>
							<colgroup>
								<col width="50%" />
								<col width="16%" />
								<col width="34%" />
							</colgroup>
							<thead className="sample-purchase_table_head">
								<tr>
									<th>자료명</th>
									<th>파일형식</th>
									<th>구매 금액(VAT별도)</th>
								</tr>
							</thead>
							<tbody className="sample-purchase_table_body">
								<tr>
									<td>{template.title}</td>
									<td>{template.file_type}</td>
									<td>{window.$Global.commaify(template.price)}원</td>
								</tr>
							</tbody>
                        </table>
						<dl className="sample-purchase_price">
                            <dt className="sample-purchase_price_title">
                                <span className="title-wrap">
									<span className="title">구입 금액</span>
									<span className="title-sub">&#40;VAT 포함 10%&#41;</span>
								</span>
                                <span className="refund">
									취소 환불 규정
                                    <Link to="/terms/payment" className="refund_link">자세히 알아보기</Link>
                                </span>
                            </dt>
                            <dd className="sample-purchase_price_info">
                                <span className="price">{window.$Global.commaify(sumPrice)}원</span>
                            </dd>
                        </dl>
						<div className="sample-purchase_point">
                            <h3 className="sample-purchase_point_title">포인트 결제</h3>
                            <table className="sample-purchase_point_table">
                                <colgroup>
                                    <col width="25%" />
                                    <col width="75%" />
                                </colgroup>
                                <tbody>
                                    <tr className="sample-purchase_point_hold">
                                        <th scope="row">
                                            <label htmlFor="totalHoldPoint">총 보유 포인트</label>
                                        </th>
                                        <td>
                                            <div className="sample-purchase_point_input">
                                                <input type="text" value={window.$Global.commaify(amount) || 0} id="totalHoldPoint" readOnly />원
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="sample-purchase_point_use">
                                        <th scope="row">
                                            <label htmlFor="usePoint">사용포인트</label>
                                        </th>
                                        <td>
                                            <div className="sample-purchase_point_input">
                                                <input type="text" name="point" id="usePoint" value={window.$Global.commaify(usePoint)} onChange={onChangePoint}/>원
                                            </div>
                                            <button type="button" className="use-btn" onClick={onClickPointUse}>사용하기</button>
                                            {
                                                Boolean(selectPoint)
                                                &&
                                                <button className="remove-btn_link" onClick={deletePointUse}>사용 포인트 입력 내용 지우기</button>
                                            }
                                        </td>
                                        {
                                            Boolean(selectPoint)
                                            &&
                                            <>
                                                <th>포인트 차감 금액</th>
                                                <td className="style">-{window.$Global.commaify(selectPoint)}원</td>
                                            </>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {findBizPermission === 1 &&
                            <div className="membership_dc">
                                <div className="desc">
                                    멤버쉽 할인 금액
                                </div>
                                <div className="price">
                                    &nbsp;&nbsp;-{dcAmount}원
                                </div>
                            </div>
                        }
						<dl className="sample-purchase_price">
                            <dt className="sample-purchase_price_title">
                                <span className="title">총 구입 금액</span>
                            </dt>
                            <dd className="sample-purchase_price_info">
                                <span className="price">{window.$Global.commaify(finalPrice)}원</span>
                            </dd>
                        </dl>
                        <div className="sample-purchase_payment">
                            <button className="btn-payment" onClick={onClickPayment}>구매 하기</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocTradeSamplePurchase;