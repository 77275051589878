import React, {useRef, useState} from "react";
import statisticAPI from "../../API/statistic"
import './paymentHistory.scss';

const paymentHead = <tr>
    <th>구매자</th>
    <th>구매일자</th>
    <th>구분</th>
    <th>결제금액</th>
    <th> </th>
</tr>;

const templateHead = <tr>
    <th>구매자</th>
    <th>구매일자</th>
    <th>제안서 제목</th>
    <th>결제금액</th>
</tr>;

function PaymentHistory() {
    const [tBody, setTBody] = useState(<tr><td>정보 없음</td></tr>);
    const [tHead, setTHead] = useState(paymentHead);

    const [isPaymentHistory, setIsPaymentHistory] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [buyDate, setBuyDate] = useState(undefined);
    const [amount, setAmount] = useState(0);
    const [ipnowMonth, setIpnowMonth] = useState(0);
    const [findxMonth, setFindxMonth] = useState(0);
    const [code, setCode] = useState('');

    const codeRef = useRef(null);

    const showPaymentHistory = async () => {
        const result = (await statisticAPI.getStatisticPaymentHistory()).data;

        setTBody(result.map((v, i) => {
            const manual = v.manual === 1;

            return <tr key={i}>
                <td>{v.name}</td>
                <td>{v.paid_at}</td>
                <td>{v['구분']}</td>
                <td>{v.amount}</td>
                <td><button onClick={() => deletePaymentHistory(v.idx)} disabled={!manual}>삭제하기</button></td>
            </tr>
        }));

        setTHead(paymentHead);
        setIsPaymentHistory(true);
    }

    const showTemplateTradeHistory = async () => {
        const result = (await statisticAPI.getStatisticTemplateTradeHistory()).data;

        setTBody(result.map((v, i) => {
            return <tr key={i}>
                <td>{v.name}</td>
                <td>{v.paid_at}</td>
                <td>{v.template_name}</td>
                <td>{v.amount}</td>
            </tr>
        }));

        setTHead(templateHead);
        setIsPaymentHistory(false);
    }

    function deletePaymentHistory (idx) {
        const payload = {idx, code: codeRef.current.value};

        statisticAPI.deletePaymentHistory(payload)
            .then(response => {
                showPaymentHistory();
            })
    }

    function insertPaymentHistory () {
        if(companyName.replace(/\s/g, '') === ''){
            alert('회사명을 입력해주세요,');
            return;
        }

        if(!buyDate){
            alert('구매 일자를 입력해주세요');
            return;
        }

        if(!amount){
            alert('금액을 입력해주세요');
            return;
        }

        if(!ipnowMonth && !findxMonth){
            alert('구매한 상품의 개월수를 둘중 하나는 꼭 입력해야합니다.');
            return;
        }

        if(0>amount || 0>ipnowMonth || 0>findxMonth){
            alert('0 이하의 숫자를 입력할 수 없습니다.');
            return;
        }

        const payload = {
            companyName,
            buyDate: new Date(buyDate).getTime(),
            amount,
            ipnowMonth,
            findxMonth,
            code
        };

        statisticAPI.insertPaymentHistory(payload)
            .then(response => {
                console.log(response);
                showPaymentHistory();
            });
    }

    return (
        <div id="PaymentHistory">
            <div className="header_wrap">
                <div className="button_wrap">
                    <button onClick={showPaymentHistory}>결제 이력 보기</button>
                    <button onClick={showTemplateTradeHistory}>제안서 구매 이력 보기</button>
                </div>
                {
                    isPaymentHistory ?
                        <div className="input_wrap">
                            <div>
                                회사명
                                <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)}/>
                            </div>
                            <div>
                                구매일자
                                <input type="date" defaultValue={buyDate} onChange={(e) => setBuyDate(e.target.value)}/>
                            </div>
                            <div>
                                금액
                                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)}/>
                            </div>
                            <div>
                                Ipnow 개월수
                                <input type="number" value={ipnowMonth} onChange={(e) => setIpnowMonth(e.target.value)}/>
                            </div>
                            <div>
                                BizNavi 개월수
                                <input type="number" value={findxMonth} onChange={(e) => setFindxMonth(e.target.value)}/>
                            </div>
                            <div>
                                코드
                                <input type="text" ref={codeRef} value={code} onChange={(e) => setCode(e.target.value)}/>
                            </div>
                            <div>
                                <button onClick={insertPaymentHistory}>등록</button>
                            </div>
                        </div>
                        : ''
                }
            </div>
            <div className="table_wrap">
                <table>
                    <thead>
                        {tHead}
                    </thead>

                    <tbody>
                        {tBody}
                    </tbody>

                </table>
            </div>
        </div>
    )
}

export default PaymentHistory;
