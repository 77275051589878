const colors = [
  "#4E7CE9",
  "#A18CF7",
  "#33CBD0",
  "#FF9675",
  "#F2677C",
  "#FFD200",
  "#95B0F2",
  "#DCE5FB",
  "#D0C5FB",
  "#FEC1C1",
  "#FFB84F",
  "#B8CBF6",
];

export default colors;
