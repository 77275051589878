import {css} from "@emotion/react";

export const container = css`
  padding: 10px;
`;

export const newsWrap = css`
  border: 1px solid black;
  padding: 30px;
  margin-bottom: 30px;
`;

export const newsHeaderWrap = css`
    margin-bottom: 30px;
`;

export const newsTitleWrap = css`
  div {
    margin-top: 10px;
  }
`;

export const newsContentWrap = css`
  border: 1px solid black;
  padding: 30px;

  img {
    max-width: 1000px !important;
  }
`;
