import React from "react";
import "./css/CommonAttachPopup.scss";

function CommonAttachPopup({list, show, hideDownloadBtn, showDeleteBtn, fileRef, onClickDelete, onClickDownload, onClickClose}) {
    if (!show) return null;

    return (
        <div id="CommonAttachPopup">
            <div className="header">
                <h2 className="title">자료</h2>
                <div>
                    {
                        showDeleteBtn && <button className="btn_upload" onClick={() => fileRef.current.click()}>파일 선택</button>
                    }
                    <button className="icon_exit ir_txt" onClick={onClickClose}>닫기</button>
                </div>
            </div>
            <div className="wrap_table">
                <table>
                    <thead>
                        <tr>
                            <th width="8%">순번</th>
                            <th>파일명</th>
                            {
                                !hideDownloadBtn
                                &&
                                <th width="15%">다운로드</th>
                            }
                            {
                                showDeleteBtn
                                &&
                                <th width="10%">삭제</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list?.map((item, idx) => {
                                return (
                                    <tr>
                                        <td>{idx + 1}</td>
                                        <td>{item.file_name ? item.file_name + "." + item.file_type : item.name}</td>
                                        {
                                            !hideDownloadBtn
                                            &&
                                            <td>
                                                <button className="icon_download ir_txt"
                                                        onClick={() => onClickDownload(item.idx)}>파일 다운
                                                </button>
                                            </td>
                                        }
                                        {
                                            showDeleteBtn &&
                                            <td>
                                                <button className="icon_badge_del ir_txt" onClick={() => onClickDelete(item.idx, idx)}>파일 삭제</button>
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CommonAttachPopup;