import React from 'react';
import AnnualFeeChart from "../../annualFee/AnnualFeeChart";
import '../css/CalcAnnualFeePopup.scss'
import {HorizontalBar} from "react-chartjs-2";
import {Link} from "react-router-dom";

function CalcAnnualFeePopup({companyName, data, apply_at, patent, onClickClose}) {
    const token = window.$Global.getToken();
    const auth = token != null;

    let tag = [];
    let date = new Date().getFullYear();
    let labels = [date];

    if (patent) {
        tag =
            patent.items.map((item, idx) => {
                const grade = item.sm_grade || 'default';
                const maxIdx = Math.floor(patent.items.length/100)-1;

                return (
                    <tr className={auth === false && idx > maxIdx ? 'blur' : 'default'}>
                        <td>{patent.count - idx}</td>
                        <td>
                            <p className={`circle grade_${grade}`}>{item.sm_grade || 'N'}</p>
                        </td>
                        <td>{item.apply_number}</td>
                        <td>{item.register_number || "미등록"}</td>
                        <td className="pc">{item.invention_title}</td>
                    </tr>
                );
            });
    }

    for (let i = 1; i < 8; i++) {
        labels.push(date + i);
    }

    let data3 = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: [
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)',
                    'rgb(255, 166, 0)'
                ],
                borderWidth: 1,
            },
        ],
    };

    let options = {
        legend: {
            display: false
        },
        tooltips: { enabled: false },
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'end',
                font: {
                    size: 10,
                    weight: 'bold',
                    color: '#959595'
                },
                formatter: function(value) {
                    return window.$Global.commaify(value);
                }
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {
                    display : true,
                    zeroLineColor: '#ffffff',
                    borderDash: [6, 6],
                    borderDashOffset: 2,
                    drawBorder: false,
                }
            }],
            yAxes: [{
                ticks: {
                    display: true,
                    // max : 10,
                },
                gridLines: {
                    display : false,
                    zeroLineColor: '#ffffff',
                }
            }]
        }
    };

    return (
        <div id="CalcAnnualFeePopup">
            <div className="pc">
                <div className="header">
                    <h2>'{companyName}'의 연차료 그래프</h2>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
                <AnnualFeeChart arr_data={data} redirect={() => {}} apply_at={apply_at}/>
                <div className="wrap_table">
                    <div className="header">
                        <h2>'{companyName}'의 <span className="color_blue">공개</span> 특허·상표 리스트
                            {patent.count === patent.items.length ? ` ${patent.count}개` : ` ${patent.items.length}개 (총 ${patent.count}개)`}
                        </h2>
                        <p className="sub_txt">미공개건 미포함</p>
                    </div>
                    <div className="table_head">
                        <table>
                            <thead>
                                <tr>
                                    <th>건수</th>
                                    <th>Value</th>
                                    <th>출원번호</th>
                                    <th>등록번호</th>
                                    <th>발명의 명칭</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="table_body">
                        <table>
                            <tbody>
                                {tag}
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    auth === false &&
                    <>
                    <p className="hide_txt">회원가입 후 모든 특허의 등급, 등급 상세정보, 특허 정보 들을 확인하실 수 있습니다.</p>
                    <Link to="/signup/type" className="btn btn_signup">회원가입 하러가기<i className="icon_next"/></Link>
                    <p className="desc">지금 IPNOW에 가입하면 모든 기능을 2개월간 무료로 사용 가능합니다.</p>
                    </>
                }
            </div>

            <div className="mo">
                <div className="header">
                    <h2>{companyName}</h2>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
                <h2 className="title">연차료(유지비용) 그래프</h2>
                <div className="chart">
                    <HorizontalBar
                        data={data3}
                        options={options}
                    />
                </div>
                {
                    auth === false &&
                    <>
                    <div className="notify">
                        <i className="icon_alert_circle"/>
                        <p>회원가입하시면 더 다양한 정보를 확인하실 수 있습니다.</p>
                    </div>

                    <div className="wrap_table">
                        <h2 className="title">특허·상표 리스트</h2>
                        <Link to="/signup/type" className="hide">
                            <i className="icon_lock"/>
                            <p>회원가입하고 더보기</p>
                        </Link>
                        <table>
                            <thead>
                                <tr>
                                    <th>건수</th>
                                    <th>등급</th>
                                    <th>출원번호</th>
                                    <th>등록번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tag}
                            </tbody>
                        </table>
                    </div>
                </>
                }
            </div>
        </div>
    );
}

export default CalcAnnualFeePopup;
