import icon from "../../assets/images/businessPlan/button-information.svg";
import "./css/Icon.scss";
import { useState } from "react";

const FirstInfoText = ({ idx }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [infoDivContents, setInfoDivContents] = useState();

  const IdxZero = () => {
    return (
      <>
        <p>
          1. 보안등급: 법 제21조제2항에 따른 보안과제에 해당하는 경우 ‘보안’에,
          그 외의 경우 '일반’에 [√] 표시합니다(연구자 직접 기재 불필요).
        </p>
        <p>
          2. 중앙행정기관명: 연구개발과제를 공고한 중앙행정기관의 명칭을
          기재합니다(중앙행정기관이 복수인 경우에는 모든 해당 중앙행정기관의
          명칭).
        </p>
        <p>
          3. 전문기관명: 연구개발과제를 관리하는 전문기관명을 기재합니다(연구자
          직접 기재 불필요).
        </p>
        <p>
          4. 사업명: 해당 연구개발과제의 사업명을 기재합니다(연구자 직접 기재
          불필요).
        </p>
        <p>
          5. 내역사업명: 해당 연구개발과제의 내역사업명을 기재합니다(연구자 직접
          기재 불필요).
        </p>
        <p>
          6. 공고번호: 연구개발과제 공고문 상단의 공고번호를 기재합니다(연구자
          직접 기재 불필요).
        </p>
        <p>
          7. 총괄연구개발 식별번호: 총괄연구개발명에 부여되는 번호를
          기재합니다(연구자 직접 기재 불필요).
        </p>
        <p>
          8. 연구개발과제번호: 연구개발과제 선정 시 부여되는 번호를
          기재합니다(연구자 직접 기재 불필요).
        </p>
      </>
    );
  };
  const IdxOne = () => {
    return (
      <>
        <p>
          9. 선정방식: 공고문에서 제시한 선정방식을 기재합니다(연구자 직접 기재
          불필요).
        </p>
        <p>
          10. 국가과학기술표준분류: 「과학기술기본법」 제27조제1항에 따른
          국가과학기술표준분류표 중 연구개발과제에 해당하는 소분류를 우선순위에
          따라 그 코드명과 비중을 기재합니다.
        </p>
        <p>
          11. 부처기술분류: 중앙행정기관에서 소관 법령에 따라 입력을 요청하는
          과학기술분류 중 연구개발과제에 해당하는 소분류를 우선순위에 따라 그
          코드명과 비중을 기재합니다.
        </p>
      </>
    );
  };
  const IdxTwo = () => {
    return (
      <>
        <p>
          12. 총괄연구개발명: 2개 이상의 연구개발과제가 서로 연관되어 추진되는
          경우에 이를 총괄하는 연구개발 명칭을 기재합니다.
        </p>
      </>
    );
  };
  const IdxThree = () => {
    return (
      <>
        <p>
          13. 연구개발과제명: 연구개발기관이 수행하는 연구개발과제의 명칭을
          기재합니다.
        </p>
      </>
    );
  };
  const IdxFour = () => {
    return (
      <>
        <p>
          14. 연구개발기간: 연구개발과제가 단계로 구분되지 않는 경우에는
          연구개발기간 전체를 1단계로 간주합니다. <br />
          &emsp; 1) 전체: 연구개발과제의 전체 연구개발기간으로서 협약기간을
          기재합니다.
          <br />
          &emsp; 2) 단계: 연구개발과제가 단계로 구분된 경우에 해당 단계의
          연구개발기간을 기재합니다.
        </p>
      </>
    );
  };
  const IdxFive = () => {
    return (
      <>
        <p>
          15. 연구개발비: 연구개발과제가 단계로 구분되지 않는 경우에는
          연구개발기간 전체를 1단계로 간주합니다.
          <br />
          &emsp; 1) 정부지원연구개발비: 중앙행정기관이 지원하는 연구개발비를
          기재합니다.
          <br />
          &emsp; 2) 기관부담연구개발비: 시행령 제19조 및 시행령 [별표 1]에 따라
          <br />
          &emsp; 연구개발기관이 부담하는 연구개발비를 현금과 현물로 구분하여
          기재합니다.
          <br />
          &emsp; 3) 그 외 기관 등의 지원금: 1) 또는 2)에 해당하지 않는
          연구개발비를 지원하는 기관이거나,
          <br />
          &emsp; 연구개발성과를 활용ㆍ구매 등을 목적으로 하는 기관 등이 지원하는
          연구개발비로서 현금과 현물로 구분하여 기재합니다.
          <br />
          &emsp; 4) 연구개발비 외 지원금: 국제기구, 외국의 정부ㆍ기관ㆍ단체 등이
          지원ㆍ부담하는 금액이거나,
          <br />
          &emsp; 중앙행정기관(소속기관 포함)이 소관 업무를 위하여 직접 수행하는
          사업의 금액으로 <br />
          &emsp;「국가연구개발혁신법」에 따른 연구개발비에 포함하지 않는 금액을
          기재합니다.
        </p>
      </>
    );
  };
  const IdxSix = () => {
    return (
      <>
        <p>
          16. 공동연구개발기관의 역할
          <br />
          &emsp;1) 공동연구개발기관으로서 연구개발성과를 활용ㆍ구매 등을
          목적으로 하는 기업(수요기업)인 경우에 "수요"로 기재합니다.
          <br />
          &emsp;2) 공동연구개발기관이 수요기업이 아닌 경우에 "공동"으로
          기재합니다.
        </p>
        <p>17. 위탁연구개발기관의 역할 : "위탁"으로 기재합니다.</p>
        <p>
          18. 연구개발기관 외 기관의 역할(공모 시 요구한 경우에 한하여 기재)
          <br />
          &emsp;1) 해당 기관이 지방자치단체인 경우에 "지자체"로 기재합니다.
          <br />
          &emsp;2) 해당 기관이 국외 연구개발기관인 경우에 "국협"으로 기재합니다.
          <br />
          &emsp;3) 해당 기관이 연구개발성과를 활용하는 기관인 경우에 "수혜"로
          기재합니다.
          <br />
          &emsp;4) 해당 기관이 연구개발과제와 관련된 컨설팅을 하는 기관인 경우에
          "컨설팅"으로 기재합니다.
          <br />
          &emsp; 5) 그 외는 "기타"로 기재합니다.
        </p>
        <p>
          19. 기관유형
          <br />
          &emsp;1) 국가가 직접 설치하여 운영하는 연구기관인 경우에 "국립연"으로
          기재합니다 <br />
          &emsp;(중앙행정기관(소속기관을 제외)이 직접 연구개발과제를 수행하는
          경우에는 "정부부처").
          <br />
          &emsp;2) 지방자치단체가 직접 설치하여 운영하는 연구기관인 경우에
          "공립연"으로 기재합니다 <br />
          &emsp;(지방자치단체(소속기관을 제외)가 직접 연구개발과제를 수행하는
          경우에는 "지자체").
          <br />
          &emsp;3) 「고등교육법」 제2조에 따른 학교인 경우에 "대학"으로
          기재합니다.
          <br />
          &emsp;4) 다음의 어느 하나에 해당하는 기관인 경우에 "정부출연연"으로
          기재합니다.
          <br />
          &emsp;&emsp;(1)「정부출연연구기관 등의 설립ㆍ운영 및 육성에 관한
          법률」제2조에 따른 정부출연연구기관
          <br />
          &emsp;&emsp;(2)「과학기술분야 정부출연연구기관 등의 설립ㆍ운영 및
          육성에 관한 법률」제2조에 따른 과학기술분야 정부출연연구기관
          <br />
          &emsp;&emsp;(3)「특정연구기관육성법」제2조에 따른 특정연구기관
          <br />
          &emsp;&emsp;(4)「한국해양과학기술원법」 제3조에 따라 설립된
          한국해양과학기술원
          <br />
          &emsp;&emsp;(5)「국방과학연구소법」 제3조에 따라 설립된 국방과학연구소
          <br />
          &emsp;5)「지방자치단체출연 연구원의 설립 및 운영에 관한 법률」제2조에
          따른 지방자치단체출연연구원인 경우에 "지자체 출연연"으로 기재합니다.
          <br />
          &emsp;6)「중소기업기본법」제2조에 따른 기업인 경우에 "중소기업"으로
          기재합니다.
          <br />
          &emsp;7)「중견기업 성장촉진 및 경쟁력 강화에 관한 특별법」제2조제1호에
          따른 기업인 경우에 "중견기업"으로 기재합니다.
          <br />
          &emsp;8)「상법」제169조에 따른 회사로서 중소기업 또는 중견기업이 아닌
          경우에 "대기업"으로 기재합니다.
          <br />
          &emsp;9)「공공기관의 운영에 관한 법률」제5조제4항제1호에 따른 공기업인
          경우 "공기업"으로 기재합니다.
          <br />
          &emsp;10)「의료법」제3조제2항제3호에 따른 병원급 의료기관인 경우
          "병원"으로 기재합니다.
          <br />
          &emsp;11)「산업기술혁신 촉진법」 제42조제1항에 따른
          전문생산기술연구소인 경우 "전문연"으로 기재합니다.
          <br />
          &emsp;12) 1)부터 11)까지에 해당하지 않는 기관인 경우에 "기타"로
          기재합니다.
        </p>
      </>
    );
  };
  const IdxSeven = () => {
    return (
      <>
        <p>
          20. 연구개발과제 실무담당자: 연구개발과제에 참여하여 연구개발내용에
          이해도가 높고 전문기관과 연구개발내용에 대한 실무적인 협의가 가능한
          주관연구개발기관 담당자를 기재합니다.
        </p>
      </>
    );
  };
  const showDiv = (idx) => {
    if (idx === 0) {
      setInfoDivContents(
        <>
          <p>
            1. 보안등급: 법 제21조제2항에 따른 보안과제에 해당하는 경우
            ‘보안’에, 그 외의 경우 '일반’에 [√] 표시합니다(연구자 직접 기재
            불필요).
          </p>
          <p>
            2. 중앙행정기관명: 연구개발과제를 공고한 중앙행정기관의 명칭을
            기재합니다(중앙행정기관이 복수인 경우에는 모든 해당 중앙행정기관의
            명칭).
          </p>
          <p>
            3. 전문기관명: 연구개발과제를 관리하는 전문기관명을
            기재합니다(연구자 직접 기재 불필요).
          </p>
          <p>
            4. 사업명: 해당 연구개발과제의 사업명을 기재합니다(연구자 직접 기재
            불필요).
          </p>
          <p>
            5. 내역사업명: 해당 연구개발과제의 내역사업명을 기재합니다(연구자
            직접 기재 불필요).
          </p>
          <p>
            6. 공고번호: 연구개발과제 공고문 상단의 공고번호를 기재합니다(연구자
            직접 기재 불필요).
          </p>
          <p>
            7. 총괄연구개발 식별번호: 총괄연구개발명에 부여되는 번호를
            기재합니다(연구자 직접 기재 불필요).
          </p>
          <p>
            8. 연구개발과제번호: 연구개발과제 선정 시 부여되는 번호를
            기재합니다(연구자 직접 기재 불필요).
          </p>
        </>
      );
    } else if (idx === 1) {
      setInfoDivContents(
        <>
          <p>
            9. 선정방식: 공고문에서 제시한 선정방식을 기재합니다(연구자 직접
            기재 불필요).
          </p>
          <p>
            10. 국가과학기술표준분류: 「과학기술기본법」 제27조제1항에 따른
            국가과학기술표준분류표 중 연구개발과제에 해당하는 소분류를
            우선순위에 따라 그 코드명과 비중을 기재합니다.
          </p>
          <p>
            11. 부처기술분류: 중앙행정기관에서 소관 법령에 따라 입력을 요청하는
            과학기술분류 중 연구개발과제에 해당하는 소분류를 우선순위에 따라 그
            코드명과 비중을 기재합니다.
          </p>
        </>
      );
    } else if (idx === 2) {
      setInfoDivContents(
        <>
          <p>
            12. 총괄연구개발명: 2개 이상의 연구개발과제가 서로 연관되어 추진되는
            경우에 이를 총괄하는 연구개발 명칭을 기재합니다.
          </p>
        </>
      );
    } else if (idx === 3) {
      setInfoDivContents(
        <>
          <p>
            13. 연구개발과제명: 연구개발기관이 수행하는 연구개발과제의 명칭을
            기재합니다.
          </p>
        </>
      );
    } else if (idx === 4) {
      setInfoDivContents(
        <>
          <p>
            14. 연구개발기간: 연구개발과제가 단계로 구분되지 않는 경우에는
            연구개발기간 전체를 1단계로 간주합니다. <br />
            &emsp; 1) 전체: 연구개발과제의 전체 연구개발기간으로서 협약기간을
            기재합니다.
            <br />
            &emsp; 2) 단계: 연구개발과제가 단계로 구분된 경우에 해당 단계의
            연구개발기간을 기재합니다.
          </p>
        </>
      );
    } else if (idx === 5) {
      setInfoDivContents(
        <>
          <p>
            15. 연구개발비: 연구개발과제가 단계로 구분되지 않는 경우에는
            연구개발기간 전체를 1단계로 간주합니다.
            <br />
            &emsp; 1) 정부지원연구개발비: 중앙행정기관이 지원하는 연구개발비를
            기재합니다.
            <br />
            &emsp; 2) 기관부담연구개발비: 시행령 제19조 및 시행령 [별표 1]에
            따라
            <br />
            &emsp; 연구개발기관이 부담하는 연구개발비를 현금과 현물로 구분하여
            기재합니다.
            <br />
            &emsp; 3) 그 외 기관 등의 지원금: 1) 또는 2)에 해당하지 않는
            연구개발비를 지원하는 기관이거나,
            <br />
            &emsp; 연구개발성과를 활용ㆍ구매 등을 목적으로 하는 기관 등이
            지원하는 연구개발비로서 현금과 현물로 구분하여 기재합니다.
            <br />
            &emsp; 4) 연구개발비 외 지원금: 국제기구, 외국의 정부ㆍ기관ㆍ단체
            등이 지원ㆍ부담하는 금액이거나,
            <br />
            &emsp; 중앙행정기관(소속기관 포함)이 소관 업무를 위하여 직접
            수행하는 사업의 금액으로 <br />
            &emsp;「국가연구개발혁신법」에 따른 연구개발비에 포함하지 않는
            금액을 기재합니다.
          </p>
        </>
      );
    } else if (idx === 6) {
      setInfoDivContents(
        <>
          <p>
            16. 공동연구개발기관의 역할
            <br />
            &emsp;1) 공동연구개발기관으로서 연구개발성과를 활용ㆍ구매 등을
            목적으로 하는 기업(수요기업)인 경우에 "수요"로 기재합니다.
            <br />
            &emsp;2) 공동연구개발기관이 수요기업이 아닌 경우에 "공동"으로
            기재합니다.
          </p>
          <p>17. 위탁연구개발기관의 역할 : "위탁"으로 기재합니다.</p>
          <p>
            18. 연구개발기관 외 기관의 역할(공모 시 요구한 경우에 한하여 기재)
            <br />
            &emsp;1) 해당 기관이 지방자치단체인 경우에 "지자체"로 기재합니다.
            <br />
            &emsp;2) 해당 기관이 국외 연구개발기관인 경우에 "국협"으로
            기재합니다.
            <br />
            &emsp;3) 해당 기관이 연구개발성과를 활용하는 기관인 경우에 "수혜"로
            기재합니다.
            <br />
            &emsp;4) 해당 기관이 연구개발과제와 관련된 컨설팅을 하는 기관인
            경우에 "컨설팅"으로 기재합니다.
            <br />
            &emsp; 5) 그 외는 "기타"로 기재합니다.
          </p>
          <p>
            19. 기관유형
            <br />
            &emsp;1) 국가가 직접 설치하여 운영하는 연구기관인 경우에
            "국립연"으로 기재합니다 <br />
            &emsp;(중앙행정기관(소속기관을 제외)이 직접 연구개발과제를 수행하는
            경우에는 "정부부처").
            <br />
            &emsp;2) 지방자치단체가 직접 설치하여 운영하는 연구기관인 경우에
            "공립연"으로 기재합니다 <br />
            &emsp;(지방자치단체(소속기관을 제외)가 직접 연구개발과제를 수행하는
            경우에는 "지자체").
            <br />
            &emsp;3) 「고등교육법」 제2조에 따른 학교인 경우에 "대학"으로
            기재합니다.
            <br />
            &emsp;4) 다음의 어느 하나에 해당하는 기관인 경우에 "정부출연연"으로
            기재합니다.
            <br />
            &emsp;&emsp;(1)「정부출연연구기관 등의 설립ㆍ운영 및 육성에 관한
            법률」제2조에 따른 정부출연연구기관
            <br />
            &emsp;&emsp;(2)「과학기술분야 정부출연연구기관 등의 설립ㆍ운영 및
            육성에 관한 법률」제2조에 따른 과학기술분야 정부출연연구기관
            <br />
            &emsp;&emsp;(3)「특정연구기관육성법」제2조에 따른 특정연구기관
            <br />
            &emsp;&emsp;(4)「한국해양과학기술원법」 제3조에 따라 설립된
            한국해양과학기술원
            <br />
            &emsp;&emsp;(5)「국방과학연구소법」 제3조에 따라 설립된
            국방과학연구소
            <br />
            &emsp;5)「지방자치단체출연 연구원의 설립 및 운영에 관한
            법률」제2조에 따른 지방자치단체출연연구원인 경우에 "지자체
            출연연"으로 기재합니다.
            <br />
            &emsp;6)「중소기업기본법」제2조에 따른 기업인 경우에 "중소기업"으로
            기재합니다.
            <br />
            &emsp;7)「중견기업 성장촉진 및 경쟁력 강화에 관한
            특별법」제2조제1호에 따른 기업인 경우에 "중견기업"으로 기재합니다.
            <br />
            &emsp;8)「상법」제169조에 따른 회사로서 중소기업 또는 중견기업이
            아닌 경우에 "대기업"으로 기재합니다.
            <br />
            &emsp;9)「공공기관의 운영에 관한 법률」제5조제4항제1호에 따른
            공기업인 경우 "공기업"으로 기재합니다.
            <br />
            &emsp;10)「의료법」제3조제2항제3호에 따른 병원급 의료기관인 경우
            "병원"으로 기재합니다.
            <br />
            &emsp;11)「산업기술혁신 촉진법」 제42조제1항에 따른
            전문생산기술연구소인 경우 "전문연"으로 기재합니다.
            <br />
            &emsp;12) 1)부터 11)까지에 해당하지 않는 기관인 경우에 "기타"로
            기재합니다.
          </p>
        </>
      );
    } else if (idx === 7) {
      setInfoDivContents(
        <>
          <p>
            20. 연구개발과제 실무담당자: 연구개발과제에 참여하여 연구개발내용에
            이해도가 높고 전문기관과 연구개발내용에 대한 실무적인 협의가 가능한
            주관연구개발기관 담당자를 기재합니다.
          </p>
        </>
      );
    }
    setIsHovering(true);
  };

  return (
    <div
      className="info_icon"
      id={idx}
      onMouseEnter={() => showDiv(idx)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img src={icon} alt="infoIcon" />
      {isHovering && (
        <div className="info_div" style={{ display: "block" }}>
          {infoDivContents}
        </div>
      )}
    </div>
  );
};

export default FirstInfoText;
