import React, { useContext, useEffect, useState } from "react";
import "./css/MyProduct.scss";
import Menu from "./common/Menu";
import ProductAPI from "../../API/product";
import _ from "lodash";
import memberContext from "../../store/Member";
import CommonAPI from "../../API/common";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function MyProduct() {
  const { auth } = useContext(memberContext);
  const [edit, setEdit] = useState(false);
  const [response, setResponse] = useState([]);
  const [historyResponse, setRistoryResponse] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [changeList, setChangeList] = useState([]);
  const isAdminCompany = window.$Global.checkAuth("특허회사_관리자", auth);
  const isAdminAgent = window.$Global.checkAuth("대리인_관리자", auth);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const title = isCompany
    ? "제품 추가/변경"
    : isAgent
    ? "주요 분야/추가 변경"
    : isInvestor && "관심 분야/추가 변경";
  const content = isCompany
    ? "제품 추가/변경"
    : isAgent
    ? "주요 분야명"
    : isInvestor && "관심 분야";
  //handle drag
  const [keywordData, setKeywordData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [originresponse, setOriginResponse] = useState([]);
  const [originresponse2, setOriginResponse2] = useState([]);
  const [drag, setdrag] = useState(false);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let result = {};
    if (isCompany) {
      result = await ProductAPI.getProductListCompany().then((res) => res.data);
    } else if (isAgent) {
      result = await ProductAPI.getProductListAsAgent().then((res) => res.data);
    } else if (isInvestor) {
      result = await ProductAPI.getProductListAsAgent().then((res) => res.data);
    }
    setOriginResponse(result);
    setResponse(result);
    setRistoryResponse(result);
  };

  const onChange = (e, p_idx, idx) => {
    let copy = _.cloneDeep(response);
    let arr = _.cloneDeep(changeList);

    let payload = {
      idx: p_idx,
      name: e.target.value,
    };
    copy[idx] = payload;
    arr[idx] = payload;
    setResponse(copy);

    setChangeList(arr);
  };

  const onClickDelete = (p_idx, idx) => {
    console.log(p_idx, idx);
    if (p_idx != "new") {
      let arr = deleteList.concat([]);
      arr.push(p_idx);
      setDeleteList(arr);
    }

    let copyArr = response.slice(0);
    copyArr.splice(idx, 1);
    setChangeList(copyArr);
    setResponse(copyArr);
  };

  const onClickEditingEnd = async () => {
    let change_arr = changeList.filter((item) => item != undefined);

    for (let i = 0; i < change_arr.length; i++) {
      if (change_arr[i].idx == "new") {
        if (isCompany) {
          await ProductAPI.addProduct(change_arr[i]).then((res) =>
            console.log(res)
          );
        } else if (isAgent) {
          await ProductAPI.addProductAsAgent(change_arr[i]);
        } else if (isInvestor) {
          await ProductAPI.addProductAsInvestor(change_arr[i]);
        }
      } else {
        if (isCompany) {
          await ProductAPI.updateProduct(change_arr[i].idx, change_arr[i]).then(
            (res) => console.log(res)
          );
        } else if (isAgent) {
          await ProductAPI.updateProductAsAgent(
            change_arr[i].idx,
            change_arr[i]
          );
        } else if (isInvestor) {
          await ProductAPI.updateProductAsInvestor(
            change_arr[i].idx,
            change_arr[i]
          );
        }
      }
    }

    for (let i = 0; i < deleteList.length; i++) {
      if (isCompany) {
        await ProductAPI.deleteProduct(deleteList[i]);
      } else if (isAgent) {
        await ProductAPI.deleteProductAsAgent(deleteList[i]);
      } else if (isInvestor) {
        await ProductAPI.deleteProductAsInvestor(deleteList[i]);
      }
    }

    window.$Global.saveToast();
    setChangeList([]);
    setDeleteList([]);
    setResponse(response.filter((item) => item.name.length > 0));
    await getList();
    setEdit(false);
  };

  const onClickEditingCancel = () => {
    setEdit(false);
    setResponse(historyResponse);
  };

  const onClickAdd = () => {
    let copy = _.cloneDeep(response);
    copy.push({ idx: "new", name: "" });
    setResponse(copy);
  };

  let tbody = response.map((item, idx) => {
    return (
      <tr key={idx}>
        <td width="10%">{idx + 1}</td>
        <td>
          {edit ? (
            <input
              type="text"
              value={item.name}
              onChange={(e) => onChange(e, item.idx, idx)}
            />
          ) : (
            item.name
          )}
        </td>
        {edit && (
          <td width="12%">
            <button
              className="btn_del"
              onClick={() => onClickDelete(item.idx, idx)}
            >
              삭제
            </button>
          </td>
        )}
      </tr>
    );
  });

  if (!tbody.length) {
    tbody = [window.$Global.notTd(3, "등록된 데이터가 없습니다")];
  }

  let tbody2 = response.map((item, idx) => {
    return (
      <tr key={idx}>
        <td width="10%">{idx + 1}</td>
        <td>
          {edit ? (
            <input
              type="text"
              value={item.name}
              onChange={(e) => onChange(e, item.idx, idx)}
            />
          ) : (
            item.name
          )}
        </td>
        {edit && (
          <td width="12%">
            <button
              className="btn_del"
              onClick={() => onClickDelete(item.idx, idx)}
            >
              삭제
            </button>
          </td>
        )}
      </tr>
    );
  });

  return (
    <div>
      <div id="MyProduct">
        <Menu number={3} />
        <div className="form">
          <div className="area">
            <div className="header">
              <h2>{title}</h2>
              {edit ? (
                <div className="btns">
                  <button className="btn_save" onClick={onClickEditingEnd}>
                    저장
                  </button>
                  <button className="btn_cancle" onClick={onClickEditingCancel}>
                    취소
                  </button>
                </div>
              ) : (
                (isAdminCompany || isAdminAgent) && (
                  <button onClick={() => setEdit(true)}>수정</button>
                )
              )}
            </div>
            <div className="table-box-wrap">
              <div className="wrap_list" id="scrollableDiv">
                <table>
                  <thead>
                    <tr>
                      <th width="10%">순번</th>
                      <th>{content}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="keywordList">
                    {response.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td width="10%">{idx + 1}</td>
                          <td>
                            {edit ? (
                              <input
                                type="text"
                                value={item.name}
                                onChange={(e) => onChange(e, item.idx, idx)}
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          {edit && (
                            <td width="12%">
                              <button
                                className="btn_del"
                                onClick={() => onClickDelete(item.idx, idx)}
                              >
                                삭제
                              </button>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {edit && (
              <button className="btn_add" onClick={onClickAdd}>
                + 추가 하기
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProduct;
