export const DEPOSITES_HEADER_FIELDS = [
    { title: "NO", name: "no" },
    { title: "발생기관", name: "depositor_company" },
    { title: "발생날짜", name: "occurrence_date" },
    { title: "수금날짜", name: "collection_date" },
    { title: "입금자", name: "depositor" },
    { title: "금액", name: "deposit_amount" },
    { title: "메모", name: "memo" },
];

export const PACKAGES_HEADER_FIELDS = [
    { title: "NO", name: "no" },
    { title: "적용기관", name: "university" },
    { title: "적용날짜", name: "packageDate" },
    { title: "금액", name: "fee" },
    { title: "메모", name: "memo" },
];