export default function filterRecords({ records, selectedMonth = 0, type }) {
  let filterdRecords;
  if (type === "DEPOSITE") {
    filterdRecords = selectedMonth
      ? records.reduce(
        (recordsAccumulator, { month, deposits }) =>
          month === selectedMonth ? [...recordsAccumulator, ...deposits] : recordsAccumulator,
        []
      )
      : records.reduce((recordsAccumulator, { deposits }) => [...recordsAccumulator, ...deposits], []);
  } else {
    filterdRecords = selectedMonth
      ? records.filter(({ month }) => month === selectedMonth)
      : records;
  }

  return filterdRecords;
}
