import API from "../../util/api";
import { process_params } from "express/lib/router";

export default {
  getAnnualPay(payload) {
    return API.get("/manager/ip/annual_pay_year", { params: payload });
  },

  singleAnnualPay(payload) {
    return API.get("/manager/ip/annual_pay_list", { params: payload });
  },

  getPatentAnnualPay(patent_idx, payload) {
    return API.get(`/manager/patent/${patent_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getTradeMarkAnnualPay(trademark_idx, payload) {
    return API.get(`/manager/trademark/${trademark_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getDesignAnnualPay(design_idx, payload) {
    return API.get(`/manager/design/${design_idx}/annual_pay_year`, {
      params: payload,
    });
  },

  getIPAnnualList(start_year, end_year) {
    return API.get(`/manager/ip/annual_graph`, {
      params: { start_year: start_year, end_year: end_year },
    });
  },

  getAnnualCount(payload) {
    return API.get("/manager/ip/year", { params: payload });
  },

  getPatentPayList(patent_idx, payload) {
    return API.get(`/manager/patent/${patent_idx}/annualPay`, {
      params: payload,
    });
  },

  getPatentPayReviewList(company_idx, payload) {
    return API.get(
      `/admin/company/${company_idx}/patent/annualPay/selectReviewList`,
      {
        params: payload,
      }
    );
  },

  getTradeMarkPayList(trademark_idx, payload) {
    return API.get(`/manager/trademark/${trademark_idx}/annualPay`, {
      params: payload,
    });
  },

  getTradeMarkPayReviewList(company_idx, payload) {
    return API.get(
      `/admin/company/${company_idx}/trademark/annualPay/selectReviewList`,
      {
        params: payload,
      }
    );
  },

  getDesignPayList(design_idx, payload) {
    return API.get(`/manager/design/${design_idx}/annualPay`, {
      params: payload,
    });
  },

  getDesignPayReviewList(company_idx, payload) {
    return API.get(
      `/admin/company/${company_idx}/design/annualPay/selectReviewList`,
      {
        params: payload,
      }
    );
  },

  getPatentPayDetail(patent_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}`,
      {
        params: payload,
      }
    );
  },

  getTrademarkPayDetail(trademark_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}`,
      {
        params: payload,
      }
    );
  },

  getDesignPayDetail(trademark_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/design/${trademark_idx}/annualPay/${annual_pay_idx}`,
      {
        params: payload,
      }
    );
  },

  uploadPatentPayData(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}`,
      payload
    );
  },

  uploadTradeMarkPayData(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}`,
      payload
    );
  },

  uploadDesignPayData(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/design/${trademark_idx}/annualPay/${annual_pay_idx}`,
      payload
    );
  },

  patentApplication(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/application`,
      payload
    );
  },

  tradeMarkApplication(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/application`,
      payload
    );
  },

  designApplication(design_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/design/${design_idx}/annualPay/${annual_pay_idx}/application`,
      payload
    );
  },

  patentPayGiveUp(patent_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/giveUp`,
      payload
    );
  },

  tradeMarkPayGiveUp(trademark_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/giveUp`,
      payload
    );
  },

  designPayGiveUp(design_idx, annual_pay_idx, payload) {
    return API.post(
      `/manager/design/${design_idx}/annualPay/${annual_pay_idx}/giveUp`,
      payload
    );
  },

  getPatentReceiptData(patent_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/billing`,
      payload
    );
  },

  getTradeMarkReceiptData(trademark_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/billing`,
      payload
    );
  },

  getDesignReceiptData(design_idx, annual_pay_idx, payload) {
    return API.get(
      `/manager/design/${design_idx}/annualPay/${annual_pay_idx}/billing`,
      payload
    );
  },

  getPatentList(payload) {
    return API.get("/manager/patent/annualPayList", { params: payload });
  },

  getDownloadPatentList(payload,setIsLoading, setDownloadComplete) {
    window.$Global.download("/manager/patent/excel_download_patent", payload, 1, setIsLoading, setDownloadComplete);
  },

  getAdminDownloadPatentList(payload, setIsLoading, setDownloadComplete) {
    window.$Global.download("/admin/company/patent/excel_download_patent", payload, 1, setIsLoading, setDownloadComplete);
  },

  updatePatentList(data) {
    return API.post("/manager/patent/annualPayList", data);
  },

  getTradeMarkList(payload) {
    return API.get("/manager/trademark/annualPayList", { params: payload });
  },

  getDesignList(payload) {
    return API.get("/manager/design/annualPayList", { params: payload });
  },

  updateTradeMarkList(data) {
    return API.post("/manager/trademark/annualPayList", data);
  },
  // 공동출원인 보기
  getJointApplicantList() {
    return API.get("/manager/patent/jointApplicant");
  },
  // 특허 연차료 코멘트 수정
  updatePatentComment(patent_idx, annual_pay_idx, comment_idx, payload) {
    return API.patch(
      `/manager/patent/${patent_idx}/annualPay/${annual_pay_idx}/comment/${comment_idx}`,
      payload
    );
  },
  updateTrademarkComment(trademark_idx, annual_pay_idx, comment_idx, payload) {
    return API.patch(
      `/manager/trademark/${trademark_idx}/annualPay/${annual_pay_idx}/comment/${comment_idx}`,
      payload
    );
  },
  updateDesignComment(design_idx, annual_pay_idx, comment_idx, payload) {
    return API.patch(
      `/manager/design/${design_idx}/annualPay/${annual_pay_idx}/comment/${comment_idx}`,
      payload
    );
  },
  getCompanyPatentList(company_idx, payload) {
    return API.get(`/manager/patent/annualList`, {
      params: payload,
    });
  },

  annualPayUserComment(payload) {
    return API.post(`/manager/patent/annual_pay_user_comment`, payload);
  },

  annualPayUserDataComment(payload) {
    return API.patch(`/manager/patent/annual_pay_right_comment`, payload);
  },

  //get final_comment
  getFinalComment(patent_idx, payload) {
    return API.get(
      `/manager/patent/${patent_idx}/annualPay/get_final_comment`,
      { params: payload }
    );
  },

  annualPayExcel(payload) {
    window.$Global.download_get(`/common/company/patent/annual_pay_excel`, {
      params: payload,
    });
  },

  // 검토중 검사
  getconfirm(patent_idx) {
    return API.get(`/manager/patent/${patent_idx}/annualPay/confirm`);
  },

  getAnnualPayCurrencySymbol(params) {
    return API.get("/manager/patent/moneySymbol", { params });
  },

  getAnnualPayGiveUpList(params) {
    return API.get(`/manager/patent/annualPayList/giveUp`, { params: params });
  },

  getAnnualPayReviewList(params) {
    return API.get(`/manager/patent/annualPayList/review`, { params: params });
  },

  getAnnualPayDeletedList(params) {
    return API.get(`/manager/patent/annualPayList/deleted`, { params: params });
  },
};
