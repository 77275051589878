import "./css/AnnualFeeBilling.scss";
import React, { useEffect, useState } from "react";
import Admin from "../../API/admin";
import AdminAPI from "../../API/admin";
import AnnualPay from "../../API/annualPay";
import BillingFooter from "./admin/billing/BillingFooter";
import BillingHeader from "./admin/billing/BillingHeader";
import BillingInputRow from "./admin/billing/BillingInputRow";
import BillingInfo from "./admin/billing/BillingInfo";
import BillingKRCost from "./admin/billing/BillingKRCost";
import BillingOverseasCost from "./admin/billing/BillingOverseasCost";
import _ from "lodash";

const AnnualFeeBilling = ({
  companyInfo,
  annualFeeData,
  setShowPopup,
  showPatent,
  getPatentList,
  getTradeMarkList,
  getDesignList,
  isAdmin,
}) => {
  const [sendData, setSendData] = useState({
    billing_number: "", // 청구번호
    type: 0, // 구분
    nation: annualFeeData.nation, // 국가
    billing_date: new Date(), // 청구일자
    remittance_date: new Date(), // 송금일자
    remittance_exchange_rate: 0, // 송금환율
    ipnow_exchange_rate: 0, // IPNOW 적용 환율
    annual_pay_amount: 0, // 연차료 금액
    agent_fee: 0, // IPNOW 수수료 (국내)
    surtax: 0, // 수수료
    total_amount: 0, // 총 금액
    file: [], // 파일
    account_number: "", // 계좌번호
    account_name: "", // 예금주
    bank_name: "", // 은행명
    history: `${annualFeeData.register_number} ${
      annualFeeData.nation
    } ${window.$Global.getOrdinalNumber(Number(annualFeeData.payment_year))}`, // 내역
    overseas_office_fee_calc: 0, // 해외관납료(환율적용)
    overseas_office_fee: 0, // 해외관납료
    overseas_office_fee_money_symbol: "", // 해외관납료 통화기호
    overseas_agent_fee_calc: 0, // 해외 대리인 수수료(환율적용)
    overseas_agent_fee: 0, // 해외 대리인 수수료
    overseas_agent_fee_money_symbol: "", // 해외 대리인 수수료 통화기호
    total_charge: 0, // 해외수수료 합
    overseas_fees: 0, // 해외수수료
    remittance_fee: 0, // 송금수수료
    computer_fee: 0, // 전산료
    ipnow_fees_calc: 0, // IPNOW 수수료(환율적용)(해외)
    ipnow_fees: 0, // IPNOW 수수료(해외)
    ipnow_fees_money_symbol: "", // IPNOW 수수료 통화기호
    receiver: companyInfo.name, // 수신자
  });
  const [fileName, setFileName] = useState([]);
  const [next, setNext] = useState(false);

  useEffect(() => {
    if (annualFeeData.billing_idx !== null) {
      switch (showPatent) {
        case "patent":
          AdminAPI.getAdminPatentReceiptData(
            annualFeeData.patent_idx,
            annualFeeData.annual_pay_idx
          ).then((res) => {
            console.log(res.data);
            // ISO Date Format을 js Date 객체로 변경 (DatePicker 라이브러리 사용을 위해)
            if (res.data.remittance_date == "0000-00-00 00:00:00") {
              res.data.remittance_date = null;
            } else {
              res.data.remittance_date = new Date(res.data.remittance_date);
            }
            if (res.data.billing_date == "0000-00-00 00:00:00") {
              res.data.billing_date = null;
            } else {
              res.data.billing_date = new Date(res.data.billing_date);
            }

            setSendData(res.data);
          });
          break;
        case "trademark":
          AdminAPI.getAdminTradeMarkReceiptData(
            annualFeeData.trademark_idx,
            annualFeeData.annual_pay_idx
          ).then((res) => {
            if (res.data.remittance_date == "0000-00-00 00:00:00") {
              res.data.remittance_date = null;
            } else {
              res.data.remittance_date = new Date(res.data.remittance_date);
            }
            if (res.data.billing_date == "0000-00-00 00:00:00") {
              res.data.billing_date = null;
            } else {
              res.data.billing_date = new Date(res.data.billing_date);
            }
            setSendData(res.data);
          });
          break;
        case "design":
          AdminAPI.getAdminDesignReceiptData(
            annualFeeData.trademark_idx,
            annualFeeData.annual_pay_idx
          ).then((res) => {
            if (res.data.remittance_date == "0000-00-00 00:00:00") {
              res.data.remittance_date = null;
            } else {
              res.data.remittance_date = new Date(res.data.remittance_date);
            }
            if (res.data.billing_date == "0000-00-00 00:00:00") {
              res.data.billing_date = null;
            } else {
              res.data.billing_date = new Date(res.data.billing_date);
            }
            setSendData(res.data);
          });
          break;
      }
    } else {
      AdminAPI.getAdminPatentBankInfoData(annualFeeData).then((res) => {
        console.log(res.data);
        setSendData({
          ...sendData,
          bank_name: res.data.bank_name,
          account_number: res.data.account_number,
          account_name: res.data.depositor,
          agent_fee: res.data.ipnow_fees,
          surtax: res.data.surtax,
          annual_pay_amount: res.data.annual_fees,
        });
      });
    }
  }, []);

  // 국내 연차료 계산
  // Deps = 국내 연차료에 사용되는 변수들
  useEffect(() => {
    // 국내 연차료 계산을 위한 함수
    if (annualFeeData.nation === "KR") {
      calculateKrFee();
    }
  }, [sendData.annual_pay_amount, sendData.agent_fee, sendData.surtax]);

  // 해외 연차료 계산
  // Deps = 해외 연차료에 사용되는 변수들
  useEffect(() => {
    // 해외 연차료 계산을 위한 함수
    if (annualFeeData.nation !== "KR") {
      calculateOverseasFee();
    }
  }, [
    sendData.overseas_office_fee,
    sendData.overseas_agent_fee,
    sendData.overseas_fees,
    sendData.remittance_fee,
    sendData.computer_fee,
    sendData.ipnow_fees,
    sendData.ipnow_exchange_rate,
    sendData.remittance_exchange_rate,
    sendData.overseas_office_fee_money_symbol,
    sendData.overseas_agent_fee_money_symbol,
    sendData.ipnow_fees_money_symbol,
  ]);

  useEffect(() => {
    // 해외 연차료 중 환율적용 된 변수들 계산을 위한 함수
    if (annualFeeData.nation !== "KR") {
      calculateOverseasCalcFee();
    }
  }, [
    sendData.overseas_office_fee_calc,
    sendData.overseas_agent_fee_calc,
    sendData.ipnow_fees_calc,
    sendData.surtax,
  ]);

  // const formmattedTimeStampToDateTime = (data) => {
  //   if (data)
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "file") {
      const fileArray = [
        ...Array.from(sendData.file),
        ...Array.from(e.currentTarget.files),
      ];
      setFileName([]);
      setSendData({
        ...sendData,
        [name]: fileArray,
      });
      fileArray.map((el) => {
        setFileName((prevState) => [...prevState, el.name]);
      });
    } else if (value === "" || isNaN(value)) {
      setSendData({
        ...sendData,
        [name]: value,
      });
    } else {
      setSendData({
        ...sendData,
        [name]: Number(value),
      });
    }
    console.log(sendData);
  };

  const handleClick = (e) => {
    let check = validateData();
    if (!check) return;

    const formData = new FormData();
    for (const item in sendData) {
      if (item === "file") {
        Array.from(sendData.file).map((el) => {
          formData.append("file", el);
        });
      } else if (
        item === "annual_pay_amount" ||
        item === "agent_fee" ||
        item === "surtax" ||
        item === "total_amount"
      ) {
        if (typeof sendData[item] === "string") {
          sendData[item] = Number(sendData[item].replace(/[^0-9]/g, ""));
        }
        formData.append(item, sendData[item]);
      } else if (item === "remittance_date") {
        let value =
          sendData[item] == "0000-00-00 00:00:00" ? null : sendData[item];
        formData.append(item, value);
      } else {
        formData.append(item, sendData[item]);
      }
    }

    switch (showPatent) {
      case "patent":
        AdminAPI.uploadAdminPatentReceiptData(
          annualFeeData.patent_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then((res) => {
          alert("청구서 등록이 완료되었습니다.");
          setShowPopup({
            billing: false,
          });
          getPatentList();
        });
        break;
      case "trademark":
        AdminAPI.uploadAdminTradeMarkReceiptData(
          annualFeeData.trademark_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then((res) => {
          alert("청구서 등록이 완료되었습니다.");
          setShowPopup({
            billing: false,
          });
          getTradeMarkList();
        });
        break;
      case "design":
        AdminAPI.uploadAdminDesignReceiptData(
          annualFeeData.design_idx,
          annualFeeData.annual_pay_idx,
          formData
        ).then((res) => {
          alert("청구서 등록이 완료되었습니다.");
          setShowPopup({
            billing: false,
          });
          getDesignList();
        });
        break;
    }
  };

  // INPUT 태그 사용시 Type이 Number여도
  // return value가 string으로 넘어와서 모든 값에 Number 처리 하였습니다.
  const calculateKrFee = () => {
    sendData.total_amount =
      Number(sendData.annual_pay_amount) +
      Number(sendData.agent_fee) +
      Number(sendData.surtax);

    setSendData({ ...sendData });
  };

  const calculateOverseasFee = () => {
    let data = _.cloneDeep(sendData);

    // 해외 관납료 계산식
    if (
      annualFeeData.nation === "JP" &&
      data.overseas_office_fee_money_symbol === "¥"
    ) {
      data.overseas_office_fee_calc = Math.round(
        (data.overseas_office_fee * data.remittance_exchange_rate) / 100
      );
    } else {
      data.overseas_office_fee_calc = Math.round(
        data.overseas_office_fee * data.remittance_exchange_rate
      );
    }

    // 해외 대리인 수수료 계산식 (US는 대리인 수수료 입력을 안하기 때문에 예외처리)
    // 국가가 JP일때 Money Symbol의 변화에 따라 계산식이 달라지기 때문에 조건 추가
    // 다른 해외건들은 계산식의 차이가 없음
    if (
      annualFeeData.nation === "JP" &&
      data.overseas_agent_fee_money_symbol === "¥"
    ) {
      data.overseas_agent_fee_calc = Math.round(
        (data.overseas_agent_fee * data.remittance_exchange_rate) / 100
      );
    } else {
      data.overseas_agent_fee_calc = Math.round(
        data.overseas_agent_fee * data.remittance_exchange_rate
      );
    }
    // 해외 수수료 합 계산식 (US는 수수료 입력을 안하기 때문에 예외처리)

    data.total_charge =
      Number(data.overseas_fees) +
      Number(data.remittance_fee) +
      Number(data.computer_fee);

    // IPNOW 수수료 계산식
    if (annualFeeData.nation === "JP" && data.ipnow_fees_money_symbol === "¥") {
      data.ipnow_fees_calc = Math.round(
        (data.ipnow_fees * data.ipnow_exchange_rate) / 100
      );
    } else {
      data.ipnow_fees_calc = Math.round(
        data.ipnow_fees * data.ipnow_exchange_rate
      );
    }

    // 부가세 계산 식
    data.surtax = Math.round(data.ipnow_fees_calc * 0.1);

    // 총 금액 계산식
    data.total_amount =
      Number(data.overseas_office_fee_calc) +
      Number(data.overseas_agent_fee_calc) +
      Number(data.total_charge) +
      Number(data.ipnow_fees_calc) +
      Number(data.surtax);

    setSendData(data);
  };

  const calculateOverseasCalcFee = () => {
    // JP 계산시 sendData(원형)로 계산하게 될 경우 환율 값이 변경될 수 있으므로 객체 복사 후 사용
    let data = _.cloneDeep(sendData);

    data.total_amount =
      Number(data.overseas_office_fee_calc) +
      Number(data.overseas_agent_fee_calc) +
      Number(data.total_charge) +
      Number(data.ipnow_fees_calc) +
      Number(data.surtax);

    setSendData(data);
  };

  const showAlert = (message) => {
    alert(message);
    return false;
  };

  const validateAmount = (amount, message) => {
    if (amount === null || amount === undefined || typeof amount === "string") {
      showAlert(message);
      return false;
    }
    return true;
  };
  const validateCheck = (amount, message) => {
    if (!amount || amount == 0 || typeof amount === "string") {
      showAlert(message);
      return false;
    }
    return true;
  };

  const validateData = () => {
    const { nation } = annualFeeData;
    const validations = [
      {
        condition: nation === "KR" && next,
        validations: [
          () =>
            validateAmount(
              sendData.annual_pay_amount,
              "연차료 금액을 입력해주세요"
            ),
          () =>
            validateAmount(sendData.agent_fee, "대리인 금액을 입력해주세요"),
          () => validateAmount(sendData.surtax, "부가세를 입력해주세요"),
        ],
      },
      {
        condition: nation !== "KR" && !next,
        validations: [
          () =>
            validateCheck(
              sendData.remittance_exchange_rate,
              "송금환율을 입력해주세요"
            ),
          () =>
            validateCheck(
              sendData.ipnow_exchange_rate,
              "IPNOW 적용 환율을 입력해주세요"
            ),
        ],
      },
      {
        condition: nation !== "KR" && next,
        validations: [
          () =>
            validateAmount(
              sendData.overseas_office_fee,
              "해외 관납료를 입력해주세요"
            ),
          () => {
            return validateAmount(
              sendData.overseas_agent_fee,
              "해외 대리인 수수료를 입력해주세요"
            );
          },
          () =>
            validateAmount(sendData.ipnow_fees, "IPNOW 수수료를 입력해주세요"),
          () => {
            return validateAmount(
              sendData.overseas_fees,
              "해외 수수료를 입력해주세요"
            );
          },
          () => {
            return validateAmount(
              sendData.remittance_fee,
              "송금 수수료를 입력해주세요"
            );
          },
          () => {
            return validateAmount(
              sendData.computer_fee,
              "전산료를 입력해주세요"
            );
          },
        ],
      },
    ];

    for (let { condition, validations } of validations) {
      if (condition) {
        for (let validate of validations) {
          if (!validate()) {
            return;
          } // 검증 실패 시 즉시 반환
        }
      }
    }
    return true;
  };

  const costProps = { sendData, setSendData, annualFeeData, handleChange };

  return (
    <div id="AnnualFeeBilling">
      <BillingHeader
        next={next}
        setShowPopup={setShowPopup}
        data={sendData}
        annualFeeData={annualFeeData}
      />
      {!next ? (
        <BillingInfo
          data={sendData}
          setData={setSendData}
          handleChange={handleChange}
          annualFeeData={annualFeeData}
        />
      ) : annualFeeData.nation === "KR" ? (
        <BillingKRCost {...costProps} />
      ) : (
        <BillingOverseasCost {...costProps} />
      )}
      <BillingFooter
        next={next}
        setNext={setNext}
        onClick={handleClick}
        data={sendData}
        nation={annualFeeData.nation}
        validateData={validateData}
      />
    </div>
  );
};

export default AnnualFeeBilling;
