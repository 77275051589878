import React from 'react';

function NormalRow({rowClassName, title, titleClassName, txtType, content, contentClassName}) {
    return (
        <div className={`row ${rowClassName || ''}`}>
            <h2 className={`title ${titleClassName || ''}`}>{title}</h2>
            <p className={contentClassName || ''}>{content ? (txtType == "date") ? window.$Global.convertDate(content) : content : 'N/A'}</p>
        </div>
    );
};

export default NormalRow;
