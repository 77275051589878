function PatentMailResRight() {
  return(
    <div id="PatentMailResRight">
      <div className="annual_mail_page">
        <div className="top">
          <div className="title">
            <i className="icon_annual_res_chk"></i>
            <div className="txt">
              <strong>연차료 납부 포기 발명자 권리 양수<br />
              여부 응답 요청</strong>
              <p>본 특허권에 대하여 '00 대학교 산학협력단'은<br />
              포기 하고자 하오니, 특허권에 대한 발명자의 <br />
              권리 양수 또는 비양수 의사에 대하여 응답하여 <br />
              주시기 바랍니다.</p>
            </div>
          </div>
          <div className="steps">
            <p className="one">1</p>
            <p className="two on">2</p>
          </div>
        </div>
        <div className="auth_form">
          <div className="chk_res">
            <ul>
              <li className="yes">
                <strong>
                  <i className="icon_annual_chk"></i>
                  양수 희망
                </strong>
                <p>
                  본 특허권에 대한 권리를<br />
                  양수받을 경우 클릭하세요.
                </p>
                <input type="radio" name="response" />
              </li>
              <li className="no">
                <strong>
                  <i className="icon_annual_reject"></i>
                  양수 거부
                </strong>
                <p>
                  본 특허권에 대한 권리를<br />
                  비양수할 경우 클릭하세요.
                </p>
                <input type="radio" name="response" />
              </li>
            </ul>
          </div>
          <div className="etc_box">
            <div className="etc">
              <strong>
                  <i className="icon_annual_etc"></i>
                  기타
              </strong>
              <textarea name="etc" />
              <input type="radio" name="response" />
            </div>
          </div>
        </div>
        <div className="btns">
          <button>제출하기</button>
        </div>
      </div>
    </div>
  )
}

export default PatentMailResRight;