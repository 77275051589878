import React, { useEffect, useState } from "react";
import "./css/DeadLineSetting.scss";
import _ from "lodash";
import DeadlineAPI from "../../API/deadline/index";
import { useLocation } from "react-router-dom";
function DeadLineSetting({ history }) {
  const location = useLocation();
  const showPatent = location.state.value;
  const initData = showPatent
    ? {
        priority_deadline: 90,
        for_apply_deadline: 90,
        pct_deadline: 90,
        oa_deadline: 90,
        screen_deadline: 90,
        regist_pay_deadline: 90,
        annual_pay_deadline: 90,
      }
    : {
        objection_deadline: 90,
        registration_payment_deadline: 90,
        renewal_deadline_end: 90,
        foreign_apply_deadline: 90,
      };

  const [notifyList, setNotifyList] = useState([]);

  useEffect(() => {
    showPatent
      ? DeadlineAPI.getDeadline().then((res) => {
          setNotifyList(res.data);
        })
      : DeadlineAPI.getTradeMarkNoticeDeadline().then((res) => {
          setNotifyList(res.data);
        });
  }, []);

  const addNotify = () => {
    let list = _.cloneDeep(notifyList);
    list.push(initData);
    setNotifyList(list);
  };

  const onChangeDeadline = (e, key, index) => {
    let list = _.cloneDeep(notifyList);
    list[index][key] = Number(e.target.value);
    setNotifyList(list);
  };

  const onClickDelete = (index) => {
    let list = _.cloneDeep(notifyList);
    list.splice(index, 1);
    setNotifyList(list);
  };

  const onClickSave = () => {
    showPatent
      ? DeadlineAPI.updateDeadline(notifyList).then(() => {
          alert("저장되었습니다.");
          history.push("/deadline/list");
        })
      : DeadlineAPI.updateTradeMarkDeadline(notifyList).then(() => {
          alert("저장되었습니다.");
          history.push("/deadline/list");
        });
  };

  let option = [];

  for (let i = 1; i <= 120; i++) {
    option.push(<option value={i}>{i}일전</option>);
  }

  let notify = notifyList.map((item, index) => {
    return (
      <div className="row" key={index}>
        <div className="title">
          <span>알림#{index + 1}</span>
          {index != 0 && (
            <button
              className="icon_badge_del"
              onClick={() => onClickDelete(index)}
            />
          )}
        </div>
        {showPatent ? (
          <>
            <div>
              <select
                value={item.priority_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "priority_deadline", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                className="color_blue"
                value={item.for_apply_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "for_apply_deadline", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                className="color_blue"
                value={item.pct_deadline}
                onChange={(e) => onChangeDeadline(e, "pct_deadline", index)}
              >
                {option}
              </select>
            </div>
            <div>
              <select
                className="color_blue"
                value={item.oa_deadline}
                onChange={(e) => onChangeDeadline(e, "oa_deadline", index)}
              >
                {option}
              </select>
            </div>
            <div>
              <select
                value={item.screen_deadline}
                onChange={(e) => onChangeDeadline(e, "screen_deadline", index)}
              >
                {option}
              </select>
            </div>
            <div>
              <select
                value={item.regist_pay_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "regist_pay_deadline", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                value={item.annual_pay_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "annual_pay_deadline", index)
                }
              >
                {option}
              </select>
            </div>
          </>
        ) : (
          <>
            <div>
              <select
                value={item.objection_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "objection_deadline", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                className="color_blue"
                value={item.registration_payment_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "registration_payment_deadline", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                className="color_blue"
                value={item.renewal_deadline_end}
                onChange={(e) =>
                  onChangeDeadline(e, "renewal_deadline_end", index)
                }
              >
                {option}
              </select>
            </div>
            <div>
              <select
                value={item.foreign_apply_deadline}
                onChange={(e) =>
                  onChangeDeadline(e, "foreign_apply_deadline", index)
                }
              >
                {option}
              </select>
            </div>
          </>
        )}
      </div>
    );
  });

  return (
    <div id="DeadLineSetting">
      <div className="area_box">
        <img
          src={
            require("../../assets/images/deadline/icon_deadline_setting.svg")
              .default
          }
          alt="소개 이미지"
        />
        <img
          src={require("../../assets/images/deadline/group-4.png").default}
          alt="소개 이미지"
        />
      </div>

      <div className="header">
        <h2 className="title">기한 알림 설정</h2>
        <button className="btn_setting_complete" onClick={onClickSave}>
          설정 완료
        </button>
      </div>

      <div className="wrap_setting custom_scroll">
        {showPatent ? (
          <div className="row">
            <div></div>
            <div className="title">우선권 기한</div>
            <div className="title color_blue">해외 출원 기한</div>
            <div className="title color_blue">PCT국내단계 진입기한</div>
            <div className="title color_blue">OA 제출기한</div>
            <div className="title">심사청구 기한</div>
            <div className="title">등록료 납부기한</div>
            <div className="title">연차료 납부기한</div>
          </div>
        ) : (
          <div className="row">
            <div></div>
            <div className="title">이의신청 마감일</div>
            <div className="title color_blue">등록료 납부 기한</div>
            <div className="title color_blue">갱신 마감 일</div>
            <div className="title">해외 출원 마감 기한</div>
          </div>
        )}
        {notify}
        <div className="row">
          <div>
            <button className="btn_add_update title" onClick={addNotify}>
              알림 추가
            </button>
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          {showPatent && (
            <>
              <div></div>
              <div></div>
              <div></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeadLineSetting;
