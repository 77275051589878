import React from "react";
import "./css/ContentData.scss";
import InfiniteScroll from "react-infinite-scroll-component";

function ContentTableList({addTheadList, title, data, drawList, content, hasMore}) {
    return (
        <div id="ContentData">
            <h2>{title}</h2>
            <div className="table-box-wrap">
                <div id="scrollableDiv" className="wrap_list">
                    <InfiniteScroll
                        dataLength={data.length}
                        next={drawList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table>
                            <thead>
                                <tr>
                                    <th>순번</th>
                                    <th>내용</th>
                                    <th>게시일</th>
                                    {addTheadList?.map((item, idx) => <th key={idx}>{item}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {content}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}

export default ContentTableList;
