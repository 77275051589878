import React from "react";
import "./css/PopupTaskDetail.scss";
import CommonAPI from "../../API/common";

function PopupTaskDetail({show, item, field, onClickInterestAdd, onClose}) {
    const onClickView = () => {
        let imageWin = window.open("", "_blank");
        imageWin.document.write(`<html><body style='margin:0; text-align: center;'><style type="text/css">img {max-width: 1100px;}</style>`);
        imageWin.document.write(item.content_image);
        imageWin.document.write("</body><html>");
    }
    
    const onClickFileDownload = (idx, f_idx) => {
        CommonAPI.taskFileDownload(idx, f_idx);
    }

    if (window.$Global.isEmptyObject(item) || !show) return null;

    return (
        <div id="PopupTaskDetail" className="custom_scroll">
            <div className="title_area">
                <div className="post_title">
                    <h2>공고명:</h2>
                    <h2 className="title">{item.title}</h2>
                    <button className="icon_exit" onClick={onClose}/>
                </div>
                <div className="title_state_inner">
                    <p className="desc">세부 공고명: {item.title_detail}</p>
                </div>
            </div>
            <div className="detail_area">
                <div className="wrap_data">
                    <div className="datas">
                        <div className="data">
                            <h2>공고기관</h2>
                            <p>{item.department_name}</p>
                        </div>
                        <div className="data">
                            <h2>참가가능지역</h2>
                            <p>{window.$Global.matchJsonToStr(field, "region", item.region)}</p>
                        </div>
                        <div className="data">
                            <h2>현황</h2>
                            <p>{window.$Global.matchJsonToStr(field, "state", item.state)}</p>
                        </div>
                    </div>
                    <div className="datas">
                        <div className="data">
                            <h2>분야</h2>
                            <p>{window.$Global.matchJsonToStr(field, "field", item.field)}</p>
                        </div>
                    </div>
                    <div className="datas">
                        <div className="data">
                            <h2 className="color_blue">접수일</h2>
                            <p>{isNaN(item.reception_at) ? item.reception_at : window.$Global.convertDate(item.reception_at)}</p>
                        </div>
                        <div className="data">
                            <h2 className="color_blue">마감 일시</h2>
                            <p>{isNaN(item.deadline_at) ? item.deadline_at : window.$Global.convertDateTime(item.deadline_at)}</p>
                        </div>
                        <div className="data">
                            <h2 className="color_blue">금액</h2>
                            <p className="color_blue">{isNaN(item.amount) ? item.amount : window.$Global.commaify(item.amount)}</p>
                        </div>
                    </div>
                    <div className="datas">
                        <div className="data rowspan3">
                            <h2>문의처</h2>
                            <p>{item.inquries}</p>
                        </div>
                    </div>
                    <div className="datas">
                        <div className="data rowspan2 files">
                            <h2>첨부파일</h2>
                            <div className="inner">
                                {
                                    item.file.map((f_item, idx) => {
                                        return (
                                            <p key={idx} onClick={() => onClickFileDownload(item.idx, f_item.idx)}>
                                                <i className="icon_download_attached"/>
                                                {
                                                    f_item.file_name + "." + f_item.file_type
                                                }
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="data">
                            {
                                item.uri && <button className="btn_go" onClick={() => window.open(item.uri, "_blank")}>공고문 바로가기</button>
                            }
                            <button className="btn_add" onClick={() => onClickInterestAdd(item.idx)}>관심공고 추가하기</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content_area">
                {
                    item.content_image && <button onClick={onClickView}>전체 공고문 보기</button>
                }
                {
                    item.content_core.map((item2, idx2) => {
                        return (
                            <div key={idx2} className="inner">
                                <div className="title">
                                    <i className="icon_detail"/>
                                    {item2.title}
                                </div>
                                <p className="content sun-editor-editable" dangerouslySetInnerHTML={ {__html: item2.content} }/>
                            </div>
                        )
                    })
                }
            </div>
            <button className="btn_close" onClick={onClose}>닫기</button>
        </div>
    );
}

export default PopupTaskDetail;