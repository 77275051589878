import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeSampleListTop.scss";

function DocTradeSampleListTop({chargedType, onClick, onChange}) {
    return (
        <div id="docTradeSampleListTop">
            <div className="sample_list-contentTop">
                <div className="sample_list-contentTop_tabs">
                    <div className={`sample_list-contentTop_tab ${chargedType == -1 ? 'active' : ''}`}>
                        <button className="sample_list-contentTop_tab_link" onClick={() => onClick(-1)}>전체</button>
                    </div>
                    <div className={`sample_list-contentTop_tab ${chargedType == 0 ? 'active' : ''}`}>
                        <button className="sample_list-contentTop_tab_link" onClick={() => onClick(0)}>유료</button>
                    </div>
                    <div className={`sample_list-contentTop_tab ${chargedType == 1 ? 'active' : ''}`}>
                        <button className="sample_list-contentTop_tab_link" onClick={() => onClick(1)}>무료</button>
                    </div>
                </div>
                <select name="sortSel" id="sortSel" className="sample_list-contentTop_sort-select" onChange={onChange}>
                    <option value="10" className="sample_list-contentTop_sort-option">10개씩 보기</option>
                    <option value="15" className="sample_list-contentTop_sort-option">15개씩 보기</option>
                    <option value="20" className="sample_list-contentTop_sort-option">20개씩 보기</option>
                    <option value="25" className="sample_list-contentTop_sort-option">25개씩 보기</option>
                </select>
            </div>
        </div>
    )
}

export default DocTradeSampleListTop;