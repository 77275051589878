import React from "react";

function ButtonRow({
  rowClassName,
  title,
  titleClassName,
  condition,
  data,
  onClick,
  index,
  dataKey,
  withCheckpoint,
  onOpenReport,
}) {
  return (
    <div className={`row ${rowClassName ? rowClassName : ""}`}>
      <h2 className={`title ${titleClassName ? titleClassName : ""}`}>
        {title}
        {data !== null ? "일" : "하기"}
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage(title)} />
      </h2>
      {condition && data === null ? (
        <button className="btn_order" onClick={() => onClick(index, dataKey)}>
          지시하기
        </button>
      ) : (
        <p>{window.$Global.convertDate(data)}</p>
      )}
    </div>
  );
}

export default ButtonRow;
