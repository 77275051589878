import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/ConsortiumModify.scss";
import { Link } from "react-router-dom";
import ConsortiumAPI from "../../API/consortium";
import localConfig from "./const/index";
import memberContext from "../../store/Member";
import qs from "query-string";
import Validator from "../../util/validator";
import CommonAttachPopup from "../common/CommonAttachPopup";
import _ from "lodash";
import config from "./const";

function ConsortiumModify({ location, history }) {
  const { idx, type, postType } = qs.parse(location.search);
  const isAdd = postType == "add";
  const isEdit = postType == "edit";
  const typeStr = type == config.supply ? "내 기업 소개하기" : "필요 기업 찾기";
  const member = useContext(memberContext);
  const listLink = `list?type=${type}`;

  const [post, setPost] = useState({
    company_name: member.company_name,
    type: type, // 0 : 공급, 1 : 수요
    company_type: "",
    representative: "",
    phone: "",
    business_field: "",
    industry_field: "",
    location: "",
    desc: "",
    license_number: "",
  });
  const [logoFile, setLogoFile] = useState("");
  const [introduceFile, setIntroduceFile] = useState([]);
  const [preview, setPreview] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const previewRef = useRef();
  const introduceFileRef = useRef();

  const CompanyRef = useRef();
  const representativeRef = useRef();
  const companyTypeRef = useRef();
  const licenceNumberRef = useRef();
  const phoneRef = useRef();
  const businessFieldRef = useRef();
  const industryFieldRef = useRef();
  const locationRef = useRef();
  const descRef = useRef();

  const refs = [
    CompanyRef,
    representativeRef,
    companyTypeRef,
    licenceNumberRef,
    phoneRef,
    businessFieldRef,
    industryFieldRef,
    locationRef,
    descRef,
  ];

  useEffect(() => {
    if (isEdit) {
      ConsortiumAPI.getInfo(idx).then((res) => {
        let data = res.data;
        setPost(data);
        setIntroduceFile(data.introduce_file);
        setPreview(window.$Global.getCDN(res.data.profile_image_key));
      });
    }
  }, []);

  const add = async () => {
    let formData = new FormData();
    let payload = {
      ...post,
      desc: window.$Global.convertLine(post.desc),
    };

    let keys = Object.keys(payload);
    keys.map((item) => formData.append(item, payload[item]));

    if (logoFile) {
      formData.append("profile_image", logoFile);
    }

    let res_idx = idx;

    if (Validator.refValidator(refs)) {
      if (!introduceFile.length) {
        alert("회사 소개 파일을 등록해주세요");
        return;
      }

      if (isAdd) {
        if (!logoFile) {
          alert("로고 파일을 등록해주세요");
          return;
        }
        introduceFile.forEach((file) =>
          formData.append("introduce_file", file)
        );
        res_idx = await ConsortiumAPI.add(formData).then(
          async (res) => res.data.cons_idx
        );
      } else if (isEdit) {
        await ConsortiumAPI.update(idx, formData).then(async () => {
          let condition = introduceFile.filter((item) => !item.idx);
          if (condition.length) {
            let introduceFormData = new FormData();
            introduceFile.forEach((file) => {
              if (!file.idx) {
                introduceFormData.append("introduce_file", file);
              }
            });
            await ConsortiumAPI.uploadIntroduce(idx, introduceFormData);
          }
        });
      }

      alert(`${isAdd ? "등록" : "수정"}되었습니다`);
      history.replace(`detail/${res_idx}`);
    }
  };

  const handleImgFile = (t) => {
    const file = t.target.files[0];
    if (!file) return;
    if (window.$Global.extendCheckImage(file.name)) {
      setLogoFile(file);
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => setPreview(e.target.result);
    }
  };

  const handleInfoFile = (t) => {
    const infoFile = t.target.files;
    if (!infoFile.length) return;
    Array.from(infoFile).forEach((item) => {
      if (window.$Global.extendCheckPDF(item.name)) {
        setIntroduceFile([...infoFile].concat(introduceFile));
      }
    });
  };

  const onClickIntroFileDelete = async (f_idx, index) => {
    let copy = _.cloneDeep(introduceFile);
    if (f_idx) {
      await ConsortiumAPI.deleteIntroduceFile(idx, f_idx);
    }
    copy.splice(index, 1);
    setIntroduceFile(copy);
  };

  return (
    <div id="ConsortiumModify">
      <div className="consortiumModify">
        <div className="consortiumModify_title-wrap">
          <h2 className="consortiumModify_title">{typeStr}</h2>
          <div className="consortiumModify_btnTop-wrap">
            {isAdd && (
              <button className="consortiumModify_btn-modify" onClick={add}>
                등&nbsp;&nbsp;록
              </button>
            )}
            {isEdit && (
              <>
                <button className="consortiumModify_btn-modify" onClick={add}>
                  저&nbsp;&nbsp;장
                </button>
                <Link to={`detail/${idx}`} className="consortiumModify_btn-del">
                  취소
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="consortiumModify_info">
          <div className="consortiumModify_info_img">
            <label htmlFor="comImg" className="icon_upload">
              이미지 올리기
            </label>
            <input
              type="file"
              name="comImg"
              id="comImg"
              className="hidden"
              accept="image/*"
              onChange={handleImgFile}
            />
            {preview && <img src={preview} ref={previewRef} />}
          </div>
          <div className="consortiumModify_info_table">
            <dl className="list_table">
              <dt className="list_table_title">
                <label htmlFor="comCompany">업체명</label>
              </dt>
              <dd className="list_table_info col-1-1">
                <input
                  type="text"
                  name="comCompany"
                  id="comCompany"
                  ref={CompanyRef}
                  data-name="업체명"
                  value={post.company_name}
                  onChange={(e) =>
                    setPost({ ...post, company_name: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="comRepresent">대표자</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="text"
                  name="comRepresent"
                  id="comRepresent"
                  ref={representativeRef}
                  data-name="대표자"
                  value={post.representative}
                  onChange={(e) =>
                    setPost({ ...post, representative: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="comCategory">기업유형</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="text"
                  name="comCategory"
                  id="comCategory"
                  ref={companyTypeRef}
                  data-name="기업유형"
                  value={post.company_type}
                  onChange={(e) =>
                    setPost({ ...post, company_type: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="comNum">사업자 등록번호</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="text"
                  name="comNum"
                  id="comNum"
                  ref={licenceNumberRef}
                  data-name="사업자 등록번호"
                  value={post.license_number}
                  onChange={(e) =>
                    setPost({ ...post, license_number: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="comTel">연락처</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="tel"
                  name="comTel"
                  id="comTel"
                  ref={phoneRef}
                  data-name="연락처"
                  value={post.phone}
                  onChange={(e) => setPost({ ...post, phone: e.target.value })}
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="businessAreas">사업분야</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="text"
                  name="businessAreas"
                  id="businessAreas"
                  ref={businessFieldRef}
                  data-name="사업분야"
                  value={post.business_field}
                  onChange={(e) =>
                    setPost({ ...post, business_field: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="industry">산업분야</label>
              </dt>
              <dd className="list_table_info col-1-2">
                <input
                  type="text"
                  name="industry"
                  id="industry"
                  ref={industryFieldRef}
                  data-name="산업분야"
                  value={post.industry_field}
                  onChange={(e) =>
                    setPost({ ...post, industry_field: e.target.value })
                  }
                />
              </dd>
              <dt className="list_table_title">
                <label htmlFor="comLocation">소재지</label>
              </dt>
              <dd className="list_table_info col-1-1">
                <input
                  type="text"
                  name="comLocation"
                  id="comLocation"
                  ref={locationRef}
                  data-name="소재지"
                  value={post.location}
                  onChange={(e) =>
                    setPost({ ...post, location: e.target.value })
                  }
                />
              </dd>
            </dl>
          </div>
        </div>
        <div className="consortiumModify_textarea">
          <div className="consortiumModify_textarea_title-wrap">
            <h3 className="consortiumModify_textarea_title">회사 소개</h3>
            <dl className="list_table">
              <dt className="list_table_title">회사 소개자료</dt>
              <dd className="list_table_info">
                <button
                  className="icon_upload"
                  onClick={() => setShowPopup(true)}
                >
                  파일 올리기
                </button>
                <input
                  type="file"
                  name="fileLoad"
                  id="fileLoad"
                  className="hidden"
                  ref={introduceFileRef}
                  multiple
                  onChange={handleInfoFile}
                  accept=".pdf"
                />
              </dd>
            </dl>
          </div>
          <textarea
            name="comIntro"
            id="comIntro"
            className="consortiumModify_textarea_content"
            ref={descRef}
            data-name="회사 소개"
            value={post.desc}
            onChange={(e) => setPost({ ...post, desc: e.target.value })}
          />
        </div>
        <div className="consortiumModify_btn-wrap">
          <Link to={listLink} className="consortiumModify_btn">
            목&nbsp;&nbsp;록
          </Link>
        </div>
      </div>

      <CommonAttachPopup
        list={introduceFile}
        show={showPopup}
        fileRef={introduceFileRef}
        onClickClose={() => setShowPopup(false)}
        hideDownloadBtn={true}
        showDeleteBtn={true}
        onClickDelete={onClickIntroFileDelete}
      />
    </div>
  );
}

export default ConsortiumModify;
