import colors from "./colors";
import techLevels from "./techLevels";

const patentStatus = {
  data: {
    labels: ["국내", "국외"],
    datasets: [
      {
        label: "출원",
        data: [50, 65],
        backgroundColor: colors[0],
      },
      {
        label: "등록",
        data: [35, 25],
        backgroundColor: colors[1],
      },
      {
        label: "소멸",
        data: [5, 3],
        backgroundColor: colors[2],
      },
      {
        label: "포기",
        data: [4, 2],
        backgroundColor: colors[3],
      },
      {
        label: "거절",
        data: [4, 3],
        backgroundColor: colors[4],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              return value + "%";
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: { mirror: true },
        },
      ],
    },
    plugins: {
      datalabels: {
        align: "center",
        anchor: "middle",
        borderRadius: 4,
        color: "white",
        formatter: (value, context) => {
          return value + "%"; // Append percentage sign if needed
        },
      },
    },
  },
  barOptions: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    indexAxis: "y",
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: function (value) {
              return value + "건";
            },
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    plugins: {
      datalabels: {
        align: "center",
        anchor: "middle",
        borderRadius: 4,
        color: "white",
        formatter: (value, context) => {
          return window.$Global.commaify(value) + "건";
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
  },
};

const patentStatusByYear = {
  data: {
    labels: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],
    datasets: [
      {
        label: "Dataset 1",
        data: [15, 15, 15, 17, 18, 19, 18],
        borderColor: colors[0],
        backgroundColor: colors[0],
        fill: false,
        tension: 0,
      },
      {
        label: "Dataset 2",
        data: [20, 21, 22, 23, 24, 25, 26],
        borderColor: colors[1],
        backgroundColor: colors[1],
        fill: false,
        tension: 0,
      },
      {
        label: "Dataset 3",
        data: [25, 25, 25, 27, 28, 29, 30],
        borderColor: colors[2],
        backgroundColor: colors[2],
        fill: false,
        tension: 0,
      },
      {
        label: "Dataset 4",
        data: [35, 35, 35, 36, 37, 38, 40],
        borderColor: colors[3],
        backgroundColor: colors[3],
        fill: false,
        tension: 0,
      },
      {
        label: "Dataset 4",
        data: [30, 21, 25, 34, 22, 28, 30],
        borderColor: colors[4],
        backgroundColor: colors[4],
        fill: false,
        tension: 0,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  },
  options2: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value) {
              return value + "건";
            },
          },
        },
      ],
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
  },
  options3: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}개월`;
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value) {
              return value + "개월";
            },
          },
        },
      ],
    },
  },
  optionsTech: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    scales: {
      yAxes: [
        {
          type: "category",
          labels: techLevels.levels,
          ticks: {
            callback: function (value, index, values) {
              return values[index];
            },
          },
          min: 0,
          max: 9,
          stepSize: 1,
        },
      ],
    },
  },
  noLegend: {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}개월`;
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value) {
              return value + "개월";
            },
          },
        },
      ],
    },
  },
};

const country = {
  data: {
    labels: ["미국", "일본", "중국", "유럽", "한국", "기타"],
    datasets: [
      {
        data: [30, 20, 15, 10, 10, 15], // Example data, adjust accordingly
        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[4],
        ],
        hoverBackgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[4],
        ],
        //hoverBorderColor: "rgba(234, 236, 244, 1)",
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = data.labels[tooltipItem.index] || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
    cutoutPercentage: 0,
    plugins: {
      datalabels: {
        color: "#fff",
        anchor: "end", // 라벨의 중심을 센터로 설정
        align: "start", // 텍스트를 센터 정렬
        offset: 15, // 라벨이 중앙에 위치하도록 오프셋을 0으로 설정
        clip: false,
        font: {
          size: 14,
          lineHeight: 1.2, // 줄 간격을 설정 (필요에 따라 조정)
        },
        formatter: function (value, context) {
          const label = context.chart.data.labels[context.dataIndex];
          const dataset = context.chart.data.datasets[0];
          const total = dataset.data.reduce((sum, val) => sum + val, 0);
          const percentage = (value / total) * 100;

          // 퍼센티지가 5% 이상일 때만 라벨 표시
          if (percentage > 5) {
            return `${window.$Global.commaify(value)}건`;
          } else {
            return ""; // 빈 문자열을 반환하면 라벨이 표시되지 않음
          }
        },
      },
    },
  },
};

const mainCountry = {
  data: {
    labels: [
      "2012",
      "2013",
      "2014",
      "2015",
      "2016",
      "2017",
      "2018",
      "2019",
      "2020",
      "2021",
      "2022",
    ],
    datasets: [
      {
        label: "중국",
        data: [
          32.2, 33.0, 37.1, 40.2, 42.4, 45.4, 51.4, 51.4, 51.5, 54.7, 56.9,
        ],
        backgroundColor: colors[7],
      },
      {
        label: "유럽",
        data: [21.8, 21.9, 18.5, 17.4, 16.5, 15.5, 13.1, 13.2, 12.3, 11.5, 9.7],
        backgroundColor: colors[1],
      },
      {
        label: "일본",
        data: [9.6, 9.7, 9.7, 9.4, 8.2, 8.1, 7.1, 7.2, 7.4, 7.3, 7.5],
        backgroundColor: colors[2],
      },
      {
        label: "한국",
        data: [
          17.1, 16.7, 16.5, 15.7, 14.7, 14.3, 12.4, 13.2, 13.9, 12.6, 13.4,
        ],
        backgroundColor: colors[3],
      },
      {
        label: "미국",
        data: [12.0, 11.6, 11.4, 11.2, 12.7, 13.6, 13.6, 13.2, 9.6, 8.2, 7.3],
        backgroundColor: colors[4],
      },
      {
        label: "기타",
        data: [7.3, 7.1, 6.8, 6.1, 5.8, 5.1, 5.1, 5.1, 6.0, 5.7, 6.2],
        backgroundColor: colors[0],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    // plugins: {
    //   datalabels: {
    //     display: true,
    //     color: "white",
    //     anchor: "center",
    //     align: "center",
    //     formatter: (value, context) => {
    //       return value + "%"; // Append percentage sign if needed
    //     },
    //   },
    // },
  },
};

const mainCountryByYear = {
  xLabels: [
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
  ],
  yLabels: ["S", "A", "B+", "B", "C+", "C", "D+", "D", "N"],
  data: [
    [2, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0],
    [6, 4, 4, 5, 0, 1, 0, 1, 0, 0, 0],
    [37, 30, 26, 21, 11, 20, 6, 6, 4, 1, 0],
    [90, 59, 61, 57, 72, 64, 37, 33, 20, 8, 0],
    [87, 70, 72, 83, 82, 120, 94, 69, 23, 4, 0],
    [92, 48, 51, 58, 55, 38, 52, 39, 14, 3, 0],
    [26, 33, 50, 58, 62, 60, 67, 50, 7, 1, 0],
    [25, 34, 31, 17, 3, 21, 38, 49, 1, 1, 0],
    [16, 6, 1, 0, 0, 3, 4, 3, 0, 1, 0],
    [208, 141, 174, 150, 150, 171, 164, 315, 390, 80, 2],
  ],
};

const techStatus = {
  data: {
    labels: ["A", "B", "C", "D", "E", "F"],
    datasets: [
      {
        data: [10, 20, 30, 10, 15, 15], // Example data, adjust accordingly
        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        hoverBackgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        borderWidth: 2,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = data.labels[tooltipItem.index] || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
  },
};

const techEval = {
  data: {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "AAA",
        data: [5, 6, 7, 6, 5],
        backgroundColor: colors[0],
      },
      {
        label: "AA",
        data: [10, 9, 8, 9, 10],
        backgroundColor: colors[1],
      },
      {
        label: "A",
        data: [15, 14, 13, 14, 15],
        backgroundColor: colors[2],
      },
      {
        label: "BBB",
        data: [20, 19, 18, 19, 20],
        backgroundColor: colors[3],
      },
      {
        label: "BB",
        data: [25, 24, 23, 24, 25],
        backgroundColor: colors[4],
      },
      {
        label: "B",
        data: [30, 29, 28, 29, 30],
        backgroundColor: colors[5],
      },
      {
        label: "CCC",
        data: [5, 6, 7, 6, 5],
        backgroundColor: colors[6],
      },
      {
        label: "CC",
        data: [3, 4, 5, 4, 3],
        backgroundColor: colors[7],
      },
      {
        label: "C",
        data: [1, 2, 3, 2, 1],
        backgroundColor: colors[8],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (value) {
              return window.$Global.commaify(value) + "건";
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
  },
};

const ipc = {
  data: {
    labels: ["A", "B", "C", "D", "E", "F", "G", "H"],
    datasets: [
      {
        label: "AAA",
        data: [5, 6, 7, 6, 5, 6, 5, 6],
      },
      {
        label: "AA",
        data: [10, 9, 8, 9, 10, 9, 8, 9],
      },
      {
        label: "A",
        data: [15, 14, 13, 14, 15, 14, 13, 14],
      },
      {
        label: "BBB",
        data: [20, 19, 18, 19, 20, 19, 18, 19],
      },
      {
        label: "BB",
        data: [25, 24, 23, 24, 25, 24, 23, 24],
      },
      {
        label: "B",
        data: [30, 29, 28, 29, 30, 29, 28, 29],
      },
      {
        label: "CCC",
        data: [5, 6, 7, 6, 5, 6, 7, 6],
      },
      {
        label: "CC",
        data: [3, 4, 5, 4, 3, 4, 5, 4],
      },
      {
        label: "C",
        data: [1, 2, 3, 2, 1, 2, 3, 2],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          //ticks: { mirror: true },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const techTransfer = {
  data: {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        type: "bar",
        label: "건수",
        data: [10, 20, 30, 40, 50], // Example data, adjust accordingly
        backgroundColor: colors[0],
        yAxisID: "y-axis-1",
      },
      {
        type: "line",
        label: "누적 건수",
        data: [10, 100, 60, 100, 150], // Example data, adjust accordingly
        borderColor: colors[11],
        backgroundColor: colors[11],
        fill: false,
        yAxisID: "y-axis-1",
        tension: 0,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return `${window.$Global.commaify(value)}건`;
            },
          },
        },
      ],
    },
    // title: {
    //   display: true,
    //   text: "건수",
    //   position: "left",
    // },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        filter: function (legendItem, chartData) {
          const dataset = chartData.datasets[legendItem.datasetIndex];
          if (dataset.type === "line") {
            legendItem.fillStyle = dataset.borderColor;
            legendItem.strokeStyle = dataset.borderColor;
          }
          return true;
        },
      },
    },
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem, data) {
    //       var datasetLabel =
    //         data.datasets[tooltipItem.datasetIndex].label || "";
    //       var value = data.datasets[0].data[tooltipItem.index];
    //       if (parseInt(value) >= 1000) {
    //         value = window.$Global.commaify(value) + "건";
    //       } else {
    //         value = value + "건";
    //       }
    //       return datasetLabel + ": " + value;
    //     },
    //   },
    // },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || "";
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var formattedValue =
            parseInt(value) >= 1000
              ? window.$Global.commaify(value) + "건"
              : value + "건";
          return datasetLabel + ": " + formattedValue;
        },
      },
    },
  },
};

const techTransfer2 = {
  data: {
    labels: ["대기업", "중기업", "소기업", "개인"],
    datasets: [
      {
        data: [45, 30, 15, 10],
        backgroundColor: [colors[0], colors[1], colors[2], colors[3]],
        hoverBackgroundColor: [colors[0], colors[1], colors[2], colors[3]],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const annualIpc = {
  data: {
    labels: ["23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33"],
    datasets: [
      {
        label: "IPC1",
        data: [3, 4, 3, 5, 2, 3, 4, 5, 6, 4, 3],
        borderColor: colors[0],
        backgroundColor: colors[0],
        fill: false,
        tension: 0.1,
      },
      {
        label: "IPC2",
        data: [4, 3, 2, 4, 5, 3, 4, 3, 4, 5, 2],
        borderColor: colors[1],
        backgroundColor: colors[1],
        fill: false,
        tension: 0.1,
      },
      {
        label: "IPC3",
        data: [2, 3, 1, 3, 4, 5, 3, 4, 3, 4, 3],
        borderColor: colors[2],
        backgroundColor: colors[2],
        fill: false,
        tension: 0.1,
      },
      {
        label: "IPC-N",
        data: [5, 6, 0, 6, 7, 8, 7, 6, 7, 5, 6],
        borderColor: colors[3],
        backgroundColor: colors[3],
        fill: false,
        tension: 0.1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const percent = {
  data: {
    labels: ["Filled", "Remaining"],
    datasets: [
      {
        data: [37, 100 - 37],
        backgroundColor: ["#4e73df", "#dfe5eb"],
        borderWidth: 0,
      },
    ],
  },
  options: {
    rotation: 1 * Math.PI,
    circumference: 1 * Math.PI,
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
  },
};

const survival = {
  data: {
    labels: ["A", "B", "C", "D", "E", "F"],
    datasets: [
      {
        data: [10, 50, 25, 5, 8, 2], // Example data, adjust accordingly
        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        hoverBackgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const survivalIpc = {
  data: {
    labels: ["IPC1", "IPC2", "IPC3", "IPC4", "IPC5", "IPC6", "IPC-n"],
    datasets: [
      {
        data: [30, 15, 10, 5, 10, 20, 10], // Example data, adjust accordingly
        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        hoverBackgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const survivalPatent = {
  data: {
    labels: ["미국", "일본", "중국", "유럽", "한국", "기타"],
    datasets: [
      {
        data: [45, 30, 25, 20, 15, 10], // Example data, adjust accordingly
        backgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        hoverBackgroundColor: [
          colors[0],
          colors[1],
          colors[2],
          colors[3],
          colors[4],
          colors[5],
        ],
        borderWidth: 1,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
  },
};

const floating = {
  data: {
    labels: ["a", "b", "c", "d", "e"],
    datasets: [
      {
        label: "floating",
        data: [
          [-30, 50],
          [60, 80],
          [10, 30],
          [-10, 80],
          [40, 60],
        ],
        backgroundColor: "lightblue",
      },
    ],
  },
  options: {
    scales: {
      yAxes: [
        {
          type: "logarithmic",
          min: 0, // y축의 최소값 설정
          max: 20000000, // y축의 최대값 설정
          stepSize: 1000000, // 눈금 간격 설정
          ticks: {
            callback: function (value) {
              return value.toLocaleString();
            },
          },
        },
      ],
    },
  },
};

const doubleLine = {
  data: {
    labels: ["May 20", "Jun 20", "Jul 20", "Aug 20", "Sep 20"],
    datasets: [
      {
        label: "Dataset 1",
        data: [50, 40, 30, 60, 20],
        borderColor: "rgba(53, 162, 235, 1)",
        backgroundColor: "rgba(53, 162, 235, 0.4)",
        fill: true,
      },
      {
        label: "Dataset 2",
        data: [30, 30, 30, 40, 10],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.4)",
        fill: true,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Chart with Area Fill",
      },
    },
    scales: {
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  },
};

const allYearStatusOptions = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "bottom",
    labels: {
      usePointStyle: true,
      pointStyle: "rectRounded",
    },
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const datasetLabel = dataset.label || "";
        const value = dataset.data[tooltipItem.index];
        return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: function (value) {
            return value + "건";
          },
        },
      },
    ],
  },
};

const annualOption = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (value, index, values) {
            return window.$Global.commaify(value) + "원";
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var value = data.datasets[0].data[tooltipItem.index];
        if (parseInt(value) >= 1000) {
          return window.$Global.commaify(value) + "원";
        } else {
          return value + "원";
        }
      },
    },
  },
};

const annualOption2 = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    position: "bottom",
    labels: {
      usePointStyle: true,
      pointStyle: "rectRounded",
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (value, index, values) {
            return window.$Global.commaify(value) + "원";
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        var datasetLabel = data.datasets[tooltipItem.datasetIndex].label || "";
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        var formattedValue =
          parseInt(value) >= 1000
            ? window.$Global.commaify(value) + "원"
            : value + "원";
        return datasetLabel + ": " + formattedValue;
      },
    },
  },
};

const annualOption3 = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          userCallback: function (value, index, values) {
            return window.$Global.commaify(value) + "원";
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const values =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

        const formattedMinValue = window.$Global.commaify(values[0]) + "원";
        const formattedMaxValue = window.$Global.commaify(values[1]) + "원";

        return `최저: ${formattedMinValue} - 최고: ${formattedMaxValue}`;
      },
    },
  },
};

const patentStatusRegion = {
  options: {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#fff", // Set text color to white
        anchor: "end", // Align the label's center
        align: "start", // Center-align the text
        //offset: 15, // Adjust the offset
        clip: false,
        font: {
          size: 14,
          lineHeight: 1.2, // Adjust line spacing if needed
        },
        formatter: function (value, context) {
          const label = context.chart.data.labels[context.dataIndex];
          const dataset = context.chart.data.datasets[0];
          const total = dataset.data.reduce((sum, val) => sum + val, 0);
          const percentage = (value / total) * 100;

          // Only display the label if the percentage is more than 0% and the value is greater than 0
          if (percentage > 8 && value > 0) {
            return `${window.$Global.commaify(value)}`;
          } else {
            return ""; // Return an empty string to hide the label
          }
        },
      },
    },
  },
};

export {
  patentStatus,
  patentStatusByYear,
  country,
  mainCountry,
  mainCountryByYear,
  techStatus,
  techEval,
  ipc,
  techTransfer,
  techTransfer2,
  annualIpc,
  percent,
  survival,
  survivalIpc,
  survivalPatent,
  floating,
  doubleLine,
  allYearStatusOptions,
  annualOption,
  annualOption2,
  annualOption3,
  patentStatusRegion,
};
