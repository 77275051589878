import React, { useEffect, useState } from "react";
import AdminAPI from "../../API/admin";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./css/PatentMailResChkAuth.scss";

const Confirm_IsGiveup = () => {
  const location = useLocation();
  const state = location.state;

  const [payload, setPayload] = useState({
    hope: null,
    content: "",
    inventor_member_idx: state.inventor_member_idx,
    send_mail_idx: state.send_mail_idx,
  });

  const handleFeedbackChange = (event) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      content: event.target.value,
    })); // content 값 업데이트
  };

  const handleSubmit = async () => {
    const result = await AdminAPI.confirm_IsGiveup(payload);

    if (result.status === 200) {
      if (result.status === 200) {
        if (!alert("응답이 저장되었습니다.")) {
          document.location = "/";
        }
      }
    }
  };

  const handleHopeChange = (e) => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      hope: e.target.value,
    }));
  };

  return (
    <div id="confirm_IsGiveup">
      <div className="annual_mail_page">
        <div className="top">
          <div className="title">
            <i className="icon_annual_res_chk"></i>
            <div className="txt">
              <strong>
                연차료 납부 포기 발명자 권리 양수
                <br />
                여부 응답 요청
              </strong>
              <p>
                본 특허권을 포기 하고자 하오니,
                <br />
                특허권에 대한 발명자의 <br />
                권리 양수 또는 비양수 의사에 대하여 응답하여 <br />
                주시기 바랍니다.
              </p>
            </div>
          </div>
          <div className="steps">
            <p className="one">1</p>
            <p className="two on">2</p>
          </div>
        </div>
        <div className="auth_form">
          <div className="chk_res">
            <ul>
              <li className="yes">
                <strong>
                  <i className="icon_annual_chk"></i>
                  양수 희망
                </strong>
                <p>
                  본 특허권에 대한 권리를
                  <br />
                  양수받을 경우 클릭하세요.
                </p>
                <input
                  type="radio"
                  name="hope"
                  value="1"
                  onChange={(e) => handleHopeChange(e)}
                />
              </li>
              <li className="no">
                <strong>
                  <i className="icon_annual_reject"></i>
                  양수 거부
                </strong>
                <p>
                  본 특허권에 대한 권리를
                  <br />
                  비양수할 경우 클릭하세요.
                </p>
                <input
                  type="radio"
                  name="hope"
                  value="2"
                  onChange={(e) => handleHopeChange(e)}
                />
              </li>
            </ul>
          </div>
          <div className="etc_box">
            <div className="etc">
              <strong>
                <i className="icon_annual_etc"></i>
                기타
              </strong>
              <textarea
                value={payload.content}
                onChange={handleFeedbackChange}
                placeholder="의견을 입력해주세요"
              />
              <input
                type="radio"
                name="hope"
                value="3"
                onChange={(e) => handleHopeChange(e)}
              />
            </div>
          </div>
        </div>
        <div className="btns">
          <button onClick={handleSubmit}>제출</button>
        </div>
      </div>
    </div>
  );
};

export default Confirm_IsGiveup;
