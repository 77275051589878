import React from "react";
import ToggleButtonWithSpan from "../../../common/ToggleButtonWithSpan";

function DivideApplyRow({
  editingClass,
  divideApplication,
  toggleSelected,
  btnHide,
  onOpenReport,
  withCheckpoint,
}) {
  return (
    <div className="row">
      <h2 className="title">
        분할 출원
        {withCheckpoint && <i onClick={onOpenReport} className="icon_check_empty" />}
        <i className="icon_info" data-content={window.$Global.getInfoMessage("분할 출원")} />
      </h2>
      <div className="btns prior">
        <span>
          {divideApplication.date != null
            ? window.$Global.convertDate(divideApplication.date)
            : divideApplication.date == null && divideApplication.value && "N/A"}{" "}
        </span>
        {btnHide ? (
          divideApplication.date == null && !divideApplication.value && "무"
        ) : (
          <ToggleButtonWithSpan
            className={editingClass}
            text={""}
            condition={{ on: "", off: "무" }}
            selected={divideApplication.value}
            toggleSelected={toggleSelected}
          />
        )}
      </div>
    </div>
  );
}

export default DivideApplyRow;
