import React, {useEffect, useState} from "react";
import "./css/SearchResult.scss";
import TradeList from "./TradeList";
import qs from "query-string";
import TradeAPI from "../../API/trade";
import CommonAPI from "../../API/common";
import config from "./const";

function SearchResult({history, location}) {
    const {keyword, type, min, max} = qs.parse(location.search);
    const [curPage, setCurPage] = useState(1);
    const [list, setList] = useState({items: []});
    const [priceRange, setPriceRange] = useState((min || max) ? window.$Global.numberToKorean(min) + '~' + window.$Global.numberToKorean(max) : '');
    const [disable, setDisable] = useState(false);
    const isAllRange = (config.min == min && config.max == max);

    useEffect(() => {
        getList();
    }, []);

    const getList = (payload, next) => {
        CommonAPI.getList(payload || getPayload(false, min, max)).then(res => {
            if (next) {
                res.data.items = list.items.concat(res.data.items);
                setCurPage(curPage + 1);
            }
            setList(res.data);
        });
    };

    const getPayload = (page, min, max) => {
        if (page) {
            setCurPage(1);
        }

        if (isAllRange) {
            min = null;
            max = null;
        }
        return {
            page: page || curPage,
            count: 20,
            keyword: keyword,
            type: type,
            min_price: min,
            max_price: max,
        };
    };

    const research = () => {
        setPriceRange(null);
        getList(getPayload(1, null, null));
    };

    const onClickNextList = () => {
        let payload = getPayload();
        getList(payload, true);
    }

    const onClickAdd = () => {
        TradeAPI.addKeyword({keyword: keyword}).then(res => {
            alert("추가되었습니다");
            setDisable(true);
        });
    };

    return (
        <div id="SearchResult">
            <div className="mo">
                <div className="header">
                    <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                    <h2 className="page_title">상표 검색</h2>
                </div>
                <div className="result">
                <div className="result_head">
                    <div className="result_info">
                        <h2>검색 결과</h2>
                        <p>{window.$Global.commaify(list.total_count || 0)}건</p>
                    </div>
                    {
                        Boolean(keyword) && <button className={`btn_search_add ${disable ? 'disable' : ''}`} onClick={onClickAdd}>검색어 관심 알림 추가하기</button>
                    }
                </div>
                <div className="result_option">
                    {
                        (Boolean(priceRange) && !isAllRange) &&
                        <>
                            <p>{priceRange}</p>
                            <i className="icon_badge_del_white" onClick={research}/>
                        </>
                    }
                </div>

                <TradeList activeDepth={0}
                           query="type=result"
                           list={list.items}
                           hasMore={list.last > list.page}
                           onClickNextList={onClickNextList}
                />
            </div>
            </div>
        </div>
    )
}

export default SearchResult;