import React from 'react';
import './css/CheckBox.scss';

function CheckBox({text, checked, onChangeChecked, disabled}) {
    return (
        <label className={`checkbox ${disabled ? 'disabled' : ''}`}>
            <input type="checkbox" checked={checked} onChange={(e) => onChangeChecked(e, text)}/>
            <span className="checkmark"/>
            {
                text && <span className="value">{text}</span>
            }
        </label>
    );
}

export default React.memo(CheckBox);