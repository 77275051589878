import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MAnnualCountView from "../components/annualCount/mobile/MAnnualCountView";
import AnnualCountGraph from "../components/annualCount/AnnualCountGraph";
import AnnualCountList from "../components/annualCount/AnnualCountList";
import CustomSwitch from "./common/CustomSwitch";

function AnnualCountRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/mview`} requireAuth={false} component={MAnnualCountView}/>
			<UserAuthRoute exact path={`${match.path}/graph`} requireAuth={true} component={AnnualCountGraph}/>
			<UserAuthRoute exact path={`${match.path}/:year/list`} requireAuth={true} component={AnnualCountList}/>
		</CustomSwitch>
	)
}

export default AnnualCountRoute;