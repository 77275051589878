import React, {useContext, useEffect, useState} from "react";
import "./GradeCompany.scss";
import CompanyAPI from "../../API/company";
import memberContext from "../../store/Member";
import {HorizontalBar} from "react-chartjs-2";

function GradeCompany({data, history}) {
    const {auth} = useContext(memberContext);
    const isLogin = auth != null;
    // 0: 전체, 1: 종합평가, 2: 정량 평가, 3: 정성 평가
    const [selectTab, setSelectTab] = useState(1);
    const [gradeData, setGradeData] = useState({});
    const [companyGrade, setCompanyGrade] = useState('N');

    const chartRef = React.useRef();
    const gradeRef = React.useRef();
    const totalRef = React.useRef();
    const quantityRef = React.useRef();
    const qualitylRef = React.useRef();

    const isCompany = window.$Global.checkAuth("특허회사", auth);
    const isInvestor = window.$Global.checkAuth("투자자", auth);

    const bgColorClass = selectTab == 1 ? 'orange' : selectTab == 2 ? 'green' : 'blue';

    useEffect(() => {
        if (isCompany) {
            // prop 값이 없으면 api 호출
            if (data) {
                setGradeData(data);
            } else {
                if (isLogin) {
                    CompanyAPI.getCompanyGrade().then(res => setGradeData(res.data));
                }
            }
        } else if (isInvestor) {
            setGradeData(data)
        } else {
            setGradeData({
                quality: 'B',
                quantity: 'A',
                total: 'S'
            })
        }
    }, []);

    useEffect(() => {
        let grade = 'N';

        if (selectTab == 1) {
            grade = gradeData.total;
        } else if (selectTab == 2) {
            grade = gradeData.quantity;
        } else if (selectTab == 3) {
            grade = gradeData.quality;
        }

        setCompanyGrade(grade || 'N');

        for (let i = 0; i < chartRef.current.childNodes.length; i++) {
            let item = chartRef.current.childNodes[i];

            if (item.dataset.grade == grade) {
                item.style['opacity'] = 1;
            } else {
                item.style['opacity'] = 0;
            }
        }

        for (let i = 1; i < gradeRef.current.childNodes.length; i++) {
            let item = gradeRef.current.childNodes[i];

            if (item.dataset.grade == grade) {
                item.style['opacity'] = 1;
                item.classList.add('active');
            } else {
                item.style['opacity'] = 0.3;
                item.classList.remove('active');
            }
        }
    }, [gradeData, selectTab]);

    const onClickChangeTab = (depth) => {
        setSelectTab(depth);
    };

    const line_chart_width = Math.floor(window.$Global.getBrowserSize().width / 1.1);

    let labels = ['SS', 'S+', 'S', 'S-', 'A+', 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'E'];
    let backgroundColor = [];
    let backgroundColorActive = ['#22c7e3', '#0780fb', '#0780fb', '#0780fb', '#5b90df', '#5b90df', '#5b90df', '#ffcd4d', '#ffcd4d', '#ffcd4d', '#ffa600', '#ffa600', '#ffa600', '#ff6159', '#ff6159', '#ff6159', '#000000'];


    let grade = '';
    if (selectTab == 1) {
        grade = gradeData.total;
    } else if (selectTab == 2) {
        grade = gradeData.quantity;
    } else if (selectTab == 3) {
        grade = gradeData.quality;
    }
    let find_idx = labels.findIndex(item => item == grade);

    for (let i = 0; i < labels.length; i++) {
        if (i == find_idx) {
            backgroundColor.push(backgroundColorActive[i]);
        } else {
            backgroundColor.push('rgba(216, 216, 216, 0.4)');
        }
    }

    const m_data = {
        labels: labels,
        datasets: [{
            data: [20, 30, 30, 30, 50, 50, 50, 70, 90, 70, 50, 50, 50, 30, 30, 30, 20],
            labels: [
                '등록',
                '출원',
                '포기'
            ],
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor,
            borderWidth: 0,
        }]
    };

    const m_annualFee_options = {
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false
        },
        responsive: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            xAxes: [{
                ticks: {
                    display: false,
                    beginAtZero: true,
                    max : 100,
                    stepSize: 100 / 4,
                    maxTicksLimit: 5,
                },
                gridLines: {
                    display : true,
                    borderDash: [4],
                    color: "#d8d8d8",
                    zeroLineColor: '#aaaaaa',
                    zeroLineWidth: 2,
                    drawBorder: false,
                    drawOnChartArea: true,
                    drawTicks: true
                }
            }],
            yAxes: [{
                ticks: {
                    fontColor : '#959595',
                    fontSize: 10
                },

                gridLines: {
                    display : false,
                }
            }]
        },
        plugins: {
            datalabels: {
                display: false,
            }
        }
    };

    return (
        <div id="GradeCompany">
            <div className="pc">
                <div className="header">
                    <h2>우리 회사/개인 기술 역량 평가</h2>
                    {
                        !data &&
                        <div className="area_box">
                            <img src={require('../../assets/images/grade/bg_intro.png').default} className="distribution" alt="이미지"/>
                        </div>
                    }

                    <ul className="tabs">
                        <li className={`tab ${selectTab == 1 ? 'active' : ''}`} onClick={() => onClickChangeTab(1)}>
                            <div className="circle orange"/>
                            종합 평가
                        </li>
                        <li className={`tab ${selectTab == 2 ? 'active' : ''}`} onClick={() => onClickChangeTab(2)}>
                            <div className="circle green"/>
                            정량 평가
                        </li>
                        <li className={`tab ${selectTab == 3 ? 'active' : ''}`} onClick={() => onClickChangeTab(3)}>
                            <div className="circle blue"/>
                            정성 평가
                        </li>
                    </ul>
                    <div className="cur_grade">
                        <div className={`grade_${((companyGrade || 'default') + "").toString().replace("+", "").replace("-", "").replace("0", "")}`}>{companyGrade || 'N'}</div>
                        <p>등급입니다.</p>
                    </div>
                </div>
                <div className="chart">
                    <img src={require('../../assets/images/common/bg_distribution.svg').default} className="distribution" alt="이미지"/>
                    <img src={require('../../assets/images/common/bg_chart_x_line.svg').default} className="line" alt="이미지"/>
                    <div className="active_chart" ref={chartRef}>
                        <img src={require('../../assets/images/common/img_chart_grade_SS.svg').default} data-grade="SS" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_S.svg').default}  data-grade="S+" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_S0.svg').default} data-grade="S" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_S-.svg').default} data-grade="S-" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_A.svg').default}  data-grade="A+" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_A0.svg').default} data-grade="A" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_A-.svg').default} data-grade="A-" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_B.svg').default}  data-grade="B+" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_B0.svg').default} data-grade="B" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_B-.svg').default} data-grade="B-" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_C.svg').default}  data-grade="C+" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_C0.svg').default} data-grade="C" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_C-.svg').default} data-grade="C-" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_D.svg').default}  data-grade="D+" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_D0.svg').default} data-grade="D" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_D-.svg').default} data-grade="D-" alt="이미지"/>
                        <img src={require('../../assets/images/common/img_chart_grade_E.svg').default}  data-grade="E" alt="이미지"/>
                    </div>

                    {
                        (selectTab === 0) &&
                        <>
                            <div className="wrap_circle orange" ref={totalRef}>
                                <div className="circle orange" data-grade="SS"/>
                                <div className="circle orange" data-grade="S+"/>
                                <div className="circle orange" data-grade="S"/>
                                <div className="circle orange" data-grade="S-"/>
                                <div className="circle orange" data-grade="A+"/>
                                <div className="circle orange" data-grade="A"/>
                                <div className="circle orange" data-grade="A-"/>
                                <div className="circle orange" data-grade="B+"/>
                                <div className="circle orange" data-grade="B"/>
                                <div className="circle orange" data-grade="B-"/>
                                <div className="circle orange" data-grade="C+"/>
                                <div className="circle orange" data-grade="C"/>
                                <div className="circle orange" data-grade="C-"/>
                                <div className="circle orange" data-grade="D+"/>
                                <div className="circle orange" data-grade="D"/>
                                <div className="circle orange" data-grade="D-"/>
                                <div className="circle orange" data-grade="E"/>
                            </div>
                            <div className="wrap_circle green" ref={quantityRef}>
                                <div className="circle green" data-grade="SS"/>
                                <div className="circle green" data-grade="S+"/>
                                <div className="circle green" data-grade="S"/>
                                <div className="circle green" data-grade="S_"/>
                                <div className="circle green" data-grade="A+"/>
                                <div className="circle green" data-grade="A"/>
                                <div className="circle green" data-grade="A-"/>
                                <div className="circle green" data-grade="B+"/>
                                <div className="circle green" data-grade="B"/>
                                <div className="circle green" data-grade="B-"/>
                                <div className="circle green" data-grade="C+"/>
                                <div className="circle green" data-grade="C"/>
                                <div className="circle green" data-grade="C-"/>
                                <div className="circle green" data-grade="D+"/>
                                <div className="circle green" data-grade="D"/>
                                <div className="circle green" data-grade="D-"/>
                                <div className="circle green" data-grade="E"/>
                            </div>
                            <div className="wrap_circle blue" ref={qualitylRef}>
                                <div className="circle blue" data-grade="SS"/>
                                <div className="circle blue" data-grade="S+"/>
                                <div className="circle blue" data-grade="S"/>
                                <div className="circle blue" data-grade="S-"/>
                                <div className="circle blue" data-grade="A+"/>
                                <div className="circle blue" data-grade="A"/>
                                <div className="circle blue" data-grade="A-"/>
                                <div className="circle blue" data-grade="B+"/>
                                <div className="circle blue" data-grade="B"/>
                                <div className="circle blue" data-grade="B-"/>
                                <div className="circle blue" data-grade="C+"/>
                                <div className="circle blue" data-grade="C"/>
                                <div className="circle blue" data-grade="C-"/>
                                <div className="circle blue" data-grade="D+"/>
                                <div className="circle blue" data-grade="D"/>
                                <div className="circle blue" data-grade="D-"/>
                                <div className="circle blue" data-grade="E"/>
                            </div>
                        </>
                    }
                </div>
                <div className={`table_grade ${bgColorClass}`} ref={gradeRef}>
                    <div className="data">
                        <h2>등급</h2>
                        <p>백분율</p>
                    </div>
                    <div className="data ac" data-grade="SS">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_SS">SS</div>
                        <p>4%</p>
                    </div>
                    <div className="data" data-grade="S+">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_S">S+</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="S">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_S">S</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="S-">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_S">S-</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="A+">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_A">A+</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="A">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_A">A</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="A-">
                        <div className="grade_A">A-</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="B+">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_B">B+</div>
                        <p>8%</p>
                    </div>
                    <div className="data" data-grade="B">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_B">B</div>
                        <p>10%</p>
                    </div>
                    <div className="data" data-grade="B-">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_B">B-</div>
                        <p>8%</p>
                    </div>
                    <div className="data" data-grade="C+">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_C">C+</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="C">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_C">C</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="C-">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_C">C-</div>
                        <p>6%</p>
                    </div>
                    <div className="data" data-grade="D+">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_D">D+</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="D">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_D">D</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="D-">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_D">D-</div>
                        <p>5%</p>
                    </div>
                    <div className="data" data-grade="E">
                        <div className="border_left_circle"/>
                        <div className="border_right_circle"/>
                        <div className="grade_E">E</div>
                        <p>4%</p>
                    </div>
                    <div className="data not" data-grade="N">
                        <div className="grade_default">N</div>
                        <p>정보없음</p>
                    </div>
                </div>
            </div>

            <div className="mo">
                <div className="header">
                    <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                    <h2 className="page_title">우리회사 기술 역량 평가</h2>
                </div>
                <div className="wrap_head">
                    <h2>{selectTab == 1 ? '종합 평가' : selectTab == 2 ? '정량 평가' : '정성 평가'}</h2>
                    <select onChange={e => onClickChangeTab(e.target.value)}>
                        <option value={1}>종합 평가</option>
                        <option value={2}>정량 평가</option>
                        <option value={3}>정성 평가</option>
                    </select>
                </div>
                {
                    !isLogin &&
                    <div className="alert_msg not_login">
                        <i className="icon_alert_circle"/>
                        <p>현재 보시는 등급은 테스트 데이터로 산출한 평가입니다.</p>
                    </div>
                }
                <HorizontalBar data={m_data} width={line_chart_width} height={480} options={m_annualFee_options}/>

                <div className="alert_msg">
                    <i className="icon_alert_circle"/>
                    <p>산업분야에서 IP에 대한 평가를 기준으로 <br/>기업의 기술 역량 평가 제공</p>
                </div>
            </div>
        </div>
    )
}

export default GradeCompany;
