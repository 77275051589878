import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import CyberSignup from "../components/cyber/CyberSignup";
import CyberSignupSuccess from "../components/cyber/CyberSignupSuccess";
import CyberLogin from "../components/cyber/CyberLogin";
import RouteIf from "./common/RouteIf";
import CyberMain from "../components/cyber/CyberMain";
import CyberCompanyAdd from "../components/cyber/CyberCompanyAdd";
import CyberCompanyAdded from "../components/cyber/CyberCompanyAdded";
import CyberCompanyManualAdd from "../components/cyber/CyberCompanyManualAdd";
import CyberMyPoint from "../components/cyber/CyberMyPoint";
import CyberNotify from "../components/cyber/CyberNotify";
import CyberPointChange from "../components/cyber/CyberPointChange";
import CyberChangeApply from "../components/cyber/CyberChangeApply";
import CyberPointHistory from "../components/cyber/CyberPointHistory";
import CyberAccount from "../components/cyber/CyberAccount";
import CustomSwitch from "./common/CustomSwitch";

function CyberRoute({match}) {
	const auth = window.$Global.hasLoginToken();
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/signup`} requireAuth={false} component={CyberSignup}/>
			<UserAuthRoute exact path={`${match.path}/signupSuccess`} requireAuth={false} component={CyberSignupSuccess}/>
			<UserAuthRoute exact path={`${match.path}/login`} requireAuth={false} component={CyberLogin}/>
			<RouteIf exact path={`${match.path}`} condition={auth} redirectPath={`${match.path}/login`} component={CyberMain}/>
			<RouteIf exact path={`${match.path}/add`} condition={auth} redirectPath={`${match.path}/login`} component={CyberCompanyAdd}/>
			<RouteIf exact path={`${match.path}/added`} condition={auth} redirectPath={`${match.path}/login`} component={CyberCompanyAdded}/>
			<RouteIf exact path={`${match.path}/manual`} condition={auth} redirectPath={`${match.path}/login`} component={CyberCompanyManualAdd}/>
			<RouteIf exact path={`${match.path}/point`} condition={auth} redirectPath={`${match.path}/login`} component={CyberMyPoint}/>
			<RouteIf exact path={`${match.path}/notify`} condition={auth} redirectPath={`${match.path}/login`} component={CyberNotify}/>
			<RouteIf exact path={`${match.path}/pointChange`} condition={auth} redirectPath={`${match.path}/login`} component={CyberPointChange}/>
			<RouteIf exact path={`${match.path}/pointChangeApply`} condition={auth} redirectPath={`${match.path}/login`} component={CyberChangeApply}/>
			<RouteIf exact path={`${match.path}/pointHistory`} condition={auth} redirectPath={`${match.path}/login`} component={CyberPointHistory}/>
			<RouteIf exact path={`${match.path}/account`} condition={auth} redirectPath={`${match.path}/login`} component={CyberAccount}/>

		</CustomSwitch>
	)
}

export default CyberRoute;