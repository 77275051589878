import "../css/RenewalIntro.scss";
import SearchInterestedForm from "../../home/SearchInterestedForm";
import Moment from "react-moment";

const RenewalIntro = () => {
  const dateTime = Date.now();

  return (
    <div id="Renewal">
      <div id="RenewalSidebar">
        <div className="userInfo">
          <div className="userImage"></div>
          <div className="userProfile">
            <p className="department">플랫폼 사업팀</p>
            <p className="user_name">
              <span>이연희</span> 대리님
            </p>
            <p className="dateTime">
              <Moment format="yyyy-MM-DD ddd">{dateTime}</Moment>
              <div className="line">|</div>
              <Moment format="HH:mm:ss" interval={1000}>
                {dateTime}
              </Moment>
            </p>
          </div>
          <div className="vacation">
            <p>잔여 휴가</p>
            <p>
              <span>3</span>일 | 15일
            </p>
          </div>
        </div>
        <div className="recentUseCategory">
          <p>비즈내비 최근 이용 서비스:</p>
        </div>
      </div>
      <div id="RenewalIntro">
        <iframe src="http://bizoffi.timworks.kr/" width="100%" height="100%" />
        {/*/!*<SearchInterestedForm />*/}
        {/*<div className="grid_wrapper">*/}
        {/*  <div>그룹웨어 서비스1</div>*/}
        {/*  <div>전자결재</div>*/}
        {/*  <div>주간업무보고</div>*/}
        {/*  <div>메일 리스트</div>*/}
        {/*  <div>웹하드</div>*/}
        {/*  <div>주소록</div>*/}
        {/*  <div>전자게시</div>*/}
        {/*</div>*!/*/}
      </div>
    </div>
  );
};

export default RenewalIntro;
