import React, { useContext, useState } from "react";
import TradeMarkList from "../components/trademark/TradeMarkListView";
import MTradeMarkListView from "../components/trademark/mobile/MTradeMarkListView";
import UserAuthRoute from "./common/UserAuthRoute";
import MTradeMarkDetail from "../components/trademark/mobile/MTradeMarkDetail";
import TradeMarkDetail from "../components/trademark/TradeMarkDetail";
import TradeMarkRelativeFileListView from "../components/trademark/relativeFile/RelativeFileListView";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import TradeMarkContext from "../store/TradeMark";
import TradeMarkFileTypeContext from "../store/TradeMarkFileType";
import TradeMarkDisputeContext from "../store/TradeMarkDispute";
import PatentList from "../components/patent/PatentListView";
import MPatentListView from "../components/patent/mobile/MPatentListView";
import RenewalTradeMarkDetail from "../components/trademark/RenewalTradeMarkDetail";
import TradeMarkDispute from "../components/trademark/TradeMarkDispute";
import TradeMarkMemo from "../components/trademark/TradeMarkMemo";
import TradeMarkFamily from "../components/trademark/TradeMarkFamily";
import TradeMarkDisputeMemo from "../components/trademark/TradeMarkDisputeMemo";
import TradeMarkEtc from "../components/trademark/TradeMarkEtc";

function TradeMarkRoute({ match }) {
  let trademarkInfo = useContext(TradeMarkContext);
  let trademarkFile = useContext(TradeMarkFileTypeContext);
  let trademarkDispute = useContext(TradeMarkDisputeContext);
  const [tradeMarkData, setTradeMarkData] = useState(trademarkInfo);
  const [tradeMarkFileType, setTradeMarkFileType] = useState(trademarkFile);
  const [tradeMarkDisputeData, setTradeMarkDisputeData] =
    useState(trademarkDispute);

  return (
    <TradeMarkContext.Provider value={tradeMarkData}>
      <TradeMarkFileTypeContext.Provider value={tradeMarkFileType}>
        <TradeMarkDisputeContext.Provider value={tradeMarkDisputeData}>
          <CustomSwitch>
            <CacheRoute
              exact
              path={`${match.path}/list`}
              render={(props) =>
                window.$Global.renderFunc(props, TradeMarkList)
              }
              when="always"
            />
            <CacheRoute
              exact
              path={`${match.path}/mlist`}
              render={(props) =>
                window.$Global.renderFunc(props, MTradeMarkListView)
              }
              when="always"
            />
            <UserAuthRoute
              exact
              path={`${match.path}/mobile/:trademark_idx`}
              requireAuth={true}
              component={MTradeMarkDetail}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/detail/:trademark_idx`}
              requireAuth={true}
              component={RenewalTradeMarkDetail}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/dispute/:trademark_idx`}
              requireAuth={true}
              component={TradeMarkDispute}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/memo/:trademark_idx`}
              requireAuth={true}
              component={TradeMarkMemo}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/family/:trademark_idx`}
              requireAuth={true}
              component={TradeMarkFamily}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/etc/:trademark_idx`}
              requireAuth={true}
              component={TradeMarkEtc}
            />
            <UserAuthRoute
              exact
              path={`${match.path}/dispute/memo/:trademark_idx/:dispute_idx`}
              requireAuth={true}
              component={TradeMarkDisputeMemo}
            />
            {/*<UserAuthRoute*/}
            {/*  exact*/}
            {/*  path={`${match.path}/detail/:trademark_idx`}*/}
            {/*  requireAuth={true}*/}
            {/*  component={RenewalTradeMarkDetail}*/}
            {/*/>*/}
            <UserAuthRoute
              exact
              path={`${match.path}/relativeFile/:trademark_idx`}
              requireAuth={true}
              component={TradeMarkRelativeFileListView}
            />
          </CustomSwitch>
        </TradeMarkDisputeContext.Provider>
      </TradeMarkFileTypeContext.Provider>
    </TradeMarkContext.Provider>
  );
}

export default TradeMarkRoute;
