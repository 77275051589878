import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import ConsultComMain from "../components/consultCom/ConsultComMain";
import ConsultComDetail from "../components/consultCom/ConsultComDetail";
import CustomSwitch from "./common/CustomSwitch";

function ConsultComRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={false} component={ConsultComMain}/>
			<UserAuthRoute exact path={`${match.path}/detail/:idx`} requireAuth={false} component={ConsultComDetail}/>
		</CustomSwitch>
	)
}

export default ConsultComRoute;