import React, {useEffect, useState} from "react";
import "./css/PopupAnnualFeeCalc.scss";
import CheckBox from "../common/CheckBox";
import _ from "lodash";

function PopupAnnualFeeCalc({option, onClickSuccess, onClickClose}) {
    const [sizeList, setSizeList] = useState([false, false, false, false]);
    const [personCountList, setPersonCountList] = useState([false, false]);
    const [etcList, setEtcList] = useState([false, false, false, false, false]);

    useEffect(() => {
        let payload = option;
        let copy_size = _.cloneDeep(sizeList);
        let copy_person = _.cloneDeep(personCountList);
        let copy_etc = _.cloneDeep(etcList);

        if (!window.$Global.isEmptyObject(payload)) {
            copy_size[payload.business] = true;
            copy_person[payload.mem_cnt] = true;

            let index = 0;
            if (payload.ec == 0) {
                index = 0;
            } else if (payload.ip == 0) {
                index = 1;
            } else if (payload.joint == 0) {
                index = 2;
            } else if (payload.lg == 0) {
                index = 3;
            } else if (payload.us_mc == 0) {
                index = 4;
            }
            copy_etc[index] = true;
        } else {
            copy_size[0] = true;
            copy_person[0] = true;
            copy_etc[0] = true;
        }
        setSizeList(copy_size);
        setPersonCountList(copy_person);
        setEtcList(copy_etc);
    }, []);

    const onClickSelect = (e, index, prop, setProp) => {
        let list = prop.map(() => false);
        let checked = e.target.checked;

        if (checked) {
            list[index] = checked;
            setProp(list);
        }
    };

    const onClickList = () => {
        let selectSize = sizeList.findIndex(item => item);
        let selectPerson = personCountList.findIndex(item => item);
        let selectEtc = etcList.findIndex(item => item);

        let payload = {
            business: selectSize,
            mem_cnt: selectPerson
        };

        switch (selectEtc) {
            case 0:
                payload.ec = 0;
                break;
            case 1:
                payload.ip = 0;
                break;
            case 2:
                payload.joint = 0;
                break;
            case 3:
                payload.lg = 0;
                break;
            case 4:
                payload.us_mc = 0;
                break;
        }
        onClickSuccess(payload);
    };

    return (
        <div id="PopupAnnualFeeCalc">
            <div className="header">
                <h2>연차료 조건 설정</h2>
                <div className="btns">
                    <button className="btn_select" onClick={onClickList}>설정완료</button>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
            </div>
            <p className="desc">
                <i className="icon_alert_circle"/>
                <span>정확한 연차료 계산을 위해 귀사에 해당되는 기업규모를 선택해 주세요.</span>
            </p>
            <div className="type">
                <div className="row">
                    <p className="title">기업규모</p>
                    <div className="checks">
                        <CheckBox text="개인or스타트업" checked={sizeList[0]} onChangeChecked={(e) => onClickSelect(e, 0, sizeList, setSizeList)}/>
                        <CheckBox text="소기업" checked={sizeList[1]} onChangeChecked={(e) => onClickSelect(e, 1, sizeList, setSizeList)}/>
                        <CheckBox text="중기업" checked={sizeList[2]} onChangeChecked={(e) => onClickSelect(e, 2, sizeList, setSizeList)}/>
                        <CheckBox text="중소벤처기업" checked={sizeList[3]} onChangeChecked={(e) => onClickSelect(e, 3, sizeList, setSizeList)}/>
                    </div>
                </div>
                <div className="row">
                    <p className="data title">직원수</p>
                    <div className="checks">
                        <CheckBox text="500명 미만" checked={personCountList[0]} onChangeChecked={(e) => onClickSelect(e, 0, personCountList, setPersonCountList)}/>
                        <CheckBox text="500명 이상" checked={personCountList[1]} onChangeChecked={(e) => onClickSelect(e, 1, personCountList, setPersonCountList)}/>
                    </div>
                </div>
                <div className="row">
                    <p className="title">기타</p>
                    <div className="checks line">
                        <CheckBox text="직무발명 보상 우수기업" checked={etcList[0]} onChangeChecked={(e) => onClickSelect(e, 0, etcList, setEtcList)}/>
                        <CheckBox text="지식 재산 경영 인증" checked={etcList[1]} onChangeChecked={(e) => onClickSelect(e, 1, etcList, setEtcList)}/>
                        <CheckBox text="대기업&중소기업 공동연구" checked={etcList[2]} onChangeChecked={(e) => onClickSelect(e, 2, etcList, setEtcList)}/>
                        <CheckBox text="지방자치단체" checked={etcList[3]} onChangeChecked={(e) => onClickSelect(e, 3, etcList, setEtcList)}/>
                        <CheckBox text="전년도 소득 $206,109 (2021년 기준)미달  & 누적 미국 출원건 4건 이하" checked={etcList[4]} onChangeChecked={(e) => onClickSelect(e, 4, etcList, setEtcList)}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PopupAnnualFeeCalc;
