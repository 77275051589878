import "./css/MailingForm.scss";
import { useCallback, useContext, useEffect, useState } from "react";
import PatentContext from "../../../store/Patent";
import DragDrop from "./DragDrop";
import PatentAPI from "../../../API/patent";
import AdminAPI from "../../../API/admin";

// Type NRAIQICJQLZR1D8G: 납부의사 / 51XFEDQHQB2ENCIYEV: 포기여부
// setShowMail: 메일창을 닫기 위해 사용

const MailingForm = ({ type, setShowMail, registFileKey, patentData }) => {
  const patent = patentData;
  console.log(patent);
  // 공고전문 다운로드
  const [downloadUrl, setDownloadUrl] = useState("");
  const [typeNum, setTypeNum] = useState(0);
  const [mailInfo, setMailInfo] = useState({
    title: `특허 등록번호 (${patent.register_number})의 ${
      type === "0"
        ? "연차료 납부 의사 회신 요청"
        : "존속권리 포기 예정 및 양수 여부 회신 요청"
    }`,
    from: `${window.sessionStorage.getItem(
      "name"
    )}<${window.sessionStorage.getItem("email")}>`,
    to: patent.inventor
      .map((item) => {
        return `${item.inventor_name}<${item.email}>`;
      })
      .join(","),
    reply_deadline: 0,
    mailing_form_idx: "",
  });
  const [text, setText] = useState("안녕하세요.");
  const [files, setFiles] = useState([]);

  let inventorIdxs = "";
  let formData = new FormData();

  useEffect(() => {
    const fetchData = async () => {
      let mailing_form_idx_by_type = null;
      if (type) {
        if (type === "0") {
          setTypeNum(0);
          mailing_form_idx_by_type = await getMailingIdx(1);
        } else {
          setTypeNum(1);
          mailing_form_idx_by_type = await getMailingIdx(2);
        }
        setMailInfo({
          ...mailInfo,
          ["mailing_form_idx"]: mailing_form_idx_by_type,
        });
      }
    };
    fetchData();
    setDownloadUrl(`/api/v1/common/inventor/regist/${registFileKey}/download`);
  }, []);

  const getMailingIdx = async (type) => {
    const result = await AdminAPI.getMailingFormIdxByIdx(type);
    return result.data.mailingFormIdx[0].mailing_form_idx;
  };

  const typeText = (type) => {
    let text;
    if (type === "0") {
      text = (
        <p>
          상기 특허에 대한 연차료 납부 기한 안내 드립니다 <br />본 특허에 대한
          발명자의 특허권 유지 및 연차료 납부 의사 회신 부탁 드립니다.
        </p>
      );
    } else if (type === "1") {
      text = (
        <p>
          상기 특허에 대한 존속 권리가 포기될 예정임을 안내해 드립니다.
          <br />
          이에 대한 양수 여부를 체크 부탁 드리며,
          <br />
          미회신의 경우 양수를 원하지 않음으로 간주하고 해당 권리는 만료됨을
          알려드립니다.
        </p>
      );
    }
    return text;
  };

  const handleMailInfoChange = (e, key) => {
    setMailInfo({ ...mailInfo, [key]: e.target.value });
  };

  // 메일 전송 API에 Payload로 사용하기 위해 FormData 작업
  const sendData = () => {
    const html = parseHTML();

    files.forEach((item) => {
      formData.append("files", item.object);
    });

    // 발명자 idx ,로 합치기
    if (patent.inventor.length) {
      patent.inventor.map((inventor, index) => {
        if (index === patent.inventor.length - 1) {
          inventorIdxs += inventor.inventor_idx;
        } else {
          inventorIdxs += inventor.inventor_idx + ",";
        }
      });
      formData.append("inventor_member_idx", inventorIdxs);
    }

    formData.append("text", text);
    formData.append("from_address", mailInfo.from);
    formData.append("to_address", mailInfo.to);
    formData.append("invention_idx", patent.invention_idx);
    formData.append("ip_idx", patent.idx);
    formData.append("year", patent.year);
    formData.append("category", "P");
    formData.append("company_idx", patent.company_idx);
    formData.append("subject", mailInfo.title);
    formData.append("content", html);
    formData.append("mailing_form_idx", mailInfo.mailing_form_idx);
    formData.append("type", typeNum);
    formData.append("reply_deadline", mailInfo.reply_deadline);
    formData.append("apply_number", patent.apply_number);
    formData.append("apply_at", window.$Global.convertDateDot(patent.apply_at));
    formData.append("register_number", patent.register_number);
    formData.append(
      "register_at",
      window.$Global.convertDateDot(patent.register_at)
    );
    formData.append("invention_name", patent.invention_name);
    formData.append(
      "annual_payment_deadline",
      window.$Global.convertDateDot(patent.annual_payment_deadline)
    );
    formData.append("regist_file_address", downloadUrl);
    formData.append("regist_file_key", registFileKey);

    PatentAPI.giveUpMailSend(formData).then((res) => {
      if (res.status != 200) {
        alert("메일 전송 실패");
      } else {
        alert("메일이 전송되었습니다.");
        setShowMail(false);
      }
    });
  };

  // FormData content의 value값인 HTML 코드
  const parseHTML = () => {
    let html =
      type === "0"
        ? "<p>상기 특허에 대한 연차료 납부 기한 안내 드립니다 <br/>본 특허에 대한 발명자의 특허권 유지 및 연차료 납부 의사 회신 부탁 드립니다.</p>"
        : "<p>상기 특허에 대한 존속 권리가 포기될 예정임을 안내해 드립니다.<br/>이에 대한 양수 여부를 체크 부탁 드리며,<br />미회신의 경우 양수를 원하지 않음으로 간주하고 해당 권리는 만료됨을 알려드립니다.</p>";

    return `
    <div style="max-width: 600px;padding: 30px;margin: 20px;border: 2px solid #f6a310;border-radius: 20px;font-family: 'Pretendard';">
       <p style="margin-bottom: 10px;display: block;">${text}</p>
       <div className="patent_info" style="padding-top: 20px;">
        <p style="padding-bottom: 4px;"><strong>출원번호</strong> : ${
          patent.apply_number
        } (${window.$Global.convertDateDot(patent.apply_at)})</p>
        <p style="padding-bottom: 4px;"><strong>등록번호</strong> : ${
          patent.register_number
        } (${window.$Global.convertDateDot(patent.register_at)})</p>
        <p style="padding-bottom: 4px;"><strong>발명자</strong> : ${patent.inventor
          .map((item) => {
            return item.name;
          })
          .join(",")}</p>
        <p style="padding-bottom: 4px;"><strong>발명의 명칭</strong> : ${
          patent.invention_name
        }</p>
        <p style="padding-bottom: 4px;"><strong>연차료 납부 기한</strong> : ${window.$Global.convertDateDot(
          patent.annual_payment_deadline
        )}</p>
      </div>
      <div style="margin-top: 10px; color: red; border-top: 1px solid #d7d7d7; font-weight: bold;">
         ${html}
        <p style="padding-top: 10px;">회신 기한: 이메일 송부일로부터 ${
          mailInfo.reply_deadline
        } 일 </p>
      </div>
    </div>`;
  };

  return (
    <div id="MailingForm">
      <header>
        <strong>메일</strong>
        <i className="icon_exit_gray" onClick={() => setShowMail(false)} />
      </header>
      <div className="box">
        <table>
          <colgroup>
            <col width="14%" />
            <col width="86%" />
          </colgroup>
          <tr>
            <td colSpan={2}>
              <input
                type="text"
                value={mailInfo.title}
                className="title"
                onChange={(e) => handleMailInfoChange(e, "title")}
              />
            </td>
          </tr>
          <tr>
            <th>보내는 사람</th>
            <td>
              <input
                type="text"
                value={mailInfo.from}
                onChange={(e) => handleMailInfoChange(e, "from")}
              />
            </td>
          </tr>
          <tr>
            <th>받는 사람</th>
            <td>
              <input
                type="text"
                value={mailInfo.to}
                onChange={(e) => handleMailInfoChange(e, "to")}
              />
            </td>
          </tr>
        </table>
        <div className="cont">
          <textarea value={text} onChange={(e) => setText(e.target.value)} />
          <div className="patent_info" style={{ margin: "30px 0" }}>
            <p>
              출원번호 : {patent.apply_number} (
              {window.$Global.convertDateDot(patent.apply_at)})
            </p>
            <p>
              등록번호 : {patent.register_number} (
              {window.$Global.convertDateDot(patent.register_at)})
            </p>
            <p>
              발명자 :{" "}
              {patent.inventor
                .map((item) => {
                  return item.inventor_name;
                })
                .join(",")}
            </p>
            <p>발명의 명칭 : {patent.invention_name}</p>
            <p>
              연차료 납부 기한 :{" "}
              {window.$Global.convertDateDot(patent.annual_payment_deadline)}
            </p>
          </div>
          <div style={{ color: "red", lineHeight: "2" }}>
            {typeText(type)}
            <p>
              회신 기한 : 이메일 송부일로부터{" "}
              <input
                type="number"
                value={mailInfo.reply_deadline}
                onChange={(e) => handleMailInfoChange(e, "reply_deadline")}
              />
              일 (고객이 설정한 날짜)
            </p>
          </div>
          <div>
            {patent.register_file_cnt ? <i className="icon_pdf"></i> : ""}
          </div>
          <DragDrop files={files} setFiles={setFiles} />
        </div>
      </div>
      <footer>
        <button onClick={() => sendData()}>전송하기</button>
      </footer>
    </div>
  );
};

export default MailingForm;
