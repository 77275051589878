import React from "react";

const JointApplyRow = ({
  item,
  patentInfo,
  index,
  onClickJointApplyAdd,
  onChangeJointApplyInput,
  onClickJointApplyDelete,
  onOpenReport,
  isCheckpointsExists = () => false,
}) => {
  return (
    <div key={index} className="rows">
      <div className={`row`}>
        <div className="title">
          <h2 className='title'>
            공동출원인
            {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "공동출원인" }) && (
              <i
                onClick={() => onOpenReport({ mainCategory: "서지사항 Check", subCategory: "공동출원인" })}
                className="icon_check_empty"
              />
            )}
            <i className="icon_info" data-content={window.$Global.getInfoMessage("공동출원인")} />
          </h2>
          {patentInfo.editing &&
            (index === 0 ? (
              <button className="icon_plus" onClick={onClickJointApplyAdd} />
            ) : (
              <button className="icon_badge_del" onClick={() => onClickJointApplyDelete(index)} />
            ))}
        </div>
        {patentInfo.editing ? (
          <input
            type="text"
            value={item.name || ""}
            onChange={(e) => onChangeJointApplyInput(index, "name", e.target.value)}
            style={{ width: 135 }}
          />
        ) : (
          <p>{item.name ? item.name : "N/A"}</p>
        )}
      </div>
      <div className={`row`}>
        <h2 className={`title`}>
          지분율
          {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "지분율" }) && (
            <i
              onClick={() => onOpenReport({ mainCategory: "서지사항 Check", subCategory: "지분율" })}
              className="icon_check_empty"
            />
          )}
          <i className="icon_info" data-content={window.$Global.getInfoMessage("지분율")} />
        </h2>
        {patentInfo.editing ? (
          <input
            type="text"
            value={item.share || ""}
            onChange={(e) => onChangeJointApplyInput(index, "share", e.target.value)}
            style={{ width: 135 }}
          />
        ) : (
          <p>{item.share ? item.share : "N/A"}</p>
        )}
      </div>
    </div>
  );
};

export default JointApplyRow;
