import React from "react";
import Styles from './feedback.module.scss';

const Feedback = ({ type, onConfirm, onCancel, message }) => {
  return (
    <>
      {type === "CONFIRM_DELETE" ? (
        <div className={`${Styles["confirm-div"]} ${Styles["delete-modal"]}`}>
          <h1>해당 체크 포인트를 삭제하시겠습니까??</h1>
          <div>
            <button onClick={onConfirm} type="button">
              삭제
            </button>
            <button onClick={onCancel} type="button">
              취소
            </button>
          </div>
        </div>
      ) : type === "ERROR" ? (
        <div className={`${Styles["confirm-div"]} ${Styles["error-modal"]}`}>
          <h1>{message}</h1>
          <button onClick={onCancel} type="button">
            닫다
          </button>
        </div>
      ) : (
        <div className={`${Styles["confirm-div"]} ${Styles["success-modal"]}`}>
          <h1>{message}</h1>
          <button onClick={onConfirm} type="button">
            확인
          </button>
        </div>
      )}
    </>
  );
};

export default Feedback;
