import React from "react";
import "./css/ImageViewer.scss";

function ImageViewer({curIndex, postData, onClickPrevViewer, onClickNextViewer, onClickClose}) {
    return (
        <div id="image_viewer">
            <div className="header">
                <h2>이미지 뷰어</h2>
                <button className="icon_exit" onClick={onClickClose} />
            </div>
            <button className={`icon_more_arrow_left_small_black ${curIndex == 0 ? 'disabled' : ''}`} onClick={onClickPrevViewer} />
            <button className={`icon_more_arrow_right_small_black ${(postData.preview.length == (curIndex + 1)) ? 'disabled' : ''}`} onClick={onClickNextViewer} />
            <img src={window.$Global.getCDN(postData.preview[curIndex]?.file_key)} alt="이미지" />
        </div>
    )
};

export default ImageViewer;