import React from "react";
import PatentList from "../components/patent/PatentListView";
import UserAuthRoute from "./common/UserAuthRoute";
import PatentMemo from "../components/patent/memo/PatentMemo";
import Detail from "../components/patent/detail/Detail";
import MPatentDetail from "../components/patent/mobile/MPatentDetail";
import FamilyView from "../components/patent/family/FamilyView";
import RelativeFileListView from "../components/patent/relativeFile/RelativeFileListView";
import PatentGrade from "../components/patent/grade/PatentGrade";
import PatentETC from "../components/patent/etc/PatentETC";
import CustomSwitch from "./common/CustomSwitch";
import MPatentListView from "../components/patent/mobile/MPatentListView";
import CacheRoute from "react-router-cache-route";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import PatentMailResChk from "../components/patent/test/PatentMailResChk";
import PatentMailResChkAuth from "../components/patent/test/PatentMailResChkAuth";
import PatentMailResRight from "../components/patent/test/PatentMailResRight";
import Checkpoint from "../components/patent/detail/checkpoint/Checkpoint";

function PatentRoute({ match }) {
  return (
    <CustomSwitch>
      <CacheRoute
        exact
        path={`${match.path}/list`}
        render={(props) => window.$Global.renderFunc(props, PatentList)}
        when="always"
      />
      <CacheRoute
        exact
        path={`${match.path}/mlist`}
        render={(props) => window.$Global.renderFunc(props, MPatentListView)}
        when="always"
      />
      <UserAuthRoute exact path={`${match.path}/memo/:patent_idx`} requireAuth={true} component={PatentMemo} />
      <UserAuthRoute exact path={`${match.path}/checkpoint`} requireAuth={true} component={Checkpoint} />
      <UserAuthRoute exact path={`${match.path}/detail/:patent_idx`} requireAuth={true} component={Detail} />
      <UserAuthRoute exact path={`${match.path}/mobile/:patent_idx`} requireAuth={true} component={MPatentDetail} />
      <UserAuthRoute exact path={`${match.path}/family/:patent_idx`} requireAuth={true} component={FamilyView} />
      <UserAuthRoute
        exact
        path={`${match.path}/relativeFile/:patent_idx`}
        requireAuth={true}
        component={RelativeFileListView}
      />
      <UserAuthRoute exact path={`${match.path}/grade/:patent_idx`} requireAuth={true} component={PatentGrade} />
      <UserAuthRoute exact path={`${match.path}/etc/:patent_idx`} requireAuth={true} component={PatentETC} />
      <Route path={`${match.path}/mailResChk`} component={PatentMailResChk} />
      <Route path={`${match.path}/mailResRight`} component={PatentMailResRight} />
      <Route path={`${match.path}/mailResAuth`} component={PatentMailResChkAuth} />
    </CustomSwitch>
  );
}

export default PatentRoute;
