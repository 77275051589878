import React from "react";
import "./css/DocTradeMyTransactionSwitchPointConversionRow.scss";
import _ from "lodash";

function DocTradeMyTransactionSwitchPointConversionRow({info, setInfo, refs}) {
    const onChangeInfo = (key, value) => {
        let copy = _.cloneDeep(info);
        if (key == "amount") {
            if (info.hasAmount < value) return;
        }
        copy[key] = value;
        setInfo(copy);
    };

    return (
        <tr className="pointConversionRow">
            <td className="pointConversionRow_retention">
                <em className="pointNum">{window.$Global.commaify(info.hasAmount)}</em>원
            </td>
            <td className="pointConversionRow_withdraw">
                <label className="hidden" htmlFor="withdrawPoint">출금을 원하는 포인트 입력</label>
                <input type="text" name="withdrawPoint" id="withdrawPoint" className="withdrawPoint" data-name="포인트" ref={refs.amount} value={window.$Global.commaify(info.amount)} onChange={e => onChangeInfo("amount", window.$Global.numberRegx(e.target.value))}/>원
            </td>
            <td className="pointConversionRow_withdraw">
                <label className="hidden" htmlFor="accountName">은행</label>
                <input type="text" name="accountName" id="accountName" className="accountName" data-name="은행" ref={refs.account_bank} value={info.account_bank} onChange={e => onChangeInfo("account_bank", e.target.value)}/>
            </td>
            <td className="pointConversionRow_withdraw">
                <label className="hidden" htmlFor="accountNumber">계좌번호</label>
                <input type="text" name="accountNumber" id="accountNumber" className="accountNumber" data-name="계좌번호" ref={refs.account_number} value={info.account_number} onChange={e => onChangeInfo("account_number", e.target.value)}/>
            </td>
            <td className="pointConversionRow_withdraw">
                <label className="hidden" htmlFor="accountBank">예금주명</label>
                <input type="text" name="accountBank" id="accountBank" className="accountBank" data-name="예금주명" ref={refs.account_name} value={info.account_name} onChange={e => onChangeInfo("account_name", e.target.value)}/>
            </td>
        </tr>
    )
}

export default DocTradeMyTransactionSwitchPointConversionRow;