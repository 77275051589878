import React from 'react';
import './css/ProductClassificationPopup.scss'

function ProductClassificationPopup({classificationList, onClickSelect, onClickClose}) {
    let tag = [];

    for (let i = 1; i <= 45; i++) {
        tag.push(<li key={i} onClick={() => onClickSelect(i)}><p>제{i}류</p></li>);
    }

    return (
        <div id="ProductClassificationPopup">
            <div className="header">
                <h2>상품분류 추가하기</h2>
                <button className="icon_exit" onClick={onClickClose}/>
            </div>
            <ul className="classification_list">
                {tag}
            </ul>
        </div>
    );
}

export default ProductClassificationPopup;
