import React from "react";

function AnnualTable({thead, tbody}) {
    return (
        <div id="AnnualTable">
            <div className="table-box-wrap">
                <div className="wrap_list custom_scroll">
                    <table>
                        <thead>
                            {thead}
                        </thead>
                        <tbody>
                            {tbody}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AnnualTable;
