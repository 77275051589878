import API, { AXIOS } from "../../util/api";

const SALES_API = {
  async getUniversitiesIncome(year) {
    return API.get("/admin/company/annualPayDeposit/deposit", { params: { year } });
  },

  async getUniversities() {
    return AXIOS.get("/admin/company/annualPayDeposit/userlist");
  },

  async getDeposites(company_idx, params) {
    return AXIOS.get(`/admin/company/annualPayDeposit/deposit/list/${company_idx}`, { params });
  },

  async uploadNewDeposit(payload) {
    return AXIOS.post("/admin/company/annualPayDeposit/deposit", payload);
  },

  async updateDeposit(payload) {
    return AXIOS.patch("/admin/company/annualPayDeposit/deposit", payload);
  },

  async deleteDeposit(params) {
    return AXIOS.delete("/admin/company/annualPayDeposit/deposit", { params });
  },


  // Packages Api
  async getPackages(company_idx, params) {
    return AXIOS.get(`/admin/company/annualPayDeposit/package/list/${company_idx}`, { params });
  },

  async uploadNewPackage(payload) {
    return AXIOS.post("/admin/company/annualPayDeposit/package", payload);
  },

  async updatePackage(payload) {
    return AXIOS.patch("/admin/company/annualPayDeposit/package", payload);
  },

  async deletePackage(params) {
    return AXIOS.delete("/admin/company/annualPayDeposit/package", { params });
  },
};

export default SALES_API;
