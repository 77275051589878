import { useEffect, useRef, useState } from "react";
import PatentAPI from "../../../API/patent";
import { Bar, Line } from "react-chartjs-2";

const SubmitNumbers = ({ getPatentListBySubmit }) => {
  const barRef = useRef();
  const [chartData, setChartData] = useState([]);

  const getData = () => {
    PatentAPI.getPatentByYear({ apply_at: 2014 }).then((res) => {
      //console.log("getPatentByYear::", res);
      if (res.data?.allData?.length) {
        setChartData(res.data?.allData || []);
      }
    });
  };

  const dataQuarterly = {
    labels: chartData.map((data) => data.apply_at).reverse(),
    datasets: [
      {
        type: "line",
        label: "증감률",
        data: chartData.map((data) => data.growthRate).reverse(),
        fill: false,
        borderColor: "#e8db2a",
        backgroundColor: "#e8db2a",
        yAxisID: "y-axis-2",
        datalabels: {
          display: true,
          formatter: (value) => `${value}%`,
          anchor: "end",
          align: "top",
        },
        lineTension: 0,
      },
      {
        type: "bar",
        label: "출원건수",
        data: chartData.map((data) => data.cnt).reverse(),
        backgroundColor: "#ba075b",
        borderColor: "#ba075b",
        borderWidth: 1,
        yAxisID: "y-axis-1",
        barThickness: 40,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false, // Hide the legend
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          offset: true,
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "출원건수",
          },
        },
        {
          id: "y-axis-2",
          type: "linear",
          position: "right",
          ticks: {
            beginAtZero: true,
          },
          scaleLabel: {
            display: true,
            labelString: "증감률",
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    onClick: (event, el) => {
      if (el.length > 0) {
        //const { datasetIndex, index } = el[0];
        const dataIndex = el[0]._index;
        const chartInstance = barRef.current.chartInstance;
        const year = chartInstance.data.labels[dataIndex]; // x축 레이블 값을 가져옴
        //const keywordName = el[0].$datalabels[0].$context.dataset.label;

        //console.log("xLabel:", year);
        if (year) {
          getPatentListBySubmit(year);
        }
      }
    },
  };

  /**
   * 
   * const handleElementClick = (el) => {
    setStaticParams((prevs) => ({ ...prevs, flag: category }));

    if (el.length > 0) {
      const dataIndex = el[0]._index;
      const chartInstance = lineRef.current.chartInstance;
      const xLabel = chartInstance.data.labels[dataIndex]; // x축 레이블 값을 가져옴
      const keywordName = el[0].$datalabels[0].$context.dataset.label;

      updateStrValueAndYear(keywordName, xLabel, (updatedParams) =>
        openPatentPop(updatedParams)
      );
    }
  };
   */

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="SubmitNumbers">
      <Bar
        ref={barRef}
        data={dataQuarterly}
        options={options}
        height={300}
        width={600}
      />
    </div>
  );
};

export default SubmitNumbers;
