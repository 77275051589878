import React from "react";

function InputEmail({able, check, signInfo, setSignInfo, onClick, required}) {

    return (
        <div className={`field ${able ? '' : 'action_no'}`}>
            <div className="f_head">
                {required && <i className="icon_red_dot"/>}
                이메일
            </div>
            <div className="f_content">
                <input type="text"
                       placeholder="이메일을 입력해주세요"
                       style={{"width" : 170}}
                       value={signInfo.email}
                       onChange={(e) => setSignInfo({...signInfo, email: e.target.value})}
                />
                <button className={`btn_check ${check ? 'btn_no' : ''}`} onClick={onClick}>중복확인</button>
            </div>
        </div>
    )
}

export default InputEmail;
