import React from "react";
import "../css/SignupTypeFindBiz.scss";
import {Link} from "react-router-dom";
import MemberAPI from "../../../API/member";

function SignupType() {

	return (
		<div id="SignupTypeFindBiz">
			<h2 className="page_title">회원가입</h2>
			<div className="wrap_content">
				<div className="left_content">
					<div className="wrap_type">
						<h2 className="type_head">희망하시는 가입유형을 선택해주세요.</h2>
						<div className="wrap_box">
							<Link to="findbiz/input?type=person" className="box">
								<i className="icon_user_outline"/>
								<h2>예비 창업자</h2>
								<p>간편 가입</p>
								<p>&nbsp;</p>
							</Link>
							<Link to="findbiz/input?type=company" className="box">
								<i className="icon_business_outline"/>
								<h2>기업 가입자</h2>
								<p>간편 가입</p>
								<p className="desc_text"><span>BASIC&nbsp;</span>멤버십&nbsp;<span>무료&nbsp;</span>제공</p>
							</Link>
						</div>
					</div>
					<Link to="/login" className="btn_login">이미 회원이라면 로그인</Link>
					<div className="bottom_button_wrap">
						<Link to="/find/pw">비밀번호 찾기</Link>
						<span className="line"/>
						<Link to="/find/id">아이디 찾기</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SignupType;