import { useEffect, useState } from "react";
import AdminAPI from "../../API/admin";
import "./css/AdminSupport.scss";
import Pagination from "../common/Pagination";

const AdminSupport = () => {
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [supportItems, setSupportItems] = useState([]);
  const count = 5;

  useEffect(() => {
    let payload = {
      page: curPage,
      count: count,
    };
    AdminAPI.getSupportData(payload).then((res) => {
      setSupportItems(res.data.items);
      setLast(res.data.last);
    });
  }, [curPage]);

  return (
    <div id="AdminSupport">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="15%" />
          <col width="15%" />
          <col width="45%" />
          <col width="15%" />
        </colgroup>
        <thead>
          <tr>
            <th>이름</th>
            <th>전화번호</th>
            <th>이메일</th>
            <th style={{ textAlign: "left" }}>내용</th>
            <th>등록일</th>
          </tr>
        </thead>
        <tbody>
          {supportItems.map((item) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.email}</td>
                <td style={{ textAlign: "left" }}>{item.content}</td>
                <td>{window.$Global.convertDateTime(item.created_at)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Pagination curPage={curPage} onClick={setCurPage} lastNum={last} />
    </div>
  );
};

export default AdminSupport;
