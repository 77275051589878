import React from "react";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";

function ToggleButtonRow({
  title,
  selected,
  conditions,
  toggleSelected,
  style,
}) {
  console.log(title);
  //console.log(conditions);
  console.log(selected);
  return (
    <div className="row" style={{ ...style }}>
      <h2 className="title">{title}</h2>
      {conditions ? (
        <ToggleButtonWithSpan
          text=""
          condition={{ on: "유", off: "무" }}
          selected={selected}
          toggleSelected={toggleSelected}
        />
      ) : (
        <ToggleButtonWithSpan
          text=""
          condition={{ on: "유", off: "무" }}
          selected={selected}
        />
      )}
    </div>
  );
}

export default ToggleButtonRow;