import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./css/MTradeMarkListView.scss";
import TradeMarkAPI from "../../../API/trademark";
import _ from "lodash";

function MTradeMarkListView({history}) {
    const count = 100;

    const [data, setData] = useState({items:[]});
    const [tbody, setTbody] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [curPage, setCurPage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [keywordType, setKeywordType] = useState(0);

    useEffect(() => {
        drawList();
    }, []);

    useEffect(() => {
        if (!data.items.length) {
            setTbody([window.$Global.notTd(20, "데이터가 없습니다")]);
        } else {
            setTbody(
                data.items.map((item, idx) => {
                    return (
                        <tr key={idx} onClick={() => history.push(`/tradeMark/mobile/${item.idx}`)}>
                            <td>{data.total_count - idx}</td>
                            <td className="state">{window.$Global.convertTradeMarkStateCodeToStr(item.state)}</td>
                            <td>{item.name || 'N/A'}</td>
                            <td>{item.apply_number || 'N/A'}</td>
                        </tr>
                    );
                })
            );
        }
    }, [data]);

    const getParams = (curPage) => {
        let page = curPage || 1;
        setCurPage(page);

        return {
            page: page,
            count: count,
            keyword: keyword,
            type: keywordType,
        };
    };

    const nextList = () => {
        let param = getParams(curPage + 1);
        drawList(param, "next");
    };

    const drawList = async (params, type) => {
        let param = params || getParams();
        let result = await TradeMarkAPI.getTradeMarkListCompany(param).then(res => res.data);

        if (type == "next") {
            let copy = _.cloneDeep(data);
            result.items = copy.items.concat(result.items);
        }
        setData(result);

        if (result.page == result.last) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };


    return (
        <div id="MPatentListView">
            <div className="header">
                <button className="icon_more_arrow_left_black" onClick={() => history.go(-1)}/>
                <h2 className="page_title">상표</h2>
            </div>
            <div className="wrap_search">
                <select onChange={(e) => setKeywordType(e.target.value)}>
                    <option value={0}>관리번호</option>
                    <option value={17}>출원번호</option>
                </select>
                <input type="text" className="input_search" placeholder="검색어를 입력해주세요" onChange={e => setKeyword(e.target.value)} onKeyUp={(e) => {if(e.key === 'Enter') drawList();}}/>
                <button className="icon_m_search" onClick={() => drawList()}/>
            </div>

            <div className="table-box-wrap">
                <div className="wrap_list" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={tbody.length}
                        next={nextList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table className="list">
                            <thead>
                                <tr className="no_focus_outline">
                                    <th>건수</th>
                                    <th>상태</th>
                                    <th>상표명칭</th>
                                    <th>출원번호</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>

            <div className="wrap_how">
                <img className="how_grade" src={require('../../../assets/images/mobile/bg_patent_detail.svg').default}/>
                <button className="btn" onClick={() => history.push('/grade/company')}>등급제 기준 보러가기</button>
            </div>
        </div>
    );
}

export default MTradeMarkListView;
