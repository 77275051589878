import React, { useEffect, useState } from "react";
import AccountAPI from "../../API/account";
import MemberAPI from "../../API/member";
import AdminAPI from "../../API/admin";
import { useHistory } from "react-router-dom";
import "./css/PatentMailResChkAuth.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Birthday = () => {
  const history = useHistory();
  const location = useLocation();
  const response = location.state.response;
  const [birthday, setBirthday] = useState(""); // 생일
  const [paramBirth, setParamBirth] = useState(""); // 페이로드 - 생일
  const [name, setName] = useState(""); // 이름
  const [payload, setPayload] = useState({
    // 페이로드
    send_mail_idx: response.send_mail_idx,
  });
  const [chkResult, setChkResult] = useState("");
  const useparams = response.useparams;
  let pathname = "";

  //console.log("response:::",response);

  const chkBirthday = async () => {
    const result = await AdminAPI.check_birthday(payload);
    if (result.status === 200) {
      setChkResult(result.data);
      return result.data;
    }
  };

  const goConfirm = (resultData) => {
    if (useparams === "isPaid") {
      pathname = "/sendMail/confirm_IsPaid";
    } else if (useparams === "isGiveUp") {
      pathname = "/sendMail/confirm_IsGiveup";
    }

    goNext(resultData);
  };

  const goNext = (resultData) => {
    if (resultData.result) {
      alert("이름 혹은 생년월일이 일치하지 않습니다.");
      return;
    } else {
      history.push({
        pathname: pathname,
        state: {
          inventor_member_idx: resultData.inventor_member_idx,
          send_mail_idx: resultData.send_mail_idx,
        },
      });
    }
  };

  const handleInputChange = (e) => {
    setBirthday(e.target.value);
    setParamBirth(e.target.value);
  };

  const handleNameChange = (e) => {
    const enteredName = e.target.value;
    const trimEnteredName = enteredName.trim();
    setName(trimEnteredName);
  };

  const submitChk = async () => {
    const resultData = await chkBirthday();
    goConfirm(resultData);
  };

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      birthday: paramBirth, // birthday만 업데이트
    }));
  }, [paramBirth]);

  useEffect(() => {
    setPayload((prevPayload) => ({
      ...prevPayload,
      username: name, // name만 업데이트
    }));
  }, [name]);

  return (
    <div id="BirthDay">
      <div className="annual_mail_page">
        <div className="top">
          <div className="title">
            <i className="icon_annual_response"></i>
            <div className="txt">
              <strong>발명자 본인 인증</strong>
              <p>본 설문 응답을 위해 생년월일을 확인합니다.</p>
            </div>
          </div>
          <div className="steps">
            <p className="one on">1</p>
            <p className="two">2</p>
          </div>
        </div>
        <div className="auth_form">
          <ul style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <li>
              <strong>이름</strong>
              <input type="text" value={name} onChange={handleNameChange} />
            </li>
            <li>
              <strong>생년월일</strong>
              <input
                type="text"
                value={birthday}
                onChange={handleInputChange}
                placeholder="ex) 20240101"
              />
            </li>
          </ul>
        </div>
        <div class="btns">
          <button onClick={submitChk}>제출하기</button>
        </div>
      </div>
    </div>
  );
};

export default Birthday;
