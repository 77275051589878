import React, { useContext } from "react";
import "./css/ToggleButtonWithSpan.scss";
import ToggleButton from "./ToggleButton";

function ToggleButtonWithSpan({
  className,
  text,
  condition,
  selected,
  toggleSelected,
}) {
  return (
    <div className="ToggleButtonWithSpan">
      {condition && (
        <span>
          {text}{" "}
          <span style={{ color: selected ? "#FFA600" : "#000000" }}>
            {selected ? condition.on : condition.off}
          </span>
        </span>
      )}
      <ToggleButton
        className={className}
        selected={Boolean(selected)}
        toggleSelected={toggleSelected}
        condition={condition}
      />
    </div>
  );
}

export default React.memo(ToggleButtonWithSpan);
