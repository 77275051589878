import { useEffect, useState } from "react";
import PatentAPI from "../../../API/patent";
import "../css/IpcStatistic.scss"; // 스타일 파일 임포트

const RightCheck = ({ data }) => {
  const [rightData, setRightData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [totalCnt, setTotalCnt] = useState(0);
  const [totalRate, setTotalRate] = useState(0);
  const [totalLegalCnt, setTotalLegalCnt] = useState(0);

  const mappingLevel = (level) => {
    let levelStr = "";
    switch (level) {
      case 10:
        levelStr = "AAA";
        break;
      case 9:
        levelStr = "AA";
        break;
      case 8:
        levelStr = "A";
        break;
      case 7:
        levelStr = "BBB";
        break;
      case 6:
        levelStr = "BB";
        break;
      case 5:
        levelStr = "B";
        break;
      case 4:
        levelStr = "CCC";
        break;
      case 3:
        levelStr = "CC";
        break;
      case 2:
        levelStr = "C";
        break;
      case 1:
        levelStr = "N";
        break;
      default:
        levelStr = "";
    }
    return levelStr;
  };

  const getRightCheck = () => {
    PatentAPI.getRightCheck().then((res) => {
      if (res.data?.rightData.length > 0) {
        const formattedData = res.data.rightData.map((item) => ({
          key: mappingLevel(item.sm_grade),
          number: `${item.cnt}건 / ${item.rate}%`,
        }));
        setRowData(formattedData);

        const totalCnt = res.data.rightData.reduce(
          (acc, item) => acc + item.cnt,
          0
        );
        const totalRate = res.data.rightData.reduce(
          (acc, item) => acc + item.rate,
          0
        );

        const totalLegalCnt = Object.values(data).reduce(
          (sum, item) => sum + item.cnt,
          0
        );

        setTotalCnt(totalCnt);
        setTotalRate((totalCnt / totalLegalCnt) * 100);
        setTotalLegalCnt(totalLegalCnt);
      }
    });
  };

  useEffect(() => {
    getRightCheck();
  }, [data]);

  return (
    <div id="RightCheck">
      <div className="total">
        <p>
          {totalCnt}건 / {Math.floor(totalRate * 100) / 100}%
        </p>
      </div>
      <div style={{ width: 280, height: 360, overflowY: "auto" }}>
        <table className="right-check-table">
          <thead>
            <tr>
              <th className="grid-header" style={{ width: "80px" }}>
                Key
              </th>
              <th className="grid-header" style={{ width: "160px" }}>
                Number
              </th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((row, index) => (
              <tr key={index}>
                <td className="grid-cell">{row.key}</td>
                <td className="grid-cell">{row.number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RightCheck;
