import React, {useContext} from "react";
import NormalRow from "../../row/NormalRow";
import BillingContext from "../../../../store/Billing";

function ApplyField() {
    const billing = useContext(BillingContext);
    const billObj = billing.bill;

    if (window.$Global.isEmptyObject(billing)) return null;

    return (
        <div className="field">
            <div className="rows">
                <NormalRow title="출원번호" titleClassName="color_blue" content={billObj.apply_number}/>
                <NormalRow title="출원일" content={billObj.apply_at} txtType="date"/>
            </div>
            <div className="rows">
                <NormalRow title="국문 명칭" rowClassName="long" content={billObj.invention_name}/>
            </div>
            <div className="rows">
                <NormalRow title="영문 명칭" rowClassName="long" content={billObj.invention_name_en}/>
            </div>
        </div>
    )
}

export default ApplyField;
