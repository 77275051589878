import React, {useContext, useEffect, useState} from "react";
import "./css/PopupFilterPrice.scss";
import CustomizedSlider from "../common/CustomizedSlider";
import config from "./const/index";
import CommonContext from "../../store/Common";

function PopupFilterPrice({FuncEvent}) {
    const {isMobile} = useContext(CommonContext);
    const initPrice = FuncEvent.getPrice();
    const step = config.step;
    const min = config.min;
    const max = config.max
    const [price, setPrice] = useState(initPrice[0] ? initPrice :[min, max]);
    const [priceRange, setPriceRange] = useState('전체');

    useEffect((e) => {
        handleChange(null, price);
    }, []);

    const handleChange = (event, newValue) => {
        setPrice(newValue);

        if (newValue[0] == min && newValue[1] == max) {
            setPriceRange('전체');
        } else {
            setPriceRange(window.$Global.numberToKorean(newValue[0]) + '~' + window.$Global.numberToKorean(newValue[1]));
        }
    };

    const onClickReset = () => {
        handleChange(null, [min, max]);
    };

    return (
        <div id="PopupFilterPrice">
            <div className="header">
                <h2 className="page_title">필터 설정</h2>
                <button className="icon_exit" onClick={() => FuncEvent.onClose()}/>
            </div>
            <div className="setting">
                <div className="s_head">
                    <h2 className="setting_title">검색 가격 설정</h2>
                    <div className="btns">
                        <button className="btn_reset" onClick={onClickReset}>초기화</button>
                        {
                            !isMobile && <button className="icon_exit" onClick={() => FuncEvent.onClose()}/>
                        }
                    </div>
                </div>
                <div className="range">
                    <h2 className="price_range">{priceRange}</h2>
                    <CustomizedSlider value={price}
                                      handleChange={handleChange}
                                      step={step}
                                      min={min}
                                      max={max}
                    />
                    <div className="wrap_range">
                        <p>{config.min}</p>
                        <p>{window.$Global.numberToKorean(config.max)}</p>
                    </div>
                </div>
                <button className="btn_search" onClick={() => FuncEvent.setPrice(price)}>설정완료</button>
            </div>
        </div>
    )
}

export default PopupFilterPrice;