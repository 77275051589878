export const dummyBanKeyword = '수질, 하수, 하천, 조경, 수경, 미화, 환경, 나무, ' +
'오염, 대기, 수집, 배출, 작업, 취소, ' +
'야간, 경비, 이사, 포장, 운반, 운송, 배송, 철거, 물품, 파견, ' +
'고양이, 어린이, 도우미, 급식, 배식, ' +
'재해, 소방, 측량, 댐, 유량, 발굴, ' +
'택배, 잔디, 공급, 유해, 이전, ' +
'불법, 단속, 매립, 소액, 오수 화학, 승강, ' +
'서적, 유통, 무기, 국방, 번역, ' +
'기록 국어 검진 냉난방 유적 간행 비탈면, ' +
'열화, 화상, 카메라, 광학, ' +
'배수, 배전, 장비, 개량, 세척, 세탁, ' +
'자전거, 셔틀, 차량 세포 미세먼지, 정밀실측, 물순환, ' +
'주차관리, 경비, 예초, 개최용역, 건강진단, 중장기, 사업자, ' +
'모의, 잡무, 렌탈, 보조, ' +
'개폐 슬레이트, 분뇨, 쓰레기 수목, 동물, ' +
'세정, 시료, 실험, 서식, 청결, 코로나, 잔류, 경로당, ' +
'일제조사, 피해, 인체, ' +
'지진, 해일, 전화, 배관, 고압, 일제, 강우, ' +
'군수, 군부, 남극, 플랑크톤, 전투, 옥외, ' +
'한우, 숲, 민군, 예초, 상수, 도로, ' +
'산림, 취업, 유전, 생물, 분자, 해파리, ' +
'시공, 채록, 재배, 변압, 정원, 장제, 편찬, ' +
'재활, 유물, 보존, 보일러, 조명, 치료, ' +
'가뭄, 폐목, 실린더, 해체, 의약품, 경관, ' +
'품질검증, 양식, 학점, 조림, ' +
'케이블, 사료, 템플스테이, 중고, 곤충, ' +
'품질평가, 재난, 베기, 택시, 공영, 관측, 아웃소싱, ' +
'어업, 조류, 방사능, 사료, 배합, 보건, 회의록, ' +
'곤충, 고양이, 해수욕, 말뭉치, 통학, ' +
'담수, 하굿둑, 저수지, 상담, 성과관리, ' +
'범죄, 항만, 콜센터, 암반, 굴착, 냉난방, 구술채록, ' +
'군관리, 해조류, 패류, 갑각류';