import React, { useState, useEffect } from "react";
import "./css/NationAddPopup.scss";
import { designatedCountryEp } from "../../../types/nationType";

function NationAddPopup({ mergePatent, onClickAdd, onClickClose }) {
  const nation_ref = React.useRef();
  const [nationList, setNationList] = useState([]);
  const [tbody, setTbody] = useState([]);

  useEffect(() => {
    let tag = nationList.map((item, index) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>{item.text}</td>
          <td>{window.$Global.convertEPNationCodeToKoreanStr(item.code)}</td>
          <td>
            <button
              className="icon_badge_del"
              onClick={() => onClickNationDelete(index)}
            />
          </td>
        </tr>
      );
    });
    setTbody(tag);
  }, [nationList]);

  const onClickNationAdd = () => {
    let code = nation_ref.current.value;
    let text =
      nation_ref.current.options[nation_ref.current.selectedIndex].text;

    if (code == "default") return;
    let result = nationList.find((item) => {
      return item.code == code;
    });
    if (result) return;
    setTbody([]);
    setNationList(nationList.concat({ code: code, text: text }));
  };

  const onClickNationDelete = (index) => {
    let arr = nationList.slice(0);
    arr.splice(index, 1);
    setNationList(arr);
  };

  const onClickSubmit = () => {
    onClickAdd(nationList);
    onClickClose();
  };

  return (
    <div id="NationAddPopup">
      <div className="popup_header">
        <h2 className="title">지정국가 선택</h2>

        <div className="btns">
          <button className="btn_add" onClick={onClickSubmit}>
            추가하기
          </button>
          <button className="icon_exit" onClick={onClickClose}></button>
        </div>
      </div>

      <div className="popup_content">
        <label style={{ float: "left", marginRight: 29 }}>
          <span>국가선택</span>
          <select defaultValue="default" ref={nation_ref}>
            <option value="default" hidden disabled>
              국가선택
            </option>
            {designatedCountryEp.map((el, idx) => {
              return <option value={idx}>{el}</option>;
            })}
            {/*<option value={7}>DE</option>*/}
            {/*<option value={13}>GB</option>*/}
          </select>
          <button
            className="icon_badge_more_blue"
            onClick={onClickNationAdd}
            style={{ position: "relative", top: 10, left: 5 }}
          />
        </label>
        <div className="wrap_list">
          <table className="list">
            <thead>
              <tr>
                <th width="8%">순번</th>
                <th width="8%">국가</th>
                <th>국가 명칭</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>{tbody}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NationAddPopup;
