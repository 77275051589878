import React, { useState, useEffect } from "react";
import qs from "query-string";
import axios from 'axios';
import AdminAPI from "../../API/admin";
import { useHistory } from 'react-router-dom';
const AesMailTest = ({ location }) => {
  const { q } = qs.parse(location.search);
  const history = useHistory();
  // 생년월일을 위한 state
  const [birthday, setBirthday] = useState("");
  const [inventorIdx, setInventorIdx] = useState(null);

  useEffect(() => {
    if (birthday) {
      // birthday 상태값이 변경될 때 /getInventor API 호출
      getInventorData();
    }
  }, [birthday]);

  // input 값 변경 시 실행되는 함수
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // 특수 문자 제거한 값으로 업데이트
    const cleanedValue = inputValue.replace(/[^\w\s]/gi, '');
    setBirthday(cleanedValue);
  };

  // /getInventor API 호출 함수
  const getInventorData = async (birthday) => {
    try {
      const response = await AdminAPI.isPaid();
  
      console.log('알렉');
      console.log(response);
  
      if (response.status === 200 && response.data.validFormat) {
        console.log('서버 응답 성공:', response.data);
  
        // 여기에서 페이지 이동 로직을 작성합니다.
        // 예를 들어 React Router를 사용하는 경우,
        history.push({
          pathname: '/sendMail/birthday',
          state: { response: response.data } // 상태로 전달할 데이터
        });
  
      } else {
        console.log('서버 응답 실패:', response.statusText);
      }
    } catch (error) {
      console.error('서버 요청 중 오류 발생:', error.message);
    }
  };

  // 생일 입력 폼 렌더링
  return (
    <div>
      <label>
        API 호출:
        <input
          type="date"
          value={birthday}
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
};

export default AesMailTest;