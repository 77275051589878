import { Link } from "react-router-dom";
import React, { useState } from "react";

export default {
  DrawTab(arr, activeTab, className, isLogin) {
    const isViewTypeIpnow = window.$Global.isIpnow();

    return arr.map((item, idx) => {
      return (
        <li
          key={idx}
          className={
            activeTab === (item.activeTab || idx) + 1
              ? !isViewTypeIpnow
                ? className
                : className + "_ipnow"
              : ""
          }
          style={{}}
        >
          <Link
            to={item.redirectURL || item.url}
            className={isViewTypeIpnow ? "a_ipnow" : "a_biznavi"}
          >
            {item.name}
          </Link>
          {Boolean(item.child_Tab?.length) && (
            <>
              <i className="icon_more_arrow_down" />
              <ul className="child_tab_list">
                {item.child_Tab.map((item2) => {
                  return (
                    <li key={item2.url}>
                      <Link to={item2.url}>{item2.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </li>
      );
    });
  },
};
