import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeSampleRegisterAgree.scss";
import CheckBox from "../common/CheckBox";
import DocTradePopup from "./common/DocTradePopup";
import Terms from "./const/terms";

function DocTradeSampleRegisterAgree({onClickAdd}) {
    const [agree, setAgree] = useState(false);
    const [terms, setTerms] = useState({title: '', desc: ''});
    const [popupShow, setPopupShow] = useState(false);

    const onClickPopupShow = (key) => {
        setTerms(Terms[key]);
        setPopupShow(true);
    };

    const onClickPost = () => {
        if (!agree) {
            alert("동의에 체크해주세요");
            return;
        }
        onClickAdd();
    };

	return (
        <div id="docTradeSampleRegisterAgree">
            <div className="register_agree-checkboxWrap">
                <p className="register_checkbox-explanation">
                    본인의 저작물을 등록 신청함에 있어 서약서와 저작권 규정 및 판매수익금 규정 을 모두 확인 하였으며 이에 동의합니다.
                </p>
                <CheckBox text="동의함" checked={agree} onChangeChecked={() => setAgree(!agree)}/>
            </div>
            <div className="register_rule-wrap">
                <ul className="register_rule">
                    <li className="register_rule-list">서약서&nbsp;&nbsp;
                        <button className="register_rule-list_link" onClick={() => onClickPopupShow('pledgeAgree')}>자세히 알아보기</button>
                    </li>
                    <li className="register_rule-list">저작권 규정&nbsp;&nbsp;
                        <button className="register_rule-list_link" onClick={() => onClickPopupShow('copyrightAgree')}>자세히 알아보기</button>
                    </li>
                </ul>
            </div>
            <div className="register_btn-submit">
                <button type="submit" onClick={onClickPost}>판매 등록</button>
            </div>

            {
                popupShow && <DocTradePopup terms={terms} onClose={() => setPopupShow(false)}/>
            }
        </div>
	)
}

export default DocTradeSampleRegisterAgree;