let real_url = "https://" + window.location.hostname;
let test_real_url = "https://www.biznavi.co.kr";
let test_url = "https://www.ipnow-dev.co.kr";
let base =
  process.env.NODE_ENV === "production"
    ? real_url
    : window.location.href.indexOf(test_url) > -1
    ? test_url
    : test_real_url;

if (window.location.hostname === "localhost") {
  base = test_url;
} else if (window.location.hostname === "127.0.0.1") {
  base = "http://127.0.0.1:8081";
} else if (window.location.hostname === "221.151.187.148") {
  base = test_url;
} else if (window.location.hostname === "54.180.76.88") {
  base = "http://54.180.76.88";
} else if (window.location.hostname === "221.151.187.104") {
  base = "http://221.151.187.104:9100";
} else if (window.location.hostname === "192.168.0.124") {
  base = "http://192.168.0.124:8081";
} else if (window.location.hostname === "192.168.0.94") {
  base = "http://192.168.0.94:55555";
} else {
  //base = gw_base = "http://" + window.location.hostname;
  // base = gw_base = "";
}

export default {
  url: base,
  testerIdx: "7IQDL4OVK90XKYDB",
  emailReg:
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
  imgType: /^jpg$|^jpeg$|^gif$|^png$|^JPG$|^JPEG$|^PNG$|^GIF$/,
  imgTypeCheck: /(.*?)\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/,
  pdfTypeCheck: /(.*?)\.(pdf|PDF)$/,
  checkReg: /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*+-]).{8,16}$/,
  regxMsg: "8~16자 영문, 숫자, 특수문자를 하나 이상 포함해주세요.",
  introSessionKey: "introViewIp",
  keyToken: "token",
  findbizFreeToken:
    "6c9a5dcde84a16bc1424c519a3e3d11d:591bd3faf303de843ca6c7ad4bd6f0830883df869c88171c739b526fc726d96968ec466815fb0862cdef9e32ccc3153a693d09c7ea2f1dad1d3140b530093bfcf8b0e3048c73ebf61f0af4f7ec6fc3a2b4d9cb42b4437a7ae70fd53bc119bdf1c1c4ebb315317617618b209349f3a82f997f80d42953eb8159bfedc385d92c72a0422e7cea7ac3b23a82f7c5eb35e5176c44dab9b6e9ba71f5d924be2956bcc56c266f3168ea441f8105cb44e3ee9027baab5022e40ba385aecfbf2744ab65bfb5477af4dc4c82f38fb1b787e446dea6bbeb5fb445db5b49907cb28addd2453adc48183486a17b9ac5bcae3100e773a15b3b7443cb13ac618caca5e1c1df5ca9d9a50126519d60811c3b01864467cdcd9eb8a8577afed4d26abe92686fd76091",
};
