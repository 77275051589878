import React, { useContext, useEffect, useRef, useState } from "react";
import "./css/DocTradeMyTransactionSaleRow.scss";
import TemplateAPI from "../../API/template";
import {Link} from "react-router-dom";

function DocTradeMyTransactionSaleRow({data}) {
    const [categoryList, setCategoryList] = useState([]);
    let items = data.items;

    useEffect(() => {
        TemplateAPI.getCategory().then(res => setCategoryList(res.data));
    }, []);

    return (
        <>
            {
                items.length
                ?
                    items.map((item, idx) => {
                        return (
                            <tr key={idx} className="docTradeMyTransactionSaleRow">
                                <td className="num-info">{data.total_count - idx}</td>
                                <td className="transaction-info">{item.title}</td>
                                <td className="date-info">{window.$Global.convertDate(item.created_at)}</td>
                                <td className="class-info">
                                    {
                                        categoryList.find(category => {
                                            return category.idx == item.category_idx1
                                        })?.name
                                    }
                                </td>
                                <td className="number-info">{item.sell_cnt}회</td>
                                <td className="price-info">{window.$Global.commaify(item.price)}<em className="style">원</em></td>
                                <td className="detail-info"><Link to={`/docTrade/view/${item.idx}`} className="detail-info_link">자세히 보기</Link></td>
                            </tr>
                        )
                    })
                : window.$Global.notTd(7, "판매 내역이 없습니다")
            }
        </>
    )
};

export default DocTradeMyTransactionSaleRow;