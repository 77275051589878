import "./css/DTop.scss";

const DTop = () => {
  return (
    <div id="DTop">
      <div className="my">
        {/* <img
          src={require("../../assets/images/common/logo_ipnow.svg").default}
          style={{ position: "relative", width: 120 }}
          alt="logo"
        /> */}
        <p className="tit">
          <strong>{sessionStorage.getItem("company_name") || ""}</strong>
          &nbsp;&nbsp;특허통계 DASH BOARD
        </p>
      </div>
      {/* <div className="files">
        <button>
          <i className="dd_pdf"></i>다운로드
        </button>
        <button>
          <i className="dd_doc"></i>다운로드
        </button>
        <button>
          <i className="dd_excel"></i>다운로드
        </button>
      </div> */}
      {/* <div className="logo">
        <img
          src={require("../../assets/images/common/logo_ipnow.svg").default}
          style={{ position: "relative", width: 120 }}
          alt="logo"
        />
      </div> */}
    </div>
  );
};

export default DTop;
