import {css} from "@emotion/react";

export const container = css`
  padding: 10px;
`;

export const companyListWrap = css`
  padding: 10px;
  margin-top: 10px;
  overflow-y: auto;
  height: 300px;
`;

export const companyListItem = css`
  cursor: pointer;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
  column-gap: 10px;
`;

export const trademarkListWrap = css`
  cursor: pointer;
  padding: 10px;
  height: 500px;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
`;

export const trademarkListItem = css`
  padding: 10px;
  display: flex;
  align-items: center;
  column-gap: 30px;

  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  margin-bottom: 10px;
`;

export const classWrap = css`
  width: 500px;
  overflow-y: auto;
`;

export const classItemWrap = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;