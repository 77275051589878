/** @jsxImportSource @emotion/react */

import React, {useEffect, useState} from "react";
import * as styles from "./news.style";
import esgMediaNewsApi from "../../../API/test/esg/news";

const cdnLocation = 'https://d2fkfd93iftsmp.cloudfront.net/';

function TestEsgMediaNewsList() {
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        esgMediaNewsApi.getEsgMediaFilteredNewsList()
            .then(result => {
                setNewsList(result.data);
            });

    }, []);

    return (
        <div css={styles.container}>
            {
                newsList.map(v => {
                    return <div css={styles.newsWrap}>
                        <div css={styles.newsHeaderWrap}>
                            <div css={styles.newsTitleWrap}>
                                <div>제목: {v.title}</div>
                                <div>부제목: {v.sub_title}</div>
                                <div>미디어 명칭: {v.media_name}</div>
                            </div>
                        </div>
                        <div
                            css={styles.newsContentWrap}
                            dangerouslySetInnerHTML={{__html: v.content}}
                        >

                        </div>
                    </div>

                })
            }

        </div>
    )
}

export default TestEsgMediaNewsList;
