import React, {useContext, useEffect, useState} from "react";
import "./css/ConsortiumMain.scss";
import Pagination from "../common/Pagination";
import ConsortiumAPI from "../../API/consortium";
import ConsortiumListItem from "./ConsortiumListItem";
import config from "./const/index";
import {Link} from "react-router-dom";
import memberContext from "../../store/Member";
import qs from "query-string";
import CommonContext from "../../store/Common";

function ConsortiumMain({location}) {
	const member = useContext(memberContext);
	const { isMobile } = useContext(CommonContext);
	const supply = config.supply; // 0 공급
	const demand = config.demand; // 1 수요

	const type = qs.parse(location.search).type || supply;

	const [searchType, setSearchType] = useState(type);
	const [keyword, setKeyword] = useState('');
	const [curPage, setCurPage] = useState(1);
	const [resData, setResData] = useState({});

	const isCompany = member.company_type == 0;

	const supplyStr = '내 기업 소개하기';
	const demandStr = '필요 기업 찾기';
	const typeStr = searchType === supply ? supplyStr : demandStr;
	const activeSupplyTab = searchType == supply ? 'on active' : '';
	const activeDemandTab = searchType == demand ? 'on active' : '';

	useEffect(() => {
		getList();
	}, [searchType]);

	const getList = () => {
		let params = {
			page: curPage,
			count: 20,
			type: searchType, // 0 공급, 1 수요
			show_type: 0, // 0 일반 검색, 1 즐겨찾기 보기
			keyword: keyword,
			option: 0 // 0: 업체명검색
		};
		ConsortiumAPI.getList(params).then(res => {
			setResData(res.data);
		});
	};

	if (window.$Global.isEmptyObject(resData)) return null;

	const ListTag =
		Boolean(resData.items.length)
		? resData.items.map(item => <ConsortiumListItem key={item.idx} item={item} isMobile={isMobile} />)
		:
		<div className="consortiumMain_table_list no_data">
			<p>등록된 기업이 없습니다</p>
		</div>

	return (
		<div id="ConsortiumMain">
			<div className="pc">
				<div className="consortiumMain">
					<div className="consortiumMain_box-wrap">
						<div className="consortiumMain_box">
							<h2 className="consortiumMain_box_title">
								사업 파트너 기업 찾기
							</h2>
							<p className="consortiumMain_box_text">
								<p>정부,공공사업를 위해 컨소시엄 구성에 필요한 기업을 여기서 찾으세요.</p>
								{/*<p>공급검색 &#58; 설명 필요</p>*/}
								{/*<p>수요검색 &#58; 설명 필요</p>*/}
							</p>
						</div>
						<div className="consortiumMain_btn">
						{/*	<button type="button">튜토리얼 바로가기</button>*/}
						</div>
					</div>
					<div className="consortiumMain_title-wrap">
						<div className="consortiumMain_title">
							<div className="consortiumMain_title_text-wrap">
								<h2 className="consortiumMain_title_text">매칭 컨소시엄</h2>
								<ul className="consortiumMain_title_menu">
									<li className={`consortiumMain_title_menu_list ${activeSupplyTab}`}>
										<button type="button" className="consortiumMain_title_menu_list_button" onClick={() => setSearchType(supply)}>내 기업 소개하기</button>
									</li>
									<li className={`consortiumMain_title_menu_list ${activeDemandTab}`}>
										<button type="button" className="consortiumMain_title_menu_list_button" onClick={() => setSearchType(demand)}>필요 기업 찾기</button>
									</li>
								</ul>
							</div>
							{
								isCompany
								&&
								<div className="consortiumMain_title_btn-wrap">
									<Link to="manage" className="btn_register">내 컨소시엄 관리</Link>
								</div>
							}
						</div>
					</div>
					<div className="consortiumMain_search-wrap">
						<div className="consortiumMain_search">
							<div className="consortiumMain_search_box">
								<select name="searchBoxSel" id="searchBoxSel" className="consortiumMain_search_box_select">
									<option value="">업체명</option>
								</select>
								<label htmlFor="searchInput" className="hidden">검색어 입력</label>
								<input type="search" name="searchInput"
									   id="searchInput" className="consortiumMain_search_box_input"
									   placeholder="검색어를 입력해 주세요.&#40;필수&#41;"
									   value={keyword}
									   onChange={e => setKeyword(e.target.value)}
									   onKeyUp={(e) => e.key === 'Enter' && getList()}
								/>
							</div>
							<button type="submit" className="consortiumMain_search_button" onClick={getList}>검색하기</button>
						</div>
					</div>
					<div className="consortiumMain_table-top">
						<div className="consortiumMain_table-top_title">
							<h3 className="title">{typeStr} 검색</h3>
							<p className="text">
								검색 건 수 총<em>{window.$Global.commaify(resData.total_count)}건</em>
							</p>
						</div>
						{/*<div className="consortiumMain_table-top_select-wrap">*/}
						{/*	<select name="tableTopSelect" id="tableTopSelect" className="consortiumMain_table-top_select">*/}
						{/*		<option value="t1">최신 등록순</option>*/}
						{/*	</select>*/}
						{/*</div>*/}
						{/*<button className="consortiumMain_title_bookmark">*/}
						{/*	즐겨찾기 보기*/}
						{/*</button>*/}
					</div>
					<div className="consortiumMain_table">
						{
							ListTag
						}
					</div>
					<Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
				</div>
			</div>
			<div className="mo">
				<div className="consortiumMain">
					<div className="consortiumMain_top">
						<h2 className="consortiumMain_title">
							사업 파트너 기업 찾기
						</h2>
						<p className="consortiumMain_subtitle">
							정부,공공사업를 위해 컨소시엄 구성에 필요한 기업을<br />여기서 찾으세요
						</p>
					</div>
					<div className="consortiumMain_content">
						<ul className="consortiumMain_content_selectBox">
							<li className="consortiumMain_content_option">
								<button type="button" className={`consortiumMain_content_option_button ${activeSupplyTab}`} onClick={() => setSearchType(supply)}>
									{supplyStr}
								</button>
							</li>
							<li className="consortiumMain_content_option">
								<button type="button" className={`consortiumMain_content_option_button ${activeDemandTab}`} onClick={() => setSearchType(demand)}>
									{demandStr}
								</button>
							</li>
						</ul>
						<div className="consortiumMain_content_search">
							<select name="searchBoxSel" id="searchBoxSel" className="consortiumMain_content_search_select">
								<option value="">업체명</option>
							</select>
							<label htmlFor="searchInput" className="hidden">검색어 입력</label>
							<input type="search" name="searchInput"
								   id="searchInput" className="consortiumMain_content_search_input"
								   placeholder="검색어를 입력해 주세요."
								   value={keyword}
								   onChange={e => setKeyword(e.target.value)}
								   onKeyUp={(e) => e.key === 'Enter' && getList()}
							/>
							<button type="submit" className="consortiumMain_content_search_button" onClick={getList}>
								검색하기
							</button>
						</div>
						<div className="consortiumMain_content_table">
							{
								ListTag
							}
						</div>
						<Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
						<div className="consortiumMain_addition">
							기업 등록은 PC버전에서 하실 수 있습니다.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ConsortiumMain;