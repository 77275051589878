import "../css/LegalStatus.scss";

const LegalStatus = ({ data, color }) => {
  const combineDataAndColors = (data, colors) => {
    const koreanKeys = {
      register: "등록",
      open: "공개",
      reject: "거절",
      giveup: "포기",
      cancel: "취하",
      delete: "소멸",
    };

    return Object.keys(data).reduce((acc, key) => {
      acc[key] = { ...data[key], color: colors[key], korean: koreanKeys[key] };
      return acc;
    }, {});
  };

  const statusData = combineDataAndColors(data, color);

  return (
    <div id="LegalStatus">
      {Object.keys(statusData).map((key) => (
        <div key={key} className="status_box">
          <div className="name">
            <strong>{statusData[key].korean}</strong>
            <div
              className="color_box"
              style={{ background: statusData[key].color }}
            ></div>
          </div>
          <p className="numbers">
            {window.$Global.commaify(statusData[key].cnt)}건 /{" "}
            {statusData[key].rate}%
          </p>
        </div>
      ))}
    </div>
  );
};

export default LegalStatus;
