export default {
    usableDataAgree: {
        title: '판매가능 자료 및 부정자료 규정',
        desc:
            '샘플거래하기에서 올릴 수 있는 자료는 문서의 형태로 본인에게 저작권이 있는 자료만 가능.' +
            '<br/>' +
            '한글, adobe PDF, MS word, MS PPT, MS excel, 훈민정음 등의 워드프로세스 파일과 텍스트파일, 압축파일로 등록이 가능.' +
            '<br/><br/>' +
            '부정자료 규정' +
            '<br/>' +
            '- 각종 연구소의 연구 결과물을 포함한 자료' +
            '<br/>' +
            '- 블로그 및 홈페이지의 내용을 발췌한 자료' +
            '<br/>' +
            '- 웹상에 게시된 글을 발췌한 자료' +
            '<br/>' +
            '- 출처 없이 가져온 인용문이 삽입된 자료' +
            '<br/>' +
            '- 각종 책의 문제 풀이 자료' +
            '<br/>' +
            '- 개인정보가 삽입된 자료' +
            '<br/>' +
            '- 타인의 저작물, 본인에게 저작권이 없는 자료' +
            '<br/>' +
            '- 조별레포트의 경우 공동저작자의 동의를 얻지 못한 자료' +
            '<br/>' +
            '- 자료특정 회사의 대외비 문서나 명예훼손의 소지가 있는 자료' +
            '<br/>' +
            '- 출제기관,출제자 또는 출판사에 저작권이 있는 기출문제나 문제풀이자료' +
            '<br/>' +
            '- 원저자의 허락없이 사용한 단순 논문요약, 신문기사 스크랩, 번역자료 등' +
            '<br/>' +
            '- 그밖에 저작권에 대한 확신이 없거나 내용상 공개 시 문제의 소지가 있는 자료' +
            '<br/>' +
            '- 기타 저작권에 확신이 없는 자료 등' +
            '<br/><br/>' +
            '자료검수 및 파일의 오류 및 내용의 일치성, 파일내 내용 및 이미지 누락 등을 확인.' +
            '<br/>' +
            '자료등록시 저작권의 판단이 불가능하므로 등록시 약관 및 서약서를 명시하여 판매자에게 저작권에 대한 책임과 권리가 있도록 한다.'
    },
    addFileAlert: {
        title: '등록파일 주의사항',
        desc:
            '- 압축파일, 엑셀파일의 경우 페이지수가 정상적으로 표기되지 않거나, 미리보기 서비스가 정상적으로 제공되지 않아 환불요소가 될 수 있습니다.' +
            '<br/><br/>' +
            '- 파일 용량이 100Mb 를 초과하는 자료를 업로드할 경우 [아이디/ 자료구분/ 판매가격/ 소개글]을 기재하여 대표 이메일(ipnow@ipnow.co.kr)로 보내주시면 확인 후 처리 드리겠습니다. 용량이 큰 자료는 구매 후 다운로드 문제로 환불 사유가 될 수 있습니다. 가급적 주제별로 분리해서 등록해주시기 바랍니다.' +
            '<br/><br/>' +
            '프로그램자료, 디자인 소스의 경우 대표이미지와 함께 전송해주세요.'
    },
    pledgeAgree: {
        title: '서약서 상세보기',
        desc:
            '저작물을 등록신청하는 본인은 김조교 서비스를 제공하는 주식회사 아이피나우 또는 그 제휴업체(이하 ‘귀사’)에 대하여, 다음과 같은 사항을 확인하고, 이에 대하여 본 서약서를 제출(동의)합니다.' +
            '<br/><br/>' +
            '1. 본인이 등록신청한 저작물에 관하여, 본인은 저작권법 기타 관련법령, 귀사의 약관, 저작권규정(저작물서비스 제공에 관한 계약) 기타 관련규정을 숙지하고, 이를 준수하겠습니다.' +
            '<br/><br/>' +
            '2. 본인이 등록신청한 저작물에 관한 귀사의 승낙 또는 승낙거절이나 보류결정, 본인의 이의신청에 관한 귀사의 결정 기타 귀사의 조치에 대하여 일체의 이의를 제기하지 아니하겠습니다.' +
            '<br/><br/>' +
            '3. 본인이 등록신청한 저작물에 관하여, 본인은 저작물의 저작권자이거나 또는 저작물의 정당한 권리자로부터 이용권을 허락받았습니다.' +
            '<br/><br/>' +
            '4. 본인이 등록신청한 저작물은 저작권법 기타 관련법령을 위반하지 아니합니다.' +
            '<br/><br/>' +
            '5. 본인이 등록신청한 저작물과 관련하여 발생한 명예훼손 기타 민ㆍ형사상 일체의 책임을 부담합니다.' +
            '<br/><br/>' +
            '6. 회원 가입시 또는 등록 신청시 귀사에 대하여 제공한 본인의 개인정보 기타 귀사가 요구한 정보는 모두 진실이며, 그 정보가 변경되는 즉시 귀사가 정한 절차에 따라 귀사에 대하여 변경 정보를 제공하겠습니다.'
    },
    copyrightAgree: {
        title: '저작권 규정 상세보기',
        desc:
            `저작물을 등록함에 있어서 저작권자 본인을 '갑'이라 하고 샘플 거래하기 서비스를 제공하는 온라인 서비스 제공자인 주식회사 아이피나우를 '을'이라 하며 당사자들은 저작물 서비스 제공에 관한 계약에 동의하며 다음과 같이 약정하고 준수한다.` +
            `<br/><br/>` +
            `제 1 조 (계약의 설정)` +
            `<br/>` +
            `'갑'은 지식재산권을 소유하거나 정당한 이용권한을 가지는 계약 대상 저작물에 대하여, ‘을’에게 이용권한을 설정하며, ‘을’은 저작물의 전송, 배포, 복제에 관한 권리를 가진다.` +
            `<br/><br/>` +
            `제 2 조 (계약의 내용)` +
            `<br/>` +
            `(1)"을"은 정보통신망을 통하여 "갑"의 저작물을 복제하거나 전송 할 수 있도록 온라인 서비스를 제공한다.` +
            `(2) 본 계약에 있어서 "을"이 제공하는 서비스의 범위는 “을” 이 소유 하는 웹사이트와 “을"과 제휴한 웹사이트에 대한 전송, 배포, 복제권 등 계약 대상 저작물을 이용하는 행위이다.` +
            `<br/><br/>` +
            `제 3 조 (계약기간)` +
            `<br/>` +
            `본 계약의 유효기간은 계약일로부터 1년으로 한다. 기간만료일 1개월 전까지 '갑', '을' 어느 한 쪽에서 계약 갱신을 원하지 않는다는 서면 통고가 없는 한 본 계약과 동일한 기간만큼 자동적으로 연장된다.` +
            `<br/><br/>` +
            `제 4 조 (분쟁책임)` +
            `<br/>` +
            `1.'갑'은 계약 대상 저작물의 내용에 있어 타인의 저작권을 침해하거나 명예훼손 또는 기타 문제의 발생으로 인하여 '을' 또는 제 3 자에게 손해, 손실이 발생하였을 경우 전적으로 책임을 지며 발생된 손해나 손실에 대하여 보상 또는 배상하여야 한다.` +
            `2. 저작물에 대하여 제3자로부터 저작권 및 기타 권리의 침해 또는 명예훼손, 음란성 등의 이유로 이의가 제기된 경우 ‘을’은 당해 저작물을 임시 삭제할 수 있으며, 이의를 제기한자와 등록자 간에 소송, 합의 등을 통해 당해 게시물에 관한 법적 문제가 종결된 후 이를 근거로 “을”에 신청이 있는 경우에만 상기 임시 삭제된 게시물은 다시 등록될 수 있다.` +
            `3. "갑"은 저작권 분쟁 관련하여 개인정보가 정확하지 않아 발생하는 민형사상 일체의 책임을 져야 한다. 개인정보라 함은 성명, 주민등록번호, 연락처, 현주소, e-mail주소를 말한다.` +
            `4. '을'은 본 계약의 대상이 되는 저작물에 대해 저작권 분쟁이 있을 시 '갑'에게 통보하며, 회신이 없을 경우 저작권 없음을 인정한 것으로 간주하고, '을'의 정책에 따라 처리한다.` +
            `<br/><br/>` +
            `제 5 조 (저작권자의 표기)` +
            `<br/>` +
            `'을'은 '갑'의 저작물에 대해 저작자의 성명을 표시하되, ID로 표기하는 것이 가능하다.` +
            `<br/><br/>` +
            `제 6 조 (계약 외의 사용목적)` +
            `<br/>` +
            `'을'이 계약 대상 저작물 내용의 전부 또는 일부를 제2조 2항에 정하지 않은 용도로 전송, 배포, 복제 할 때에는 사전에 '갑'의 서면 승낙을 얻어야 한다.` +
            `<br/><br/>` +
            `제 7 조 (계약의 해제)` +
            `<br/>` +
            `'갑' 또는 '을'이 계약에 정한 사항을 위반했을 때에는 그 상대방은 상당한 기간을 정하여 그 이행을 최고한 후 계약을 해제할 수 있고, 또 손해의 배상을 청구할 수 있다.` +
            `<br/><br/>` +
            `제 8 조 (계약의 해석 및 보완)` +
            `<br/>` +
            `이 계약에 명시되어 있지 않거나 해석상 이견이 있을 경우에는 저작권법, 민법 등을 준용하고 사회통념과 조리에 맞게 해결한다.` +
            `<br/><br/>` +
            `제 9 조 (관할법원)` +
            `<br/>` +
            `본 계약과 관련하여 분쟁이나 소송이 발생하는 경우에, 저작권법에 의해 설치된 저작권심의 조정위원회의 조정 또는 "을"의 소재지를 관할하는 법원에서 해결하기로 한다.`
    }
};