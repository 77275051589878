import React from "react";
import InputEmail from "../inputCommon/InputEmail";
import InputPhone from "../inputCommon/InputPhone";

function SignupInputSNS({signInfo, setSignInfo,
						 checkEmail, checkPhone,
						 onClickCheckEmail, onClickCertification}) {
	return (
		<>
			<div className="box">
				<div className="wrap_field" style={{margin: 0}}>
					<InputEmail able={true}
								check={checkEmail}
								onClick={onClickCheckEmail}
								required={true}
								signInfo={signInfo}
								setSignInfo={setSignInfo}
					/>
					<InputPhone able={true}
								check={checkPhone}
								required={true}
								onClick={onClickCertification}
								signInfo={signInfo}
								setSignInfo={setSignInfo}
					/>
				</div>
			</div>
		</>
	)
}

export default SignupInputSNS;