import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const LegalStatusChart = ({ data, color }) => {
  const rates = Object.values(data).map((item) => item.rate) || [];
  const labels = Object.keys(data) || [];
  const totalCnt = Object.values(data).reduce((sum, item) => sum + item.cnt, 0);

  const mappingName = (key) => {
    let name = "";
    if (key === "cancel") {
      name = "취하";
    } else if (key === "delete") {
      name = "소멸";
    } else if (key === "giveup") {
      name = "포기";
    } else if (key === "open") {
      name = "공개";
    } else if (key === "register") {
      name = "등록";
    } else if (key === "reject") {
      name = "거절";
    }
    return name;
  };

  const chartData = {
    labels: labels.map((item) => mappingName(item)),
    datasets: [
      {
        data: rates.map((item) => item),
        borderColor: [
          color.register,
          color.open,
          color.giveup,
          color.reject,
          color.cancel,
          color.delete,
        ],
        backgroundColor: [
          color.register,
          color.open,
          color.giveup,
          color.reject,
          color.cancel,
          color.delete,
        ],
      },
    ],
  };

  const chartOption = {
    plugins: {
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "start",
        offset: -45,
        formatter: (value, context) => {
          return context.chart.data.labels[context.dataIndex];
        },
        font: {
          size: 12,
          weight: "bold",
        },
        padding: {
          left: 5,
          right: 5,
          top: 5,
          bottom: 20,
        },
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 50,
    layout: {
      padding: {
        top: 50,
        bottom: 50,
      },
    },
  };

  return (
    <div
      id="LegalStatusChart"
      style={{ position: "relative", width: "360px", height: "360px" }}
    >
      {Object.keys(data).length === 0 ? (
        "데이터가 없습니다."
      ) : (
        <>
          <Doughnut
            data={chartData}
            options={chartOption}
            plugins={[ChartDataLabels]}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            전체 출원건수
            <br />
            <br />
            {window.$Global.commaify(totalCnt)}건
          </div>
        </>
      )}
    </div>
  );
};

export default LegalStatusChart;
