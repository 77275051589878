import "./css/AnnualFeeReceipt.scss";
import React, { useEffect, useRef, useState } from "react";
import AnnualPay from "../../API/annualPay";
import { useReactToPrint } from "react-to-print";
import AdminAPI from "../../API/admin";
import ReceiptInfoKR from "./receipt/ReceiptInfoKR";
import ReceiptInfoOverseas from "./receipt/ReceiptInfoOverseas";
import ReceiptCostKR from "./receipt/ReceiptCostKR";
import ReceiptCostOverseas from "./receipt/ReceiptCostOverseas";

const AnnualFeeReceipt = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const { nation } = annualFeeData;
  const componentRef = useRef();
  const hideRef = useRef();
  const hideRefTwo = useRef();
  const hideRefThree = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "영수증",
  });

  useEffect(() => {
    switch (showPatent) {
      case "patent":
        isAdmin ? getAdminPatentReceiptData() : getPatentReceipt();
        break;
      case "trademark":
        isAdmin ? getAdminTradeMarkReceipt() : getTradeMarkReceipt();
        break;
      case "design":
        isAdmin ? getAdminDesignReceipt() : getDesignReceipt();
        break;
    }
  }, []);

  const getAdminPatentReceiptData = () => {
    return AdminAPI.getAdminPatentReceiptData(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const getAdminTradeMarkReceipt = () => {
    return AdminAPI.getAdminTradeMarkReceiptData(
      annualFeeData.trademark_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const getAdminDesignReceipt = () => {
    return AdminAPI.getAdminDesignReceiptData(
      annualFeeData.design_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const getPatentReceipt = () => {
    return AnnualPay.getPatentReceiptData(
      annualFeeData.patent_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const getTradeMarkReceipt = () => {
    return AnnualPay.getTradeMarkReceiptData(
      annualFeeData.trademark_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const getDesignReceipt = () => {
    return AnnualPay.getDesignReceiptData(
      annualFeeData.design_idx,
      annualFeeData.annual_pay_idx
    ).then((res) => {
      setData(res.data);
    });
  };

  const downloadFile = (file_idx) => {
    let url = (function () {
      switch (showPatent) {
        case "patent":
          return isAdmin
            ? `/admin/company/${annualFeeData.patent_idx}/patent/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
            : `/manager/patent/${annualFeeData.patent_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`;
        case "trademark":
          return isAdmin
            ? `/admin/company/${annualFeeData.trademark_idx}/trademark/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
            : `/manager/trademark/${annualFeeData.trademark_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`;
        case "design":
          return isAdmin
            ? `/admin/company/${annualFeeData.design_idx}/design/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
            : `/manager/design/${annualFeeData.design_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`;
      }
    })();
    window.$Global.fileDownloadSync(url);
    /*window.$Global
      .fileDownloadSync(
        isAdmin
          ? showPatent
            ? `/admin/company/${annualFeeData.patent_idx}/patent/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
            : `/admin/company/${annualFeeData.trademark_idx}/trademark/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
          : showPatent
          ? `/manager/patent/${annualFeeData.patent_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
          : `/manager/trademark/${annualFeeData.trademark_idx}/annualPay/${annualFeeData.annual_pay_idx}/billing/file/${file_idx}/download`
      )
      .then((res) => console.log(res.data));*/
  };

  return (
    <>
      <div id="AnnualFeeReceipt" ref={componentRef}>
        <div className="title">
          <h2>
            연차료 비용 청구서
            <span>{`[${nation === "KR" ? "국내" : "해외"}]`}</span>
          </h2>
        </div>
        <div className="title" ref={hideRef}>
          <i
            className="icon_exit_gray"
            style={{ cursor: "pointer" }}
            onClick={() =>
              setShowPopup({
                receipt: false,
              })
            }
          />
        </div>
        <div className="company_info">
          <p>주식회사 아이피나우</p>
          <p>
            (06744) 서울특별시 서초구 바우뫼로 175, 5F
            <br />
            (양재동, 창덕빌딩)
          </p>
        </div>
        <div className="annual_fee_receipt_company">
          {nation === "KR" ? (
            <ReceiptInfoKR annualFeeData={annualFeeData} data={data} />
          ) : (
            <ReceiptInfoOverseas annualFeeData={annualFeeData} data={data} />
          )}
        </div>
        <div className="annual_fee_receipt_table">
          {nation === "KR" ? (
            <ReceiptCostKR data={data} />
          ) : (
            <ReceiptCostOverseas data={data} />
          )}
        </div>
        <div className="annual_fee_receipt_cost">
          <div>
            <p>
              합&emsp;계 <span>단위 (KRW)</span>
            </p>
          </div>
          <p>₩{window.$Global.numberWithCommas(data?.total_amount || 0)}</p>
        </div>
        <div className="annual_fee_receipt_file" ref={hideRefTwo}>
          <div>첨부 파일</div>
          <div>
            {data?.file?.map((el) => {
              return (
                <p
                  onClick={() => downloadFile(el.idx)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="icon_download_attached" />
                  {el.file_name}.{el.file_type}
                </p>
              );
            })}
          </div>
        </div>
        <p className="annual_fee_receipt_account">
          계좌번호 : {data.bank_name} {data.account_number} (예금주 :{" "}
          {data.account_name})
        </p>
        <div className="annual_fee_receipt_print" ref={hideRefThree}>
          <button onClick={handlePrint}>
            <i className="icon_print" />
            프린트 하기
          </button>
          {/*<button onClick={download}>*/}
          {/*  <i className="icon_download_attached" />*/}
          {/*  청구서 PDF 다운로드*/}
          {/*</button>*/}
        </div>
      </div>
    </>
  );
};

export default AnnualFeeReceipt;
