import "./css/IntroServiceIntro.scss";
import React from "react";

const IntroServiceIntro = ({ history }) => {
  return (
    <div id="IntroServiceIntro">
      <img
        src={require("../../assets/images/intro/ipnow_prm_img.png").default}
      />
      <div className="qna">
        <h5>문의하기</h5>
        <div className="qna_item">
          <i className="icon_call" />
          <p>전화번호</p>
          <p>02-3463-9766</p>
        </div>
        <div className="qna_item">
          <i className="icon_email" />
          <p>이메일</p>
          <p>jhlee@ipnow.co.kr</p>
        </div>
      </div>
      {/*<div className="signup_wrapper">*/}
      {/*  <button onClick={() => history.push("/signup/type")}>*/}
      {/*    무료 회원가입*/}
      {/*  </button>*/}
      {/*</div>*/}
    </div>
  );
};

export default IntroServiceIntro;
