import React from "react";
import {CacheRoute} from "react-router-cache-route";
import CommunityListView from "../components/community/CommunityListView";
import {Route} from "react-router-dom";
import CommunityDetail from "../components/community/CommunityDetail";
import CustomSwitch from "./common/CustomSwitch";

function CommunityRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`} component={CommunityListView}/>
			<Route exact path={`${match.path}/detail/:idx`} component={CommunityDetail}/>
		</CustomSwitch>
	)
}

export default CommunityRoute;