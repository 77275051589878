import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import "./css/TutorialList.scss";
import TutorialAPI from "../../API/tutorial";

function TutorialMain() {
    const [videoTag, setVideoTag] = useState([]);

    useEffect(() => {
        TutorialAPI.getList({page:1, count: 9, type: 0}).then(res => {
            let tag_video =
                res.data.items.map((item, idx) => {
                    return (
                        <Link key={idx} to={`detail/${item.idx}`} className="item">
                            <img src={window.$Global.getCDN(item.thumbnail_key)} className="bg_image"/>
                            <p>{item.title}</p>
                        </Link>
                    );
                });
            setVideoTag(tag_video);
        });
    }, []);


    return (
        <div id="TutorialMain">
            <h2 className="page_title">IPNOW 튜토리얼</h2>

            <div className="wrap">
                <div className="area_video">
                    <div className="head">
                        <h2>동영상</h2>
                        <Link to="list?type=video" className="more">
                            <p>더보기</p>
                            <i className="icon_more_arrow_right_small_black"/>
                        </Link>
                    </div>
                    <div className="list">
                        {videoTag}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TutorialMain;
