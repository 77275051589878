import React, {useEffect, useState} from "react";
import "./css/AnnualCountGraph.scss";
import {Line} from "react-chartjs-2";
import AnnualPayAPI from "../../API/annualPay/index";

function AnnualCountGraph({history}) {

    let year_arr = [];
    const curYear = new Date().getFullYear();
    const pastYear = curYear - 10;
    const [annualData, setAannualData] = useState({apply: [], register: []});

    for (let i = pastYear; i <= curYear; i++) {
        year_arr.push(i);
    }

    useEffect(async () => {
        AnnualPayAPI.getIPAnnualList(pastYear, curYear).then(res => {
            let apply = res.data.apply;
            let register = res.data.register;

            let apply_keys = Object.keys(apply);
            let register_keys = Object.keys(register);

            let apply_arr = [];
            let register_arr = [];

            for (let i in apply_keys) {
                apply_arr.push({cnt: apply[apply_keys[i]].cnt});
            }

            for (let i in register_keys) {
                register_arr.push({cnt: register[register_keys[i]].cnt});
            }
            setAannualData({apply: apply_arr, register: register_arr});
        });
    }, []);

    const patent_count_data = {
        labels: year_arr,
        datasets: [
            {
                label: '출원갯수',
                data: annualData.apply.map(item => item.cnt),
                backgroundColor: 'rgb(255, 172, 18)',
                borderColor: 'rgba(255, 172, 18, 0.2)',
                pointRadius: 10,
                pointHoverRadius: 10,
                borderWidth: 8,
                lineTension: 0,
                fill: false,
            },
            {
                label: '등록갯수',
                data: annualData.register.map(item => item.cnt),
                backgroundColor: 'rgb(0, 149, 174)',
                borderColor: 'rgba(0, 149, 174, 0.2)',
                pointRadius: 10,
                pointHoverRadius: 10,
                borderWidth: 8,
                lineTension: 0,
                fill: false,
            },
        ],
    };

    const max = window.$Global.calcGraphMaxNumber(annualData.apply.map(item => item.cnt).concat(annualData.register.map(item => item.cnt)));

    const patent_count_options = {
        onClick: function (e, item) {
            let index = item.length ? item[0]._index : this.scales['x-axis-0'].getValueForPixel(e.x);

            if (Number.isInteger(index)) {
                let selectedYear = pastYear + index;
                history.push(`/annualCount/${selectedYear}/list`);
            }
        },
        legend: {
            display: false,
        },
        maintainAspectRatio: false,
        scales: {
            angleLines: {
                lineWidth: 0,
            },
            yAxes: [{
                ticks: {
                    display: true,
                    stepSize : max / 3,
                    beginAtZero: true,
                    max : max,
                    callback: (value) => Math.floor(value)
                },
                gridLines: {
                    display : true
                }
            }],
            xAxes: [{
                gridLines: {
                    display : false
                }
            }]
        },
        plugins: {
            datalabels: {
                anchor: 'end',
                align: 'top',
                font: {
                    weight: 'bold'
                }
            }
        },
    };

    return (
        <div id="AnnualCountList">
            <div className="header">
                <div className="info">
                    <h2>IP 등록, 출원 실적</h2>
                    <p>{pastYear}년 ~ {curYear}년</p>
                    <p className="noti">
                        <i className="icon_alert_circle"/>
                        <span>자세히 보고 싶은 년도를 클릭하시면 년도별 데이터를 확인하실 수 있습니다</span>
                    </p>
                </div>
                <div className="legend">
                    <div className="item">
                        <div className="circle blue"/>
                        <p>등록갯수</p>
                    </div>
                    <div className="item">
                        <div className="circle yellow"/>
                        <p>출원갯수</p>
                    </div>
                </div>
            </div>
            <div className="chart">
                <Line
                    data={patent_count_data}
                    width={880}
                    height={400}
                    options={patent_count_options}
                />
            </div>
        </div>
    )
}

export default AnnualCountGraph;