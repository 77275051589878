import icon from "../../assets/images/businessPlan/button-information.svg";
import { useState } from "react";

const SecondInfoText = ({ idx }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [infoDivContents, setInfoDivContents] = useState();

  const IdxZero = () => {
    return (
      <>
        <p>
          1. 사업명: 해당 연구개발과제의 사업명을 기재합니다(연구자 직접 기입
          불필요).
        </p>
        <p>
          2. 내역사업명: 해당 연구개발과제의 내역사업명을 기재합니다(연구자 직접
          기입 불필요).
        </p>
        <p>
          3. 총괄연구개발 식별번호: 총괄연구개발명에 부여되는 번호를
          기재합니다(연구자 직접 기입 불필요).
        </p>
        <p>
          4. 연구개발과제번호: 연구개발과제 선정 시 부여되는 번호를
          기재합니다(연구자 직접 기입 불필요).
        </p>
      </>
    );
  };

  const IdxOne = () => {
    return (
      <>
        <p>5. 기술분류: 연구개발계획서 표지에 기재한 기술분류를 기재합니다.</p>
        <p>
          6. 총괄연구개발명: 연구개발계획서 표지에 기재한 총괄연구개발명을
          기재합니다.
        </p>
        <p>
          7. 연구개발과제명: 연구개발계획서 표지에 기재한 연구개발과제명을
          기재합니다.
        </p>
        <p>
          8. 전체 연구개발기간: 연구개발계획서 표지에 기재한 연구개발과제의 전체
          연구개발기간을 기재합니다.
        </p>
      </>
    );
  };

  const IdxTwo = () => {
    return (
      <p>
        9. 총 연구개발비: 연구개발계획서 표지에 기재한 연구개발과제의 총
        연구개발비를 기재합니다.
      </p>
    );
  };

  const IdxThree = () => {
    return (
      <>
        <p>
          10. 연구개발단계: 해당되는 연구개발과제의 연구개발단계 유형에 [√]
          표시합니다.
        </p>
        <p>
          &emsp;1) 기초연구단계란 특수한 응용 또는 사업을 직접적 목표로 하지
          아니하고 현상 및 관찰 가능한 사실에 대한 새로운 지식을 얻기 위하여
          수행하는 이론적 또는 실험적 연구단계를 의미합니다.
        </p>
        <p>
          &emsp;2) 응용연구단계란 기초연구단계에서 얻어진 지식을 이용하여 주로
          실용적인 목적으로 새로운 과학적 지식을 얻기 위하여 수행하는 독창적인
          연구단계를 의미합니다.
        </p>
        <p>
          &emsp;3) 개발연구단계란 기초연구단계, 응용연구단계 및 실제 경험에서
          얻어진 지식을 이용하여 새로운 제품, 장치 및 서비스를 생산하거나 이미
          생산되거나 설치된 것을 실질적으로 개선하기 위하여 수행하는 체계적
          연구단계를 의미합니다.
        </p>
        <p>
          &emsp;4) 기타는 기초, 응용, 개발 등 3가지 단계에 해당하지 않는 경우를
          의미합니다.
        </p>
      </>
    );
  };

  const IdxFour = () => {
    return (
      <>
        <p>
          11. 기술성숙도: 특정기술(재료, 부품, 소자, 시스템 등)의 성숙도로서
          최종 연구개발 목표, 내용, 최종 결과물 등을 고려하여 아래의 9단계 중
          해당하는 단계를 선택합니다(특정기술의 개발을 목적으로 하는
          연구개발과제의 경우에만 작성).
        </p>
        <p>
          &emsp;1) 기초연구단계: 1단계(기초 이론ㆍ실험), 2단계(실용 목적의
          아이디어, 특허 등 개념 정립)
        </p>
        <p>
          &emsp;2) 실험단계: 3단계(연구실 규모의 기본성능 검증), 4단계(연구실
          규모의 소재ㆍ부품ㆍ시스템 핵심성능 평가)
        </p>
        <p>
          &emsp;3) 시작품단계: 5단계(확정된 소재ㆍ부품ㆍ시스템 시작품 제작 및
          성능 평가), 6단계(시범규모의 시작품 제작 및 성능 평가)
        </p>
        <p>
          &emsp;4) 제품화단계: 7단계(신뢰성평가 및 수요기업 평가),
          8단계(시험제품 인증 및 표준화)
        </p>
        <p>&emsp;5) 사업화단계: 9단계(사업화)</p>
      </>
    );
  };

  const IdxFive = () => {
    return (
      <>
        <p>14. 연구개발 목표: 연구개발과제의 목표를 500자 내외로 기재합니다.</p>
        <p>
          15. 연구개발 내용: 연구개발과제의 내용을 1,000자 내외로 기재합니다.
        </p>
      </>
    );
  };

  const IdxSix = () => {
    return (
      <p>
        16. 연구개발성과 활용계획 및 기대효과: 연구개발성과의 수요처, 활용내용,
        경제적 파급효과 등을 500자 내외로 기재합니다(연구시설ㆍ장비 구축을
        목적으로 하는 연구개발과제의 경우에 연구시설ㆍ장비를 활용한 성과관리 및
        자립운영계획, 수입금 관리 및 운영계획 등).
      </p>
    );
  };

  const showDiv = (idx) => {
    if (idx === 0) {
      setInfoDivContents(<IdxZero />);
    } else if (idx === 1) {
      setInfoDivContents(<IdxOne />);
    } else if (idx === 2) {
      setInfoDivContents(<IdxTwo />);
    } else if (idx === 3) {
      setInfoDivContents(<IdxThree />);
    } else if (idx === 4) {
      setInfoDivContents(<IdxFour />);
    } else if (idx === 5) {
      setInfoDivContents(<IdxFive />);
    } else if (idx === 6) {
      setInfoDivContents(<IdxSix />);
    }
    setIsHovering(true);
  };

  return (
    <div
      className="info_icon"
      id={idx}
      onMouseEnter={() => showDiv(idx)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img src={icon} alt="infoIcon" />
      {isHovering && (
        <div className="info_div" style={{ display: "block" }}>
          {infoDivContents}
        </div>
      )}
    </div>
  );
};

export default SecondInfoText;
