import React, { useState, useEffect, useContext } from "react";
import CheckBox from "../common/CheckBox";
import InfiniteScroll from "react-infinite-scroll-component";
import "./css/CostInputList.scss";
import PatentAPI from "../../API/patent";
import TradeMarkAPI from "../../API/trademark";
import DesignAPI from "../../API/design";
import MemberContext from "../../store/Member";
import { useHistory } from "react-router-dom";

function CostInputList({ history }) {
  const { manage_company_idx } = useContext(MemberContext);
  const count = 100;
  const [curPage, setCurPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [keywordType, setKeywordType] = useState(0);
  const [data, setData] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [tbody, setTbody] = useState([]);
  const [check, setCheck] = useState({
    patent: true,
    tradeMark: false,
    design: false,
  });
  const isPatent = check.patent;
  const isTradeMark = check.tradeMark;

  useEffect(() => {
    setCurPage(1);
    getList({ page: 1, count: count });
  }, [check]);

  useEffect(() => {
    if (window.$Global.isEmptyObject(data)) return;
    setTbody(
      data.items.map((item, index) => {
        return (
          <tr
            key={index}
            onClick={() =>
              history.push(
                `/cost/detail/${
                  isPatent ? "patent" : isTradeMark ? "tradeMark" : "design"
                }/${item.idx}/new`
              )
            }
          >
            <td>{data.total_count - index}</td>
            <td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
            <td>{item.manage_number}</td>
            <td>{item.apply_number}</td>
            <td>{window.$Global.convertDate(item.apply_at)}</td>
            <td>{item.register_number}</td>
            <td>{window.$Global.convertDate(item.register_at)}</td>
            <td>{window.$Global.convertStateToText(item.state)}</td>
          </tr>
        );
      })
    );
  }, [data]);

  const getList = async (payload, type) => {
    let result = {};
    if (check.patent) {
      result = await PatentAPI.getPatentListAsAgent(
        manage_company_idx,
        payload
      ).then((res) => res.data);
    } else if (check.tradeMark) {
      result = await TradeMarkAPI.getTradeMarkListAgent(
        manage_company_idx,
        payload
      ).then((res) => res.data);
    } else if (check.design) {
      result = await DesignAPI.getDesignListAsAgent(
        manage_company_idx,
        payload
      ).then((res) => res.data);
    }
    if (type == "next") {
      result.items = data.items.concat(result.items);
    }
    setData(result);

    if (result.page == result.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const nextInvestorList = () => {
    let payload = {
      page: curPage + 1,
      count: count,
      keyword: keyword,
    };
    setCurPage(curPage + 1);
    getList(payload, "next");
  };

  const searchPatent = () => {
    let param = {
      page: 1,
      count: count,
      keyword: keyword,
      type: keywordType,
    };
    setCurPage(1);
    getList(param);
  };

  const onChangeChecked = (e, type) => {
    let copy = { patent: false, tradeMark: false, design: false };
    let keys = Object.keys(copy);
    let changed = false;
    copy[type] = e.target.checked;

    for (let i in keys) {
      if (copy[keys[i]]) {
        changed = true;
        break;
      }
    }
    if (changed) {
      setCheck(copy);
    }
  };

  return (
    <div id="CostInputList">
      <h2>관리 IP 비용 청구하기</h2>
      <div className="wrap_search">
        <select onChange={(e) => setKeywordType(e.target.value)}>
          <option value={0}>관리번호</option>
          <option value={17}>출원번호</option>
          <option value={6}>등록번호</option>
        </select>
        <input
          type="text"
          className="input_search"
          placeholder="검색어를 입력해주세요"
          onChange={(e) => setKeyword(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") searchPatent();
          }}
        />
        <button className="icon_search_black" onClick={searchPatent} />
      </div>
      <div className="sorting">
        <CheckBox
          text="특허"
          checked={check.patent}
          onChangeChecked={(e) => onChangeChecked(e, "patent")}
        />
        <CheckBox
          text="상표"
          checked={check.tradeMark}
          onChangeChecked={(e) => onChangeChecked(e, "tradeMark")}
        />
        <CheckBox
          text="디자인"
          checked={check.design}
          onChangeChecked={(e) => onChangeChecked(e, "design")}
        />
      </div>
      <div className="table-box-wrap">
        <div className="wrap_list custom_tr_hover" id="scrollableDiv">
          <InfiniteScroll
            dataLength={tbody.length}
            next={nextInvestorList}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table className="list">
              <thead>
                <tr>
                  <th>건수</th>
                  <th>국가</th>
                  <th>관리번호</th>
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>현재상태</th>
                </tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default CostInputList;
