import React, { forwardRef, useRef, useImperativeHandle } from "react";
import { createPortal } from "react-dom";
import useClickOutside from "../../hooks/useClickOutside";
import "./css/Modal.scss";

const Modal = forwardRef(({ children, onClose }, ref) => {
  const modalRef = useRef();
  const containerRef = useClickOutside(() => {
    document.body.style.overflow = 'initial';
    modalRef.current?.close()
    onClose && onClose();
  });
  useImperativeHandle(ref, () => ({
    open() {
      modalRef.current?.showModal();
    },
    close() {
      modalRef.current?.close();
    },
  }));

  return createPortal(
    <dialog id="modal" ref={modalRef}>
      <div ref={containerRef}>{children}</div>
    </dialog>,
    document.getElementById("root")
  );
});

export default Modal;
