import React from "react";
import CheckBox from "../common/CheckBox";

function MobileSignupTypeItem({title, desc, icon_className, checked, moreState, onClickMoreToggle, onChangeChecked}) {
    return (
        <div className={`tab ${checked ? 'active' : ''}`}>
            <div className="tab_title">
                <CheckBox checked={checked} onChangeChecked={onChangeChecked}/>
                <div className="title">
                    <i className={icon_className}/>
                    <p>{title}</p>
                </div>
                {
                    moreState
                    ?   <button className="icon_more_arrow_down_black" onClick={onClickMoreToggle}/>
                    :   <button className="icon_more_arrow_up" onClick={onClickMoreToggle}/>
                }
            </div>
            <div className={`tab_content ${moreState ? 'active' : ''}`} dangerouslySetInnerHTML={ {__html: desc} }/>
        </div>
    )
}

export default MobileSignupTypeItem;
