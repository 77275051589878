import { useState } from "react";
import ToggleButton from "./ToggleButton";
import ToggleContent from "./ToggleContent";
import "./css/ToggleSlide.scss";

const ToggleSlide = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`toggle-slide-container`}>
      <div className="top">
        <strong className="title">{title}</strong>
        <ToggleButton isOpen={isOpen} toggleOpen={toggleOpen} />
      </div>
      <ToggleContent isOpen={isOpen}>{content}</ToggleContent>
    </div>
  );
};

export default ToggleSlide;
