import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import DeadLineList from "../components/deadline/DeadLineList";
import DeadLineSetting from "../components/deadline/DeadLineSetting";
import CustomSwitch from "./common/CustomSwitch";

function DeadlineRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={DeadLineList}/>
			<UserAuthRoute exact path={`${match.path}/setting`} requireAuth={true} component={DeadLineSetting}/>
		</CustomSwitch>
	)
}

export default DeadlineRoute;