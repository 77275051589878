import React from "react";
import { Route, Redirect } from 'react-router-dom';

function UserAuthRoute ({component: Component, requireAuth, ...parentProps}) {
    const auth = window.$Global.hasLoginToken();
    const renderFunc = (props) => ((!requireAuth || auth) ? <Component {...props}/> : <Redirect to="/login"/>);

    if (requireAuth) {
        window.$Global.saveHistory();
    }
    return <Route {...parentProps} render={renderFunc}/>;
}
export default UserAuthRoute;
