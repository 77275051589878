import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import AnnualPay from "../../API/annualPay";
import "./css/AnnualFeeGiveUpAndReview.scss";
import Pagination from "../common/Pagination";
import AdminAPI from "../../API/admin";

const AnnualFeeGiveUpAndReview = ({ location }) => {
  const isAdmin = window.sessionStorage.getItem("admin") === "true"; // 관리자 여부 확인
  const { company_idx, year, month } = location.state; // 이전 페이지 정보 가져오기 위한 변수
  const [tabValue, setTabValue] = useState(0);
  const [showAll, setShowAll] = useState(false); // 전체보기 Checkbox에 사용되는 변수
  const [data, setData] = useState([]); // Response Data 저장 변수

  // 페이징을 위해 사용되는 변수들
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [count, setCount] = useState(10);
  const [sortUpdatedAt, setSortUpdatedAt] = useState(0);
  const nowYear = new Date().getFullYear();
  const [currentMonth, setCurrentMonth] = useState(month || 1);
  const [currentYear, setCurrentYear] = useState(year || 1);

  useEffect(() => {
    getDataList();
  }, [tabValue, currentYear, currentMonth, curPage, showAll, count]);

  const getDataList = () => {
    let params = {
      company_idx: company_idx,
      year: showAll ? 0 : currentYear, // 전체 보기시 year값을 0으로 보냄
      month: showAll ? 0 : currentMonth, // 전체 보기시 month값을 0으로 보냄
      page: curPage,
      count: count,
      ...(tabValue === 0 && { service: -1 }), //
    };

    let apiCall;

    if (tabValue === 0) {
      apiCall = AnnualPay.getAnnualPayReviewList;
    } else if (tabValue === 1) {
      apiCall = AnnualPay.getAnnualPayGiveUpList;
    } else if (tabValue === 2) {
      apiCall = AnnualPay.getAnnualPayDeletedList;
    }

    apiCall(params).then((res) => {
      setData(res.data.items);
      setLast(res.data.last);
    });
  };

  const handleChange = (e, value) => {
    setTabValue(value);
  };

  const onChangeDate = (e, type) => {
    const value = Number(e.target.value);
    if (type === "year") {
      setCurrentYear(value);
    } else {
      setCurrentMonth(value);
    }
    setCurPage(1);
  };

  const onChangeAll = (e) => {
    if (e.target.checked) {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
    setCurPage(1);
  };

  const formattedDate = (giveup_date) => {
    const date = new Date(giveup_date);
    if (isNaN(date)) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  // 포기했던 건들 되돌리는 기능(관리자)
  const onClickReturnGiveUp = (item) => {
    const { annual_pay_idx, company_idx } = item;

    const check = window.confirm("연차료 포기권을 되돌리시겠습니까?");
    if (check) {
      AdminAPI.revertGiveUp(company_idx, annual_pay_idx).then(() => {
        alert("완료되었습니다.");
        getDataList();
      });
    }
  };

  const getColSpan = () => {
    // tabValue = 0 => 검토
    // tabValue = 1 => 포기
    if (tabValue === 0) {
      return 13;
    } else if (tabValue === 1) {
      return isAdmin ? 13 : 12;
    } else if (tabValue === 2) {
      return 15;
    } else {
      return 13;
    }
  };

  const renderHeaders = () => {
    let commonHeaders = [
      { label: "No.", key: "no" },
      { label: "출원번호", key: "applicationNumber" },
      { label: "출원일", key: "applicationDate" },
      { label: "등록번호", key: "registrationNumber" },
      { label: "등록일", key: "registrationDate" },
      { label: "국가", key: "country" },
      { label: "권리자", key: "rightHolder" },
      { label: "관리주체", key: "manager" },
      { label: "납부기한", key: "paymentDue" },
      { label: "연차", key: "annuity" },
      { label: "금액", key: "amount" },
    ];

    if (tabValue === 0) {
      commonHeaders.push(
        { label: "검토내용", key: "reviewContent" },
        { label: "작성일시", key: "createdDateTime" }
      );
    } else if (tabValue === 1) {
      commonHeaders.push({ label: "지시날짜", key: "instructionDate" });
      if (isAdmin) {
        commonHeaders.push({ label: "되돌리기", key: "rollback" });
      }
    } else if (tabValue === 2) {
      commonHeaders.push(
        { label: "Comment", key: "comment" },
        { label: "User Comment", key: "userComment" },
        { label: "Final Comment", key: "finalComment" },
        { label: "삭제일", key: "adminDeletedDate" }
      );
    }

    return commonHeaders.map((header) => (
      <th key={header.key}>{header.label}</th>
    ));
  };

  return (
    <div id="AnnualFeeGiveUpAndReview">
      <header>
        <h2>연차료 검토/포기 리스트</h2>
        <div>
          <p>연월 변경</p>
          <select
            value={currentYear}
            disabled={showAll}
            onChange={(e) => onChangeDate(e, "year")}
          >
            {Array.from({ length: 26 }, (_, i) => nowYear - 10 + i)
              .reverse()
              .map((y) => (
                <option key={y} value={y}>
                  {y}년
                </option>
              ))}
          </select>
          {tabValue !== 2 && (
            <select
              value={currentMonth}
              disabled={showAll}
              onChange={(e) => onChangeDate(e, "month")}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                <option key={m} value={m}>
                  {m}월
                </option>
              ))}
            </select>
          )}
          <p>리스트 개수</p>
          <select
            value={count}
            onChange={(e) => {
              setCount(e.target.value);
              setCurPage(1);
            }}
          >
            <option value={10}>10개</option>
            <option value={30}>30개</option>
            <option value={50}>50개</option>
            <option value={100}>100개</option>
          </select>
          <label>
            <p>전체보기</p>
            <input
              type="checkbox"
              value={showAll}
              onChange={(e) => onChangeAll(e)}
            />
          </label>
        </div>
      </header>
      <section>
        <Tabs
          className="tab_wrapper"
          value={tabValue}
          onChange={handleChange}
          centered
        >
          <Tab className="tab_button" label="검토" />
          <Tab className="tab_button" label="포기" />
          {isAdmin && <Tab className="tab_button" label="삭제" />}
        </Tabs>
        <div>
          <table>
            <thead>
              <tr>{renderHeaders()}</tr>
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <tr>
                  <td colSpan={getColSpan()}>데이터가 없습니다.</td>
                </tr>
              ) : (
                data.map((item, index) => {
                  return (
                    <tr>
                      <td>{(curPage - 1) * count + (index + 1)}</td>
                      <td>{item.apply_number}</td>
                      <td>{window.$Global.convertDate(item.apply_at)}</td>
                      <td>{item.register_number}</td>
                      <td>{window.$Global.convertDate(item.register_at)}</td>
                      <td>
                        {window.$Global.convertNationCodeToStr(item.nation)}
                      </td>
                      <td>
                        {item.joint_owner_list?.map((item) => {
                          return <p>{item}</p>;
                        })}
                      </td>
                      <td>{item.management_name}</td>
                      <td>
                        {window.$Global.convertDate(
                          item.annual_payment_deadline
                        )}
                      </td>
                      <td>{item.payment_year}</td>
                      <td>{`${window.$Global.commaify(item.cost)} 원`}</td>
                      {tabValue === 0 && <td>{item.memo}</td>}
                      {tabValue === 0 && (
                        <td>
                          {window.$Global.convertDate(item.updated_at * 1000)}
                        </td>
                      )}
                      {tabValue === 1 && (
                        <td>
                          {item.giveup_date
                            ? formattedDate(item.giveup_date)
                            : "N/A"}
                        </td>
                      )}
                      {tabValue === 1 && isAdmin && (
                        <td>
                          <i
                            className="icon_refresh"
                            style={{ cursor: "pointer" }}
                            onClick={() => onClickReturnGiveUp(item)}
                          />
                        </td>
                      )}
                      {tabValue === 2 && <td>{item.comment}</td>}
                      {tabValue === 2 && <td>{item.user_comment}</td>}
                      {tabValue === 2 && <td>{item.final_comment}</td>}
                      {tabValue === 2 && (
                        <td>
                          {item.admin_deleted_date
                            ? formattedDate(item.admin_deleted_date)
                            : "N/A"}
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      </section>
    </div>
  );
};

export default AnnualFeeGiveUpAndReview;
