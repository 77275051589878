import React, {useEffect, useState} from "react";
import "./css/AdminCalcYearPay.scss"
import Pagination from "../common/Pagination";
import AdminAPI from "../../API/admin";

function AdminCalcYearPay() {
    const [type, setType] = useState(0); // 0 대학 1 특허 연구소 2 나머지
    const [resData, setResData] = useState({items: []});
    const [curPage, setCurPage] = useState(1);
    const arr = ["대학", "출연 연구소", "기업"];
    let params = {
        page: curPage,
        count: 20,
        type: type
    };

    useEffect(() => getList(), [curPage])

    const getList = () => {
        AdminAPI.getAnnulPay(params).then(res => setResData(res.data));
    };

    const changeTab = (tabType) => {
        params.type = tabType;
        setCurPage(1);
        setType(tabType);
        AdminAPI.getAnnulPay(params).then(res => setResData(res.data));
    }

    const deleteData = (idx) => {
        AdminAPI.deleteAnnualPay(idx).then(() => getList());
    }

    return (
        <div id="AdminCalcYearPay">
            <div className="header">
                <h2 className="title">특허권자별 연차료 리스트</h2>
                <ul>
                    {
                        arr.map((item, idx) => <li key={idx} className={type == idx ? 'active' : ''} onClick={() => changeTab(idx)}>{item}</li>)
                    }
                </ul>
            </div>
            
            <table>
                <caption className="ir_txt">특허권자별 연차료 리스트</caption>
                <colgroup>
                    <col width="50px"/>
                    <col width="200px"/>
                    <col width="100px"/>
                    <col width="100px"/>
                    <col width="100px"/>
                    <col width="50px"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>순번</th>
                        <th>특허권자명</th>
                        <th>3년</th>
                        <th>5년</th>
                        <th>10년</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        resData.items.map((item, idx) => {
                            return (
                                <tr key={item.idx}>
                                    <td>{resData.total_count - idx - ((curPage - 1) * 20)}</td>
                                    <td>{item.company_name}</td>
                                    <td>{item.ap_three}</td>
                                    <td>{item.ap_five}</td>
                                    <td>{item.ap_ten}</td>
                                    <td>
                                        <button className="icon_badge_del ir_txt" onClick={() => deleteData(item.idx)}>삭제하기</button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination curPage={curPage} lastNum={resData.last} onClick={setCurPage} />
        </div>
    )
}

export default AdminCalcYearPay;