import React, {useState, useEffect} from "react";
import "./css/PopupInvestorList.scss";
import CheckBox from "../common/CheckBox";
import InvestmentAPI from "../../API/investment/index";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";


function PopupInvestorList({idx, show, onClickRegister, onClickClose}) {
    const count = 100;
    const [curPage, setCurPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [hasMore, setHasMore] = useState(false);
    const [tbody, setTbody] = useState([]);
    const [data, setData] = useState({});
    const [allChecked, setAllChecked] = useState(false);
    const [checkboxList, setCheckboxList] = useState([0]);

    useEffect(() => {
        if (idx == "self") {
            let payload = {
                page: 1,
                count: count,
                keyword: keyword
            };
            getInvestorList(payload);
        }
    }, []);

    useEffect(() => {
        setAllChecked(false);
        setCheckboxList(checkboxList.map(() => false));
    }, [show]);

    useEffect(() => {
        if (!window.$Global.isEmptyObject(data)) {
            drawTable();
        }
    }, [data, checkboxList]);

    const getInvestorList = (payload, type) => {
        InvestmentAPI.getInvestorList(payload).then(res => {
            let get_data = res.data;

            if (type == "next") {
                get_data.items = get_data.items.concat(data.items);
            }
            setData(get_data);
            setCheckboxList(res.data.items.map(() => false));

            if (get_data.page == get_data.last) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    const searchInvestorList = () => {
        let payload = {
            page: 1,
            count: count,
            keyword: keyword
        };
        setCurPage(1);
        getInvestorList(payload);
    };

    const nextInvestorList = () => {
        let payload = {
            page: curPage + 1,
            count: count,
            keyword: keyword
        };
        setCurPage(curPage + 1);
        getInvestorList(payload, "next");
    };

    const drawTable = () => {
        setTbody(data.items.map((item, index) => {
            return (
                <tr>
                    <td>
                        <CheckBox checked={checkboxList[index]} onChangeChecked={() => {
                            let copy = _.cloneDeep(checkboxList);
                            copy[index] = !copy[index];
                            setCheckboxList(copy);
                        }}/>
                    </td>
                    <td>{index + 1}</td>
                    <td>{window.$Global.convertInvestTypeToStr(item.type_detail)}</td>
                    <td>{item.name}</td>
                    <td>{item.intrest_field || "관심분야 미등록"}</td>
                </tr>
            );
        }));
    };

    const onClickAllCheck = () => {
        setAllChecked(!allChecked);
        setCheckboxList(checkboxList.map(() => !allChecked));
    };

    const onClickSubmit = () => {
        let arr = [];

        for (let i = 0; i < checkboxList.length; i++) {
            if (checkboxList[i]) {
                arr.push(data.items[i]);
            }
        }
        onClickRegister(arr);
    };

    if (!show) return null;

    return (
        <div id="PopupInvestorList">
            <div className="header">
                <h2>투자자 구분</h2>
                <div className="btns">
                    <button className="btn_register" onClick={onClickSubmit}>등록</button>
                    <button className="icon_exit" onClick={onClickClose}/>
                </div>
            </div>
            <div className="search">
                <input type="text" placeholder="회사명을 입력하세요" onChange={(e) => setKeyword(e.target.value)} onKeyUp={(e) => e.key === 'Enter' && searchInvestorList()}/>
                <button className="icon_search_black" onClick={searchInvestorList}/>
            </div>
            <div className="table-box-wrap">
                <div id="scrollableDiv" className="wrap_list">
                    <InfiniteScroll
                        dataLength={data.items.length}
                        next={nextInvestorList}
                        hasMore={hasMore}
                        scrollableTarget="scrollableDiv">
                        <table>
                            <thead>
                                <tr>
                                    <th><CheckBox checked={allChecked} onChangeChecked={onClickAllCheck}/></th>
                                    <th>순번</th>
                                    <th>투자자 유형</th>
                                    <th>투자자 명칭</th>
                                    <th>관심분야</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tbody}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    )
}

export default PopupInvestorList;
