import React, { useEffect, useRef, useState } from "react";
import "../css/MarketInfoRequest.scss";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import _ from "lodash";
import CommonAPI from "../../../API/common";
import Validator from "../../../util/validator";
import qs from "query-string";
import MarketInfoHeader from "../MarketInfoHeader";

function MarketInfoRequest({ history, location }) {
  const { idx } = qs.parse(location.search);
  const isEdit = Boolean(idx);
  const [request, setRequest] = useState({
    title: "", //제목(string)
    content: "", //내용(string)
    page: undefined, //페이지 수(int)
    phone: "", //연락처(string)
    price: "", //가격(price)
    proposal: 0, //역제안 여부(0 or 1) 0 거절 1 허용
    category1_idx: 0, // 대분류 카테고리
    category2_idx: 0, // 중분류 카테고리
  });
  const [etcCheck, setEtcCheck] = useState(false);
  const [proposalCheck, setProposalCheck] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [middleCategory, setMiddleCategory] = useState([]);
  const [selected, setSelected] = useState("");
  const titleRef = useRef();
  const contentRef = useRef();
  const pageRef = useRef();
  const phoneRef = useRef();
  const priceRef = useRef();
  const refs = [titleRef, contentRef, pageRef, priceRef, phoneRef];

  useEffect(() => {
    if (isEdit) {
      CommonAPI.getMarketRequest(idx).then((res) => {
        setRequest(res.data);
      });
    }
  }, []);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    changeMiddleCategory(selected);
  }, [selected]);

  const getCategory = () => {
    CommonAPI.getMarketCategory().then((res) => {
      let data = res.data.category_list;
      setCategoryData(data);
      console.log(data);
    });
  };
  const onChangeRequest = (key, value) => {
    let copy = _.cloneDeep(request);
    copy[key] = value;
    console.log(copy);
    setRequest(copy);
  };

  const onClickRequest = async () => {
    if (Validator.refValidator(refs)) {
      if (isEdit) {
        await CommonAPI.updateMarketRequest(idx, request).then(() =>
          alert("수정되었습니다")
        );
      } else {
        await CommonAPI.addMarketRequest(request).then(() =>
          alert("등록되었습니다")
        );
      }
      history.push("request/end");
    }
  };

  const onChangeData = (e) => {
    setSelected(e.target.value);
  };

  const changeMiddleCategory = (selected) => {
    setMiddleCategory([]);
    categoryData.map((el) => {
      if (el.idx === Number(selected)) {
        setMiddleCategory(el.child);
      }
    });
  };

  return (
    <div id="MarketInfoRequest">
      <MarketInfoHeader />
      <div className="wrap">
        <h2 className="title">시장 정보 작성 요청</h2>
        <p className="sub_title">시장 현황 조사를 요청 해 보세요.</p>
        <div className="request_wrapper">
          <div className="request_title">
            <h2>제목 작성하기</h2>
            <input
              type="text"
              placeholder="제목을 입력해주세요."
              value={request.title}
              onChange={(e) => onChangeRequest("title", e.target.value)}
              data-name="제목"
              ref={titleRef}
              autoFocus
            />
          </div>
          <div className="request_category">
            <h2>카테고리</h2>
            <select
              defaultValue="대분류"
              onChange={(e) => {
                onChangeRequest("category1_idx", e.target.value);
                onChangeData(e);
              }}
            >
              <option value="" selected disabled>
                대분류
              </option>
              {categoryData.map((el) => {
                return <option value={el.idx}>{el.name}</option>;
              })}
            </select>
            <select
              defaultValue="중분류"
              onChange={(e) => onChangeRequest("category2_idx", e.target.value)}
            >
              <option value="" selected disabled>
                중분류
              </option>
              {middleCategory?.map((el) => {
                return <option value={el.idx}>{el.name}</option>;
              })}
            </select>
            <input
              type="checkbox"
              id="etc"
              onClick={() => {
                setEtcCheck(!etcCheck);
              }}
            />
            <label for="etc">
              <i
                className={
                  etcCheck ? "icon_checkbox_blue" : "icon_checkbox_gray"
                }
              />
            </label>
            <p style={{ marginLeft: "6px" }}>기타</p>
          </div>
          <div className="request_content">
            <h2>
              시장 정보 작성
              <br /> 요청하기
            </h2>
            <textarea
              value={request.content}
              onChange={(e) => onChangeRequest("content", e.target.value)}
              data-name="요청 내용"
              ref={contentRef}
            />
          </div>
          <div className="request_price">
            <div>
              <h2>제시 가격 입력</h2>
              <input
                type="text"
                placeholder="금액을 입력해주세요."
                value={window.$Global.commaify(request.price)}
                onChange={(e) =>
                  onChangeRequest(
                    "price",
                    window.$Global.numberRegx(e.target.value)
                  )
                }
                data-name="제시 가격"
                ref={priceRef}
              />
              <p>원</p>
            </div>
            <div>
              <input
                type="checkbox"
                id="proposal"
                onClick={() => {
                  setProposalCheck(!proposalCheck);
                  onChangeRequest("proposal", Number(!proposalCheck));
                }}
              />
              <label for="proposal">
                <i
                  className={
                    proposalCheck ? "icon_checkbox_blue" : "icon_checkbox_gray"
                  }
                />
              </label>
              <p style={{ marginLeft: "6px" }}>
                관리자 측으로 부터 가격 역 제안 받기
              </p>
              <i className="icon_question_mark" />
            </div>
          </div>
          <div className="request_page">
            <h2>요청 페이지 수</h2>
            <input
              type="number"
              placeholder="페이지수를 입력해주세요."
              value={request.page}
              onChange={(e) =>
                onChangeRequest(
                  "page",
                  window.$Global.numberRegx(e.target.value)
                )
              }
              data-name="요청 페이지수"
              ref={pageRef}
            />
            <p>장</p>
          </div>
          <div className="request_phone">
            <h2>연락처</h2>
            <input
              type="number"
              placeholder="연락처를 입력해주세요."
              value={request.phone}
              onChange={(e) => onChangeRequest("phone", e.target.value)}
              data-name="연락처"
              ref={phoneRef}
            />
          </div>
        </div>
        <div className="btn_request">
          <button onClick={() => alert("준비중인 기능입니다.")}>
            임시저장
          </button>
          <button onClick={onClickRequest}>제출하기</button>
        </div>
      </div>
    </div>
  );
}

export default MarketInfoRequest;
