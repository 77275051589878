import React, { useEffect, useState } from "react";
import "./css/IntroTaskList.scss";
import CommonAPI from "../../../API/common";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link, useHistory } from "react-router-dom";
import LoaderPlaceholder from "../../common/LoaderPlaceholder";
import TaskTable from "../../taskManage/organisms/TaskTable";
import Const from "../../taskManage/const";
import links from "../../../const/links";
import PDFDocument from "../../common/PDFDocument";
import { left } from "core-js/internals/array-reduce";

function IntroTaskList() {
  const defaultStr = "공고 참고";
  const history = useHistory();
  const date = new Date();
  const [filePDF, setFilePDF] = useState(null);
  const [list, setList] = useState([]);
  const [value, setValue] = useState(0);
  const [field, setField] = useState({});
  const [taskList, setTaskList] = useState([]);
  const [PDFList, setPDFList] = useState([]);
  const [taskTotalCount, setTaskTotalCount] = useState(0);
  const [state, setState] = useState(-1);
  const defaultSort = 0;

  useEffect(() => {
    CommonAPI.getTaskField(Const.showType.bizOrganized).then((res) =>
      setField(res.data)
    );
    CommonAPI.getTaskPDFList().then((res) =>
      setPDFList(res.data.items.sort((a, b) => a.order - b.order))
    );
    getList();
  }, []);

  useEffect(() => {
    getTaskList();
  }, [state]);

  useEffect(() => {
    CommonAPI.getTaskTotalCount().then((res) => setTaskTotalCount(res.data));
  }, []);

  const getPDFInfo = (idx) => {
    CommonAPI.getTaskPDFInfo(idx).then((res) => {
      setFilePDF(res.data);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setList([]);

    if (newValue !== 0) {
      getList(newValue - 1);
    } else {
      getList(newValue);
    }
  };

  const getTaskList = () => {
    let payload = {
      count: 26,
      state: state,
      sort: defaultSort,
    };
    CommonAPI.getTaskList(payload).then((res) => {
      setTaskList(res.data.items);
    });
  };
  const getList = (option) => {
    let payload = { count: 26, select_option: option || 0 };
    CommonAPI.getTaskNewHome(payload).then((res) => {
      setList(res.data.items);
    });
  };

  const redirectURL = (keyword, idx) => {
    return history.push(
      // `${links.taskManage}?searchKeyword=${keyword}${idx ? `&idx=${idx}` : ""}`
      `${links.taskManage}?count=100&state=-1&sort=6${idx ? `&idx=${idx}` : ""}`
      //https://www.biznavi.co.kr/api/v1/common/task?count=26&state=-1
      //https://www.biznavi.co.kr/api/v1/common/task?page=1&count=100&show_type=0&state=-1&sort=6
    );
  };

  const convertDate = (ms) => {
    if (ms) {
      return isNaN(ms) ? ms : window.$Global.convertDate(ms);
    } else {
      return defaultStr;
    }
  };

  const convertDay = (day) => {
    let result = Number(day) || "";
    return result > 999 ? "999+" : result;
  };

  const onClickDetail = (idx, title) => {
    redirectURL(title, idx);
  };

  return (
    <div id="IntroTaskList">
      <div className="tabs">
        <button
          className={state === -1 ? "active" : ""}
          onClick={() => setState(-1)}
        >
          전체
          <br className="mo" />
          <span>
            {window.$Global.commaify(taskTotalCount.total_count || 0)}건
          </span>
        </button>
        <button
          className={state === 4 ? "active" : ""}
          onClick={() => setState(4)}
        >
          접수중
          <br className="mo" />
          <span>
            {window.$Global.commaify(taskTotalCount.receiving || 0)}건
          </span>
        </button>
        <button
          className={state === 2 ? "active" : ""}
          onClick={() => setState(2)}
        >
          접수예정
          <br className="mo" />
          <span>
            {window.$Global.commaify(taskTotalCount.scheduled || 0)}건
          </span>
        </button>
      </div>
      <TaskTable
        showType={Const.showType.bizOrganized}
        searchType={{}}
        field={field}
        list={taskList}
        onClickDetail={onClickDetail}
      />

      <Link to={links.taskManage} className="btn_go_findbiz">
        <span>더 많은 공고 보기</span>
        <i className="icon_more_arrow_right_white" />
      </Link>

      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="핵심 통합 공고" data-key={"init"} />
        <Tab label="추천 사업" data-key={"recommended"} />
        <Tab label="마감 임박 사업" data-key={"d_day"} />
        <Tab label="조회수 상위 사업" data-key={"high_hit"} />
      </Tabs>

      <div className="task_box_list scrollbar_hidden">
        {value === 0 ? (
          PDFList.slice(0, 6).map((item, idx) => {
            return (
              // <div key={item.idx} className={`task_item korea_item type_${(idx % 3 + 1)}`} onClick={() => setFilePDF(`https://findbiz.co.kr/${item.file_key}`)}>
              <div
                key={item.idx}
                className={`task_item korea_item type_${(idx % 3) + 1}`}
                onClick={() => getPDFInfo(item.idx)}
              >
                <p>{date.getFullYear()}년</p>
                <h2>{item.title}</h2>
                <button>보러가기</button>
              </div>
            );
          })
        ) : Boolean(list?.length) ? (
          list?.slice(0, 4).map((item, idx) => {
            return (
              <div
                key={idx}
                className="task_item"
                onClick={() => redirectURL(item.title, item.idx)}
              >
                <div className="task_head">
                  <div className="task_state">{"접수중"}</div>
                  <h2 className="task_title">{item.title}</h2>
                </div>
                <p
                  className="task_desc"
                  dangerouslySetInnerHTML={{
                    __html: window.$Global.removeHTML(item.description),
                  }}
                />
                <p className="day pc">D-day {convertDay(item.d_day)}</p>
                <div className="task_info_list">
                  <div className="wrap_date">
                    <div className="task_info_item">
                      <h2>접수일</h2>
                      <p>{convertDate(item.reception_at)}</p>
                    </div>
                    <div className="task_info_item">
                      <h2>마감일</h2>
                      <p>{convertDate(item.deadline_at)}</p>
                    </div>
                  </div>
                  <div className="wrap_date col">
                    <div className="task_info_item">
                      <h2>지역</h2>
                      <p>
                        {window.$Global.matchJsonToStr(
                          field,
                          "region",
                          item.region
                        ) || defaultStr}
                      </p>
                    </div>
                    <div className="task_info_item">
                      <h2>분야</h2>
                      <p>
                        {window.$Global.matchJsonToStr(
                          field,
                          "field",
                          item.field
                        ) || defaultStr}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="task_add_info">
                  <div className="task_amount">
                    <i className="icon_money" />
                    <p>
                      <b>
                        {Boolean(item.amount)
                          ? (isNaN(item.amount)
                              ? item.amount
                              : window.$Global.commaify(item.amount) || 0) +
                            "원"
                          : "공고 확인"}
                      </b>
                    </p>
                  </div>
                  <p className="day mo">D-day {convertDay(item.d_day)}</p>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <LoaderPlaceholder />
            <LoaderPlaceholder />
            <LoaderPlaceholder />
            <LoaderPlaceholder />
          </>
        )}
      </div>
      {value === 0 && (
        <button
          className="gov_nav_btn"
          onClick={() => history.push("/intro/page2")}
        >
          모두 보러가기 <i className="icon_arrow_right_white" />
        </button>
      )}
      {filePDF && <PDFDocument file={filePDF} setFile={setFilePDF} />}

      {/*<PopupTaskDetail*/}
      {/*  show={popShow}*/}
      {/*  item={detailInfo}*/}
      {/*  field={field}*/}
      {/*  onClickInterestAdd={onClickInterestAdd}*/}
      {/*  onClose={() => setPopShow(false)}*/}
      {/*/>*/}
      {/*<PopupMobileTaskDetail*/}
      {/*  show={moPopShow}*/}
      {/*  field={field}*/}
      {/*  item={detailInfo}*/}
      {/*  showMoPopupFile={showMoPopupFile}*/}
      {/*/>*/}
    </div>
  );
}

export default IntroTaskList;
