import React, { useState } from "react";
import "../css/AdminIntegrationTask.scss";
import AdminAPI from "../../../API/admin";
import { useHistory } from "react-router-dom";

function AdminIntegrationTask() {
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [horizontal, setHorizontal] = useState(1);
  const add = () => {
    let formData = new FormData();
    formData.append("title", title);
    formData.append("horizontal", horizontal);
    for (let i = 0; i < file.length; i++) {
      formData.append("file", file[i]);
    }
    AdminAPI.addTaskPDF(formData).then(() => {
      alert("등록되었습니다");
      history.go(0);
    });
  };

  return (
    <div id="AdminIntegrationTask">
      <div className="head">
        <h2 className="title">통합 공고 업로드</h2>
      </div>
      <div className="form">
        <div className="input_area">
          <label htmlFor="title">제목</label>
          <input
            type="text"
            id="title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="input_area">
          <label htmlFor="file">파일</label>
          <input
            type="file"
            id="file"
            onChange={(e) => setFile(e.target.files)}
          />
        </div>
        <div className="input_area">
          <label>PDF 유형</label>
          <label className="horizontal" htmlFor="row">
            가로
          </label>
          <input
            type="radio"
            id="row"
            name="horizontal"
            value={1}
            onClick={(e) => setHorizontal(e.target.value)}
            defaultChecked
          />
          <label className="horizontal" htmlFor="column">
            세로
          </label>
          <input
            type="radio"
            id="column"
            name="horizontal"
            value={0}
            onClick={(e) => setHorizontal(e.target.value)}
          />
        </div>
      </div>

      <button className="btn_upload" onClick={add}>
        업로드하기
      </button>
    </div>
  );
}

export default AdminIntegrationTask;
