import links from "../../../const/links";

export const getKeywordToTaskURL = (keyword) => {
  let result = "";
  if (keyword === "전체 사업") {
    result = `${links.taskManage}?searchKeyword=`;
  } else if (keyword === "추천 사업") {
    result = `${links.taskManage}?category=1&searchKeyword=`;
  } else {
    result = `${links.taskManage}?searchKeyword=${keyword}`;
  }
  return result;
};
