import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import './css/AnnualCountList.scss';
import AnnualPayAPI from "../../API/annualPay";
import AnnualTable from "./AnnualTable";

function AnnualCountList() {
    const no_data = window.$Global.notTd(8, '출원·등록되지 않은 항목입니다.');
    const [type, setType] = useState("patent");
    const isPatent = type == "patent";
    const isTradeMark = type == "tradeMark";
    const [resData, setResData] = useState({});
    const [applyTag, setApplyTag] = useState(no_data);
    const [registerTag, setRegisterTag] = useState(no_data);
    const {year} = useParams();

    let thead = [];

    if (isPatent) {
        thead = <tr>
                    <th>건수</th>
                    <th>관리번호</th>
                    <th>Family</th>
                    <th>국가</th>
                    <th>종류</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th>현재상태</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                    <th>특허사무소</th>
                </tr>;
    } else if (isTradeMark) {
        thead = <tr>
                    <th>건수</th>
                    <th>출원번호</th>
                    <th>출원일</th>
                    <th>현재상태</th>
                    <th>등록번호</th>
                    <th>등록일</th>
                </tr>;
    }

    useEffect(() => {
        AnnualPayAPI.getAnnualCount({year: year}).then(res => setResData(res.data));
    }, []);

    useEffect(() => {
        if (window.$Global.isEmptyObject(resData)) return;
        let res_apply = {};
        let res_register = {};
        let result_apply = no_data;
        let result_register = no_data;

        if (isPatent) {
            res_apply = resData.patent.apply;
            res_register = resData.patent.register;
            if (res_apply.length) {
                result_apply = res_apply.map((item, idx) => {
                    return getPatentTag(idx, item);
                });
            }
            if (res_register.length) {
                result_register = res_register.map((item, idx) => {
                    return getPatentTag(idx, item);
                });
            }
        } else if (isTradeMark) {
            res_apply = resData.trademark.apply;
            res_register = resData.trademark.register;
            if (res_apply.length) {
                result_apply = res_apply.map((item, idx) => {
                    return getTramdeMarkTag(idx, item);
                });
            }
            if (res_register.length) {
                result_register = res_register.map((item, idx) => {
                    return getTramdeMarkTag(idx, item);
                });
            }
        }
        setApplyTag(result_apply);
        setRegisterTag(result_register);
    }, [resData, type]);

    const getPatentTag = (idx, item) => {
        return <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.manage_number}</td>
                    <td>{item.family}</td>
                    <td>{window.$Global.convertNationCodeToStr(item.nation)}</td>
                    <td>{window.$Global.convertTypeToText(item.type)}</td>
                    <td>{item.apply_number}</td>
                    <td>{window.$Global.convertDate(item.apply_at)}</td>
                    <td>{window.$Global.convertStateToText(item.state)}</td>
                    <td>{item.register_number}</td>
                    <td>{window.$Global.convertDate(item.register_at)}</td>
                    <td>{item.agent_company_name}</td>
                </tr>
    };

    const getTramdeMarkTag = (idx, item) => {
        return <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.apply_number}</td>
                    <td>{window.$Global.convertDate(item.apply_at)}</td>
                    <td>{window.$Global.convertTradeMarkStateCodeToStr(item.state)}</td>
                    <td>{item.regist_number}</td>
                    <td>{window.$Global.convertDate(item.regist_at)}</td>
                </tr>
    };

    return (
        <div id="AnnualCountList">
            <ul className="tab">
                <li>
                    <button className="btn" onClick={() => setType("patent")}>특허</button>
                </li>
                <li>
                    <button className="btn" onClick={() => setType("tradeMark")}>상표</button>
                </li>
            </ul>
            <h2 className="title">{year}년 출원·등록 {isPatent ? "특허" : "상표"} 리스트</h2>
            <h2 className="table_title">출원</h2>
            <AnnualTable thead={thead} tbody={applyTag}/>
            <h2 className="table_title" style={{marginTop: 41}}>등록</h2>
            <AnnualTable thead={thead} tbody={registerTag}/>
        </div>
    );
}

export default AnnualCountList;
