import { useEffect, useState } from "react";

const InfoTable = ({ data, isInfo, units, tableWidth }) => {
  const [tblData, setTblData] = useState(data);

  useEffect(() => {
    setTblData(data);
  }, [data]);

  return (
    <div className="InfoTable">
      {tblData?.headers?.length > 0 ? (
        <>
          {units ? <p className="units">단위: {units}</p> : ""}
          <div className="tbl_wrap">
            <table style={{ width: tableWidth ? tableWidth : "100%" }}>
              {tblData?.ipcDesc ? (
                <colgroup>
                  <col />
                  <col />
                  <col width={400} />
                </colgroup>
              ) : (
                ""
              )}
              <thead>
                <tr>
                  {tblData?.headers?.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tblData?.rows?.length > 0 ? (
                  tblData?.ipcDesc ? (
                    tblData?.rows?.map((row, rowIndex) =>
                      Array.isArray(row) ? (
                        <tr key={rowIndex}>
                          {row.map((item, idx) => (
                            <td
                              key={idx}
                              style={{
                                wordBreak: "keep-all",
                                textAlign:
                                  idx === row.length - 1 ? "left" : "center",
                              }}
                            >
                              {idx === 0 ? item : window.$Global.commaify(item)}
                            </td>
                          ))}
                        </tr>
                      ) : (
                        ""
                      )
                    )
                  ) : (
                    tblData?.rows?.map((row, rowIndex) =>
                      Array.isArray(row) ? (
                        <tr key={rowIndex}>
                          {row.map((item, idx) => (
                            <td key={idx} style={{ wordBreak: "keep-all" }}>
                              {idx === 0 ? item : window.$Global.commaify(item)}
                            </td>
                          ))}
                        </tr>
                      ) : (
                        ""
                      )
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={tblData?.headers?.length}>
                      데이터가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {isInfo ? (
            <div className="table_desc">
              <ul>
                {tblData?.description?.length > 0
                  ? tblData?.description.map((item, idx) => (
                      <li key={idx}>
                        <strong>{item.name}:</strong>
                        <span>{item.description}</span>
                      </li>
                    ))
                  : ""}
              </ul>
            </div>
          ) : (
            ""
          )}
        </>
      ) : tblData?.ipcDesc ? (
        ""
      ) : (
        <p className="no_data">데이터가 없습니다.</p>
      )}
    </div>
  );
};

export default InfoTable;
