import React, {useContext} from "react";
import "./css/CyberHeader.scss";
import MemberContext from "../../store/Member";

function CyberHeader() {
    const {auth} = useContext(MemberContext);

    const logout = () => {
        window.$Global.deleteSession();
        window.location.href = "/cyber";
    };

    return (
        <div id="CyberHeader">
            {
                auth
                &&
                <img src={require('../../assets/images/cyber/icon_logout.svg').default} alt="이미지" onClick={logout}/>
            }
        </div>
    )
}

export default CyberHeader;