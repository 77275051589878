import { AXIOS } from "../../util/api";

const CHECKPOINT_API = {
  async getFrequentQuestions() {
    return AXIOS.get("/manager/help/checkpoint", { params: { flag: 2 } });
  },

  async getReport() {
    return AXIOS.get("/manager/help/checkpoint", { params: { flag: 1 } });
  },
};

export default CHECKPOINT_API;
