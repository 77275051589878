import { Fragment } from "react";

const HeatMap = ({
  data,
  xLabels,
  yLabels,
  itemWidth,
  itemHeight,
  clickEvent,
  color,
}) => {
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  const getTextColor = (r, g, b, alpha) => {
    const blendedR = (1 - alpha) * 255 + alpha * r;
    const blendedG = (1 - alpha) * 255 + alpha * g;
    const blendedB = (1 - alpha) * 255 + alpha * b;
    const brightness =
      (blendedR * 299 + blendedG * 587 + blendedB * 114) / 1000;
    return brightness > 125 ? "black" : "white";
  };

  const rgbaToRgb = (rgba) => {
    const match = rgba.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*(\d*\.?\d+)?\)/
    );
    if (match) {
      const r = parseInt(match[1], 10);
      const g = parseInt(match[2], 10);
      const b = parseInt(match[3], 10);
      const alpha = parseFloat(match[4] || 1);
      return [r, g, b, alpha];
    }
    return [0, 0, 0, 1]; // Default fallback color
  };

  const getColor = (value) => {
    const max = 160;
    const min = 10;
    const alpha = (value - min) / (max - min);
    const cappedAlpha = Math.min(Math.max(alpha, 0), 1);
    let r, g, b;

    if (color) {
      [r, g, b] = hexToRgb(color);
    } else {
      [r, g, b] = [44, 89, 133]; // Default color
    }

    return `rgba(${r}, ${g}, ${b}, ${cappedAlpha < 0.1 ? 0.1 : cappedAlpha})`;
  };

  return (
    <>
      {data.length > 0 ? (
        <div
          className="heatmap"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        >
          <div
            style={{
              display: "grid",
              width: "100%",
              gridTemplateColumns:
                data.length > 0
                  ? `repeat(${data[0].length + 1}, ${
                      itemWidth ? `${itemWidth}px` : "auto"
                    })`
                  : "none",
            }}
          >
            <div></div>
            {xLabels.map((label, index) => (
              <div
                key={index}
                style={{
                  padding: "10px 5px 10px",
                  textAlign: "center",
                  fontSize: "12px",
                  color: "#8B95A1",
                  borderBottom: "1px solid #dedede",
                }}
              >
                {label}
              </div>
            ))}
            {data.map((row, rowIndex) => (
              <Fragment key={rowIndex}>
                <div
                  style={{
                    padding: "5px 5px 5px",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    fontSize: "14px",
                    color: "#8B95A1",
                  }}
                >
                  {yLabels[rowIndex]}
                </div>
                {row.map((value, colIndex) => {
                  const bgColor = getColor(value);
                  const [r, g, b, alpha] = rgbaToRgb(bgColor);
                  const textColor = getTextColor(r, g, b, alpha);
                  const handleClick = () => {
                    if (clickEvent) {
                      clickEvent({
                        value: value,
                        x: xLabels[colIndex],
                        y: yLabels[rowIndex],
                      });
                    }
                  };
                  return (
                    <div
                      key={colIndex}
                      style={{
                        width: itemWidth ? `${itemWidth}px` : "auto",
                        height: itemHeight ? `${itemHeight}px` : "30px",
                        backgroundColor: bgColor,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: textColor,
                        fontSize: "12px",
                        border: "1px solid #fff",
                        cursor: clickEvent ? "pointer" : "default",
                      }}
                      onClick={handleClick}
                    >
                      {value ? window.$Global.commaify(value) : ""}
                    </div>
                  );
                })}
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HeatMap;
