import React, { useContext, useEffect, useState } from "react";
import "./css/MarketInfoCategory.scss";
import CommonAPI from "../../API/common";
import _ from "lodash";
import { Link } from "react-router-dom";
import CommonContext from "../../store/Common";
import MarketInfoSideBar from "./MarketInfoSideBar";
import MarketInfoHeader from "./MarketInfoHeader";

function MarketInfoCategory({ history }) {
  const { isMobile } = useContext(CommonContext);
  const [historyCategory, setHistoryCategory] = useState([]);
  const [category, setCategory] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = () => {
    CommonAPI.getMarketCategory().then((res) => {
      let data = res.data.category_list;
      setCategory(data);
      setHistoryCategory(data);
      console.log(res.data);
    });
  };

  const onClickView = (idx1, idx2) => {
    history.push(`list?category_idx1=${idx1}&category_idx2=${idx2}`);
  };

  const onClickSearch = () => {
    let copy = _.cloneDeep(historyCategory);
    let result = copy.filter((item) => {
      let child = item.child.filter((item2) => {
        if (item2.name.indexOf(keyword.replace(/(\s*)/g, "")) > -1) {
          return item2;
        }
      });

      item.child = child;
      if (child.length) return child;
    });
    setCategory(result);
  };

  return (
    <div id="MarketInfoCategory">
      <MarketInfoHeader />
      <div className="marketInfo_wrapper">
        <MarketInfoSideBar category={category} />
        <div>
          <div className="head pc">
            <div className="search_wrapper">
              <h2 className="title">분야 선택하기</h2>
              <div className="search">
                <input
                  type="text"
                  placeholder="찾고싶은 키워드를 입력하세요."
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyUp={(e) => e.key === "Enter" && onClickSearch()}
                />
                {keyword.length > 0 ? (
                  <button
                    className="icon_del_small_with_bg_gray"
                    onClick={() => {
                      setKeyword("");
                      getCategory();
                    }}
                  ></button>
                ) : (
                  <button
                    className="icon_search_gray ir_txt"
                    onClick={onClickSearch}
                  >
                    검색
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="wrap_category">
            {category?.map((item) => {
              return (
                <div className="category">
                  <div className="category_b_title">
                    <i className="icon_m_menu" />
                    <p>{item.name}</p>
                  </div>
                  <ul className="category_s_list">
                    {item.child?.map((child) => {
                      let checkDataLength = !child.data_cnt;
                      return (
                        <li
                          className={checkDataLength ? "not_data" : ""}
                          onClick={() => onClickView(item.idx, child.idx)}
                        >
                          <span>{child.name}</span>
                          {checkDataLength &&
                            (isMobile ? (
                              <p>준비중</p>
                            ) : (
                              <img
                                className="pc"
                                src={
                                  require("../../assets/images/marketInfo/icon_badge_ready.png")
                                    .default
                                }
                                alt="준비중 이미지"
                              />
                            ))}
                          {!checkDataLength && (
                            <i className="icon_next_black" />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketInfoCategory;
