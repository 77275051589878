import React, { useEffect, useLayoutEffect, useState } from "react";
import "./css/ResearchTechPatentList.scss";
import CommonAPI from "../../API/common";
import { Link, useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import convertCategory from "../marketInfo/js/convertCategory";
import iconDownload from "../../assets/images/common/icon/icon-download.svg";

function ResearchTechPatentList() {
  const history = useHistory();
  const location = useLocation();
  const { idx, category_idx1, category_idx2, keyword } = qs.parse(
    location.search
  );
  const [researchInfo, setResearchInfo] = useState({});
  const [category, setCategory] = useState([]);

  useLayoutEffect(() => {
    CommonAPI.getMarketCategory().then((res) => setCategory(res.data));
    if (idx) {
      CommonAPI.getResearchInfo(idx).then((res) => setResearchInfo(res.data));
    }

    if (keyword) {
      CommonAPI.getPatentData({ keyword }).then((res) =>
        setResearchInfo(res.data)
      );
    }
  }, []);

  const getCategory = () => {
    let result = convertCategory(category, category_idx1, category_idx2);
    return result.b_category + ">" + result.s_category;
  };

  const downloadExcel = async () => {
    const fileDown = {
      keywordIdx: researchInfo.keyword_idx,
      method: "POST",
    };
    window.$Global.download("/common/patent/downloadKeywordExcel", fileDown, 1);
  };

  return (
    <div id="ResearchTechPatentList">
      <div className="wrap_category">
        <div className="category">
          <i className="icon_m_menu" />
          <p>{getCategory()}</p>
        </div>
        <div className="button_link">
          <div className="download_button" onClick={() => downloadExcel()}>
            <img src={iconDownload} alt="download_image" />
            <span>Excel 다운받기</span>
          </div>
          <div className="link" onClick={() => history.go(-1)}>
            <span>목록으로 돌아가기</span>
            <i className="icon_more_arrow_right" />
          </div>
        </div>
      </div>
      <div className="wrap_table">
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>국가</th>
              <th>특허명</th>
              <th>출원인</th>
              <th>등급</th>
              <th>출원번호</th>
              <th>출원일</th>
            </tr>
          </thead>
          <tbody>
            {researchInfo.data?.patent_list.items
              ?.filter((item) => item.invention_name)
              .map((item, idx) => {
                console.log(item)
                return (
                  <tr>
                    <td>
                      {researchInfo.data.patent_list.items?.filter(
                        (item) => item.invention_name
                      ).length - idx}
                    </td>
                    <td>{item.nation}</td>
                    <td>{item.invention_name}</td>
                    <td>{item.applicant}</td>
                    <td>{item.sm_grade}</td>
                    <td>{item.apply_number}</td>
                    <td>{`${item.apply_date?.substr(
                      0,
                      4
                    )}-${item.apply_date?.substr(4, 2)}-${item.apply_date?.substr(
                      6,
                      2
                    )}`}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="btns">
        <button className="btn_prev" onClick={() => history.go(-1)}>
          이전
        </button>
        <button
          className="btn_next"
          onClick={() =>
            history.push(
              keyword !== undefined
                ? `edit?keyword=${keyword}`
                : `edit?idx=${idx}`
            )
          }
        >
          선행기술조사 작성하러 가기
        </button>
      </div>
    </div>
  );
}

export default ResearchTechPatentList;
