import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import DesignListView from "../components/design/DesignListView";
import MDesignListView from "../components/design/mobile/MDesignListView";
import DesignDetail from "../components/design/detail/DesignDetail";
import MDesignDetail from "../components/design/mobile/MDesignDetail";
import DesignRelativeFileListView from "../components/design/relativeFile/RelativeFileListView";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import PatentList from "../components/patent/PatentListView";
import MPatentListView from "../components/patent/mobile/MPatentListView";

function DesignRoute({match}) {
	return (
		<CustomSwitch>
			<CacheRoute exact path={`${match.path}/list`}
						render={(props) => window.$Global.renderFunc(props, DesignListView)}
						when="always"
			/>
			<CacheRoute exact path={`${match.path}/mlist`}
						render={(props) => window.$Global.renderFunc(props, MDesignListView)}
						when="always"
			/>

			<UserAuthRoute exact path={`${match.path}/detail/:patent_idx`} requireAuth={true} component={DesignDetail}/>
			<UserAuthRoute exact path={`${match.path}/mobile/:design_idx`} requireAuth={true} component={MDesignDetail}/>
			<UserAuthRoute exact path={`${match.path}/relativeFile/:design_idx`} requireAuth={true} component={DesignRelativeFileListView}/>
		</CustomSwitch>
	)
}

export default DesignRoute;
