import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import PatentApplicationInfoList from "../components/patentApplicationInfo/PatentApplicationInfoList";
import PatentApplicationInfoContent from "../components/patentApplicationInfo/PatentApplicationInfoContent";
import CustomSwitch from "./common/CustomSwitch";

function ApplicationRoute({match}) {
	return (
		<CustomSwitch>
			<UserAuthRoute exact path={`${match.path}/list`} requireAuth={true} component={PatentApplicationInfoList}/>
			<UserAuthRoute exact path={`${match.path}/content/:type`} requireAuth={true} component={PatentApplicationInfoContent}/>
		</CustomSwitch>
	)
}

export default ApplicationRoute;