import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import "./css/EducationMain.scss";
import CommonAPI from "../../API/common";

function EducationMain() {
    const [curPage, setCurPage] = useState(1);
    const [data, setData] = useState({items: []});
    const [hasMore ,setHasMore] = useState(false);
    const [documentTag, setDocumentTag] = useState([]);

    useEffect(() => {
        nextList();
    }, []);

    useEffect(() => {
        let tag_doc =
            data.items.map((item, idx) => {
                return (
                    <Link key={idx} to={`detail/${item.idx}`} className="item">
                        <img src={window.$Global.getCDN(item.thumbnail_key)} className="bg_image" alt="이미지"/>
                        <p>{item.title}</p>
                    </Link>
                );
            });
        setDocumentTag(tag_doc);
    }, [data]);

    const nextList = () => {
        CommonAPI.getEduList({page:curPage, count: 100, type: 0}).then(res => {
            res.data.items = data.items.concat(res.data.items);
            setData(res.data);
            setCurPage(curPage + 1);
            if (res.data.last == res.data.page) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        });
    };

    return (
        <div id="EducationMain">
            <h2 className="page_title">특허 교육자료</h2>

            <div className="wrap">
                <div className="area_ppt">
                    <div className="head">
                        {/*<Link to="list" className="more">*/}
                        {/*    <p>더보기</p>*/}
                        {/*    <i className="icon_more_arrow_right_small_black"/>*/}
                        {/*</Link>*/}
                    </div>
                    <div className="list">
                        {documentTag}
                    </div>
                </div>
            </div>
            {
                hasMore && <button className="btn_more" onClick={nextList}>교육자료 더보기</button>
            }
        </div>
    )
}

export default EducationMain;
