import React, {useContext} from "react";
import "./terms.scss";
import memberContext from "../../store/Member";
import Terms from "../../const/Terms";

function TermsUse() {
    const {auth} = useContext(memberContext);

    const redirect = () => {
        window.$Global.redirectHome(auth);
    };

    return (
        <div id="Terms">
            <h2>
                IPNOW 서비스 이용약관
            </h2>
            <p className="custom_scroll" dangerouslySetInnerHTML={ {__html: Terms.useTxt} }/>
            <button className="btn_confirm" onClick={redirect}>확인</button>
        </div>
    )
}

export default TermsUse;
