import React, {useContext, useEffect, useState} from "react";
import "./css/PopupKeywordCondition.scss";
import CheckBox from "../common/CheckBox";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import CommonAPI from "../../API/common";
import _ from "lodash";
import memberContext from "../../store/Member";

function PopupKeywordCondition({show, onClose}) {
	const isLogin = Boolean(useContext(memberContext).idx);
	const keyword_list = {
		"주요기술": ["인공지능(AI)", "빅데이터", "클라우드", "컴퓨팅", "차세대통신", "사물인터넷", "로봇", "자율 주행차", "드론", "가상증강현실", "스마트시티", "헬스케어", "신약", "반도체", "첨단소재", "에너지",
			"3D프린팅", "블록체인", "DNA", "선박", "항공기", "우주산업", "컴퓨터", "금융", "농업", "관광", "미디어콘텐츠", "CCTV", "고령화", "기후", "환경", "정보통신", "스마트팩토리",
			"열화상카메라", "초음파", "스마트하우스", "스마트팜", "의료기기", "건강식품", "휴대폰", "에어컨", "진공청소기", "텔레비전", "카메라", "안마의자", "나노기술", "철도", "폐수",
			"하수", "상수", "태양열", "수력", "풍력", "홀로그램", "2차전지", "광고"],
		"음향기": ["라디오", "오디오", "홈시어터", "마이크", "이어폰", "전자악기"],
		"의복생활용": ["재봉틀", "다리미", "세탁기", "건조기"],
		"이·미용용": ["면도기", "이발기", "드라이어", "헤어아이론"],
		"욕실용": ["비데", "칫솔", "구강세정기", "조리용", "가스레인지", "밥솥", "전기오븐", "토스터", "전기주전자", "전기그릴", "전자레인지", "에어프라이어", "커피머신", "빙수기", "생수기"],
		"원료": ["희토류", "화약", "핵산", "할로겐", "하프늄", "팔라듐", "티탄", "토륨", "텅스텐", "탄탈", "탄소", "크롬", "퀴논", "콘크리트", "코발트", "케톤", "케텐", "칼슘", "칼륨", "카르복시간", "카드뮴",
			"철", "질소", "지르코늄", "주석", "인산", "이리듐", "은", "유리", "우라늄", "오스뮴", "암모니아", "안티몬", "아지드", "아연", "수은", "석영", "비스무트", "비소", "비료", "붕소",
			"베릴륨", "백금", "바나듐", "몰리브덴", "모르타르", "망간", "마그네슘", "리튬", "루테늄", "루비듐", "로듐", "레늄", "랑데히드", "라듐", "니켈", "니오브", "납", "나트륨", "금",
			"규소(Silicon)", "규리", "고무", "게르마늄", "갈륨"]
	};

	const [keyword, setKeyword] = useState("");
	const [selectList, setSelectList] = useState([]); // { isAuto: ture, // 자동, 수동 여부 keyword: "" }
	const [showIndex, setShowIndex] = useState(0);

	const keywordList = selectList.filter(item => !item.isAuto).map(item => item.keyword);

	WithPreventScroll(<></>, show);

	useEffect(() => {
		setSelectList([]);
	}, [show]);

	const setting = async () => {
		if (selectList.length < 6) {
			alert("키워드 6개를 선택해주세요");
			return;
		}

		let keyword_for_type = selectList.map(item => item.keyword);

		keyword_for_type = keyword_for_type.map(item => {
			if (item == "인공지능(AI)") {
				return "인공지능";
			}
			return item;
		})

		let params = {
			day_for_type: 30,
			keyword_for_type: keyword_for_type.join(",")
		};

		if (isLogin) {
			let payload = {
				type: 2,
				option: params
			};
			await CommonAPI.addOption(payload);
		} else {
			localStorage.setItem("keyword_for_type", JSON.stringify(params));
		}
		window.location.reload();
	};

	const addKeyword = () => {
		if (!keyword.length) {
			alert("키워드를 입력해주세요");
			return;
		}

		if (selectList.length > 5) {
			alert("최대 6개를 초과할 수 없습니다");
			return;
		}
		let addKeyword = {
			isAuto: false,
			keyword: keyword
		};

		let concat = selectList.concat(addKeyword);

		setSelectList(concat);
		setKeyword("");
	}

	const deleteKeyword = (keyword) => {
		let copy = _.cloneDeep(selectList);
		let idx = copy.findIndex(item => !item.isAuto && item.keyword == keyword);
		if (idx > -1) {
			copy.splice(idx, 1);
			setSelectList(copy);
		}
	}

	const onClickCheck = (e, name) => {
		if (e.target.checked) {
			if (selectList.length > 5) {
				alert("최대 6개를 초과할 수 없습니다");
				e.target.checked = false;
				return;
			}
		}

		let copy = _.cloneDeep(selectList);
		let idx = selectList.filter(item => item.isAuto).findIndex(item => item.keyword == name);

		let addKeyword = {
			isAuto: true,
			keyword: name
		};
		if (idx > -1) {
			copy.splice(idx, 1);
		} else {
			copy = copy.concat(addKeyword);
		}
		setSelectList(copy);
	}

	if (!show) return null;

	return (
		<div id="PopupKeywordCondition">
			<div className="header">
				<h2>조건 키워드 설정하기</h2>
				<div className="btns">
					<button className="btn_setting" onClick={setting}>설정</button>
					<button className="icon_exit ir_txt" onClick={onClose}>닫기</button>
				</div>
			</div>
			<div className="desc">
				<i className="icon_alert_circle"/>
				<p>최근 한달 주요 사업별 조건을 설정 해 주세요. (최대 6항목 선택 가능)</p>
			</div>
			<div className="input">
				<h2>수동 등록하기</h2>
				<input type="text" value={keyword} onChange={e => setKeyword(e.target.value)} placeholder="카워드를 입력해주세요"
					   onKeyUp={e => e.key === 'Enter' && addKeyword()}/>
				<button className="icon_orange_add ir_txt" onClick={addKeyword}>추가</button>
			</div>
			<ul className="add_keyword">
				{
					keywordList.map((item, idx) => {
						return (
							<li key={idx}>
								<div>{item}</div>
								<button className="icon_badge_del ir_txt" onClick={(e) => deleteKeyword(item)}>삭제</button>
							</li>
						)
					})
				}
			</ul>

			<ul className="category_list custom_scroll">
				{
					Object.keys(keyword_list).map((keyword, idx1) => {
						let active = showIndex == idx1;
						return (
							<li>
								<div className={`title ${active ? 'active' : ''}`}>
									<p>
										<span>{idx1 + 1}</span>
										{keyword}
									</p>
									<button className={`${active ? 'icon_more_arrow_up' : 'icon_more_arrow_down'} ir_txt`} onClick={() => setShowIndex(active ? -1 : idx1)}>목록 보기/닫기</button>
								</div>
								<div className="check_list" style={{display: active ? "flex" : "none"}}>
									{
										keyword_list[keyword].map((item, idx2) => <CheckBox key={idx2} text={item} onChangeChecked={(e) => onClickCheck(e, item)}/>)
									}
								</div>
							</li>
						)
					})
				}
			</ul>
		</div>
	)
}

export default PopupKeywordCondition;