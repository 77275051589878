import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        width: '80%'
    },
    margin: {
        height: theme.spacing(3),
    },
}));

const AirbnbSlider = withStyles({
    root: {
        color: '#ffa600',
        height: 3,
        // margin: '0 0 3px 0',
        margin: '0',
        padding: '13px 0',
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#aaaaaa',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 2,
            backgroundColor: '#ffffff',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 3,
    },

    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
})(Slider);

function AirbnbThumbComponent(props) {
    return (
        <span {...props}>
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </span>
    );
}

export default function CustomizedSlider({value, handleChange, step, min, max}) {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <AirbnbSlider
                step={step}
                min={min}
                max={max}
                value={value}
                onChange={handleChange}
                ThumbComponent={AirbnbThumbComponent}
                getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                defaultValue={[20, 40]}
            />
        </div>
    );
}
