import React from "react";

function RelativeFileList({title, manage_number, nation, tag}) {

    return (
        <div id="RelativeFileListView">
            <div className="header">
                <h2 className="title">관련 파일 페이지</h2>
                <div className="patent_info">
                    {title} {manage_number != "null" ? manage_number : 'N/A'}
                    <i className={`icon_flag_${nation} flag`}/>
                </div>
            </div>
            {tag}
        </div>
    );
}

export default React.memo(RelativeFileList);
