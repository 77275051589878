import React, {useEffect, useRef, useState} from "react";
import "../css/ResearchTechEdit.scss";
import { Bar, Pie, Line } from 'react-chartjs-2';
import CommonAPI from "../../../API/common";
import {useHistory, useLocation} from "react-router-dom";
import qs from "query-string";
import _ from "lodash";
import message from "../../../const/message";

function ResearchTechEdit() {
	const history = useHistory();
	const location = useLocation();
	const { idx, doc_idx, keyword, address, year } = qs.parse(location.search);
	const [researchInfo, setResearchInfo] = useState({});
	const copyResearchInfo = _.cloneDeep(researchInfo);
	const [applicantRef, numberYearRef, IPCRef, lineRef, patentListRef] = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

	useEffect(() => {
		if (idx) {
			CommonAPI.getResearchInfo(idx).then(res => setResearchInfo(res.data))
		}

		if (doc_idx) {
			CommonAPI.getResearchDocument(doc_idx).then(res => setResearchInfo(res.data))
		}

		if (keyword) {
			CommonAPI.getPatentData({keyword}).then(res => setResearchInfo(res.data))
		}

		if(address){
			if(year === undefined){
				alert('year를 입력해주세요.');
				return;
			}

			CommonAPI.getPatentDataAddress({address, year}).then(res => setResearchInfo(res.data))
		}

	}, []);
	
	if (window.$Global.isEmptyObject(researchInfo)) return null;

	copyResearchInfo.data.patent_grade_info?.items.reverse();

	const vertical_patent_list_data = {
		labels: copyResearchInfo.data.patent_grade_info?.items?.map(item => item.sm_grade),
		datasets: [
			{
				label: '등록건수',
				data: copyResearchInfo.data.patent_grade_info?.items?.map(item => item.cnt),
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		],
	};

	const applicant_count = copyResearchInfo.data.applicant_count.items.filter(item => (item.apply_cnt + item.register_cnt) > 0).splice(0, 6);
	const vertical_applicant_labels = applicant_count.map(item => item.name);

	const vertical_applicant_data = {
		labels: vertical_applicant_labels,
		datasets: [
			{
				label: '출원건수',
				data: applicant_count.map(item => item.apply_cnt),
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: '등록건수',
				data: applicant_count.map(item => item.register_cnt),
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		],
	};

	const number_of_year_count = copyResearchInfo.data.number_of_year.items.splice(copyResearchInfo.data.number_of_year.items.length - 6);
	const vertical_number_of_year_labels = number_of_year_count.map(item => item.year);

	const vertical_number_of_year_data = {
		labels: vertical_number_of_year_labels,
		datasets: [
			{
				label: '출원건수',
				data: number_of_year_count.map(item => item.apply_count + item.f_apply_count),
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: '등록건수',
				data: number_of_year_count.map(item => item.register_count + item.f_register_count),
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		],
	};

	const vertical_options = {
		responsive: true,
		legend: {
			position: 'bottom',
		},
		plugins: {
			datalabels: {
				display: false,
			}
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					precision: 0,
					callback: (value) => window.$Global.commaify(value)
				},
			}]
		},
	};

	const ipc_number = copyResearchInfo.data.ipc_number.items.sort((a, b) => a.cnt - b.cnt).splice(copyResearchInfo.data.ipc_number.items.length - 6);
	const vertical_ipc_number_labels = ipc_number.map(item => item.ipc_code);

	const pie_data = {
		labels: vertical_ipc_number_labels,
		datasets: [
			{
				data: ipc_number.map(item => item.cnt),
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	const pie_options = {
		responsive: true,
		legend: {
			position: 'bottom',
		},
		plugins: {
			datalabels: {
				display: false,
			}
		},
	}

	const line_data = {
		labels: vertical_number_of_year_labels,
		datasets: [
			{
				label: '국내',
				data: number_of_year_count.map(item => item.apply_count + item.register_count),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
				lineTension: 0,
				fill: false,
			},
			{
				label: '해외',
				data: number_of_year_count.map(item => item.f_apply_count + item.f_register_count),
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				lineTension: 0,
				fill: false,
			},
		],
	};

	const line_options = {
		responsive: true,
		legend: {
			position: 'bottom',
		},
		plugins: {
			datalabels: {
				display: false,
			}
		},
	};


	const onClickSaveAndDownload = () => {
		const dataURI1 = applicantRef.current.chartInstance.canvas.toDataURL('image/png');
		const blob1 = window.$Global.dataURItoBlob(dataURI1);
		const dataURI2 = numberYearRef.current.chartInstance.canvas.toDataURL('image/png');
		const blob2 = window.$Global.dataURItoBlob(dataURI2);
		const dataURI3 = IPCRef.current.chartInstance.canvas.toDataURL('image/png');
		const blob3 = window.$Global.dataURItoBlob(dataURI3);
		const dataURI4 = lineRef.current.chartInstance.canvas.toDataURL('image/png');
		const blob4 = window.$Global.dataURItoBlob(dataURI4);
		const dataURI5 = patentListRef.current.chartInstance.canvas.toDataURL('image/png');
		const blob5 = window.$Global.dataURItoBlob(dataURI5);

		let formData = new FormData();

		formData.append("file", new File([blob1], `image1.${blob1.type}`));
		formData.append("file", new File([blob2], `image2.${blob2.type}`));
		formData.append("file", new File([blob3], `image3.${blob3.type}`));
		formData.append("file", new File([blob4], `image4.${blob4.type}`));
		formData.append("file", new File([blob5], `image5.${blob5.type}`));

		formData.append("title", researchInfo.title);
		formData.append("category1_idx", researchInfo.category1_idx);
		formData.append("category2_idx", researchInfo.category2_idx);
		formData.append("data", JSON.stringify(researchInfo.data));
		formData.append("research_idx", idx);

		if(address !== undefined){
			formData.append('keyword_idx', 'ZBLIE6ZABLF95E1L');
		}else{
			formData.append("keyword_idx", researchInfo?.keyword_idx);
		}

		let payload = {
			title : researchInfo.title,
			category1_idx: researchInfo.category1_idx,
			category2_idx: researchInfo.category2_idx,
			data: researchInfo.data,
			research_idx: idx
		};

		if (idx) {
			CommonAPI.addResearchDoc(formData).then(() => {
				alert(message.successSave);
				history.push(`/researchTech/my`);
			});
		}

		if (doc_idx) {
			CommonAPI.updateResearchDoc(doc_idx, payload).then(() => {
				alert(message.successSave);
				history.push(`/researchTech/my`);
			});

			if (!researchInfo.grade_cnt_key) {
				CommonAPI.uploadResearchImage(doc_idx, formData);
			}
		}

		if (keyword || address) {
			CommonAPI.addResearchDoc(formData).then(() => {
				alert(message.successSave);
				history.push(`/researchTech/my`);
			});
		}
	}

	const onChangeTextEdit = (e) => {
		let { id, value} = e.target;
		let copy = _.cloneDeep(researchInfo);
		if (value.length > 1000) {
			alert(message.lengthOver);
		} else {
			copy.data.text_obj[id] = value;
		}
		setResearchInfo(copy);
	}

	return (
		<div id="ResearchTechEdit">
			<div className="header">
				<h2>선행기술 조사 작성하기</h2>
				{
					researchInfo?.category1_name  ?
					<ul className="category">
						<li>
							<h2>대분류</h2>
							<p>{researchInfo.category1_name}</p>
						</li>
						<li>
							<h2>중분류</h2>
							<p>{researchInfo.category2_name}</p>
						</li>
					</ul> : ''
				}
			</div>

			<div className="step">
				<h2>Step.1 제목 작성하기</h2>
			</div>

			<div className="step1">
				<h2>제목 작성</h2>
				<input type="text"
					   placeholder="제목을 입력해주세요"
					   data-name="제목"
					   value={researchInfo.title}
					   onChange={e => setResearchInfo({...researchInfo, title: e.target.value})}/>
			</div>

			<div className="step">
				<h2>Step.2 내용 작성하기</h2>
			</div>
			<p className="semi_title">Type.주요 선행기술
				{
					researchInfo.data?.total_count ? ` (총 ${researchInfo.data.total_count}개의 특허중 중 5개)` : ''
				}
			</p>

			<table>
				<colgroup>
					<col width="5%"/>
					<col width="5%"/>
					<col width="50%"/>
					<col width="10%"/>
					<col width="20%"/>
					<col width="10%"/>
				</colgroup>
				<thead>
					<tr>
						<th>번호</th>
						<th>국가</th>
						<th>발명의 명칭</th>
						<th>등급</th>
						<th>출원인</th>
						<th>출원일</th>
					</tr>
				</thead>
				<tbody>
				{
					copyResearchInfo.data.patent_list.items.filter(item => item.invention_name).slice(0, 5).map((item, idx) => {
						return (
							<tr key={item.idx}>
								<td>{idx + 1}</td>
								<td>{item.nation}</td>
								<td>{item.invention_name}</td>
								<td>{item.sm_grade}</td>
								<td>{item.applicant}</td>
								<td>{`${item.apply_date?.substr(0, 4)}-${item.apply_date?.substr(4, 2)}-${item.apply_date?.substr(6, 2)}`}</td>
							</tr>
						)
					})
				}
				</tbody>
			</table>

			<div className="graph_item">
				<p className="semi_title">Type.특허권자별 특허 건수</p>
				<div className="description_area">
					<h2 className="description_title">설명란</h2>
					<div className="cur_letter_count">
						<h2>현재 글자 수</h2>
						<p><span>{researchInfo.data?.text_obj?.applicant_count_text?.length || 0}</span>/1000자</p>
					</div>
				</div>
				<div className="content_area">
					<div className="graph">
						<h2>특허권자별 특허 건수</h2>
						<Bar
							ref={applicantRef}
							height={250}
							data={vertical_applicant_data}
							options={vertical_options}
						/>
					</div>
					<textarea placeholder="내용을 입력해주세요"
							  spellcheck = "false"
							  id="applicant_count_text"
							  value={researchInfo.data?.text_obj?.applicant_count_text}
							  onChange={e => onChangeTextEdit(e)}/>
				</div>
			</div>

			<div className="graph_item">
				<p className="semi_title">Type.연도별 특허 건 수</p>
				<div className="description_area">
					<h2 className="description_title">설명란</h2>
					<div className="cur_letter_count">
						<h2>현재 글자 수</h2>
						<p><span>{researchInfo.data?.text_obj?.count_of_year_text?.length || 0}</span>/1000자</p>
					</div>
				</div>
				<div className="content_area">
					<div className="graph">
						<h2>연도별 특허 건 수</h2>
						<Bar
							ref={numberYearRef}
							height={250}
							data={vertical_number_of_year_data}
							options={vertical_options}
						/>
					</div>
					<textarea placeholder="내용을 입력해주세요"
							  spellCheck="false"
							  id="count_of_year_text"
							  value={researchInfo.data?.text_obj?.count_of_year_text}
							  onChange={e => onChangeTextEdit(e)}/>
				</div>
			</div>

			<div className="graph_item">
				<p className="semi_title">Type.IPC 분포 현황</p>
				<div className="description_area">
					<h2 className="description_title">설명란</h2>
					<div className="cur_letter_count">
						<h2>현재 글자 수</h2>
						<p><span>{researchInfo.data?.text_obj?.ipc_text?.length || 0}</span>/1000자</p>
					</div>
				</div>
				<div className="content_area">
					<div className="graph">
						<h2>IPC 분포 현황</h2>
						<Pie
							ref={IPCRef}
							height={250}
							data={pie_data}
							options={pie_options}
						/>
					</div>
					<textarea placeholder="내용을 입력해주세요"
							  spellCheck="false"
							  id="ipc_text"
							  value={researchInfo.data?.text_obj?.ipc_text}
							  onChange={e => onChangeTextEdit(e)}/>
				</div>
			</div>

			<div className="graph_item">
				<p className="semi_title">Type.연도별 국내&해외기업 특허 건수</p>
				<div className="description_area">
					<h2 className="description_title">설명란</h2>
					<div className="cur_letter_count">
						<h2>현재 글자 수</h2>
						<p><span>{researchInfo.data?.text_obj?.applicant_year_count_text?.length || 0}</span>/1000자</p>
					</div>
				</div>
				<div className="content_area">
					<div className="graph">
						<h2>연도별 국내&해외기업 특허 건수</h2>
						<Line
							ref={lineRef}
							height={250}
							data={line_data}
							options={line_options}
						/>
					</div>
					<textarea placeholder="내용을 입력해주세요"
							  spellCheck="false"
							  id="applicant_year_count_text"
							  value={researchInfo.data?.text_obj?.applicant_year_count_text}
							  onChange={e => onChangeTextEdit(e)}/>
				</div>
			</div>

			<div className="graph_item">
				<p className="semi_title">Type.등록건에 대한 특허 등급 정보</p>
				<div className="description_area">
					<h2 className="description_title">설명란</h2>
					<div className="cur_letter_count">
						<h2>현재 글자 수</h2>
						<p><span>{researchInfo.data?.text_obj?.patent_grade_text?.length || 0}</span>/1000자</p>
					</div>
				</div>
				<div className="content_area">
					<div className="graph">
						<h2>등록건에 대한 특허 등급 정보</h2>
						<Bar
							ref={patentListRef}
							height={250}
							data={vertical_patent_list_data}
							options={vertical_options}
						/>
					</div>
					<textarea placeholder="내용을 입력해주세요"
							  spellCheck="false"
							  id="patent_grade_text"
							  value={researchInfo.data?.text_obj?.patent_grade_text}
							  onChange={e => onChangeTextEdit(e)}/>
				</div>
			</div>
			<button className="btn_save" onClick={onClickSaveAndDownload}>저장</button>
		</div>
	)
}

export default ResearchTechEdit;