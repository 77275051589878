import React from "react";

function InputId({ able, check, signInfo, setSignInfo, onClick }) {
  return (
    <div className={`field ${able ? "" : "action_no"}`}>
      <div className="f_head">
        <i className="icon_red_dot" />
        아이디
      </div>
      <div className="f_content">
        <input
          type="text"
          placeholder="아이디를 입력해주세요"
          style={{ width: 170 }}
          value={signInfo.id}
          onChange={(e) => setSignInfo({ ...signInfo, id: e.target.value })}
        />
        <button
          className={`btn_check ${check ? "btn_no" : ""}`}
          onClick={onClick}
        >
          중복확인
        </button>
      </div>
    </div>
  );
}

export default InputId;
