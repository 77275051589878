/** @jsxImportSource @emotion/react */

import React, {useEffect, useState} from "react";
import * as styles from "./trademark.style";
import companyApi from "../../../API/infoCompany/index";
import testTrademarkApi from "../../../API/test/company/trademark";

function TrademarkSearch() {
    const [companyName, setCompanyName] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [trademarkList, setTrademarkList] = useState([]);
    const [firstTrademarkList, setFirstTrademarkList] = useState([]);
    const [companyCode, setCompanyCode] = useState(undefined);

    const selectCompany = async () => {
        if(companyName === ''){
            alert('회사명을 입력해주세요');
            return;
        }
        const response = (await companyApi.getInfoCompany(companyName)).data;

        setCompanyList(response);
    }

    const selectTrademarkList = async () => {
        if(companyCode === undefined) return;

        const response = (await testTrademarkApi.getTrademarkAPI({companyCode}));

        if(response.data.length !== 0) {
            setTrademarkList(response.data);
            setFirstTrademarkList([response.data[0]])
        }else{
            setTrademarkList([]);
            setFirstTrademarkList([])
        }
    }

    useEffect(() => {
        selectTrademarkList();
    }, [ companyCode ]);

    return (
        <div css={styles.container}>
            <div>
                회사명
                <input
                    type={"text"}
                    onChange={(e) => setCompanyName(e.target.value)}
                    onKeyPress={(e) => {
                        console.log(e.key)
                        if(e.code === 'Enter') selectCompany()
                    }}
                />
                <button
                    onClick={selectCompany}
                >
                    검색
                </button>
            </div>

            <div>
                <div>
                    회사목록
                </div>
                <div css={styles.companyListWrap}>
                    {
                        companyList.map(v => {
                            return <div
                                css={styles.companyListItem}
                                key={v.unq_key}
                                onClick={() => setCompanyCode(v.company_code)}
                            >
                                <div>
                                    {v.name}
                                </div>
                                <div>
                                    대표자: {v.representative || '정보없음'}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>

            <div>
                <div>
                    첫 상표
                </div>
                <div>
                    {
                        firstTrademarkList.length === 0 ? <div
                                css={styles.trademarkListItem}
                            >상표 없음</div> :
                        firstTrademarkList.map(v => {
                            const applyAt = v.apply_at ? new Date(v.apply_at*1000).toISOString() : '';
                            const registerAt = v.register_at ? new Date(v.register_at*1000).toISOString() : '';
                            const classList = v.class.split('|');
                            const productList = v.product.split('|');
                            const classObj = {};

                            for(let i=0 ; i<classList.length ; i++){
                                if(classObj[classList[i]] === undefined){
                                    classObj[classList[i]] = [productList[i]];
                                }else{
                                    classObj[classList[i]].push(productList[i])
                                }
                            }

                            return <div
                                css={styles.trademarkListItem}
                                key={v.idx}
                            >
                                <div>
                                    {
                                        v.thumbnail_key ?
                                            <img src={window.$Global.getCDN(v.thumbnail_key)} alt="이미지"
                                                 width={40}
                                                 height={40}
                                            />
                                            :
                                            <div>이미지 없음</div>
                                    }
                                </div>
                                <div>
                                    <div>상표명: {v.title}</div>
                                    <div>상태: {v.state}</div>
                                    <div>국가: {v.nation}</div>
                                </div>
                                <div>
                                    <div>출원일: {applyAt}</div>
                                    <div>출원번호: {v.apply_number}</div>
                                </div>
                                <div>
                                    <div>등록일: {registerAt}</div>
                                    <div>등록번호: {v.register_number}</div>
                                </div>
                                <div css={styles.classWrap}>
                                    {
                                        Object.keys(classObj).map((k) => {
                                            return classObj[k].map((v, i) => {
                                                return <div css={styles.classItemWrap}>
                                                    <div>
                                                        {
                                                            i === 0 ?
                                                                `제${k}류`
                                                                : ''
                                                        }
                                                    </div>
                                                    <div>
                                                        {productList[i]}
                                                    </div>
                                                </div>
                                            })
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>

            <div>
                <div>
                    상표목록
                </div>
                <div css={styles.trademarkListWrap}>
                    {
                        trademarkList.length === 0 ? <div
                                css={styles.trademarkListItem}
                            >상표 없음</div> :
                        trademarkList.map(v => {
                            const applyAt = v.apply_at ? new Date(v.apply_at*1000).toISOString() : '';
                            const registerAt = v.register_at ? new Date(v.register_at*1000).toISOString() : '';
                            const classList = v?.class ? v.class.split('|') : [];
                            const productList = v?.product ? v.product.split('|') : [];
                            const classObj = {};

                            for(let i=0 ; i<classList.length ; i++){
                                if(classObj[classList[i]] === undefined){
                                    classObj[classList[i]] = [productList[i]];
                                }else{
                                    classObj[classList[i]].push(productList[i])
                                }
                            }

                            return <div
                                css={styles.trademarkListItem}
                                key={v.idx}
                            >
                                <div>
                                    {
                                        v.thumbnail_key ?
                                            <img src={window.$Global.getCDN(v.thumbnail_key)} alt="이미지"
                                                 width={40}
                                                 height={40}
                                            />
                                            :
                                            <div>이미지 없음</div>
                                    }
                                </div>
                                <div>
                                    <div>상표명: {v.title}</div>
                                    <div>상태: {v.state}</div>
                                    <div>국가: {v.nation}</div>
                                </div>
                                <div>
                                    <div>출원일: {applyAt}</div>
                                    <div>출원번호: {v.apply_number}</div>
                                </div>
                                <div>
                                    <div>등록일: {registerAt}</div>
                                    <div>등록번호: {v.register_number}</div>
                                </div>
                                <div css={styles.classWrap}>
                                    {
                                        Object.keys(classObj).map((k) => {
                                            return classObj[k].map((v, i) => {
                                                return <div css={styles.classItemWrap}>
                                                    <div>
                                                        {
                                                            i === 0 ?
                                                                `제${k}류`
                                                            : ''
                                                        }
                                                    </div>
                                                    <div>
                                                        {productList[i]}
                                                    </div>
                                                </div>
                                            })
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default TrademarkSearch;
