import React, { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import "./css/ResponseStatus.scss";

function ResponseStatus({
  setResponseStatusPop,
  company_idx,
  patent_idx,
  inventorList,
}) {
  const currentYear = new Date().getFullYear();
  const [mailList, setMailList] = useState([]);
  const [year, setYear] = useState(currentYear);

  useEffect(() => {
    getMailList();
  }, [year]);

  const getMailList = async () => {
    const result = await AdminAPI.sendMailList({
      patent_idx: patent_idx,
      company_idx: company_idx,
      year,
    });

    let data = inventorList.map((item) => {
      let checkData = result.data.items.filter(
        (el) => el.inventor_name === item.inventor_name
      )[0];
      if (checkData?.payment_yn === 3) {
        checkData.showPaymentEtc = false;
      } else if (checkData?.giveup_yn === 3) {
        checkData.showGiveUpEtc = false;
      }
      return { ...item, ...checkData };
    });

    setMailList(data);
  };

  const onClickShowEtc = (item) => {
    if (item.hasOwnProperty("showPaymentEtc")) {
      item.showPaymentEtc = !item.showPaymentEtc;
      setMailList([...mailList]);
    } else if (item.hasOwnProperty("showGiveUpEtc")) {
      item.showGiveUpEtc = !item.showGiveUpEtc;
      setMailList([...mailList]);
    }
  };

  return (
    <div id="ResponseStatus">
      <div className="title">
        <strong>발명자 회신 현황</strong>
      </div>
      <div className="select_box">
        <i
          className="icon_more_arrow_left_black"
          onClick={() => {
            if (currentYear - 10 < year) {
              setYear((prev) => prev - 1);
            }
          }}
        />
        <select onChange={(e) => setYear(e.target.value)} value={year}>
          {Array.from({ length: 26 }, (_, i) => currentYear - 10 + i)
            .reverse()
            .map((y) => (
              <option key={y} value={y}>
                {y}년
              </option>
            ))}
        </select>
        <i
          className="icon_more_arrow_right_black"
          onClick={() => {
            if (currentYear + 15 > year) {
              setYear((prev) => prev + 1);
            }
          }}
        />
      </div>
      <div className="cont">
        <table>
          <tr>
            <th></th>
            <th>메일 송부 여부</th>
            <th>납부 여부</th>
            <th>수용 여부</th>
          </tr>
          {mailList.length > 0 ? (
            mailList.map((mail, index) => (
              <tr>
                <th>{mail.inventor_name}</th>
                <td>
                  <span className={mail.mailing_idx ? "yes" : "no"}>
                    {mail.mailing_idx ? "YES" : "NO"}
                  </span>
                </td>
                <td
                  onClick={() => {
                    onClickShowEtc(mail);
                  }}
                >
                  <span
                    className={
                      mail.mailing_idx
                        ? mail.payment_yn === 1
                          ? "yes"
                          : mail.payment_yn === 2
                          ? "no"
                          : mail.payment_yn === 0
                          ? "no_res"
                          : "etc"
                        : "no_res"
                    }
                  >
                    {mail.mailing_idx
                      ? mail.payment_yn === 1
                        ? "납부희망"
                        : mail.payment_yn === 2
                        ? "미납희망"
                        : mail.payment_yn === 0
                        ? "미회신"
                        : "기타"
                      : "미송부"}
                  </span>
                  {mail.showPaymentEtc && (
                    <div className="show_comment custom_scroll">
                      <i
                        className="icon_exit_gray"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickShowEtc(mail);
                        }}
                      />
                      <p>{mail.comment}</p>
                    </div>
                  )}
                </td>
                <td
                  onClick={() => {
                    onClickShowEtc(mail);
                  }}
                >
                  <span
                    className={
                      mail.mailing_idx
                        ? mail.giveup_yn === 1
                          ? "yes"
                          : mail.giveup_yn === 2
                          ? "no"
                          : mail.giveup_yn === 0
                          ? "no_res"
                          : "etc"
                        : "no_res"
                    }
                  >
                    {mail.mailing_idx
                      ? mail.giveup_yn === 1
                        ? "양수희망"
                        : mail.giveup_yn === 2
                        ? "양수포기"
                        : mail.giveup_yn === 0
                        ? "미회신"
                        : "기타"
                      : "미송부"}
                  </span>
                  {mail.showGiveUpEtc && (
                    <div className="show_comment custom_scroll">
                      <i
                        className="icon_exit_gray"
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickShowEtc(mail);
                        }}
                      />
                      <p>{mail.comment}</p>
                    </div>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>데이터가 없습니다.</td>
            </tr>
          )}
        </table>
        <div className="btns">
          <button className="bg" onClick={() => setResponseStatusPop(false)}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResponseStatus;
