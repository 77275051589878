import React, {useState} from "react";
import * as xlsx from "xlsx";
import "./TaskDownload.scss";
import dataVoucherAPI from "../../API/dataVoucher/dataVoucher";

let failCount = 0;
let completeCount = 0;

function TaskDownload () {
    const [files, setFiles] = useState(null);
    const [excelDownloadStart, setExcelDownloadStart] = useState(false);
    const [docxDownloadStart, setDocxDownloadStart] = useState(false);

    const [completeCnt, setCompleteCnt] = useState(0);
    const [failCnt, setFailCnt] = useState(0);
    const [totalCnt, setTotalCnt] = useState(0);
    const [resultText, setResultText] = useState('');

    let data = null;

    async function dataSetting() {
        const file = files[0];

        const reader = new FileReader();

        const buffer = await new Promise(async (resolve) => {
            reader.onload = (e) => {
                resolve(e.target.result);
            };

            reader.readAsArrayBuffer(file);
        });

        const workbook = xlsx.read(buffer);
        const sheet = workbook.SheetNames;

        const rowList = xlsx.utils.sheet_to_json(workbook.Sheets[sheet[0]]);
        const dataList = [];

        for (const row of rowList) {
            const company = row["수요기업명"];
            const keywordList = Object.keys(row).reduce((arr, k) => {

                if (k.indexOf('키워드') !== -1) {
                    arr = [...arr, ...row[k].replace(/\(/g, '/').replace(/\)/g, '').split('/')]
                }

                return arr;
            }, []);

            dataList.push({
                company,
                keywordList,
            });
        }

        data = dataList;

        setTotalCnt(dataList.reduce((cnt, v) => {
            return cnt += v.keywordList.length
        }, 0));
    }

    async function excelDownload() {
        if((/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }

        if (data === null) await dataSetting();

        if (excelDownloadStart === true) {
            alert("이미 엑셀 다운로드를 진행했습니다.");
            return;
        }

        setExcelDownloadStart(true);

        try {
            await dataVoucherAPI.asyncDownload('test/dataVoucher/excel/task', {searchData: data});
        }catch (e){
            alert('엑셀 다운로드 실패');
        }

        setExcelDownloadStart(false);
    }

    async function wordDownload() {
        if((/^www.ipnow.co.kr$|^www.biznavi.co.kr$/).test(window.location.hostname)) {
            alert('54.180.76.88로 접속하셔서 진행해주세요.');
            return;
        }
        
        completeCount = 0;
        failCount = 0;
        setCompleteCnt(completeCount);
        setFailCnt(failCount);

        if (data === null) await dataSetting();

        if (docxDownloadStart === true) {
            alert(
                "이미 문서 다운로드를 진행하고 있습니다..\n" +
                "아직 다운로드가 진행되고 있을 수 있으니 사이트를 이탈하지 말아주세요."
            );
            return;
        }

        setDocxDownloadStart(true);

        for (const d of data) {
            for (const keyword of d.keywordList) {
                try {
                    await dataVoucherAPI.asyncDownload('test/dataVoucher/chartTask', {
                        keyword,
                        company: d.company,
                        type: 0,
                    });
                    completeCount++;
                    setCompleteCnt(completeCount);
                } catch (e) {
                    failCount++;
                    setFailCnt(failCount);
                    console.log(e);
                }
            }

            setResultText('문서 다운로드 완료');
        }

        setDocxDownloadStart(false);
    }

    return (
        <div id="TaskDownload">
            <div>
                <input
                    id="file"
                    type={"file"}
                    onChange={(e) => setFiles(e.target.files)}
                />
            </div>
            <div>
                <button onClick={excelDownload}>공고 리스트 다운하기</button>
            </div>
            <div>
                <button onClick={wordDownload}>공고 문서 다운하기</button>
            </div>
            <div>
                성공 {completeCnt}
            </div>
            <div>
                실패 {failCnt}
            </div>
            <div>
                총개수 {totalCnt}
            </div>
            <div>
                {resultText}
            </div>
        </div>
    );
}

export default TaskDownload;
