import { useState, useRef, useEffect } from "react";
import CHECKPOINT_API from "../../../API/patent/checkpoint";

const useReportModal = () => {
  const reportModalRef = useRef();
  const [activePoints, setActivePoints] = useState("");
  const [reportState, setReportState] = useState({
    state: "LOADING",
    checkpoints: [],
  });

  useEffect(() => {
    CHECKPOINT_API.getReport()
      .then((result) => {
        const { checkpointList } = result.data.data;
        setReportState({
          state: "SUCCESS",
          checkpoints: checkpointList,
        });
      })
      .catch((err) => {
        setReportState({
          state: "ERROR",
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, []);

  const openReportModal = (activePoints) => {
    setActivePoints(activePoints);
    reportModalRef.current.open();
    document.body.style.overflow = "hidden";
  };

  const closeReportModal = () => {
    reportModalRef.current.close();
    document.body.style.overflow = "initial";
  };

  const isCheckpointsExists = ({ mainCategory, subCategory }) => {
    if (reportState.state !== "SUCCESS") return false;

    return subCategory
      ? !!reportState.checkpoints?.[mainCategory]?.[subCategory]
      : !!reportState.checkpoints?.[mainCategory];
  };

  return {
    reportModalRef,
    activePoints,
    reportState,
    openReportModal,
    closeReportModal,
    isCheckpointsExists,
  };
};

export default useReportModal;
